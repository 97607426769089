import { StringIdEntity } from "./StringIdEntity";

export class StringIdLabelEntity extends StringIdEntity {
    descripcion: string;
  
    constructor(data: Partial<StringIdLabelEntity> = {}) {
        super();
        Object.assign(this, data);
      }
  }
  