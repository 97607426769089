import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { ModeloRadioModeloAuto } from "src/models/ModeloRadioModeloAuto";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerModelosRadios = () => {
  const SCREEN_CODE = "abm0046_mantener_modelos_radios";
  const URL_BASE = "/mantener-modelos-radios";
  const SCREEN_TITLE = (
    <LanguageProvider id="270" alt="Relacionar Modelos de Autos y Radios" />
  );
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ModeloRadioModeloAuto, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client
      ) {
        const modeloRadioModeloAuto = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          marca: filterValues?.marca,
          modelo: filterValues?.modelo,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          modeloRadioModeloAuto,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ModeloRadioModeloAuto, filterValues: any) {
    try {
      const modeloRadioModeloAuto = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        marca: filterValues?.marca,
        modelo: filterValues?.modelo,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        modeloRadioModeloAuto,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: ModeloRadioModeloAuto, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        modeloRadioModeloAuto: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ModeloRadioModeloAuto[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter.securityValues.account,
          marca: filter.marca,
          modelo: filter.modelo,
        }
      );
      if (status === 200)
        if (data.length > 0) {
          const newData = data.map((item: any) => ({
            ...item,
            id: item.modelo.id + item.modeloRadio.id,
          }));
          return newData;
        } else {
          return [];
        }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<ModeloRadioModeloAuto> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          modeloRadioModeloAuto: { ...model },
        }
      );
      if (status === 200) {
        data.id = data.modelo.id + data.modeloRadio.id;
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarca(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/marca",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadModelo(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.client &&
        filter.marca
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/modelo",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            marca: filter.marca,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadMarcaRadio(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/marca-radio",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadModeloRadio(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.marcaRadio) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/modelo-radio",
          {
            pais: filter?.securityValues?.country,
            marcaRadio: filter?.marcaRadio,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<ModeloRadioModeloAuto>
      screenCode={SCREEN_CODE}
      title={SCREEN_TITLE}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      filterSettings={[
        {
          filter: "marca",
          label: LanguageProvider({ id: "396", alt: "Marca" }),
          placeholder: LanguageProvider({ id: "396", alt: "Marca" }),
          type: "dropdown",
          dataSource: loadMarca,
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
          required: true,
        },
        {
          filter: "modelo",
          label: LanguageProvider({ id: "2139", alt: "Modelo" }),
          placeholder: LanguageProvider({ id: "2139", alt: "Modelo" }),
          type: "dropdown",
          dataSource: loadModelo,
          dropDownTemplate: DropdownOptionTemplate,
          dependency: [
            "securityValues.country",
            "securityValues.client",
            "marca",
          ],
          required: true,
        },
      ]}
      dataTableColumns={[
        {
          field: "cliente.descripcion",
          header: <LanguageProvider id="488" alt="Clientes" />,
        },
        {
          field: "modelo.descripcion",
          header: <LanguageProvider id="396" alt="Marca" />,
        },
        {
          field: "modelo.descripcion",
          header: <LanguageProvider id="2139" alt="Modelo" />,
        },
        {
          field: "modeloRadio.descripcion",
          header: <LanguageProvider id="391" alt="Marca de Radio" />,
        },
        {
          field: "modeloRadio.descripcion",
          header: <LanguageProvider id="1490" alt="Modelo de Radio" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        activo: true,
        pais: null,
        cliente: null,
        modelo: null,
        modeloRadio: null,
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          disabled: true,
        },
        {
          field: "marcaRadio",
          label: LanguageProvider({ id: "391", alt: "Marca de Radio" }),
          dropDownPlaceholder: LanguageProvider({
            id: "391",
            alt: "Marca de Radio",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadMarcaRadio(filter),
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          field: "modeloRadio",
          label: LanguageProvider({ id: "2139", alt: "Modelo" }),
          dropDownPlaceholder: LanguageProvider({ id: "2139", alt: "Modelo" }),
          type: "dropdown",
          dataSource: (filter: any) => loadModeloRadio(filter),
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          field: "activo",
          label: LanguageProvider({ id: "72", alt: "Activo" }),
          type: "checkbox",
        },
      ]}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
    />
  );
};

export default MantenerModelosRadios;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
