import "./DetalleDeValesRepuestos.scss";

import React, { useEffect, useRef, useState } from "react";
import { AccordionTab } from "primereact/accordion";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { useLocation } from "react-router-dom";
import usePageViews from "../../../../hooks/usePageViews";
import { InputText } from "primereact/inputtext";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import useRowStyles from "../../../../hooks/useRowStyles";

export function DetalleDeValesRepuestos() {
  const SCREEN_CODE = "abm0109_detalle_de_vales_repuestos";
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const location = useLocation();
  const { secFilter } = location.state || {};
  const { model } = location.state || {};
  const { selectedData } = location.state || {};

  ScreenCodeValue(SCREEN_CODE);
  usePageViews();

  const dt = useRef(null);
  const [reporteData, setReporteData] = useState(null);

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [selectedReportData, setSelectedReportData] = useState(null);

  const rowStyles = useRowStyles([selectedReportData]);

  async function loadReportData() {
    setLoadingReportData(true);
    try {
      const filters = {
        pais: { id: selectedData?.selectedReportData?.pais?.id },
        cuenta: { id: selectedData?.selectedReportData?.cuenta?.id },
        subcuenta: { id: selectedData?.selectedReportData?.subcuenta?.id },
        taller: { id: selectedData?.selectedReportData?.taller?.id },
        vale: { id: selectedData?.selectedReportData?.id },
      };

      const { status, data } = await sipcoAxiosService.post(
        "/detalle-de-vales-repuestos/findBy",
        filters
      );

      if (status === 200) {
        setReporteData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (selectedData) {
        try {
          loadReportData();
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [selectedData]);

  return (
    <div className="detalle-de-vales-repuestos">
      <Toast ref={toast} position="top-center" />
      <Filter>
        <AccordionTab header={<LanguageProvider id={"65"} alt="Seguridad" />}>
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"5044"} alt="País" />
                </label>
                <InputText value={secFilter?.pais} disabled={true} />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"24328"} alt="Cuenta" />
                </label>
                <InputText value={secFilter?.cuenta} disabled={true} />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <InputText value={model?.taller} disabled={true} />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"441"} alt="Playas" />
                </label>
                <InputText value={model?.playa} disabled={true} />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"12231"} alt="Nro. de Vale" />
                </label>
                <InputText
                  value={selectedData?.selectedReportData?.id}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel header={<LanguageProvider id="6697" alt="Listado" />}>
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporteData}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReportData}
          filterDisplay="menu"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
          selectionMode="single"
          rowClassName={rowStyles}
        >
          <SipcoColumn
            field="id"
            header={<LanguageProvider id="5788" alt="Línea" />}
            sortable
            filter
          />
          <SipcoColumn
            field="clienteRepuesto.id"
            header={<LanguageProvider id="16968" alt="Código cliente" />}
            sortable
            filter
          />
          <SipcoColumn
            field="pieza.id"
            header={<LanguageProvider id="10829" alt="Código Pieza" />}
            sortable
            filter
          />
          <SipcoColumn
            field="pieza.descripcion"
            header={<LanguageProvider id="11833" alt="Descripción" />}
            sortable
            filter
          />
          <SipcoColumn
            field="cantidad"
            header={<LanguageProvider id="1253" alt="Cantidad" />}
            sortable
            filter
          />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
