import "./VehiculosPendienteTaller.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Tag } from "primereact/tag";
import useAuth from "@shared/AuthContext";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { Toast } from "primereact/toast";
import { inactivoColorTDesc } from "../../../hooks/useInactivoColor";

export function VehiculosPendienteTaller() {
  const SCREEN_CODE = "con0049_vehiculos_pendiente_taller";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const { user } = useAuth();
  const toast = useRef<Toast>(null);
  const dt = useRef(null);
  const [dataTableValue, setDataTableValue] = useState([]);
  const [selectedReportData, setSelectedReportData] = useState(null);

  const dataTableColumns = [
    { field: "marca", header: LanguageProvider({ id: "396", alt: "x" }) },
    { field: "vin", header: LanguageProvider({ id: "17088", alt: "x" }) },
    {
      field: "remitoDespacho",
      header: LanguageProvider({ id: "321", alt: "x" }),
    },
    { field: "modelo", header: LanguageProvider({ id: "2139", alt: "x" }) },
    {
      field: "fechaPendienteIngreso",
      header: LanguageProvider({ id: "17091", alt: "x" }),
    },
    {
      field: "descripcionPendienteIngreso",
      header: LanguageProvider({ id: "17092", alt: "x" }),
    },
    {
      field: "codigoOrdenReparacion",
      header: LanguageProvider({ id: "5701", alt: "x" }),
    },
    { field: "fechaOrden", header: LanguageProvider({ id: "9857", alt: "x" }) },
    {
      field: "fechaIngreso",
      header: LanguageProvider({ id: "3232", alt: "x" }),
    },
    {
      field: "fechaPromesaEntrega",
      header: LanguageProvider({ id: "9904", alt: "x" }),
    },
    {
      field: "fechaCierreReal",
      header: LanguageProvider({ id: "2520", alt: "x" }),
    },
    {
      field: "codigoDanio",
      header: LanguageProvider({ id: "1043", alt: "x" }),
    },
    { field: "tipoDanio", header: LanguageProvider({ id: "10541", alt: "x" }) },
    {
      field: "partesDanios",
      header: LanguageProvider({ id: "16243", alt: "x" }),
    },
    {
      field: "fechareparado",
      header: LanguageProvider({ id: "17028", alt: "x" }),
    },
    {
      field: "responsables",
      header: LanguageProvider({ id: "336", alt: "x" }),
    },
    {
      field: "ordenReparacionObs",
      header: LanguageProvider({ id: "3625", alt: "x" }),
    },
    {
      field: "detalleNroPedido",
      header: LanguageProvider({ id: "16480", alt: "x" }),
    },
    {
      field: "fechaCompra",
      header: LanguageProvider({ id: "2519", alt: "x" }),
    },
    {
      field: "fechaPrevista",
      header: LanguageProvider({ id: "16950", alt: "x" }),
    },
    {
      field: "fechaCierre",
      header: LanguageProvider({ id: "2520", alt: "x" }),
    },
    {
      field: "usuarioPriorizacion",
      header: LanguageProvider({ id: "19689", alt: "x" }),
    },
    { field: "origenAuto", header: LanguageProvider({ id: "2496", alt: "x" }) },
    {
      field: "fechaPriorizacion",
      header: LanguageProvider({ id: "19690", alt: "x" }),
    },
    {
      field: "fecEmisionRemito",
      header: LanguageProvider({ id: "14624", alt: "x" }),
    },
    {
      field: "ordenOrigen",
      header:
        LanguageProvider({ id: "11370", alt: "x" }) +
        " " +
        LanguageProvider({ id: "2496", alt: "x" }),
    },
    {
      field: "fechaUltimoIngresoAPlaya",
      header: LanguageProvider({ id: "16553", alt: "x" }),
    },
    {
      field: "numeroIngreso",
      header: LanguageProvider({ id: "16478", alt: "x" }),
    },
  ];
  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {}
  };

  const [filter, setFilter] = useState({
    marca: null,
    vin: null,
  });

  const [loadingMarca, setLoadingMarca] = useState(false);
  const [marca, setMarca] = useState([]);
  async function loadMarca() {
    setLoadingMarca(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/vehiculos-pendientes-taller/combo/marca",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({
            id: 0,
            descripcionFull: "TODOS",
            descripcion: "TODOS",
          });
          setMarca(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
    return [];
  }

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters?.securityValues?.country) {
        const payload = {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
            codCuenta: securityFilters.securityValues.account.id,
            vin: vin,
          },
        };
        const { status, data } = await sipcoAxiosService.get(
          "/vehiculos-pendientes-taller/combo/vin",
          payload
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError("654", "No existe el VIN");
          }
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [clients, setClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);
  async function loadClients() {
    setLoadingClients(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.get(
          "/screens/get-cliente-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              screenCode: SCREEN_CODE,
              level: user.nivel,
            },
          }
        );
        if (status === 200) {
          setClients(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClients(false);
    }
  }

  const [loadingReportData, setLoadingReportData] = useState(false);
  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        marca: filter.marca.id ?? null,
        marcaDescripcion: filter.marca.descripcion ?? null,
        vin: filter?.vin?.id || "",
        clientes: clients,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/vehiculos-pendientes-taller/report",
        filters
      );
      if (status === 200) {
        setDataTableValue(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const marcas = await loadMarca();
        await loadClients();
        setFilter({
          ...filter,
          marca: marcas[0] ?? null,
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues.client]);

  return (
    <div className="vehiculos-pendiente-taller">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"396"} alt="Marca" />
                </label>
                <SipcoDropdown
                  value={filter?.marca}
                  options={marca}
                  onChange={(e) => setFilter({ ...filter, marca: e.value })}
                  optionLabel="descripcionFull"
                  loading={loadingMarca}
                  itemTemplate={inactivoColorTDesc}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="id"
                  dropdown
                  maxLength={17}
                  forceSelection
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="9854" alt="Vehiculos Pendiente Taller" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}                  
                  data={dataTableValue.map(item => 
                    dataTableColumns.map(field => item[field.field])
                  )}
                  
                  columns={dataTableColumns.map(({ header, ...rest }) => ({
                    label: header,
                    ...rest,
                  }))}
                  screenName={"Vehiculos Pendientes Taller"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <Tag
          className="tag-vehiculo-con-fecha-promesa-retrasada"
          value={
            <LanguageProvider
              id={"9905"}
              alt="Vehiculos con Fecha de Promesa de la Orden de Reparación retrasada"
            />
          }
        />
        <SipcoDataTable
          ref={dt}
          value={dataTableValue}
          loading={loadingReportData}
          selectionMode="single"
          filterDisplay="menu"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
          rowClassName={getRowStyles}
        >
          {dataTableColumns.map((dataTableColumn, key) => (
            <SipcoColumn
              key={key}
              field={dataTableColumn.field}
              header={dataTableColumn.header}
              // body={dataTableColumn.body}
              filter
              sortable
            />
          ))}
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default VehiculosPendienteTaller;

const getRowStyles = (dataRow) => {
  let className = "";

  if (dataRow && dataRow.fechaPromesaEntrega) {
    const fechaHoy = new Date();
    const [day, month, year] = dataRow.fechaPromesaEntrega
      .split("/")
      .map(Number);
    const fechaPromesa = new Date(year, month - 1, day);
    if (fechaPromesa < fechaHoy) {
      className = "dt-list-vehiculo-con-fecha-promesa-retrasada";
    }
  }

  return className.trim();
};
