import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { ResultadoOperacion } from "src/models/ResultadoOperacion";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerResultadoOperaciones = () => {
  const SCREEN_CODE = "abm0186_mantener_resultado_operaciones";
  const URL_BASE = "/mantener-resultado-operaciones";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ResultadoOperacion, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const resultadoOperacion = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          operacion: model.operacion,
          lineaOperacion: model.lineaOperacion,
          replicar: model.replicar,
          activo: model.activo,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          resultadoOperacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ResultadoOperacion, filterValues: any) {
    try {
      const resultadoOperacion = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        operacion: model.operacion,
        lineaOperacion: model.lineaOperacion,
        replicar: model.replicar,
        activo: model.activo,
        id: model.id,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        resultadoOperacion,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: ResultadoOperacion, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        resultadoOperacion: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ResultadoOperacion[]> {
    try {
      if (
        filter.securityValues.country !== null &&
        filter?.operacion !== null
      ) {
        console.log(filter);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            pais: filter.securityValues.country,
            operacion: filter.operacion,
          }
        );
        if (status === 200) return data;
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<ResultadoOperacion> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          resultadoOperacion: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadOperacion(filter: any): Promise<ResultadoOperacion> {
    try {
      if (filter.securityValues.country !== null) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/operacion",
          {
            pais: filter.securityValues.country,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.label}${item.id}`,
            }));
            return updatedData;
          }
        };
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadOperacionFilter(filter: any): Promise<ResultadoOperacion> {
    try {
      if (filter.securityValues.country !== null) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/operacion",
          {
            pais: filter.securityValues.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadLineaFilter(filter: any): Promise<ResultadoOperacion> {
    try {
      if (filter.securityValues.country !== null && filter.operacion !== null) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/lineaOperacion",
          {
            pais: filter.securityValues.country,
            operacion: filter.operacion,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <GenericAbm<ResultadoOperacion>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      dataTableColumns={[
        {
          field: "operacion.descripcion",
          header: <LanguageProvider id="18637" alt="Operación" />,
        },
        {
          field: "lineaOperacion.descripcion",
          header: <LanguageProvider id="5788" alt="Línea" />,
        },
        { field: "id", header: <LanguageProvider id="6590" alt="Código" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="descripcion" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
        descripcion: "",
        lineaOperacion: null,
        operacion: null,
        replicar: false,
        todos: false,
        resultado: "",
      }}
      formSettings={[
        {
          field: "operacion",
          label: <LanguageProvider id="1569" alt="Operación" />,
          type: "dropdown",
          dataSource: (filter: any) => loadOperacionFilter(filter),
          optionLabel: "label",
          disableOnUpdate: true,
        },
        {
          field: "lineaOperacion",
          label: <LanguageProvider id="5788" alt="Línea" />,
          type: "dropdown",
          dataSource: (filter: any) => loadLineaFilter(filter),
          optionLabel: "descripcion",
          dependency: ["operacion"],
          disableOnUpdate: true,
        },
        {
          field: "id",
          label: <LanguageProvider id="8380" alt="Resultado" />,
          type: "inputText",
          length: 20,
          disableOnUpdate: true,
        },

        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripción" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      filterSettings={[
        {
          filter: "operacion",
          label: LanguageProvider({ id: "1569", alt: "Operación" }),
          placeholder: LanguageProvider({ id: "1569", alt: "Operación" }),
          type: "dropdown",
          dataSource: (filter: any) => loadOperacion(filter),
          dropDownTemplate: DropdownOptionTemplate,
          filterBy: "search",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
    />
  );
};

export default MantenerResultadoOperaciones;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
