import "./EgresoTaller.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import useFormattedDate from "../../../hooks/useFormattedDate";
import usePageViews from "../../../hooks/usePageViews";
import useRowStyles from "../../../hooks/useRowStyles";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { LanguageProviderString } from "@shared/components/language-provider-string";
import useAuth from "@shared/AuthContext";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export function EgresoTaller() {
  const SCREEN_CODE = "fun0054_egreso_taller";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  ScreenCodeValue(SCREEN_CODE);
  const toast = useRef<Toast>(null);
  const { user } = useAuth();
  usePageViews();
  const dt = useRef(null);
  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);
  const [selectedReportData, setSelectedReportData] = useState(null);
  const [loadingGridDanios, setLoadingGridDanios] = useState(false);
  const [gridDanios, setGridDanios] = useState([]);
  const [selectedGridDanios, setSelectedGridDanios] = useState([]);
  const rowStyles = useRowStyles([selectedReportData]);
  const [indisponible, setIndisponible] = useState("");

  const { formatDateTime } = useFormattedDate();

  const [filter, setFilter] = useState({
    taller: null,
    calle: null,
    columna: null,
    nivel: null,
    vin: null,
  });

  const [model, setModel] = useState({
    calleTraslado: null,
    columnaTraslado: null,
    nivelTraslado: null,
  });

  const [taller, setTaller] = useState([]);
  const [loadingTaller, setLoadingTaller] = useState(false);
  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (securityFilters?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/taller",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCliente: securityFilters?.securityValues?.client?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
          }
        );

        if (status === 200) {
          setTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
    setTaller([]);
    return [];
  }

  const [calle, setCalle] = useState([]);
  const [loadingCalle, setLoadingCalle] = useState(false);
  async function loadCalle(taller) {
    setLoadingCalle(true);
    try {
      if (securityFilters?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/taller-calle",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCliente: securityFilters?.securityValues?.client?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
            taller: taller,
          }
        );

        if (status === 200) {
          setCalle(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalle(false);
    }
    setCalle([]);
    return [];
  }

  const [columna, setColumna] = useState([]);
  const [loadingColumna, setLoadingColumna] = useState(false);
  async function loadColumna(calle) {
    setLoadingColumna(true);
    try {
      if (calle) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/columna",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
            calle: calle,
          }
        );

        if (status === 200) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
    setColumna([]);
    return [];
  }

  const [nivel, setNivel] = useState([]);
  const [loadingNivel, setLoadingNivel] = useState(false);
  async function loadNivel(calle, columna) {
    setLoadingNivel(true);
    try {
      if (calle && columna) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/nivel",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
            calle: calle,
            columna: columna,
          }
        );

        if (status === 200) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
    setNivel([]);
    return [];
  }

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters?.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/vin",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            vin: vin,
          }
        );

        if (status === 200) {
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    setVin([]);
    return [];
  }

  const [ubicacionTranslado, setUbicacionTranslado] = useState([]);
  const [loadingUbicacionTrans, setLoadingUbicacionTrans] = useState(false);
  async function loadUbicacionTranslado() {
    setLoadingUbicacionTrans(true);
    try {
      if (securityFilters?.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/calle",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCliente: securityFilters?.securityValues?.client?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
          }
        );

        if (status === 200) {
          setUbicacionTranslado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUbicacionTrans(false);
    }
    setUbicacionTranslado([]);
    return [];
  }

  const [columnaModel, setColumnaModel] = useState([]);
  const [loadingColumnaModel, setLoadingColumnaModel] = useState(false);
  async function loadColumnaModel(ubicacionTranslado) {
    setLoadingColumnaModel(true);
    try {
      if (ubicacionTranslado) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/columna",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
            calle: ubicacionTranslado,
          }
        );

        if (status === 200) {
          setColumnaModel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumnaModel(false);
    }
    setColumnaModel([]);
    return [];
  }

  const [nivelModel, setNivelModel] = useState([]);
  const [loadingNivelModel, setLoadingNivelModel] = useState(false);
  async function loadNivelModel(ubicacionTranslado, columna) {
    setLoadingNivelModel(true);
    try {
      if (ubicacionTranslado && columna) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/nivel",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
            calle: ubicacionTranslado,
            columna: columna,
          }
        );

        if (status === 200) {
          setNivelModel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivelModel(false);
    }
    setNivelModel([]);
    return [];
  }

  function clearGrids() {
    setReporte([]);
    setSelectedReportData([]);
    setGridDanios([]);
    setSelectedGridDanios([]);
  }

  function setLoadingsGrids(boolean: boolean) {
    setLoadingReportData(boolean);
    setLoadingGridDanios(boolean);
  }

  function showToast({ severity, messageId, alt, life = 3000 }) {
    toast.current.show({
      severity,
      detail: `${LanguageProviderString({ id: messageId, alt: alt, user })}`,
      life,
    });
  }

  function showConfirmationDialog({ headerId, messageId, alt, onAccept }) {
    confirmDialog({
      header: LanguageProviderString({
        id: headerId,
        alt: "Atención",
        user,
      }),
      message: `${LanguageProviderString({
        id: messageId,
        alt: alt,
        user,
      })}`,
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => {
        setTimeout(() => {
          onAccept();
        }, 200);
      },
      reject: () => setLoadingsGrids(false),
    });
  }

  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/egreso-taller/historico-taller",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCliente: securityFilters?.securityValues?.client?.id || null,
          codCuenta: securityFilters?.securityValues?.account?.id || null,
          codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
          taller: filter?.taller?.id ?? null,
          vin: filter?.vin?.id ?? null,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  async function loadReportDataDetails() {
    try {
      setLoadingGridDanios(true);
      const { status, data } = await sipcoAxiosService.post(
        "/egreso-taller/historico-danos",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCliente: securityFilters?.securityValues?.client?.id || null,
          codCuenta: securityFilters?.securityValues?.account?.id || null,
          vin: selectedReportData?.vin?.id,
        }
      );

      if (status === 200) {
        setGridDanios(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGridDanios(false);
    }
  }

  async function procesar() {
    try {
      // srvHistoricoTaller.egresoTaller(filterForm.pais, filterForm.cliente, filterForm.cuenta, filterForm.subcuenta, this.taller, historicoTaller, stockPlayaOrigen, stockPlayaDestino, gridDanios.selectedItems, gridDanios.unselectedItems, indisponible);
      const payload = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        cuenta: securityFilters.securityValues.account,
        subcuenta: securityFilters.securityValues.subaccount,
        taller: filter.taller,
        historicoTaller: selectedReportData,
        stockPlayaOrigen: {
          calle: filter.calle,
          columna: filter.columna,
          nivel: filter.nivel,
        },
        stockPlayaDestino: {
          calle: model.calleTraslado,
          columna: model.columnaTraslado,
          nivel: model.nivelTraslado,
        },
        danios: selectedGridDanios,
        daniosSinReparar: gridDanios.filter(
          (danio) => !selectedGridDanios.includes(danio)
        ),
        indisponible: indisponible,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/egreso-taller/egreso-taller",
        payload
      );
      if (status === 200) {
        if (data === "egreso taller") {
          loadReportData();
          setGridDanios([]);
          setSelectedGridDanios([]);
          setLoadingGridDanios(false);
        }
      } else {
        setLoadingsGrids(false);
        showToast({
          severity: "error",
          messageId: "11",
          alt: "Ha ocurrido un error, su ultima operación no fue realizada. Comuniquese con Sistemas",
        });
      }
    } catch (error) {
      setLoadingsGrids(false);
      console.error(error);
    }
  }

  function onValidationProcesarComplete() {
    showConfirmationDialog({
      headerId: "16239",
      messageId: "13275",
      alt: "¿Desea procesar los cambios realizados?",
      onAccept: () => procesar(),
    });
  }

  async function validaEgreso() {
    try {
      const payload = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        cuenta: securityFilters.securityValues.account,
        subcuenta: securityFilters.securityValues.subaccount,
        taller: filter.taller,
        historicoTaller: selectedReportData,
        calle: model.calleTraslado,
        columna: model.columnaTraslado,
        nivel: model.nivelTraslado,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/egreso-taller/validate-egreso-taller",
        payload
      );
      if (status === 200) {
        if (data !== "") {
          showConfirmationDialog({
            headerId: "16239",
            messageId: data.toString(),
            alt: data.toString(),
            onAccept: () => onValidationProcesarComplete(),
          });
        } else {
          onValidationProcesarComplete();
        }
      } else {
        setLoadingsGrids(false);
      }
    } catch (error) {
      setLoadingsGrids(false);
      console.error(error);
    }
  }

  async function validarCantidadSuperior() {
    try {
      // srvStockPlaya.validarCantidadSuperior(filterForm.pais, filterForm.cuenta, filterForm.subcuenta, this.calleTraslado, this.columnaTraslado, this.nivelTraslado, 0);
      const payload = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        subcuenta: securityFilters.securityValues.subaccount,
        calle: model.calleTraslado,
        columna: model.columnaTraslado,
        nivel: model.nivelTraslado,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/egreso-taller/validar-cantidad-superior",
        payload
      );
      if (status === 200) {
        if (data) {
          showConfirmationDialog({
            headerId: "16239",
            messageId: "660",
            alt: "Esta excediendo la cantidad permitida - Desea Continuar ?",
            onAccept: () => validaEgreso(),
          });
        } else {
          validaEgreso();
        }
      } else {
        setLoadingsGrids(false);
      }
    } catch (error) {
      setLoadingsGrids(false);
      console.error(error);
    }
  }

  async function valid() {
    setLoadingsGrids(true);
    const { calleTraslado, columnaTraslado, nivelTraslado } = model;

    if (
      calleTraslado === null ||
      columnaTraslado === null ||
      nivelTraslado === null
    ) {
      showToast({
        severity: "warn",
        messageId: "39",
        alt: "Existen datos en blanco.",
      });
      setLoadingsGrids(false);
      return;
    }

    if (selectedReportData.length === 0) {
      showToast({
        severity: "warn",
        messageId: "753",
        alt: "Debe seleccionar al menos un registro de la Grilla.",
      });
      setLoadingsGrids(false);
      return;
    }

    if (selectedReportData?.tipo === "interno") {
      if (gridDanios.length !== selectedGridDanios.length) {
        setIndisponible("I");
        showConfirmationDialog({
          headerId: "16239",
          messageId: "1030",
          alt: "Al menos un daño no fue reparado - Continua ?",
          onAccept: () => validarCantidadSuperior(),
        });
      } else {
        setIndisponible("");
        validarCantidadSuperior();
      }
    } else {
      validaEgreso();
    }
  }

  useEffect(() => {
    async function initialize() {
      const { country, client, account, subaccount } =
        securityFilters.securityValues;
      if (country && client && account && subaccount) {
        clearGrids();
        try {
          const tallers = await loadTaller();
          const callers = await loadCalle(
            securityFilters?.securityValues?.subaccount?.id
          );

          setFilter((prevFilter) => ({
            ...prevFilter,
            taller: tallers?.length > 0 ? tallers[0] : null,
            calle: callers?.length > 0 ? callers[0] : null,
          }));

          const ubicacionTranslados = await loadUbicacionTranslado();

          setModel((prevFilter) => ({
            ...prevFilter,
            calleTraslado: ubicacionTranslados[0],
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters?.securityValues]);

  // Carregar columnas ao mudar Calle
  useEffect(() => {
    async function updateColumna() {
      if (filter?.calle) {
        try {
          const columnas = await loadColumna(filter.calle);
          setFilter((prevFilter) => ({
            ...prevFilter,
            columna: columnas?.length > 0 ? columnas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateColumna();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.calle]);

  // Carregar níveis ao mudar a columnas
  useEffect(() => {
    async function updateNivel() {
      if (filter?.calle && filter?.columna) {
        try {
          const niveis = await loadNivel(filter.calle, filter.columna);
          setFilter((prevFilter) => ({
            ...prevFilter,
            nivel: niveis?.length > 0 ? niveis[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateNivel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.columna]);

  //MODEL
  // Carregar columnas ao mudar UbicacionTranslado
  useEffect(() => {
    async function updateColumnaModel() {
      if (model?.calleTraslado) {
        try {
          const columnas = await loadColumnaModel(model.calleTraslado);
          setModel((prevFilter) => ({
            ...prevFilter,
            columnaTraslado: columnas?.length > 0 ? columnas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateColumnaModel();
  }, [model?.calleTraslado]);

  // Carregar níveis ao mudar a columnas
  useEffect(() => {
    async function updateNivelModel() {
      if (model?.calleTraslado && model?.columnaTraslado) {
        try {
          const niveis = await loadNivelModel(
            model.calleTraslado,
            model.columnaTraslado
          );
          setModel((prevFilter) => ({
            ...prevFilter,
            nivelTraslado: niveis?.length > 0 ? niveis[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateNivelModel();
  }, [model?.columnaTraslado]);

  useEffect(() => {
    if (selectedReportData) {
      loadReportDataDetails();
    }
  }, [selectedReportData]);

  // Seleciona todas as linhas do conjunto de dados
  useEffect(() => {
    if (gridDanios && gridDanios.length > 0) {
      setSelectedGridDanios(gridDanios);
    }
  }, [gridDanios]);

  return (
    <div className="egreso-taller">
      <Toast ref={toast} position="top-center" />
      <ConfirmDialog />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"664"} alt="Talleres" />
                </label>
                <SipcoDropdown
                  value={filter.taller}
                  options={taller}
                  onChange={(e) => setFilter({ ...filter, taller: e.value })}
                  optionLabel="descripcion"
                  loading={loadingTaller}
                  filter
                  disabled={true}
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"990"} alt="Calle" />
                </label>
                <SipcoDropdown
                  value={filter.calle}
                  options={calle}
                  onChange={(e) => setFilter({ ...filter, calle: e.value })}
                  optionLabel="descripcion"
                  loading={loadingCalle}
                  filter
                  disabled={true}
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"991"} alt="Columna" />
                </label>
                <SipcoDropdown
                  value={filter.columna}
                  options={columna}
                  onChange={(e) => setFilter({ ...filter, columna: e.value })}
                  optionLabel="descripcion"
                  loading={loadingColumna}
                  filter
                  disabled={true}
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"992"} alt="Nivel" />
                </label>
                <SipcoDropdown
                  value={filter.nivel}
                  options={nivel}
                  onChange={(e) => setFilter({ ...filter, nivel: e.value })}
                  optionLabel="descripcion"
                  loading={loadingNivel}
                  filter
                  disabled={true}
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"17088"} alt="Vin" />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="id"
                  dropdown
                  forceSelection
                  maxLength={17}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        className="mb-3"
        header={<LanguageProvider id={"320"} alt="Egreso Taller" />}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="single"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
        >
          <SipcoColumn
            field="vin.id"
            header={<LanguageProvider id="17088" alt="Vin" />}
          />
          <SipcoColumn
            field="vin.marca.descripcion"
            header={<LanguageProvider id="396" alt="Marca" />}
          />
          <SipcoColumn
            field="vin.modelo.descripcion"
            header={<LanguageProvider id="2139" alt="Modelo" />}
          />
          <SipcoColumn
            field="vin.color.descripcion"
            header={<LanguageProvider id="2140" alt="Color" />}
          />
          <SipcoColumn
            field="vin.patente"
            header={<LanguageProvider id="4724" alt="Patente" />}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="849" alt="Letra" />}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="850" alt="Pto.Venta" />}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="932" alt="Código 4" />}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="1492" alt="Fecha Emisión" />}
          />
          <SipcoColumn
            field=""
            header={<LanguageProvider id="3344" alt="Estado" />}
          />
          <SipcoColumn
            field="fechaIngreso.date"
            header={
              <LanguageProvider id="16797" alt="Fecha de ingreso taller" />
            }
            sortable
            filter
            body={(rowData) =>
              rowData.fechaIngreso?.date
                ? formatDateTime(rowData.fechaIngreso.date)
                : rowData.fechaIngreso || ""
            }
          />
          <SipcoColumn
            field="tipo"
            header={<LanguageProvider id="802" alt="Tipo de Ingreso" />}
          />
        </SipcoDataTable>
      </Panel>

      <Panel>
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={Array.isArray(gridDanios) ? gridDanios : []}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          loading={loadingGridDanios}
          selectionMode="multiple"
          onSelectionChange={(e) => setSelectedGridDanios(e.value)}
          selection={selectedGridDanios}
        >
          <SipcoColumn
            field="tipoDeDanio.id"
            header={<LanguageProvider id="10540" alt="Codigo Daño" />}
          />
          <SipcoColumn
            field="tipoDeDanio.descripcion"
            header={<LanguageProvider id="10541" alt="Descripción Daño" />}
          />
          <SipcoColumn
            field="partesDanios.id"
            header={<LanguageProvider id="2490" alt="Parte" />}
          />
          <SipcoColumn
            field="partesDanios.descripcion"
            header={<LanguageProvider id="16243" alt="Parte dañada" />}
          />
          <SipcoColumn
            field="gravedadDeDanio.id"
            header={<LanguageProvider id="16575" alt="Cod. Gravedad" />}
          />
          <SipcoColumn
            field="gravedadDeDanio.descripcion"
            header={<LanguageProvider id="17007" alt="Gravedad del Daño" />}
          />
          <SipcoColumn
            field="fechaCarga.date"
            header={<LanguageProvider id="16541" alt="Fecha de carga" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaCarga?.date
                ? formatDateTime(rowData.fechaCarga.date)
                : rowData.fechaCarga || ""
            }
          />
        </SipcoDataTable>
      </Panel>

      <div>
        <p>
          <LanguageProvider
            id="17475"
            alt="Los registros seleccionados se marcarán como reparados, puede desmarcar con un click"
          />
        </p>
      </div>

      <div className="filter-options">
        <div className="sipco-options-line">
          <div className="sipco-option">
            <label>
              <LanguageProvider id={"741"} alt="Ubicación de Traslado" />
            </label>
            <SipcoDropdown
              value={model.calleTraslado}
              options={ubicacionTranslado}
              onChange={(e) => setModel({ ...model, calleTraslado: e.value })}
              optionLabel="label"
              loading={loadingUbicacionTrans}
              filter
            />
          </div>

          <div className="sipco-option">
            <label>
              <LanguageProvider id={"991"} alt="Columna" />
            </label>
            <SipcoDropdown
              value={model.columnaTraslado}
              options={columnaModel}
              onChange={(e) => setModel({ ...model, columnaTraslado: e.value })}
              optionLabel="descripcionFull"
              loading={loadingColumnaModel}
              filter
            />
          </div>

          <div className="sipco-option">
            <label>
              <LanguageProvider id={"992"} alt="Nivel" />
            </label>
            <SipcoDropdown
              value={model.nivelTraslado}
              options={nivelModel}
              onChange={(e) => setModel({ ...model, nivelTraslado: e.value })}
              optionLabel="descripcionFull"
              loading={loadingNivelModel}
              filter
            />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "34",
            alt: "Procesar o guardar cambios",
          })}
          icon="pi pi-save"
          onClick={() => valid()}
          loading={loadingReportData || loadingGridDanios}
        />
      </div>
    </div>
  );
}
export default EgresoTaller;
