import React, { useEffect, useState } from "react";
import { LanguageProvider } from "@shared/components/language-provider";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { useFilterContext } from "../../../../protected-routes";

export function OperacionesAltaModificar({
  showModal,
  setShowModal,
  userData,
  listadoSelectedOperacion,
}) {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const [clienteSelectedObject, setClienteSelectedObject] = useState(null);
  const [listClientes, setListClientes] = useState([]);
  const [loadingConc, setLoadingConc] = useState(false);

  const [operacionesSelectedObject, setOperacionesSelectedObject] =
    useState(null);
  const [listOperaciones, setlistOperaciones] = useState([]);

  const [activoChecked, setActivoChecked] = useState(true);

  useEffect(() => {
    findClientes();
    findListOperaciones(listadoSelectedOperacion?.cliente?.id);
  }, []);

  const footerContent = (
    <div>
      <Button
        label="Aceptar"
        icon="pi pi-check"
        onClick={() => {
          if(!clienteSelectedObject?.id || !operacionesSelectedObject?.id) {
            return alert("selecione corretamente")
          }
          //TODO popup confirmação
          showModal === "Operaciones - Modificar"
            ? modifyOperacion()
            : save();
        }}
        autoFocus
      />

      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => {
          setShowModal(false);
        }}
        className="p-button-text"
      />
    </div>
  );

  async function save() {

    if (listClientes.length < 1 || listOperaciones.length < 1)
      return alert("aguarde a pesquisa");

    let body = {
      ...listadoSelectedOperacion,
      activo: activoChecked,
      pais: securityFilters.securityValues.country,
      usuario: userData,
      cliente: clienteSelectedObject,
      usuarioUltimaModificacion: userData,
    };


    try {
      const { status, data } = await sipcoAxiosService.post(
        "/mantenerUsuario/saveMarcaUsuario",
        body,
        {}
      );

      if (status === 200) {
        setClienteSelectedObject(null);
        setOperacionesSelectedObject(null);

        // toast.current.show({
        //   severity: "success",
        //   summary: "Success",
        //   detail: "Carrera forzada con éxito!",
        //   life: 3000,
        // });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function modifyOperacion() {
    if(!clienteSelectedObject  || !operacionesSelectedObject){
      return alert("falta dados")
    }

    let body = {
      ...listadoSelectedOperacion,
      activo: activoChecked,
      pais: securityFilters.securityValues.country,
      usuario: userData,
      cliente: clienteSelectedObject,
      usuarioUltimaModificacion: userData,
      operacion: operacionesSelectedObject
    };


    try {
      const { status, data } = await sipcoAxiosService.post(
        `/mantenerUsuario/modificarOperacionUsuario`,
        body,
        {}
      );

      if (status === 200) {
        // toast.current.show({
        //   severity: "success",
        //   summary: "Success",
        //   detail: "Carrera forzada con éxito!",
        //   life: 3000,
        // });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findClientes() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/findClientes"
      );
      if (status === 200) {
        setListClientes(data);
        if (showModal === "Operaciones - Modificar") {
          const fullData = data.find(
            (value) => value?.descripcion === listadoSelectedOperacion?.cliente?.descripcion
          );
          setClienteSelectedObject(fullData);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findListOperaciones(codCliente: string) {
    setLoadingConc(true);

    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/findListOperaciones",
        {
          params: {
            codPais: userData?.pais?.id,
            codCliente: codCliente,
            activo: activoChecked
          },
        }
      );
      if (status === 200) {
        setlistOperaciones(data);
        setLoadingConc(false);

        if (showModal === "Operaciones - Modificar") {
          const fullData = data.find(
            (value) => value?.descripcion === listadoSelectedOperacion?.operacion?.descripcion
          );
          setOperacionesSelectedObject(fullData);
        }
      }
    } catch (error) {
      console.error(error);
      setLoadingConc(false);
    }
  }

  return (
    
      <div className={"modal-geral"}>
        <Dialog
          header={showModal}
          visible={showModal}
          maximizable
          style={{ width: "55vw" }}
          footer={footerContent}
          onHide={() => {
            if (!showModal) return;
            setShowModal(false);
          }}
        >
          <div className="cp-option-modal">
            <div className="organizeHorizontally-col">
              <div className={"sipco-option-modal"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Cliente" />
                </label>
                <Dropdown
                  value={clienteSelectedObject?.descripcion}
                  placeholder={clienteSelectedObject?.descripcion}
                  options={listClientes}
                  onChange={(e) => {
                    setClienteSelectedObject(e.value);
                  }}
                  optionLabel="descripcion"
                  itemTemplate={(option) =>
                    `${option.descripcion} (${option.id})`
                  }
                  className="w-full md:w-30rem"
                  loading={listClientes.length > 0 ? false : true}
                  filter
                  key={"id"}
                  disabled={
                    showModal === "Operaciones - Modificar"
                  }
                />
              </div>

              <div className={"sipco-option-modal"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Operaciones" />
                </label>
                <Dropdown
                  value={operacionesSelectedObject?.descripcion}
                  placeholder={operacionesSelectedObject?.descripcion}
                  options={listOperaciones}
                  optionLabel="descripcion"
                  onChange={(e) => {
                    setOperacionesSelectedObject(e.value);
                  }}
                  itemTemplate={(option) =>
                    `${option.descripcion} (${option.id})`
                  }
                  className="w-full md:w-30rem"
                  loading={loadingConc}
                  filter
                  key={"id"}
                  disabled={
                    showModal === "Operaciones - Modificar"
                  }
                />
              </div>
            </div>

            <div className={"org-option-col orgHori"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Activo" />
              </label>
              <Checkbox
                checked={activoChecked}
                onClick={() => {
                  setActivoChecked(!activoChecked);
                }}
              ></Checkbox>
            </div>
          </div>
        </Dialog>
      </div>
  );
}

export default OperacionesAltaModificar;
