import { confirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoInputText from "@shared/components/sipco-input-text";
import SkeletonBodyForm from "./../components/SkeletonBodyForm";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Panel } from "primereact/panel";
import { LanguageProviderString } from "@shared/components/language-provider-string";
import useAuth from "@shared/AuthContext";

const TransferenciaVin: React.FC<any> = (props) => {
  const { toast } = props;
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const { user } = useAuth(); 
  const [loading, setLoading] = useState(false);
  const [vin, setVin] = useState({
    id: "",
    marca: "",
    calle: "",
    modelo: "",
    columna: "",
    color: "",
    nivel: "",
  });
  const [localizarVin, setLocalizarVin] = useState(null);
  const [ingreseElVin, setIngreseElVin] = useState(null);
  const [invalidVin, setInvalidVin] = useState(false);
  const [disableNuevaUbicacion, setDisableNuevaUbicacion] = useState(true);
  const [lineasCarga, setLineasCarga] = useState(false);
  const [autoForm, setAutoForm] = useState(new AutoForm());
  const [calle, setCalle] = useState([]);
  const [columna, setColumna] = useState([]);
  const [nivel, setNivel] = useState([]);

  const MSG_ALL_FIELDS_SECURITY_REQUIRED = LanguageProvider({
    id: "gen.selectAllSecurityFields.required",
    alt: "Selecione todos os campos de segurança.",
  });

  // const params = {
  //   codPais: "1",
  //   codCuenta: "1",
  //   codSubcuenta: "1",
  //   vin: "8ADUEFC29SG503184",
  //   codCliente: "00000453",
  //   descripcionCliente: "PEUGEOT",
  // };

  const checkValidVin = () => {
    if (vin.id.length < 8 || vin.id.length > 17) {
      setInvalidVin(true);
      toast.current?.show({
        severity: "warn",
        detail: (
          <LanguageProvider
            id={"653"}
            alt="Debe ingresar un minimo de 8 y un maximo de 17 dígitos"
          />
        ),
        life: 5000,
      });
      setLoading(false);
      return false;
    } else {
      setInvalidVin(false);
      setLoading(false);

      return true;
    }
  };

  const hasValidSecurityValues = () => {
    const { client, account, country, subaccount } =
      securityFilters.securityValues;
    if (
      country?.id &&
      account?.id &&
      subaccount?.id &&
      client?.id &&
      client?.descripcion
    ) {
      return true;
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: MSG_ALL_FIELDS_SECURITY_REQUIRED,
        life: 3000,
      });
      setLoading(false);
      return false;
    }
  };

  const clearVin = () => {
    setVin({
      ...vin,
      marca: "",
      calle: "",
      modelo: "",
      columna: "",
      color: "",
      nivel: "",
    });
  };

  const clearAutoForm = () => {
    setAutoForm(new AutoForm());
  };

  const clearAll = () => {
    setIngreseElVin(null);
    setLocalizarVin(null);
    setVin({
      id: "",
      marca: "",
      calle: "",
      modelo: "",
      columna: "",
      color: "",
      nivel: "",
    });
    setAutoForm(new AutoForm());
    setDisableNuevaUbicacion(true);
  };

  const getApiLocalizar = async (idVin) => {
    const ValidSecurity = hasValidSecurityValues();
    if (ValidSecurity) {
      setLoading(true);
      try {
        const { status, data } = await sipcoAxiosService.post(
          "/transferencia-ubicacion/localizar",
          {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
            descripcionCliente:
              securityFilters.securityValues.client.descripcion,
            codCuenta: securityFilters.securityValues.account.id,
            codSubcuenta: securityFilters.securityValues.subaccount.id,
            vin: idVin,
          }
        );
        console.log("getApiCalle status ", status, " data ", data);
        if (status === 200) {
          setLocalizarVin(data);
        }
      } catch (error) {
        console.error(error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getApiIngreseElVin = async () => {
    const ValidSecurity = hasValidSecurityValues();
    const ValidVin = checkValidVin();
    if (ValidSecurity && ValidVin) {
      try {
        const payload = {
          codPais: securityFilters.securityValues.country.id,
          codCuenta: securityFilters.securityValues.account.id,
          codSubcuenta: securityFilters.securityValues.subaccount.id,
          codCliente: securityFilters.securityValues.client.id,
          descripcionCliente: securityFilters.securityValues.client.descripcion,
          vin: vin.id,
        };
        setLoading(true);
        const { status, data } = await sipcoAxiosService.get(
          "/transferencia-ubicacion/vin",
          {
            params: payload,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            console.log("vin status 200 length > 0", data[0]);
            setIngreseElVin(data[0]);
            getApiLocalizar(data[0].id);
            setDisableNuevaUbicacion(false);
          } else {
            toast.current?.show({
              severity: "warn",
              detail: <LanguageProvider id={"1480"} alt="El VIN no existe" />,
              life: 5000,
            });
            setIngreseElVin(null);
            setLocalizarVin(null);
            clearVin();
            setDisableNuevaUbicacion(true);
          }
        }
      } catch (error) {
        console.error(error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  async function searchCalle(event: any) {
    await loadCalle(event.query);
  }
  async function loadCalle(query = "") {
    if (autoForm.columna !== "" || autoForm.nivel !== "") {
      console.log("entrou no if do loadcalle de limpeza");
      setAutoForm({ ...autoForm, columna: "", nivel: "" });
    }
    const ValidSecurity = hasValidSecurityValues();
    if (ValidSecurity && ingreseElVin?.id) {
      try {
        const { status, data } = await sipcoAxiosService.get(
          "/transferencia-ubicacion/calle",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              descripcionCliente:
                securityFilters.securityValues.client.descripcion,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
              chkLineaCarga: lineasCarga,
            },
          }
        );
        if (status === 200) {
          if (query) {
            const q = data.filter((x: any) =>
              x.toLowerCase().includes(query.toLowerCase())
            );
            setCalle(q);
          } else {
            setCalle(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function searchColumna(event: any) {
    await loadColumna(event.query);
  }
  async function loadColumna(query = "") {
    const ValidSecurity = hasValidSecurityValues();
    if (ValidSecurity && ingreseElVin?.id) {
      try {
        const { status, data } = await sipcoAxiosService.get(
          "/transferencia-ubicacion/columna",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              descripcionCliente:
                securityFilters.securityValues.client.descripcion,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
              chkLineaCarga: lineasCarga,
              calle: autoForm.calle,
            },
          }
        );
        if (status === 200) {
          if (query) {
            const teste = data.filter((x: any) =>
              x.toLowerCase().includes(query.toLowerCase())
            );
            setColumna(teste);
          } else {
            setColumna(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function searchNivel(event: any) {
    await loadNivel(event.query);
  }
  async function loadNivel(query = "") {
    const ValidSecurity = hasValidSecurityValues();
    if (ValidSecurity && ingreseElVin?.id) {
      try {
        const { status, data } = await sipcoAxiosService.get(
          "/transferencia-ubicacion/nivel",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
              chkLineaCarga: lineasCarga,
              calle: autoForm.calle,
              columna: autoForm.columna,
            },
          }
        );
        if (status === 200) {
          if (query) {
            const teste = data.filter((x: any) =>
              x.toLowerCase().includes(query.toLowerCase())
            );
            setNivel(teste);
          } else {
            setNivel(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  const hasValidAllFields = () => {
    const { client, account, country, subaccount } =
      securityFilters?.securityValues || {};
    const { id: vinId } = ingreseElVin || {};
    const { calle: oldCalle, columna: oldColumna, nivel: oldNivel } = vin || {};
    const {
      calle: newCalle,
      columna: newColumna,
      nivel: newNivel,
      fecha: newFecha,
    } = autoForm || {};

    if (
      country?.id &&
      account?.id &&
      client?.id &&
      client?.descripcion &&
      subaccount?.id &&
      vinId &&
      newFecha &&
      oldCalle &&
      oldColumna &&
      oldNivel &&
      newCalle &&
      newColumna &&
      newNivel
    ) {
      return true;
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Preencha todos os campos obrigatórios.",
        life: 3000,
      });
      setLoading(false);
      return false;
    }
  };

  const postApiCambiarUbicacionVin = async () => {
    const payload = {
      codPais: securityFilters.securityValues.country.id,
      codCliente: securityFilters.securityValues.client.id,
      descripcionCliente: securityFilters.securityValues.client.descripcion,
      codCuenta: securityFilters.securityValues.account.id,
      codSubcuenta: securityFilters.securityValues.subaccount.id,
      vin: ingreseElVin.id,
      fecha: autoForm.fecha,
      oldCalle: vin.calle,
      oldColumna: vin.columna,
      oldNivel: vin.nivel,
      newCalle: autoForm.calle,
      newColumna: autoForm.columna,
      newNivel: autoForm.nivel,
      chkPagoVin: false,
      activarCTRC: false,
    };
    console.log(payload);
    const ValidAllFields = hasValidAllFields();
    if (ValidAllFields) {
      setLoading(true);
      try {
        const { status, data  } = await sipcoAxiosService.post(
          "/transferencia-ubicacion/cambiar-ubicacion-vin",
          payload
        );

        if (status === 200) {
          toast.current.show({
            position: "bottom-left",
            severity: "success",
            summary: "Atención",
            detail: "su transaccion se realizó con éxito",
            life: 3000,
          });
          getApiIngreseElVin();
          clearAutoForm();
        } else {
          toast.current.show({
            position: "bottom-left",
            severity: "error",
            summary: status,
            detail: `${LanguageProviderString({ id: "17344", alt: "La operación no se pudo completar", user })} - ${data}`,
            life: 3000,
          });
        }
      } catch (error) {
        console.error(error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const aceptarUbcacion = () => {
    confirmDialog({
      header: <LanguageProvider id={"16239"} alt="Atención" />,
      message: (
        <LanguageProvider
          id={"13275"}
          alt="¿Desea procesar los cambios realizados?"
        />
      ),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => postApiCambiarUbicacionVin(),
      reject: null,
    });
  };

  useEffect(() => {
    console.log("useEffect ingreseElVin, localizarVin");
    if (ingreseElVin !== null && localizarVin !== null) {
      const vin = {
        id: ingreseElVin.id,
        marca: ingreseElVin.marca.descripcion,
        modelo:
          ingreseElVin.modelo.descripcion + ` (${ingreseElVin.modelo.id})`,
        calle: localizarVin.calle,
        columna: localizarVin.columna,
        color: ingreseElVin.color.descripcion,
        nivel: localizarVin.nivel,
      };
      setVin(vin);
    }
  }, [ingreseElVin, localizarVin]);

  useEffect(() => {    
    if (ingreseElVin?.id !== vin.id && ingreseElVin?.id !== undefined) {
      clearVin();
      setDisableNuevaUbicacion(true);
      setAutoForm(new AutoForm());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vin.id]);

  useEffect(() => {
    console.log("useEffect lineasCarga");
    setAutoForm(new AutoForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineasCarga]);

  useEffect(() => {
    console.log("useEffect securityFilters");
    clearAll();
  }, [securityFilters]);

  return (
    <div>
      <div className="filter-options">
        <div className="sipco-options-line">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"662"} alt="Ingrese el VIN" />
            </label>
            <div className="divide">
              <div className={"sipco-option-divide"}>
                <SipcoInputText
                  value={vin.id}
                  onChange={(e) => {
                    setVin({ ...vin, id: e.target.value });
                    setInvalidVin(false);
                  }}
                  invalid={invalidVin}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getApiIngreseElVin();
                    }
                  }}
                />
              </div>
              <div className={"sipco-option-button"}>
                <Button
                  onClick={getApiIngreseElVin}
                  loading={loading}
                  label="BUSCAR"
                />
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <SkeletonBodyForm />
        ) : (
          <div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"396"} alt="Marca" />
                </label>
                <SipcoInputText value={vin.marca} readOnly />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"990"} alt="Calle" />
                </label>
                <SipcoInputText value={vin.calle} readOnly />
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2139"} alt="Modelo" />
                </label>
                <SipcoInputText value={vin.modelo} readOnly />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"991"} alt="Columna" />
                </label>
                <SipcoInputText value={vin.columna} readOnly={true} />
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2140"} alt="Color" />
                </label>
                <SipcoInputText value={vin.color} readOnly />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"992"} alt="Nivel" />
                </label>
                <SipcoInputText value={vin.nivel} readOnly />
              </div>
            </div>
          </div>
        )}
        {/* <div className="sipco-options-line">
          <div className="RadioButtonFilter">
            <label>
              <LanguageProvider id={"21367"} alt="Pago?" />
            </label>
            <Checkbox checked={false}></Checkbox>
          </div>
        </div> */}
      </div>
      <Panel header={<LanguageProvider id="663" alt="Nueva Ubicación" />}>
        <div style={{ display: "flex" }}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="RadioButtonFilter">
                <label>
                  <LanguageProvider id={"11313"} alt="Lineas de Carga?" />
                </label>
                <Checkbox
                  checked={lineasCarga}
                  onChange={(e) => setLineasCarga(e.checked)}
                  disabled={disableNuevaUbicacion}
                ></Checkbox>
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"990"} alt="Calle" />
                </label>
                <SipcoAutocomplete
                  value={autoForm.calle}
                  suggestions={calle}
                  completeMethod={searchCalle}
                  onChange={(e) => setAutoForm({ ...autoForm, calle: e.value })}
                  dropdown
                  disabled={disableNuevaUbicacion}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"991"} alt="Columna" />
                </label>
                <SipcoAutocomplete
                  value={autoForm.columna}
                  suggestions={columna}
                  completeMethod={searchColumna}
                  onChange={(e) =>
                    setAutoForm({ ...autoForm, columna: e.value })
                  }
                  dropdown
                  disabled={disableNuevaUbicacion}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"992"} alt="Nivel" />
                </label>
                <SipcoAutocomplete
                  value={autoForm.nivel}
                  suggestions={nivel}
                  completeMethod={searchNivel}
                  onChange={(e) => setAutoForm({ ...autoForm, nivel: e.value })}
                  dropdown
                  disabled={disableNuevaUbicacion}
                />
              </div>
            </div>
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"14407"} alt="Fecha de Transferencia" />
                </label>
                <SipcoCalendar
                  value={autoForm.fecha}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                  onChange={(e) =>
                    setAutoForm({ ...autoForm, fecha: e.value })
                  }
                  disabled={disableNuevaUbicacion}                  
                />
              </div>
            </div>
          </div>
        </div>
      </Panel>
      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          loading={loading}
          onClick={aceptarUbcacion}
          label={LanguageProvider({
            id: "23827",
            alt: "Aceptar",
          })}
          disabled={disableNuevaUbicacion}
        />
        <Button
          loading={loading}
          onClick={clearAll}
          label={LanguageProvider({
            id: "23826",
            alt: "Cancelar",
          })}
          disabled={disableNuevaUbicacion}
        />
      </div>
    </div>
  );
};
export default TransferenciaVin;

class AutoForm {
  calle: string;
  columna: string;
  nivel: string;
  fecha: Date;

  constructor() {
    this.calle = "";
    this.columna = "";
    this.nivel = "";
    this.fecha = new Date();
  }
}
