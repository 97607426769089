import "./HistoriaVin.scss";

import * as XLSX from "xlsx";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function HistoriaVin() {
  const SCREEN_CODE = "con0036_historia_del_vin";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const dt = useRef(null);
  usePageViews();

  const filterColumnsIdServ = [
    1491, 16478, 1151, 16796, 16797, 4243, 9965, 396, 2139, 4724, 2140, 1317,
    2205, 5701, 5788, 1684, 31, 1850, 1849, 16798, 2496, 4137, 4587, 17012,
    3568, 4061, 24997,
  ];
  const filterColumnsIdRep = [
    1491, 16478, 20804, 16796, 16797, 4243, 1384, 23888, 2139, 4724, 2140,
    11250, 2205, 5701, 5788, 2020, 3756, 3773, 8818, 2496, 22724, 22725, 17012,
    3568, 4061, 24997,
  ];
  const columnsNames = [];
  const columnsNamesRepuestos = [];

  for (const element of filterColumnsIdServ) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }
  for (const element of filterColumnsIdRep) {
    columnsNamesRepuestos.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    taller: null,
    estado: null,
    vin: "",
    aplicarFiltroFecha: false,
    tipoFiltro: "Fecha Ingreso",
    fechaDesde: null,
    fechaHasta: null,
  });

  const [loadingTaller, setLoadingTaller] = useState(false);
  const [taller, setTaller] = useState([]);
  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/historia-del-vin/combo/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
            },
          }
        );
        if (status === 200) {
          setTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
    return [];
  }

  const [loadingEstados, setLoadingEstados] = useState(false);
  const [estados, setEstados] = useState([]);
  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/historia-del-vin/combo/estado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setEstados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
    return [];
  }

  const [loadingServReporteData, setLoadingServReporteData] = useState(false);
  const [serviciosReporteData, setServiciosReporteData] = useState(null);
  async function loadServicesReport() {
    setLoadingServReporteData(true);
    try {
      let filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        codTaller: filter?.taller?.id || null,
        vin: filter?.vin || null,
        estado: null,
        estadoDescripcion: filter?.estado?.descripcion || null,
        rangeFecha: [filter?.fechaDesde, filter?.fechaHasta],
        filtroPorFecha: filter.tipoFiltro,
      };
      if (filter.estado != null && filter.estado.id != 0) {
        filters.estado = filter.estado.id;
      }
      const { status, data } = await sipcoAxiosService.post(
        "historia-del-vin/findServiciosBy",
        filters
      );
      if (status === 200) {
        setServiciosReporteData(data);
        setFilterExport();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingServReporteData(false);
    }
  }

  const [loadingRepReporteData, setLoadingRepReporteData] = useState(false);
  const [repuestosReporteData, setRepuestosReporteData] = useState(null);
  async function loadRepuestosReport() {
    setLoadingRepReporteData(true);
    try {
      let filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        codTaller: filter?.taller?.id || null,
        vin: filter?.vin || null,
        estado: null,
        estadoDescripcion: filter?.estado?.descripcion || null,
        rangeFecha: [filter?.fechaDesde, filter?.fechaHasta],
        filtroPorFecha: filter.tipoFiltro,
      };

      if (filter.estado != null && filter.estado.id != 0) {
        filters.estado = filter.estado.id;
      }
      const { status, data } = await sipcoAxiosService.post(
        "historia-del-vin/findRepuestosBy",
        filters
      );
      if (status === 200) {
        setRepuestosReporteData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRepReporteData(false);
    }
  }

  function getTableHeaders() {
    const columns = dt.current?.props.children || [];
    return columns.map((col) => ({
      header: col.props.header,
      field: col.props.field,
    }));
  }

  function formatExportData(data, headers) {
    return data.map((row) => {
      let formattedRow = {};
      headers.forEach(({ header, field }) => {
        formattedRow[header] = row[field];
      });
      return formattedRow;
    });
  }

  function exportExcel() {
    const headers = getTableHeaders();
    const servicesData = formatExportData(dataServicios || [], headers);
    const repuestosData = formatExportData(dataRepuestos || [], headers);

    const workbook = XLSX.utils.book_new();

    if (servicesData.length > 0) {
      const ws1 = XLSX.utils.json_to_sheet(servicesData);
      XLSX.utils.book_append_sheet(workbook, ws1, "Servicios");
    }

    if (repuestosData.length > 0) {
      const ws2 = XLSX.utils.json_to_sheet(repuestosData);
      XLSX.utils.book_append_sheet(workbook, ws2, "Repuestos");
    }

    XLSX.writeFile(workbook, "ValeRepuestos.xlsx");
  }

  useEffect(() => {
    async function initialize() {
      try {
        const talleres = await loadTaller();
        const estados = await loadEstados();
        setFilter({
          ...filter,
          taller: talleres[0],
          estado: estados[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  const [filterParamsExport, setFilterParamsExport] = useState([]);
  function setFilterExport() {
    const filters = [
      { 1499: securityFilters.securityValues.country?.descripcion },
      { 488: securityFilters.securityValues.client?.descripcion },
      { 1791: securityFilters.securityValues.account?.descripcion },
      { 4791: securityFilters.securityValues.subaccount?.descripcion },
      { 327: filter.taller.descripcion },
      { 1151: filter.estado.descripcion },
      { 1491: filter.vin },
      { 3233: filter.aplicarFiltroFecha ? "S" : "N" },
      {
        1317:
          filter.tipoFiltro === "Fecha Ingreso"
            ? "Fecha Ingreso"
            : "Fecha Facturacion",
      },
      { 467: filter.fechaDesde },
      { 468: filter.fechaHasta },
    ];
    setFilterParamsExport(filters);
  }

  const dataServicios = convertDateObjects(serviciosReporteData?.filas);
  const dataRepuestos = convertDateObjects(repuestosReporteData?.filas);

  return (
    <div className="historia-vin">
      <Filter
        onSearch={() => {
          loadServicesReport();
          loadRepuestosReport();
        }}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id="743" alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="327" alt="Taller" />
                </label>
                <SipcoDropdown
                  value={filter.taller}
                  options={taller}
                  onChange={(e) => {
                    setFilter({ ...filter, taller: e.value });
                  }}
                  filter
                  optionLabel="descripcion"
                  loading={loadingTaller}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="1151" alt="Estados" />
                </label>
                <SipcoDropdown
                  value={filter.estado}
                  options={estados}
                  onChange={(e) => {
                    setFilter({ ...filter, estado: e.value });
                  }}
                  filter
                  optionLabel="descripcion"
                  loading={loadingEstados}
                  showClear
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="1491" alt="VIN" />
                </label>
                <SipcoInputText
                  value={filter.vin}
                  onChange={(e) => {
                    setFilter({ ...filter, vin: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option-check"}>
                <Checkbox
                  checked={filter.aplicarFiltroFecha}
                  onChange={(e) => {
                    if (e.checked) {
                      setFilter({
                        ...filter,
                        aplicarFiltroFecha: e.checked,
                        fechaDesde: new Date(),
                        fechaHasta: new Date(),
                      });
                    } else {
                      setFilter({
                        ...filter,
                        aplicarFiltroFecha: e.checked,
                        fechaDesde: null,
                        fechaHasta: null,
                      });
                    }
                  }}
                />
                <label>
                  <LanguageProvider id="3233" alt="Aplicar Filtro por Fecha" />
                </label>
              </div>
              <div className="RadioButtonFilter">
                <RadioButton
                  checked={filter.tipoFiltro === "Fecha Ingreso"}
                  onChange={(e) => {
                    setFilter({ ...filter, tipoFiltro: "Fecha Ingreso" });
                  }}
                  disabled={!filter.aplicarFiltroFecha}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id="3232" alt="Fecha Ingreso" />
                </label>
                <RadioButton
                  checked={filter.tipoFiltro === "Fecha Facturacion"}
                  onChange={(e) => {
                    setFilter({ ...filter, tipoFiltro: "Fecha Facturacion" });
                  }}
                  disabled={!filter.aplicarFiltroFecha}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={"3231"} alt="Fecha Facturación" />
                </label>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="467" alt="Desde :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaDesde}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaDesde: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                  disabled={!filter.aplicarFiltroFecha}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="468" alt="Hasta :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaHasta}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaHasta: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                  disabled={!filter.aplicarFiltroFecha}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="1684" alt="Servicios" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  dataArray={[
                    {
                      data: dataServicios,
                      columns: columnsNames,
                      tab: { id: 1684, alt: "Servicios" },
                    },
                    {
                      data: dataRepuestos,
                      columns: columnsNamesRepuestos,
                      tab: { id: 2248, alt: "Repuestos" },
                    },
                  ]}
                  screenName={"Historia del Vin"}
                  pdf={false}
                  csv={false}
                  filterProps={filterParamsExport}
                  filter={true}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          ref={dt}
          value={dataServicios}
          loading={loadingServReporteData}
          size={"small"}
          showGridlines
          stripedRows
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn
            field="0"
            header={<LanguageProvider id="1491" alt="VIN" />}
            sortable
            filter
          />
          <SipcoColumn
            field="1"
            header={<LanguageProvider id="16478" alt="Número de ingreso" />}
            sortable
            filter
          />
          <SipcoColumn
            field="2"
            header={<LanguageProvider id="1151" alt="Estados" />}
            sortable
            filter
          />
          <SipcoColumn
            field="3"
            header={<LanguageProvider id="16796" alt="Fecha de Facturación" />}
            sortable
            filter
          />
          <SipcoColumn
            field="4"
            header={
              <LanguageProvider id="16797" alt="Fecha de ingreso taller" />
            }
            sortable
            filter
          />
          <SipcoColumn
            field="5"
            header={<LanguageProvider id="4243" alt="Fecha Egreso" />}
            sortable
            filter
          />
          <SipcoColumn
            field="6"
            header={<LanguageProvider id="9965" alt="Estadías" />}
            sortable
            filter
          />
          <SipcoColumn
            field="7"
            header={<LanguageProvider id="396" alt="Marca" />}
            sortable
            filter
          />
          <SipcoColumn
            field="8"
            header={<LanguageProvider id="2139" alt="Modelo" />}
            sortable
            filter
          />
          <SipcoColumn
            field="9"
            header={<LanguageProvider id="4724" alt="Patente" />}
            sortable
            filter
          />
          <SipcoColumn
            field="10"
            header={<LanguageProvider id="2140" alt="Color" />}
            sortable
            filter
          />
          <SipcoColumn
            field="11"
            header={<LanguageProvider id="1317" alt="Tipo" />}
            sortable
            filter
          />
          <SipcoColumn
            field="12"
            header={<LanguageProvider id="2205" alt="Saneamiento" />}
            sortable
            filter
          />
          <SipcoColumn
            field="13"
            header={<LanguageProvider id="5701" alt="Nro. Orden" />}
            sortable
            filter
          />
          <SipcoColumn
            field="14"
            header={<LanguageProvider id="5788" alt="Línea" />}
            sortable
            filter
          />
          <SipcoColumn
            field="15"
            header={<LanguageProvider id="1684" alt="Servicios" />}
            sortable
            filter
          />
          <SipcoColumn
            field="16"
            header={<LanguageProvider id="31" alt="Descripción" />}
            sortable
            filter
          />

          <SipcoColumn
            field="17"
            header={<LanguageProvider id="1850" alt="Venta" />}
            sortable
            filter
          />
          <SipcoColumn
            field="18"
            header={<LanguageProvider id="1849" alt="Compra" />}
            sortable
            filter
          />
          <SipcoColumn
            field="19"
            header={<LanguageProvider id="16798" alt="Horas estimadas" />}
            sortable
            filter
          />
          <SipcoColumn
            field="20"
            header={<LanguageProvider id="2496" alt="Origen" />}
            sortable
            filter
          />
          <SipcoColumn
            field="21"
            header={<LanguageProvider id="4137" alt="Prestación" />}
            sortable
            filter
          />
          <SipcoColumn
            field="22"
            header={<LanguageProvider id="4587" alt="Subprestación" />}
            sortable
            filter
          />
          <SipcoColumn
            field="23"
            header={<LanguageProvider id="17012" alt="Incidentes" />}
            sortable
            filter
          />
          <SipcoColumn
            field="24"
            header={<LanguageProvider id="3568" alt="Imputado a" />}
            sortable
            filter
          />
          <SipcoColumn
            field="25"
            header={<LanguageProvider id="4061" alt="Buque" />}
            sortable
            filter
          />
          <SipcoColumn
            field="26"
            header={<LanguageProvider id="24997" alt="Fecha Bajada Buque" />}
            sortable
            filter
          />
        </SipcoDataTable>
      </Panel>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id={"2248"} alt="Repuestos" />
              </span>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={dataRepuestos}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          loading={loadingRepReporteData}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn
            field="0"
            header={<LanguageProvider id="1491" alt="VIN" />}
            sortable
            filter
          />
          <SipcoColumn
            field="1"
            header={<LanguageProvider id="16478" alt="Número de ingreso" />}
            sortable
            filter
          />
          <SipcoColumn
            field="2"
            header={<LanguageProvider id="20804" alt="Estados" />}
            sortable
            filter
          />
          <SipcoColumn
            field="3"
            header={<LanguageProvider id="16796" alt="Fecha de Facturación" />}
            sortable
            filter
          />
          <SipcoColumn
            field="4"
            header={
              <LanguageProvider id="16797" alt="Fecha de ingreso taller" />
            }
            sortable
            filter
          />
          <SipcoColumn
            field="5"
            header={<LanguageProvider id="4243" alt="Fecha Egreso" />}
            sortable
            filter
          />
          <SipcoColumn
            field="6"
            header={<LanguageProvider id="1384" alt="Estadías" />}
            sortable
            filter
          />
          <SipcoColumn
            field="7"
            header={<LanguageProvider id="23888" alt="Marca" />}
            sortable
            filter
          />
          <SipcoColumn
            field="8"
            header={<LanguageProvider id="2139" alt="Modelo" />}
            sortable
            filter
          />
          <SipcoColumn
            field="9"
            header={<LanguageProvider id="4724" alt="Patente" />}
            sortable
            filter
          />
          <SipcoColumn
            field="10"
            header={<LanguageProvider id="2140" alt="Color" />}
            sortable
            filter
          />
          <SipcoColumn
            field="11"
            header={<LanguageProvider id="11250" alt="Tipo" />}
            sortable
            filter
          />
          <SipcoColumn
            field="12"
            header={<LanguageProvider id="2205" alt="Saneamiento" />}
            sortable
            filter
          />
          <SipcoColumn
            field="13"
            header={<LanguageProvider id="5701" alt="Nro. Orden" />}
            sortable
            filter
          />
          <SipcoColumn
            field="14"
            header={<LanguageProvider id="5788" alt="Línea" />}
            sortable
            filter
          />
          <SipcoColumn
            field="15"
            header={<LanguageProvider id="2020" alt="Pieza" />}
            sortable
            filter
          />
          <SipcoColumn
            field="16"
            header={<LanguageProvider id="3756" alt="Descripción" />}
            sortable
            filter
          />
          <SipcoColumn
            field="17"
            header={<LanguageProvider id="3773" alt="Cantidad" />}
            sortable
            filter
          />
          <SipcoColumn
            field="18"
            header={<LanguageProvider id="8818" alt="Importe Neto" />}
            sortable
            filter
          />
          <SipcoColumn
            field="19"
            header={<LanguageProvider id="2496" alt="Origen" />}
            sortable
            filter
          />
          <SipcoColumn
            field="20"
            header={<LanguageProvider id="22724" alt="Prestacion" />}
            sortable
            filter
          />
          <SipcoColumn
            field="21"
            header={<LanguageProvider id="22725" alt="Subprestacion" />}
            sortable
            filter
          />
          <SipcoColumn
            field="22"
            header={<LanguageProvider id="17012" alt="Incidentes" />}
            sortable
            filter
          />
          <SipcoColumn
            field="23"
            header={<LanguageProvider id="3568" alt="Imputado a" />}
            sortable
            filter
          />
          <SipcoColumn
            field="24"
            header={<LanguageProvider id="4061" alt="Buque" />}
            sortable
            filter
          />
          <SipcoColumn
            field="25"
            header={<LanguageProvider id="24997" alt="Fecha Bajada Buque" />}
            sortable
            filter
          />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default HistoriaVin;
