import ExcelJS from "exceljs";
import { LanguageProviderString } from "../language-provider-string";
import logoBase64 from "../../../assets/excel-templates/imageBase64";
import { saveAs } from "file-saver";

interface DataTableResponse {
  data: any[]; // valores das linhas
  columns: any[]; // ids das colunas
  tab: { id?: number, alt?: string }; // ids das tabs(aba) da plhanilha
}

interface ExportPropsType {
  data?: any[];
  dataArray?: DataTableResponse[];
  columns?: any[];
  screenName: string;
  filterProps?: any[];
  filter?: boolean;
  user: any;
}const ExportExcelDataTableTransportOrder = async ({
  data,
  dataArray,
  columns,
  screenName,
  filterProps = [],
  filter = false,
  user,
}: ExportPropsType): Promise<void> => {
  const workbook = new ExcelJS.Workbook();

  let dataToProcess = [];
  if (dataArray) {
    dataToProcess = dataArray;
  } else {
    dataToProcess = [{ data: data, tab: { alt: "data" }, columns: columns }];
  }

  dataToProcess.forEach((dt) => {
    const worksheet = workbook.addWorksheet(LanguageProviderString({
      user,
      id: dt.tab,
      alt: dt.tab.alt,
    }));

    // Adicionar imagem
    const imageId = workbook.addImage({
      base64: logoBase64,
      extension: "png",
    });
    worksheet.addImage(imageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 1115, height: 40 },
    });

    // Informações básicas na parte superior
    worksheet.mergeCells("A3:G3");
    worksheet.getCell("A3").value = "CEVA LOGISTICS";
    worksheet.getCell("A3").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.mergeCells("A4:G4");
    worksheet.getCell("A4").value = screenName;
    worksheet.getCell("A4").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.mergeCells("A5:G5");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(user?.idioma?.codigoIso);
    const formattedTime = currentDate.toLocaleTimeString(user?.idioma?.codigoIso);

    worksheet.getCell("A5").value = `${LanguageProviderString({
      user,
      id: "9369",
      alt: "Usuário:",
    })} ${user?.nombre || "User Name"} - ${LanguageProviderString({
      user,
      id: "191",
      alt: "Data:",
    })} ${formattedDate} - ${LanguageProviderString({
      user,
      id: "2201",
      alt: "Hora",
    })}: ${formattedTime}`;
    worksheet.getCell("A5").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Informações básicas na parte superior
    worksheet.mergeCells("A7:E7");
    worksheet.getCell("A7").value = "Datos relacionados a TO (TMS005)";
    worksheet.getCell("A7").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFE3ECF8" },
    };
    worksheet.getCell("A7").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.mergeCells("F7:G7");
    worksheet.getCell("F7").value = "Datos Relacionados a TOC (TMS005_TOC)";
    worksheet.getCell("F7").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFD99694" },
    };
    worksheet.getCell("F7").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.mergeCells("H7:P7");
    worksheet.getCell("H7").value = "Datos relacionados a TO (TMS005)";
    worksheet.getCell("H7").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFE3ECF8" },
    };
    worksheet.getCell("H7").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.mergeCells("Q7:R7");
    worksheet.getCell("Q7").value = "Liberacion Remito Ficticio a BSM";
    worksheet.getCell("Q7").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFD7E4BD" },
    };
    worksheet.getCell("Q7").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.mergeCells("S7:AC7");
    worksheet.getCell("S7").value = "Datos relacionados a respuesta de armado de viaje (TMS028)";
    worksheet.getCell("S7").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFF2DCDB" },
    };
    worksheet.getCell("S7").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.mergeCells("AD7:AH7");
    worksheet.getCell("AD7").value = "Datos relacionados a remito definitivo (TMS005_LR)";
    worksheet.getCell("AD7").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFCD5B5" },
    };
    worksheet.getCell("AD7").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.mergeCells("AI7:AJ7");
    worksheet.getCell("AI7").value = "Liberacion Remito Definitivo a BSM";
    worksheet.getCell("AI7").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFD7E4BD " },
    };
    worksheet.getCell("AI7").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.mergeCells("AK7:AN7");
    worksheet.getCell("AK7").value = "Datos relacionados a TMS005_LR";
    worksheet.getCell("AK7").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFB7DEE8" },
    };
    worksheet.getCell("AK7").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.mergeCells("AO7:CE7");
    worksheet.getCell("AO7").value = "Datos complementares SIPCO";
    worksheet.getCell("AO7").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFA6A6A6" },
    };
    worksheet.getCell("AO7").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Cabeçalho
    const header = dt.columns.map((column) => column.label);
    worksheet.addRow(header);

    // Estilizar cabeçalho
    const headerRow = worksheet.getRow(8);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "003366" },
      };
      cell.font = { bold: true, color: { argb: "FFFFFF" } };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    const columnColors = [
      { start: "A", end: "E", color: "FFE3ECF8" }, 
      { start: "F", end: "G", color: "FFD99694" },
      { start: "H", end: "P", color: "FFE3ECF8" },
      { start: "Q", end: "R", color: "FFD7E4BD" },
      { start: "S", end: "AC", color: "FFF2DCDB" },
      { start: "AD", end: "AH", color: "FFFCD5B5" },
      { start: "AI", end: "AJ", color: "FFD7E4BD" },
      { start: "AK", end: "AN", color: "FFB7DEE8" },
      { start: "AO", end: "CE", color: "FFA6A6A6" },
    ];

    // Função para obter a cor de uma coluna específica
    const columnLetterToNumber = (columnLetter) => {
      let number = 0;
      for (let i = 0; i < columnLetter.length; i++) {
        number = number * 26 + (columnLetter.charCodeAt(i) - 64);
      }
      return number;
    };
    
    const getColumnColor = (columnLetter) => {
      const columnNumber = columnLetterToNumber(columnLetter);
      for (const group of columnColors) {
        const startNumber = columnLetterToNumber(group.start);
        const endNumber = columnLetterToNumber(group.end);
        if (columnNumber >= startNumber && columnNumber <= endNumber) {
          return group.color;
        }
      }
      return "FFFFFFFF";
    };
    
    // Adicionar dados
    dt.data.forEach((row, rowIndex) => {
      const excelRow = worksheet.addRow(row.map((cellValue) => cellValue || ""));
    
      // Aplicar a cor a cada célula da linha, com base na coluna
      excelRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        const columnLetter = worksheet.getColumn(colNumber).letter;
        const cellColor = getColumnColor(columnLetter); 
    
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: cellColor },
        };
      });
    });
    // Adicionar linhas em branco e resumo
    worksheet.addRow([]);
    worksheet.addRow([
      `${LanguageProviderString({
        user,
        id: "3116",
        alt: "Quantidade de Registros",
      })}:`,
      dt.data.length,
    ]);


    // Adicionar filtros
    const addFiltersToWorksheet = (worksheet: ExcelJS.Worksheet): void => {
      filterProps.forEach((filter) => {
        const [key, value] = Object.entries(filter)[0] || ["", ""];
        worksheet.addRow([
          LanguageProviderString({ user, id: key }),
          value || "",
        ]);
      });
    };

    if (filter) {
      worksheet.addRow([]);
      worksheet.addRow([
        LanguageProviderString({ user, id: "5583", alt: "Filtros" }),
      ]);
      worksheet.addRow([]);
      addFiltersToWorksheet(worksheet);
    }

    // Ajustar largura das colunas
    worksheet.columns.forEach((column, index) => {
      const labelLength = dt.columns[index]?.label?.length || 0;
      column.width = Math.max(labelLength + 5, 10);
    });

  });

  try {
    // Gerar e salvar o arquivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const EXCEL_EXTENSION = ".xlsx";
    saveAs(new Blob([buffer]), `${screenName}_export${EXCEL_EXTENSION}`);
  } catch (error) {
    console.error("Erro ao exportar arquivo Excel:", error);
  }
};

export default ExportExcelDataTableTransportOrder;
