import "./scss/filter.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { ReactNode, useState } from "react";

import { Button } from "primereact/button";
import { FitlerSecuritySection } from "./components/FitlerSecuritySection";
import { LanguageProvider } from "../language-provider";

export interface FilterSecurityOptions {
  country?: boolean;
  client?: boolean;
  account?: boolean;
  subaccount?: boolean;
  allClientsOptions?: boolean;
  allSubaccountsOptions?: boolean;
  screenCode?: string;
}

export interface FilterOptions {
  urlApi?: string;
  parametros?: string[];
}
export interface FilterProps {
  onSearch?: () => void;
  securityOptions?: FilterSecurityOptions;
  children?: ReactNode;
}

export function Filter(props: FilterProps): React.ReactElement {
  const { children } = props;
  const [activeIndex, setActiveIndex] = useState<any>([0]);

  const CustomHeader = () => (
    <div className="">
      <LanguageProvider id="7795" alt="Filtrar por" />
      {props.onSearch != null && (
        <Button
          label={LanguageProvider({ id: "9665", alt: "Buscar" })}
          onClick={async (e) => {
            props.onSearch();
            e.preventDefault();
            e.stopPropagation();
          }}
          icon="pi pi-search"
          iconPos="right"
          raised
          tooltip={LanguageProvider({ id: "9665", alt: "Buscar" })}
          tooltipOptions={{ position: "bottom" }}
        />
      )}
    </div>
  );

  const tabCount = React.Children.toArray(children).length + 1; // Número total de abas
  let alwaysOpenTabs = [];

  try {
    alwaysOpenTabs = Array.from({ length: tabCount }, (_, i) => i);
  } catch (error) {
    alwaysOpenTabs = [0];
  }

  return (
    <div className="sipco-filter">
      <Accordion
        multiple
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <AccordionTab header={<CustomHeader />}>
          <Accordion multiple activeIndex={alwaysOpenTabs}>
            {props.securityOptions && (
              <AccordionTab
                header={LanguageProvider({ id: "65", alt: "Segurança" })}
              >
                <FitlerSecuritySection {...props.securityOptions} />
              </AccordionTab>
            )}
            {children}
          </Accordion>
        </AccordionTab>
      </Accordion>
    </div>
  );
}
