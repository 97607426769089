import "./Remitos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export function Remitos() {
  const SCREEN_CODE = "con0021_remitos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const filterColumnsId = [
    2131, 54, 266, 1491, 9428, 9620, 14731, 14722, 2375, 13534, 14735, 932,
    2892, 3344, 4216, 8299, 10774, 2496, 396, 2139, 2140, 1492, 1285, 10540,
    16243, 16505, 292, 295, 16926, 1617, 16757, 13338, 16288, 2892, 1617, 159,
    164, 3186, 10502, 13594, 1144, 1284, 16868, 16869, 5720, 8642, 2015, 818,
    1847, 10495, 2015, 1847, 754, 4589, 17759, 18099,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [validCompletarDatosDialog, setValidationDatosDialog] = useState(false);
  const [validDesdeMayorDialog, setValidDesdeMayorDialog] = useState(false);

  function validationFields() {
    try {
      if (!filter?.letraDesde || !filter?.letraHasta) {
        setValidationDatosDialog(true);
        return;
      }
      if (Number(filter?.puntoVentaDesde) > Number(filter?.puntoVentaHasta)) {
        setValidDesdeMayorDialog(true);
        return;
      } else if (Number(filter?.numeroDesde) > Number(filter?.numerHasta)) {
        setValidDesdeMayorDialog(true);
        return;
      }

      return false;
    } catch (error) {
      console.error(error);
      return true;
    }
  }

  const handleValidationClose = () => {
    setValidationDatosDialog(false);
    setValidDesdeMayorDialog(false);
  };

  const [filter, setFilter] = useState({
    letraDesde: "R",
    letraHasta: "R",
    liberacion: false,
    liberacionDesde: null,
    liberacionHasta: null,
    puntoVentaDesde: "0",
    puntoVentaHasta: "9999",
    despacho: false,
    despachoDesde: null,
    despachoHasta: null,
    numeroDesde: "0",
    numerHasta: "99999999",
    cRetorno: false,
    cRetornoDesde: null,
    cRetornoHasta: null,
    fechaEmisionDesde: new Date(),
    fechaEmisionHasta: new Date(),
    incluirOpPendientes: false,
    tipoRemito: [],
    mercados: [],
    estados: [],
    listarVeiculosCoDanos: null,
    concesionarios: [],
    dirEntrega: [],
  });

  const [loadingTipoRemito, setLoadingTipoRemito] = useState(false);
  const [tipoRemito, setTipoRemito] = useState([]);
  async function loadTipoRemito() {
    setLoadingTipoRemito(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/remitos/combo/remitos-sin-anulacion",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setTipoRemito(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoRemito(false);
    }
    return [];
  }

  const [loadingMercado, setLoadingMercado] = useState(false);
  const [mercado, setMercado] = useState([]);
  async function loadMercado() {
    setLoadingMercado(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/remitos/combo/mercado",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setMercado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercado(false);
    }
    return [];
  }

  const [loadingEstadoRemito, setLoadingEstadoRemito] = useState(false);
  const [estadoRemito, setEstadoRemito] = useState([]);
  async function loadEstadoRemito() {
    setLoadingEstadoRemito(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/remitos/combo/estado-remito",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setEstadoRemito(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadoRemito(false);
    }
    return [];
  }

  const [loadingConcesionario, setLoadingConcesionario] = useState(false);
  const [concesionario, setConcesionario] = useState([]);
  async function loadConcesionarios() {
    setLoadingConcesionario(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/remitos/combo/concesionario",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );
        if (status === 200) {
          setConcesionario(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConcesionario(false);
    }
    return [];
  }

  const [loadingDirEntrega, setLoadingDirEntrega] = useState(false);
  const [direccionesEntrega, setDireccionesEntrega] = useState([]);
  async function loadDirEntrega() {
    setLoadingDirEntrega(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client &&
        filter?.concesionarios?.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/remitos/combo/direccion-entrega",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codConcesionario: filter?.concesionarios[0].id,
            },
          }
        );
        if (status === 200) {
          setDireccionesEntrega(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDirEntrega(false);
    }
    return [];
  }

  const [loadingListVConDano, setLoadingListVConDano] = useState(false);
  const [listVConDano, setListVConDano] = useState([]);
  async function loadListarVeiculosConDanos() {
    setLoadingListVConDano(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/remitos/combo/si-no-ambos",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setListVConDano(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingListVConDano(false);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const tipoRemitos = await loadTipoRemito();
          const mercados = await loadMercado();
          const estadoRemitos = await loadEstadoRemito();
          let concesionarios = [];
          if (securityFilters.securityValues?.client?.descripcion !== "TODOS") {
            concesionarios = await loadConcesionarios();
          }
          const listarVeiculosConDanos = await loadListarVeiculosConDanos();

          setFilter({
            ...filter,
            tipoRemito: tipoRemitos,
            mercados: mercados,
            estados: estadoRemitos,
            concesionarios: concesionarios,
            listarVeiculosCoDanos: listarVeiculosConDanos[2],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [
    securityFilters.securityValues.country,
    securityFilters.securityValues.client,
  ]);

  useEffect(() => {
    async function handleConcesionarionSelecion() {
      if (filter.concesionarios?.length === 1) {
        try {
          const dirEntrega = await loadDirEntrega();

          setFilter({
            ...filter,
            dirEntrega: dirEntrega,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleConcesionarionSelecion();
  }, [filter.concesionarios]);

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporteData, setReporteData] = useState(null);
  async function loadReportData() {
    validationFields();
    try {
      setLoadingReportData(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,

        letras: [filter?.letraDesde || null, filter?.letraHasta || null],
        liberacionBoolean: filter?.liberacion || false,
        fechaLiberacion: [
          filter?.liberacionDesde || new Date(),
          filter?.liberacionHasta || new Date(),
        ],

        ptoVenta: [
          filter?.puntoVentaDesde || "0",
          filter?.puntoVentaHasta || null,
        ],
        despachoBoolean: filter?.despacho || false,
        fechaDespacho: [
          filter.despachoDesde || new Date(),
          filter.despachoHasta || new Date(),
        ],

        numeros: [filter?.numeroDesde || "0", filter?.numerHasta || null],
        cargaRetornoBoolean: filter?.cRetorno || false,
        fechaCargaRetorno: [
          filter?.cRetornoDesde || new Date(),
          filter?.cRetornoHasta || new Date(),
        ],

        rangeFecha: [
          filter?.fechaEmisionDesde || null,
          filter?.fechaEmisionHasta || null,
        ],
        // incluirOpPendientes: filter.incluirOpPendientes

        remitos: filter?.tipoRemito?.map((x) => x.id) || [],
        mercado: filter?.mercados?.map((x) => x.id) || [],
        estadosRemitos: filter?.estados?.map((x) => x.id) || [],
        concesionarios: filter?.concesionarios?.map((x) => x.id) || [],
        dirEntrega: filter?.dirEntrega?.map((x) => x.id) || [],
        listarVehiculosDanos: filter?.listarVeiculosCoDanos?.id || [],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/remitos/report",
        filters
      );

      if (status === 200) {
        setReporteData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  const clientIsTodos =
    securityFilters.securityValues?.client?.descripcion === "TODOS";
  const data = convertDateObjects(reporteData?.reporte?.filas);

  return (
    <div className="remitos">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option-small"}>
                <label>
                  <LanguageProvider id={"849"} alt="Letra" />
                </label>
                <SipcoInputText
                  value={filter.letraDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, letraDesde: e.target.value });
                  }}
                  maxLength={1}
                />
              </div>
              <div className={"sipco-option-small"}>
                <label>
                  <LanguageProvider id={"849"} alt="Letra" />
                </label>
                <SipcoInputText
                  value={filter.letraHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, letraHasta: e.target.value });
                  }}
                  maxLength={1}
                />
              </div>
              <div className={"RadioButtonFilter"}>
                <Checkbox
                  checked={filter.liberacion}
                  onChange={() => {
                    setFilter({ ...filter, liberacion: !filter.liberacion });
                  }}
                />
                <label>
                  <LanguageProvider id={"25188"} alt="Liberacion" />
                </label>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={""} alt="" />
                </label>
                <SipcoCalendar
                  value={filter.liberacionDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, liberacionDesde: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                  disabled={!filter.liberacion}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={""} alt="" />
                </label>
                <SipcoCalendar
                  value={filter.liberacionHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, liberacionHasta: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                  disabled={!filter.liberacion}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option-small"}>
                <label>
                  <LanguageProvider id={"850"} alt="Pto.Venta" />
                </label>
                <SipcoInputText
                  value={filter.puntoVentaDesde}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/\D/g, ""); //numeric
                    setFilter({
                      ...filter,
                      puntoVentaDesde: inputValue,
                    });
                  }}
                  maxLength={4}
                />
              </div>
              <div className={"sipco-option-small"}>
                <label>
                  <LanguageProvider id={"850"} alt="Pto.Venta" />
                </label>
                <SipcoInputText
                  value={filter.puntoVentaHasta}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/\D/g, ""); //numeric
                    setFilter({
                      ...filter,
                      puntoVentaHasta: inputValue,
                    });
                  }}
                  maxLength={4}
                />
              </div>
              <div className={"RadioButtonFilter"}>
                <Checkbox
                  checked={filter.despacho}
                  onChange={() => {
                    setFilter({ ...filter, despacho: !filter.despacho });
                  }}
                />
                <label>
                  <LanguageProvider id={"845"} alt="Despacho" />
                </label>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={""} alt="" />
                </label>
                <SipcoCalendar
                  value={filter.despachoDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, despachoDesde: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                  disabled={!filter.despacho}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={""} alt="" />
                </label>
                <SipcoCalendar
                  value={filter.despachoHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, despachoHasta: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                  disabled={!filter.despacho}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option-small"}>
                <label>
                  <LanguageProvider id={"851"} alt="Número" />
                </label>
                <SipcoInputText
                  value={filter.numeroDesde}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/\D/g, ""); //numeric
                    setFilter({
                      ...filter,
                      numeroDesde: inputValue,
                    });
                  }}
                  maxLength={8}
                />
              </div>
              <div className={"sipco-option-small"}>
                <label>
                  <LanguageProvider id={"851"} alt="Número" />
                </label>
                <SipcoInputText
                  value={filter.numerHasta}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/\D/g, ""); //numeric
                    setFilter({
                      ...filter,
                      numerHasta: inputValue,
                    });
                  }}
                  maxLength={8}
                />
              </div>
              <div className={"RadioButtonFilter"}>
                <Checkbox
                  checked={filter.cRetorno}
                  onChange={() => {
                    setFilter({ ...filter, cRetorno: !filter.cRetorno });
                  }}
                />
                <label>
                  <LanguageProvider id={"16842"} alt="Carga retorno" />
                </label>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={""} alt="" />
                </label>
                <SipcoCalendar
                  value={filter.cRetornoDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, cRetornoDesde: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                  disabled={!filter.cRetorno}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={""} alt="" />
                </label>
                <SipcoCalendar
                  value={filter.cRetornoHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, cRetornoHasta: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                  disabled={!filter.cRetorno}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option-small"}>
                <label>
                  <LanguageProvider id={"21422"} alt="Fecha Emision" />
                </label>
                <SipcoCalendar
                  value={filter.fechaEmisionDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaEmisionDesde: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                />
              </div>
              <div className={"sipco-option-small"}>
                <label>
                  <LanguageProvider id={"2622"} alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.fechaEmisionHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaEmisionHasta: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showIcon
                />
              </div>
              <div className={"RadioButtonFilter"}>
                <Checkbox
                  checked={filter.incluirOpPendientes}
                  onChange={() => {
                    setFilter({
                      ...filter,
                      incluirOpPendientes: !filter.incluirOpPendientes,
                    });
                  }}
                />
                <label>
                  <LanguageProvider
                    id={"17638"}
                    alt="Incluir Operaciones Pendientes"
                  />
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1617"} alt="Tipo remito" />
                </label>
                <SipcoMultiSelect
                  value={filter.tipoRemito}
                  options={tipoRemito}
                  onChange={(e) => {
                    setFilter({ ...filter, tipoRemito: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingTipoRemito}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10774"} alt="Mercados" />
                </label>
                <SipcoMultiSelect
                  value={filter.mercados}
                  options={mercado}
                  onChange={(e) => {
                    setFilter({ ...filter, mercados: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingMercado}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1151"} alt="Estados" />
                </label>
                <SipcoMultiSelect
                  value={filter.estados}
                  options={estadoRemito}
                  onChange={(e) => {
                    setFilter({ ...filter, estados: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingEstadoRemito}
                  showClear
                />
              </div>
              {!clientIsTodos && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"266"} alt="Concesionarios" />
                  </label>
                  <SipcoMultiSelect
                    value={filter.concesionarios}
                    options={concesionario}
                    onChange={(e) => {
                      setFilter({ ...filter, concesionarios: e.value });
                    }}
                    display="chip"
                    filter
                    optionLabel="label"
                    loading={loadingConcesionario}
                    showClear
                  />
                </div>
              )}
              {filter.concesionarios.length === 1 && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"492"} alt="Dirección de Entrega" />
                  </label>
                  <SipcoMultiSelect
                    value={filter.dirEntrega}
                    options={direccionesEntrega}
                    onChange={(e) => {
                      setFilter({ ...filter, dirEntrega: e.value });
                    }}
                    display="chip"
                    filter
                    optionLabel="label"
                    loading={loadingDirEntrega}
                    showClear
                  />
                </div>
              )}
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={"1417"}
                    alt="Listar vehiculos con daños"
                  />
                </label>
                <SipcoDropdown
                  value={filter.listarVeiculosCoDanos}
                  options={listVConDano}
                  onChange={(e) => {
                    setFilter({ ...filter, listarVeiculosCoDanos: e.value });
                  }}
                  filter
                  optionLabel="descripcionFull"
                  loading={loadingListVConDano}
                  showClear
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="275" alt="Remitos" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={LanguageProvider({ id: "275", alt: "RemitoS" })}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data || []}
          paginator
          scrollHeight="flex"
          rows={16}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="single"
        >
          <SipcoColumn
            field="0"
            header={<LanguageProvider id="2131" alt="Fecha" />}
          />
          <SipcoColumn
            field="1"
            header={<LanguageProvider id="54" alt="Cliente" />}
          />
          <SipcoColumn
            field="2"
            header={<LanguageProvider id="2941" alt="Concesionario" />}
          />
          <SipcoColumn
            field="3"
            header={<LanguageProvider id="1491" alt="VIN" />}
          />
          <SipcoColumn
            field="4"
            header={<LanguageProvider id="9428" alt="VIN Indisponible" />}
          />
          <SipcoColumn
            field="5"
            header={<LanguageProvider id="9620" alt="Nacionalización" />}
          />
          <SipcoColumn
            field="6"
            header={
              <LanguageProvider id="14731" alt="Fec. Bloq. Liberacón Remito" />
            }
          />
          <SipcoColumn
            field="7"
            header={<LanguageProvider id="14722" alt="Pendiente Taller" />}
          />
          <SipcoColumn
            field="8"
            header={<LanguageProvider id="2375" alt="Vehículos en Taller" />}
          />
          <SipcoColumn
            field="9"
            header={
              <LanguageProvider
                id="13534"
                alt="Operaciones bloqueantes y no bloqueantes"
              />
            }
          />
          <SipcoColumn
            field="10"
            header={
              <LanguageProvider
                id="14735"
                alt="Op. Pend. Alerta (BLOQUEANTES)"
              />
            }
          />
          <SipcoColumn
            field="11"
            header={<LanguageProvider id="932" alt="Código Remito" />}
          />
          <SipcoColumn
            field="12"
            header={<LanguageProvider id="2892" alt="Playa" />}
          />
          <SipcoColumn
            field="13"
            header={<LanguageProvider id="3344" alt="Estado" />}
          />
          <SipcoColumn
            field="14"
            header={<LanguageProvider id="4216" alt="Boca de Entrega" />}
          />
          <SipcoColumn
            field="15"
            header={<LanguageProvider id="8299" alt="Domicilio" />}
          />
          <SipcoColumn
            field="16"
            header={<LanguageProvider id="10774" alt="Mercados" />}
          />
          <SipcoColumn
            field="17"
            header={<LanguageProvider id="2496" alt="Origen" />}
          />
          <SipcoColumn
            field="18"
            header={<LanguageProvider id="396" alt="Marca" />}
          />
          <SipcoColumn
            field="19"
            header={<LanguageProvider id="2139" alt="Modelo" />}
          />
          <SipcoColumn
            field="20"
            header={<LanguageProvider id="2140" alt="Color" />}
          />
          <SipcoColumn
            field="21"
            header={<LanguageProvider id="1492" alt="Fecha Emisión" />}
          />
          <SipcoColumn
            field="22"
            header={<LanguageProvider id="1285" alt="Llegada" />}
          />
          <SipcoColumn
            field="23"
            header={<LanguageProvider id="10540" alt="Codigo Daño" />}
          />
          <SipcoColumn
            field="24"
            header={<LanguageProvider id="16243" alt="Parte dañada" />}
          />
          <SipcoColumn
            field="25"
            header={<LanguageProvider id="16505" alt="Gravedad daño" />}
          />
          <SipcoColumn
            field="26"
            header={<LanguageProvider id="292" alt="Despachos" />}
          />
          <SipcoColumn
            field="27"
            header={<LanguageProvider id="295" alt="Retorno" />}
          />
          <SipcoColumn
            field="28"
            header={<LanguageProvider id="16926" alt="Fecha carga retorno" />}
          />
          <SipcoColumn
            field="29"
            header={<LanguageProvider id="1617" alt="Tipo remito" />}
          />
          <SipcoColumn
            field="30"
            header={<LanguageProvider id="16757" alt="Leyenda" />}
          />
          <SipcoColumn
            field="31"
            header={<LanguageProvider id="13338" alt="Modelo Largo" />}
          />
          <SipcoColumn
            field="32"
            header={<LanguageProvider id="16288" alt="Coeficiente" />}
          />
          <SipcoColumn
            field="33"
            header={<LanguageProvider id="2892" alt="Playa" />}
          />
          <SipcoColumn
            field="34"
            header={<LanguageProvider id="1617" alt="Tipo remito" />}
          />
          <SipcoColumn
            field="35"
            header={<LanguageProvider id="159" alt="Provincia" />}
          />
          <SipcoColumn
            field="36"
            header={<LanguageProvider id="164" alt="Ciudad" />}
          />
          <SipcoColumn
            field="37"
            header={<LanguageProvider id="3186" alt="Localidad" />}
          />
          <SipcoColumn
            field="38"
            header={<LanguageProvider id="10502" alt="Corredor" />}
          />
          <SipcoColumn
            field="39"
            header={<LanguageProvider id="13594" alt="Descripción Prioridad" />}
          />
          <SipcoColumn
            field="40"
            header={<LanguageProvider id="1144" alt="Bloqueado" />}
          />
          <SipcoColumn
            field="41"
            header={<LanguageProvider id="1284" alt="Liberación" />}
          />
          <SipcoColumn
            field="42"
            header={<LanguageProvider id="16868" alt="Días centro" />}
          />
          <SipcoColumn
            field="43"
            header={<LanguageProvider id="16869" alt="Días transporte" />}
          />
          <SipcoColumn
            field="44"
            header={<LanguageProvider id="5720" alt="Cant. Dias Playa" />}
          />
          <SipcoColumn
            field="45"
            header={<LanguageProvider id="8642" alt="Cant. Total" />}
          />
          <SipcoColumn
            field="46"
            header={<LanguageProvider id="2015" alt="Fecha Max. Entrega" />}
          />
          <SipcoColumn
            field="47"
            header={<LanguageProvider id="818" alt="Feriados" />}
          />
          <SipcoColumn
            field="48"
            header={<LanguageProvider id="1847" alt="Días a Vencer" />}
          />
          <SipcoColumn
            field="49"
            header={
              <LanguageProvider id="10495" alt="Controla Feriados y T. Entr." />
            }
          />
          <SipcoColumn
            field="50"
            header={<LanguageProvider id="2015" alt="Fecha Max. Entrega" />}
          />
          <SipcoColumn
            field="51"
            header={<LanguageProvider id="1847" alt="Días a Vencer" />}
          />
          <SipcoColumn
            field="52"
            header={<LanguageProvider id="754" alt="Transportista" />}
          />
          <SipcoColumn
            field="53"
            header={<LanguageProvider id="4589" alt="Fecha Movimiento" />}
          />
          <SipcoColumn
            field="54"
            header={
              <LanguageProvider id="17759" alt="Fecha Confirmación AAD" />
            }
          />
          <SipcoColumn
            field="55"
            header={<LanguageProvider id="18099" alt="Nro. Check List" />}
          />
        </SipcoDataTable>
      </Panel>
      <div className="dialogs">
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validCompletarDatosDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p>
            <LanguageProvider id="10531" alt="Faltan completar datos" />
          </p>
        </Dialog>
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validDesdeMayorDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p>
            <LanguageProvider
              id="1131"
              alt="La selección en el campo DESDE no puede ser mayor a la seleccion en el campo HASTA"
            />
          </p>
        </Dialog>
      </div>
    </div>
  );
}
export default Remitos;

const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
