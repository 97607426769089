import "./ImpressionHojaRuta.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import ExportButtons from "@shared/components/Export";
import ExportExcelHojaRuta from "@shared/components/excelExportFunc/export-excel-hoja-ruta";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import useAuth from "@shared/AuthContext";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ImpressionHojaRuta() {
  const SCREEN_CODE = "con0046_impresion_hoja_ruta";
  ScreenCodeValue(SCREEN_CODE);
  const { user } = useAuth();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();

  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const [selectedRows, setSelectedRows] = useState([]);
  const toast = useRef<Toast>(null);

  const filterLabelId = [467, 468, 441];
  const filterNames = [];
  const filterColumnsId = [2940, 3129, 3127, 3141, 4285, 4284, 441];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    fechaInicio: new Date(),
    fechaFinal: new Date(),
    playas: [],
  });

  const [loading, setLoading] = useState(false);

  const [loadingPlayas, setLoadingPlayas] = useState(true);
  const [playas, setPlayas] = useState([]);

  async function loadPlayas() {
    setLoadingPlayas(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/report/impresion-hoja-ruta-subcuenta",
          {
            cuenta: {
              id: securityFilters?.securityValues?.account?.id,
            },
          }
        );

        if (status === 200) {
          const playas = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setPlayas(playas);
          return playas;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlayas(false);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.account != null
      ) {
        try {
          const playas = await loadPlayas();

          setFilter({
            ...filter,
            playas: playas,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || null,
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,

        rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
        playas: filter?.playas?.map((x) => x.id) || [],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/report/impresion-hoja-ruta",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function customExcel() {
    if (selectedRows.length === 0) {
      toast.current?.show({
        severity: "warn",
        summary: "Error",
        detail: (
          <LanguageProvider
            id="753"
            alt="Debe seleccionar al menos un registro de la Grilla."
          />
        ),
        life: 3000,
      });
      return;
    }

    const hojasRutaIds = selectedRows.map((row) => ({ id: row[0] }));

    try {
      setLoading(true);
      const params = {
        pais: securityFilters?.securityValues?.country,
        cuenta: securityFilters?.securityValues?.account,
        hojasRuta: hojasRutaIds,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/report/impresion-hojas-ruta",
        params
      );

      if (status === 200) {
        if (data.length > 0) {
          const exportXlsx = await ExportExcelHojaRuta({ user, data });
          if (!exportXlsx) {
            toast.current?.show({
              severity: "warn",
              summary: "Error",
              detail: (
                <LanguageProvider
                  id="gen.error.excel.message"
                  alt="Error al generar archivo Excel"
                />
              ),
              life: 3000,
            });
          }
        }
      } else {
        toast.current?.show({
          severity: "warn",
          summary: "Error",
          detail: (
            <LanguageProvider
              id="778"
              alt="No existen datos asociados a los criterios seleccionados"
            />
          ),
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="ImpressionHojaRuta">
      <Toast ref={toast} position="bottom-left" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[0].label} alt="Desde" />
              </label>
              <SipcoCalendar
                value={filter.fechaInicio}
                onChange={(e) => {
                  setFilter({ ...filter, fechaInicio: e.value });
                }}
                showButtonBar
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={filterNames[1].label} alt="Hasta" />
              </label>
              <SipcoCalendar
                value={filter.fechaFinal}
                onChange={(e) => {
                  setFilter({ ...filter, fechaFinal: e.value });
                }}
                showButtonBar
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={filterNames[2].label} alt="Playas" />
              </label>
              <SipcoMultiSelect
                value={filter.playas}
                options={playas}
                onChange={(e) => {
                  setFilter({ ...filter, playas: e.value });
                }}
                display="chip"
                filter
                optionLabel="label"
                loading={loadingPlayas}
                showClear
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="6697" alt="Listado" />
              </span>
              {/* <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName="Reimpresión de Hoja de Ruta"
                  pdf={false}
                />
              </div> */}
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={getRowStyles}
          loading={loading}
          filterDisplay="menu"
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
        >
          <SipcoColumn field="0" header={columnsNames[0].label} />
          <SipcoColumn field="1" header={columnsNames[1].label} />
          <SipcoColumn field="2" header={columnsNames[2].label} />
          <SipcoColumn field="3" header={columnsNames[3].label} />
          <SipcoColumn field="4" header={columnsNames[4].label} />
          <SipcoColumn field="5" header={columnsNames[5].label} />
          <SipcoColumn field="6" header={columnsNames[6].label} />
        </SipcoDataTable>
        <div className="tags">
          <Tag
            value={<LanguageProvider id="5558" alt="posee carga compartida" />}
            className="tag-posee-carga-compartida"
          />
        </div>
      </Panel>
      <div className="footer-buttons">
        <Button
          label={LanguageProvider({ id: "42", alt: "Excel" })}
          text
          onClick={() => customExcel()}
        />
      </div>
    </div>
  );
}
export default ImpressionHojaRuta;

const getRowStyles = (data) => {
  let className = "";

  if (Number(data[7]) > 1) {
    className += " color-posee-carga-compartida ";
  }

  return className.trim();
};
