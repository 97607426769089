import "./MantenerClienteProveedor.scss";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useEffect, useRef, useState } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoColumn from "@shared/components/sipco-column";
import { Checkbox } from "primereact/checkbox";
import { ClienteProveedor } from "src/models/ClienteProveedor";
import { useFilterContext } from "../../../protected-routes";

const MantenerClienteProveedor = () => {
  const SCREEN_CODE = "abm0074_mantener_cliente_proveedor";
  const URL_BASE = "/mantener-cliente-proveedor";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const dt = useRef(null);

  const [tipoProveedor, setTipoProveedor] = useState(null);
  const [disabledProveedor, setDisabledProveedor] = useState(false);

  const [loadingReportFirst, setLoadingReportFirst] = useState(false);
  const [reporteFirst, setReporteFirst] = useState(null);
  const [selectedReporFirst, setSelectedReporFirst] = useState(null);

  const [loadingReportSec, setLoadingReportSec] = useState(false);
  const [reporteSecond, setReporteSecond] = useState(null);
  const [selectedReportSec, setSelectedReportSec] = useState(null);

  const [defecto, setDefecto] = useState(false);
  const [activo, setActivo] = useState(true);

  async function handleSave(model: ClienteProveedor, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const clienteProveedor = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          clienteProveedor: clienteProveedor,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate() {
    try {
      const clienteProveedor = {
        ...reporteSecond,
        defecto,
        activo,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        clienteProveedor: clienteProveedor,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: ClienteProveedor) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        clienteProveedor: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<ClienteProveedor[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter.securityValues.account,
          subcuenta: filter.securityValues.subaccount,
          tipoDeProveedor: filter?.tipoDeProveedor,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<ClienteProveedor> {
    setDisabledProveedor(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          clienteProveedor: model,
        }
      );
      if (status === 200 && data?.proveedor) {
        setReporteSecond(data);
        setActivo(model.activo);
        setDefecto(model.defecto);
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadTipoProveedor(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/tipoProveedor",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  useEffect(() => {
    async function loadProveedor(): Promise<any[]> {
      setDisabledProveedor(false);
      try {
        if (tipoProveedor !== null) {
          setLoadingReportFirst(true);
          const { status, data } = await sipcoAxiosService.post(
            URL_BASE + "/proveedor",
            {
              pais: { id: securityFilters?.securityValues?.country?.id },
              tipoDeProveedor: { id: tipoProveedor?.id },
              cliente: { id: securityFilters?.securityValues?.client?.id },
              cuenta: { id: securityFilters?.securityValues?.account?.id },
              subcuenta: {
                id: securityFilters?.securityValues?.subaccount?.id,
              },
            }
          );
          if (status === 200) {
            setReporteFirst(data);
            return data;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingReportFirst(false);
      }
      return [];
    }
    loadProveedor();
  }, [tipoProveedor]);

  return (
    <GenericAbm<ClienteProveedor>
      screenCode={SCREEN_CODE}
      title={
        <LanguageProvider
          id="5708"
          alt="Proveedores habilitados por Cliente Playa"
        />
      }
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        cuenta: null,
        subcuenta: null,
        tipoDeProveedor: null,
        defecto: false,
        activo: true,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
          subaccount: true,
        },
      }}
      filterSettings={[
        {
          filter: "tipoDeProveedor",
          label: LanguageProvider({ id: "24038", alt: "Proveedor" }),
          placeholder: LanguageProvider({ id: "11263", alt: "Tipo Proveedor" }),
          type: "dropdown",
          dataSource: loadTipoProveedor,
          dropDownTemplate: DropdownOptionTemplate,
          onChange: (e) => {
            setTipoProveedor(e);
          },
          dependency: ["securityValues.country"],
        },
      ]}
      dataTableColumns={[
        {
          field: "proveedor.descripcion",
          header: <LanguageProvider id="24038" alt="Proveedor" />,
        },
        {
          field: "tipoDeProveedor.descripcion",
          header: <LanguageProvider id="16276" alt="Tipo de proveedor" />,
        },
        {
          field: "defecto",
          header: <LanguageProvider id="25061" alt="Defecto" />,
          body: (rowData: any) => {
            return rowData.defecto ? "SI" : "NO";
          },
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      formSettings={[]}
      customForm={
        <div className="mantener-cliente-proveedor">
          <div className="form">
            <p>
              <LanguageProvider id="24038" alt="Proveedor" />
            </p>
            <div className="form-row">
              <div
                style={{
                  pointerEvents: disabledProveedor ? "none" : "auto",
                  opacity: disabledProveedor ? 0.5 : 1,
                }}
              >
                <SipcoDataTable
                  size="small"
                  showGridlines
                  stripedRows
                  ref={dt}
                  value={reporteFirst}
                  paginator
                  scrollHeight="flex"
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  resizableColumns
                  removableSort
                  filterDisplay="menu"
                  loading={loadingReportFirst}
                  selectionMode="single"
                  selection={selectedReporFirst}
                  onSelectionChange={(e) => setSelectedReporFirst(e.value)}
                >
                  <SipcoColumn field="descripcion" />
                </SipcoDataTable>
              </div>
              <div
                style={{
                  pointerEvents: disabledProveedor ? "none" : "auto",
                  opacity: disabledProveedor ? 0.5 : 1,
                }}
              >
                <SipcoDataTable
                  size={"small"}
                  showGridlines
                  stripedRows
                  ref={dt}
                  value={reporteSecond?.proveedores || []}
                  paginator
                  scrollHeight="flex"
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  resizableColumns
                  removableSort
                  filterDisplay="menu"
                  loading={loadingReportSec}
                  selectionMode="single"
                  selection={selectedReportSec}
                  onSelectionChange={(e) => setSelectedReportSec(e.value)}
                >
                  <SipcoColumn field="descripcion" />
                </SipcoDataTable>
              </div>
            </div>
            <div className="sipco-check">
              <label>
                <LanguageProvider id={"25061"} alt="Defecto" />
              </label>
              <Checkbox
                checked={defecto}
                onChange={(e) => setDefecto(e.target.checked)}
              />
            </div>
            <div className="sipco-check">
              <label>
                <LanguageProvider id={"23849"} alt="Activo" />
              </label>
              <Checkbox
                checked={activo}
                onChange={(e) => setActivo(e.target.checked)}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default MantenerClienteProveedor;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
