import "./ModalModificarReparacionProveedores.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../../../protected-routes";
import { useLocation } from "react-router-dom";
import usePageViews from "../../../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { HttpStatusCode } from "axios";

interface InterfaceModalAltaReparacionProveedores {
  visible: boolean;
  onClose: () => void;
  model?: any;
  onConfirm?: (updatedList: any[]) => void;
  selected?: any[];
}

const ModalAltaReparacionProveedores: React.FC<
  InterfaceModalAltaReparacionProveedores
> = ({ visible, onClose, model, onConfirm, selected }) => {
  const location = useLocation();
  const { pais, cuenta, tallerFilter, subcuenta, nroOrden } =
    location.state || {};

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    proveedores: null,
    porcentaje: "",
  });

  const [proveedores, setProveedores] = useState([]);
  const [loadingProveedores, setLoadingProveedores] = useState(false);

  async function loadProveedores() {
    setLoadingProveedores(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/orden-reparacion-proveedores/proveedores",
        {}
      );
      if (status === HttpStatusCode.Ok) {
        setProveedores(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedores(false);
    }
  }

  const [updateState, seUpdateState] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);


  async function update() {
    setLoadingUpdate(true);
    try {
      if (filter.proveedores && visible === true) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-proveedores/update",
          {
            ordenReparacionProveedor: {
              proveedor: filter.proveedores,
              pais: selected[0].pais,
              subcuenta: selected[0].subcuenta,
              cuenta: selected[0].cuenta,
              taller: selected[0].taller,
              detalleOrdenServicio: selected[0].detalleOrdenServicio,
              porcentaje: filter.porcentaje,
            },
          }
        );
        if (status === HttpStatusCode.Ok) {
          seUpdateState(data);
          onClose();
          toast.current?.show({
            severity: "success",
            detail: (
              <LanguageProvider
                id={"36"}
                alt="Sua transação se realizou com sucesso."
              />
            ),
            life: 3000,
          });
          return data;
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Erro",
            detail: "Erro ao processar",
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUpdate(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        if (selected.length >= 0) {
          const proveedores = await loadProveedores();
          setFilter({
            ...filter,
            proveedores: proveedores[0],
            porcentaje: selected[0].porcentaje,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [visible]);

  const data = convertDateObjects(reporte?.filas);

  return (
    <div>
      <Dialog
        className="modalModificarReparacionServiciosProveedores"
        header="Orden Reparación Proveedores - Alta"
        visible={visible}
        style={{ width: "60vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={LanguageProvider({ id: "9491", alt: "Aceptar" })}
                icon="pi pi-check"
                onClick={update}
                autoFocus
              />
              <Button
                label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                icon="pi pi-times"
                onClick={onClose}
                className="p-button-text"
              />
            </div>
          );
        }}
      >
        <div className="modal-container">
          <div className="form-modal">
            <div className="form-column">
              <label>
                <LanguageProvider id={"24388"} alt="Proveedores" />
              </label>
              <SipcoDropdown
                loading={loadingProveedores}
                value={filter.proveedores}
                onChange={(e) => setFilter({ ...filter, proveedores: e.value })}
                options={proveedores}
                className="input-modal"
                optionLabel="descripcion"
              /></div>
            <div className="form-column">
              <label>
                <LanguageProvider id={"1454"} alt="Porcentaje" />
              </label>
              <SipcoInputText
                className="input-modal"
                value={filter.porcentaje}
                onChange={(e) => setFilter({ ...filter, porcentaje: e.target.value })}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default ModalAltaReparacionProveedores;
