import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ObservacionPVN } from "src/models/ObservacionPVN";

const Observacionespvn = () => {
  const SCREEN_CODE = "abm0228_observacionespvn";
  const URL_BASE = "/observaciones-pvn";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ObservacionPVN, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const observacionPVN = {
          ...model,
          pais: filterValues.securityValues.country,
          tipoObservacion: model.tipoObservacion,
          descTipoObservacion: model.descTipoObservacion, 
          descripcion: model.descripcion,
          activo: model.activo,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          observacionPVN,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ObservacionPVN, filterValues: any) {
    try {
      const observacionPVN = {
        ...model,
        pais: filterValues.securityValues.country,
        tipoObservacion: model.tipoObservacion,
        descTipoObservacion: model.descTipoObservacion, 
        descripcion: model.descripcion,
        activo: model.activo,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        observacionPVN,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  } 
  async function handleRemove(model: ObservacionPVN, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        observacionPVN: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ObservacionPVN[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter.securityValues.country,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<ObservacionPVN> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          observacionPVN: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }
  async function loadTipoObservacio(filter: any): Promise<ObservacionPVN[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/tipo-observacio",
        {
          pais: filter.securityValues.country,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<ObservacionPVN>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
        },
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="23876" alt="Código" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="descripcion" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => rowData.activo ? "SI" : "NO",
        },
      ]
      }
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
        descripcion: "",
        tipoObservacion: null,
        descTipoObservacion: '',
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="20181" alt="Código Observación" />,
          type: "inputText",
          disabled: true,
        },
        {
          field: "tipoObservacion",
          label: <LanguageProvider id="20180" alt="Tipo Observación" />,
          type: "dropdown",
          dataSource: filter =>  loadTipoObservacio(filter),
          optionLabel: "descripcion",
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripción" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default Observacionespvn;
