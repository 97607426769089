import "./OrdenReparacionProveedores.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { useLocation, useNavigate } from "react-router-dom";
import ModalAltaReparacionProveedores from "./modals/proveedores/ModalAltaReparacionProveedores";
import ModalModificarReparacionProveedores from "./modals/proveedores/ModalModificarReparacionProveedores";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import ModalVerReparacionProveedores from "./modals/proveedores/ModalVerReparacionProveedores";
import { format } from "date-fns";
import { HttpStatusCode } from "axios";

export function OrdenReparacionProveedores() {
  const SCREEN_CODE = "abm0106_orden_reparacion_proveedores";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [alta, setAlta] = useState(false);
  const [modificar, setModificar] = useState(false);
  const [ver, setVer] = useState(false);



  const location = useLocation();
  const { listado, order } =
    location.state || {};
  const showModalVer = () => {
    if(selectedRows.length !== 0){
      setVer(true);
    }else{
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Debe seleccionar un registro",
        life: 3000,
      });
    }
  };

  const hideModalVer = () => {
    setVer(false);
  };

  const showModalModificar = () => {
    setModificar(true);
  };

  const hideModalModificar = () => {
    setModificar(false);
  };

  const showModalAlta = () => {
    setAlta(true);
  };

  const hideModalAlta = () => {
    setAlta(false);
  };

  function compareArrays(selectRows: any[], arrayBase: any[]): any[] {
    const result = [];
    if (selectRows.length > 0 && arrayBase.length > 0) {
      selectRows.forEach((row: any) => {
        const proveedor = row?.descripcionProveedor;

        const matchingObject = arrayBase.find(
          (item: any) => item?.proveedor?.descripcion === proveedor
        );

        if (matchingObject) {
          result.push(matchingObject);
        }
      });
    }
    return result;
  }

  const columnsNames = [];
  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    checkVinTaller: false,
    taller: null,
    estados: null,
    nroOrden: null,
    vin: null,
    checkFueraTaller: false,
    desde: new Date(),
    hasta: new Date(),
  });

  const [updateState, seUpdateState] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  async function remove() {
    setLoadingUpdate(true);
    try {
      if (selectedRows.length >= 0) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-proveedores/remove",
          {
            ordenReparacionProveedor: selectedRows[0]
          }
        );
        if (status === HttpStatusCode.Ok) {
          seUpdateState(data);
        toast.current?.show({
          severity: "success",
          detail: (
            <LanguageProvider
              id={"36"}
              alt="Sua transação se realizou com sucesso."
            />
          ),
          life: 3000,
        });
          return data;
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Erro",
            detail: "Erro ao processar",
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUpdate(false);
    }
  }

  function barrar() {
    return confirmDialog({
      header: <LanguageProvider id={"16239"} alt="Atención" />,
      message: (
        <LanguageProvider id={"829"} alt="¿Elimina el o los registros?" />
      ),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      reject: () => console.log("aceptar"),
      accept: () => remove(),
    });
  }

  const [ordenReparacionProveedores, setOrdenReparacionProveedores] = useState(
    []
  );


  async function loadReportData() {
    try {
      setLoading(true);
      const reportResult = await sipcoAxiosService.post(
        "/orden-reparacion-proveedores/find",
        {
          pais: listado.pais,
          cuenta: listado.cuenta,
          subcuenta: listado.subcuenta,
          taller: listado.taller,
          orden: order,
          detalle: listado
        }
      );

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === 200 && data) {

          setOrdenReparacionProveedores(data);
          setReporte(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        await loadReportData();
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [listado]);

  const data = convertDateObjects(reporte?.filas);

  const columnsNamesTable = [
    { field: "detalleOrdenServicio.id", header: "Proveedor" },
    { field: "proveedor.descripcion", header: "Código Cliente" },
    { field: "porcentaje", header: "Código Servicio" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };

  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };



  return (
    <div className="orden-reparacion-proveedores">
      <ModalAltaReparacionProveedores
        visible={alta}
        onClose={hideModalAlta}
        listado={listado}
        order={order}
      />
      <ModalModificarReparacionProveedores
        visible={modificar}
        onClose={hideModalModificar}
        selected={selectedRows}
      />
      <ModalVerReparacionProveedores
        selected={selectedRows}
        visible={ver}
        onClose={hideModalVer}
      />
      <Toast ref={toast} position="top-center" />
      <ConfirmDialog />
      <Filter>
        <AccordionTab header={<LanguageProvider id={"65"} alt="Seguridad" />}>
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"5044"} alt="País" />
                </label>
                <InputText value={order.pais?.descripcion} disabled={true} />
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <InputText value={order.taller.descripcion} disabled={true} />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"24328"} alt="Cuenta" />
                </label>
                <InputText value={order.cuenta?.descripcion} disabled={true} />
                <label>
                  <LanguageProvider id={"5701"} alt="Nro. Orden" />
                </label>
               <InputText value={order?.id} disabled={true} />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"441"} alt="Playas" />
                </label>
                <InputText value={order.subcuenta?.descripcion} disabled={true} />
                <label>
                  <LanguageProvider id={"10828"} alt="Linea" />
                </label>
                <InputText value={listado.id} disabled={true} />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Listado</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({ id: "25", alt: "Alta" })}
                  text
                  onClick={showModalAlta}
                />
                <Button
                  label={LanguageProvider({ id: "27", alt: "Modificar" })}
                  text
                  onClick={showModalModificar}
                />
                <Button
                  label={LanguageProvider({ id: "12899", alt: "Ver" })}
                  text
                  onClick={showModalVer}
                />
                <Button
                  label={LanguageProvider({ id: "2016", alt: "Borrar" })}
                  text
                  onClick={barrar}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={ordenReparacionProveedores}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e: any) => setSelectedRows(e.value)}
          metaKeySelection={true}
          dragSelection
        >
          {columnsNamesTable.map((colum, index) => {
            if (colum.header === "Fecha Alta") {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </DataTable>
      </Panel>
    </div>
  );
}
export default OrdenReparacionProveedores;
