import "./ModificacionMasivaMotor.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ModificacionMasivaMotor() {
  const SCREEN_CODE = "fun0084_modificacion_masiva_motor";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const selectAll = () => {
    const allRegisters = dt.current.props.value;
    setSelectedReportData(allRegisters);
  };

  const deselectAll = () => {
    setSelectedReportData([]);
  };

  async function validarProcesar() {
    try {
      if (selectedReportData <= 0) {
        showError("753", "Debe seleccionar al menos un registro de la Grilla.");
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [selectedReportData, setSelectedReportData] = useState(null);
  // const rowStyles = useRowStyles([selectedReportData]);

  const [filter, setFilter] = useState({
    vin: null,
    modelo: null,
    fechaLlegada: null,
    marca: null,
    buque: null,
    origen: null,
  });

  const [model, setModel] = useState({
    accion: null,
    parte: null,
    valor: null,
  });

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin) {
    try {
      if (securityFilters?.securityValues?.account) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-masiva-motor/vin",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
            vin: { id: vin },
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError(
              "10859",
              "El vin no se encuentra en el stock de la playa"
            );
          }
          setVin(data);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
    setVin([]);
    return [];
  }

  const [loadingModelo, setLoadingModelo] = useState(false);
  const [modelo, setModelo] = useState([]);
  async function loadModelo(marcaId) {
    setLoadingModelo(true);
    try {
      if (marcaId && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-masiva-motor/modelo",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
            marca: { id: marcaId },
          }
        );

        if (status === 200) {
          const modeloData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setModelo(modeloData);
          return modeloData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelo(false);
    }
    setModelo([]);
    return [];
  }

  const [loadingFechaLlegada, setLoadingFechaLlegada] = useState(false);
  const [fechaLlegada, setFechaLlegada] = useState([]);
  async function loadFechallegada(transporteId) {
    setLoadingFechaLlegada(true);
    try {
      if (transporteId && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-masiva-motor/importado",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
            transporte: { id: transporteId },
          }
        );

        if (status === 200) {
          setFechaLlegada(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFechaLlegada(false);
    }
    setFechaLlegada([]);
    return [];
  }

  const [loadingMarca, setLoadingMarca] = useState(false);
  const [marca, setMarca] = useState([]);
  async function loadMarca() {
    setLoadingMarca(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-masiva-motor/marca",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
          }
        );

        if (status === 200) {
          const marcaData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setMarca(marcaData);
          return marcaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
    setMarca([]);
    return [];
  }

  const [loadingBuque, setLoadingBuque] = useState(false);
  const [buque, setBuque] = useState([]);
  async function loadBuque() {
    setLoadingBuque(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-masiva-motor/transporte-playa",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
          }
        );

        if (status === 200) {
          const buqueData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setBuque(buqueData);
          return buqueData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBuque(false);
    }
    setBuque([]);
    return [];
  }

  const [loadingOrigen, setLoadingOrigen] = useState(false);
  const [origen, setOrigen] = useState([]);
  async function loadOrigen() {
    setLoadingOrigen(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-masiva-motor/origen",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
          }
        );

        if (status === 200) {
          const origenData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setOrigen(origenData);
          return origenData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigen(false);
    }
    setOrigen([]);
    return [];
  }

  const [loadingAccion, setLoadingAccion] = useState(false);
  const [accion, setAccion] = useState([]);
  async function loadAccion() {
    setLoadingAccion(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-masiva-motor/acciones",
          {
            pais: securityFilters.securityValues.country,
          }
        );

        if (status === 200) {
          setAccion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAccion(false);
    }
    setAccion([]);
    return [];
  }

  const [loadingParte, setLoadingParte] = useState(false);
  const [parte, setParte] = useState([]);
  async function loadParte() {
    setLoadingParte(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-masiva-motor/partes",
          {
            pais: securityFilters.securityValues.country,
          }
        );

        if (status === 200) {
          setParte(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingParte(false);
    }
    setParte([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const marcas = await loadMarca();
          const buques = await loadBuque();
          const origens = await loadOrigen();

          const acciones = await loadAccion();
          const partes = await loadParte();

          setFilter((prevFilter) => ({
            ...prevFilter,
            marca: marcas[0],
            buque: buques[0],
            origen: origens[0],
          }));

          setModel((prevFilter) => ({
            ...prevFilter,
            accion: acciones[0],
            parte: partes[0],
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  useEffect(() => {
    async function updateMarca() {
      if (securityFilters?.securityValues?.country && filter?.marca?.id) {
        try {
          const modelos = await loadModelo(filter.marca.id);

          setFilter((prevFilter) => ({
            ...prevFilter,
            modelo: modelos[0],
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateMarca();
  }, [filter?.marca, securityFilters.securityValues]);

  useEffect(() => {
    async function updatedBuques() {
      if (filter?.buque) {
        try {
          const fechaLlegadas = await loadFechallegada(filter.buque.id);

          setFilter((prevFilter) => ({
            ...prevFilter,
            fechaLlegada: fechaLlegadas[0],
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updatedBuques();
  }, [filter?.buque]);

  async function loadReportDataConBuques() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/modificacion-masiva-motor/con-buques",
        {
          pais: { id: securityFilters.securityValues.country.id || null },
          cliente: { id: securityFilters.securityValues.client.id || null },
          cuenta: { id: securityFilters.securityValues.account.id || null },
          transporte: { id: filter.buque.id || null },
          fechaLlegada: filter.fechaLlegada || null,
          origen: { id: filter.origen.id || null },
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  async function loadReportDataSinBuques() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/modificacion-masiva-motor/sin-buques",
        {
          pais: { id: securityFilters.securityValues.country.id || null },
          cliente: { id: securityFilters.securityValues.client.id || null },
          cuenta: { id: securityFilters.securityValues.account.id || null },
          vin: { id: filter?.vin?.id ?? null },
          marca: { id: filter?.marca.id || null },
          modelo: { id: filter?.modelo.id || null },
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  const stateBuques = filter?.buque || null;

  return (
    <div className="modificacion-masiva-motor">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={
          stateBuques !== null
            ? loadReportDataConBuques
            : loadReportDataSinBuques
        }
        securityOptions={{
          account: true,
          country: true,
          client: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"7795"} alt="Filtrar Por:" />}
        >
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="id"
                  dropdown
                  forceSelection
                />

                <label>
                  <LanguageProvider id={"489"} alt="Marcas" />
                </label>
                <SipcoDropdown
                  value={filter?.marca}
                  options={marca}
                  onChange={(e) => setFilter({ ...filter, marca: e.value })}
                  optionLabel="label"
                  loading={loadingMarca}
                  filter
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"408"} alt="Modelos" />
                </label>
                <SipcoDropdown
                  value={filter.modelo}
                  options={modelo}
                  onChange={(e) => setFilter({ ...filter, modelo: e.value })}
                  optionLabel="label"
                  loading={loadingModelo}
                  filter
                />

                <label>
                  <LanguageProvider id={"1117"} alt="Buques" />
                </label>
                <SipcoDropdown
                  value={filter.buque}
                  options={buque}
                  onChange={(e) => setFilter({ ...filter, buque: e.value })}
                  optionLabel="label"
                  loading={loadingBuque}
                  filter
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1295"} alt="Fechas de Llegadas" />
                </label>
                <SipcoDropdown
                  value={filter.fechaLlegada}
                  options={fechaLlegada}
                  onChange={(e) =>
                    setFilter({ ...filter, fechaLlegada: e.value })
                  }
                  optionLabel="label"
                  loading={loadingFechaLlegada}
                  filter
                />

                <label>
                  <LanguageProvider id={"2496"} alt="Origen" />
                </label>
                <SipcoDropdown
                  value={filter.origen}
                  options={origen}
                  onChange={(e) => setFilter({ ...filter, origen: e.value })}
                  optionLabel="label"
                  loading={loadingOrigen}
                  filter
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="2477" alt="Modificacion Masiva Motor" />
              </span>
            </div>
          );
        }}
      >
        <div className="dt-descripcion">
          <LanguageProvider
            id="2507"
            alt="Marque en la grilla los motores a modificar"
          />
        </div>

        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          // rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="multiple"
          onSelectionChange={(e) => setSelectedReportData(e.value)}
          selection={selectedReportData}
        >
          <SipcoColumn
            field="id"
            header={<LanguageProvider id="1491" alt="VIN" />}
          />
          <SipcoColumn
            field="numeroMotor"
            header={<LanguageProvider id="13335" alt="Motor" />}
          />
          <SipcoColumn
            field="marca.descripcion"
            header={<LanguageProvider id="23888" alt="Marca" />}
          />

          <SipcoColumn
            field="modelo.descripcionFull"
            header={<LanguageProvider id="23864" alt="Modelo" />}
          />
        </SipcoDataTable>
      </Panel>

      <div className="chips">
        <div className="sipco-chip">
          <label>
            <LanguageProvider id={"841"} alt="Total de Registros:" />
          </label>
          <Chip
            label={
              reporte && Array.isArray(reporte) ? String(reporte.length) : "0"
            }
          />
        </div>

        <div className="sipco-chip">
          <label>
            <LanguageProvider id={"864"} alt="Registros Selecionados:" />
          </label>
          <Chip
            label={
              selectedReportData && selectedReportData.length > 0
                ? String(selectedReportData.length)
                : "0"
            }
          />
        </div>
      </div>

      <div className="form">
        <div className={"form-row"}>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2489"} alt="Acción" />
            </label>
            <SipcoDropdown
              value={model?.accion}
              options={accion}
              onChange={(e) => setModel({ ...model, accion: e.value })}
              optionLabel="descripcionFull"
              loading={loadingAccion}
              filter
            />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2490"} alt="Parte" />
            </label>
            <SipcoDropdown
              value={model?.parte}
              options={parte}
              onChange={(e) => setModel({ ...model, parte: e.value })}
              optionLabel="descripcionFull"
              loading={loadingParte}
              filter
            />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"4675"} alt="Valor" />
            </label>
            <SipcoAutocomplete
              value={model?.valor ?? ""}
              onChange={(e) => setModel({ ...model, valor: e.value })}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({ id: "748", alt: "Seleccionar tudo" })}
          icon="pi pi-plus"
          onClick={selectAll}
        />
        <Button
          label={LanguageProvider({ id: "749", alt: "Deseleccionar tudo" })}
          icon="pi pi-minus"
          onClick={deselectAll}
        />
        <Button
          label={LanguageProvider({
            id: "34",
            alt: "Procesar o guardar cambios",
          })}
          icon="pi pi-save"
          onClick={validarProcesar}
        />
      </div>
    </div>
  );
}
