import "./ListadoApoyoEstadia.scss";
import React, { useEffect, useRef, useState } from "react";
import { AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { LanguageProviderString } from "@shared/components/language-provider-string";
import useAuth from "@shared/AuthContext";

export function ListadoApoyoEstadia() {
  const SCREEN_CODE = "fun0072_listado_apoyo_estadia";
  ScreenCodeValue(SCREEN_CODE);
  const { user } = useAuth();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const filterLabelId = [1491, 18309, 1569, 15739, 19265];
  const filterNames = [];

  const [loading, setLoading] = useState(false);
  const [dataTableVin, setDataTableVin] = useState(false);
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    1, 13, 54, 65, 396, 448, 466, 467, 468, 653, 748, 749, 900, 901, 990, 991,
    1182, 1184, 1491, 1569, 1791, 2139, 2140, 2892, 3750, 4589, 4791, 5044,
    5271, 5583, 19179, 18637, 17088, 15739, 19265, 11986, 5271, 10034, 13706,
    14288, 16501, 13514, 18305, 367, 12251, 3410, 18202, 11507, 17131, 1492,
    3344, 10871, 2941, 10868, 10904, 18306, 18307, 748, 749, 321, 18308, 18309,
    10531, 5366, 19178, 16245, 10774, 16416, 5788, 19179, 8380, 2502, 2521,
  ];

  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const { values: securityFilters } = useFilterContext();
  const sipcoAxiosService = useSipcoAxiosService();

  const [filter, setFilter] = useState({
    playas: null,
    marcas: null,
    fechaEstadiaPlayaInicio: new Date(),
    fechaEstadiaPlayaFim: new Date(),
    agrupadoPorVin: false,
  });

  const [playa, setPlaya] = useState([]);
  const [loadingPlaya, setLoadingPlaya] = useState(false);

  async function getPlaya() {
    try {
      setLoadingPlaya(true);
      if (securityFilters.securityValues.account !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/listado-apoyo-estadia/get-estadias",
          securityFilters.securityValues.account
        );
        if (status === 200) {
          setPlaya(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlaya(false);
    }
  }

  const [marca, setMarca] = useState([]);
  const [loadingMarca, setLoadingMarca] = useState(false);

  async function getMarca() {
    try {
      setLoadingMarca(true);
      if (
        securityFilters.securityValues.country !== null &&
        securityFilters.securityValues.client !== null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/listado-apoyo-estadia/get-marcas",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          setMarca(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      const playas = await getPlaya();
      const marcas = await getMarca();

      setFilter({
        ...filter,
        playas: playas,
        marcas: marcas,
      });
    };
    init();
  }, [securityFilters.securityValues]);

  function checkButton() {
    if (filter.agrupadoPorVin === true) {
      setDataTableVin(true);
    } else {
      setDataTableVin(false);
    }
  }

  async function loadReportData() {
    try {
      setLoading(true);
      const reportResult = await sipcoAxiosService.post(
        "/listado-apoyo-estadia/estadia-movimiento",
        {
          pais: securityFilters.securityValues?.country,
          cliente: securityFilters.securityValues?.client,
          cuenta: securityFilters.securityValues?.account,
          subcuentas: filter?.playas || null,
          marcas: filter?.marcas || null,
          fechaDesde: {
            timestamp: filter?.fechaEstadiaPlayaInicio ?? null,
            date: filter?.fechaEstadiaPlayaInicio ?? null,
          },
          fechaHasta: {
            timestamp: filter?.fechaEstadiaPlayaFim ?? null,
            date: filter?.fechaEstadiaPlayaFim ?? null,
          },
          checkAgrupado: filter?.agrupadoPorVin || false,
        }
      );

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === 200 && data) {
          setReporte(data);
          checkButton();
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const transformedData: any = data.map((item) => ({
    ...item,
    8: item[8] === 1 ? "Si" : "No",
    10: item[10] !== null ? "" : item[10],
    12: item[12] !== null ? "" : item[12],
  }));

  const agrupadoPorVinColumnIds = [
    4791, 17088, 2139, 4724, 3344, 1197, 4111, 396, 321, 367, 1256, 15863,
  ];
  const filterColumnsId = [
    4791, 1269, 17088, 2139, 4724, 3344, 16313, 1197, 4111, 16578, 16965, 396,
    321, 367, 16579, 1492, 1256, 15863,
  ];
  const columnsNames = [];
  if (filter.agrupadoPorVin) {
    for (const element of agrupadoPorVinColumnIds) {
      columnsNames.push({
        id: element,
        label: LanguageProvider({
          id: element,
          alt: "Error Columns Labels",
        }),
      });
    }
  } else {
    for (const element of filterColumnsId) {
      columnsNames.push({
        id: element,
        label: LanguageProvider({
          id: element,
          alt: "Error Columns Labels",
        }),
      });
    }
  }

  function exportDataValues() {
    // filter.agrupadoPorVin ? data : transformedData
    if (filter.agrupadoPorVin) {
      const fields = [0, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1];
      const filteredData = data.map((childArray) =>
        fields.map((index) => childArray[index])
      );
      return filteredData;
    } else {
      const fields = [
        2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
      ];
      const filteredData = transformedData.map((childArray) =>
        fields.map((index) => childArray[index])
      );
      const convertedArrays = filteredData.map((obj) => Object.values(obj));
      return convertedArrays;
    }
  }

  function exportName() {
    return filter.agrupadoPorVin
      ? `${LanguageProviderString({
          id: "1505",
          alt: "Listado de Apoyo para Estadías",
          user,
        })} - ${LanguageProviderString({
          id: "4203",
          alt: "Agrupado por VIN",
          user,
        })}`
      : LanguageProviderString({
          id: "1505",
          alt: "Listado de Apoyo para Estadías",
          user,
        });
  }

  return (
    <div className="consulta-operaciones-canceladas-web">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"441"} alt="Playas" />
              </label>
              <SipcoMultiSelect
                value={filter?.playas}
                options={playa}
                onChange={(e) =>
                  setFilter({ ...filter, playas: e.value || [] })
                }
                optionLabel="label"
                filter
                showClear
                display="chip"
                loading={loadingPlaya}
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"23888"} alt="Marca" />
              </label>
              <SipcoMultiSelect
                value={filter?.marcas}
                options={marca}
                onChange={(e) =>
                  setFilter({ ...filter, marcas: e.value || [] })
                }
                optionLabel="label"
                filter
                showClear
                display="chip"
                loading={loadingMarca}
              />
            </div>
          </div>

          <div className="form">
            <div className="form-row">
              <label>
                <LanguageProvider id={1504} alt="Fecha de estadía en Playa" />
              </label>

              <div className="sipco-option">
                <SipcoCalendar
                  value={filter.fechaEstadiaPlayaInicio}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaEstadiaPlayaInicio: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>

              <div className="sipco-option">
                <SipcoCalendar
                  value={filter.fechaEstadiaPlayaFim}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaEstadiaPlayaFim: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
            </div>
          </div>

          <div className="form">
            <div className="form-row">
              <label>
                <LanguageProvider id={4203} alt="Agrupado por VIN" />
              </label>
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.agrupadoPorVin}
                  onChange={(e) => {
                    if (e.checked) {
                      setReporte([]);
                      setFilter({
                        ...filter,
                        agrupadoPorVin: true,
                      });
                    } else {
                      setReporte([]);
                      setFilter({
                        ...filter,
                        agrupadoPorVin: false,
                      });
                    }
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Listado</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={exportDataValues()}
                  columns={columnsNames}
                  screenName={exportName()}
                  pdf={false}
                  csv={false}
                />
              </div>
            </div>
          );
        }}
      >
        {filter.agrupadoPorVin ? (
          <SipcoDataTable ref={dt} value={data} loading={loading}>
            <SipcoColumn
              field="0"
              header={<LanguageProvider id="4791" alt="Subcuenta" />}
              sortable
              filter
            />
            <SipcoColumn
              field="3"
              header={<LanguageProvider id="17088" alt="Vin" />}
              sortable
              filter
            />
            <SipcoColumn
              field="4"
              header={<LanguageProvider id="2139" alt="Modelo" />}
              sortable
              filter
            />
            <SipcoColumn
              field="5"
              header={<LanguageProvider id="4724" alt="Patente" />}
              sortable
              filter
            />
            <SipcoColumn
              field="6"
              header={<LanguageProvider id="3344" alt="Estado" />}
              sortable
              filter
            />
            <SipcoColumn
              field="7"
              header={<LanguageProvider id="1197" alt="Ingreso a Playas" />}
              sortable
              filter
            />
            <SipcoColumn
              field="8"
              header={<LanguageProvider id="4111" alt="Salida de Playas" />}
              sortable
              filter
            />
            <SipcoColumn
              field="9"
              header={<LanguageProvider id="396" alt="Marca" />}
              sortable
              filter
            />
            <SipcoColumn
              field="10"
              header={<LanguageProvider id="321" alt="Remito de Despacho" />}
              sortable
              filter
            />
            <SipcoColumn
              field="11"
              header={<LanguageProvider id="367" alt="Mercado" />}
              sortable
              filter
            />
            <SipcoColumn
              field="12"
              header={<LanguageProvider id="1256" alt="Categoria" />}
              sortable
              filter
            />
            <SipcoColumn
              field="1"
              header={<LanguageProvider id="15863" alt="Días a Cobrar" />}
              sortable
              filter
            />
          </SipcoDataTable>
        ) : (
          <SipcoDataTable ref={dt} value={transformedData} loading={loading}>
            <SipcoColumn
              field="2"
              header={<LanguageProvider id="4791" alt="Subcuenta" />}
              sortable
              filter
            />
            <SipcoColumn
              field="3"
              header={<LanguageProvider id="1269" alt="Ubicación" />}
              sortable
              filter
            />
            <SipcoColumn
              field="4"
              header={<LanguageProvider id="17088" alt="Vin" />}
              sortable
              filter
            />
            <SipcoColumn
              field="5"
              header={<LanguageProvider id="2139" alt="Modelo" />}
              sortable
              filter
            />
            <SipcoColumn
              field="6"
              header={<LanguageProvider id="4724" alt="Patente" />}
              sortable
              filter
            />
            <SipcoColumn
              field="7"
              header={<LanguageProvider id="3344" alt="Estado" />}
              sortable
              filter
            />
            <SipcoColumn
              field="8"
              header={<LanguageProvider id="16313" alt="Techada" />}
              sortable
              filter
            />
            <SipcoColumn
              field="9"
              header={<LanguageProvider id="1197" alt="Ingreso a Playas" />}
              sortable
              filter
            />
            <SipcoColumn
              field="10"
              header={<LanguageProvider id="4111" alt="Salida de Playas" />}
              sortable
              filter
            />
            <SipcoColumn
              field="11"
              header={<LanguageProvider id="16578" alt="Ingreso ubicación" />}
              sortable
              filter
            />
            <SipcoColumn
              field="12"
              header={<LanguageProvider id="16965" alt="Salida ubicación" />}
              sortable
              filter
            />
            <SipcoColumn
              field="13"
              header={<LanguageProvider id="396" alt="Marca" />}
              sortable
              filter
            />
            <SipcoColumn
              field="14"
              header={<LanguageProvider id="321" alt="Remito de Despacho" />}
              sortable
              filter
            />
            <SipcoColumn
              field="15"
              header={<LanguageProvider id="367" alt="Mercado" />}
              sortable
              filter
            />
            <SipcoColumn
              field="16"
              header={<LanguageProvider id="16579" alt="Cliente Terceros" />}
              sortable
              filter
            />
            <SipcoColumn
              field="17"
              header={<LanguageProvider id="1492" alt="Fecha Emisión" />}
              sortable
              filter
            />
            <SipcoColumn
              field="18"
              header={<LanguageProvider id="1256" alt="Categoría" />}
              sortable
              filter
            />
            <SipcoColumn
              field="19"
              header={<LanguageProvider id="15863" alt="Días a Cobrar" />}
              sortable
              filter
            />
          </SipcoDataTable>
        )}
      </Panel>
    </div>
  );
}
export default ListadoApoyoEstadia;
