import "./ModalAltaOrdenReparacion.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { HttpStatusCode } from "axios";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../../../protected-routes";
import usePageViews from "../../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

interface ModalAltaOrdenReparacionProps {
  visible: boolean;
  onClose: () => void;
  model?: any;
  onConfirm?: (updatedList: any[]) => void;
  ordemReparacao: any;
}

const ModalAltaOrdenReparacion: React.FC<ModalAltaOrdenReparacionProps> = ({
  visible,
  onClose,
  model,
  onConfirm,
  ordemReparacao,
}) => {
  const { values: securityFilters } = useFilterContext();

  const sipcoAxiosService = useSipcoAxiosService();
  usePageViews();
  const dt = useRef(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }


  const [formModel, setFormModel] = useState({
    cliente: null,
    rubro: null,
    cuentaTaller: null,
    grupoTaller: null,
    peiza: null,
    modelo: null,
    cantidad: "",
    precisoUn: "",
    descuento: null,
    origen: null,
    nroPedidoPSA: null,
    prestacion: null,
    subPrestacion: null,
  });

  const data = convertDateObjects(reporte?.filas);

  const [cliente, setCliente] = useState([]);
  const [loadingCliente, setLoadingCliente] = useState(false);

  async function loadCliente() {
    setLoadingCliente(true);
    try {
      if (ordemReparacao.pais && ordemReparacao.cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-repuestos/cliente",
          {
            pais: ordemReparacao.pais,
            cuenta: ordemReparacao.cuenta,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setCliente(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCliente(false);
    }
  }


  
  const [rubro, setRubro] = useState([]);
  const [loadingRubro, setLoadingRubro] = useState(false);

  async function loadRubro() {
    setLoadingRubro(true);
    try {
      if (ordemReparacao.pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-repuestos/rubro",
          {
            pais: ordemReparacao.pais,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setRubro(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRubro(false);
    }
  }

  const [cuentaTaller, setCuentaTaller] = useState([]);
  const [loadingCuentaTaller, setLoadingCuentaTaller] = useState(false);
  async function loadCuentaTaller() {
    setLoadingCuentaTaller(true);
    try {
      if (ordemReparacao.pais && ordemReparacao.cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-repuestos/cuenta-taller",
          {
            pais: ordemReparacao.pais,
            cuenta: ordemReparacao.cuenta,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setCuentaTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCuentaTaller(false);
    }
  }

  const [grupoTaller, setGrupoTaller] = useState([]);
  const [loadingGrupoTaller, setLoadingGrupoTaller] = useState(false);

  async function loadGrupoTaller() {
    setLoadingGrupoTaller(true);
    try {
      if (ordemReparacao.pais && ordemReparacao.cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-repuestos/grupo-taller",
          {
            pais: ordemReparacao.pais,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setGrupoTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrupoTaller(false);
    }
  }

  const [modelo, setModelo] = useState([]);
  const [loadingModelo, setLoadingModelo] = useState(false);

  async function loadModelo() {
    setLoadingModelo(true);
    try {
      if (formModel.grupoTaller !== null && ordemReparacao.pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-repuestos/modelo-taller",
          {
            pais: ordemReparacao.pais,
            grupoTaller: formModel.grupoTaller,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setModelo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelo(false);
    }
  }

  const [duescuento, setDescuento] = useState([]);
  const [loadingDuescuento, setLoadingDescuento] = useState(false);

  async function loadDuescuento() {
    setLoadingDescuento(true);
    try {
      if (
        ordemReparacao.cuenta &&
        ordemReparacao.pais &&
        ordemReparacao.subcuenta &&
        ordemReparacao.taller
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-repuestos/descuento-taller",
          {
            pais: ordemReparacao.pais,
            cuenta: ordemReparacao.cuenta,
            subcuenta: ordemReparacao.subcuenta,
            taller: ordemReparacao.taller,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setDescuento(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDescuento(false);
    }
  }

  const [origen, setOrigen] = useState([]);
  const [loadingOrigen, setLoadingOrigen] = useState(false);

  async function loadOrigen() {
    setLoadingOrigen(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/orden-reparacion-repuestos/find-by-user",
        {}
      );
      if (status === HttpStatusCode.Ok) {
        setOrigen(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigen(false);
    }
  }

  const [prestacion, setPrestacion] = useState([]);
  const [loadingPrestacion, setLoadingPrestacion] = useState(false);

  async function loadPrestacion(origen: any) {
    setLoadingPrestacion(true);
    try {
      if (origen) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-repuestos/find-prestacion",
          {
            codori: origen?.id,
            pais: ordemReparacao.pais,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setPrestacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPrestacion(false);
    }
  }
  const [subPrestacion, setSubPrestacion] = useState([]);
  const [loadingSubPrestacion, setLoadingSubPrestacion] = useState(false);
  async function loadSubPrestacion(prestacion: any, origen: any) {
    setLoadingSubPrestacion(true);
    try {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-repuestos/find-subprestacion",
          {
            codori: origen?.id,
            prestacion: prestacion?.id,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setSubPrestacion(data);
          return data;
        }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSubPrestacion(false);
    }
  }
  const [comprasTaller, setComprasTaller] = useState([]);
  async function loadReport() {
    setLoading(true);
    try {
      if (ordemReparacao.pais && ordemReparacao.cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-repuestos/repuesto-cantidad",
          {
            pais: ordemReparacao.pais,
            cuenta: ordemReparacao.cuenta,
            cliente:
              formModel.cliente?.descripcion === "TODOS"
                ? null
                : formModel.cliente,
            rubro:
              formModel.rubro?.descripcion === "TODOS" ? null : formModel.rubro,
            cuentaTaller:
              formModel.cuentaTaller?.descripcion === "TODOS"
                ? null
                : formModel.cuentaTaller,
            pieza: formModel.peiza,
            grupoTaller:
              formModel.grupoTaller?.descripcion === "TODOS"
                ? null
                : formModel.grupoTaller,
            modeloTaller:
              formModel.modelo?.descripcion === "TODOS"
                ? null
                : formModel.modelo,
            subcuenta: ordemReparacao.subcuenta,
            taller: ordemReparacao.taller,
          }
        );
        if (status === HttpStatusCode.Ok) {

          setComprasTaller(data);

          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  // Cliente - Grupo Taller - Cuenta Taller - Rubro - Descuento
  useEffect(() => {
    const init = async () => {
      if (visible) {
        try {
          const [cliente, rubro, cuentaTaller, grupoTaller, descuento] =
            await Promise.all([
              loadCliente(),
              loadRubro(),
              loadCuentaTaller(),
              loadGrupoTaller(),
              loadDuescuento(),
            ]);

          setFormModel({
            ...formModel,
            cliente: cliente[0],
            rubro: rubro[0],
            cuentaTaller: cuentaTaller[0],
            grupoTaller: grupoTaller[0],
            descuento: descuento[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    };

    init();
  }, [visible]);

  // Data Table
  useEffect(() => {
    const init = async () => {
      if (
        formModel.peiza &&
        formModel.grupoTaller &&
        formModel.cuentaTaller &&
        formModel.rubro &&
        formModel.cliente
      ) {
        try {
          await loadReport();
        } catch (error) {
          console.error(error);
        }
      }
    };

    init();
  }, [formModel.peiza || formModel]);

  // Modelo - Origen
  useEffect(() => {
    const init = async () => {
      try {
        const [modelo, origen] = await Promise.all([
          loadModelo(),
          loadOrigen(),
        ]);

        setFormModel({
          ...formModel,
          modelo: modelo[0],
          origen: origen[0],
        });
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);

  console.log(selectedRow);

  const [saveLabel, setSaveLabel] = useState([]);
  const [loadingSabeLabel, setLoadingSaveLabel] = useState(false);
  async function save() {
    setLoadingSaveLabel(true);
    try {
      if (ordemReparacao.length !== 0 && selectedRow.length !== 0) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-repuestos/save",
          {
            detalleOrden: {
              activo: true,
              pais: ordemReparacao?.pais,
              cuenta: ordemReparacao?.cuenta,
              subcuenta: ordemReparacao?.subcuenta,
              taller: ordemReparacao?.taller,
              codori: formModel.origen?.id ?? null,
              ordenReparacion: ordemReparacao,
              id: ordemReparacao?.id,
              importe: Number(formModel.precisoUn) ?? null,
              fechaUltimaModificacion: ordemReparacao?.fechaUltimaModificacion ?? null,
              usuarioUltimaModificacion: ordemReparacao?.usuarioUltimaModificacion ?? null,
              descuentoTaller: formModel.descuento ?? null,
              importeNeto: Number(formModel.precisoUn) ?? null,
              prestacion: formModel.prestacion?.id ?? null,
              subprestacion: formModel.subPrestacion.id ?? null,
              cantidad: Number(formModel.cantidad) ?? null,
              clienteRepuesto: formModel.cliente ?? null,
              pieza: selectedRow[0].catalogo ?? null,
              estadoRepuesto: ordemReparacao?.estado ?? null,
            },
          }
        );
        if (status === HttpStatusCode.Ok) {
          setSaveLabel(data);
          onClose();
          return data;
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error",
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSaveLabel(false);
    }
  }

  const columnsTable = [
    { field: "catalogo.id", header: "Pieza" },
    { field: "catalogo.descripcion", header: "Pieza" },
    { field: "catalogo?.fechaCotizacion?.date", header: "Fecha Cotizacion" },
    { field: "catalogo.costoPeso", header: "Custo ($)" },
    { field: "catalogo.costoDolar", header: "Custo(U$D)" },
    { field: "catalogo.iva", header: "IVA" },
    { field: "catalogo.fechaUltimaModificacion?.timestamp", header: "Última Modificación" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };

  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };


  async function handleDataTableRowSelection(value: any) {
    try {
      setSelectedRow(value);

      //  Lógica dos campos de prestacion y subprestacion
      let prestaciones: any[] = [];
      let subPrestaciones: any[] = [];
      const origens = await loadOrigen();
      if (origen.length > 0) {
        prestaciones = await loadPrestacion(origens[0]);
        if (prestaciones.length > 0) {
          subPrestaciones = await loadSubPrestacion(prestaciones[0], origens[0]);
        }
      }


      setFormModel({
        ...formModel,
        origen: origens[0] ?? null,
        prestacion: prestaciones[0] ?? null,
        subPrestacion: subPrestaciones[0] ?? null,
        cantidad: value.map(x => x.catalogo.cantidad)[0] ?? null,
        precisoUn: value.map(x => x.catalogo.costoPeso)[0] ?? null,

      });
    } catch (error) {
      console.error(error);
    }
  }
  async function handlePrestacionChange(value: any) {
    try {
      let subPrestaciones = null;


      if (value && formModel.origen !== null) {
        subPrestaciones = await loadSubPrestacion(value, formModel.origen);
 
      }
      setFormModel({
        ...formModel,
        prestacion: value,
        subPrestacion: subPrestaciones[0] ?? null,
      });
    } catch (error) {
      console.error(error);
    }
  }


  async function handleOrigenChange(value: any) {
    try {
      let prestaciones = null;


      if (value) {
        prestaciones = await loadPrestacion(value);
 
      }
      setFormModel({
        ...formModel,
        origen: value,
        prestacion: prestaciones[0] ?? null,
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <Dialog
        className="modalOrdenReparacionAlta"
        header="Orden de Reparación - Alta"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={LanguageProvider({ id: "15447", alt: "Confirmar" })}
                icon="pi pi-check"
                onClick={save}
                autoFocus
              />
              <Button
                label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                icon="pi pi-times"
                onClick={onClose}
                className="p-button-text"
              />
            </div>
          );
        }}
      >
        <div className="column-form">
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"3750"} alt="Cliente" />
                  </label>
                  <SipcoDropdown
                    loading={loadingCliente}
                    value={formModel.cliente}
                    onChange={(e) =>
                      setFormModel({ ...formModel, cliente: e.value })
                    }
                    options={cliente}
                    optionLabel="descripcion"
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"2183"} alt="Rubro" />
                  </label>
                  <SipcoDropdown
                    loading={loadingRubro}
                    value={formModel.rubro}
                    onChange={(e) =>
                      setFormModel({ ...formModel, rubro: e.value })
                    }
                    options={rubro}
                    optionLabel="descripcion"
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"2184"} alt="Cuenta Taller" />
                  </label>
                  <SipcoDropdown
                    loading={loadingCuentaTaller}
                    value={formModel.cuentaTaller}
                    onChange={(e) =>
                      setFormModel({ ...formModel, cuentaTaller: e.value })
                    }
                    options={cuentaTaller}
                    optionLabel="descripcion"
                    className="input-modal"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"2020"} alt="Pieza" />
                  </label>
                  <SipcoInputText
                    className="input-modal"
                    value={formModel.peiza}
                    onChange={(e) =>
                      setFormModel({ ...formModel, peiza: e.target.value })
                    }
                  />
                </div>
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"2185"} alt="Grupo Taller" />
                  </label>
                  <SipcoDropdown
                    loading={loadingGrupoTaller}
                    value={formModel.grupoTaller}
                    onChange={(e) =>
                      setFormModel({ ...formModel, grupoTaller: e.value })
                    }
                    options={grupoTaller}
                    optionLabel="descripcion"
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"10746"} alt="Modelo" />
                  </label>
                  <SipcoDropdown
                    loading={loadingModelo}
                    value={formModel.modelo}
                    onChange={(e) =>
                      setFormModel({ ...formModel, modelo: e.value })
                    }
                    options={modelo}
                    optionLabel="descripcion"
                    className="input-modal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Data Table Modal */}
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={comprasTaller}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%", marginBottom: "1rem" }}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRow}
          onSelectionChange={async (e: any) =>
            await handleDataTableRowSelection(e.value)
          }
          metaKeySelection={true}
          dragSelection
        >
          {columnsTable.map((colum, index) => {
            if (
              colum.header === "Fecha Cotizacion" ||
              colum.header === "Última Modificación"
            ) {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </SipcoDataTable>

        <div className="column-form">
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"3773"} alt="Cantidad" />
                  </label>
                  <SipcoInputText
                    className="input-modal"
                    value={formModel.cantidad}
                    onChange={(e) =>
                      setFormModel({ ...formModel, cantidad: e.target.value })
                    }
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"2265"} alt="Precio x Un." />
                  </label>
                  <SipcoInputText
                    className="input-modal"
                    value={formModel.precisoUn}
                    onChange={(e) =>
                      setFormModel({ ...formModel, precisoUn: e.target.value })
                    }
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"2263"} alt="Descuento (%)" />
                  </label>
                  <SipcoDropdown
                    loading={loadingDuescuento}
                    value={formModel.descuento}
                    options={duescuento}
                    onChange={(e) =>
                      setFormModel({ ...formModel, descuento: e.value })
                    }
                    optionLabel="descripcion"
                    className="input-modal"
                  />
                </div>
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"16480"} alt="Nro. Pedido PSA" />
                  </label>
                  <SipcoInputText
                    value={formModel.nroPedidoPSA}
                    onChange={(e) =>
                      setFormModel({
                        ...formModel,
                        nroPedidoPSA: e.target.value,
                      })
                    }
                    className="input-modal"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"2496"} alt="Origen" />
                  </label>
                  <SipcoDropdown
                    loading={loadingOrigen}
                    value={formModel.origen}
                    options={origen}
                    onChange={async (e) => {
                      await handleOrigenChange(e.value);
                    }}
                    optionLabel="descripcion"
                    className="input-modal"
                    filter
                  />
                </div>
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"22724"} alt="Prestacion" />
                  </label>
                  <SipcoDropdown
                    loading={loadingPrestacion}
                    value={formModel.prestacion}
                    options={prestacion}
                    onChange={async (e) => {
                      
                      await handlePrestacionChange(e.value);
                    }}
                    optionLabel="descripcion"
                    className="input-modal"
                    filter
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"22725"} alt="Subprestacion" />
                  </label>
                  <SipcoDropdown
                    loading={loadingSubPrestacion}
                    value={formModel.subPrestacion}
                    options={subPrestacion}
                    onChange={(e) =>
                      setFormModel({ ...formModel, subPrestacion: e.value })
                    }
                    optionLabel="descripcion"
                    className="input-modal"
                    filter
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default ModalAltaOrdenReparacion;
