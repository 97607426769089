import { Familia } from "src/models/Familia";
import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerFamilias = () => {
  const SCREEN_CODE = "abm0028_mantener_familias";
  const URL_BASE = "/mantener-familias";
  const SCREEN_TITLE = <LanguageProvider id="401" alt="Mantener Familias" />;
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: Familia, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client
      ) {
        const familia = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          marca: filterValues.marca,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          familia,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: Familia, filterValues: any) {
    try {
      const familia = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        marca: filterValues.marca,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        familia,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Familia, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        familia: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Familia[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          marca: filter.marca,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<Familia> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          familia: model,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarca(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/marca",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadCoeficienteFlete(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/coeficiente-flete",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            marca: filter.marca,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadSuperFamilia(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/super-familia",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            marca: filter.marca,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<Familia>
      screenCode={SCREEN_CODE}
      title={SCREEN_TITLE}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      filterSettings={[
        {
          filter: "marca",
          label: LanguageProvider({ id: "396", alt: "Marca" }),
          placeholder: LanguageProvider({ id: "396", alt: "Marca" }),
          type: "dropdown",
          dataSource: loadMarca,
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
          required: true,
        },
      ]}
      defaultValues={{
        id: "",
        activo: true,
        cliente: null,
        pais: null,
        coeficienteFlete: null,
        descripcionCorta: "",
        film: 0,
        marca: {},
        superFamilia: {},
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Código" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="Descripcion" />,
        },
        {
          field: "cliente.descripcion",
          header: <LanguageProvider id="54" alt="Cliente" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
        },
      ]}
      loadDropdownData={{
        superFamilia: loadSuperFamilia,
        coeficienteFlete: loadCoeficienteFlete,
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          length: 2,
          required: true,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripcion" />,
          type: "inputText",
          length: 50,
          required: true,
        },
        {
          field: "descripcionCorta",
          label: <LanguageProvider id="9894" alt="Descripción Corta" />,
          type: "inputText",
          length: 4,
        },
        {
          field: "altura",
          label: <LanguageProvider id="16287" alt="Altura" />,
          type: "inputNumber",
          length: 6,
          max: 99,
          maxFractionDigits: 3,
          required: true,
        },
        {
          field: "superFamilia",
          label: <LanguageProvider id="16267" alt="Super Familia" />,
          dropDownPlaceholder: LanguageProvider({
            id: "16267",
            alt: "Super Familia",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadSuperFamilia(filter),
          dropDownTemplate: DropdownOptionTemplate,
          required: true,
        },
        {
          field: "coeficienteFlete",
          label: <LanguageProvider id="16288" alt="Coeficiente" />,
          dropDownPlaceholder: LanguageProvider({
            id: "16288",
            alt: "Coeficiente",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadCoeficienteFlete(filter),
          dropDownTemplate: DropdownOptionTemplateCoe,
        },
        {
          field: "film",
          label: <LanguageProvider id="16289" alt="Film" />,
          type: "checkbox",
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
    />
  );
};

export default MantenerFamilias;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};

const DropdownOptionTemplateCoe = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.coeficiente} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
