import React, { useRef, useState } from "react";
import GenericAbm, { GenericAbmRef } from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ProveedorTipo } from "src/models/ProveedorTipo";
import { PickList } from "primereact/picklist";
import { Checkbox } from "primereact/checkbox";
import '../functions/compras-taller/components/ModalVerComprasTaller.scss';

const MantenerProveedoresTipos = () => {
  const SCREEN_CODE = "abm0056_mantener_proveedores_tipos";
  const URL_BASE = "/mantener-proveedores-tipos";
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function save(abm: any, model: ProveedorTipo) {
    try {
      if (items.target.length !== 0) {
        const proveedorTipo = {
          ...model,
          pais: abm.current?.filter?.securityValues?.country,
          proveedor: abm.current?.filter?.proveedor,
          tipoDeProveedor: items.target[0],
          tipoDeProveedores: items.target,
          activo: model.activo,


        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          proveedorTipo,
        });
        setDisplayLocalAlta(false);
        toast.current?.show({
          severity: "success",
          detail: (
            <LanguageProvider
              id={"36"}
              alt="Sua transação se realizou com sucesso."
            />
          ),
          life: 3000,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function update(model: any, abm: any) {
    try {
      if (items.target.length !== 0) {
        const perfilTraslado = {
          ...model,
          pais: abm.current?.filter?.securityValues?.country,
          proveedor: abm.current?.filter?.proveedor,
          tipoDeProveedor: items.target[0],
          tipoDeProveedores: items.target,
          activo: model.activo,
        };
        await sipcoAxiosService.post(URL_BASE + "/update", {
          perfilTraslado,
        });

        setDisplayLocalModificar(false);
        toast.current?.show({
          severity: "success",
          detail: (
            <LanguageProvider
              id={"36"}
              alt="Sua transação se realizou com sucesso."
            />
          ),
          life: 3000,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function remove(abm: any) {
    try {
      const model = abm.current?.selectedEntries[0];
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        proveedorTipo: { ...model },
      });
      toast.current?.show({
        severity: "success",
        detail: (
          <LanguageProvider
            id={"36"}
            alt="Sua transação se realizou com sucesso."
          />
        ),
        life: 3000,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handlerAlta(model: any) {
    try {
      setItems({ source: [], target: [] });
      setDisplayLocalAlta(true);

      const data = await loadTipoProveedores(model);

      if (Array.isArray(data) && data.length > 0) {
        setItems((prevItems) => ({
          ...prevItems,
          source: data,
        }));
      }
    } catch (error) {
      console.error("Erro ao carregar os tipos de fornecedores:", error);
    }
  }

  function handleModificar(abm: any) {
    try {
      setLoadingOperacion(true);
      const get = abm.current?.selectedEntries;
      if (!(get.length >= 0)) {
        return false;
      } else {
        const modelBody = get[0];



        console.log(modelBody?.operacion?.descripcion)

        setFilterModel({
          ...filterModel,
          activo: modelBody?.activo,
        });
        setDisplayLocalModificar(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOperacion(false);
    }
  }

  async function handleList(filter: any): Promise<ProveedorTipo[]> {
    try {

      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter.securityValues.country,
          proveedor: filter.proveedor

        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<ProveedorTipo> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          perfilTraslado: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  const [filterModel, setFilterModel] = useState<ProveedorTipo>({
    id: "",
    activo: false,
    fechaUltimaModificacion: null,
    pais: null,
    proveedor: null,
    tipoDeProveedor: null,
    tipoDeProveedores: [],
    usuarioUltimaModificacion: null,
  });


  const abmRef = useRef<GenericAbmRef<ProveedorTipo>>(null);
  const [displayLocalAlta, setDisplayLocalAlta] = useState(false);
  const [displayLocalModificar, setDisplayLocalModificar] = useState(false);
  const [loadingOperacion, setLoadingOperacion] = useState(false);

  async function loadProveedores(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/proveedor",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadTipoProveedores(filter: any): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/tipo-de-proveedor",
        {
          pais: filter?.current?.filter?.securityValues?.country,
          proveedor: filter?.current?.filter?.proveedor
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [items, setItems] = useState({
    source: [],
    target: [],
  });



  return (
    <>
      <Toast ref={toast} position="top-right" />
      <GenericAbm<ProveedorTipo>
        screenCode={SCREEN_CODE}
        ref={abmRef}
        title={<LanguageProvider id="6697" alt="Listado" />}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            account: true,
          },
        }}
        dataTableColumns={[
          {
            field: "proveedor.descripcion",
            header: <LanguageProvider id="24388" alt="x" />,
          },
          {
            field: "tipoDeProveedor.descripcion",
            header: <LanguageProvider id="16276" alt="x" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
            body: (rowData: any) => (
              <span>{new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}</span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          pais: null,
          activo: false,
          proveedor: null,
          tipoDeProveedor: null,
          tipoDeProveedores: [],
          fechaUltimaModificacion: null,
          usuarioUltimaModificacion: null,
        }}
        formSettings={[
          {
            "field": "id",
            "label": LanguageProvider({ id: "6590", alt: "Código" }),
            "type": "inputText",
            "length": 6
          }
        ]}
        filterSettings={[
          {
            filter: "proveedor",
            label: LanguageProvider({ id: "24388", alt: "Proveedores" }),
            placeholder: LanguageProvider({ id: "24388", alt: "Proveedores" }),
            type: "dropdown",
            dataSource: loadProveedores,
            dropDownTemplate: DropdownOptionTemplate,
            dependency: ["securityValues.country"],
            required: true
          },

        ]}
        customActions={
          <>
            <Button
              label="Borrar"
              onClick={() => {
                remove(abmRef)
              }}
            />
            <Button
              label="Modificar"
              onClick={() => {
                handleModificar(abmRef)
              }}
            />
            <Button
              label="Alta"
              onClick={() => {
                handlerAlta(abmRef);
              }}
            />
          </>
        }
        list={handleList}
        get={handleGet}
      />
      <Dialog
        visible={displayLocalAlta}
        onHide={() => setDisplayLocalAlta(false)}
        className="modalComprasTallerVer"

      >
        <div className="column-form">
          <div className="form">
            <div className="sipco-option">
              <PickList
                source={items.source}
                target={items.target}
                onChange={(e) => setItems(e)}
                itemTemplate={(item) => <span>{item.descripcion}</span>}
                sourceHeader="Tipo de Proveedor - Disponíveis"
                targetHeader="Tipo de Proveedor - Selecionados"
                dataKey="id"
              />
            </div>
            <div className="sipco-option">
              <div className="form-row">
                <label>Activo</label>
                <Checkbox
                  checked={filterModel.activo}
                  value={filterModel.activo}
                  onChange={(e: any) => setFilterModel({ ...filterModel, activo: e.target.checked })}
                />
              </div>
            </div>

            <div className="sipco-option">
              <div className="form-row">

                <Button
                  label="Confirmar"
                  onClick={(e: any) => save(abmRef, filterModel)}
                />

                <Button
                  label="Cancelar"
                  onClick={(e: any) => setDisplayLocalAlta(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={displayLocalModificar}
        onHide={() => setDisplayLocalModificar(false)}
        className="modalComprasTallerVer"
      >


        <div className="column-form">

          <div className="sipco-option">
            <PickList
              source={items.source}
              target={items.target}
              onChange={(e) => setItems(e)}
              itemTemplate={(item) => <span>{item.descripcion}</span>}
              sourceHeader="Tipo de Proveedor - Disponíveis"
              targetHeader="Tipo de Proveedor - Selecionados"
              dataKey="id"

            />
          </div>
          <div className="sipco-option">
            <div className="form-row">
              <label>Activo</label>
              <Checkbox
                checked={filterModel.activo}
                value={filterModel.activo}
                disabled
                onChange={(e: any) => setFilterModel({ ...filterModel, activo: e.target.checked })}
              />
            </div>
          </div>


          <div className="sipco-option" style={{ marginTop: "1em" }}>
            <div className="form-row">

              <Button
                label="Confirmar"
                onClick={(e: any) => update(filterModel, abmRef)}
                disabled
              />

              <Button
                label="Cancelar"
                onClick={(e: any) => setDisplayLocalAlta(false)}
                disabled
              />
            </div>
          </div>
        </div>

      </Dialog>
    </>
  );
};

export default MantenerProveedoresTipos;


const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
