import GenericAbm from "@shared/components/genericAbm/generic-abm";
import React, { useRef } from "react";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import {  CoeficienteFlete } from "src/models/CoeficienteFlete";

const MantenerCoeficientesFletes = () => {
  const SCREEN_CODE = "abm0121_mantener_coeficientes_fletes";
  const URL_BASE = "/mantener-coeficientes-fletes";
  const SCREEN_TITLE = (
    <LanguageProvider id="10485" alt="Mantener Coeficientes de Fletes" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: CoeficienteFlete, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.account &&
        filterValues?.securityValues?.account
      ) {
        const coeficienteFlete = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues?.securityValues?.client,
          cuenta: filterValues?.securityValues?.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          coeficienteFlete,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: CoeficienteFlete, filterValues: any) {
    try {
      const coeficienteFlete = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues?.securityValues?.client,
        cuenta: filterValues?.securityValues?.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        coeficienteFlete,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: CoeficienteFlete, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        coeficienteFlete: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<CoeficienteFlete[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.account &&
      filter?.securityValues?.account
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,
          }
        );
        if (status === 200)
          if (data.length > 0) {
            return data;
          } else {
            return [];
          }
      } catch (error) {
        console.error(error);
      }
    }

    return [];
  }
  async function handleGet(model: any): Promise<CoeficienteFlete> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          coeficienteFlete: { ...model },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<CoeficienteFlete>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
          },
        }}
        // filterSettings={}
        dataTableColumns={[
          {
            field: "coeficiente",
            header: <LanguageProvider id="10486" alt="x" />,
            body: (rowData: any) => rowData.coeficiente.toFixed(2), // Formatação para 2 casas decimais
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>{new Date(rowData.fechaUltimaModificacion.timestamp).toLocaleString()}</span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]
        }
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          activo: true,
          pais: null,
          cliente: null,
          cuenta: null,
          coeficiente:0,
        }}
        formSettings={[
          {
            field: "id",
            label: LanguageProvider({ id: "6590", alt: "x" }),
            type: "inputText",
            disabled: true
          },
          {
            field: "coeficiente",
            label: LanguageProvider({ id: "10486", alt: "x" }),
            type: "inputNumber",
            max: 99,            
            maxFractionDigits: 2,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          }
        ]
        }
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default MantenerCoeficientesFletes;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
