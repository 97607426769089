import { DistanciasPlayaDireccionEntrega } from "src/models/DistanciasPlayaDireccionEntrega";
import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerDistPlayaEntregas = () => {
  const SCREEN_CODE = "abm0009_mantener_dist_playa_entregas";
  const URL_BASE = "/mantener-dist-playa-entregas";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(
    model: DistanciasPlayaDireccionEntrega,
    filterValues: any
  ) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client
      ) {
        const distanciasPlayaDireccionEntrega = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
          concesionario: filterValues?.concesionario,
          direccionEntrega: filterValues?.provincia,
          fechaHora: {
            timestamp: model.fechaHora,
          },
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
          distanciasPlayaDireccionEntrega: distanciasPlayaDireccionEntrega,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  //bug com ids em string
  async function handleUpdate(
    model: DistanciasPlayaDireccionEntrega,
    filterValues: any
  ) {
    try {
      const distanciasPlayaDireccionEntrega = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
        concesionario: filterValues?.concesionario,
        direccionEntrega: filterValues?.provincia,
        fechaHora: {
          timestamp: model.fechaHora,
        },
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
        distanciasPlayaDireccionEntrega: distanciasPlayaDireccionEntrega,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(
    model: DistanciasPlayaDireccionEntrega,
    filterValues: any
  ) {
    try {
      const distanciasPlayaDireccionEntrega = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
        concesionario: filterValues?.concesionario,
        direccionEntrega: filterValues?.provincia,
        fechaHora: {
          timestamp: model.fechaHora,
        },
      };
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
        distanciasPlayaDireccionEntrega: distanciasPlayaDireccionEntrega,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(
    filter: any
  ): Promise<DistanciasPlayaDireccionEntrega[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter.securityValues.account,
          subcuenta: filter.securityValues.subaccount,
          concesionario: filter.concesionario,
          direccionEntrega: filter.provincia,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(
    model: any
  ): Promise<DistanciasPlayaDireccionEntrega> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findFullByPk",
        {
          distanciasPlayaDireccionEntrega: model,
        }
      );
      if (status === 200) {
        return { ...data, fechaHora: new Date(data.fechaHora.timestamp) };
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadConcesionario(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/concesionario",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadDireccionEntrega(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.client &&
        filter.concesionario
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/direccion-entrega",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            concesionario: filter.concesionario,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadTramo(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/tramo",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<DistanciasPlayaDireccionEntrega>
      screenCode={SCREEN_CODE}
      title={
        <LanguageProvider
          id="268"
          alt="Mantener Distancias a Bocas de Entrega"
        />
      }
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: "",
        activo: true,
        descripcion: "",
        concesionario: null,
        cuenta: null,
        direccionEntrega: null,
        fechaHora: null,
        subcuenta: null,
        cliente: null,
        pais: null,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
          subaccount: true,
        },
      }}
      loadDropdownData={{ concesionario: loadConcesionario, tramo: loadTramo }}
      filterSettings={[
        {
          filter: "concesionario",
          label: LanguageProvider({ id: "266", alt: "Concesionarios" }),
          placeholder: LanguageProvider({ id: "266", alt: "Concesionarios" }),
          type: "dropdown",
          dataSource: loadConcesionario,
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
          required: true,
        },
        {
          filter: "provincia",
          label: LanguageProvider({ id: "530", alt: "Dirección de Entrega" }),
          placeholder: LanguageProvider({
            id: "530",
            alt: "Dirección de Entrega",
          }),
          type: "dropdown",
          dataSource: loadDireccionEntrega,
          dropDownTemplate: DropdownOptionDirEntregaTemplate,
          dependency: [
            "securityValues.country",
            "securityValues.client",
            "concesionario",
          ],
          required: true,
        },
      ]}
      dataTableColumns={[
        {
          field: "fechaHora.date",
          header: <LanguageProvider id="16310" alt="Fecha Hora Última" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(rowData.fechaHora.timestamp).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "tramo.descripcion",
          header: <LanguageProvider id="6590" alt="Código" />,
        },
        {
          field: "direccionEntrega.direccion",
          header: <LanguageProvider id="530" alt="Dirección de Entrega" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      formSettings={[
        {
          field: "tramo",
          label: <LanguageProvider id="10672" alt="Tramo" />,
          dropDownPlaceholder: LanguageProvider({ id: "10672", alt: "Tramo" }),
          type: "dropdown",
          dataSource: (filter: any) => loadTramo(filter),
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          field: "fechaHora",
          label: <LanguageProvider id="16310" alt="Fecha Hora Última" />,
          type: "calendar",
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
    />
  );
};

export default MantenerDistPlayaEntregas;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionDirEntregaTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.direccion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
