import "./export.scss";

import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import ExportExcelDataTableTransportOrder from "./excelExportFunc/export-excel-transport-order";
import React from "react";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import useAuth from "@shared/AuthContext";

interface DataTableResponse {
  data: any[]; // valores das linhas
  columns: any[]; // ids das colunas
  tab: { id?: number; alt?: string }; // ids das tabs(aba) da plhanilha
}

interface ExportButtonsTransportOrderProps {
  dt: React.RefObject<DataTable<any>>;
  data?: any[];
  dataArray?: DataTableResponse[];
  columns?: any[];
  screenName: string;
  filterProps?: any[];
  filter?: boolean;
  csv?: boolean;
  xls?: boolean;
  pdf?: boolean;
  customExcelExportFunction?: () => void;
}

const ExportButtonsTransportOrder: React.FC<ExportButtonsTransportOrderProps> = ({
  dt,
  data,
  dataArray,
  columns,
  screenName,
  filterProps,
  csv = false,
  xls = true,
  pdf = false,
  filter = false,
  customExcelExportFunction: customExcelExportFunctionTransportOrder,
}) => {
  const { user } = useAuth();

  const exportCSV = () => {
    dt.current?.exportCSV();
  };

  const exportPdf = () => {
    const doc = new jsPDF("p", "pt");
    autoTable(doc, {
      columns: columns.map((column) => column.label),
      body: data,
    });
    doc.save(`${screenName}.pdf`);
  };

  const callExportExcelTransportOrder = async () => {
    await ExportExcelDataTableTransportOrder({
      data,
      dataArray,
      columns,
      screenName,
      user,
      filterProps,
      filter,
    });
  };

  return (
    <div className="export-report-button-container">
      {csv && (
        <Button
          className="icon-centered"
          type="button"
          icon="pi pi-file"
          rounded
          onClick={exportCSV}
          data-pr-tooltip="CSV"
        />
      )}
      {xls && (
        <Button
          className="icon-centered"
          type="button"
          icon="pi pi-file-excel"
          severity="success"
          rounded
          onClick={customExcelExportFunctionTransportOrder || callExportExcelTransportOrder}
          data-pr-tooltip="XLS"
        />
      )}
      {pdf && (
        <Button
          type="button"
          icon="pi pi-file-pdf"
          severity="warning"
          rounded
          onClick={exportPdf}
          data-pr-tooltip="PDF"
        />
      )}
    </div>
  );
};

export default ExportButtonsTransportOrder;
