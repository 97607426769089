import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import useFilterColumnsIds from "../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { HttpStatusCode } from "axios";

const Manual: React.FC<{ value: any; state: boolean; }> = ({
  value,
  state
}) => {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const [loading, setLoading] = useState(false);
  const [reporte, setReporte] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const toast = useRef<Toast>(null);
  const filterColumnsIdManual = [1491, 1569, 5788, 8380, 2521, 1669];
  const columNames = useFilterColumnsIds(filterColumnsIdManual);

  const [filter, setFilter] = useState({
    resultado: null,
    proveedor: null,
    observacion: null,
  });


  async function loadGrid() {
    setLoading(true);
    try {
      if (value?.state?.pais !== null
        && value?.state?.cliente !== null
        && value?.state?.cuenta !== null && value?.vin) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-automatica/manual/operacion-pendiente",
          {
            pais: value?.state?.pais ?? null,
            cliente: value?.state?.cliente ?? null,
            cuenta: value?.state?.cuenta ?? null,
            vin: { id: value?.vin },
          }
        );

        if (status === HttpStatusCode.Ok) {
          setReporte(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }


  const [resultado, setResultado] = useState([]);
  const [loadingResultado, setLoadingResultado] = useState(false);
  async function loadResulto(operacionPendiente: any) {
    setLoadingResultado(true);
    try {
      if (operacionPendiente && value?.state?.pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-automatica/manual/resultado-operacion",
          {

            lineaOperacion: operacionPendiente?.lineaOperacionRegistrada?.lineaOperacion,
            operacion: operacionPendiente?.operacion,
            pais: value?.state?.pais ?? null,
          }
        );

        if (status === HttpStatusCode.Ok) {
          setResultado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingResultado(false);
    }
  }


  const [proveedor, setProveedor] = useState([]);
  const [loadingProveedor, setLoadingProveedor] = useState(false);
  async function loadProveedor(operacionPendiente: any) {
    setLoadingProveedor(true);
    try {
      if (operacionPendiente) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-automatica/manual/proveedor",
          {
            operacion: operacionPendiente?.operacion,
            cuenta: value?.state?.cuenta ?? null,
            pais: value?.state?.pais ?? null,
          }
        );

        if (status === HttpStatusCode.Ok) {
          setProveedor(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedor(false);
    }
  }

  const [loadingRegistrar, setLoadingRegistrar] = useState(false);
  async function registrarOperacion(operacionPendiente: any) {
    try {
      console.log("operacionPendiente", operacionPendiente);
      setLoadingRegistrar(true);
      const { status, data } = await sipcoAxiosService.post(
        "/registracion-operacion-automatica/lector/registrar-operacion",
        {
          pais: value?.state?.pais ?? null,
          cliente: value?.state?.cliente ?? null,
          cuenta: value?.state?.cuenta ?? null,
          subcuenta: value?.state?.subcuenta ?? null,
          fechaRealizacion: new Date(value.fechaRealizacion ?? null).getTime(),
          lineasOperacion: [operacionPendiente],
        }
      );

      if (status !== HttpStatusCode.BadRequest) {
        toast.current?.show({
          severity: "success",
          summary: LanguageProvider({ id: "14394", alt: "Operación" }),
          detail: LanguageProvider({ id: "14395", alt: "Operación Registrada" }),
          life: 3000,
        });
        return data;

      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRegistrar(false);
    }
  }

  const [loadingRealizarOperacion, setLoadingRealizarOperacion] = useState(false);
  async function realizarOperacion(operacionPendiente: any) {
    try {
      setLoadingRealizarOperacion(true);
      const { status, data } = await sipcoAxiosService.post(
        "/registracion-operacion-automatica/manual/operacion-registrada",
        {
          pais: value?.state?.pais ?? null,
          cliente: value?.state?.cliente ?? null,
          cuenta: value?.state?.cuenta ?? null,
          subcuenta: value?.state?.subcuenta ?? null,
          fechaRealizacion: new Date(value.fechaRealizacion ?? null).getTime(),
          lineasOperacion: [operacionPendiente],
        }
      );

      if (status !== HttpStatusCode.BadRequest) {
        toast.current?.show({
          severity: "success",
          summary: LanguageProvider({ id: "14394", alt: "Operación" }),
          detail: LanguageProvider({ id: "14395", alt: "Operación Registrada" }),
          life: 3000,
        });
        return data;

      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRealizarOperacion(false);
    }
  }


  // Method´s Handles
  async function handleSelectedRows(operacionPendiente: any) {
    setSelectedRow(operacionPendiente);
    const [resultado, proveedor] = await Promise.all([
      loadResulto(operacionPendiente),
      loadProveedor(operacionPendiente)
    ]);

    setFilter({
      ...filter,
      resultado,
      proveedor
    });
  }


  // Load´s 
  useEffect(() => {
    if (state) {
      loadGrid();
    }
  }, [state]);



  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{
        display: "flex",
        justifyContent: "flex-end"
      }}>
        <Button
          label="Buscar"
          icon="pi pi-search"
          style={{
            display: "flex",
            justifyContent: "center", marginBottom: "10px",
          }}
          onClick={loadGrid}
        />
      </div>
      <div>
        <ConfirmDialog />
      </div>
      <Toast ref={toast} position="bottom-left" />
      <SipcoDataTable
        size={"small"}
        showGridlines
        stripedRows
        value={reporte}
        paginator
        scrollHeight="flex"
        filterDisplay="menu"
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        style={{ maxWidth: "100%" }}
        loading={loading}
        selectionMode="single"
        onRowSelect={(e) => {
          handleSelectedRows(e.data);
        }}
        selection={selectedRow}
      >
        <SipcoColumn field="vin.id" header={columNames[0].label} />
        <SipcoColumn
          field="operacion.descripcion"
          header={columNames[1].label}
        />
        <SipcoColumn
          field="lineaOperacionRegistrada.lineaOperacion.descripcion"
          header={columNames[2].label}
        />
        <SipcoColumn
          header={columNames[3].label}
          body={(rowData) => {
            return rowData?.lineaOperacionRegistrada?.valor;
          }}
        />
        <SipcoColumn
          header={columNames[4].label}
          body={(rowData) => {
            return rowData?.lineaOperacionRegistrada?.operacionRegistrada
              ?.proveedorOperacion?.descripcion;
          }}
        />
        <SipcoColumn
          field="lineaOperacionRegistrada.operacionRegistrada.observaciones"
          header={columNames[5].label}
        />
      </SipcoDataTable>
      <div className="filter-options">
        <div className="sipco-options-line">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2785"} alt="Operación" />
            </label>
            <SipcoInputText
              value={selectedRow?.operacion?.descripcion}
              disabled={true}
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"10828"} alt="Linea" />
            </label>
            <SipcoInputText
              value={selectedRow?.lineaOperacionRegistrada?.lineaOperacion?.descripcion}
              disabled={true}
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"8380"} alt="Resultado" />
            </label>
            <SipcoDropdown
              loading={loadingResultado}
              value={filter.resultado}
              options={resultado}
              onChange={(e) => setFilter({ ...filter, resultado: e.value })}
              optionLabel="descripcion"
            />
          </div>
        </div>
        <div className="sipco-options-line">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2521"} alt="Proveedor" />
            </label>
            <SipcoDropdown
              value={filter.proveedor}
              options={proveedor.map((item) => ({
                ...item,
                optionLabel: `${item.descripcion} (${item.id})`,
              }))}
              onChange={(e) => setFilter({ ...filter, proveedor: e.value })}
              loading={loadingProveedor}
              optionLabel="optionLabel"
              filter
              showClear
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1669"} alt="Observación" />
            </label>
            <SipcoInputText
              value={filter?.observacion}
              onChange={(e) =>
                setFilter({ ...filter, observacion: e.target.value })
              }
            />
          </div>
          <div className={"sipco-option"}>
            <Button
              className="button"
              icon="pi pi-check"
              iconPos="right"
              severity="success"
              label={LanguageProvider({
                id: "14394",
                alt: "Registrar Operación",
              })}
              onClick={() => registrarOperacion(selectedRow)}
              loading={loadingRegistrar}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
        <Button
          label={LanguageProvider({
            id: "14391",
            alt: "Realizar Operación",
          })}
          onClick={() => realizarOperacion(selectedRow)}
          loading={loadingRealizarOperacion}
        />
        <Button
          label={LanguageProvider({
            id: "14915",
            alt: "Cancelar",
          })}
          onClick={() => setSelectedRow({...selectedRow})}
        />
      </div>
    </div>
  );
};
export default Manual;
