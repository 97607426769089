import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { DenominacionComercial } from "src/models/DenominacionComercial";

const MantenerDenominacionComercial = () => {
  const SCREEN_CODE = "abm0204_mantener_denominacion_comercial";
  const URL_BASE = "/mantener-denominacion-comercial";
  const SCREEN_TITLE = (
    <LanguageProvider id="18201" alt="Mantener Denominación Comercial" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: DenominacionComercial, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client
      ) {
        const denominacionComercial = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          codigo: filterValues?.descripcion
            ? filterValues?.descripcion
            : "",
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          denominacionComercial,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: DenominacionComercial, filterValues: any) {
    try {
      const denominacionComercial = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        codigo: filterValues?.descripcion,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        denominacionComercial,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: DenominacionComercial, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        denominacionComercial: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<DenominacionComercial[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            codigo: filter?.descripcion ? filter?.descripcion : "",
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<DenominacionComercial> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          denominacionComercial: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarcas(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/marca",
          {
            pais: filter.securityValues.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<DenominacionComercial>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
          },
        }}
        filterSettings={[
          {
            filter: "descripcion",
            label: LanguageProvider({
              id: "16740",
              alt: "Codigo",
            }),
            type: "inputText",
          },
        ]}
        dataTableColumns={[
          { field: "id", header: <LanguageProvider id="6590" alt="Código" /> },
          {
            field: "descripcion",
            header: <LanguageProvider id="18202" alt="Descripcion" />,
          },
          {
            field: "marca.descripcion",
            header: <LanguageProvider id="396" alt="x" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: (
              <LanguageProvider id="5271" alt="Fec. Ult. Modifcicacion" />
            ),
            body: (rowData: any) => {
              return (
                <span>
                  {new Date(
                    rowData.fechaUltimaModificacion.date
                  ).toLocaleString()}
                </span>
              );
            },
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => {
              return rowData.activo ? "SI" : "NO";
            },
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          cliente: null,
          marca: null,
        }}
        formSettings={[
          {
            field: "marca",
            label: LanguageProvider({ id: "396", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "396", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadMarcas(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate: true
          },
          {
            field: "id",
            label: LanguageProvider({ id: "6590", alt: "x" }),
            type: "inputText",
            length: 35,
            disableOnUpdate: true
          },
          {
            field: "descripcion",
            label: LanguageProvider({ id: "18202", alt: "x" }),
            type: "inputText",
            length: 50,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default MantenerDenominacionComercial;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
