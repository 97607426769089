import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { ConcesionarioCodigosNostraModel } from "src/models/ConcesionarioCodigosNostraModel";

const ConcesionariosCodigosNostra = () => {
  const SCREEN_CODE = "abm0155_concesionarios_codigos_nostra";
  const URL_BASE = "/mantener-concesionarios-codigos-nostra";
  const SCREEN_TITLE = (
    <LanguageProvider id="12066" alt="Mantener Concesionarios Códigos Nostra" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(
    model: ConcesionarioCodigosNostraModel,
    filterValues: any
  ) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues.securityValues.client &&
        filterValues.securityValues.account
      ) {
        const concesionarioCodigosNostra = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          concesionario: filterValues.concesionarioFilter,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          concesionarioCodigosNostra,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(
    model: ConcesionarioCodigosNostraModel,
    filterValues: any
  ) {
    try {
      const concesionarioCodigosNostra = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        concesionario: filterValues.concesionarioFilter,
        cuenta: filterValues.securityValues.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        concesionarioCodigosNostra,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(
    model: ConcesionarioCodigosNostraModel,
    filterValues: any
  ) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        concesionarioCodigosNostra: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(
    filter: any
  ): Promise<ConcesionarioCodigosNostraModel[]> {
    if (filter?.securityValues?.country && filter.securityValues.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            concesionario: filter?.concesionarioFilter,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(
    model: any
  ): Promise<ConcesionarioCodigosNostraModel> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          concesionarioCodigosNostra: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarca(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/marca",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            return updatedData;
          }
          
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadConcesionario(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/concesionario",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.label}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadDireccionEntrega(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter.concesionario
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/direccion-entrega",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            concesionario: filter.concesionario,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.direccion}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ConcesionarioCodigosNostraModel>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
          },
        }}
        filterSettings={[
          {
            filter: "concesionarioFilter",
            label: LanguageProvider({ id: "266", alt: "x" }),
            placeholder: LanguageProvider({ id: "266", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadConcesionario(filter),
            dropDownTemplate: DropdownOptionTemplateConcesionario,            
            required: true,
            dependency: ["securityValues.country", "securityValues.client"],
            filterBy: "search"
          },
        ]}
        dataTableColumns={[
          {
            field: "concesionario.descripcion",
            header: <LanguageProvider id="266" alt="Concesionarios" />,
          },
          {
            field: "direccionEntrega.direccion",
            header: <LanguageProvider id="492" alt="Dirección Entrega" />,
          },
          {
            field: "marca.descripcion",
            header: <LanguageProvider id="396" alt="Marca" />,
          },
          {
            field: "destinoFrancia",
            header: <LanguageProvider id="12067" alt="Código Nostra" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          cuenta: null,
          cliente: null,
          concesionario: null,
          direccionEntrega: null,
          marca: null,
          destinoFrancia: "",
        }}
        formSettings={[
          {
            field: "marca",
            label: LanguageProvider({ id: "396", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "396", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadMarca(filter),
            dropDownTemplate: DropdownOptionTemplate,
            filterBy: "search",
            disableOnUpdate: true,
          },
          {
            field: "concesionario",
            label: LanguageProvider({ id: "266", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "266", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadConcesionario(filter),
            dropDownTemplate: DropdownOptionTemplateConcesionario,
            filterBy: "search",
            disableOnUpdate: true,
          },
          {
            field: "direccionEntrega",
            label: LanguageProvider({ id: "492", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "492", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadDireccionEntrega(filter),
            dropDownTemplate: DropdownOptionTemplateDireccion,
            dependency: ["concesionario"],
            filterBy: "search",
            disableOnUpdate: true,
          },
          {
            field: "destinoFrancia",
            label: LanguageProvider({ id: "12067", alt: "x" }),
            type: "inputText",
            length: 9,
            capitalizeInputText: true,
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default ConcesionariosCodigosNostra;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionTemplateConcesionario = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionTemplateDireccion = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.direccion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
