import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { NotaPedidoFlete } from "src/models/NotaPedidoFlete";

const MantenerNotaPedidoFlete = () => {
  const SCREEN_CODE = "abm0132_mantener_nota_pedido_flete";
  const URL_BASE = "/mantener-nota-pedido-flete";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: NotaPedidoFlete, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const notaPedidoFlete = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
          marca: filterValues.marca,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          notaPedidoFlete: notaPedidoFlete,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: NotaPedidoFlete) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/update", {
        notaPedidoFlete: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: NotaPedidoFlete) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        notaPedidoFlete: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<NotaPedidoFlete[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter?.securityValues?.country,
          cliente: filter?.securityValues?.client,
          cuenta: filter?.securityValues?.account,
          subcuenta: filter?.securityValues?.subaccount,
          marca: filter?.marca,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<NotaPedidoFlete> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          notaPedidoFlete: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarca(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/marca",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<NotaPedidoFlete>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="10852" alt="Mantener Nota Pedido Flete" />}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        cuenta: null,
        subcuenta: null,
        marca: null,
        descripcion: "",
        pagaFlete: "",
        activo: true,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
          subaccount: true,
        },
      }}
      filterSettings={[
        {
          filter: "marca",
          label: LanguageProvider({ id: "23888", alt: "Marca" }),
          placeholder: LanguageProvider({ id: "23888", alt: "Marca" }),
          type: "dropdown",
          dataSource: loadMarca,
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
        },
      ]}
      dataTableColumns={[
        {
          field: "id",
          header: <LanguageProvider id="10854" alt="Cod. Nota Pedido" />,
        },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="Descripcion" />,
        },
        {
          field: "pagaFlete",
          header: <LanguageProvider id="10855" alt="Paga Flete" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="10854" alt="Cod. Nota Pedido" />,
          type: "inputText",
          disabled: true,
          required: true,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripcion" />,
          type: "inputText",
          required: true,
        },
        {
          field: "pagaFlete",
          label: <LanguageProvider id="10855" alt="Paga Flete" />,
          dropDownPlaceholder: LanguageProvider({
            id: "10855",
            alt: "Paga Flete",
          }),
          type: "dropdown",
          dataSource: async () =>
            Promise.resolve([
              { value: "", descripcion: "" },
              { value: "C", descripcion: "C" },
              { value: "D", descripcion: "D" },
            ]),
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
    />
  );
};

export default MantenerNotaPedidoFlete;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
