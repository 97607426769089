import React from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ReglaRegistracionOperacion } from "src/models/ReglaRegistracionOperacion";
import { sub } from "date-fns";

const ReglasRegistracionOperaciones = () => {
  const SCREEN_CODE = "abm0212_reglas_registracion_operaciones";
  const URL_BASE = "/mantener-reglas-registracion-operaciones";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ReglaRegistracionOperacion, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const reglaRegistracionOperacion = {
          ...model,
          pais: filterValues.securityValues.country,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
          operacion: model.operacion,
          transaccion: model.transaccion,
          tipoUbicacion: model.tipoUbicacion,
          tiempo: model.tiempo,
          activo: model.activo,

        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          reglaRegistracionOperacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ReglaRegistracionOperacion, filterValues: any) {
    try {
      const reglaRegistracionOperacion = {
        ...model,
        pais: filterValues.securityValues.country,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
        operacion: model.operacion,
        transaccion: model.transaccion,
        tipoUbicacion: model.tipoUbicacion,
        tiempo: model.tiempo,
        activo: model.activo,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        reglaRegistracionOperacion,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: ReglaRegistracionOperacion, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        reglaRegistracionOperacion: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ReglaRegistracionOperacion[]> {
    try {
      if (
        filter.securityValues.country !== null &&
        filter.securityValues.account !== null &&
        filter.securityValues.subaccount !== null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            pais: filter.securityValues.country,
            cuenta: filter.securityValues.account,
            subcuenta: filter.securityValues.subaccount,
          }
        );
        if (status === 200) return data;
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<ReglaRegistracionOperacion> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          reglaRegistracionOperacion: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }


  // Operações de modal
  async function loadOperacion(filter: any): Promise<ReglaRegistracionOperacion> {
    try {
      if (filter.securityValues.country !== null) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/operacion",
          {
            pais: filter.securityValues.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadTransaccion(filter: any): Promise<ReglaRegistracionOperacion> {
    try {
      if (filter.securityValues.country !== null) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/transaccion",
          {
            pais: filter.securityValues.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadTipoUbicacion(filter: any): Promise<ReglaRegistracionOperacion> {
    try {
      if (filter.securityValues.country !== null) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/tipo-ubicacion",
          {
            pais: filter.securityValues.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }


  return (
    <GenericAbm<ReglaRegistracionOperacion>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          account: true,
          subaccount: true,
        },
      }}
      dataTableColumns={[
        {
          field: "operacion.descripcion",
          header: <LanguageProvider id="18637" alt="Operación" />,
        },
        {
          field: "transaccion.descripcionFull",
          header: <LanguageProvider id="14288" alt="Transacción" />,
        },

        { field: "tipoUbicacion.id", header: <LanguageProvider id="24522" alt="Tipo Ubicación" /> },

        {
          field: "tiempo",
          header: <LanguageProvider id="19052" alt="Tiempo (en minutos)" />,
        },


        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="2131" alt="Fecha" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
        lineaOperacion: null,
        operacion: null,
        subcuenta: null,
        cuenta: null,
        transaccion: null,
        tiempo: 0,
        tipoUbicacion: null,
      }}
      formSettings={[
        {
          field: "operacion",
          label: <LanguageProvider id="1569" alt="Operación" />,
          type: "dropdown",
          dataSource: (filter: any) => loadOperacion(filter),
          optionLabel: "label",
          disableOnUpdate: true,
        },
        {
          field: "transaccion",
          label: <LanguageProvider id="14288" alt="Transacción" />,
          type: "dropdown",
          dataSource: (filter: any) => loadTransaccion(filter),
          optionLabel: "descripcion",
          disableOnUpdate: true,
        },

        {
          field: "tipoUbicacion",
          label: <LanguageProvider id="24522" alt="Tipo Ubicación" />,
          type: "dropdown",
          dataSource: (filter: any) => loadTipoUbicacion(filter),
          optionLabel: "descripcion",
          disableOnUpdate: true,
        },
        {
          field: "tiempo",
          label: <LanguageProvider id="4586" alt="Tiempo" />,
          type: "inputText",
          length: 4,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default ReglasRegistracionOperaciones;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
