import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { CupoDespacho } from "src/models/CupoDespacho";

const MantenerCupoDespacho = () => {
  const SCREEN_CODE = "abm0222_mantener_cupo_despacho";
  const URL_BASE = "/mantener-cupo-despacho";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: CupoDespacho, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const cupoDespacho = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
          marca: model.marca,
          corredor: model.corredor,
          tipoVehiculo: model.tipoVehiculo,
          cantidad: model.cantidad,
          cantidadBatea: model.cantidadBatea,
          activo: model.activo,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          cupoDespacho,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: CupoDespacho, filterValues: any) {
    try {
      const cupoDespacho = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
        marca: model.marca,
        corredor: model.corredor,
        tipoVehiculo: model.tipoVehiculo,
        cantidad: model.cantidad,
        cantidadBatea: model.cantidadBatea,
        activo: model.activo,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        cupoDespacho,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  } 
  async function handleRemove(model: CupoDespacho, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        cupoDespacho: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<CupoDespacho[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter.securityValues.account,
          subcuenta: filter.securityValues.subaccount,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<CupoDespacho> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          cupoDespacho: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadCorredor(filter: any): Promise<CupoDespacho> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/corredor",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarca(filter: any): Promise<CupoDespacho> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/marca",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadTipoVeiculos(filter: any): Promise<CupoDespacho> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/tipo-vehiculo",
        {
          pais: filter.securityValues.country,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }



  return (
    <GenericAbm<CupoDespacho>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
          subaccount: true,
        },
      }}
      dataTableColumns={[
        { field: "corredor.descripcion", header: <LanguageProvider id="10502" alt="Corredor" /> },
        {
          field: "marca.descripcion",
          header: <LanguageProvider id="396" alt="Marca" />,
        },
        {
          field: "tipoVehiculo.descripcion",
          header: <LanguageProvider id="16782" alt="Tipo de Vehículo" />,
        },
        {
          field: "cantidad",
          header: <LanguageProvider id="1253" alt="Cantidad" />,
        },
        {
          field: "cantidadBatea",
          header: <LanguageProvider id="19764" alt="Cantidad Batea" />,
        },
        
        {
          field: "usuarioUltimaModificacion.id",
          header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => rowData.activo ? "SI" : "NO",
        },
      ]
      }
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
        cliente: null,
        cuenta: null,
        subcuenta: null,
        marca: null,
        corredor: null,
        tipoVehiculo: null,
        cantidadBatea: 0,
        cantidad: 0,
      }}
      formSettings={[
        {
          field: "corredor",
          label: <LanguageProvider id="10502" alt="Corredor" />,
          type: "dropdown",
          dataSource: filter => loadCorredor(filter),
          optionLabel: "descripcion",
          disableOnUpdate: true,
        },
        {
          field: "marca",
          label: <LanguageProvider id="396" alt="Marca" />,
          type: "dropdown",
          dataSource: filter => loadMarca(filter),
          optionLabel: "descripcion",
          disableOnUpdate: true,
        },
        {
          field: "tipoVehiculo",
          label: <LanguageProvider id="16782" alt="Tipo de Vehículo" />,
          type: "dropdown",
          dataSource: filter => loadTipoVeiculos(filter),
          optionLabel: "descripcion",
          disableOnUpdate: true,
        },
        {
          field: "cantidad",
          label: <LanguageProvider id="1253" alt="Cantidad" />,
          type: "inputText",
          length: 5,
        },
        {
          field: "cantidadBatea",
          label: <LanguageProvider id="19764" alt="Cantidad Batea" />,
          type: "inputText",
          length: 2,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerCupoDespacho;
