import React, { useRef } from "react";

import { CargoTaller } from "src/models/CargoTaller";
import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerParamCargosTalleres = () => {
  const SCREEN_CODE = "abm0086_mantener_cargos_talleres";
  const URL_BASE = "/mantener-cargo-tallec";
  const SCREEN_TITLE = (
    <LanguageProvider id="2066" alt="Mantener Cargos Talleres" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: CargoTaller, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.account &&
        filterValues?.securityValues?.client
      ) {
        const cargoTaller = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues?.securityValues?.client,
          cuenta: filterValues?.securityValues?.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          cargoTaller,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: CargoTaller, filterValues: any) {
    try {
      const cargoTaller = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues?.securityValues?.client,
        cuenta: filterValues?.securityValues?.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        cargoTaller,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: CargoTaller, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        cargoTaller: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<CargoTaller[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.account &&
      filter?.securityValues?.client
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,
          }
        );
        if (status === 200)
          if (data.length > 0) {
            return data;
          } else {
            return [];
          }
      } catch (error) {
        console.error(error);
      }
    }

    return [];
  }
  async function handleGet(model: any): Promise<CargoTaller> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          cargoTaller: { ...model },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<CargoTaller>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            account: true,
            client: true,
          },
        }}
        dataTableColumns={[
          {
            field: "id",
            header: <LanguageProvider id="6590" alt="Código" />,
          },
          {
            field: "descripcion",
            header: <LanguageProvider id="12419" alt="Descripcion" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          activo: true,
          pais: null,
          cliente: null,
          cuenta: null,
          cargoTaller: null,
        }}
        formSettings={[
          {
            field: "id",
            label: LanguageProvider({ id: "6590", alt: "Código" }),
            type: "inputNumber",
            disabled: true,
          },
          {
            field: "descripcion",
            label: LanguageProvider({ id: "12419", alt: "Descripcion" }),
            type: "inputText",
            length: 50,
            required: true,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default MantenerParamCargosTalleres;
