import "./ModalVerMantenerCatalogoRepuestos.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

interface InterfaceModalVerMantenerCatalogoRepuestos {
  visible: boolean;
  onClose: () => void;
  model?: any;
  onConfirm?: (updatedList: any[]) => void;
  selected?: any[];
  values?: any;
}

const ModalVerMantenerCatalogoRepuestos: React.FC<
  InterfaceModalVerMantenerCatalogoRepuestos
> = ({ visible, onClose, model, onConfirm, selected, values }) => {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [rubro, setRubro] = useState([]);
  const [cuenta, setCuenta] = useState([]);
  const [grupo, setGrupo] = useState([]);
  const [modelo, setModelo] = useState([]);

  const [filter, setFilter] = useState({
    pieza: "",
    descripcion: "",
    costo: "",
    costoDollar: "",
    cantidad: "",
    fechaCotizacion: new Date(),
    peso: "",
    iva: "",
    rubro: null,
    cuentaTaller: null,
    grupoTaller: null,
    modeloTaller: null,
  });

  const data = convertDateObjects(reporte?.filas);
  useEffect(() => {
    const init = () => {
      if (visible) {
        setRubro([selected.map((x) => x.rubro)[0]]);
        setCuenta([selected.map((x) => x.cuentaTaller)[0]]);
        setGrupo([selected.map((x) => x.grupoTaller)[0]]);
        setModelo([selected.map((x) => x.modeloTaller)[0]]);

        console.log(selected);

        setFilter({
          ...filter,
          fechaCotizacion: new Date(selected.map((x) => x.fechaCotizacion.date)[0]),
          iva: selected.map((x) => x.iva)[0],
          peso: selected.map((x) => x.peso)[0],
          costo: selected.map((x) => x.costoPeso)[0],
          costoDollar: selected.map((x) => x.costoDolar)[0],
          cantidad: selected.map((x) => x.cantidad)[0],
          descripcion: selected.map((x) => x.descripcion)[0],
          pieza: selected.map((x) => x.id)[0],
          rubro: selected.map((x) => x.rubro)[0],
          cuentaTaller: selected.map((x) => x.cuentaTaller)[0],
          grupoTaller: selected.map((x) => x.grupoTaller)[0],
          modeloTaller: selected.map((x) => x.modeloTaller)[0],
        });
      }
    };

    init();
  }, [visible || selected]);
  return (
    <div>
      <Dialog
        className="modalVerReparacionProveedores"
        header="Orden Reparación Proveedores - Alta"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={LanguageProvider({ id: "9491", alt: "Aceptar" })}
                icon="pi pi-check"
                onClick={onClose}
                autoFocus
              />
              <Button
                label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                icon="pi pi-times"
                onClick={onClose}
                className="p-button-text"
              />
            </div>
          );
        }}
      >
        <div className="modal-container">
          <div className="form-modal">
            <div className="form-row-modal">
              <label>
                <LanguageProvider id={"2020"} alt="Pieza" />
              </label>
              <SipcoInputText
                value={filter.pieza}
                onChange={(e) =>
                  setFilter({ ...filter, pieza: e.target.value })
                }
                className="input-modal"
                disabled={true}
              />
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"12419"} alt="Descripcion" />
                </label>
                <SipcoInputText
                  value={filter.descripcion}
                  onChange={(e) =>
                    setFilter({ ...filter, descripcion: e.target.value })
                  }
                  className="input-modal"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"16953"} alt="Costo($)" />
                </label>
                <SipcoInputText
                  value={filter.costo}
                  onChange={(e) =>
                    setFilter({ ...filter, costo: e.target.value })
                  }
                  className="input-modal"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"16954"} alt="Costo(U$D)" />
                </label>
                <SipcoInputText
                  value={filter.costoDollar}
                  onChange={(e) =>
                    setFilter({ ...filter, costoDollar: e.target.value })
                  }
                  className="input-modal"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"1253"} alt="Cantidad" />
                </label>
                <SipcoInputText
                  value={filter.cantidad}
                  onChange={(e) =>
                    setFilter({ ...filter, cantidad: e.target.value })
                  }
                  className="input-modal"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"6256"} alt="Fecha Cotización" />
                </label>
                <SipcoCalendar
                  value={filter.fechaCotizacion}
                  onChange={(e) =>
                    setFilter({ ...filter, fechaCotizacion: e.value })
                  }
                  className="input-modal"
                  showIcon
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"16955"} alt="Peso(Gr.)" />
                </label>
                <SipcoInputText
                  value={filter.peso}
                  onChange={(e) =>
                    setFilter({ ...filter, peso: e.target.value })
                  }
                  className="input-modal"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"16956"} alt="I.V.A (%)" />
                </label>
                <SipcoInputText
                  value={filter.iva}
                  onChange={(e) =>
                    setFilter({ ...filter, iva: e.target.value })
                  }
                  className="input-modal"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2183"} alt="Rubro" />
                </label>
                <SipcoDropdown
                  value={filter.rubro}
                  onChange={(e) => setFilter({ ...filter, rubro: e.value })}
                  options={rubro}
                  className="input-modal"
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2184"} alt="Cuenta Taller" />
                </label>
                <SipcoDropdown
                  value={filter.cuentaTaller}
                  onChange={(e) =>
                    setFilter({ ...filter, cuentaTaller: e.value })
                  }
                  options={cuenta}
                  className="input-modal"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2185"} alt="Grupo Taller" />
                </label>
                <SipcoDropdown
                  value={filter.grupoTaller}
                  onChange={(e) =>
                    setFilter({ ...filter, grupoTaller: e.value })
                  }
                  options={grupo}
                  className="input-modal"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2186"} alt="Modelo Taller" />
                </label>
                <SipcoDropdown
                  value={filter.modeloTaller}
                  onChange={(e) =>
                    setFilter({ ...filter, modeloTaller: e.value })
                  }
                  options={modelo}
                  className="input-modal"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default ModalVerMantenerCatalogoRepuestos;
