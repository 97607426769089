import GenericAbm from "@shared/components/genericAbm/generic-abm";
import React, { useRef } from "react";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ParametroRangoEstadia } from "src/models/ParametroRangoEstadia";

const ParamRangosEstadia = () => {
  const SCREEN_CODE = "abm0085_param_rangos_estadia";
  const URL_BASE = "/mantener-param-rangos-estadia";
  const SCREEN_TITLE = (
    <LanguageProvider id="1434" alt="Parametrización de Rangos por Estadía" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const empityFilter = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - 
  ${LanguageProvider({ id: "2639", alt: "Movimiento" })}`;

  async function handleSave(model: ParametroRangoEstadia, filterValues: any) {
    if (!filterValues?.movimiento) {
      toast.current?.show({
        severity: "warn",
        detail: empityFilter,
        life: 3000,
      });
      return false;
    }
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.account &&
        filterValues?.securityValues?.client &&
        filterValues?.securityValues?.subaccount
      ) {
        const parametroRangoEstadia = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues?.securityValues?.client,
          cuenta: filterValues?.securityValues?.account,
          subcuenta: filterValues?.securityValues?.subaccount,
          movimiento: filterValues?.movimiento,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          parametroRangoEstadia,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ParametroRangoEstadia, filterValues: any) {
    try {
      const parametroRangoEstadia = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues?.securityValues?.client,
        cuenta: filterValues?.securityValues?.account,
        subcuenta: filterValues?.securityValues?.subaccount,
        movimiento: filterValues?.movimiento,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        parametroRangoEstadia,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: ParametroRangoEstadia, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        parametroRangoEstadia: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ParametroRangoEstadia[]> {
    if (!filter?.movimiento) {
      toast.current?.show({
        severity: "warn",
        detail: empityFilter,
        life: 3000,
      });
      return [];
    }
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.account &&
      filter?.securityValues?.client &&
      filter?.securityValues?.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            movimiento: filter?.movimiento,
          }
        );
        if (status === 200)
          if (data.length > 0) {
            return data;
          } else {
            return [];
          }
      } catch (error) {
        console.error(error);
      }
    }

    return [];
  }
  async function handleGet(model: any): Promise<ParametroRangoEstadia> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          parametroRangoEstadia: { ...model },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMovimiento(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find-movimiento-estadia-by",
          { pais: filter?.securityValues?.country }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadTipoParametro(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find-tipo-parametro-estadia",
          {
            pais: filter?.securityValues.country,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadNombreParametro(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.tipoParametro) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find-nombre-parametro-by",
          {
            pais: filter?.securityValues.country,
            tipoParametro: filter?.tipoParametro,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadDescripcionUnidades(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find-descripcion-unidad-by",
          {
            pais: filter?.securityValues.country,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ParametroRangoEstadia>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            account: true,
            client: true,
            subaccount: true,
          },
        }}
        filterSettings={[
          {
            filter: "movimiento",
            label: LanguageProvider({ id: "2639", alt: "Movimiento" }),
            placeholder: LanguageProvider({ id: "2639", alt: "Movimiento" }),
            type: "dropdown",
            dataSource: loadMovimiento,
            dropDownTemplate: DropdownOptionTemplate,
            required: true,
            dependency: ["securityValues.country"],
          },
        ]}
        dataTableColumns={[
          {
            field: "tipoParametro.descripcion",
            header: <LanguageProvider id="16315" alt="x" />,
          },
          {
            field: "nombreParametro.descripcion",
            header: <LanguageProvider id="16316" alt="x" />,
          },
          {
            field: "tope",
            header: <LanguageProvider id="16317" alt="x" />,
          },
          {
            field: "descripcionUnidad.descripcion",
            header: <LanguageProvider id="16318" alt="x" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          activo: true,
          pais: null,
          cliente: null,
          cuenta: null,
          subcuenta: null,
          tipoParametro: null,
          nombreParametro: null,
          tope: 0,
          descripcionUnidad: null,
          movimiento: null,
        }}
        formSettings={[
          {
            field: "tipoParametro",
            label: LanguageProvider({ id: "16315", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "16315", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadTipoParametro(filter),
            dropDownTemplate: DropdownOptionTemplate,
            required: true,
            disableOnUpdate: true,
          },
          {
            field: "nombreParametro",
            label: LanguageProvider({ id: "16316", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "16316", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadNombreParametro(filter),
            dropDownTemplate: DropdownOptionTemplate,
            required: true,
            dependency: ["tipoParametro"],
            disableOnUpdate: true,
          },
          {
            field: "tope",
            label: LanguageProvider({ id: "16317", alt: "x" }),
            type: "inputNumber",
            max: 999999,
            useGrouping: false,
            required: true,
          },
          {
            field: "descripcionUnidad",
            label: LanguageProvider({ id: "16318", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "16318", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadDescripcionUnidades(filter),
            dropDownTemplate: DropdownOptionTemplate,
            required: true,
          },
        ]}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default ParamRangosEstadia;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
