import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { CoeficienteFamiliaTransporte } from "src/models/CoeficienteFamiliaTransporte";

const MantenerCoeficientesFamiliaTransporte = () => {
  const SCREEN_CODE = "abm0213_mantener_coeficientes_familia_transporte";
  const URL_BASE = "/coeficiente-familia-transporte";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(
    model: CoeficienteFamiliaTransporte,
    filterValues: any
  ) {
    try {
      if (filterValues?.securityValues?.country) {
        const coeficienteFamiliaTransporte = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues?.securityValues.client,
          cuenta: filterValues?.securityValues.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          coeficienteFamiliaTransporte: coeficienteFamiliaTransporte,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(
    model: CoeficienteFamiliaTransporte,
    filterValues: any
  ) {
    try {
      const coeficienteFamiliaTransporte = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues?.securityValues.client,
        cuenta: filterValues?.securityValues.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        coeficienteFamiliaTransporte: coeficienteFamiliaTransporte,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: CoeficienteFamiliaTransporte) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        coeficienteFamiliaTransporte: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(
    filter: any
  ): Promise<CoeficienteFamiliaTransporte[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter?.securityValues.country,
          cuenta: filter?.securityValues.account,
          marcas: filter?.marcas,
          familias: filter?.familias,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<CoeficienteFamiliaTransporte> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          coeficienteFamiliaTransporte: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadFilterMarca(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/marcas",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadMarca(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/marca",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadFamilia(filter): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.client &&
        filter?.marca
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/familia",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            coeficienteFamiliaTransporte: {
              marca: filter?.marca,
            },
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadTipoVehiculo(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/tipo-vehiculo",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<CoeficienteFamiliaTransporte>
      screenCode={SCREEN_CODE}
      title={
        <LanguageProvider
          id="18983"
          alt="Mantener Coeficientes Familia Transporte"
        />
      }
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          allClientsOptions: true,
          account: true,
        },
      }}
      dataTableColumns={[
        {
          field: "marca.descripcion",
          header: <LanguageProvider id="396" alt="Marca" />,
        },
        {
          field: "familia.descripcion",
          header: <LanguageProvider id="10034" alt="Familia" />,
        },
        {
          field: "tipoVehiculo.id",
          header: <LanguageProvider id="16782" alt="Tipo de Vehículo" />,
        },
        {
          field: "coeficienteVenta",
          header: <LanguageProvider id="18984" alt="Coeficiente Venta" />,
        },
        {
          field: "coeficienteCompra",
          header: <LanguageProvider id="18985" alt="Coeficiente Compra" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        cuenta: null,
        marcas: null,
        marca: null,
        familias: null,
        tipoVehiculo: null,
        coeficienteVenta: null,
        coeficienteCompra: null,
        activo: true,
      }}
      filterSettings={[
        {
          filter: "marcas",
          label: LanguageProvider({ id: "23888", alt: "Marca" }),
          placeholder: LanguageProvider({ id: "23888", alt: "Marca" }),
          type: "multiselect",
          optionLabel: "label",
          dataSource: loadFilterMarca,
          //dropDownTemplate: DropdownLabelTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
          virtualizeDropdown: true,
        },
      ]}
      formSettings={[
        {
          field: "marca",
          label: <LanguageProvider id="23888" alt="Marca" />,
          dropDownPlaceholder: LanguageProvider({
            id: "23888",
            alt: "Marca",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadMarca(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "familia",
          label: <LanguageProvider id="10034" alt="Familia" />,
          dropDownPlaceholder: LanguageProvider({
            id: "10034",
            alt: "Familia",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadFamilia(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["marca"],
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "tipoVehiculo",
          label: <LanguageProvider id="16782" alt="Tipo de Vehículo" />,
          dropDownPlaceholder: LanguageProvider({
            id: "16782",
            alt: "Tipo de Vehículo",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadTipoVehiculo(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country"],
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "coeficienteVenta",
          label: <LanguageProvider id="18984" alt="Coeficiente Venta" />,
          type: "inputNumber",
          min: 0,
          max: 99,
          required: true,
        },
        {
          field: "coeficienteCompra",
          label: <LanguageProvider id="18985" alt="Coeficiente Compra" />,
          type: "inputNumber",
          min: 0,
          max: 99,
          required: true,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerCoeficientesFamiliaTransporte;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};

const DropdownLabelTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
