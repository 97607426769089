import React, { useRef } from "react";

import { EventoMail } from "src/models/EventoMail";
import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { LanguageProviderString } from "@shared/components/language-provider-string";
import { Toast } from "primereact/toast";
import useAuth from "@shared/AuthContext";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerEventosMails = () => {
  const SCREEN_CODE = "abm0181_eventos_mails";
  const URL_BASE = "/mantener-eventos-mail";
  const sipcoAxiosService = useSipcoAxiosService();
  const { user } = useAuth();
  const toast = useRef<Toast>(null);

  async function handleSave(model: EventoMail, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country && filterValues.evento) {
        const eventoMail = {
          ...model,
          pais: filterValues.securityValues.country,
        };
        const validateResult = await sipcoAxiosService.post(
          URL_BASE + "/form/validate",
          { eventoMail: eventoMail }
        );
        if (validateResult.status === 200) {
          if (validateResult.data != "") {
            toast.current?.show({
              severity: "warn",
              summary: LanguageProviderString({
                id: "TODO",
                alt: "Atencion",
                user,
              }),
              detail: LanguageProviderString({
                id: "12717",
                alt: "El evento ya existe",
                user,
              }),
              life: 3000,
            });
          } else {
            await sipcoAxiosService.post(URL_BASE + "/save", {
              eventoMail,
            });
          }
        }
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: EventoMail, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country && filterValues.evento) {
        const eventoMail = {
          ...model,
          pais: filterValues.securityValues.country,
          evento: filterValues.evento,
        };
        await sipcoAxiosService.post(URL_BASE + "/update", {
          eventoMail,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: EventoMail, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        eventoMail: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<EventoMail[]> {
    try {
      if (filter?.evento && filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            pais: filter.securityValues.country,
            evento: filter.evento,
          }
        );
        if (status === 200) return data;
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<EventoMail> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          eventoMail: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }
  async function loadEventoFilter(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/codigo-playa",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) {
          data.unshift({ id: null, descripcion: "TODOS" });
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadEventoForm(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/codigo-playa",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <>
      <GenericAbm<EventoMail>
        screenCode={SCREEN_CODE}
        title={<LanguageProvider id="12716" alt="Mantener Eventos Mails" />}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
          },
        }}
        filterSettings={[
          {
            filter: "evento",
            label: LanguageProvider({ id: "2360", alt: "Evento" }),
            placeholder: LanguageProvider({ id: "2360", alt: "Evento" }),
            type: "dropdown",
            dataSource: loadEventoFilter,
            dropDownTemplate: DropdownOptionTemplate,
            required: true,
          },
        ]}
        dataTableColumns={[
          {
            field: "evento.id",
            header: <LanguageProvider id="12718" alt="Cod. Evento" />,
          },
          {
            field: "evento.descripcion",
            header: <LanguageProvider id="12419" alt="Descripcion" />,
          },
          {
            field: "mails",
            header: <LanguageProvider id="10415" alt="Email" />,
          },
          {
            field: "usuarioUltimaModificacion.descripcion",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
        ]}
        defaultValues={{
          id: "",
          pais: null,
          evento: null,
          listaMails: [],
        }}
        formSettings={[
          {
            field: "evento",
            label: <LanguageProvider id="12718" alt="Cod. Evento" />,
            type: "dropdown",
            dataSource: loadEventoForm,
            disableOnUpdate: true,
          },
          {
            field: "listaMails",
            label: <LanguageProvider id="12714" alt="Lista de E-mails" />,
            type: "listbox",
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
      <Toast ref={toast} position="top-right" />
    </>
  );
};

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};

export default MantenerEventosMails;
