import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useRef } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { TallerUbicacion } from "src/models/TallerUbicacion";

const MantenerTalleresUbicaciones = () => {
  const SCREEN_CODE = "abm0069_mantener_talleres_ubicaciones";
  const URL_BASE = "/mantener-talleres-ubicaciones";
  const SCREEN_TITLE = (
    <LanguageProvider id="770" alt="Mantener Talleres Ubicaciones" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: TallerUbicacion, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client &&
        filterValues?.securityValues?.account &&
        filterValues?.securityValues?.subaccount
      ) {
        const tallerUbicacion = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues?.securityValues?.account,
          subcuenta: filterValues?.securityValues?.subaccount,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          tallerUbicacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: TallerUbicacion, filterValues: any) {
    try {
      const tallerUbicacion = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues?.securityValues?.account,
        subcuenta: filterValues?.securityValues?.subaccount,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        tallerUbicacion,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: TallerUbicacion, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        tallerUbicacion: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<TallerUbicacion[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );
        if (status === 200)
          if (data.length > 0) {
            const newData = data.map((item: any) => ({
              ...item,
            }));
            return newData;
          } else {
            return [];
          }
      } catch (error) {
        console.error(error);
      }
    }

    return [];
  }
  async function handleGet(model: any): Promise<TallerUbicacion> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          tallerUbicacion: { ...model },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadCalle(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/calles",
          {
            pais: filter?.securityValues.country,
            cliente: filter?.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadColumna(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount &&
      filter?.calle
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/columnas",
          {
            pais: filter?.securityValues.country,
            cliente: filter?.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            calle: filter?.calle,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadNivel(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount &&
      filter?.calle &&
      filter?.columna
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/niveles",
          {
            pais: filter?.securityValues.country,
            cliente: filter?.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            calle: filter?.calle,
            columna: filter?.columna,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadTalleres(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/taller",
          {
            pais: filter?.securityValues.country,
            cliente: filter?.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<TallerUbicacion>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
            subaccount: true,
          },
        }}
        // filterSettings={[]}
        dataTableColumns={[
          { field: "calle", header: <LanguageProvider id="990" alt="Calle" /> },
          {
            field: "columna",
            header: <LanguageProvider id="991" alt="Columna" />,
          },
          { field: "nivel", header: <LanguageProvider id="992" alt="Nivel" /> },
          {
            field: "taller.descripcion",
            header: <LanguageProvider id="327" alt="Taller" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          activo: true,
          pais: null,
          cliente: null,
          cuenta: null,
          subcuenta: null,
          calle: "",
          columna: "",
          nivel: "",
          taller: null,
        }}
        formSettings={[
          {
            field: "calle",
            type: "dropdown",
            label: LanguageProvider({ id: "990", alt: "Calle" }),
            dropDownPlaceholder: LanguageProvider({ id: "990", alt: "Calle" }),
            dataSource: (filter: any) => loadCalle(filter),
            disableOnUpdate: true
          },
          {
            field: "columna",
            label: LanguageProvider({ id: "991", alt: "Columna" }),
            type: "dropdown",
            dropDownPlaceholder: LanguageProvider({ id: "991", alt: "Columna" }),
            dataSource: (filter: any) => loadColumna(filter),
            dependency: ["calle"],
            disableOnUpdate: true
          },
          {
            field: "nivel",
            label: LanguageProvider({ id: "992", alt: "Nivel" }),
            type: "dropdown",
            dropDownPlaceholder: LanguageProvider({ id: "992", alt: "Nivel" }),
            dataSource: (filter: any) => loadNivel(filter),
            dependency: ["columna"],
            disableOnUpdate: true
          },
          {
            field: "taller",
            label: LanguageProvider({ id: "327", alt: "Taller" }),
            type: "dropdown",
            dropDownPlaceholder: LanguageProvider({ id: "327", alt: "Taller" }),
            dataSource: (filter: any) => loadTalleres(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default MantenerTalleresUbicaciones;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};