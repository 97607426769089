import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useState } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { RelacionOperaciones } from "src/models/RelacionOperaciones";

const MantenerRelacionOperaciones = () => {
  const SCREEN_CODE = "abm0187_mantener_relacion_operaciones";
  const URL_BASE = "/mantener-relacion-operaciones";
  const sipcoAxiosService = useSipcoAxiosService();
  const [operacion, setOperacion] = useState(null);

  async function handleSave(model: RelacionOperaciones, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const relacionOperacion = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          fechaDesde: { date: model.fechaDesde },
          fechaHasta: { date: model.fechaHasta },
          operacion: operacion,
          existe: false,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          relacionOperacion: relacionOperacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: RelacionOperaciones, filterValues: any) {
    try {
      const relacionOperacion = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        fechaDesde: { date: model.fechaDesde },
        fechaHasta: { date: model.fechaHasta },
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        relacionOperacion: relacionOperacion,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<RelacionOperaciones[]> {
    try {
      setOperacion(filter?.operacion);
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          operacion: filter.operacion,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<RelacionOperaciones> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          relacionOperacion: model,
        }
      );
      if (status === 200) {
        return {
          ...data,
          fechaDesde: new Date(data.fechaDesde.timestamp),
          fechaHasta: new Date(data.fechaHasta.timestamp),
        };
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadOperacion(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/operacion",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadLinea(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.relacionOperacion) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/linea-operacion",
          {
            pais: filter?.securityValues?.country,
            operacion: filter?.relacionOperacion,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadResultado(filter): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.operacion &&
        filter?.lineaOperacion
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/resultado-operacion",
          {
            pais: filter?.securityValues?.country,
            operacion: filter?.operacion,
            lineaOperacion: filter?.lineaOperacion,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadOperacionPendiente(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/operacion-pendiente",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadRutinaSipco(): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/rutina",
        {}
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadAccion(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/accion",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<RelacionOperaciones>
      screenCode={SCREEN_CODE}
      title={
        <LanguageProvider id="14123" alt="Mantener Relación Operaciones" />
      }
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      filterSettings={[
        {
          filter: "operacion",
          label: LanguageProvider({ id: "1569", alt: "Operación" }),
          placeholder: LanguageProvider({ id: "1569", alt: "Operación" }),
          type: "dropdown",
          dataSource: loadOperacion,
          dropDownTemplate: DropdownInactivoTemplate,
          virtualizeDropdown: true,
          dependency: ["securityValues.country"],
        },
      ]}
      dataTableColumns={[
        {
          field: "fechaDesde.date",
          header: <LanguageProvider id="467" alt="Desde: " />,
          body: (rowData: any) => {
            return (
              <span>{new Date(rowData.fechaDesde.date).toLocaleString()}</span>
            );
          },
        },
        {
          field: "fechaHasta.date",
          header: <LanguageProvider id="468" alt="Hasta: " />,
          body: (rowData: any) => {
            return (
              <span>{new Date(rowData.fechaHasta.date).toLocaleString()}</span>
            );
          },
        },
        {
          field: "operacion.descripcion",
          header: <LanguageProvider id="1569" alt="Operación" />,
        },
        {
          field: "resultadoOperacion.id",
          header: <LanguageProvider id="8380" alt="Resultado" />,
        },
        {
          field: "operacionPendiente.descripcion",
          header: <LanguageProvider id="14121" alt="Operación Pendiente" />,
        },
        {
          field: "rutinaSipco.descripcion",
          header: <LanguageProvider id="25211" alt="Rutina SIPCO" />,
        },
        {
          field: "lineaOperacion.descripcion",
          header: <LanguageProvider id="10828" alt="Linea" />,
        },
        {
          field: "exigeOperacion",
          header: <LanguageProvider id="14246" alt="Exige Operación" />,
          body: (rowData: any) => {
            return rowData.exigeOperacion ? "SI" : "NO";
          },
        },
        {
          field: "inhibeOperacion",
          header: <LanguageProvider id="14247" alt="Inhibe Operación" />,
          body: (rowData: any) => {
            return rowData.inhibeOperacion ? "SI" : "NO";
          },
        },
        {
          field: "tiempoInhibicion",
          header: <LanguageProvider id="14248" alt="Tiempo Inhibicion" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        operacion: null,
        fechaDesde: new Date(),
        fechaHasta: new Date(),
        relacionOperacion: null,
        lineaOperacion: null,
        resultadoOperacion: null,
        operacionPendiente: null,
        rutinaSipco: null,
        accion: null,
        tiempoInhibicion: "0",
        activo: true,
      }}
      formSettings={[
        {
          field: "fechaDesde",
          label: <LanguageProvider id="467" alt="Desde :" />,
          type: "calendar",
          disableOnUpdate: true,
        },
        {
          field: "fechaHasta",
          label: <LanguageProvider id="468" alt="Hasta :" />,
          type: "calendar",
          disableOnUpdate: true,
        },
        {
          field: "relacionOperacion",
          label: <LanguageProvider id="1569" alt="Operación" />,
          dropDownPlaceholder: LanguageProvider({
            id: "1569",
            alt: "Operación",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadOperacionPendiente(filter),
          dropDownTemplate: DropdownInactivoTemplate,
          //dependency: ["securityValues.country"],
          virtualizeDropdown: true,
          required: true,
          disableOnUpdate: true,
        },
        {
          field: "lineaOperacion",
          label: <LanguageProvider id="10828" alt="Linea" />,
          dropDownPlaceholder: LanguageProvider({
            id: "10828",
            alt: "Linea",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadLinea(filter),
          dropDownTemplate: DropdownInactivoTemplate,
          dependency: ["relacionOperacion"],
          required: true,
          disableOnUpdate: true,
        },
        {
          field: "resultadoOperacion",
          label: <LanguageProvider id="8380" alt="Resultado" />,
          dropDownPlaceholder: LanguageProvider({
            id: "8380",
            alt: "Resultado",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadResultado(filter),
          dropDownTemplate: DropdownInactivoTemplate,
          dependency: ["operacion", "lineaOperacion"],
          required: true,
          disableOnUpdate: true,
        },
        {
          field: "operacionPendiente",
          label: <LanguageProvider id="14121" alt="Operación Pendiente" />,
          dropDownPlaceholder: LanguageProvider({
            id: "14121",
            alt: "Operación Pendiente",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadOperacionPendiente(filter),
          dropDownTemplate: DropdownInactivoTemplate,
          dependency: ["securityValues.country"],
          required: true,
          disableOnUpdate: true,
        },
        {
          field: "rutinaSipco",
          label: <LanguageProvider id="25211" alt="Rutina SIPCO" />,
          dropDownPlaceholder: LanguageProvider({
            id: "25211",
            alt: "Rutina SIPCO",
          }),
          type: "dropdown",
          dataSource: () => loadRutinaSipco(),
          dropDownTemplate: DropdownOptionTemplate,
          required: true,
          disableOnUpdate: true,
        },
        {
          field: "accion",
          label: <LanguageProvider id="2489" alt="Acción" />,
          dropDownPlaceholder: LanguageProvider({
            id: "2489",
            alt: "Acción",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadAccion(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country"],
          required: true,
          disableOnUpdate: true,
        },
        {
          field: "tiempoInhibicion",
          label: <LanguageProvider id="14249" alt="Tiempo Inhibicion (Días)" />,
          type: "inputNumber",
          min: 0,
          max: 9999,
          disableOnUpdate: true,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={null}
    />
  );
};

export default MantenerRelacionOperaciones;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};

const DropdownInactivoTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div
        className="flex align-items-center"
        style={{ color: option.activo ? "inherit" : "red" }}
      >
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props?.placeholder}</span>;
};
