import "./stock.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const TODOS_OBJ = {
  label: "TODOS",
  id: "TODOS",
  descripcion: "TODOS",
};

export function Stock() {
  const SCREEN_CODE = "con0027_stock";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const filterColumnsId = [
    19927, 54, 4791, 1491, 990, 991, 992, 10774, 2496, 396, 6590, 408, 491,
    13335, 10021, 31, 6590, 18202, 16809, 369, 13798, 12103, 21430, 10979,
    11307, 10758, 4724, 11443, 16932, 14456,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    mostrarRazonSocialUltimaNF: false,
    stock: false,
    estadoVehiculo: TODOS_OBJ,
    conDanos: null,
    conBloqueo: null,
    playas: [],
    calles: [],
    columnas: [],
    niveles: [],
    mercado: [],
    origens: [],
    destinos: [],
    marcas: [],
    modelos: [],
    versiones: [],
    colores: [],
    familias: [],
    categorias: [],
    estadoRemito: [],
  });

  const [loadingVehiConDanios, setLoadingVehiConDanios] = useState(false);
  const [vehiculosConDanios, setVehiculosConDanios] = useState([]);
  async function loadVehiculosConDanios() {
    setLoadingVehiConDanios(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/codigo-playa-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setVehiculosConDanios(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVehiConDanios(false);
    }
    return [];
  }

  const [loadingVehiConBlock, setLoadingVehiConBlock] = useState(false);
  const [vehiculosConBloqueo, setVehiculosConBloqueo] = useState([]);
  async function loadVehiculosConBloqueo() {
    setLoadingVehiConBlock(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/codigo-playa-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setVehiculosConBloqueo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVehiConBlock(false);
    }
    return [];
  }

  const [loadingEstadosVeiculos, setLoadingEstadosVeiculos] = useState(false);
  const [estadoVehiculo, setEstadoVehiculo] = useState([]);
  async function loadEstadoVehiculo() {
    setLoadingEstadosVeiculos(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/estado-vehiculo-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setEstadoVehiculo(data);
          return estadoVehiculo;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadosVeiculos(false);
    }
    return [];
  }

  const [loadingPlayas, setLoadingPlayas] = useState(true);
  const [playas, setPlayas] = useState([]);
  async function loadPlaya() {
    setLoadingPlayas(true);
    try {
      if (securityFilters.securityValues.account) {
        const { status, data } = await sipcoAxiosService.get(
          "/playas-options",
          {
            params: {
              codCuenta: securityFilters.securityValues.account.id,
              descripcion: "",
            },
          }
        );
        if (status === 200) {
          setPlayas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlayas(false);
    }
    return [];
  }

  const [loadingCalles, setLoadingCalles] = useState(false);
  const [calles, setCalles] = useState([]);
  async function loadCalles() {
    setLoadingCalles(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        filter?.playas.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get("/combo/calles", {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCuenta: securityFilters.securityValues.account.id,
            codSubcuenta: filter.playas[0].codSubcuenta,
          },
        });
        if (status === 200) {
          setCalles(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalles(false);
    }
    return [];
  }

  const [loadingColumnas, setLoadingColumnas] = useState(false);
  const [columnas, setColumnas] = useState([]);
  async function loadColumnas() {
    setLoadingColumnas(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        filter?.calles.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/columnas",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: filter.playas[0].codSubcuenta,
              calle: filter.calles[0].id,
            },
          }
        );
        if (status === 200) {
          setColumnas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumnas(false);
    }
    return [];
  }

  const [loadingNiveles, setLoadingNiveles] = useState(false);
  const [niveles, setNiveles] = useState([]);
  async function loadNiveles() {
    setLoadingNiveles(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        filter?.columnas.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/columnas",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: filter.playas[0].codSubcuenta,
              calle: filter.calles[0].id,
              columna: filter.columnas[0].id,
            },
          }
        );
        if (status === 200) {
          setNiveles(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNiveles(false);
    }
    return [];
  }

  const [loadingMercados, setLoadingMercados] = useState(true);
  const [mercados, setMercados] = useState([]);
  async function loadMercado() {
    setLoadingMercados(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/mercados-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              descripcion: "",
            },
          }
        );
        if (status === 200) {
          setMercados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
    return [];
  }

  const [loadingOrigens, setLoadingOrigens] = useState(true);
  const [origens, setOrigens] = useState([]);
  async function loadOrigen() {
    setLoadingOrigens(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/origen-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              descripcion: "",
            },
          }
        );
        if (status === 200) {
          setOrigens(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigens(false);
    }
    return [];
  }

  const [loadingDestinos, setLoadingDestinos] = useState(true);
  const [destinos, setDestinos] = useState([]);
  async function loadDestinos() {
    setLoadingDestinos(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/combo/destinos",
          securityFilters.securityValues.country
        );
        if (status === 200) {
          setDestinos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinos(false);
    }
    return [];
  }

  const [loadingMarcas, setLoadingMarcas] = useState(true);
  const [marcas, setMarcas] = useState([]);
  async function loadMarcas() {
    setLoadingMarcas(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client?.id
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/stock-marcas-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );
        if (status === 200) {
          const marcas = data.map((x: any) => {
            return { ...x, label: `${x.label} - (${x.id})` };
          });
          setMarcas(marcas);
          return marcas;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
    return [];
  }

  const [loadingModelos, setLoadingModelos] = useState(false);
  const [modelos, setModelos] = useState([]);
  async function loadModelos() {
    setLoadingModelos(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        filter?.marcas?.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get("/combo/modelos", {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
            codMarca: filter.marcas[0].id,
          },
        });
        if (status === 200) {
          setModelos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
    return [];
  }

  const [loadingVersiones, setLoadingVersiones] = useState(false);
  const [versiones, setVersiones] = useState([]);
  async function loadVersiones() {
    setLoadingVersiones(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        filter?.marcas?.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/versiones",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: filter.marcas[0].id,
            },
          }
        );
        if (status === 200) {
          setVersiones(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVersiones(false);
    }
    return [];
  }

  const [loadingColores, setLoadingColores] = useState(false);
  const [colores, setColores] = useState([]);
  async function loadColores() {
    setLoadingColores(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        filter?.marcas?.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get("/combo/color", {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
            codMarca: filter.marcas[0].id,
          },
        });
        if (status === 200) {
          setColores(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColores(false);
    }
    return [];
  }

  const [loadingFamilias, setLoadingFamilias] = useState(false);
  const [familia, setFamilia] = useState([]);
  async function loadFamilias() {
    setLoadingFamilias(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        filter?.marcas?.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get("/combo/familia", {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
            codMarca: filter.marcas[0].id,
          },
        });
        if (status === 200) {
          setFamilia(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFamilias(false);
    }
    return [];
  }

  const [loadingCategorias, setLoadingCategorias] = useState(false);
  const [categorias, setCategorias] = useState([]);
  async function loadCategorias() {
    setLoadingCategorias(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/categoria",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );
        if (status === 200) {
          setCategorias(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCategorias(false);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const [
            estadosVeiculos,
            estadosVeiculosDanos,
            estadosVeiculosBoqueios,
            playas,
            mercados,
            origens,
            destinos,
            marcas,
          ] = await Promise.all([
            loadEstadoVehiculo(),
            loadVehiculosConDanios(),
            loadVehiculosConBloqueo(),
            loadPlaya(),
            loadMercado(),
            loadOrigen(),
            loadDestinos(),
            loadMarcas(),
          ]);

          setFilter({
            ...filter,
            estadoVehiculo: estadosVeiculos[0],
            conDanos: estadosVeiculosDanos[2], //TODOS
            conBloqueo: estadosVeiculosBoqueios[0],
            playas,
            mercado: mercados,
            origens,
            destinos,
            marcas,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  useEffect(() => {
    async function handlePlayaChange() {
      if (filter?.playas?.length === 1) {
        try {
          const calles = await loadCalles();
          setFilter({
            ...filter,
            calles: calles,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handlePlayaChange();
  }, [filter.playas]);

  useEffect(() => {
    async function handleCallesChange() {
      if (filter?.calles?.length === 1) {
        try {
          const columnas = await loadColumnas();
          setFilter({
            ...filter,
            columnas: columnas,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleCallesChange();
  }, [filter.calles]);

  useEffect(() => {
    async function handleColumnasChange() {
      if (filter?.columnas?.length === 1) {
        try {
          const niveles = await loadNiveles();
          setFilter({
            ...filter,
            niveles: niveles,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleColumnasChange();
  }, [filter.columnas]);

  useEffect(() => {
    async function handleMarcaChange() {
      if (filter?.marcas?.length === 1) {
        try {
          const [modelos, versiones, colores, familias, categorias] =
            await Promise.all([
              loadModelos(),
              loadVersiones(),
              loadColores(),
              loadFamilias(),
              loadCategorias(),
            ]);

          setFilter({
            ...filter,
            modelos,
            versiones,
            colores,
            familias,
            categorias,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleMarcaChange();
  }, [filter.marcas]);

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);
  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,

        stock: filter.stock ? "Stock con Remitos" : "Stock Actual",
        mostrarRazonSocialUltimaNF: filter.mostrarRazonSocialUltimaNF,
        estadoVehiculo: filter?.estadoVehiculo?.id || null,
        estadoRemito: filter?.estadoRemito?.map((x) => x.codEstadoRemito) || [],
        conDanos: filter?.conDanos?.id || null,
        conBloqueo: filter?.conBloqueo?.id || null,
        playas: filter?.playas?.map((x) => x.codSubcuenta.toString()) || [],
        mercado: filter?.mercado?.map((x) => x.codMercado) || [],
        origens: filter?.origens?.map((x) => x.codOrigen) || [],
        destinos: filter?.destinos?.map((x) => x.id) || [],
        marcas: filter?.marcas?.map((x) => x.id) || [],
        calles: filter?.calles?.map((x) => x.id) || [],
        columnas: filter?.columnas?.map((x) => x.id) || [],
        niveles: filter?.niveles?.map((x) => x.id) || [],
        modelos: filter?.modelos?.map((x) => x.id) || [],
        versiones: filter?.versiones?.map((x) => x.id) || [],
        colores: filter?.colores?.map((x) => x.id) || [],
        familias: filter?.familias?.map((x) => x.id) || [],
        categorias: filter?.categorias?.map((x) => x.id) || [],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/report/stock",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  const data = convertDateObjects(reporte?.filas);

  const clientIsTodos =
    securityFilters.securityValues?.client?.descripcion === "TODOS";

  return (
    <div className="stock-report">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="RadioButtonFilter">
                <RadioButton
                  checked={filter.stock}
                  onChange={(e) => {
                    setFilter({ ...filter, stock: true });
                    setReporte([]);
                  }}
                  // style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id="1241" alt="" />
                </label>
                <RadioButton
                  checked={!filter.stock}
                  onChange={(e) => {
                    setFilter({ ...filter, stock: false });
                    setReporte([]);
                  }}
                  // style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id="1242" alt="" />
                </label>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="11443" alt="Estado vehículo" />
                </label>
                <SipcoDropdown
                  value={filter.estadoVehiculo}
                  options={estadoVehiculo}
                  onChange={(e) =>
                    setFilter({ ...filter, estadoVehiculo: e.value })
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingEstadosVeiculos}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id="1149"
                    alt="Listar vehiculos con daños (SI/NO/AMBOS)"
                  />
                </label>
                <SipcoDropdown
                  value={filter.conDanos}
                  options={vehiculosConDanios}
                  onChange={(e) => setFilter({ ...filter, conDanos: e.value })}
                  optionLabel="descripcion"
                  filter
                  loading={loadingVehiConDanios}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id="4397"
                    alt="Listar vehiculos con Bloqueo (SI/NO/AMBOS)"
                  />
                </label>
                <SipcoDropdown
                  value={filter.conBloqueo}
                  options={vehiculosConBloqueo}
                  onChange={(e) =>
                    setFilter({ ...filter, conBloqueo: e.value })
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingVehiConBlock}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="441" alt="Playas" />
                </label>
                <SipcoMultiSelect
                  value={filter.playas}
                  options={playas}
                  onChange={(e) => {
                    setFilter({ ...filter, playas: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="descripcion"
                  loading={loadingPlayas}
                  showClear
                />
              </div>
              {filter?.playas?.length === 1 && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id="990" alt="Calle" />
                  </label>
                  <SipcoMultiSelect
                    value={filter.calles}
                    options={calles}
                    onChange={(e) => {
                      setFilter({ ...filter, calles: e.value });
                    }}
                    display="chip"
                    filter
                    optionLabel="label"
                    loading={loadingCalles}
                    showClear
                  />
                </div>
              )}
              {filter?.calles?.length === 1 && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id="991" alt="Columnas" />
                  </label>
                  <SipcoMultiSelect
                    value={filter.columnas}
                    options={columnas}
                    onChange={(e) => {
                      setFilter({ ...filter, columnas: e.value });
                    }}
                    display="chip"
                    filter
                    optionLabel="label"
                    loading={loadingColumnas}
                    showClear
                  />
                </div>
              )}
              {filter?.columnas?.length === 1 && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id="992" alt="Nivel" />
                  </label>
                  <SipcoMultiSelect
                    value={filter.niveles}
                    options={niveles}
                    onChange={(e) => {
                      setFilter({ ...filter, niveles: e.value });
                    }}
                    display="chip"
                    filter
                    optionLabel="label"
                    loading={loadingNiveles}
                    showClear
                  />
                </div>
              )}
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="10774" alt="Mercados" />
                </label>
                <SipcoMultiSelect
                  value={filter.mercado}
                  options={mercados}
                  onChange={(e) => {
                    setFilter({ ...filter, mercado: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="descripcion"
                  loading={loadingMercados}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="2496" alt="Origen" />
                </label>
                <SipcoMultiSelect
                  value={filter.origens}
                  options={origens}
                  onChange={(e) => {
                    setFilter({ ...filter, origens: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="descripcion"
                  loading={loadingOrigens}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="369" alt="Destinos" />
                </label>
                <SipcoMultiSelect
                  value={filter.destinos}
                  options={destinos}
                  onChange={(e) => {
                    setFilter({ ...filter, destinos: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingDestinos}
                  showClear
                />
              </div>
            </div>
            {!clientIsTodos && (
              <div className="form-row">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id="396" alt="Marca" />
                  </label>
                  <SipcoMultiSelect
                    value={filter.marcas}
                    options={marcas}
                    onChange={(e) => {
                      setFilter({ ...filter, marcas: e.value });
                    }}
                    display="chip"
                    filter
                    loading={loadingMarcas}
                    showClear
                  />
                </div>
                {filter?.marcas?.length === 1 && (
                  <>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id="2139" alt="Modelo" />
                      </label>
                      <SipcoMultiSelect
                        value={filter.modelos}
                        options={modelos}
                        onChange={(e) => {
                          setFilter({ ...filter, modelos: e.value });
                        }}
                        display="chip"
                        filter
                        loading={loadingModelos}
                        showClear
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id="16808" alt="Versiones" />
                      </label>
                      <SipcoMultiSelect
                        value={filter.versiones}
                        options={versiones}
                        onChange={(e) => {
                          setFilter({ ...filter, versiones: e.value });
                        }}
                        display="chip"
                        filter
                        loading={loadingVersiones}
                        showClear
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id="491" alt="Colores" />
                      </label>
                      <SipcoMultiSelect
                        value={filter.colores}
                        options={colores}
                        onChange={(e) => {
                          setFilter({ ...filter, colores: e.value });
                        }}
                        display="chip"
                        filter
                        loading={loadingColores}
                        showClear
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id="10021" alt="Familias" />
                      </label>
                      <SipcoMultiSelect
                        value={filter.familias}
                        options={familia}
                        onChange={(e) => {
                          setFilter({ ...filter, familias: e.value });
                        }}
                        display="chip"
                        filter
                        loading={loadingFamilias}
                        showClear
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id="16809" alt="Categorías" />
                      </label>
                      <SipcoMultiSelect
                        value={filter.categorias}
                        options={categorias}
                        onChange={(e) => {
                          setFilter({ ...filter, categorias: e.value });
                        }}
                        display="chip"
                        filter
                        loading={loadingCategorias}
                        showClear
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {<LanguageProvider id="274" alt="Stock" />}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Stock"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          ref={dt}
          value={data}
          loading={loadingReportData}
          size={"small"}
          showGridlines
          stripedRows
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn
            field="0"
            header={<LanguageProvider id="19927" alt="Cod. Cliente" />}
            sortable
            filter
          />
          <SipcoColumn
            field="1"
            header={<LanguageProvider id="54" alt="Cliente" />}
            sortable
            filter
          />
          <SipcoColumn
            field="2"
            header={<LanguageProvider id="4791" alt="Subcuenta" />}
            sortable
            filter
          />
          <SipcoColumn
            field="3"
            header={<LanguageProvider id="1491" alt="VIN" />}
            sortable
            filter
          />
          <SipcoColumn
            field="4"
            header={<LanguageProvider id="990" alt="Calle" />}
            sortable
            filter
          />
          <SipcoColumn
            field="5"
            header={<LanguageProvider id="991" alt="Columna" />}
            sortable
            filter
          />
          <SipcoColumn
            field="6"
            header={<LanguageProvider id="992" alt="Nivel" />}
            sortable
            filter
          />
          <SipcoColumn
            field="7"
            header={<LanguageProvider id="10774" alt="Mercados" />}
            sortable
            filter
          />
          <SipcoColumn
            field="8"
            header={<LanguageProvider id="2496" alt="Origen" />}
            sortable
            filter
          />
          <SipcoColumn
            field="9"
            header={<LanguageProvider id="396" alt="Marca" />}
            sortable
            filter
          />
          <SipcoColumn
            field="10"
            header={<LanguageProvider id="6590" alt="Código" />}
            sortable
            filter
          />
          <SipcoColumn
            field="11"
            header={<LanguageProvider id="408" alt="Modelos" />}
            sortable
            filter
          />
          <SipcoColumn
            field="12"
            header={<LanguageProvider id="491" alt="Colores" />}
            sortable
            filter
          />
          <SipcoColumn
            field="13"
            header={<LanguageProvider id="13335" alt="Motor" />}
            sortable
            filter
          />
          <SipcoColumn
            field="14"
            header={<LanguageProvider id="10021" alt="Familias" />}
            sortable
            filter
          />
          <SipcoColumn
            field="15"
            header={<LanguageProvider id="31" alt="Descripción" />}
            sortable
            filter
          />
          <SipcoColumn
            field="16"
            header={<LanguageProvider id="6590" alt="Código" />}
            sortable
            filter
          />
          <SipcoColumn
            field="17"
            header={
              <LanguageProvider id="18202" alt="Denominación Comercial" />
            }
            sortable
            filter
          />
          <SipcoColumn
            field="18"
            header={<LanguageProvider id="16809" alt="Categorías" />}
          />
          <SipcoColumn
            field="19"
            header={<LanguageProvider id="369" alt="Destinos" />}
            sortable
            filter
          />
          <SipcoColumn
            field="20"
            header={<LanguageProvider id="13798" alt="Fecha Bloqueo Calidad" />}
            sortable
            filter
          />
          <SipcoColumn
            field="21"
            header={<LanguageProvider id="12103" alt="Fecha Fabricación" />}
            sortable
            filter
          />
          <SipcoColumn
            field="22"
            header={<LanguageProvider id="21430" alt="Días en stock" />}
            sortable
            filter
          />
          <SipcoColumn
            field="23"
            header={<LanguageProvider id="10979" alt="Fecha Nacionalización" />}
            sortable
            filter
          />
          <SipcoColumn
            field="24"
            header={<LanguageProvider id="11307" alt="Jumelage" />}
            sortable
            filter
          />
          <SipcoColumn
            field="25"
            header={<LanguageProvider id="10758" alt="Nro. Colecta" />}
            sortable
            filter
          />
          <SipcoColumn
            field="26"
            header={<LanguageProvider id="4724" alt="Patente" />}
            sortable
            filter
          />
          <SipcoColumn
            field="27"
            header={<LanguageProvider id="11443" alt="Estado vehículo" />}
            sortable
            filter
          />
          <SipcoColumn
            field="28"
            header={<LanguageProvider id="16932" alt="Fecha Llegada" />}
            sortable
            filter
          />
          <SipcoColumn
            field="29"
            header={<LanguageProvider id="14456" alt="Fecha Solicitud" />}
            sortable
            filter
          />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default Stock;
