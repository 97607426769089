import "./ModalModificarMantenerCatalogoServicios.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import { useLocation } from "react-router-dom";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { HttpStatusCode } from "axios";

interface InterfaceModalModificarMantenerCatalogoServicios {
  visible: boolean;
  onClose: () => void;
  model?: any;
  onConfirm?: (updatedList: any[]) => void;
  values?: any
  selected?: any[];
}

const ModalModificarMantenerCatalogoServicios: React.FC<
  InterfaceModalModificarMantenerCatalogoServicios
> = ({ visible, onClose, model, onConfirm, selected, values }) => {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [cuenta, setCuenta] = useState([]);
  const [grupo, setGrupo] = useState([]);
  const [modelo, setModelo] = useState([]);

  const [filter, setFilter] = useState({
    servico: "",
    descripcion: "",
    horasStandard: "",
    fechaCotizacion: new Date(),
    cuentaTaller: null,
    grupoTaller: null,
    modeloTaller: null,
    compra: false,
    venta: false,
  });


  const data = convertDateObjects(reporte?.filas);

  const [updateLabel, setUpdateLabel] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  async function update() {
    try {

      setLoadingUpdate(true);
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-catalogo-servicios/update",
        {
          catalogoServicio: {
            pais: values?.state?.pais,
            cliente: values?.state?.cliente,
            cuenta: values?.state?.cuenta,
            usuarioUltimaModificacion: selected.map((x: any) => x.usuarioUltimaModificacion)[0],
            id: filter.servico || null,
            descripcion: filter.descripcion || null,
            horasStandard: filter.horasStandard || null,
            fechaCotizacion: new Date(filter.fechaCotizacion).getTime() || null,
            cuentaTaller: filter.cuentaTaller || null,
            grupoTaller: filter.grupoTaller || null,
            modeloTaller: filter.modeloTaller || null,
            compra: filter.compra || false,
            venta: filter.venta || false,
          }
        }
      );
      if (status === HttpStatusCode.Ok) {
        setUpdateLabel(data);
        onClose();
        toast.current?.show({
          severity: "success",
          summary: "Success",
          detail: "Saved successfully",
          life: 3000,
        })
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUpdate(false);
    }
  }


  useEffect(() => {
    const init = () => {
      if (visible && selected.length > 0) {
        setCuenta([selected.map((x: any) => x.cuentaTaller)[0]]);
        setGrupo([selected.map((x: any) => x.grupoTaller)[0]]);
        setModelo([selected.map((x: any) => x.modeloTaller)[0]]);

        setFilter({
          ...filter,
          fechaCotizacion: new Date(
            selected.map((x: any) => x.fechaCotizacion)[0]
          ),
          servico: selected.map((x: any) => x.id)[0],
          descripcion: selected.map((x: any) => x.descripcion)[0],
          cuentaTaller: selected.map((x: any) => x.cuentaTaller)[0],
          grupoTaller: selected.map((x: any) => x.grupoTaller)[0],
          modeloTaller: selected.map((x: any) => x.modeloTaller)[0],
          horasStandard: selected.map((x: any) => x.horasStandard)[0],
        });
      }
    };
    init();
  }, [visible || selected]);

  return (
    <div>
      <Dialog
        className="modalAltaReparacionServicios"
        header="Orden Reparación Proveedores - Alta"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={LanguageProvider({ id: "9491", alt: "Aceptar" })}
                icon="pi pi-check"
                onClick={update}
                autoFocus
              />
              <Button
                label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                icon="pi pi-times"
                onClick={onClose}
                className="p-button-text"
              />
            </div>
          );
        }}
      >
        <div className="modal-container">
          <div className="form-modal">
            <div className="form-row-modal">
              <label>
                <LanguageProvider id={"9912"} alt="Servicio" />
              </label>
              <SipcoInputText
                value={filter.servico}
                onChange={(e) =>
                  setFilter({ ...filter, servico: e.target.value })
                }
                disabled
                className="input-modal"
              />
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"12419"} alt="Descripcion" />
                </label>
                <SipcoInputText
                  value={filter.descripcion}
                  onChange={(e) =>
                    setFilter({ ...filter, descripcion: e.target.value })
                  }
                  className="input-modal"
                />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"16959"} alt="Horas Standard" />
                </label>
                <SipcoInputText
                  value={filter.horasStandard}
                  onChange={(e) =>
                    setFilter({ ...filter, horasStandard: e.target.value })
                  }
                  className="input-modal"
                />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"6256"} alt="Fecha Cotización" />
                </label>
                <SipcoCalendar
                  value={filter.fechaCotizacion}
                  onChange={(e) =>
                    setFilter({ ...filter, fechaCotizacion: e.value })
                  }
                  className="input-modal"
                  showIcon
                />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2184"} alt="Cuenta Taller" />
                </label>
                <SipcoDropdown
                  value={filter.cuentaTaller}
                  options={cuenta}
                  optionLabel="descripcion"
                  onChange={(e) =>
                    setFilter({ ...filter, cuentaTaller: e.value })
                  }
                  className="input-modal"
                />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2185"} alt="Grupo Taller" />
                </label>
                <SipcoDropdown
                  value={filter.grupoTaller}
                  options={grupo}
                  optionLabel="descripcion"
                  onChange={(e) =>
                    setFilter({ ...filter, grupoTaller: e.value })
                  }
                  className="input-modal"
                />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2186"} alt="Modelo Taller" />
                </label>
                <SipcoDropdown
                  value={filter.modeloTaller}
                  options={modelo}
                  optionLabel="id"
                  onChange={(e) =>
                    setFilter({ ...filter, modeloTaller: e.value })
                  }
                  className="input-modal"
                />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"5373"} alt="Venta" />
                </label>
                <Checkbox
                  value={filter.venta}
                  onChange={(e) =>
                    setFilter({ ...filter, venta: e.target.checked })
                  }
                  className="check-box"
                  checked={filter.venta}
                />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"5372"} alt="Compra" />
                </label>
                <Checkbox
                  className="check-box"
                  value={filter.compra}
                  onChange={(e) =>
                    setFilter({ ...filter, compra: e.target.checked })
                  }
                  checked={filter.compra}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default ModalModificarMantenerCatalogoServicios;
