import "./HistoricoDanos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useDebounce } from "primereact/hooks";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function HistoricoDanos() {
  const SCREEN_CODE = "con0026_historico_danios";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const filterColumnsId = [
    1396, 2892, 54, 1491, 10540, 16243, 21675, 7280, 21836, 16505, 16246, 11314,
    7811, 11097, 3344, 20854, 10774, 2496, 396, 408, 491, 10021, 6590, 16809,
    6590, 32, 16544, 1, 16576, 2941, 11070, 14712, 4870, 4871, 4655, 18096,
    18125, 18097, 18099, 18098, 1269, 19126, 14535, 1355, 16541,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, debouncedFilter, setFilter] = useDebounce(
    {
      fechaDesde: new Date(),
      fechaHasta: new Date(),
      listarVehiculosConDanios: null,
      conceptosBloqueantes: null,
      usaFechaDeCargaDelDano: false,
      vin: "",
      destinos: [],
      descripcionesDanios: [],
      partesDanadas: [],
      gravedadDeDanios: [],
      cuadranteDanios: [],
      mercado: [],
      origens: [],
      marcas: [],
      modelos: [],
      versiones: [],
      colores: [],
      familias: [],
      categorias: [],
    },
    400
  );

  const [loadingListVehiConDan, setLoadingListVehiConDan] = useState(true);
  const [listarVehiculosConDanios, setListarVehiculosConDanios] = useState([]);
  async function loadListarVehiculosConDanios() {
    setLoadingListVehiConDan(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/codigo-playa",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );
      if (status === 200) {
        setListarVehiculosConDanios(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingListVehiConDan(false);
    }
    return [];
  }

  const [loadingConceptosBloq, setLoadingConceptosBloq] = useState(true);
  const [conceptosBloqueadores, setConceptosBloqueadores] = useState([]);
  async function loadConceptosBloqueadores() {
    setLoadingConceptosBloq(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/codigo-playa",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );
      if (status === 200) {
        setConceptosBloqueadores(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConceptosBloq(false);
    }
    return [];
  }

  const [loadingDestinos, setLoadingDestinos] = useState(true);
  const [destinos, setDestinos] = useState([]);
  async function loadDestinos() {
    setLoadingDestinos(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/destino",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );
      if (status === 200) {
        const destino = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setDestinos(destino);
        return destino;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinos(false);
    }
    return [];
  }

  const [loadingDescDanios, setLoadingDesDanios] = useState(true);
  const [descripcionesDanios, setDescripcionesDanios] = useState([]);
  async function loadDescripcionesDanios() {
    setLoadingDesDanios(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/descripcion-danios",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
          },
        }
      );
      if (status === 200) {
        const descripcionDano = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setDescripcionesDanios(descripcionDano);
        return descripcionDano;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDesDanios(false);
    }
    return [];
  }

  const [loadingPartesDanadas, setLoadingPartesDanadas] = useState(true);
  const [partesDanadas, setPartesDanadas] = useState([]);
  async function loadPartesDanadas() {
    setLoadingPartesDanadas(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/partes-danadas",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
          },
        }
      );
      if (status === 200) {
        const parteDanada = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setPartesDanadas(parteDanada);
        return parteDanada;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPartesDanadas(false);
    }
    return [];
  }

  const [loadingGravedadDeDanios, setLoadingGravedadDeDanios] = useState(true);
  const [gravedadDeDanios, setGravedadDeDanios] = useState([]);
  async function loadGravedadDeDanios() {
    setLoadingGravedadDeDanios(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/gravedad-danios",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
          },
        }
      );
      if (status === 200) {
        const gravedadDelDano = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setGravedadDeDanios(gravedadDelDano);
        return gravedadDelDano;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGravedadDeDanios(false);
    }
    return [];
  }

  const [loadingCuadranteDanios, setLoadingCuadranteDanios] = useState(false);
  const [cuadranteDanios, setCuadranteDanios] = useState([]);
  async function loadCuadranteDanios(parteDanificada: any) {
    setLoadingCuadranteDanios(true);
    try {
      if (parteDanificada) {
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/cuadrantes",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codParteDanio: parteDanificada.id,
            },
          }
        );

        if (status === 200) {
          const cuadrante = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setCuadranteDanios(cuadrante);
          return cuadrante;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCuadranteDanios(false);
    }
    return [];
  }

  const [loadingMercados, setLoadingMercados] = useState(true);
  const [mercados, setMercados] = useState([]);
  async function loadMercados() {
    setLoadingMercados(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/mercados",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );
      if (status === 200) {
        const mercados = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setMercados(mercados);
        return mercados;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
    return [];
  }

  const [loadingOrigen, setLoadingOrigen] = useState(true);
  const [origen, setOrigen] = useState([]);
  async function loadOrigen() {
    setLoadingOrigen(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/origen",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );
      if (status === 200) {
        const origen = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setOrigen(origen);
        return origen;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigen(false);
    }
    return [];
  }

  const [loadingMarca, setLoadingMarca] = useState(true);
  const [marca, setMarca] = useState([]);
  async function loadMarcas() {
    setLoadingMarca(true);
    try {
      const { status, data } = await sipcoAxiosService.get(
        "historico-danos/marca",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
          },
        }
      );
      if (status === 200) {
        const marcas = data.map((x: any) => {
          return { ...x, label: `${x.label}` };
        });
        setMarca(marcas);
        return marcas;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
    return [];
  }

  const [loadingModelos, setLoadingModelos] = useState(false);
  const [modelos, setModelos] = useState([]);
  async function loadModelos(marca: any) {
    setLoadingModelos(true);
    try {
      if (marca) {
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/modelos",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: marca.id,
            },
          }
        );
        if (status === 200) {
          const modelos = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setModelos(modelos);
          return modelos;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
    return [];
  }

  const [loadingVersiones, setLoadingVersiones] = useState(false);
  const [versiones, setVersiones] = useState([]);
  async function loadVersiones(marca: any) {
    setLoadingVersiones(true);
    try {
      if (marca) {
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/versiones",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: marca.id,
            },
          }
        );
        if (status === 200) {
          const versiones = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setVersiones(versiones);
          return versiones;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVersiones(false);
    }
    return [];
  }

  const [loadingColores, setLoadingColores] = useState(false);
  const [colores, setColores] = useState([]);
  async function loadColores(marca: any) {
    setLoadingColores(true);
    try {
      if (marca) {
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/colores",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: marca.id,
            },
          }
        );
        if (status === 200) {
          const colores = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setColores(colores);
          return colores;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColores(false);
    }
    return [];
  }

  const [loadingFamilias, setLoadingFamilias] = useState(false);
  const [familias, setFamilias] = useState([]);
  async function loadFamilias(marca: any) {
    setLoadingFamilias(true);
    try {
      if (marca) {
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/familias",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: marca.id,
            },
          }
        );

        if (status === 200) {
          const familias = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setFamilias(familias);
          return familias;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFamilias(false);
    }
    return [];
  }

  const [loadingCategorias, setLoadingCategorias] = useState(false);
  const [categorias, setCategorias] = useState([]);
  async function loadCategorias(marca: any) {
    setLoadingCategorias(true);
    try {
      if (marca) {
        const { status, data } = await sipcoAxiosService.get(
          "historico-danos/categorias",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );
        if (status === 200) {
          const categorias = data.map((x: any) => {
            return { ...x, label: `${x.label}` };
          });
          setCategorias(categorias);
          return categorias;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCategorias(false);
    }
    return [];
  }

  async function handleMarcaSelection(event: MultiSelectChangeEvent) {
    try {
      if (event?.value?.length === 1) {
        const [modelos, versiones, colores, categorias, familias] =
          await Promise.all([
            loadModelos(event.value[0]),
            loadVersiones(event.value[0]),
            loadColores(event.value[0]),
            loadCategorias(event.value[0]),
            loadFamilias(event.value[0]),
          ]);
        setFilter({
          ...filter,
          marcas: event.value,
          modelos,
          versiones,
          colores,
          categorias,
          familias,
        });
      } else {
        setFilter({
          ...filter,
          marcas: event.value,
          modelos: [],
          versiones: [],
          colores: [],
          categorias: [],
          familias: [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleParteDanificadaSelection(event: MultiSelectChangeEvent) {
    try {
      if (event?.value?.length === 1) {
        const cuadrantesDano = await loadCuadranteDanios(event.value[0]);
        setFilter({
          ...filter,
          partesDanadas: event.value,
          cuadranteDanios: cuadrantesDano,
        });
      } else {
        setFilter({
          ...filter,
          partesDanadas: event.value,
          cuadranteDanios: [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [loadingReporteData, setLoadingReporteData] = useState(false);
  const [reporteData, setReporteData] = useState(null);
  async function loadReportData() {
    try {
      setLoadingReporteData(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,

        rangeFecha: [filter?.fechaDesde, filter?.fechaHasta],
        listarVehiculosConDanios: filter?.listarVehiculosConDanios?.id || "",
        conceptosBloqueadores: filter?.conceptosBloqueantes?.id || "",
        fechaUsadaHistoricoDanios: filter.usaFechaDeCargaDelDano
          ? "false"
          : "true",
        vin: filter?.vin || "",
        destinos: filter?.destinos?.map((x) => x.id) || [],
        descripcionesDanios:
          filter?.descripcionesDanios?.map((x) => x.id) || [],
        partesDanadas: filter?.partesDanadas?.map((x) => x.id) || [],
        gravedadDeDanios: filter?.gravedadDeDanios?.map((x) => x.id) || [],
        cuadranteDanios: filter?.cuadranteDanios?.map((x) => x.id) || [],
        mercado: filter?.mercado?.map((x) => x.id) || [],
        origens: filter?.origens?.map((x) => x.id) || [],
        marcas: filter?.marcas?.map((x) => x.id) || [],
        modelos: filter?.modelos?.map((x) => x.id) || [],
        versiones: filter?.versiones?.map((x) => x.id) || [],
        colores: filter?.colores?.map((x) => x.id) || [],
        familias: filter?.familias?.map((x) => x.id) || [],
        categorias: filter?.categorias?.map((x) => x.id) || [],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/historico-danos/report",
        filters
      );
      if (status === 200) {
        setReporteData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReporteData(false);
    }
  }

  const data = convertDateObjects(reporteData?.filas);
  const clientIsTodos =
    securityFilters.securityValues?.client?.descripcion === "TODOS";
  const exportData = data.map((e) => {
    return Object.entries(e)
      .filter(
        ([key]) =>
          !["0", "1", "2", "3", "4", "5", "6", "7", "53", "54", "55"].includes(
            key
          )
      )
      .map(([, value]) => value);
  });

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const [
            listarVehiculosConDanios,
            conceptosBloqueadores,
            destinos,
            descripcionesDanios,
            partesDanadas,
            gravedadDeDanios,
            mercados,
            origens,
            marcas,
          ] = await Promise.all([
            loadListarVehiculosConDanios(),
            loadConceptosBloqueadores(),
            loadDestinos(),
            loadDescripcionesDanios(),
            loadPartesDanadas(),
            loadGravedadDeDanios(),
            loadMercados(),
            loadOrigen(),
            loadMarcas(),
          ]);

          setFilter({
            ...filter,
            listarVehiculosConDanios: listarVehiculosConDanios[0],
            conceptosBloqueantes: conceptosBloqueadores[0],
            destinos,
            descripcionesDanios,
            partesDanadas,
            gravedadDeDanios,
            mercado: mercados,
            origens,
            marcas,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  return (
    <div className="historico-danos">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id="743" alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="467" alt="Desde" />
                </label>
                <SipcoCalendar
                  value={filter.fechaDesde}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaDesde: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="468" alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.fechaHasta}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaHasta: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id="1357"
                    alt="Listar vehiculos con daños Reparados"
                  />
                </label>
                <SipcoDropdown
                  value={filter.listarVehiculosConDanios}
                  options={listarVehiculosConDanios}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      listarVehiculosConDanios: e.value,
                    });
                  }}
                  optionLabel="descripcion"
                  loading={loadingListVehiConDan}
                  filter
                  itemTemplate={(e) => `${e.id} - ${e.descripcion}`}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="11078" alt="Conceptos Bloqueantes" />
                </label>
                <SipcoDropdown
                  value={filter.conceptosBloqueantes}
                  options={conceptosBloqueadores}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      conceptosBloqueantes: e.value,
                    });
                  }}
                  optionLabel="descripcion"
                  loading={loadingConceptosBloq}
                  filter
                  itemTemplate={(e) => `${e.id} - ${e.descripcion}`}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="RadioButtonFilter">
                <RadioButton
                  checked={!filter.usaFechaDeCargaDelDano}
                  onChange={(e) => {
                    setFilter({ ...filter, usaFechaDeCargaDelDano: false });
                    setReporteData([]);
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider
                    id="1551"
                    alt="Usa fecha en que se produjo el Daño"
                  />
                </label>
                <RadioButton
                  checked={filter.usaFechaDeCargaDelDano}
                  onChange={(e) => {
                    setFilter({ ...filter, usaFechaDeCargaDelDano: true });
                    setReporteData([]);
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider
                    id="1552"
                    alt="Usa fecha de Carga del Daño"
                  />
                </label>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="662" alt="Ingrese el VIN" />
                </label>
                <SipcoInputText
                  value={filter.vin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="369" alt="Destinos" />
                </label>
                <SipcoMultiSelect
                  value={filter.destinos}
                  options={destinos}
                  onChange={(e) => {
                    setFilter({ ...filter, destinos: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingDestinos}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="10541" alt="Descripción Daño" />
                </label>
                <SipcoMultiSelect
                  value={filter.descripcionesDanios}
                  options={descripcionesDanios}
                  onChange={(e) => {
                    setFilter({ ...filter, descripcionesDanios: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingDescDanios}
                  showClear
                  disabled={clientIsTodos}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="16243" alt="Parte dañada" />
                </label>
                <SipcoMultiSelect
                  value={filter.partesDanadas}
                  options={partesDanadas}
                  onChange={handleParteDanificadaSelection}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingPartesDanadas}
                  showClear
                  disabled={clientIsTodos}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="17007" alt="Gravedad del Daño" />
                </label>
                <SipcoMultiSelect
                  value={filter.gravedadDeDanios}
                  options={gravedadDeDanios}
                  onChange={(e) => {
                    setFilter({ ...filter, gravedadDeDanios: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingGravedadDeDanios}
                  showClear
                  disabled={clientIsTodos}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="21675" alt="Cuadrante (LD)" />
                </label>
                <SipcoMultiSelect
                  value={filter.cuadranteDanios}
                  options={cuadranteDanios}
                  onChange={(e) => {
                    setFilter({ ...filter, cuadranteDanios: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingCuadranteDanios}
                  showClear
                  disabled={
                    filter?.partesDanadas?.length !== 1 || clientIsTodos
                  }
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="367" alt="Mercado" />
                </label>
                <SipcoMultiSelect
                  value={filter.mercado}
                  options={mercados}
                  onChange={(e) => {
                    setFilter({ ...filter, mercado: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingMercados}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="2496" alt="Origen" />
                </label>
                <SipcoMultiSelect
                  value={filter.origens}
                  options={origen}
                  onChange={(e) => {
                    setFilter({ ...filter, origens: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingOrigen}
                  showClear
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="396" alt="Marca" />
                </label>
                <SipcoMultiSelect
                  value={filter.marcas}
                  options={marca}
                  onChange={handleMarcaSelection}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingMarca}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2139"} alt="Modelo" />
                </label>
                <SipcoMultiSelect
                  value={filter.modelos}
                  options={modelos}
                  onChange={(e) => {
                    setFilter({ ...filter, modelos: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingModelos}
                  showClear
                  disabled={filter?.marcas?.length !== 1}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="16808" alt="Versiones" />
                </label>
                <SipcoMultiSelect
                  value={filter.versiones}
                  options={versiones}
                  onChange={(e) => {
                    setFilter({ ...filter, versiones: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingVersiones}
                  showClear
                  disabled={filter?.marcas?.length !== 1}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="491" alt="Colores" />
                </label>
                <SipcoMultiSelect
                  value={filter.colores}
                  options={colores}
                  onChange={(e) => {
                    setFilter({ ...filter, colores: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingColores}
                  showClear
                  disabled={filter?.marcas?.length !== 1}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="10021" alt="Familias" />
                </label>
                <SipcoMultiSelect
                  value={filter.familias}
                  options={familias}
                  onChange={(e) => {
                    setFilter({ ...filter, familias: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingFamilias}
                  showClear
                  disabled={filter?.marcas?.length !== 1}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="16809" alt="Categorías" />
                </label>
                <SipcoMultiSelect
                  value={filter.categorias}
                  options={categorias}
                  onChange={(e) => {
                    setFilter({ ...filter, categorias: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingCategorias}
                  showClear
                  disabled={filter?.marcas?.length !== 1}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {<LanguageProvider id="254" alt="Histórico de Daños" />}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={exportData}
                  columns={columnsNames}
                  screenName={LanguageProvider({
                    id: "254",
                    alt: "Histórico de Daños",
                  })}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          loading={loadingReporteData}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn
            field="8"
            header={<LanguageProvider id="1396" alt="Fecha de Movimientos" />}
            sortable
            filter
          />
          <SipcoColumn
            field="9"
            header={<LanguageProvider id="2892" alt="Playa" />}
            sortable
            filter
          />
          <SipcoColumn
            field="10"
            header={<LanguageProvider id="54" alt="Cliente" />}
            sortable
            filter
          />
          <SipcoColumn
            field="11"
            header={<LanguageProvider id="1491" alt="VIN" />}
            sortable
            filter
          />
          <SipcoColumn
            field="12"
            header={<LanguageProvider id="10540" alt="Codigo Daño" />}
            sortable
            filter
          />
          <SipcoColumn
            field="13"
            header={<LanguageProvider id="16243" alt="Parte dañada" />}
            sortable
            filter
          />
          <SipcoColumn
            field="14"
            header={<LanguageProvider id="21675" alt="Cuadrante (LD)" />}
            sortable
            filter
          />
          <SipcoColumn
            field="15"
            header={<LanguageProvider id="7280" alt="Unidad de Medida" />}
            sortable
            filter
          />
          <SipcoColumn
            field="16"
            header={<LanguageProvider id="21836" alt="Valor Unidad Medida" />}
            sortable
            filter
          />
          <SipcoColumn
            field="17"
            header={<LanguageProvider id="16505" alt="Gravedad daño" />}
            sortable
            filter
          />
          <SipcoColumn
            field="18"
            header={
              <LanguageProvider id="16246" alt="Responsable del cliente" />
            }
            sortable
            filter
          />
          <SipcoColumn
            field="19"
            header={<LanguageProvider id="11314" alt="CheckPoint" />}
            sortable
            filter
          />
          <SipcoColumn
            field="20"
            header={<LanguageProvider id="7811" alt="Imputación" />}
            sortable
            filter
          />
          <SipcoColumn
            field="21"
            header={<LanguageProvider id="11097" alt="Responsable" />}
            sortable
            filter
          />
          <SipcoColumn
            field="22"
            header={<LanguageProvider id="3344" alt="Estado" />}
            sortable
            filter
          />
          <SipcoColumn
            field="23"
            header={<LanguageProvider id="20854" alt="Motivo Baja Daño" />}
            sortable
            filter
          />
          <SipcoColumn
            field="24"
            header={<LanguageProvider id="10774" alt="Mercados" />}
            sortable
            filter
          />
          <SipcoColumn
            field="25"
            header={<LanguageProvider id="2496" alt="Origen" />}
            sortable
            filter
          />
          <SipcoColumn
            field="26"
            header={<LanguageProvider id="396" alt="Marca" />}
            sortable
            filter
          />
          <SipcoColumn
            field="27"
            header={<LanguageProvider id="408" alt="Modelos" />}
            sortable
            filter
          />
          <SipcoColumn
            field="28"
            header={<LanguageProvider id="491" alt="Colores" />}
            sortable
            filter
          />
          <SipcoColumn
            field="29"
            header={<LanguageProvider id="10021" alt="Familias" />}
            sortable
            filter
          />
          <SipcoColumn
            field="30"
            header={<LanguageProvider id="6590" alt="Código" />}
            sortable
            filter
          />
          <SipcoColumn
            field="31"
            header={<LanguageProvider id="16809" alt="Categorías" />}
            sortable
            filter
          />
          <SipcoColumn
            field="32"
            header={<LanguageProvider id="6590" alt="Código" />}
            sortable
            filter
          />
          <SipcoColumn
            field="33"
            header={<LanguageProvider id="32" alt="Valor" />}
            sortable
            filter
          />
          <SipcoColumn
            field="34"
            header={<LanguageProvider id="16544" alt="Observación del daño" />}
            sortable
            filter
          />
          <SipcoColumn
            field="35"
            header={<LanguageProvider id="1" alt="Usuario" />}
            sortable
            filter
          />
          <SipcoColumn
            field="36"
            header={<LanguageProvider id="16576" alt="Fecha reparado" />}
            sortable
            filter
          />
          <SipcoColumn
            field="37"
            header={<LanguageProvider id="2941" alt="Concesionario" />}
            sortable
            filter
          />
          <SipcoColumn
            field="38"
            header={<LanguageProvider id="11070" alt="Cod. Inspector" />}
            sortable
            filter
          />
          <SipcoColumn
            field="39"
            header={<LanguageProvider id="14712" alt="Nro. Hoja Ruta" />}
            sortable
            filter
          />
          <SipcoColumn
            field="40"
            header={<LanguageProvider id="4870" alt="Patente Tractor" />}
            sortable
            filter
          />
          <SipcoColumn
            field="41"
            header={<LanguageProvider id="4871" alt="Patente Remolque" />}
            sortable
            filter
          />
          <SipcoColumn
            field="42"
            header={<LanguageProvider id="4655" alt="Chofer" />}
            sortable
            filter
          />
          <SipcoColumn
            field="43"
            header={<LanguageProvider id="18096" alt="Nro. Dossier" />}
            sortable
            filter
          />
          <SipcoColumn
            field="44"
            header={<LanguageProvider id="18125" alt="Comentario Dossier" />}
            sortable
            filter
          />
          <SipcoColumn
            field="45"
            header={<LanguageProvider id="18097" alt="Fecha SAGAI" />}
            sortable
            filter
          />
          <SipcoColumn
            field="46"
            header={<LanguageProvider id="18099" alt="Nro. Check List" />}
            sortable
            filter
          />
          <SipcoColumn
            field="47"
            header={<LanguageProvider id="18098" alt="Resultado Analisis" />}
            sortable
            filter
          />
          <SipcoColumn
            field="48"
            header={<LanguageProvider id="1269" alt="Ubicación" />}
            sortable
            filter
          />
          <SipcoColumn
            field="49"
            header={
              <LanguageProvider id="19126" alt="Fecha Ult. Ingreso a Playa" />
            }
            sortable
            filter
          />
          <SipcoColumn
            field="50"
            header={<LanguageProvider id="14535" alt="Destino" />}
            sortable
            filter
          />
          <SipcoColumn
            field="51"
            header={<LanguageProvider id="1355" alt="Fecha de Daño" />}
            sortable
            filter
          />
          <SipcoColumn
            field="52"
            header={<LanguageProvider id="16541" alt="Fecha de carga" />}
            sortable
            filter
          />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default HistoricoDanos;
