import "./PopupDevolucionDanios.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import { useLocation } from "react-router-dom";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { HttpStatusCode } from "axios";
import useFilterColumnsIds from "../../../../hooks/useFilterColumnsIds";
import { LanguageProviderString } from "@shared/components/language-provider-string";
import useAuth from "@shared/AuthContext";
import { set } from "date-fns";

interface PropsPopupDevolucionDanios {
  visible: boolean;
  payload?: { pais: {}; cliente: {}; cuenta: {}; vin: { id: string } };
  onClose: () => void;
}

const columnsNamesIds = [
  { header: 10540, type: "STRING", field: "tipoDeDanio.id" },
  { header: 10541, type: "STRING", field: "tipoDeDanio.descripcion" },
  { header: 2490, type: "STRING", field: "partesDanios.id" },
  { header: 16243, type: "STRING", field: "partesDanios.descripcion" },
  { header: 16575, type: "STRING", field: "gravedadDeDanio.id" },
  { header: 17007, type: "STRING", field: "gravedadDeDanio.descripcion" },
  { header: 16544, type: "STRING", field: "observacion" },
  { header: 17009, type: "FECHA_HORA", field: "fechaMovimientoDanio.date" },
  { header: 8385, type: "FECHA_HORA", field: "fechaCarga.date" },
  { header: 3344, type: "STRING", field: "estadoDanio.descripcion" },
  { header: 6197, type: "FECHA_HORA", field: "fechaBaja..date" },
  { header: 20854, type: "STRING", field: "motivoBajaDanio.descripcion" },
  { header: 11314, type: "STRING", field: "checkpoint.id" },
  { header: 11314, type: "STRING", field: "checkpoint.descripcion" },
  { header: 18096, type: "STRING", field: "numeroDossier" },
  { header: 18125, type: "STRING", field: "comentarioDossier" },
  { header: 18097, type: "FECHA", field: "fechaSagai.date" },
  { header: 18099, type: "STRING", field: "numeroCheckList" },
  { header: 18098, type: "STRING", field: "resultadoAnalisis.descripcion" },
];

const PopupDevolucionDanios: React.FC<PropsPopupDevolucionDanios> = ({
  visible,
  onClose,
  payload,
}) => {
  const { values: securityFilters } = useFilterContext();

  const sipcoAxiosService = useSipcoAxiosService();
  const dt = useRef(null);
  const { user } = useAuth();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const columnsNamesTable = useFilterColumnsIds(columnsNamesIds);
  const [dataTableValue, setDataTableValue] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [hd, setHd] = useState<any>({});
  const [estadoDanioNuevo, setStadoDanioNuevo] = useState<any>({});
  const [estadoDanioNuevoOptions, setEstadoDanioNuevoOptions] = useState<any>(
    []
  );
  const [loadingDanioNuevo, setLoadingDanioNuevo] = useState(false);
  const [motivos, setMotivos] = useState<any>({});
  const [motivosOptions, setMotivosOptions] = useState<any>([]);
  const [loadingMotivo, setLoadingMotivo] = useState(false);

  function showToast({ severity, messageId, alt, life = 3000 }) {
    toast.current.show({
      severity,
      detail: `${LanguageProviderString({ id: messageId, alt: alt, user })}`,
      life,
    });
  }

  async function loadDanios() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "/devolucion-mercado-local/find-danios-devolucion",
        {
          ...payload,
        }
      );

      if (status === 200) {
        setDataTableValue(data);
        return data;
      }
    } catch (error) {
      console.error("Error in loadFullSearch:", error);
    } finally {
      setLoading(false);
    }
  }

  async function findEstadosDevolucion() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/devolucion-mercado-local/estados-devolucion",
        {
          ...payload,
        }
      );

      if (status === 200) {
        const updatedElements = data.map((x) => {
          return {
            ...x,
            search: x.descripcion + x.id,
            descripcionFull: `${x.descripcion} - (${x.id})`,
          };
        });
        setEstadoDanioNuevoOptions(updatedElements);
        return updatedElements;
      }
    } catch (error) {
      console.error("Error in findEstadosDevolucion:", error);
    }
  }

  async function findMotivoBajaDanio() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/devolucion-mercado-local/estados-devolucion",
        {
          ...payload,
        }
      );

      if (status === 200) {
        const updatedElements = data.map((x) => {
          return {
            ...x,
            search: x.descripcion + x.id,
            descripcionFull: `${x.descripcion} - (${x.id})`,
          };
        });
        setMotivosOptions(updatedElements);
        return updatedElements;
      }
    } catch (error) {
      console.error("Error in findMotivoBajaDanio:", error);
    }
  }

  async function updateEstadoDevolucion(hdNuevo) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/devolucion-mercado-local/update-estado-devolucion",
        {
          historicoDanios: hdNuevo,
        }
      );
      if (status === 200) {
        if (data) {
          showToast({
            severity: "success",
            messageId: "36",
            alt: "Su transacción se realizó con éxito.",
          });
          loadDanios();
        }
      } else {
        showToast({
          severity: "error",
          messageId: "11",
          alt: "Ha ocurrido un error, su ultima operación no fue realizada. Comuniquese con Sistemas",
        });
      }
    } catch (error) {
      console.error("Error in findMotivoBajaDanio:", error);
    } finally {
      setLoading(false);
    }
  }

  function aceptar() {
    setLoading(true);
    if (hd.estadoDanio.id !== 1 && hd.estadoDanio.id !== 3) {
      toast.current.show({
        severity: "warn",
        detail: `${LanguageProviderString({ id: "20946", alt: "Solo se pueden Modificar Daños en estado 'NUEVO' o 'REPARA CE", user })}`,
        life: 3000,
      });
      setLoading(false);
      return;
    } else {
      let hdNuevo = { ...hd };
      hdNuevo.estadoDanio = estadoDanioNuevo;
      if (estadoDanioNuevo.id === 0) {
        hdNuevo.motivoBajaDanio = motivos;
      } else {
        hdNuevo.motivoBajaDanio = null;
      }
      console.log(hdNuevo);
      updateEstadoDevolucion(hdNuevo);
    }
  }

  function clearValuesPopUp() {
    setHd({});
    setStadoDanioNuevo({});
    setEstadoDanioNuevoOptions([]);
    setMotivos({});
    setMotivosOptions([]);
    setSelectedRow(null);
    setDataTableValue([]);
  }

  useEffect(() => {
    if (visible) {
      const init = async () => {
        await loadDanios();
      };
      init();
    } else {
      clearValuesPopUp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (selectedRow) {
      setHd(selectedRow);
      const init = async () => {
        try {
          setLoadingDanioNuevo(true);
          const estado = await findEstadosDevolucion();
          setStadoDanioNuevo(estado[0]);
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingDanioNuevo(false);
        }
      };
      init();
    } else {
      setHd({});
      setStadoDanioNuevo({});
      setEstadoDanioNuevoOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  useEffect(() => {
    if (estadoDanioNuevo.id === 0) {
      const init = async () => {
        try {
          setLoadingMotivo(true);
          const motivo = await findMotivoBajaDanio();
          setMotivos(motivo[0]);
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingMotivo(false);
        }
      };
      init();
    } else {
      setMotivos({});
      setMotivosOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estadoDanioNuevo]);

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const formatDateIfNeeded = (value) => {
    const isoDatePattern =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}(Z|[+-]\d{2}:\d{2})$/;
    if (typeof value === "string" && isoDatePattern.test(value)) {
      const date = new Date(value);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Janeiro é 0!
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}`; // Formata a data para DD/MM/YYYY HH:MM
    }
    return value;
  };

  return (
    <div>
      <Toast ref={toast} position="bottom-left" />
      <Dialog
        className="pop-up-devolucion-danios"
        header={`${LanguageProviderString({ id: "1043", alt: "Daños del VIN", user })} : ${payload?.vin.id}`}
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={LanguageProvider({ id: "9491", alt: "Aceitar" })}
                icon="pi pi-check"
                onClick={aceptar}
                disabled={selectedRow === null}
                loading={loading}
              />
              <Button
                label={LanguageProvider({ id: "16582", alt: "Fechar" })}
                icon="pi pi-times"
                onClick={onClose}
                className="p-button-text"
                autoFocus
              />
            </div>
          );
        }}
      >
        {/* Data Table Modal */}
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={dataTableValue}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%", marginBottom: "1rem" }}
          selectionMode="single"
          selection={selectedRow}
          loading={loading}
          onSelectionChange={(e) => setSelectedRow(e.value)}
        >
          {columnsNamesTable.map((colum, index) => (
            <SipcoColumn
              key={index}
              field={colum.field}
              header={colum.label}
              sortable
              filter
              body={(rowData) =>
                formatDateIfNeeded(getNestedValue(rowData, colum.field))
              }
            />
          ))}
        </SipcoDataTable>

        <div className="form">
          <div className="form-row">
            <div className={"sipco-option comment-option"}>
              <label>
                <LanguageProvider id={"11314"} alt="CheckPoint" />
              </label>
              <SipcoInputText
                value={hd?.checkpoint?.descripcion || ""}
                readOnly
              />
            </div>
            <div className={"sipco-option comment-option"}>
              <label>
                <LanguageProvider id={"10541"} alt="Descripción Daño" />
              </label>
              <SipcoInputText
                value={hd?.tipoDeDanio?.descripcion || ""}
                readOnly
              />
            </div>
          </div>
          <div className="form-row">
            <div className={"sipco-option comment-option"}>
              <label>
                <LanguageProvider id={"16243"} alt="Parte dañada" />
              </label>
              <SipcoInputText
                value={hd?.partesDanios?.descripcion || ""}
                readOnly
              />
            </div>
            <div className={"sipco-option comment-option"}>
              <label>
                <LanguageProvider id={"17007"} alt="Gravedad del Daño" />
              </label>
              <SipcoInputText
                value={hd?.gravedadDeDanio?.descripcion || ""}
                readOnly
              />
            </div>
          </div>
          <div className="form-row">
            <div className={"sipco-option comment-option"}>
              <label>
                <LanguageProvider id={"5626"} alt="Nuevo Estado" />
              </label>
              <SipcoDropdown
                value={estadoDanioNuevo}
                options={estadoDanioNuevoOptions}
                optionLabel="descripcionFull"
                onChange={(e) => setStadoDanioNuevo(e.value)}
                loading={loadingDanioNuevo}
              />
            </div>
            <div className={"sipco-option comment-option"}>
              <label>
                <LanguageProvider id={"14914"} alt="Motivo de Baja" />
              </label>
              <SipcoDropdown
                value={motivos}
                options={motivosOptions}
                optionLabel="descripcionFull"
                onChange={(e) => setMotivos(e.value)}
                loading={loadingMotivo}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default PopupDevolucionDanios;
