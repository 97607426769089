import "./GeracaoColectaPopUp.scss";

import React, { useEffect, useRef, useState } from "react";
import { LanguageProvider } from "@shared/components/language-provider";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { useFilterContext } from "../../../../protected-routes";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import useFormattedDate from "../../../../../src/hooks/useFormattedDate";
import { Proveedor } from "src/models/Proveedor";
import { ConfirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";

export function GeracaoColectaPopUp({
  modeColecta,
  setModeColecta,
  screenCode,
  colectaSelected,
  listColectasQuitadas,
  selectedColectasQuitada,
}) {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const { formatDate, formatDateTime } = useFormattedDate();
  const toast = useRef<Toast>(null);

  const [date, setDate] = useState(
    modeColecta === "alta"
      ? new Date()
      : new Date(colectaSelected?.fechaHojaRuta?.date)
  );

  //POPUP CABECERA

  const [listTransportista, setListTransportista] = useState([]);
  const [selectedTransportista, setSelectedTransportista] = useState(null);
  const [loadingTransporter, setLoadingTransporter] = useState(false);

  const [listTypeTransportista, setListTypeTransportista] = useState([]);
  const [selectedTypeTransportista, setSelectedTypeTransportista] = useState();
  const [loadingTipeTransportista, setLoadingTipeTransportista] =
    useState(false);

  const [listTractor, setListTractor] = useState([]);
  const [selectedTractor, setSelectedTractor] = useState();
  const [loadingTractor, setLoadingTractor] = useState(false);

  const [listBateaTransp, setListBateaTransp] = useState([]);
  const [selectedBateaTransp, setSelectedBateaTransp] = useState(null);
  const [loadingBatTransp, setLoadingBatTransp] = useState(false);

  const [listChofer, setListChofer] = useState([]);
  const [selectedChofer, setSelectedChofer] = useState(null);
  const [loadingChofer, setLoadingChofer] = useState(false);

  const [listEstadoHr, setListEstadoHr] = useState([]);
  const [selectedEstadoHr, setSelectedEstadoHr] = useState(null);
  const [loadingEstadoHr, setLoadingEstadoHr] = useState(false);

  const [listEstadosLineaHr, setListEstadosLineaHr] = useState([]);
  const [selectedEstadosLineaHr, setSelectedEstadosLineaHr] = useState(null);
  const [loadingEstadosLineaHr, setLoadingEstadosLineaHr] = useState(false);

  const [listCalle, setListCalle] = useState([]);
  const [selectedCalle, setSelectedCalle] = useState(null);
  const [loadingCalle, setLoadingCalle] = useState(false);

  const [listColumnas, setListColumnas] = useState([]);
  const [selectedColumnas, setSelectedColumnas] = useState(null);
  const [loadingColumnas, setLoadingColumnas] = useState(false);

  const [listNivels, setListNivels] = useState([]);
  const [selectedNivels, setSelectedNivels] = useState(null);
  const [loadingNivels, setLoadingNivels] = useState(false);

  const [listDeliveryType, setListDeliveryType] = useState([]);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState(null);
  const [loadingDeliveryType, setLoadingDeliveryType] = useState(false);

  const [checkInfMicrosiga, setCheckInfMicrosiga] = useState(false);
  const [checkBonificada, setCheckBonificada] = useState(false);
  const [checkQUitarJumelage, setCheckQUitarJumelage] = useState(false);

  const [clienteSelectedObject, setClienteSelectedObject] = useState(null);
  const [loadingConc, setLoadingConc] = useState(false);

  const [marcaSelectedObject, setMarcaSelectedObject] = useState(null);

  const [activoChecked, setActivoChecked] = useState(true);

  const [autosByColectas, setAutosByColectas] = useState("10");

  const [selectedNumerodOrden, setSelectedNumerodOrden] = useState(null);

  const [selectedPrioridadOrden, setSelectedPrioridadOrden] = useState(null);
  const [objectPrioridadOrden, setObjectPrioridadOrden] = useState(null);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    //modifica apenas si colectaSelected?.estado?.id !== "1" && colectaSelected?.hojaRuta.estado.id !== "1A"
    //return alert("10718: La colecta no puede ser modificada");
    // if (modeColecta === "alta")
    findEstadoHojaRuta();
  }, []);

  async function validationUpdate() {
    if (modeColecta === "alta") {
    } else {
      if (
        colectaSelected?.estado?.id !== "1" &&
        colectaSelected?.estado?.id !== "1A"
      ) {
        toast.current.show({
          severity: "info",
          summary: "Confirmed",
          detail: LanguageProvider({
            id: 10718,
            alt: "La colecta no puede ser modificada",
          }),
          life: 3000,
        });
        return;
      }
      updateColecta();
    }
  }

  async function updateColecta() {
    setLoadingEstadoHr(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/update-colecta",
        {
          pais: securityFilters.securityValues?.country,
          url: window.location.href,
          title: "Geracao de Coletas",
          jumelage: checkQUitarJumelage,
          hojaRuta: {
            ...colectaSelected,
            pais: securityFilters.securityValues?.country,
            cuenta: securityFilters.securityValues?.account,
            subcuenta: securityFilters.securityValues?.subaccount,
            administradorTractor: selectedTransportista,
            tractor: selectedTractor,
            estado: selectedEstadoHr,
            batea: selectedBateaTransp,
            fechaHojaRuta:
              modeColecta === "alta"
                ? new Date().getTime()
                : new Date(colectaSelected?.fechaHojaRuta?.date).getTime(),
            calle: selectedCalle,
            columna: selectedColumnas,
            numeroOrden: selectedNumerodOrden,
            tipoEntrega: selectedDeliveryType,
            //lineas: listColectasQuitadas
          },
        }
      );
      if (status === 200) {
        saveColecta();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadoHr(false);
    }
  }

  async function saveColecta() {
    setLoadingEstadoHr(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/save-colecta",
        {
          pais: securityFilters.securityValues?.country,
          url: window.location.href,
          title: "Geracao de Coletas",
          cantidadPorColecta: autosByColectas,
          jumelage: checkQUitarJumelage,
          hojaRuta: {
            ...colectaSelected,
            pais: securityFilters.securityValues?.country,
            cuenta: securityFilters.securityValues?.account,
            subcuenta: securityFilters.securityValues?.subaccount,
            administradorTractor: selectedTransportista,
            tractor: selectedTractor,
            estado: selectedEstadoHr,
            batea: selectedBateaTransp,
            fechaHojaRuta:
              modeColecta === "alta"
                ? new Date().getTime()
                : new Date(colectaSelected?.fechaHojaRuta?.date).getTime(),
            calle: selectedCalle,
            columna: selectedColumnas,
            numeroOrden: selectedNumerodOrden,
            tipoEntrega: selectedDeliveryType,
            //lineas: listColectasQuitadas
          },
        }
      );
      if (status === 200) {
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadoHr(false);
    }
  }

  const accept = () => {
    //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    validationUpdate();
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const footerContent = (
    <div>
      <ConfirmPopup
        visible={visible}
        onHide={() => setVisible(false)}
        message="¿Confirma las modificaciones?" //"28": "¿Confirma las modificaciones?",
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
        pt={{ root: { className: "custom-popup" } }}
      />
      <div className="card flex justify-content-center">
        <Button
          onClick={() => setVisible(true)}
          icon="pi pi-check"
          label="Aceptar"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          onClick={() => {
            setModeColecta("");
          }}
          className="p-button-text"
        />
      </div>
    </div>
  );

  const footerContentNacionalizacion = (
    <div>
      <Button
        label="Aceptar"
        icon="pi pi-check"
        onClick={() => {
          if (!clienteSelectedObject?.id || !marcaSelectedObject?.id) {
            // return alert("selecione corretamente");
          }
        }}
        autoFocus
      />

      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => {
          setShowNacionalizacion(false);
        }}
        className="p-button-text"
      />
    </div>
  );

  async function save() {
    // return alert("aguarde a pesquisa");

    let body = {
      activo: activoChecked,
      pais: securityFilters.securityValues.country,

      cliente: clienteSelectedObject,
    };

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/mantenerUsuario/saveMarcaUsuario",
        body,
        {}
      );

      if (status === 200) {
        setClienteSelectedObject(null);
        setMarcaSelectedObject(null);

        // toast.current.show({
        //   severity: "success",
        //   summary: "Success",
        //   detail: "Carrera forzada con éxito!",
        //   life: 3000,
        // });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findTransporter() {
    setLoadingTransporter(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/proveedor",
        {
          pais: securityFilters.securityValues?.country,
          cuenta: securityFilters.securityValues?.account,
          cliente: securityFilters.securityValues?.client,
          subcuenta: securityFilters.securityValues?.subaccount,
        }
      );
      if (status === 200) {
        setListTransportista(data);

        if (modeColecta === "alta") {
          setSelectedTransportista(data[0]);
          findCalles(data[0]);
          findTractor(data[0]);
          findChofer(data[0]);
        } else {
          const oProvedoor = data.find(
            (v) => v.id === colectaSelected?.administradorTractor?.id
          );
          setSelectedTransportista(oProvedoor);
          findCalles(oProvedoor);
          findTractor(oProvedoor);
          findChofer(oProvedoor);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransporter(false);
      setLoadingCalle(false);
    }
  }

  async function findEstadosLineaHojaRutaBy() {
    setLoadingEstadoHr(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/find-estados-linea-hoja-ruta",
        {
          pais: securityFilters.securityValues?.country,
        }
      );
      if (status === 200) {
        setListEstadosLineaHr(data);

        findClients();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadoHr(false);
    }
  }

  async function findEstadoHojaRuta() {
    setLoadingEstadoHr(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/estadoHojaRuta",
        {
          pais: securityFilters.securityValues?.country,
        }
      );
      if (status === 200) {
        setListEstadoHr(data);

        if (modeColecta === "alta") {
          let newState = data.filter((v) => v.id === "1")[0];
          setSelectedEstadoHr(newState);
        } else {
          const newState = data.find(
            (v) => v.id === colectaSelected?.estado?.id
          );
          setSelectedEstadoHr(newState);
        }
        findEstadosLineaHojaRutaBy();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadoHr(false);
    }
  }

  async function findDeliveryType() {
    setLoadingDeliveryType(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/find-tipo-entrega-order",
        {
          pais: securityFilters.securityValues?.country,
        }
      );
      if (status === 200) {
        setListDeliveryType(data);
        if (modeColecta === "alta") {
          setSelectedDeliveryType(data[0]);
        } else {
          const setDelivTipe = data.find(
            (v) => v.id === colectaSelected?.tipoEntrega.id
          );
          setSelectedDeliveryType(setDelivTipe);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDeliveryType(false);
    }
  }

  async function findTipoTransferencia() {
    setLoadingDeliveryType(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/find-tipo-transferencia-colecta",
        {
          pais: securityFilters.securityValues?.country,
        }
      );
      if (status === 200) {
        setListTypeTransportista(data);
        setSelectedTypeTransportista(data[0]);
        findDeliveryType();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDeliveryType(false);
    }
  }

  async function findCalles(proveedor) {
    setLoadingCalle(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/all-calles",
        {
          pais: securityFilters.securityValues?.country,
          cuenta: securityFilters.securityValues?.account,
          cliente: securityFilters.securityValues?.client,
          subcuenta: securityFilters.securityValues?.subaccount,
        }
      );
      if (status === 200) {
        setListCalle(data);
        findTipoTransferencia();
        if (modeColecta === "modificar") {
          const setCalle = data.find((v) => v === colectaSelected?.calle);
          setSelectedCalle(setCalle);
          findColumnas(setCalle, proveedor);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findColumnas(calle, proveedor) {
    setLoadingColumnas(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/all-columnas",
        {
          pais: securityFilters.securityValues?.country,
          cuenta: securityFilters.securityValues?.account,
          cliente: securityFilters.securityValues?.client,
          subcuenta: securityFilters.securityValues?.subaccount,
          calle,
        }
      );
      if (status === 200) {
        setListColumnas(data);
        if (modeColecta === "alta") {
          setSelectedColumnas(data[0]);
          findPrioridadOrdem(calle, data[0], proveedor);
        } else {
          const setColumn = data.find((v) => v === colectaSelected.columna);
          setSelectedColumnas(setColumn);
          findPrioridadOrdem(calle, setColumn, proveedor);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumnas(false);
    }
  }

  async function findBateaTranportin(tratorObj, proveedor) {
    setLoadingBatTransp(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/remolque-batea",
        {
          tractor: tratorObj || selectedTractor,
          administradorTractor: proveedor || selectedTransportista,
        }
      );
      if (status === 200) {
        setListBateaTransp(data);
        if (modeColecta === "alta") {
          setSelectedBateaTransp(data[0]);
        } else {
          const setBateaTrans = data.find(
            (v) => v.id === colectaSelected.batea.id
          );
          setSelectedBateaTransp(setBateaTrans);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBatTransp(false);
    }
  }

  async function findChofer(proveedor) {
    setLoadingChofer(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/chofer",
        {
          administradorTractor: proveedor || selectedTransportista,
        }
      );
      if (status === 200) {
        setListChofer(data);
        if (modeColecta === "alta") {
          setSelectedChofer(data[0]);
        } else {
          const setChofer = data.find(
            (v) => v.id === colectaSelected?.chofer?.id
          );
          setSelectedChofer(setChofer);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChofer(false);
    }
  }

  async function findTractor(proveedor) {
    setLoadingTransporter(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/tractores",
        {
          pais: securityFilters.securityValues?.country,
          administradorTractor: proveedor,
        }
      );
      if (status === 200) {
        setListTractor(data);
        if (modeColecta === "alta" && selectedTractor == null)
          setSelectedTractor(data[0]);
        if (modeColecta === "modificar") {
          const setTractor = data.find(
            (v) => v.id === colectaSelected.tractor.id
          );
          setSelectedTractor(setTractor);
        }

        findBateaTranportin(data[0], proveedor);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransporter(false);
    }
  }

  async function findPrioridadOrdem(calle, columna, proveedor) {
    setLoadingTransporter(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/fill-prioridad-orden-of-colecta",
        {
          cliente: securityFilters.securityValues?.client,
          hojaRuta: {
            pais: securityFilters.securityValues?.country,
            cuenta: securityFilters.securityValues?.account,
            subcuenta: securityFilters.securityValues?.subaccount,
            administradorTractor: proveedor || selectedTransportista,
            fechaHojaRuta:
              modeColecta === "alta"
                ? new Date().getTime()
                : new Date(colectaSelected?.fechaHojaRuta?.date).getTime(),
            calle: calle || selectedCalle,
            columna: columna || selectedColumnas,
          },
        }
      );
      if (status === 200) {
        if (modeColecta === "alat") {
          setObjectPrioridadOrden(data);
          setCheckBonificada(data.bonificada);
          setCheckInfMicrosiga(data.informaMicrosiga);
          setSelectedNumerodOrden(data.numeroOrden);
          setSelectedPrioridadOrden(data.prioridad);
          setCheckQUitarJumelage(data.jumelage);
        } else {
          setObjectPrioridadOrden(data);
          setCheckBonificada(colectaSelected?.bonificada);
          setCheckInfMicrosiga(colectaSelected?.informaMicrosiga);
          setSelectedNumerodOrden(data?.numeroOrden);
          setSelectedPrioridadOrden(colectaSelected?.prioridad);
          setCheckQUitarJumelage(colectaSelected.jumelage);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransporter(false);
    }
  }

  //#region DETALLE
  const [despachoStockChecked, setDespachoStockChecked] = useState(true);
  const [transferPlayasChecked, setTransferPlayasChecked] = useState(false);
  const [transferRemitoChecked, settransferRemitoChecked] = useState(false);

  const [listPlayaDestino, setListPlayaDestino] = useState([]);
  const [objectPlayaDestino, setObjectPlayaDestino] = useState(null);

  const [inputPatente, setInputPatente] = useState(null);

  const [objectCliente, setObjectCliente] = useState(null);
  const [ListClientes, setListClientes] = useState([]);
  const [loadingClientes, setLoadingClientes] = useState(false);

  const [loadingSubcuenta, setLoadingSubcuenta] = useState(false);

  const [listVins, setListVins] = useState([]);
  const [ingresseVin, setIngresseVin] = useState("");
  const [objSelectedVin, setObjSelectedVin] = useState(null);

  const [editablePatente, setEditablePatente] = useState(false);

  const [checkedBarCod, setCheckedBarCod] = useState(false);

  const [ListPatioUnidade, setListPatioUnidade] = useState([]);

  const [selectedPatioUnidade, setSelectedPatioUnidade] = useState(false);

  async function findSubCuentaDestino() {
    setLoadingTransporter(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/subcuentaDestino",
        {
          cuenta: securityFilters.securityValues?.account,
        }
      );
      if (status === 200) {
        setListPlayaDestino(data);
        findTransporter();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransporter(false);
    }
  }

  async function findSubCuentaPatioOrigen() {
    //playa/patio unidade
    setLoadingSubcuenta(true);

    const userData = localStorage.getItem("loginData");
    const authenticatedUser = JSON.parse(userData);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/subcuentaOrigen",
        {
          usuario: { id: authenticatedUser?.id },
          cuenta: securityFilters.securityValues?.account,
          funcion: screenCode,
          sistema: "02",
          idioma: authenticatedUser.idioma.codIdioma,
          nivel: authenticatedUser.nivel,
        }
      );
      if (status === 200) {
        findSubCuentaDestino();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSubcuenta(false);
    }
  }

  async function findClients() {
    setLoadingClientes(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/cliente",
        {
          pais: securityFilters.securityValues?.country,
        }
      );
      if (status === 200) {
        setListClientes(data);
        setObjectCliente(data[0]);
        findSubCuentaPatioOrigen();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClientes(false);
    }
  }

  async function findVinStockPlaya() {
    setLoadingCalle(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/find-with-vin-stock-by-hr",
        {
          pais: securityFilters.securityValues?.country,
          cuenta: securityFilters.securityValues?.account,
          cliente: securityFilters.securityValues?.client,
          subcuenta: securityFilters.securityValues?.subaccount,
          transferencia: transferRemitoChecked,
          vin: {
            id: ingresseVin,
          },
        }
      );
      if (status === 200) {
        if (data.length <= 0) alert("o Vin não existe no stock");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function vinInStockBy() {
    setLoadingCalle(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/vin-in-stock",
        {
          pais: securityFilters.securityValues?.country,
          vin: {
            id: ingresseVin,
          },
        }
      );
      if (status === 200) {
        if (data.length === 1) {
          alert(
            "10344: Existe el mismo vin para dos clientes, se propone el cliente del vehiculo en stock"
          );
          const vin = data[0];
          if (vin.stockPlaya == null) {
            alert("1489 El VIN no se encuentra en stock.");
            setSelectedPatioUnidade(null); //limpiarPlayaOrigen();
            return;
          } else if (
            vin.stockPlaya.subcuenta !==
            securityFilters.securityValues?.subaccount
          ) {
            alert("10858 El vin no corresponde a la Subucuenta seleccionada");
            setSelectedPatioUnidade(null); //limpiarPlayaOrigen();
            return;
          }
          setSelectedPatioUnidade(securityFilters.securityValues?.subaccount);
          completaCombos(vin);
        } else if (data.length === 0) {
          alert("1489: El VIN no se encuentra en stock.");
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findStockPlayaBy() {
    setLoadingCalle(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/stock-playa",
        {
          pais: securityFilters.securityValues?.country,
          vin: {
            id: ingresseVin,
          },
        }
      );
      if (status === 200) {
        const stockPlaya = data;
        if (stockPlaya == null) {
          alert("654 No existe el VIN");
        } else {
          const msg: String =
            stockPlaya.cliente +
            "/" +
            stockPlaya.cuenta +
            "/" +
            stockPlaya.subcuenta;
          alert(
            "655 El VIN solicitado pertenece al Cliente/Cuenta/Playa " + msg
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function validVin() {
    setLoadingCalle(true);

    if (ingresseVin.length < 8 || ingresseVin.length > 17) {
      return alert(
        "653 Debe ingresar un minimo de 8 y un maximo de 17 dígitos"
      );
    }

    try {
      const { status, data } = await sipcoAxiosService.post("/colectas/vin", {
        pais: securityFilters.securityValues?.country,
        cuenta: securityFilters.securityValues?.account,
        vin: {
          id: ingresseVin,
        },
      });
      if (status === 200) {

        if (data.length <= 0) return alert("o Vin não existe");

        setObjSelectedVin(data[0]);

        if (data.length > 0) {
          if (data.length === 1) {
            if (data[0].stockPlaya == null) {
              alert("1489: El VIN no se encuentra en stock.");
            } else if (
              data[0].stockPlaya.subcuenta.id !==
              securityFilters.securityValues?.subaccount
            ) {
              alert("10858 El vin no corresponde a la Subucuenta seleccionada");
            }

            completaCombos(data[0]);
          } else {
            vinInStockBy();
          }
        } else {
          findStockPlayaBy();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findOperacionesPendientes(screenCode, tipoBloqueo) {
    setLoadingCalle(true);

    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/find-with-vin-stock-by-hr",
        {
          pais: securityFilters.securityValues?.country,
          cuenta: securityFilters.securityValues?.account,
          cliente: securityFilters.securityValues?.client,
          function: screenCode,
          estado: {
            id: tipoBloqueo,
          },
          transferencia: transferRemitoChecked,
          vin: {
            id: ingresseVin,
          },
        }
      );
      if (status === 200) {
        if (data.length <= 0) alert("o Vin não existe no stock");
      }
    } catch (error) {
      console.error(error);
    }
  }

  function completaCombos(vin) {
    cargaPatente(vin);

    if (!transferRemitoChecked) {
      findVinStockPlaya();
    } else {
      if (checkedBarCod)
        findOperacionesPendientes("fun0103_colecta", "ALERTA VISUAL");
    }
  }

  function cargaPatente(vin) {
    if (vin?.patente !== null) {
      if (vin.patente !== "") {
        setEditablePatente(false);
      } else {
        setEditablePatente(true);
      }
    } else {
      setEditablePatente(true);
    }
  }

  //#region popup Nacionalizacion
  const [showNacionalizacion, setShowNacionalizacion] = useState(false);
  const [listGridNacionalization, setlistGridNacionalization] = useState([]);
  const [itemSelectedGridNacion, setitemSelectedGridNacion] = useState(null);

  const [tipoDespacho, setTipoDespacho] = useState(
    despachoStockChecked ? 0 : 1
  );

  const [listMarcas, setListMarcas] = useState([]); //Fazer a dropdaw no front
  const [selectedMarca, setselectedMarca] = useState(null);

  async function findListGridNacionalizacion() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/findLineasByNacionalizacion",
        {
          pais: securityFilters.securityValues?.country,
          cuenta: securityFilters.securityValues?.account,
          cliente: securityFilters.securityValues?.client,
          subcuenta: securityFilters.securityValues?.subaccount,
          administradorTractor: selectedTransportista,
          masrca: selectedMarca,
          subcuentaDestino: objectPlayaDestino,
        },
        {
          params: {
            tipoDespacho: tipoDespacho,
          },
        }
      );
      if (status === 200) {
        setlistGridNacionalization(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findMarcas() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/findMarcas",
        {
          pais: securityFilters.securityValues?.country,
          cliente: securityFilters.securityValues?.client,
        }
      );
      if (status === 200) {
        let optionAll = { descripcion: "TODOS" };
        data.unshift(optionAll);
        setselectedMarca(optionAll);
        setListMarcas(data);

        findListGridNacionalizacion();
      }
    } catch (error) {
      console.error(error);
    }
  }

  function getRowStyles(rowData) {
    rowData.bloqueado = false;
    if (
      rowData.lineaHojaRuta.vin.fechaBloqueoCalidad != null ||
      rowData.lineaHojaRuta.vin.fechaIndisponible != null
    ) {
      rowData.bloqueado = true;
      return "bg-white text-red-400";
    } else {
      if (rowData.cantidadProveedores > 1) {
        return "bg-white text-blue-700";
      }
      if (rowData.operacionPendienteBloqueo) {
        return "bg-white text-rose-900";
      }
    }
    return null;
  }

  return (
    <div className={"GeracaoColectaPopUp"}>
      <Toast ref={toast} position="top-center" />
      <Dialog
        header={"Geracao de Coletas"} //Translate
        visible={modeColecta === "" ? false : true}
        maximizable
        style={{ width: "55vw" }}
        footer={footerContent}
        onHide={() => {
          if (!modeColecta) return;
          setModeColecta("");
        }}
      >
        <Panel>
          <TabView>
            {
              //#region PopUp Cabecera Hoja Huta
            }
            <TabPanel header={"Cabecera de la Hoja de Huta"}>
              {" "}
              {/*3245*/}
              <div className="org-Hor">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id="1342" alt="Fecha Despacho" />
                  </label>
                  <SipcoCalendar
                    value={date}
                    showButtonBar
                    showIcon
                    showTime
                    onChange={(e) => {
                      setDate(e.value.toISOString());
                    }}
                    disabled={modeColecta === "modificar"}
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id="754" alt="Transportista" />
                  </label>
                  <SipcoDropdown
                    value={selectedTransportista}
                    placeholder={
                      colectaSelected?.administradorTractor?.descripcion
                    }
                    options={listTransportista}
                    onChange={(e) => {
                      setSelectedTransportista(e.value);
                      findTractor(e.value);
                      findChofer(e.value);
                    }}
                    itemTemplate={(option) =>
                      `${option.descripcion} - (${option.id})`
                    }
                    loading={loadingTransporter}
                    filter
                    optionLabel="descripcion"
                    disabled={modeColecta === "modificar"}
                  />
                </div>
              </div>
              <div className="org-Hor">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id="3754" alt="Estado" />
                  </label>
                  <SipcoDropdown
                    value={selectedEstadoHr}
                    placeholder={colectaSelected?.estado?.descripcion}
                    options={listEstadoHr}
                    onChange={(e) => setSelectedEstadoHr(e.value)}
                    optionLabel="descripcion"
                    loading={loadingEstadoHr}
                    filter
                    disabled={true}
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Tractor" />
                  </label>
                  <SipcoDropdown
                    value={
                      selectedTractor || colectaSelected?.tractor?.descripcion
                    }
                    options={listTractor}
                    onChange={(e) => {
                      setSelectedTractor(e.value);
                      findBateaTranportin(e.value, selectedTransportista);
                      findChofer(selectedTransportista);
                    }}
                    itemTemplate={(option) =>
                      `${option.descripcion} - (${option.id})`
                    }
                    optionLabel="descripcion"
                    loading={loadingTractor}
                    disabled={modeColecta === "modificar"}
                  />
                </div>
              </div>
              <div className="org-Hor">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Calle" />
                  </label>
                  <SipcoDropdown
                    value={selectedCalle}
                    placeholder={colectaSelected?.calle}
                    options={listCalle}
                    onChange={(e) => {
                      setSelectedCalle(e.value);
                      findColumnas(e.value, selectedTransportista);
                    }}
                    optionLabel="descripcion"
                    loading={loadingCalle}
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Batea / Transportín" />
                  </label>
                  <SipcoDropdown
                    value={selectedBateaTransp}
                    options={listBateaTransp}
                    onChange={(e) => setSelectedBateaTransp(e.value)}
                    itemTemplate={(option) =>
                      `${option.descripcion} - (${option.id})`
                    }
                    optionLabel="descripcion"
                    loading={loadingBatTransp}
                    filter
                    disabled={modeColecta === "modificar"}
                  />
                </div>
              </div>
              <div className="org-Hor">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Columna" />
                  </label>
                  <SipcoDropdown
                    value={selectedColumnas}
                    placeholder={colectaSelected?.columna}
                    options={listColumnas}
                    onChange={(e) => {
                      setSelectedColumnas(e.value);
                      findPrioridadOrdem(
                        selectedCalle,
                        e.value,
                        selectedTransportista
                      );
                    }}
                    optionLabel="descripcion"
                    loading={loadingColumnas}
                    filter
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Chofer :" />
                  </label>
                  <SipcoDropdown
                    value={selectedChofer}
                    options={listChofer}
                    placeholder={colectaSelected?.chofer?.nombre}
                    onChange={(e) => setSelectedChofer(e.value)}
                    itemTemplate={(option) =>
                      option
                        ? `${option.apellido} ${option.nombre} - (${option.id})`
                        : ""
                    }
                    valueTemplate={(option) =>
                      option
                        ? `${option.apellido} ${option.nombre} - (${option.id})`
                        : ""
                    }
                    loading={loadingChofer}
                    optionLabel="descripcion"
                    filter
                    disabled={modeColecta === "modificar"}
                  />
                </div>
              </div>
              <div className="org-Hor">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider
                      id="TODO"
                      alt="Cantidad de Autos por Colecta"
                    />
                  </label>
                  <SipcoInputText
                    value={autosByColectas}
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value))) {
                        setAutosByColectas(e.target.value);
                      }
                    }}
                    type="int"
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id="TODO" alt="Nro. Ordem" />
                  </label>
                  <SipcoInputText
                    value={selectedNumerodOrden}
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value))) {
                        setSelectedNumerodOrden(e.target.value);
                      }
                    }}
                    type="int"
                    maxLength={3}
                  />
                </div>
              </div>
              <div className="org-Hor">
                <div className={"org-vert"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Informa Microsiga" />
                  </label>
                  <Checkbox
                    checked={checkInfMicrosiga}
                    disabled={modeColecta === "modificar"}
                    onClick={() => {
                      setCheckInfMicrosiga(!checkInfMicrosiga);
                    }}
                  ></Checkbox>
                </div>

                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id="4976" alt="Prioridad" />
                  </label>
                  <SipcoInputText
                    value={selectedPrioridadOrden}
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value))) {
                        setSelectedPrioridadOrden(e.target.value);
                      }
                    }}
                    type="int"
                    maxLength={3}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="org-Hor">
                <div className={"org-vert"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Bonificada" />
                  </label>
                  <Checkbox
                    checked={checkBonificada}
                    disabled={modeColecta === "modificar"}
                    onClick={() => {
                      setCheckBonificada(!checkBonificada);
                    }}
                  ></Checkbox>
                </div>{" "}
                <div className={"org-vert"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Quitar del Jumelage" />
                  </label>
                  <Checkbox
                    checked={checkQUitarJumelage}
                    onClick={() => {
                      setCheckQUitarJumelage(!checkQUitarJumelage);
                    }}
                  ></Checkbox>
                </div>
              </div>
              <div className="org-Hor">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Tipo de Entrega" />
                  </label>
                  <SipcoDropdown
                    value={selectedDeliveryType}
                    options={listDeliveryType}
                    onChange={(e) => setSelectedDeliveryType(e.value)}
                    optionLabel="descripcion"
                    loading={loadingDeliveryType}
                    filter
                  />
                </div>
              </div>
            </TabPanel>

            {
              //#region Detalle de la Hoja de Ruta
            }

            <TabPanel header={"Detalle de la Hoja de Ruta"}>
              <div className="org-Hor">
                <Button
                  label={LanguageProvider({
                    id: "10711",
                    alt: "Notas Fiscales Disponibles",
                  })}
                  onClick={() => {
                    setShowNacionalizacion(true);
                    findMarcas();
                  }}
                />
              </div>
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                //ref={dt}
                value={
                  Array.isArray(listColectasQuitadas)
                    ? listColectasQuitadas
                    : []
                }
                paginator
                scrollHeight="flex"
                rows={7}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                resizableColumns
                removableSort
                //rowClassName={rowStyles}
                filterDisplay="menu"
                //loading={loadingChildReportData}
                selectionMode="single"
                //onSelectionChange={(e) => setSelectedChildReportData(e.value)}
                selection={colectaSelected}
              >
                <SipcoColumn
                  field="cliente.descripcion"
                  header={<LanguageProvider id="54" alt="Cliente" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="vin.id"
                  header={<LanguageProvider id="1491" alt="VIN" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="vin.patente"
                  header={<LanguageProvider id="4724" alt="Patente" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="remito"
                  header={<LanguageProvider id="3184" alt="Remito" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="subcuentaOrigen.descripcion"
                  header={
                    <LanguageProvider id="16457" alt="Playa de la Unidad" />
                  }
                  sortable
                  filter
                />
                <SipcoColumn
                  field="subcuenta.descripcion"
                  header={<LanguageProvider id="740" alt="Playa Desino" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="concesionarioP.descripcion"
                  header={<LanguageProvider id="2941" alt="Concesionario" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="posicion"
                  header={<LanguageProvider id="4399" alt="Posición" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="ordenEntrega"
                  header={<LanguageProvider id="16459" alt="Orden entrega" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="usuarioUltimaModificacion.id"
                  header={<LanguageProvider id="16456" alt="Modificó" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="fechaUltimaModificacion.date"
                  header={
                    <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />
                  }
                  sortable
                  filter
                  body={(rowData) =>
                    rowData.fechaUltimaModificacion?.date
                      ? formatDate(rowData.fechaUltimaModificacion.date)
                      : rowData.fechaUltimaModificacion || ""
                  }
                />
                <SipcoColumn
                  field="estado.id"
                  header={<LanguageProvider id="3344" alt="Estado" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="tipoRegistro.descripcion"
                  header={<LanguageProvider id="504" alt="Tipo Despacho" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="estado.descripcion"
                  header={<LanguageProvider id="3344" alt="Estado" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="vin.marca.descripcion"
                  header={<LanguageProvider id="396" alt="Marca" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="vin.modelo.descripcion"
                  header={<LanguageProvider id="2139" alt="Modelo" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="contablePuntoRetiro"
                  header={<LanguageProvider id="16460" alt="Pto Retiro" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="contablePuntoRetiro"
                  header={<LanguageProvider id="16460" alt="Pto Retiro" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="vin.marca.id"
                  header={<LanguageProvider id="396" alt="Marca" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="jumelage"
                  header={<LanguageProvider id="11307" alt="Jumelage" />}
                  sortable
                  filter
                  body={(rowData) =>
                    rowData.jumelage === 0 ? (
                      <LanguageProvider
                        id="11143"
                        alt="Vin no usado en Jumelage"
                      />
                    ) : rowData.jumelage === 1 ? (
                      <LanguageProvider
                        id="11142"
                        alt="Vin usado en jumelage"
                      />
                    ) : rowData.jumelage === 2 ? (
                      <LanguageProvider
                        id="11144"
                        alt="Vin eliminado del Jumelage"
                      />
                    ) : (
                      ""
                    )
                  }
                />
                <SipcoColumn
                  field="algoritmoUbicacionPlaya.descripcion"
                  header={<LanguageProvider id="1269" alt="Ubicación" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="tipoTransferencia"
                  header={
                    <LanguageProvider id="21377" alt="Tipo de transferencia" />
                  }
                  sortable
                  filter
                />
              </SipcoDataTable>

              <div className="org-Hor-left">
                <Button
                  label={LanguageProvider({ id: "273", alt: "Consulta Vin" })}
                  onClick={() => {
                    window.open("/consulta-vin", "_blank");
                  }}
                />
              </div>

              <div className="org-Hor">
                <div className={"org-vert"}>
                  <label>
                    <LanguageProvider id="5394" alt="Despacho desde Stock" />
                  </label>
                  <Checkbox
                    checked={despachoStockChecked}
                    onClick={() => {
                      settransferRemitoChecked(false);
                      setTransferPlayasChecked(false);
                      setDespachoStockChecked(!despachoStockChecked);
                    }}
                  ></Checkbox>
                </div>
                <div className={"org-vert"}>
                  <label>
                    <LanguageProvider
                      id="10943"
                      alt="Transferencia. e/playas"
                    />
                  </label>
                  <Checkbox
                    checked={transferPlayasChecked}
                    onClick={() => {
                      settransferRemitoChecked(false);
                      setDespachoStockChecked(false);
                      setTransferPlayasChecked(!transferPlayasChecked);
                    }}
                  ></Checkbox>
                </div>
                <div className={"org-vert"}>
                  <label>
                    <LanguageProvider id="10942" alt="Transferencia s/Remito" />
                  </label>
                  <Checkbox
                    checked={transferRemitoChecked}
                    onClick={() => {
                      setTransferPlayasChecked(false);
                      setDespachoStockChecked(false);
                      settransferRemitoChecked(!transferRemitoChecked);
                    }}
                  ></Checkbox>
                </div>
              </div>

              <div className="org-Hor">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id="901" alt="Ingrese el Vin" />
                  </label>
                  <SipcoDropdown
                    value={ingresseVin}
                    options={[]}
                    filter={false}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        validVin();
                      }
                    }}
                    onChange={(e) => setIngresseVin(e.value)}
                    optionLabel="descripcion"
                    editable
                    loading={false}
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id="4724" alt="Patente" />
                  </label>
                  <SipcoInputText
                    value={inputPatente}
                    onChange={(e) => {
                      setInputPatente(e.target.value);
                    }}
                    disabled={editablePatente}
                  />
                </div>
              </div>
              <div className="org-Hor">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id="3754" alt="Estado" />
                  </label>
                  <SipcoDropdown
                    value={selectedEstadosLineaHr}
                    options={listEstadosLineaHr}
                    onChange={(e) => setListEstadosLineaHr(e.value)}
                    optionLabel="descripcion"
                    loading={false}
                    filter
                    disabled={true}
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id="54" alt="Cliente" />
                  </label>
                  <SipcoDropdown
                    value={objectCliente}
                    options={ListClientes}
                    onChange={(e) => setObjectCliente(e.value)}
                    itemTemplate={(option) =>
                      option ? `${option.descripcion} (${option.id})` : ""
                    }
                    optionLabel="descripcion"
                    loading={ListClientes.length <= 0}
                    filter
                  />
                </div>
              </div>
              {transferRemitoChecked ? (
                ""
              ) : (
                <div className="org-Hor">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"2559"} alt="Nro. Remito" />
                    </label>
                    <SipcoDropdown
                      value={""}
                      options={[]}
                      //onChange={(e) => setFilter(e.value)}
                      optionLabel="descripcionFull"
                      loading={false}
                      filter
                    />
                  </div>
                </div>
              )}
              <div className="org-Hor">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"16988"} alt="Pátio da Unidade" />
                  </label>
                  <SipcoDropdown
                    value={selectedPatioUnidade}
                    options={ListPatioUnidade}
                    onChange={(e) => setSelectedPatioUnidade(e.value)}
                    optionLabel="descripcion"
                    loading={false}
                    filter
                    disabled={true}
                  />
                </div>

                {despachoStockChecked ? (
                  ""
                ) : (
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"740"} alt="Playa Destino" />
                    </label>
                    <SipcoDropdown
                      value={objectPlayaDestino}
                      options={listPlayaDestino}
                      onChange={(e) => setObjectPlayaDestino(e.value)}
                      optionLabel="descripcion"
                      loading={false}
                      filter
                    />
                  </div>
                )}
              </div>

              <div className="org-Hor">
                <div className={"org-vert"}>
                  <label>
                    <LanguageProvider
                      id="1500"
                      alt="Trabajar con Lectora Cod.Barras"
                    />
                  </label>
                  <Checkbox
                    checked={checkedBarCod}
                    onClick={() => {
                      setCheckedBarCod(!activoChecked);
                    }}
                  ></Checkbox>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </Panel>
      </Dialog>

      {
        //#region popupNacionalizacion
      }
      {showNacionalizacion ? (
        <Dialog
          header={"Nacionalización"} //Translate
          visible={showNacionalizacion}
          maximizable
          style={{ width: "55vw" }}
          footer={footerContentNacionalizacion}
          onHide={() => {
            if (!showNacionalizacion) return;
            setModeColecta("");
          }}
        >
          <div className="org-Hor">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"489"} alt="Marcas" />
              </label>
              <SipcoDropdown
                value={selectedMarca}
                options={listMarcas}
                onChange={(e) => {
                  setselectedMarca(e.value === "TODOS" ? null : e.value);
                }}
                optionLabel="descripcion"
                loading={loadingColumnas}
              />
            </div>
          </div>
          <div className="org-Hor">
            <div className={"org-vert"}>
              <div className="red"></div>
              <label>
                <LanguageProvider
                  id="17130"
                  alt="Vin bloqueado o no disponible"
                />
              </label>
            </div>
            <div className={"org-vert"}>
              <div className="blue"></div>
              <label>
                <LanguageProvider
                  id="10741"
                  alt="Concesionario asociado a más de un transportista"
                />
              </label>
            </div>
            <div className={"org-vert"}>
              <div className="dark-red"></div>
              <label>
                <LanguageProvider
                  id="22636"
                  alt="Operación pendiente bloqueante"
                />
              </label>
            </div>
          </div>

          <SipcoDataTable
            className="dataTable-spacing"
            size={"small"}
            showGridlines
            stripedRows
            //ref={dt}
            value={listGridNacionalization}
            paginator
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            resizableColumns
            removableSort
            rowClassName={getRowStyles}
            filterDisplay="menu"
            loading={listGridNacionalization.length <= 0}
            selectionMode="single"
            //onSelectionChange={(e) => setSelectedReportData(e.value)}
            selection={itemSelectedGridNacion}
          >
            <SipcoColumn
              field="lineaHojaRuta.cliente.descripcion"
              header={<LanguageProvider id="3750" alt="Cliente" />}
            />
            <SipcoColumn
              field="lineaHojaRuta.vin.id"
              header={<LanguageProvider id="TODO" alt="Vin" />}
            />
            <SipcoColumn
              field="lineaHojaRuta.vin.patente"
              header={<LanguageProvider id="TODO" alt="Patente" />}
            />
            <SipcoColumn
              field="lineaHojaRuta.vin.marca.descripcion"
              header={<LanguageProvider id="23889" alt="Marca" />}
            />
            <SipcoColumn
              field="lineaHojaRuta.remito.formatRemito"
              header={<LanguageProvider id="TODO" alt="Remito" />}
            />

            <SipcoColumn
              field="lineaHojaRuta.remito.id"
              header={<LanguageProvider id="10855" alt="Paga Flete" />}
            />
            <SipcoColumn
              field="lineaHojaRuta.jumelage"
              header={<LanguageProvider id="11307" alt="Jumelage" />}
            />
            <SipcoColumn
              field="lineaHojaRuta.remito.naturalezaFiscal.id"
              header={<LanguageProvider id="10769" alt="Naturaleza Fiscal" />}
            />
            <SipcoColumn
              field="lineaHojaRuta.remito.naturalezaFiscal.descripcion"
              header={<LanguageProvider id="10769" alt="Naturaleza Fiscal" />}
            />
            <SipcoColumn
              field="lineaHojaRuta.concesionarioP.id"
              header={<LanguageProvider id="10871" alt="Cod. Concesionario" />}
            />
            <SipcoColumn
              field="lineaHojaRuta.concesionarioP.descripcion"
              header={<LanguageProvider id="2941" alt="Concesionario" />}
            />
            <SipcoColumn
              field="lineaHojaRuta.direccionEntregaP.direccion"
              header={
                <LanguageProvider id="18967" alt="Dirección de Entrega" />
              }
            />
            <SipcoColumn
              body={(rowData) =>
                rowData.lineaHojaRuta.remito.fechaEmision.date
                  ? formatDate(rowData.lineaHojaRuta.remito.fechaEmision.date)
                  : rowData.lineaHojaRuta.remito.fechaEmision.dateTime || ""
              }
              header={<LanguageProvider id="1492" alt="Fecha Emisión" />}
            />
            <SipcoColumn
              field="lineaHojaRuta.remito.direccionEntrega.ciudad.descripcion"
              header={<LanguageProvider id="164" alt="Ciudad" />}
            />
            <SipcoColumn
              field="lineaHojaRuta.vin.stockPlaya"
              body={(rowData) =>
                `${rowData.lineaHojaRuta.vin.stockPlaya.calle}${rowData.lineaHojaRuta.vin.stockPlaya.columna}${rowData.lineaHojaRuta.vin.stockPlaya.nivel}`
              }
              header={<LanguageProvider id="21342" alt="Ubicación" />}
            />
            <SipcoColumn
              field="lineaHojaRuta.vin.origen.descripcion"
              header={<LanguageProvider id="2496" alt="Origen" />}
            />
            <SipcoColumn
              body={(rowData) =>
                `${rowData.lineaHojaRuta.vin.modelo.descripcion}  (${rowData.lineaHojaRuta.vin.modelo.id})`
              }
              header={<LanguageProvider id="23865" alt="Modelo" />}
            />
          </SipcoDataTable>
        </Dialog>
      ) : (
        ""
      )}
    </div>
  );
}

export default GeracaoColectaPopUp;
