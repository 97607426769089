import "./ModalVerComprasTaller.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import { useLocation } from "react-router-dom";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { HttpStatusCode } from "axios";
import { format } from "date-fns";

interface ModalComprasTaller {
  visible: boolean;
  onClose: () => void;
  model?: any;
  onConfirm?: (updatedList: any[]) => void;
  selected?: any[];
}

const ModalVerComprasTaller: React.FC<ModalComprasTaller> = ({
  visible,
  onClose,
  model,
  onConfirm,
  selected
}) => {
  const location = useLocation();
  const { pais, cuenta, tallerFilter, subcuenta, nroOrden } =
    location.state || {};

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const columnsNames = [];
  const [selectedRows, setSelectedRows] = useState([]);
  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  function compareArrays(selectRows: any[], arrayBase: any[]): any[] {
    const result = [];
    if (selectRows.length > 0 && arrayBase.length > 0) {
      selectRows.forEach((row: any) => {
        const vinValue = row.piezaId;

        const matchingObject = arrayBase.find(
          (item: any) => item?.pieza?.id === vinValue
        );

        if (matchingObject) {
          result.push(matchingObject);
        }
      });
    }
    return result;
  }

  const [filter, setFilter] = useState({
    cliente: null,
    rubro: null,
    cuentaTaller: null,
    grupoTaller: null,
    peiza: null,
    modelo: null,
    cantidad: null,
    catEntrega: null,
    descuento: null,
    origen: null,
    prestacion: null,
    subPrestacion: null
  });

  const data = convertDateObjects(reporte?.filas);

  const [cliente, setCliente] = useState([]);
  const [loadingCliente, setLoadingCliente] = useState(false);

  async function loadCliente() {
    setLoadingCliente(true);
    try {
      if (pais && cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/cliente",
          {
            pais: pais,
            cuenta: cuenta,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setCliente(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCliente(false);
    }
  }

  const [rubro, setRubro] = useState([]);
  const [loadingRubro, setLoadingRubro] = useState(false);

  async function loadRubro() {
    setLoadingRubro(true);
    try {
      if (pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/rubros",
          {
            pais: pais,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setRubro(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRubro(false);
    }
  }

  const [cuentaTaller, setCuentaTaller] = useState([]);
  const [loadingCuentaTaller, setLoadingCuentaTaller] = useState(false);
  async function loadCuentaTaller() {
    setLoadingCuentaTaller(true);
    try {
      if (pais && cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/rubros",
          {
            pais: pais,
            cuenta: cuenta,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setCuentaTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCuentaTaller(false);
    }
  }

  const [grupoTaller, setGrupoTaller] = useState([]);
  const [loadingGrupoTaller, setLoadingGrupoTaller] = useState(false);

  async function loadGrupoTaller() {
    setLoadingGrupoTaller(true);
    try {
      if (pais && cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/rubros",
          {
            pais: pais,
            cuenta: cuenta,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setGrupoTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrupoTaller(false);
    }
  }

  const [modelo, setModelo] = useState([]);
  const [loadingModelo, setLoadingModelo] = useState(false);

  async function loadModelo() {
    setLoadingModelo(true);
    try {
      if (filter.grupoTaller !== null && pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/modelo-taller",
          {
            pais: pais,
            grupoTaller: filter.grupoTaller,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setModelo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelo(false);
    }
  }

  const [duescuento, setDescuento] = useState([]);
  const [loadingDuescuento, setLoadingDescuento] = useState(false);

  async function loadDuescuento() {
    setLoadingDescuento(true);
    try {
      if (filter.grupoTaller !== null && pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/descuento-taller",
          {
            pais: pais,
            cuenta: cuenta,
            subcuenta: subcuenta,
            taller: tallerFilter
          }
        );
        if (status === HttpStatusCode.Ok) {
          setDescuento(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDescuento(false);
    }
  }


  const [origen, setOrigen] = useState([]);
  const [loadingOrigen, setLoadingOrigen] = useState(false);

  async function loadOrigen() {
    setLoadingOrigen(true);
    try {
      if (selected) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/find-by-user",
          {
          }
        );
        if (status === HttpStatusCode.Ok) {
          setOrigen(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigen(false);
    }
  }

  const [prestacion, setPrestacion] = useState([]);
  const [loadingPrestacion, setLoadingPrestacion] = useState(false);

  async function loadPrestacion() {
    setLoadingPrestacion(true);
    try {
      if (selected) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/find-prestacion",
          {
            codori: selected.map(x => x.codori)[0],
            pais: pais
          }
        );
        if (status === HttpStatusCode.Ok) {
          setPrestacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPrestacion(false);
    }
  }

  const [subPrestacion, setSubPrestacion] = useState([]);
  const [loadingSubPrestacion, setLoadingSubPrestacion] = useState(false);

  async function loadSubPrestacion() {
    setLoadingSubPrestacion(true);
    try {
      if (selected && filter.prestacion) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/find-subprestacion",
          {
            codori: selected.map(x => x.codori)[0],
            prestacion: filter.prestacion?.descripcion
          }
        );
        if (status === HttpStatusCode.Ok) {
          setSubPrestacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSubPrestacion(false);
    }
  }

  const [comprasTaller, setComprasTaller] = useState([]);
  async function loadReport() {
    setLoading(true);
    try {
      if (pais && cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/catalogos",
          {
            pais: pais,
            cuenta: cuenta,
            cliente: filter.cliente,
            rubro: filter.rubro,
            cuentaTaller: filter.cuentaTaller,
            pieza: filter.peiza,
            grupoTaller: filter.grupoTaller,
            modeloTaller: filter.modelo
          }
        );
        if (status === HttpStatusCode.Ok) {
          const response = data.map((x: any) => {
            return {
              ...x,
              piezaId: x?.id,
              pieza: x?.descripcion,
              fechaCotizacion: x?.fechaCotizacion?.date,
              costoPeso: x?.costoPeso,
              costoDolar: x?.costoDolar,
              iva: x?.iva,
              fechaUltimaModificacion: x?.fechaUltimaModificacion?.date,
            }
          });

          setComprasTaller(response);
          setReporte(data);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }


  // Cliente - Grupo Taller - Cuenta Taller - Rubro - Descuento
  useEffect(() => {
    const init = async () => {
      if (visible) {
        try {


          setFilter({
            ...filter,
            cantidad: selected.map(x => x?.cantidad),
            catEntrega: selected.map(x => x?.cantidadEntregada) === null ? 0 : selected.map(x => x?.cantidadEntregada),
            descuento: selected.map(x => x?.descuento?.descripcion),
            origen: selected.map(x => x?.contableOrigen?.descripcion),
            prestacion: selected.map(x => x?.prestacion),
            subPrestacion: selected.map(x => x?.subprestacion),

          });
        } catch (error) {
          console.error(error);
        }
      }
    }

    init();
  }, [visible]);




  const [saveLabel, setSaveLabel] = useState([]);
  const [loadingSabeLabel, setLoadingSaveLabel] = useState(false);

  async function save() {
    setLoadingSaveLabel(true);
    try {
      if (selected && filter.prestacion) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/save",
          {
            detalleCompraTaller: {
              activo: true,
              pais: pais,
              cuenta: cuenta,
              subcuenta: subcuenta,
              taller: tallerFilter,
              linea: selected.map(x => x.linea)[0] || null,
              codori: selected.map(x => x.codori)[0] || null,
              importe: selected.map(x => x.importe)[0] || 0,
              fechaUltimaModificacion: selected.map(x => x.fechaUltimaModificacion)[0] || null,
              usuarioUltimaModificacion: selected.map(x => x.usuarioUltimaModificacion)[0] || null,
              detalleOrdenRepuesto: selected.map(x => x.detalleOrdenRepuesto)[0] || null,
              importeNeto: selected.map(x => x.importeNeto)[0] || 0,
              prestacion: filter.prestacion?.id || null,
              subprestacion: filter.subPrestacion || null,
              cantidad: Number(filter.cantidad) || null,
              id: selected.map(x => x.id)[0],
              descuento: filter.descuento || null,
              clienteRepuesto: filter.cliente || null,
              pieza: compareArrays(selected, reporte)[0],
              cantidadEntregada: Number(filter.catEntrega) || null,
              compraTaller: {
                id: selected.map(x => x.compraTaller?.id)[0],
                pais: pais,
                cuenta: cuenta,
                subcuenta: subcuenta,
                taller: tallerFilter,
              },
            },
          }
        );
        if (status === HttpStatusCode.Ok) {
          setSaveLabel(data);
          onClose();
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSaveLabel(false);
    }
  }

  const columnsTable = [
    { field: "piezaId", header: "Pieza" },
    { field: "pieza", header: "Pieza" },
    { field: "fechaCotizacion", header: "Fecha Cotizacion" },
    { field: "costoPeso", header: "Custo ($)" },
    { field: "costoDolar", header: "Custo(U$D)" },
    { field: "iva", header: "IVA" },
    { field: "fechaUltimaModificacion", header: "Última Modificación" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  return (
    <div>
      <Dialog
        className="modalAlta"
        header="Detalhes - Alta"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={LanguageProvider({ id: "15447", alt: "Confirmar" })}
                icon="pi pi-check"
                onClick={onClose}
                autoFocus
              />
              <Button
                label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                icon="pi pi-times"
                onClick={onClose}
                className="p-button-text"
              />
            </div>
          );
        }}
      >

        <div className="column-form">
          <div className="form" >
            <div className="form-row">
              <div className="sipco-option">
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"3773"} alt="Cantidad" />
                  </label>
                  <SipcoInputText
                    className="input-modal"
                    value={filter.cantidad}
                    disabled
                    onChange={(e) => setFilter({ ...filter, cantidad: e.target.value })}
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"17041"} alt="Cant. Entregada" />
                  </label>
                  <SipcoInputText
                    className="input-modal"
                    disabled
                    value={filter.catEntrega}
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"2263"} alt="Descuento (%)" />
                  </label>
                  <SipcoInputText
                    disabled
                    value={filter.descuento}
                    className="input-modal" />
                </div>
              </div>
            </div>
          </div>


          <div className="form" >
            <div className="form-row">
              <div className="sipco-option">
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"2496"} alt="Origen" />
                  </label>
                  <SipcoInputText
                    disabled
                    value={filter.origen}
                    className="input-modal"

                  />
                </div>
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"22724"} alt="Prestacion" />
                  </label>
                  <SipcoInputText
                    disabled
                    value={filter.prestacion}
                    className="input-modal"
                  />
                </div>


                <div className="input-group">
                  <label>
                    <LanguageProvider id={"22725"} alt="Subprestacion" />
                  </label>
                  <SipcoInputText
                    disabled
                    value={filter.subPrestacion}
                    className="input-modal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </Dialog>
    </div>
  );
};
export default ModalVerComprasTaller;
