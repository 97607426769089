import "./CargaManualVin.scss";
import "primeicons/primeicons.css";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Dialog } from "primereact/dialog";
import useAuth from "@shared/AuthContext";
import { ProgressSpinner } from "primereact/progressspinner";
import SipcoInputText from "@shared/components/sipco-input-text";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Checkbox } from "primereact/checkbox";
import SipcoCalendar from "@shared/components/sipco-calendar";

export function CargaManualVin() {
  const SCREEN_CODE = "fun0068_carga_manual_vin";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const [fieldsEnabled, setFieldsEnabled] = useState(false);
  const [dropDownEnabled, setdropDownEnabled] = useState(false);
  const { user } = useAuth();
  const [loadingProcesar, setLoadingProcesar] = useState(false);
  const [procesarDialog, setProcesarDialog] = useState(false);
  const [elVinNoExisteDialog, setElVinNoExisteDialog] = useState(false);
  const [datosEnBlancoDialog, setDatosEnBlancoDialog] = useState(false);
  const [transacConExitoDialog, setTransacConExitoDialog] = useState(false);

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {}
  };

  async function handleVinInputKeyDown(event) {
    try {
      if (event.key === "Enter") {
        if (filter.vin.length >= 8 && filter.vin.length <= 17) {
          await loadVinActivo();
        } else {
          showError(
            "653",
            "Debe ingresar un mínimo de 8 y un máximo de 17 dígitos"
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleClearFields() {
    try {
      setFilter({ vin: filter.vin, copiaVin: false, cliente: null });
      setModel({
        mercado: null,
        origen: null,
        marca: null,
        modelo: null,
        color: null,
        destino: null,
        origenDeFabricacion: null,
        genero: null,
        denominacionComercial: {},
        codPlataforma: "",

        silueta: null,
        acabado: null,
        motorizacion: null,
        caja: null,
        baseConcepcion: null,
        clienteConcepcion: null,
        anioFabricacion: null,
        modificacion: null,
        fechaUltimaModificacion: null,

        tapizado: null,
        cabeceraOpcional: null,
        patente: "",
        numeroMotor: "",
        modeloLargo: "",
        ordenFabricacion: "",
        actualizoUsuario: "",
        fechaSalidaFabrica: null,
        ncm: "",
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function loadVinActivo() {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/find-vin",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
            vin: { id: filter.vin || null },
          }
        );

        if (status === 200) {
          let vin = data[0];
          if (vin.id.length === 0) {
            return showError("654", "No existe el VIN");
          }

          setFieldsEnabled(true); // habilit button borrar and desabilit button procesar
          setdropDownEnabled(false); // desahabilit dropDown

          setFilter({ ...filter, vin: vin.id });
          const [
            mercados,
            origens,
            marcas,
            modelos,
            colores,
            destinos,
            destOrigens,
            generos,
            denoComerciais,
            siluetas,
            acabados,
            motorizaciones,
            cajas,
            baseConcepciones,
            clienteConcepciones,
            anioFabricaciones,
            modificaciones,
            tapizados,
            opcionais,
          ] = await Promise.all([
            loadMercado(),
            loadOrigen(),
            loadMarca(),
            loadModelo(vin.marca.id),
            loadColor(vin.marca.id),
            loadDestino(),
            loadDestinoOrig(),
            loadGenero(vin.marca.id),
            loadDenoComercial(vin.marca.id),
            loadSilueta(vin.marca.id),
            loadAcabado(vin.marca.id),
            loadMotorizacion(vin.marca.id),
            loadCaja(vin.marca.id),
            loadBaseConcepcion(vin.marca.id),
            loadCliConcesionario(vin.marca.id),
            loadAnioFabricacion(vin.marca.id),
            loadModificacion(vin.marca.id),
            loadTapizado(vin.marca.id),
            loadCabeceraOpcional(vin.marca.id),
          ]);

          vin.mercado = mercados.find((x) => x.id === vin?.mercado?.id) || null;
          vin.origen = origens.find((x) => x.id === vin?.origen?.id) || null;
          vin.marca = marcas.find((x) => x.id === vin?.marca?.id) || null;
          vin.color = colores.find((x) => x.id === vin?.color?.id) || null;
          vin.modelo = modelos.find((x) => x.id === vin?.modelo?.id) || null;
          vin.destino = destinos.find((x) => x.id === vin?.destino?.id) || null;
          vin.origenDeFabricacion =
            destOrigens.find((x) => x.id === vin?.origenDeFabricacion?.id) ||
            null;
          vin.genero = generos.find((x) => x.id === vin?.genero?.id) || null;
          vin.denominacionComercial =
            denoComerciais.find(
              (x) => x.id === vin?.denominacionComercial?.id
            ) || null;
          vin.silueta = siluetas.find((x) => x.id === vin?.silueta?.id) || null;
          vin.acabado = acabados.find((x) => x.id === vin?.acabado?.id) || null;
          vin.motorizacion =
            motorizaciones.find((x) => x.id === vin?.motorizacion?.id) || null;
          vin.caja = cajas.find((x) => x.id === vin?.caja?.id) || null;
          vin.baseConcepcion =
            baseConcepciones.find((x) => x.id === vin?.baseConcepcion?.id) ||
            null;
          vin.clienteConcepcion =
            clienteConcepciones.find(
              (x) => x.id === vin?.clienteConcepcion?.id
            ) || null;
          vin.anioFabricacion =
            anioFabricaciones.find((x) => x.id === vin?.anioFabricacion?.id) ||
            null;
          vin.modificacion =
            modificaciones.find((x) => x.id === vin?.modificacion?.id) || null;
          vin.tapizado = tapizados.find((x) => x.id === vin?.tapizado?.id);
          vin.cabeceraOpcional =
            opcionais.find((x) => x.id === vin?.cabeceraOpcional?.id) || null;

          setModel((prevModel) => ({
            ...prevModel,
            mercado: vin.mercado,
            origen: vin.origen,
            marca: vin.marca,
            modelo: vin.modelo,
            color: vin.color,
            destino: vin.destino,
            origenDeFabricacion: vin.origenDeFabricacion,
            genero: vin.genero,
            denominacionComercial: vin.denominacionComercial,
            codPlataforma: vin.codigoPlataforma,
            silueta: vin.silueta,
            acabado: vin.acabado,
            motorizacion: vin.motorizacion,
            caja: vin.caja,
            baseConcepcion: vin.baseConcepcion,
            clienteConcepcion: vin.clienteConcepcion,
            anioFabricacion: vin.anioFabricacion,
            modificacion: vin.modificacion,
            fechaUltimaModificacion: vin.fechaUltimaModificacion?.date
              ? new Date(vin.fechaUltimaModificacion.date).getTime()
              : null,
            tapizado: vin.tapizado,
            cabeceraOpcional: vin.cabeceraOpcional,
            patente: vin.patente,
            numeroMotor: vin.numeroMotor,
            modeloLargo: vin.modelo.id,
            ordenFabricacion: vin.ordenFabricacion,
            fechaSalidaFabrica: vin.fechaSalidaFabrica?.date
              ? new Date(vin.fechaSalidaFabrica.date).getTime()
              : null,
            ncm: vin.ncm,
          }));
        }
      }
    } catch (error) {
      console.error(error);
      loadFieldsNoData();
      setElVinNoExisteDialog(true); // call no data dialog
      setFieldsEnabled(false); // habilit button procesar and desabilit button borrar
    }
  }

  const loadFieldsNoData = () => {
    if (securityFilters?.securityValues?.country) {
      setdropDownEnabled(true); // habilit dropDown
      handleClearFields();
      try {
        const mercados = loadMercado();
        const origens = loadOrigen();
        const marcas = loadMarca();
        const destinos = loadDestino();
        const destinosOrig = loadDestinoOrig();

        setModel((prevModel) => ({
          ...prevModel,
          mercado: mercados,
          origen: origens,
          marca: marcas,
          destino: destinos,
          origenDeFabricacion: destinosOrig,
          actualizoUsuario: user?.userName,
          fechaUltimaModificacion: new Date().getTime(),
          fechaSalidaFabrica: new Date().getTime(),
        }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const procesarClick = () => {
    try {
      if (requiredFields()) {
        return;
      }
      setProcesarDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  function requiredFields() {
    try {
      if (
        !model?.mercado.id ||
        !model?.origen.id ||
        !model?.marca.id ||
        !model?.modelo.id ||
        !model?.color.id ||
        !model?.destino.id ||
        !model?.origenDeFabricacion.id ||
        !model?.genero.id ||
        !model?.silueta.id ||
        !model?.acabado.id ||
        !model?.motorizacion.id ||
        !model?.caja.id ||
        !model?.baseConcepcion.id ||
        !model?.clienteConcepcion.id ||
        !model?.anioFabricacion.id ||
        !model?.modificacion.id ||
        !model?.tapizado.id ||
        !model?.cabeceraOpcional.id ||
        !model?.numeroMotor ||
        !model?.modeloLargo
      ) {
        setDatosEnBlancoDialog(true);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      setDatosEnBlancoDialog(true);
      return true;
    }
  }

  const handleProcesar = (isConfirmed) => {
    setProcesarDialog(false);
    if (isConfirmed) {
      if (!fieldsEnabled) {
        aProcesar();
      } else {
        aBorrar();
      }
    }
  };

  async function aProcesar() {
    setLoadingProcesar(true);
    try {
      const { status } = await sipcoAxiosService.post(
        "/carga-manual-vin/save-vin",
        {
          ...model,
          denominacionComercial:
            model.denominacionComercial &&
            Object.keys(model.denominacionComercial).length > 0
              ? model.denominacionComercial
              : null,
          id: filter.vin,
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          subcuenta: securityFilters.securityValues.subaccount,
        }
      );

      if (status === 200) {
        handleClearFields();
        setTransacConExitoDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcesar(false);
    }
  }

  const borrarClick = () => {
    try {
      setProcesarDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  async function aBorrar() {
    setLoadingProcesar(true);
    try {
      const { status } = await sipcoAxiosService.post(
        "/carga-manual-vin/remove-vin",
        {
          ...model,
          denominacionComercial:
            model.denominacionComercial &&
            Object.keys(model.denominacionComercial).length > 0
              ? model.denominacionComercial
              : null,
          id: filter.vin,
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          subcuenta: securityFilters.securityValues.subaccount,
        }
      );

      if (status === 200) {
        handleClearFields();
        setTransacConExitoDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcesar(false);
    }
  }

  const handleValidationClose = () => {
    setElVinNoExisteDialog(false);
    setDatosEnBlancoDialog(false);
    setTransacConExitoDialog(false);
  };

  const [filter, setFilter] = useState({
    vin: "",
    copiaVin: false,
    cliente: null,
  });

  const [model, setModel] = useState({
    mercado: null,
    origen: null,
    marca: null,
    modelo: null,
    color: null,
    destino: null,
    origenDeFabricacion: null,
    genero: null,
    denominacionComercial: {},
    codPlataforma: "",

    silueta: null,
    acabado: null,
    motorizacion: null,
    caja: null,
    baseConcepcion: null,
    clienteConcepcion: null,
    anioFabricacion: null,
    modificacion: null,
    fechaUltimaModificacion: null,

    tapizado: null,
    cabeceraOpcional: null,
    patente: "",
    numeroMotor: "",
    modeloLargo: "",
    ordenFabricacion: "",
    actualizoUsuario: "",
    fechaSalidaFabrica: null,
    ncm: "",
  });

  const [loadingCliente, setLoadingCliente] = useState(false);
  const [cliente, setCliente] = useState([]);
  async function loadCliente() {
    setLoadingCliente(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/cliente",
          {}
        );

        if (status === 200) {
          setCliente(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCliente(false);
    }
    setCliente([]);
    return [];
  }

  const [loadingMercado, setLoadingMercado] = useState(false);
  const [mercado, setMercado] = useState([]);
  async function loadMercado() {
    setLoadingMercado(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/mercado",
          {
            pais: securityFilters.securityValues.country || null,
          }
        );

        if (status === 200) {
          setMercado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercado(false);
    }
    setMercado([]);
    return [];
  }

  const [loadingOrigen, setLoadingOrigen] = useState(false);
  const [origen, setOrigen] = useState([]);
  async function loadOrigen() {
    setLoadingOrigen(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/origen",
          {
            pais: securityFilters.securityValues.country || null,
          }
        );

        if (status === 200) {
          setOrigen(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigen(false);
    }
    setOrigen([]);
    return [];
  }

  const [loadingMarca, setLoadingMarca] = useState(false);
  const [marca, setMarca] = useState([]);
  async function loadMarca() {
    setLoadingMarca(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/marca",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            activo: true,
          }
        );

        if (status === 200) {
          setMarca(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
    setMarca([]);
    return [];
  }

  const [loadingModelo, setLoadingModelo] = useState(false);
  const [modelo, setModelo] = useState([]);
  async function loadModelo(marca: any) {
    setLoadingModelo(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/modelo",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          setModelo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelo(false);
    }
    setModelo([]);
    return [];
  }

  const [loadingColor, setLoadingColor] = useState(false);
  const [color, setColor] = useState([]);
  async function loadColor(marca: any) {
    setLoadingColor(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/color",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          setColor(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColor(false);
    }
    setColor([]);
    return [];
  }

  const [loadingDestino, setLoadingDestino] = useState(false);
  const [destino, setDestino] = useState([]);
  async function loadDestino() {
    setLoadingDestino(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/destino",
          {
            pais: securityFilters.securityValues.country || null,
          }
        );

        if (status === 200) {
          setDestino(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestino(false);
    }
    setDestino([]);
    return [];
  }

  const [loadingDestinoOrig, setLoadingDestinoOrig] = useState(false);
  const [destinoOrig, setDestinoOrig] = useState([]);
  async function loadDestinoOrig() {
    setLoadingDestinoOrig(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/destino",
          {
            pais: securityFilters.securityValues.country || null,
          }
        );

        if (status === 200) {
          setDestinoOrig(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinoOrig(false);
    }
    setDestinoOrig([]);
    return [];
  }

  const [loadingGenero, setLoadingGenero] = useState(false);
  const [genero, setGenero] = useState([]);
  async function loadGenero(marca: any) {
    setLoadingGenero(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/genero",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          setGenero(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGenero(false);
    }
    setGenero([]);
    return [];
  }

  const [loadingDenoComercial, setLoadingDenoComercial] = useState(false);
  const [denoComercial, setDenoComercial] = useState(null);
  async function loadDenoComercial(marca: any) {
    setLoadingDenoComercial(true);
    try {
      if (marca && securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/denominacion-comercial",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          setDenoComercial(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDenoComercial(false);
    }
    setDenoComercial(null);
    return null;
  }

  const [loadingSilueta, setLoadingSilueta] = useState(false);
  const [silueta, setSilueta] = useState([]);
  async function loadSilueta(marca: any) {
    setLoadingSilueta(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/silueta",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          setSilueta(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSilueta(false);
    }
    setSilueta([]);
    return [];
  }

  const [loadingAcabado, setLoadingAcabado] = useState(false);
  const [acabado, setAcabado] = useState([]);
  async function loadAcabado(marca: any) {
    setLoadingAcabado(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/acabado",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          setAcabado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAcabado(false);
    }
    setAcabado([]);
    return [];
  }

  const [loadingMotorizacion, setLoadingMotorizacion] = useState(false);
  const [motorizacion, setMotorizacion] = useState([]);
  async function loadMotorizacion(marca: any) {
    setLoadingMotorizacion(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/motorizacion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          setMotorizacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMotorizacion(false);
    }
    setMotorizacion([]);
    return [];
  }

  const [loadingCaja, setLoadingCaja] = useState(false);
  const [caja, setCaja] = useState([]);
  async function loadCaja(marca: any) {
    setLoadingCaja(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/caja",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          setCaja(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCaja(false);
    }
    setCaja([]);
    return [];
  }

  const [loadingBaseConcepcion, setLoadingBaseConcepcion] = useState(false);
  const [baseConcepcion, setBaseConcepcion] = useState([]);
  async function loadBaseConcepcion(marca: any) {
    setLoadingBaseConcepcion(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/base-concepcion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          setBaseConcepcion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBaseConcepcion(false);
    }
    setBaseConcepcion([]);
    return [];
  }

  const [loadingClienteConc, setLoadingClienteConc] = useState(false);
  const [clienteConc, setClienteConc] = useState([]);
  async function loadCliConcesionario(marca: any) {
    setLoadingClienteConc(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/cliente-concepcion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          setClienteConc(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClienteConc(false);
    }
    setClienteConc([]);
    return [];
  }

  const [loadingAnoFabricacion, setLoadingAnoFabricacion] = useState(false);
  const [anioFabricacion, setAnoFabricacion] = useState([]);
  async function loadAnioFabricacion(marca: any) {
    setLoadingAnoFabricacion(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/anio-fabricacion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          setAnoFabricacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAnoFabricacion(false);
    }
    setAnoFabricacion([]);
    return [];
  }

  const [loadingModificacion, setLoadingModificacion] = useState(false);
  const [modificacion, setModificacion] = useState([]);
  async function loadModificacion(marca: any) {
    setLoadingModificacion(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/modificacion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );
        if (status === 200) {
          setModificacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModificacion(false);
    }
    setModificacion([]);
    return [];
  }

  const [loadingTapizado, setLoadingTapizado] = useState(false);
  const [tapizado, setTapizado] = useState([]);
  async function loadTapizado(marca: any) {
    setLoadingTapizado(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/tapizado",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          setTapizado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTapizado(false);
    }
    setTapizado([]);
    return [];
  }

  const [loadingOpcional, setLoadingOpcional] = useState(false);
  const [opcional, setOpcional] = useState([]);
  async function loadCabeceraOpcional(marca: any) {
    setLoadingOpcional(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/cabecera-opcional",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: { id: marca || null },
          }
        );

        if (status === 200) {
          setOpcional(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOpcional(false);
    }
    setOpcional([]);
    return [];
  }

  useEffect(() => {
    async function loadCopiaVin() {
      if (filter.copiaVin) {
        try {
          const clientes = await loadCliente();

          if (clientes?.length > 0) {
            setFilter((prevFilter) => ({
              ...prevFilter,
              cliente: clientes?.length > 0 ? clientes[0] : null,
            }));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    loadCopiaVin();
  }, [filter.copiaVin]);

  useEffect(() => {
    async function dependsOnMarca() {
      if (!elVinNoExisteDialog && dropDownEnabled) {
        try {
          const [
            modelos,
            colores,
            generos,
            denoComerciais,
            siluetas,
            acabados,
            motorizaciones,
            cajas,
            baseConcepciones,
            cliConcesionarios,
            anioFabricaciones,
            modificaciones,
            tapizados,
            opcionais,
          ] = await Promise.all([
            loadModelo(model.marca.id),
            loadColor(model.marca.id),
            loadGenero(model.marca.id),
            loadDenoComercial(model.marca.id),
            loadSilueta(model.marca.id),
            loadAcabado(model.marca.id),
            loadMotorizacion(model.marca.id),
            loadCaja(model.marca.id),
            loadBaseConcepcion(model.marca.id),
            loadCliConcesionario(model.marca.id),
            loadAnioFabricacion(model.marca.id),
            loadModificacion(model.marca.id),
            loadTapizado(model.marca.id),
            loadCabeceraOpcional(model.marca.id),
          ]);

          setModel((prevFilter) => ({
            ...prevFilter,
            modelo: modelos,
            color: colores,
            genero: generos,
            denominacionComercial: denoComerciais,
            silueta: siluetas,
            acabado: acabados,
            motorizacion: motorizaciones,
            caja: cajas,
            baseConcepcion: baseConcepciones,
            clienteConcepcion: cliConcesionarios,
            anioFabricacion: anioFabricaciones,
            modificacion: modificaciones,
            tapizado: tapizados,
            cabeceraOpcional: opcionais,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }

    dependsOnMarca();
  }, [model?.marca]);

  return (
    <div className="carga-manual-vin">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>

      <Fieldset
        legend={<LanguageProvider id={"1465"} alt="Carga Manual del VIN" />}
      >
        <div className="form" style={{ flexDirection: "column" }}>
          <div className="form-row">
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"901"} alt="Ingrese el VIN" />
              </label>
              <SipcoInputText
                value={filter.vin}
                onChange={(e) => {
                  setFilter({ ...filter, vin: e.target.value });
                }}
                onKeyDown={handleVinInputKeyDown}
                maxLength={17}
              />
            </div>
            <label>
              <LanguageProvider
                id={"10196"}
                alt="Copiar el Vin a Otro Cliente"
              />
            </label>
            <Checkbox
              checked={filter.copiaVin}
              onChange={(e) => setFilter({ ...filter, copiaVin: e.checked })}
              disabled={!fieldsEnabled}
            />
            {filter.copiaVin && (
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"24084"} alt="Cliente" />
                </label>
                <SipcoDropdown
                  value={filter.cliente}
                  options={cliente}
                  onChange={(e) =>
                    setFilter({ ...filter, cliente: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingCliente}
                />
              </div>
            )}
          </div>
        </div>
      </Fieldset>

      <Fieldset>
        <div className="form">
          <div className="column ">
            <div className="sipco-option">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"367"} alt="Mercado" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.mercado || null}
                  options={mercado}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      mercado: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingMercado}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2496"} alt="Origen" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.origen || null}
                  options={origen}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      origen: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingOrigen}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"396"} alt="Marca" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.marca || null}
                  options={marca}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      marca: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingMarca}
                  disabled={!dropDownEnabled}
                />
                {/* <Button
                    icon="pi pi-arrow-right"
                    onClick={() =>
                      window.open("/abm0010_mantener_marcas", "_blank")  //NAO USARA POR HORA!
                    }
                  /> */}
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2139"} alt="Modelo" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.modelo || null}
                  options={modelo}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      modelo: e.value || null,
                      modeloLargo: e.value.id,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingModelo}
                  disabled={!dropDownEnabled}
                />
                {/* <Button
                    icon="pi pi-arrow-right"
                    onClick={
                      () => window.open("/abm0027_mantener_modelos", "_blank") //NAO USARA POR HORA!
                    }
                  /> */}
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2140"} alt="Color" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.color || null}
                  options={color}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      color: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingColor}
                  disabled={!dropDownEnabled}
                />
                {/* <Button
                    icon="pi pi-arrow-right"
                    onClick={
                      () => window.open("/abm0012_mantener_colores", "_blank") //NAO USARA POR HORA!
                    }
                  /> */}
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"4102"} alt="Destino" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.destino || null}
                  options={destino}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      destino: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingDestino}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16421"} alt="Destino de origen" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.origenDeFabricacion || null}
                  options={destinoOrig}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      origenDeFabricacion: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingDestinoOrig}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16422"} alt="Género" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.genero || null}
                  options={genero}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      genero: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingGenero}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"18202"} alt="Denominación Comercial" />
                </label>
                <SipcoDropdown
                  value={model?.denominacionComercial || null}
                  options={denoComercial}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      denominacionComercial: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingDenoComercial}
                  disabled={!dropDownEnabled}
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"22209"} alt="Cód. Plataforma" />
                </label>
                <SipcoInputText
                  value={model.codPlataforma}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      codPlataforma: e.target.value.toUpperCase(),
                    });
                  }}
                  disabled={!dropDownEnabled}
                  maxLength={18}
                />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="sipco-option">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16423"} alt="Silueta" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.silueta || null}
                  options={silueta}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      silueta: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingSilueta}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16424"} alt="Acabados" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.acabado || null}
                  options={acabado}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      acabado: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingAcabado}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16425"} alt="Motorización" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.motorizacion || null}
                  options={motorizacion}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      motorizacion: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingMotorizacion}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16426"} alt="Cajas" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.caja || null}
                  options={caja}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      caja: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingCaja}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16427"} alt="Base concepción" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.baseConcepcion || null}
                  options={baseConcepcion}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      baseConcepcion: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingBaseConcepcion}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16307"} alt="Cliente concesionario" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.clienteConcepcion || null}
                  options={clienteConc}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      clienteConcepcion: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingClienteConc}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16429"} alt="Año de fabricación" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.anioFabricacion || null}
                  options={anioFabricacion}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      anioFabricacion: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingAnoFabricacion}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13502"} alt="Modificación" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.modificacion || null}
                  options={modificacion}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      modificacion: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingModificacion}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"12103"} alt="Fecha Fabricación" />
                </label>
                <SipcoCalendar
                  value={
                    model.fechaSalidaFabrica
                      ? new Date(model.fechaSalidaFabrica)
                      : null
                  }
                  onChange={(e) => {
                    const date = e.value ? new Date(e.value).getTime() : null;
                    setModel({ ...model, fechaSalidaFabrica: date });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!dropDownEnabled}
                />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="sipco-option">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16430"} alt="Tapizado" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.tapizado || null}
                  options={tapizado}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      tapizado: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingTapizado}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16431"} alt="Opcional" />
                  <span className="required">*</span>
                </label>
                <SipcoDropdown
                  value={model?.cabeceraOpcional || null}
                  options={opcional}
                  onChange={(e) =>
                    setModel((prevModel) => ({
                      ...prevModel,
                      cabeceraOpcional: e.value || null,
                    }))
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingOpcional}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"4724"} alt="Patente" />
                </label>
                <SipcoInputText
                  value={model.patente}
                  onChange={(e) => {
                    setModel({ ...model, patente: e.target.value });
                  }}
                  disabled={!dropDownEnabled}
                  maxLength={8}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13335"} alt="Motor" />
                  <span className="required">*</span>
                </label>
                <SipcoInputText
                  value={model.numeroMotor}
                  onChange={(e) => {
                    setModel({ ...model, numeroMotor: e.target.value });
                  }}
                  disabled={!dropDownEnabled}
                  maxLength={22}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13338"} alt="Modelo Largo" />
                  <span className="required">*</span>
                </label>
                <SipcoInputText
                  value={model.modeloLargo}
                  onChange={(e) => {
                    setModel({ ...model, modeloLargo: e.target.value });
                  }}
                  disabled={!dropDownEnabled}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10784"} alt="Orden de Fabricación" />
                </label>
                <SipcoInputText
                  value={model.ordenFabricacion}
                  onChange={(e) => {
                    setModel({ ...model, ordenFabricacion: e.target.value });
                  }}
                  disabled={!dropDownEnabled}
                  maxLength={8}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13489"} alt="Actualizó Usuario" />
                </label>
                <SipcoInputText
                  value={model.actualizoUsuario}
                  onChange={(e) => {
                    setModel({ ...model, actualizoUsuario: e.target.value });
                  }}
                  disabled={!dropDownEnabled}
                  readOnly={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13490"} alt="Actualizó con Fecha" />
                </label>
                <SipcoCalendar
                  value={
                    model.fechaUltimaModificacion
                      ? new Date(model.fechaUltimaModificacion)
                      : null
                  }
                  onChange={(e) => {
                    const date = e.value ? new Date(e.value).getTime() : null;
                    setModel({ ...model, fechaUltimaModificacion: date });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={true}
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3505"} alt="NCM" />
                </label>
                <SipcoInputText
                  value={model.ncm}
                  onChange={(e) => {
                    setModel({ ...model, ncm: e.target.value });
                  }}
                  disabled={!dropDownEnabled}
                  maxLength={16}
                />
              </div>
            </div>
          </div>
        </div>
      </Fieldset>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "16283",
            alt: "Procesar",
          })}
          icon="pi pi-save"
          onClick={procesarClick}
          disabled={!dropDownEnabled}
        />
        <Button
          label={LanguageProvider({ id: "2016", alt: "Borrar" })}
          icon="pi pi-times"
          onClick={borrarClick}
          disabled={dropDownEnabled || !fieldsEnabled}
        />
        <Button
          label={LanguageProvider({
            id: "2366",
            alt: "Importar Archivo",
          })}
          icon="pi pi-upload"
          disabled={fieldsEnabled || elVinNoExisteDialog}
        />
      </div>

      <div className="dialogs">
        {/* El VIN no existe Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={elVinNoExisteDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p>
            <LanguageProvider id="1480" alt="El VIN no existe" />
          </p>
        </Dialog>

        {/* Existen datos en blanco Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={datosEnBlancoDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p>
            <LanguageProvider id="39" alt="Existen datos en blanco." />
          </p>
        </Dialog>

        {/* Procesar Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={procesarDialog}
          style={{ width: "15vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("10043", "Si")}
                icon="pi pi-check"
                onClick={() => handleProcesar(true)}
                autoFocus
                disabled={loadingProcesar}
              />
              <Button
                label={getLabel("10044", "No")}
                icon="pi pi-times"
                onClick={() => handleProcesar(false)}
                className="p-button-text"
                disabled={loadingProcesar}
              />
            </div>
          }
          onHide={() => setProcesarDialog(false)}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="13275"
              alt="¿Desea procesar los cambios realizados?"
            />
          </p>
          {loadingProcesar && (
            <ProgressSpinner style={{ width: "50px", height: "50px" }} />
          )}
        </Dialog>

        {/* Su transacción se realizó con éxito Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={transacConExitoDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="36"
              alt="Su transacción se realizó con éxito."
            />
          </p>
        </Dialog>
      </div>
    </div>
  );
}
export default CargaManualVin;

const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
