import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { DanioPendienteTallerModel } from "src/models/DanioPendienteTallerModel";

const DanioPendienteTaller = () => {
  const SCREEN_CODE = "abm0230_danio_pendiente_taller";
  const URL_BASE = "/mantener-danio-pendiente-taller";
  const SCREEN_TITLE = (
    <LanguageProvider id="20847" alt="Mantener Daño NO pendiente taller" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(
    model: DanioPendienteTallerModel,
    filterValues: any
  ) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues.securityValues.client &&
        filterValues.securityValues.account
      ) {
        const danioPendienteTaller = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,          
          cuenta: filterValues.securityValues.account,
          // tipoDeDanio: filterValues.tipoDeDanioFilter,
          // parteDanio: filterValues.parteDanioFilter,
          // gravedadDeDanio: filterValues.gravedadDeDanioFilter,
          // checkpoint: filterValues.checkpointFilter,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          danioPendienteTaller,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(
    model: DanioPendienteTallerModel,
    filterValues: any
  ) {
    try {
      const danioPendienteTaller = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,        
        cuenta: filterValues.securityValues.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        danioPendienteTaller,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(
    model: DanioPendienteTallerModel,
    filterValues: any
  ) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/update", {
        danioPendienteTaller: { ...model,
          activo: false,
         },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<DanioPendienteTallerModel[]> {
    if (filter?.securityValues?.country && filter.securityValues.client) {
      try {
        const payload = {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          concesionario: filter?.concesionarioFilter,
          cuenta: filter.securityValues.account,
          tipoDeDanio: filter.tipoDeDanioFilter,
          parteDanio: filter.parteDanioFilter,
          gravedadDeDanio: filter.gravedadDeDanioFilter,
          checkpoint: filter.checkpointFilter,
        };
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          payload
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<DanioPendienteTallerModel> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          danioPendienteTaller: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadTipoDeDanioFilter(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/tipo-de-danio",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              // search: `${item.descripcion}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadPartesDaniosFilter(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/parte-danio",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              // search: `${item.descripcion}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadGravedadDeDanioFilter(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/gravedad-de-danio",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              // search: `${item.descripcion}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadCheckpointFilter(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/checkpoint",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              // search: `${item.descripcion}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  async function loadTipoDanio(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/tipo-de-danio",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadParteDanio(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/parte-danio",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadGravedadDanio(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/gravedad-de-danio",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadCheckpoint(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/checkpoint",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<DanioPendienteTallerModel>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
          },
        }}
        filterSettings={[
          {
            filter: "tipoDeDanioFilter",
            label: LanguageProvider({ id: "10541", alt: "x" }),
            placeholder: LanguageProvider({ id: "10541", alt: "x" }),
            type: "multiselect",
            optionLabel: "label",
            dataSource: loadTipoDeDanioFilter,
            // dropDownTemplate: DropdownOptionTemplateLabelId,
            dependency: ["securityValues.country", "securityValues.client"],
            virtualizeDropdown: true,
          },
          {
            filter: "partesDaniosFilter",
            label: LanguageProvider({ id: "16243", alt: "x" }),
            placeholder: LanguageProvider({ id: "16243", alt: "x" }),
            type: "multiselect",
            optionLabel: "label",
            dataSource: loadPartesDaniosFilter,
            //dropDownTemplate: DropdownLabelTemplate,
            dependency: ["securityValues.country", "securityValues.client"],
            virtualizeDropdown: true,
          },
          {
            filter: "gravedadDeDanioFilter",
            label: LanguageProvider({ id: "17007", alt: "x" }),
            placeholder: LanguageProvider({ id: "17007", alt: "x" }),
            type: "multiselect",
            optionLabel: "label",
            dataSource: loadGravedadDeDanioFilter,
            //dropDownTemplate: DropdownLabelTemplate,
            dependency: ["securityValues.country", "securityValues.client"],
            virtualizeDropdown: true,
          },
          {
            filter: "checkpointFilter",
            label: LanguageProvider({ id: "11314", alt: "x" }),
            placeholder: LanguageProvider({ id: "11314", alt: "x" }),
            type: "multiselect",
            optionLabel: "label",
            dataSource: loadCheckpointFilter,
            //dropDownTemplate: DropdownLabelTemplate,
            dependency: ["securityValues.country", "securityValues.client"],
            virtualizeDropdown: true,
          },
        ]}
        dataTableColumns={[
          {
            field: "cliente.descripcion",
            header: <LanguageProvider id="54" alt="x" />,
          },
          {
            field: "tipoDeDanio.descripcion",
            header: <LanguageProvider id="10540" alt="x" />,
          },
          {
            field: "parteDanio.descripcion",
            header: <LanguageProvider id="16240" alt="x" />,
          },
          {
            field: "gravedad.descripcion",
            header: <LanguageProvider id="16505" alt="x" />,
          },
          {
            field: "checkpoint.descripcion",
            header: <LanguageProvider id="11314" alt="x" />,
          },
          {
            field: "observacion",
            header: <LanguageProvider id="1669" alt="x" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
            body: (rowData: any) => {
              return (
                <span>
                  {new Date(
                    rowData.fechaUltimaModificacion.date
                  ).toLocaleString()}
                </span>
              );
            },
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => {
              return rowData.activo ? "SI" : "NO";
            },
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          cliente: null,
          cuenta: null,
          tipoDeDanio: null,
          parteDanio: null,
          gravedad: null,
          checkpoint: null,
          observacion: "",
        }}
        formSettings={[
          {
            field: "tipoDeDanio",
            label: LanguageProvider({ id: "10540", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "10540", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadTipoDanio(filter),
            dropDownTemplate: DropdownOptionTemplate,
            filterBy: "search",
          },
          {
            field: "parteDanio",
            label: LanguageProvider({ id: "16240", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "16240", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadParteDanio(filter),
            dropDownTemplate: DropdownOptionTemplate,
            filterBy: "search",
          },
          {
            field: "gravedad",
            label: LanguageProvider({ id: "16505", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "16505", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadGravedadDanio(filter),
            dropDownTemplate: DropdownOptionTemplate,
            filterBy: "search",
          },
          {
            field: "checkpoint",
            label: LanguageProvider({ id: "11314", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "11314", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadCheckpoint(filter),
            dropDownTemplate: DropdownOptionTemplate,
            filterBy: "search",
          },
          {
            field: "observacion",
            label: LanguageProvider({ id: "1669", alt: "x" }),
            type: "inputText",
            length: 100,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default DanioPendienteTaller;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionTemplateLabelId = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionTemplateDireccion = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.direccion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
