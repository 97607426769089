import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { ProveedorLineaCarga } from "src/models/ProveedorLineaCarga";

const MantenerProveedoresLineascarga = () => {
  const SCREEN_CODE = "abm0124_mantener_prov_lineascarga";
  const URL_BASE = "/mantener-prov-lineas-carga";
  const SCREEN_TITLE = (
    <LanguageProvider id="10620" alt="Mantener Proveedores Lineas Carga" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const empityFilter = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - 
    ${LanguageProvider({ id: "2521", alt: "Proveedor" })}`;

  async function handleSave(model: ProveedorLineaCarga, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const proveedorLineaCarga = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues?.securityValues?.account,
          subcuenta: filterValues?.securityValues?.subaccount,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          proveedorLineaCarga,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ProveedorLineaCarga, filterValues: any) {
    try {
      const proveedorLineaCarga = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues?.securityValues?.account,
        subcuenta: filterValues?.securityValues?.subaccount,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        proveedorLineaCarga,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: ProveedorLineaCarga, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        proveedorLineaCarga: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ProveedorLineaCarga[]> {
    if (!filter?.proveedorFilter) {
      toast.current?.show({
        severity: "warn",
        detail: empityFilter,
        life: 3000,
      });
      return [];
    }
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            proveedor: filter?.proveedorFilter,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<ProveedorLineaCarga> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          proveedorLineaCarga: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadProveedores(filter: any): Promise<ProveedorLineaCarga[]> {
    if (filter?.securityValues?.country) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/proveedor",
          {
            pais: filter.securityValues.country,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadProveedor(filter: any): Promise<ProveedorLineaCarga[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/proveedor2",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadCalle(filter: any): Promise<ProveedorLineaCarga[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/calles",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadColumna(filter: any): Promise<ProveedorLineaCarga[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount &&
      filter.calle !== ""
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/columnas",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            calle: filter.calle,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadNivel(filter: any): Promise<ProveedorLineaCarga[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount &&
      filter.calle !== "" &&
      filter.columna !== ""
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/niveles",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            calle: filter.calle,
            columna: filter.columna,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ProveedorLineaCarga>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
            subaccount: true,
          },
        }}
        filterSettings={[
          {
            filter: "proveedorFilter",
            label: LanguageProvider({ id: "2521", alt: "Proveedor" }),
            placeholder: LanguageProvider({ id: "2521", alt: "Proveedor" }),
            type: "dropdown",
            dataSource: loadProveedores,
            dropDownTemplate: DropdownOptionTemplateProveedores,
            required: true,
            virtualizeDropdown: true,
            dependency: ["securityValues.country"],
          },
        ]}
        dataTableColumns={[
          {
            field: "proveedor.descripcion",
            header: <LanguageProvider id="2521" alt="Proveedor" />,
          },
          {
            field: "calle",
            header: <LanguageProvider id="990" alt="x" />,
          },
          {
            field: "columna",
            header: <LanguageProvider id="991" alt="x" />,
          },
          {
            field: "nivel",
            header: <LanguageProvider id="992" alt="x" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          proveedor: null,
          cliente: null,
          cuenta: null,
          subcuenta: null,
          calle: "",
          columna: "",
          nivel: "",
        }}
        formSettings={[
          {
            field: "proveedor",
            label: LanguageProvider({ id: "2521", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "2521", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadProveedor(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate: true,
          },
          {
            field: "calle",
            label: LanguageProvider({ id: "990", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "990", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadCalle(filter),
            disableOnUpdate: true,
          },
          {
            field: "columna",
            label: LanguageProvider({ id: "991", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "991", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadColumna(filter),
            disableOnUpdate: true,
            dependency: ["calle"],
          },
          {
            field: "nivel",
            label: LanguageProvider({ id: "992", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "992", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadNivel(filter),
            disableOnUpdate: true,
            dependency: ["columna"],
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default MantenerProveedoresLineascarga;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionTemplateProveedores = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
