import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { TipoBloqueo } from "src/models/TipoBloqueo";

const MantenerTiposBloqueo = () => {
  const SCREEN_CODE = "abm0138_mantener_tipos_bloqueo";
  const URL_BASE = "/mantener-tipo-bloqueo";
  const SCREEN_TITLE = (
    <LanguageProvider id="11075" alt="Mantener Tipos de bloqueo" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: TipoBloqueo, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client &&
        filterValues?.securityValues?.account
      ) {
        const tipoBloqueo = {
          ...model,
          calle: model.separaEcom ? model.calle : "",
          columna: model.separaEcom ? model.columna : "",
          nivel: model.separaEcom ? model.nivel : "",
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues?.securityValues?.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          tipoBloqueo,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: TipoBloqueo, filterValues: any) {
    try {
      const tipoBloqueo = {
        ...model,
        calle: model.separaEcom ? model.calle : "",
        columna: model.separaEcom ? model.columna : "",
        nivel: model.separaEcom ? model.nivel : "",
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues?.securityValues?.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        tipoBloqueo,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: TipoBloqueo, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        tipoBloqueo: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<TipoBloqueo[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<TipoBloqueo> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          tipoBloqueo: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadResponsable(filter: any): Promise<TipoBloqueo[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/responsable",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadCliente(filter: any): Promise<TipoBloqueo[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/cliente",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadCalle(filter: any): Promise<TipoBloqueo[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/calle",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadColumna(filter: any): Promise<TipoBloqueo[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter.calle !== ""
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/columna",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            calle: filter.calle,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadNivel(filter: any): Promise<TipoBloqueo[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter.calle !== "" &&
      filter.columna !== ""
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/nivel",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            calle: filter.calle,
            columna: filter.columna,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<TipoBloqueo>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
          },
        }}
        // filterSettings={[]}
        dataTableColumns={[
          { field: "id", header: <LanguageProvider id="11072" alt="x" /> },
          {
            field: "descripcion",
            header: <LanguageProvider id="12419" alt="x" />,
          },
          {
            field: "responsable.descripcion",
            header: <LanguageProvider id="11097" alt="x" />,
          },

          { field: "calle", header: <LanguageProvider id="990" alt="x" /> },
          { field: "columna", header: <LanguageProvider id="991" alt="x" /> },
          { field: "nivel", header: <LanguageProvider id="992" alt="x" /> },
          {
            field: "separaEcom",
            header: <LanguageProvider id="15871" alt="x" />,
            body: (rowData: any) => {
              return rowData.separaEcom ? "SI" : "NO";
            },
          },

          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => {
              return (
                <span>
                  {new Date(
                    rowData.fechaUltimaModificacion.date
                  ).toLocaleString()}
                </span>
              );
            },
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => {
              return rowData.activo ? "SI" : "NO";
            },
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          cliente: null,
          cuenta: null,
          responsable: null,
          calle: "",
          columna: "",
          nivel: "",
          separaEcom: false,
        }}
        formSettings={[
          {
            field: "id",
            label: LanguageProvider({ id: "6590", alt: "x" }),
            type: "inputText",
            length: 5,
            disableOnUpdate: true,
            capitalizeInputText: true,
          },
          {
            field: "descripcion",
            label: LanguageProvider({ id: "12419", alt: "x" }),
            type: "inputText",
            length: 100,
          },
          {
            field: "responsable",
            label: LanguageProvider({ id: "11097", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "11097", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadResponsable(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "cliente",
            label: LanguageProvider({ id: "448", alt: "Cuenta" }),
            dropDownPlaceholder: LanguageProvider({ id: "448", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadCliente(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "separaEcom",
            label: LanguageProvider({ id: "15871", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
          {
            field: "calle",
            label: LanguageProvider({ id: "990", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "990", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadCalle(filter),
          },
          {
            field: "columna",
            label: LanguageProvider({ id: "991", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "991", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadColumna(filter),
            dependency: ["calle"],
          },
          {
            field: "nivel",
            label: LanguageProvider({ id: "992", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "992", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadNivel(filter),
            dependency: ["columna"],
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default MantenerTiposBloqueo;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
