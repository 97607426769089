import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { CuentaGasto } from "src/models/CuentaGasto";

const MantenerCuentasGastos = () => {
  const SCREEN_CODE = "abm0131_mantener_cuentas_gastos";
  const URL_BASE = "/mantener-cuentas-gastos";
  const SCREEN_TITLE = (
    <LanguageProvider id="10816" alt="Mantener Cuenta Gastos" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const empityFilter = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - 
    ${LanguageProvider({ id: "23888", alt: "Marca" })}`;

  async function handleSave(model: CuentaGasto, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const cuentaGasto = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          marca: filterValues?.marca,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          cuentaGasto,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: CuentaGasto, filterValues: any) {
    try {
      const cuentaGasto = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        marca: filterValues?.marca,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        cuentaGasto,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: CuentaGasto, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        cuentaGasto: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<CuentaGasto[]> {
    if (!filter?.marca) {
      toast.current?.show({
        severity: "warn",
        detail: empityFilter,
        life: 3000,
      });
      return [];
    }
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            marca: filter?.marca,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<CuentaGasto> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          cuentaGasto: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarca(filter: any): Promise<CuentaGasto[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/marca",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<CuentaGasto>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
          },
        }}
        filterSettings={[
          {
            filter: "marca",
            label: LanguageProvider({ id: "23888", alt: "Marca" }),
            placeholder: LanguageProvider({ id: "23888", alt: "Marca" }),
            type: "dropdown",
            dataSource: loadMarca,
            dropDownTemplate: DropdownOptionTemplate,
            dependency: ["securityValues.country", "securityValues.client"],
            required: true,
          },
        ]}
        dataTableColumns={[
          {
            field: "id",
            header: <LanguageProvider id="10772" alt="Cuenta Gastos" />,
          },
          {
            field: "descripcion",
            header: <LanguageProvider id="3756" alt="Descripción" />,
          },
          {
            field: "pais.descripcion",
            header: <LanguageProvider id="5044" alt="País" />,
          },
          {
            field: "cliente.descripcion",
            header: <LanguageProvider id="54" alt="Cliente" />,
          },
          {
            field: "marca.descripcion",
            header: <LanguageProvider id="396" alt="Marca" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          cliente: null,
          marca: null,
        }}
        formSettings={[
          {
            field: "id",
            label: LanguageProvider({ id: "10772", alt: "Cuenta Gastos" }),
            type: "inputText",
            length: 10,
            disableOnUpdate: true,
          },
          {
            field: "descripcion",
            label: LanguageProvider({ id: "3756", alt: "Descripción" }),
            type: "inputText",
            length: 30,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default MantenerCuentasGastos;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
