import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { GruposTaller } from "src/models/GruposTaller";

const MantenerGruposTaller = () => {
  const SCREEN_CODE = "abm0094_mantener_grupos_taller";
  const URL_BASE = "/mantener-grupos-taller";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: GruposTaller, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const grupoTaller = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          grupoTaller: grupoTaller,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: GruposTaller, filterValues: any) {
    try {
      const grupoTaller = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        grupoTaller: grupoTaller,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: GruposTaller) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        grupoTaller: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<GruposTaller[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<GruposTaller> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          grupoTaller: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <GenericAbm<GruposTaller>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="2093" alt="Mantener Grupos de Talleres" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      dataTableColumns={[
        {
          field: "id",
          header: <LanguageProvider id="6590" alt="Código" />,
        },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="Descripcion" />,
        },
        {
          field: "cliente.descripcion",
          header: <LanguageProvider id="54" alt="Cliente" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        descripcion: null,
        activo: true,
      }}
      loadDropdownData={{}}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          length: 5,
          required: true,
          capitalizeInputText: true,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripcion" />,
          type: "inputText",
          required: true,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerGruposTaller;
