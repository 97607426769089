import { Button } from "primereact/button";
import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { Pais } from "src/models/Pais";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerPaises = () => {
  const SCREEN_CODE = "abm0001_mantener_paises";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: Pais, filterValues: any) {
    try {
      const pais = { ...model };
      await sipcoAxiosService.post("/mantener-paises/save", pais);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: Pais) {
    try {
      await sipcoAxiosService.post("/mantener-paises/update", model);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Pais) {
    try {
      await sipcoAxiosService.post("/mantener-paises/remove", model);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Pais[]> {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantener-paises/findBy"
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<Pais> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-paises/findByPk",
        model
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }
  async function loadZonas(): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantener-paises/filter/zonas"
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadProvedores(): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantener-paises/filter/provedores"
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<Pais>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="146" alt="Mantener Paises" />}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: 0,
        codigoDiscado: null,
        codigoInternacional: null,
        descripcionCorta: null,
        dwh: null,
        generaPueFob: false,
        moneda: null,
        proveedor: null,
        sigla: null,
        wav: null,
        zona: null,
        activo: true,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Codigo" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="3756" alt="Descripcion" />,
        },
        {
          field: "zona.descripcion",
          header: <LanguageProvider id="5255" alt="Zona" />,
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.timestamp",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modifcicacion" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "generaPueFob",
          header: <LanguageProvider id="10200" alt="Genera Prest. PUE_FOB" />,
          body: (rowData: any) => {
            return rowData.generaPueFob ? "SI" : "NO";
          },
        },
        {
          field: "proveedor.descripcion",
          header: <LanguageProvider id="16269" alt="Prov. Transp. PUE_FOB" />,
        },
      ]}
      formSettings={[
        {
          field: "id",
          required: true,
          label: <LanguageProvider id="6590" alt="Codigo" />,
          type: "inputText",
          disabled: true,
        },
        {
          field: "descripcion",
          required: true,
          label: <LanguageProvider id="31" alt="Descripcion" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "sigla",
          label: <LanguageProvider id="6753" alt="Sigla" />,
          type: "inputText",
          length: 4,
        },
        {
          field: "descripcionCorta",
          label: <LanguageProvider id="9894" alt="Descripción Corta" />,
          type: "inputText",
          length: 3,
        },
        {
          field: "zona",
          label: <LanguageProvider id="5255" alt="Zona" />,
          type: "dropdown",
          dataSource: (filter: any) => loadZonas(),
          dropDownTemplate: DropdownOptionTemplate,
          dropDownPlaceholder: "Zona",
        },
        {
          field: "proveedor",
          label: <LanguageProvider id="16269" alt="Prov. Transp. PUE_FOB" />,
          type: "dropdown",
          dataSource: (filter: any) => loadProvedores(),
          dropDownTemplate: DropdownOptionTemplate,
          dropDownPlaceholder: "Provedor",
        },
        {
          field: "generaPueFob",
          label: <LanguageProvider id="10200" alt="Genera Prest. PUE_FOB" />,
          type: "checkbox",
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
    />
  );
};

export default MantenerPaises;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
