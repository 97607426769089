import React, { createContext, useContext, useState, ReactNode } from "react";

// Definição do tipo genérico
export interface GenericAbmContextType<T = any> {
  genericAbmStatesContextData: T;
  setGenericAbmStatesContextData: (value: T) => void;
}

// Criando o contexto com um valor padrão
const GenericAbmContext = createContext<GenericAbmContextType | undefined>(
  undefined
);

// Provedor do contexto
interface GenericAbmProviderProps<T> {
  children: ReactNode;
  initialData: T;
}

export function GenericAbmProvider<T>({
  children,
  initialData,
}: GenericAbmProviderProps<T>) {
  const [genericAbmStatesData, setGenericAbmStatesData] =
    useState<T>(initialData);

  return (
    <GenericAbmContext.Provider
      value={{
        genericAbmStatesContextData: genericAbmStatesData,
        setGenericAbmStatesContextData: setGenericAbmStatesData,
      }}
    >
      {children}
    </GenericAbmContext.Provider>
  );
}

// Hook para usar o contexto de forma segura
export function useGenericAbm<T>() {
  const context = useContext(GenericAbmContext);
  if (!context) {
    throw new Error(
      "useGenericAbm deve ser usado dentro de um GenericAbmProvider"
    );
  }
  return context as GenericAbmContextType<T>;
}
