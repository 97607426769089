import "./MantenerValesDeRepuestos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import useRowStyles from "../../../hooks/useRowStyles";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Panel } from "primereact/panel";
import useFormattedDate from "../../../hooks/useFormattedDate";
import MantenerValesDeRepuestosDialog from "./componentes/MantenerValesDeRepuestos-Dialog";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

export function MantenerValesDeRepuestos() {
  const SCREEN_CODE = "fun0081_mantener_vales_de_repuestos";
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef(null);
  const { values: securityFilters } = useFilterContext();
  ScreenCodeValue(SCREEN_CODE);
  const navigate = useNavigate();

  usePageViews();
  const dt = useRef(null);
  const [reporteData, setReporteData] = useState(null);
  const [selectedReportData, setSelectedReportData] = useState(null);

  const [dialogMode, setDialogMode] = useState<DialogMode | null>(null);
  const [displayFormDialog, setDisplayFormDialog] = useState(false);
  const [validFantanDatosDialog, setValidFantanDatosDialog] = useState(false);
  const [validselRegistroDialog, setValidselRegistroDialog] = useState(false);
  const [loadingReportData, setLoadingReportData] = useState(false);
  const [loadingBorrarRegDialog, setLoadingBorrarRegDialog] = useState(false);
  const [borrarRegistroDialog, setBorrarRegistroDialog] = useState(false);
  const [loadingCerrarValeDialog, setLoadingCerrarValeDialog] = useState(false);
  const [cerrarValeDialog, setCerrarValeDialog] = useState(false);
  const [sucessDialog, setSucessDialog] = useState(false);

  const rowStyles = useRowStyles([selectedReportData]);
  const { formatDate } = useFormattedDate();

  enum DialogMode {
    Alta = 0,
    Modificar = 1,
    Borrar = 2,
  }

  const btnAltaClick = () => {
    try {
      setDialogMode(DialogMode.Alta);
      if (filter.taller === null) {
        setValidFantanDatosDialog(true);
        return;
      }
      setDisplayFormDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  const btnModificarClick = () => {
    try {
      setDialogMode(DialogMode.Modificar);
      if (filter.taller === null) {
        setValidFantanDatosDialog(true);
        return;
      }
      if (selectedReportData === null) {
        setValidselRegistroDialog(true);
        return;
      }
      setDisplayFormDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  const btnBorrarClick = () => {
    try {
      setDialogMode(DialogMode.Borrar);
      if (filter.taller === null) {
        setValidFantanDatosDialog(true);
        return;
      }
      if (selectedReportData === null) {
        setValidselRegistroDialog(true);
        return;
      }
      setBorrarRegistroDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  const btnCerrarValeClick = () => {
    try {
      if (selectedReportData === null) {
        setValidselRegistroDialog(true);
        return;
      }
      setCerrarValeDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  // const btnRepuestosClick = () => {
  //   try {
  //     if (selectedReportData === null) {
  //       setValidselRegistroDialog(true);
  //       return;
  //     }

  //     localStorage.setItem(
  //       "selectedOrder",
  //       JSON.stringify(selectedReportData[0])
  //     );

  //     window.open("/detalle_de_vales_repuestos", "_blank");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const btnRepuestosClick = () => {
    try {
      if (selectedReportData === null) {
        setValidselRegistroDialog(true);
        return;
      }

      let pais = securityFilters.securityValues.country.descripcion;
      let cuenta = securityFilters.securityValues.account.descripcion;
      let taller = filter.taller.descripcion;
      let playa = securityFilters.securityValues.subaccount.descripcion;

      navigate("/detalle_de_vales_repuestos", {
        state: {
          secFilter: {
            pais,
            cuenta,
          },
          model: {
            taller,
            playa,
          },
          selectedData: {
            selectedReportData,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleBorrar = (isConfirmed) => {
    setBorrarRegistroDialog(false);
    if (isConfirmed) {
      borrar();
    }
  };

  const handleCerrarVale = (isConfirmed) => {
    setCerrarValeDialog(false);
    if (isConfirmed) {
      validaCerrarVale();
    }
  };

  async function borrar() {
    setLoadingBorrarRegDialog(true);
    try {
      const filters = {
        valeRepuesto: {
          pais: { id: securityFilters.securityValues.country.id },
          cuenta: { id: securityFilters.securityValues.account.id },
          subcuenta: { id: securityFilters.securityValues.subaccount.id },
          id: selectedReportData?.id,
          taller: { id: filter?.taller?.id },
          observaciones: selectedReportData?.observaciones,
          fechaVale: selectedReportData.fechaAlta,
        },
      };

      const { status } = await sipcoAxiosService.post(
        "/mantener-vales-repuestos/remove-vale-repuesto",
        filters
      );
      if (status === 200) {
        setSucessDialog(true);
        setSelectedReportData(null);
        loadReportData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBorrarRegDialog(false);
    }
  }

  async function validaCerrarVale() {
    setLoadingCerrarValeDialog(true);
    try {
      const filters = {
        valeRepuesto: {
          pais: { id: securityFilters.securityValues.country.id },
          cuenta: { id: securityFilters.securityValues.account.id },
          subcuenta: { id: securityFilters.securityValues.subaccount.id },
          id: selectedReportData?.id,
          taller: { id: filter?.taller?.id },
          observaciones: selectedReportData?.observaciones,
          fechaVale: selectedReportData.fechaAlta,
          ordenReparacion: { id: selectedReportData?.ordenReparacion?.id },
        },
      };

      const { status } = await sipcoAxiosService.post(
        "/mantener-vales-repuestos/validar-cierre-vale-repuesto",
        filters
      );
      if (status === 200) {
        setSucessDialog(true);
        setSelectedReportData(null);
        loadReportData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCerrarValeDialog(false);
    }
  }

  const handleConfirmationClose = () => {
    try {
      setValidFantanDatosDialog(false);
      setValidselRegistroDialog(false);
      setSucessDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [filter, setFilter] = useState({
    taller: null,
  });

  const [loadingTaller, setLoadingTaller] = useState(false);
  const [taller, setTaller] = useState([]);
  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/mantener-vales-repuestos/combo/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
            },
          }
        );
        if (status === 200) {
          const tallerData = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setTaller(tallerData);
          return tallerData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
    setTaller([]);
    return;
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const talleres = await loadTaller();

          setFilter((prevFilter) => ({
            ...prevFilter,
            taller: talleres?.length > 0 ? talleres[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  useEffect(() => {
    async function refreshReportData() {
      if (filter?.taller) {
        try {
          loadReportData();
        } catch (error) {
          console.error(error);
        }
      }
    }
    refreshReportData();
  }, [filter?.taller]);

  async function loadReportData() {
    setLoadingReportData(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-vales-repuestos/vale-repuesto",
        {
          pais: securityFilters.securityValues.country,
          cuenta: securityFilters.securityValues.account,
          subcuenta: securityFilters.securityValues.subaccount,
          taller: filter.taller,
        }
      );

      if (status === 200) {
        setReporteData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  return (
    <div className="mantener-vales-de-repuestos">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          account: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"327"} alt="Taller" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <SipcoDropdown
                  value={filter.taller}
                  options={taller}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      taller: e.value,
                    })
                  }
                  optionLabel="label"
                  loading={loadingTaller}
                  filter
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel header={<LanguageProvider id="6697" alt="Listado" />}>
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporteData}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReportData}
          filterDisplay="menu"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
          selectionMode="single"
          rowClassName={rowStyles}
        >
          <SipcoColumn
            field="id"
            header={<LanguageProvider id="12231" alt="Nro. de Vale" />}
            sortable
            filter
          />
          <SipcoColumn
            field="fechaVale.date"
            header={<LanguageProvider id="16800" alt="Fecha vale" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaVale?.date
                ? formatDate(rowData.fechaVale.date)
                : rowData.fechaVale || ""
            }
          />
          <SipcoColumn
            field="ordenReparacion.vin.id"
            header={<LanguageProvider id="1491" alt="VIN" />}
            sortable
            filter
          />
          <SipcoColumn
            field="ordenReparacion.vin.modeloLargo"
            header={<LanguageProvider id="2139" alt="Modelo" />}
            sortable
            filter
          />
          <SipcoColumn
            field="observaciones"
            header={<LanguageProvider id="3625" alt="Observaciones" />}
            sortable
            filter
          />
          <SipcoColumn
            field="ordenReparacion.id"
            header={<LanguageProvider id="16801" alt="Código orden" />}
            sortable
            filter
          />
        </SipcoDataTable>

        <div className="flex flex-wrap gap-2 mt-5">
          <Button
            label={LanguageProvider({ id: "23824", alt: "Alta" })}
            icon="pi pi-plus-circle"
            onClick={btnAltaClick}
          />
          <Button
            label={LanguageProvider({ id: "19267", alt: "Modificar" })}
            icon="pi pi-pencil"
            onClick={btnModificarClick}
          />
          <Button
            label={LanguageProvider({ id: "21880", alt: "Borrar" })}
            icon="pi pi-times"
            onClick={btnBorrarClick}
          />
          <Button
            label={LanguageProvider({ id: "2343", alt: "Cerrar Vale" })}
            icon="pi pi-power-off"
            onClick={btnCerrarValeClick}
          />
          <Button
            label={LanguageProvider({ id: "2248", alt: "Repuestos" })}
            icon="pi pi-book"
            onClick={btnRepuestosClick}
          />
        </div>
      </Panel>

      <div className="popups">
        <MantenerValesDeRepuestosDialog
          visible={displayFormDialog}
          mode={dialogMode}
          reporte={reporteData}
          selectedReporte={selectedReportData}
          model={filter.taller}
          refreshData={() => {
            loadReportData();
          }}
          onClose={() => {
            setDisplayFormDialog(false);
          }}
        />
      </div>

      <div className="dialogs">
        {/* Faltan seleccionar datos Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validFantanDatosDialog}
          style={{ width: "6vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider id="16994" alt="Faltan seleccionar datos" />
          </p>
        </Dialog>

        {/* Debe seleccionar al menos un registro Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validselRegistroDialog}
          style={{ width: "6vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="12642"
              alt="Debe seleccionar al menos un registro"
            />
          </p>
        </Dialog>

        {/* Elimina el o los registros Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={borrarRegistroDialog}
          style={{ width: "12vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("10043", "Si")}
                icon="pi pi-check"
                onClick={() => handleBorrar(true)}
                autoFocus
                disabled={loadingBorrarRegDialog}
              />
              <Button
                label={getLabel("10044", "No")}
                icon="pi pi-times"
                onClick={() => handleBorrar(false)}
                className="p-button-text"
                disabled={loadingBorrarRegDialog}
              />
            </div>
          }
          onHide={() => setBorrarRegistroDialog(false)}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider id="829" alt="¿Elimina el o los registros?" />
          </p>
          {loadingBorrarRegDialog && (
            <ProgressSpinner style={{ width: "50px", height: "50px" }} />
          )}
        </Dialog>

        {/* Sucess Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={sucessDialog}
          style={{ width: "5vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="36"
              alt="Su transacción se realizó con éxito."
            />
          </p>
        </Dialog>

        {/* Cerrar Vale? Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={cerrarValeDialog}
          style={{ width: "12vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("10043", "Si")}
                icon="pi pi-check"
                onClick={() => handleCerrarVale(true)}
                autoFocus
                disabled={loadingCerrarValeDialog}
              />
              <Button
                label={getLabel("10044", "No")}
                icon="pi pi-times"
                onClick={() => handleCerrarVale(false)}
                className="p-button-text"
                disabled={loadingCerrarValeDialog}
              />
            </div>
          }
          onHide={() => setCerrarValeDialog(false)}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider id="17361" alt="Cerrar Vale?" />
          </p>
          {loadingCerrarValeDialog && (
            <ProgressSpinner style={{ width: "50px", height: "50px" }} />
          )}
        </Dialog>
      </div>
    </div>
  );
}
export default MantenerValesDeRepuestos;

const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
