import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { MotivoBaja } from "src/models/MotivoBaja";

const MantenerMotivosBaja = () => {
  const SCREEN_CODE = "abm0191_mantener_motivos_baja";
  const URL_BASE = "/mantener-motivos-baja";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: MotivoBaja, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const motivoBaja = {
          ...model,
          pais: filterValues?.securityValues?.country,
          cliente: filterValues?.securityValues?.client,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          motivoBaja,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: MotivoBaja, filterValues: any) {
    try {
      const motivoBaja = {
        ...model,
        pais: filterValues?.securityValues?.country,
        cliente: filterValues?.securityValues?.client,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        motivoBaja,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: MotivoBaja, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        motivoBaja: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<MotivoBaja[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter.securityValues.country,
          cuenta: filter.securityValues.account,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<MotivoBaja> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          motivoBaja: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <GenericAbm<MotivoBaja>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          account: true,
        },
      }}
      dataTableColumns={[
        {
          field: "id",
          header: <LanguageProvider id="6590" alt="Código" />,
        },
        {
          field: "descripcion",
          header: <LanguageProvider id="23858" alt="Descripcion" />,
        },
  
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: null,
        activo: true,
        pais: null,
        cuenta: null,
        descripcion: "",
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          disabled: true,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="23858" alt="Descripcion" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerMotivosBaja;
