import React, { useRef, useState } from "react";
import GenericAbm, {
  GenericAbmRef,
} from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Dialog } from "primereact/dialog";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import "../functions/compras-taller/components/ModalVerComprasTaller.scss";
import { Toast } from "primereact/toast";
import { PrestacionOperacion } from "src/models/PrestacionOperacion";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { RadioButton } from "primereact/radiobutton";

const PrestacionesOperacion = () => {
  const SCREEN_CODE = "abm0182_prestaciones_operacion";
  const URL_BASE = "/mantener-prestaciones-operacion";
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function save(abm: any, model: PrestacionOperacion) {
    try {
      const prestacionOperacion = {
        ...model,
        pais: abm.current?.filter?.securityValues?.country,
        cuenta: abm.current?.filter?.securityValues?.account,
        operacion: model.operacion,
        prestacion: model.prestacion,
        subprestacion: model.subprestacion,
        compra: model.compra || false,
        proveedor: model.proveedor,
        vendeClienteActual: model.vendeClienteActual,
        vendeClienteOriginal: model.vendeClienteOriginal,
        sabado: model.sabado,
        domingo: model.domingo,
        feriado: model.feriado,
        utilizaHorario: model.utilizaHorario,
        activo: model.activo,
      };
      await sipcoAxiosService.post(URL_BASE + "/save", {
        prestacionOperacion,
      });
      setDisplayLocalAlta(false);
      toast.current?.show({
        severity: "success",
        detail: (
          <LanguageProvider
            id={"36"}
            alt="Sua transação se realizou com sucesso."
          />
        ),
        life: 3000,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function update(model: any, abm: any) {
    try {
      const modelBody = abmRef.current?.selectedEntries;
      if (!(modelBody.length >= 0)) {
        return false;
      }
      const prestacionOperacion = {
        ...model,
        pais: abm.current?.filter?.securityValues?.country,
        cliente: abm.current?.filter?.securityValues?.client,
        cuenta: abm.current?.filter?.securityValues?.account,
        operacion: model.operacion,
        prestacion: model.prestacion,
        subprestacion: model.subprestacion,
        compra: model.compra || false,
        proveedor: model.proveedor,
        vendeClienteActual: model.vendeClienteActual,
        vendeClienteOriginal: model.vendeClienteOriginal,
        sabado: model.sabado,
        domingo: model.domingo,
        feriado: model.feriado,
        utilizaHorario: model.utilizaHorario,
        activo: model.activo,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        prestacionOperacion,
      });

      setDisplayLocalModificar(false);
      toast.current?.show({
        severity: "success",
        detail: (
          <LanguageProvider
            id={"36"}
            alt="Sua transação se realizou com sucesso."
          />
        ),
        life: 3000,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function remove(abm: any) {
    try {
      const model = abm.current?.selectedEntries[0];
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        prestacionOperacion: { ...model },
      });
      toast.current?.show({
        severity: "success",
        detail: (
          <LanguageProvider
            id={"36"}
            alt="Sua transação se realizou com sucesso."
          />
        ),
        life: 3000,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handlerAlta(model: any) {
    try {
      setDisplayLocalAlta(true);
      const operacion = await loadFormOperacion(model);
      setFilterModel({
        ...filterModel,
        operacion,
      });
    } catch (error) {
      console.error(error);
    }
  }
  function handleModificar(abm: any) {
    try {
      setLoadingOperacion(true);
      const get = abm.current?.selectedEntries;
      if (!(get.length >= 0)) {
        return false;
      } else {
        const modelBody = get[0];

        console.log(modelBody?.operacion?.descripcion);

        setFilterModel({
          ...filterModel,
          activo: modelBody?.activo,
          operacion: modelBody?.operacion,
          prestacion: modelBody?.prestacion,
          subprestacion: modelBody?.subprestacion,
          sabado: modelBody?.sabado,
          domingo: modelBody?.domingo,
          feriado: modelBody?.feriado,
          utilizaHorario: modelBody?.utilizaHorario,
          vendeClienteActual: modelBody?.vendeClienteActual,
          vendeClienteOriginal: modelBody?.vendeClienteOriginal,
        });
        setDisplayLocalModificar(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOperacion(false);
    }
  }
  async function handleList(filter: any): Promise<PrestacionOperacion[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cuenta: filter.securityValues.account,
          prestaciones: filter?.operacion || null,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<PrestacionOperacion> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          perfilTraslado: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  const abmRef = useRef<GenericAbmRef<PrestacionOperacion>>(null);
  const [displayLocalAlta, setDisplayLocalAlta] = useState(false);
  const [displayLocalModificar, setDisplayLocalModificar] = useState(false);
  const [filterModel, setFilterModel] = useState<PrestacionOperacion>();

  const [loadingOperacion, setLoadingOperacion] = useState(false);
  const [operacionForm, setOperacionForm] = useState(null);

  async function loadGrupo(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/grupos-operacion",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadOperacion(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/operacion",
          {
            pais: filter?.securityValues?.country,
            grupoOperacion:
              filter?.grupoOperacion?.id === 0 ? null : filter?.grupoOperacion,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadFormOperacion(filter: any): Promise<any[]> {
    try {
      setLoadingOperacion(true);
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/form/operacion",
        {
          pais: filter?.current?.filter?.securityValues?.country,
        }
      );
      if (status === 200) {
        setOperacionForm(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOperacion(false);
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="top-right" />
      <GenericAbm<PrestacionOperacion>
        screenCode={SCREEN_CODE}
        ref={abmRef}
        title={<LanguageProvider id="6697" alt="Listado" />}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            account: true,
          },
        }}
        dataTableColumns={[
          {
            field: "operacion.id",
            header: <LanguageProvider id="16391" alt="x" />,
          },
          {
            field: "operacion.descripcion",
            header: <LanguageProvider id="7141" alt="x" />,
          },
          {
            field: "prestacion",
            header: <LanguageProvider id="4137" alt="x" />,
          },
          {
            field: "subprestacion",
            header: <LanguageProvider id="4587" alt="x" />,
          },
          {
            field: "compra",
            header: <LanguageProvider id="12851" alt="x" />,
            body: (rowData: any) => (rowData.compra ? "SI" : "NO"),
          },
          {
            field: "vendeClienteOriginal",
            header: <LanguageProvider id="12854" alt="x" />,
            body: (rowData: any) =>
              rowData.vendeClienteOriginal ? "SI" : "NO",
          },
          {
            field: "vendeClienteActual",
            header: <LanguageProvider id="12855" alt="x" />,
            body: (rowData: any) => (rowData.vendeClienteActual ? "SI" : "NO"),
          },
          {
            field: "sabado",
            header: <LanguageProvider id="10658" alt="x" />,
            body: (rowData: any) => (rowData.sabado ? "SI" : "NO"),
          },
          {
            field: "domingo",
            header: <LanguageProvider id="10659" alt="x" />,
            body: (rowData: any) => (rowData.domingo ? "SI" : "NO"),
          },
          {
            field: "feriado",
            header: <LanguageProvider id="818" alt="x" />,
            body: (rowData: any) => (rowData.feriado ? "SI" : "NO"),
          },
          {
            field: "utilizaHorario",
            header: <LanguageProvider id="18304" alt="x" />,
            body: (rowData: any) => (rowData.utilizaHorario ? "SI" : "NO"),
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          pais: null,
          activo: true,
          compra: false,
          cuenta: null,
          domingo: false,
          feriado: false,
          grupoOperacion: null,
          operacion: null,
          prestacion: "",
          proveedor: null,
          sabado: false,
          subprestacion: "",
          utilizaHorario: false,
          vendeClienteActual: false,
          vendeClienteOriginal: false,
          fechaUltimaModificacion: null,
          usuarioUltimaModificacion: null,
          pendente: false,
          pendentesChecked: false,
        }}
        formSettings={[
          {
            field: "id",
            label: LanguageProvider({ id: "6590", alt: "Código" }),
            type: "inputText",
            length: 6,
          },
        ]}
        filterSettings={[
          {
            filter: "grupoOperacion",
            label: LanguageProvider({ id: "1799", alt: "Grupo" }),
            placeholder: LanguageProvider({ id: "1799", alt: "Grupo" }),
            type: "dropdown",
            dataSource: loadGrupo,
            dropDownTemplate: DropdownOptionTemplate,
            dependency: ["securityValues.country"],
          },
          {
            filter: "operacion",
            label: LanguageProvider({ id: "2785", alt: "Operación" }),
            placeholder: LanguageProvider({ id: "2785", alt: "Operación" }),
            type: "multiselect",
            dataSource: loadOperacion,
            optionLabel: "label",
            dependency: ["securityValues.country", "grupoOperacion"],
          },
        ]}
        customActions={
          <>
            <Button
              label="Borrar"
              onClick={() => {
                remove(abmRef);
              }}
            />

            <Button
              label="Modificar"
              onClick={() => {
                handleModificar(abmRef);
              }}
            />
            <Button
              label="Alta"
              onClick={() => {
                handlerAlta(abmRef);
              }}
            />
          </>
        }
        list={handleList}
        get={handleGet}
      />
      <Dialog
        visible={displayLocalAlta}
        onHide={() => setDisplayLocalAlta(false)}
        className="modalComprasTallerVer"
      >
        <div className="column-form">
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"18637"} alt="Operación" />
                </label>
                <SipcoDropdown
                  loading={loadingOperacion}
                  className="input-modal"
                  value={filterModel?.operacion}
                  options={operacionForm}
                  optionLabel="descripcion"
                  onChange={(e) =>
                    setFilterModel({
                      ...filterModel,
                      operacion: e.target.value,
                    })
                  }
                  maxLength={6}
                />

                <label>
                  <LanguageProvider id={"4137"} alt="Prestación" />
                </label>
                <SipcoInputText
                  value={filterModel?.prestacion}
                  onChange={(e) =>
                    setFilterModel({
                      ...filterModel,
                      prestacion: e.target.value,
                    })
                  }
                  className="input-modal"
                  maxLength={9}
                />

                <label>
                  <LanguageProvider id={"4587"} alt="Subprestación" />
                </label>
                <SipcoInputText
                  value={filterModel?.subprestacion}
                  onChange={(e) =>
                    setFilterModel({
                      ...filterModel,
                      subprestacion: e.target.value,
                    })
                  }
                  className="input-modal"
                  maxLength={10}
                />

                {/* CheckBox´s  */}
                <div className="sipco-option">
                  <div className="form-row">
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider id={"10658"} alt="Sabado" />
                      </label>
                      <Checkbox
                        checked={filterModel?.sabado}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            sabado: e.target.checked,
                          })
                        }
                      />
                    </div>
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider id={"10659"} alt="Domingo" />
                      </label>
                      <Checkbox
                        checked={filterModel?.domingo}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            domingo: e.target.checked,
                          })
                        }
                      />
                    </div>
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider id={"818"} alt="Feriados" />
                      </label>
                      <Checkbox
                        checked={filterModel?.feriado}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            feriado: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-row" style={{ marginTop: "1em" }}>
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider
                          id={"18304"}
                          alt="Contempla horario"
                        />
                      </label>
                      <Checkbox
                        checked={filterModel?.utilizaHorario}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            utilizaHorario: e.target.checked,
                          })
                        }
                      />
                    </div>
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider id={"23849"} alt="Activo" />
                      </label>
                      <Checkbox
                        checked={filterModel?.activo}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            activo: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="sipco-option" style={{ marginTop: 10 }}>
                  <div className="label-style" style={{ height: 30 }}>
                    <label
                      style={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <LanguageProvider id={"4137"} alt="Prestación" />
                      <Checkbox
                        checked={filterModel?.pendente}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            pendente: e.target.checked,
                            vendeClienteOriginal: false,
                            vendeClienteActual: false,
                          })
                        }
                      />
                    </label>
                  </div>

                  <div className="sipco-option">
                    <div
                      className="form-row"
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <RadioButton
                        disabled={!filterModel?.pendente}
                        checked={filterModel?.vendeClienteOriginal}
                        value={filterModel?.vendeClienteOriginal}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            vendeClienteOriginal: e.target.checked,
                            vendeClienteActual: false,
                          })
                        }
                      />
                      <label>
                        <LanguageProvider
                          id={"12854"}
                          alt="Vende a Cliente Original"
                        />
                      </label>

                      <RadioButton
                        disabled={!filterModel?.pendente}
                        checked={filterModel?.vendeClienteActual}
                        value={filterModel?.vendeClienteActual}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            vendeClienteActual: e.target.checked,
                            vendeClienteOriginal: false,
                          })
                        }
                      />
                      <label>
                        <LanguageProvider
                          id={"12855"}
                          alt="Vende a Cliente Actual"
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="sipco-option" style={{ marginTop: "1em" }}>
                  <div className="form-row">
                    <Button
                      label="Confirmar"
                      onClick={(e: any) => save(abmRef, filterModel)}
                    />

                    <Button
                      label="Cancelar"
                      onClick={(e: any) => setDisplayLocalAlta(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={displayLocalModificar}
        onHide={() => setDisplayLocalModificar(false)}
        className="modalComprasTallerVer"
      >
        <div className="column-form">
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"18637"} alt="Operación" />
                </label>
                <SipcoInputText
                  value={filterModel?.operacion?.descripcion}
                  className="input-modal"
                  disabled
                />

                <label>
                  <LanguageProvider id={"4137"} alt="Prestación" />
                </label>
                <SipcoInputText
                  value={filterModel?.prestacion}
                  onChange={(e) =>
                    setFilterModel({
                      ...filterModel,
                      prestacion: e.target.value,
                    })
                  }
                  className="input-modal"
                  maxLength={9}
                  disabled
                />

                <label>
                  <LanguageProvider id={"4587"} alt="Subprestación" />
                </label>
                <SipcoInputText
                  value={filterModel?.subprestacion}
                  onChange={(e) =>
                    setFilterModel({
                      ...filterModel,
                      subprestacion: e.target.value,
                    })
                  }
                  className="input-modal"
                  maxLength={10}
                  disabled
                />

                {/* CheckBox´s  */}
                <div className="sipco-option">
                  <div className="form-row">
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider id={"10658"} alt="Sabado" />
                      </label>
                      <Checkbox
                        checked={filterModel?.sabado}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            sabado: e.target.checked,
                          })
                        }
                      />
                    </div>
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider id={"10659"} alt="Domingo" />
                      </label>
                      <Checkbox
                        checked={filterModel?.domingo}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            domingo: e.target.checked,
                          })
                        }
                      />
                    </div>
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider id={"818"} alt="Feriados" />
                      </label>
                      <Checkbox
                        checked={filterModel?.feriado}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            feriado: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-row" style={{ marginTop: "1em" }}>
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider
                          id={"18304"}
                          alt="Contempla horario"
                        />
                      </label>
                      <Checkbox
                        checked={filterModel?.utilizaHorario}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            utilizaHorario: e.target.checked,
                          })
                        }
                      />
                    </div>
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider id={"23849"} alt="Activo" />
                      </label>
                      <Checkbox
                        checked={filterModel?.activo}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            activo: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="sipco-option" style={{ marginTop: 10 }}>
                  <div className="label-style" style={{ height: 30 }}>
                    <label
                      style={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <LanguageProvider id={"4137"} alt="Prestación" />
                      <Checkbox
                        checked={filterModel?.pendente}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            pendente: e.target.checked,
                            vendeClienteOriginal: false,
                            vendeClienteActual: false,
                          })
                        }
                      />
                    </label>
                  </div>

                  <div className="sipco-option">
                    <div
                      className="form-row"
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <RadioButton
                        disabled={!filterModel?.pendente}
                        checked={filterModel?.vendeClienteOriginal}
                        value={filterModel?.vendeClienteOriginal}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            vendeClienteOriginal: e.target.checked,
                            vendeClienteActual: false,
                          })
                        }
                      />
                      <label>
                        <LanguageProvider
                          id={"12854"}
                          alt="Vende a Cliente Original"
                        />
                      </label>

                      <RadioButton
                        disabled={!filterModel?.pendente}
                        checked={filterModel?.vendeClienteActual}
                        value={filterModel?.vendeClienteActual}
                        onChange={(e) =>
                          setFilterModel({
                            ...filterModel,
                            vendeClienteActual: e.target.checked,
                            vendeClienteOriginal: false,
                          })
                        }
                      />
                      <label>
                        <LanguageProvider
                          id={"12855"}
                          alt="Vende a Cliente Actual"
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="sipco-option" style={{ marginTop: "1em" }}>
                  <div className="form-row">
                    <Button
                      label="Confirmar"
                      onClick={(e: any) => update(filterModel, abmRef)}
                    />

                    <Button
                      label="Cancelar"
                      onClick={(e: any) => setDisplayLocalAlta(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PrestacionesOperacion;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
