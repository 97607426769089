import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Panel } from "primereact/panel";
import useRowStyles from "../../../../hooks/useRowStyles";
import { Tag } from "primereact/tag";

interface ContabilizarViajesTercerosDialogProps {
  visible: boolean;
  model: any;
  mode: any;
  onClose: () => void;
}

const ContabilizarViajesTercerosDialog: React.FC<
  ContabilizarViajesTercerosDialogProps
> = ({ visible, model, mode, onClose }) => {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const dt = useRef(null);
  const [loadingReportDataC, setLoadingReportDataC] = useState(false);
  const [loadingReportDataV, setLoadingReportDataV] = useState(false);
  const [reporteCompra, setReporteCompra] = useState(null);
  const [reporteVenta, setReporteVenta] = useState(null);
  const [selectedReportDataC, setSelectedReportDataC] = useState(null);
  const [selectedReportDataV, setSelectedReportDataV] = useState(null);
  const [validFieldDialog, setValidFieldDialog] = useState(false);
  const [confirmProcessDialog, setConfirmProcessDialog] = useState(false);
  const [callCerrar, setCallCerrar] = useState(false);

  const rowStyles = useRowStyles([selectedReportDataC, selectedReportDataV]);
  const rowStylesBold = (rowData) => {
    return rowData.modificado ? "selected-row-bold" : "";
  };

  const handleSelectionChangeC = (e) => {
    try {
      setSelectedReportDataC(e.value);
      setSelectedReportDataV(null);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSelectionChangeV = (e) => {
    try {
      setSelectedReportDataV(e.value);
      setSelectedReportDataC(null);
    } catch (error) {
      console.error(error);
    }
  };

  const clearFieldsCompra = () => {
    try {
      setSelectedReportDataC(null);
      setModelCompra((prevState) => ({
        ...prevState,
        valorCompProvHr: "",
        valorCompProvReal: "",
      }));
    } catch (error) {
      console.error(error);
    }
  };
  const clearFieldsVenta = () => {
    try {
      setSelectedReportDataV(null);
      setModelVenta((prevState) => ({
        ...prevState,
        valorVentaVin: "",
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const reestabelecerRegCompra = () => {
    try {
      if (selectedReportDataC != null) {
        if (mode === 0) {
          //CompraVenta
          if (selectedReportDataC?.estadoContabilizacion?.id === "4") {
            const updatedReportData = { ...selectedReportDataC };
            updatedReportData.valorCompraProvHr = 0;
            updatedReportData.valorCompraProvReal = 0;
            updatedReportData.modificado = true;
            updatedReportData.servicio = null;

            const updatedReportCompra = reporteCompra.map((item) =>
              item.hojaRuta.id === updatedReportData.hojaRuta.id
                ? updatedReportData
                : item
            );
            setReporteCompra(updatedReportCompra);
          } else {
            const updatedReportData = { ...selectedReportDataC };

            updatedReportData.valorCompraProvHr =
              updatedReportData.valorCompraProvHrOriginal;
            updatedReportData.valorCompraProvReal =
              updatedReportData.valorCompraProvRealOriginal;
            updatedReportData.modificado = false;
            updatedReportData.servicio = null;

            const updatedReportCompra = reporteCompra.map((item) =>
              item.hojaRuta.id === updatedReportData.hojaRuta.id
                ? updatedReportData
                : item
            );
            setReporteCompra(updatedReportCompra);
          }
        } else if (mode === 1) {
          //Contabilizar
          if (selectedReportDataC != null) {
            const updatedReportData = { ...selectedReportDataC };
            updatedReportData.valorCompraProvHr =
              updatedReportData.valorCompraProvHrOriginal;
            updatedReportData.valorCompraProvReal =
              updatedReportData.valorCompraProvRealOriginal;
            updatedReportData.modificado = false;
            updatedReportData.servicio = null;

            const updatedReportCompra = reporteCompra.map((item) =>
              item.hojaRuta.id === updatedReportData.hojaRuta.id
                ? updatedReportData
                : item
            );
            setReporteCompra(updatedReportCompra);
          }
        } else if (mode === 2) {
          //Anular
          if (selectedReportDataC != null) {
            const updatedReportData = { ...selectedReportDataC };
            updatedReportData.modificado = false;

            const updatedReportCompra = reporteCompra.map((item) => {
              if (item.hojaRuta?.id === updatedReportData.hojaRuta?.id) {
                return updatedReportData;
              }
              return item;
            });

            setReporteCompra(updatedReportCompra);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const reestabelecerRegVenta = () => {
    try {
      if (selectedReportDataV != null) {
        if (mode === 0) {
          //CompraVenta
          const updatedReportData = { ...selectedReportDataV };
          if (updatedReportData?.estadoContabilizacion?.id === "3") {
            updatedReportData.valorVenta = 0;
            updatedReportData.modificado = true;
            updatedReportData.servicio = null;
          } else {
            updatedReportData.valorVenta = updatedReportData.valorVentaOriginal;
            updatedReportData.modificado = false;
            updatedReportData.servicio = null;
          }

          const updatedReportVenta = reporteVenta.map((item) => {
            if (item.hojaRuta?.id === updatedReportData.hojaRuta?.id) {
              return updatedReportData;
            }
            return item;
          });
          setReporteVenta(updatedReportVenta);
        } else if (mode === 1) {
          //Contabilizar
          if (selectedReportDataV != null) {
            const updatedReportData = { ...selectedReportDataV };
            updatedReportData.valorVenta = updatedReportData.valorVentaOriginal;
            updatedReportData.modificado = false;
            updatedReportData.servicio = null;

            const updatedReportVenta = reporteVenta.map((item) => {
              if (item.hojaRuta?.id === updatedReportData.hojaRuta?.id) {
                return updatedReportData;
              }
              return item;
            });

            setReporteVenta(updatedReportVenta);
          }
        } else if (mode === 2) {
          //Anular
          if (selectedReportDataV != null) {
            const updatedReportData = { ...selectedReportDataV };
            updatedReportData.modificado = false;

            const updatedReportVenta = reporteVenta.map((item) => {
              if (item.hojaRuta?.id === updatedReportData.hojaRuta?.id) {
                return updatedReportData;
              }
              return item;
            });

            setReporteVenta(updatedReportVenta);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const contabilizarCompra = () => {
    try {
      if (selectedReportDataC != null) {
        if (selectedReportDataC?.estadoContabilizacion?.id === "4") {
          const updatedReportData = { ...selectedReportDataC };
          updatedReportData.modificado = true;

          const updatedReportCompra = reporteCompra.map((item) =>
            item.hojaRuta.id === updatedReportData.hojaRuta.id
              ? updatedReportData
              : item
          );
          setReporteCompra(updatedReportCompra);
        }
      } else {
        setValidFieldDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const contabilizarVenta = () => {
    try {
      if (selectedReportDataV != null) {
        const updatedReportData = { ...selectedReportDataV };
        if (updatedReportData?.estadoContabilizacion?.id === "3") {
          updatedReportData.modificado = true;
        }

        const updatedReportVenta = reporteVenta.map((item) => {
          if (item.hojaRuta?.id === updatedReportData.hojaRuta?.id) {
            return updatedReportData;
          }
          return item;
        });

        setReporteVenta(updatedReportVenta);
      } else {
        setValidFieldDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const anularCompra = () => {
    try {
      if (selectedReportDataC != null) {
        if (selectedReportDataC?.estadoContabilizacion?.id === "3") {
          const updatedReportData = { ...selectedReportDataC };
          updatedReportData.modificado = true;

          const updatedReportCompra = reporteCompra.map((item) =>
            item.hojaRuta.id === updatedReportData.hojaRuta.id
              ? updatedReportData
              : item
          );
          setReporteCompra(updatedReportCompra);
        }
      } else {
        setValidFieldDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const anularVenta = () => {
    try {
      if (selectedReportDataV != null) {
        const updatedReportData = { ...selectedReportDataV };
        if (updatedReportData?.estadoContabilizacion?.id === "2") {
          updatedReportData.modificado = true;
        }

        const updatedReportVenta = reporteVenta.map((item) => {
          if (item.hojaRuta?.id === updatedReportData.hojaRuta?.id) {
            return updatedReportData;
          }
          return item;
        });

        setReporteVenta(updatedReportVenta);
      } else {
        setValidFieldDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const btnAceptarClick = () => {
    try {
      if (!selectedReportDataC && !selectedReportDataV) {
        setValidFieldDialog(true);
      } else {
        //CompraVenta
        if (mode === 0) {
          validaProcesarCompraVenta();
          //Contabilizar
        } else if (mode === 1) {
          validaProcesarContabilizar();
          //Anular
        } else if (mode === 2) {
          validaProcesarAnular();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function validaProcesarCompraVenta() {
    try {
      const filters = {
        listDtoCompra: reporteCompra.map((item) => ({ ...item })),
        listDtoVenta: reporteVenta.map((item) => ({ ...item })),
      };

      const { status } = await sipcoAxiosService.post(
        "/contabilizar-viajes-terceros/validate-cargar-compra-venta",
        filters
      );

      if (status === 200) {
        procesarCompraVenta();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function procesarCompraVenta() {
    try {
      const filters = {
        listDtoCompra: reporteCompra.map((item) => ({ ...item })),
        listDtoVenta: reporteVenta.map((item) => ({ ...item })),
      };

      const { status } = await sipcoAxiosService.post(
        "/contabilizar-viajes-terceros/cargar-compra-venta",
        filters
      );

      if (status === 200) {
        setConfirmProcessDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function validaProcesarContabilizar() {
    try {
      const filters = {
        listDtoCompra: reporteCompra.map((item) => ({ ...item })),
        listDtoVenta: reporteVenta.map((item) => ({ ...item })),
      };

      const { status } = await sipcoAxiosService.post(
        "/contabilizar-viajes-terceros/validate-contabilizar",
        filters
      );

      if (status === 200) {
        procesarContabilizar();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function procesarContabilizar() {
    try {
      const filters = {
        listDtoCompra: reporteCompra.map((item) => ({ ...item })),
        listDtoVenta: reporteVenta.map((item) => ({ ...item })),
      };

      const { status } = await sipcoAxiosService.post(
        "/contabilizar-viajes-terceros/contabilizar",
        filters
      );

      if (status === 200) {
        setConfirmProcessDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function validaProcesarAnular() {
    try {
      const filters = {
        listDtoCompra: reporteCompra.map((item) => ({ ...item })),
        listDtoVenta: reporteVenta.map((item) => ({ ...item })),
      };

      const { status } = await sipcoAxiosService.post(
        "/contabilizar-viajes-terceros/validate-anular-contabilizar",
        filters
      );

      if (status === 200) {
        procesarAnular();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function procesarAnular() {
    try {
      const filters = {
        listDtoCompra: reporteCompra.map((item) => ({ ...item })),
        listDtoVenta: reporteVenta.map((item) => ({ ...item })),
      };

      const { status } = await sipcoAxiosService.post(
        "/contabilizar-viajes-terceros/anular-contabilizar",
        filters
      );

      if (status === 200) {
        setConfirmProcessDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleClose = () => {
    try {
      setCallCerrar(true);
      cerrarClearFields();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const cerrarClearFields = async () => {
    if (model) {
      try {
        loadReportDataCompra();
        loadReportDataVenta();
        clearFieldsCompra();
        clearFieldsVenta();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleConfirmationClose = () => {
    try {
      setValidFieldDialog(false);
      setConfirmProcessDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [modelContabilizacion, setmodelContabilizacion] = useState({
    ofertaServicio: "",
    cliente: "",
  });

  const [modelCompra, setModelCompra] = useState({
    servicio: null,
    moneda: null,
    valorCompProvHr: "",
    valorCompProvReal: "",
    distribuirUnifor: false,
  });

  const [modelVenta, setModelVenta] = useState({
    servicio: null,
    moneda: null,
    valorVentaVin: "",
    distribuirUnifor: false,
  });

  const [loadingCompraServicio, setLoadingCompraServicio] = useState(false);
  const [compraServicio, setCompraServicio] = useState([]);
  async function loadCompraServicio() {
    setLoadingCompraServicio(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/contabilizar-viajes-terceros/servicio-extra",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
          }
        );

        if (status === 200) {
          const compraServicioData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setCompraServicio(compraServicioData);
          return compraServicioData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCompraServicio(false);
    }
    setCompraServicio([]);
    return [];
  }

  const [loadingCompraMoneda, setLoadingCompraMoneda] = useState(false);
  const [compraMoneda, setCompraMoneda] = useState([]);
  async function loadCompraMoneda() {
    setLoadingCompraMoneda(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/contabilizar-viajes-terceros/moneda",
          {}
        );

        if (status === 200) {
          const compraMonedaData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setCompraMoneda(compraMonedaData);
          return compraMonedaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCompraMoneda(false);
    }
    setCompraMoneda([]);
    return [];
  }

  const [loadingVentaServicio, setLoadingVentaServicio] = useState(false);
  const [ventaServicio, setVentaServicio] = useState([]);
  async function loadVentaServicio() {
    setLoadingVentaServicio(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/contabilizar-viajes-terceros/servicio-extra",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
          }
        );

        if (status === 200) {
          const ventaServicioData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setVentaServicio(ventaServicioData);
          return ventaServicioData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVentaServicio(false);
    }
    setVentaServicio([]);
    return [];
  }

  const [loadingVentaMoneda, setLoadingVentaMoneda] = useState(false);
  const [ventaMoneda, setVentaMoneda] = useState([]);
  async function loadVentaMoneda() {
    setLoadingVentaMoneda(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/contabilizar-viajes-terceros/moneda",
          {}
        );

        if (status === 200) {
          const ventaMonedaData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setVentaMoneda(ventaMonedaData);
          return ventaMonedaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVentaMoneda(false);
    }
    setVentaMoneda([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (model?.caratula?.id) {
        try {
          loadReportDataCompra();
          loadReportDataVenta();

          const serviciosC = await loadCompraServicio();
          const monedasC = await loadCompraMoneda();
          const serviciosV = await loadVentaServicio();
          const monedasV = await loadVentaMoneda();

          setModelCompra((prevModelC) => ({
            ...prevModelC,
            servicio: serviciosC?.length > 0 ? serviciosC[0] : null,
            moneda: monedasC?.length > 0 ? monedasC[0] : null,
          }));

          setModelVenta((prevModelV) => ({
            ...prevModelV,
            servicio: serviciosV?.length > 0 ? serviciosV[0] : null,
            moneda: monedasV?.length > 0 ? monedasV[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [model?.caratula]);

  async function loadReportDataCompra() {
    try {
      setLoadingReportDataC(true);
      const { status, data } = await sipcoAxiosService.post(
        "/contabilizar-viajes-terceros/contabilizacion",
        {
          pais: { id: securityFilters.securityValues.country.id },
          cuenta: { id: securityFilters.securityValues.account.id },
          caratula: { id: model?.caratula?.id || "" },
        }
      );

      if (status === 200) {
        setReporteCompra(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportDataC(false);
    }
  }

  async function loadReportDataVenta() {
    try {
      setLoadingReportDataV(true);
      const { status, data } = await sipcoAxiosService.post(
        "/contabilizar-viajes-terceros/detalle-v",
        {
          pais: { id: securityFilters.securityValues.country.id },
          cuenta: { id: securityFilters.securityValues.account.id },
          caratula: { id: model?.caratula?.id || "" },
        }
      );

      if (status === 200) {
        setReporteVenta(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportDataV(false);
    }
  }

  return (
    <Dialog
      className="contabilizar-viajes-terceros-dialog"
      visible={visible}
      onHide={onClose}
      style={{ width: "80vw" }}
      footer={() => {
        return (
          <div className="dialog-footer">
            <Button
              label={LanguageProvider({ id: "23827", alt: "Aceptar" })}
              icon="pi pi-check"
              onClick={btnAceptarClick}
              autoFocus
            />
            <Button
              label={LanguageProvider({ id: "16582", alt: "Cerrar" })}
              icon="pi pi-times"
              className="p-button-text"
              onClick={handleClose}
            />
          </div>
        );
      }}
    >
      <Panel header={<LanguageProvider id="23100" alt="Contabilización" />}>
        <div className="form">
          <div className="form-row-center">
            <div className="sipco-option">
              <label>
                <LanguageProvider id="16641" alt="Oferta de Servicios" />
              </label>
              <SipcoInputText
                value={model?.caratula?.id || ""}
                onChange={(e) =>
                  setmodelContabilizacion({
                    ...modelContabilizacion,
                    ofertaServicio: e.target.value,
                  })
                }
                readOnly={true}
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id="54" alt="Cliente" />
              </label>
              <SipcoInputText
                value={model?.cliente?.descripcion || ""}
                onChange={(e) =>
                  setmodelContabilizacion({
                    ...modelContabilizacion,
                    cliente: e.target.value,
                  })
                }
                readOnly={true}
              />
            </div>
            {mode === 0 || mode === 1 ? (
              <>
                <Tag
                  value={
                    <LanguageProvider
                      id="23147"
                      alt="Registros a generar prestación contable"
                    />
                  }
                  className="tag-registros-generar-prest-contable"
                />
              </>
            ) : mode === 2 ? (
              <>
                <Tag
                  value={
                    <LanguageProvider
                      id="23197"
                      alt="Registros a generar prestación contable"
                    />
                  }
                  className="tag-registros-por-anular"
                />
              </>
            ) : null}
          </div>
        </div>

        <Panel
          className="panel-compra"
          header={<LanguageProvider id="1849" alt="Compra" />}
        >
          <SipcoDataTable
            className="dt-compra"
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={reporteCompra}
            paginator
            scrollHeight="flex"
            rows={16}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            resizableColumns
            removableSort
            rowClassName={(rowData) =>
              `${rowStyles(rowData)} ${rowStylesBold(rowData)}`
            }
            filterDisplay="menu"
            loading={loadingReportDataC}
            selectionMode="single"
            selection={selectedReportDataC}
            onSelectionChange={handleSelectionChangeC}
          >
            <SipcoColumn
              field="hojaRuta.id"
              header={<LanguageProvider id="2940" alt="Nro. Hoja de Ruta" />}
              sortable
              filter
            />
            <SipcoColumn
              field="vin.id"
              header={<LanguageProvider id="1491" alt="VIN" />}
              sortable
              filter
            />
            <SipcoColumn
              field="origenDestino"
              header={<LanguageProvider id="4860" alt="Orígenes/Destinos" />}
              sortable
              filter
            />
            <SipcoColumn
              field="proveedor.descripcion"
              header={<LanguageProvider id="24038" alt="Proveedor" />}
              sortable
              filter
            />
            <SipcoColumn
              field="servicio.descripcion"
              header={<LanguageProvider id="9912" alt="Servicio" />}
              sortable
              filter
            />
            <SipcoColumn
              field="servicio.codPre"
              header={<LanguageProvider id="22641" alt="Prestación" />}
              sortable
              filter
            />
            <SipcoColumn
              field="valorCompraProvHr"
              header={
                <LanguageProvider id="23101" alt="Valor Compra Prov HR" />
              }
              sortable
              filter
              body={(rowData) =>
                new Intl.NumberFormat("pt-BR", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  useGrouping: true,
                }).format(Number(rowData.valorCompraProvHr))
              }
            />
            <SipcoColumn
              field="valorCompraProvReal"
              header={
                <LanguageProvider id="23124" alt="Valor Compra Prov Real" />
              }
              sortable
              filter
              body={(rowData) =>
                new Intl.NumberFormat("pt-BR", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  useGrouping: true,
                }).format(Number(rowData.valorCompraProvReal))
              }
            />
            <SipcoColumn
              field="estadoContabilizacion.descripcion"
              header={
                <LanguageProvider id="23125" alt="Estado Contabilización" />
              }
              sortable
              filter
            />
          </SipcoDataTable>
          <div className="form">
            <div className="form-row">
              {mode === 0 ? ( // CompraVenta
                <>
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id="9912" alt="Servicio" />
                    </label>
                    <SipcoDropdown
                      value={modelCompra.servicio}
                      options={compraServicio}
                      onChange={(e) =>
                        setModelCompra({ ...modelCompra, servicio: e.value })
                      }
                      optionLabel="label"
                      loading={loadingCompraServicio}
                      filter
                    />
                  </div>
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id="1545" alt="Moneda" />
                    </label>
                    <SipcoDropdown
                      value={modelCompra.moneda}
                      options={compraMoneda}
                      onChange={(e) =>
                        setModelCompra({ ...modelCompra, moneda: e.value })
                      }
                      optionLabel="label"
                      loading={loadingCompraMoneda}
                      filter
                    />
                  </div>
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id="23101" alt="Valor Compra Prov HR" />
                    </label>
                    <SipcoInputText
                      value={modelCompra.valorCompProvHr}
                      onChange={(e) => {
                        const onlyNumbers = e.target.value.replace(/\D/g, "");
                        setModelCompra({
                          ...modelCompra,
                          valorCompProvHr: onlyNumbers,
                        });
                      }}
                      maxLength={10}
                    />
                  </div>
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider
                        id="23124"
                        alt="Valor Compra Prov Real"
                      />
                    </label>
                    <SipcoInputText
                      value={modelCompra.valorCompProvReal}
                      onChange={(e) => {
                        const onlyNumbers = e.target.value.replace(/\D/g, "");
                        setModelCompra({
                          ...modelCompra,
                          valorCompProvReal: onlyNumbers,
                        });
                      }}
                      maxLength={10}
                    />
                  </div>
                  <div className="sipco-option-check">
                    <label>
                      <LanguageProvider
                        id="23102"
                        alt="Distribuir uniformemente"
                      />
                    </label>
                    <Checkbox
                      checked={modelCompra.distribuirUnifor}
                      onChange={(e) => {
                        setModelCompra({
                          ...modelCompra,
                          distribuirUnifor: !modelCompra.distribuirUnifor,
                        });
                      }}
                    />
                  </div>
                  <div className="sipco-option-btn">
                    <Button
                      label={LanguageProvider({
                        id: "16872",
                        alt: "Subir",
                      })}
                      icon="pi pi-upload"
                      onClick={clearFieldsCompra}
                    />
                  </div>
                  <div className="sipco-option-btn">
                    <Button
                      label={LanguageProvider({
                        id: "23148",
                        alt: "Restablecer registro/s",
                      })}
                      icon="pi pi-refresh"
                      onClick={reestabelecerRegCompra}
                    />
                  </div>
                </>
              ) : // Contabilizar
              mode === 1 ? (
                <>
                  <div className="sipco-option-btn">
                    <Button
                      label={LanguageProvider({
                        id: "7206",
                        alt: "Contabilizar",
                      })}
                      icon="pi pi-calculator"
                      onClick={contabilizarCompra}
                    />
                  </div>
                  <div className="sipco-option-btn">
                    <Button
                      label={LanguageProvider({
                        id: "23148",
                        alt: "Restablecer registro/s",
                      })}
                      icon="pi pi-refresh"
                      onClick={reestabelecerRegCompra}
                    />
                  </div>
                </>
              ) : // Anular
              mode === 2 ? (
                <>
                  <div className="sipco-option-btn">
                    <Button
                      label={LanguageProvider({
                        id: "2784",
                        alt: "Anular",
                      })}
                      icon="pi pi-ban"
                      onClick={anularCompra}
                    />
                  </div>
                  <div className="sipco-option-btn">
                    <Button
                      label={LanguageProvider({
                        id: "23148",
                        alt: "Restablecer registro/s",
                      })}
                      icon="pi pi-refresh"
                      onClick={reestabelecerRegCompra}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </Panel>

        <Panel
          className="panel-venta"
          header={<LanguageProvider id="1850" alt="Venta" />}
        >
          <SipcoDataTable
            className="dt-venta"
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={reporteVenta}
            paginator
            scrollHeight="flex"
            rows={16}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            resizableColumns
            removableSort
            rowClassName={(rowData) =>
              `${rowStyles(rowData)} ${rowStylesBold(rowData)}`
            }
            filterDisplay="menu"
            loading={loadingReportDataV}
            selectionMode="single"
            selection={selectedReportDataV}
            onSelectionChange={handleSelectionChangeV}
          >
            <SipcoColumn
              field="vin.id"
              header={<LanguageProvider id="1491" alt="VIN" />}
              sortable
              filter
            />
            <SipcoColumn
              field="origenDestino"
              header={<LanguageProvider id="4860" alt="Orígenes/Destinos" />}
              sortable
              filter
            />
            <SipcoColumn
              field="servicio.descripcion"
              header={<LanguageProvider id="9912" alt="Servicio" />}
              sortable
              filter
            />
            <SipcoColumn
              field="servicio.codPre"
              header={<LanguageProvider id="22641" alt="Prestación" />}
              sortable
              filter
            />
            <SipcoColumn
              field="valorVenta"
              header={<LanguageProvider id="5490" alt="Valor Venta" />}
              sortable
              filter
              body={(rowData) =>
                new Intl.NumberFormat("pt-BR", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  useGrouping: true,
                }).format(Number(rowData.valorVenta))
              }
            />
            <SipcoColumn
              field="estadoContabilizacion.descripcion"
              header={
                <LanguageProvider id="23125" alt="Estado Contabilización" />
              }
              sortable
              filter
            />
          </SipcoDataTable>
          <div className="form">
            <div className="form-row">
              {mode === 0 ? ( // CompraVenta
                <>
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id="9912" alt="Servicio" />
                    </label>
                    <SipcoDropdown
                      value={modelVenta.servicio}
                      options={ventaServicio}
                      onChange={(e) =>
                        setModelVenta({ ...modelVenta, servicio: e.value })
                      }
                      optionLabel="label"
                      loading={loadingVentaServicio}
                      filter
                    />
                  </div>
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id="1545" alt="Moneda" />
                    </label>
                    <SipcoDropdown
                      value={modelVenta.moneda}
                      options={ventaMoneda}
                      onChange={(e) =>
                        setModelVenta({ ...modelVenta, moneda: e.value })
                      }
                      optionLabel="label"
                      loading={loadingVentaMoneda}
                      filter
                    />
                  </div>
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id="23103" alt="Valor venta x Vin" />
                    </label>
                    <SipcoInputText
                      value={modelVenta.valorVentaVin}
                      onChange={(e) => {
                        const onlyNumbers = e.target.value.replace(/\D/g, "");
                        setModelVenta({
                          ...modelVenta,
                          valorVentaVin: onlyNumbers,
                        });
                      }}
                      maxLength={10}
                    />
                  </div>
                  <div className="sipco-option-check">
                    <label>
                      <LanguageProvider
                        id="23102"
                        alt="Distribuir uniformemente"
                      />
                    </label>
                    <Checkbox
                      checked={modelVenta.distribuirUnifor}
                      onChange={(e) => {
                        setModelVenta({
                          ...modelVenta,
                          distribuirUnifor: !modelVenta.distribuirUnifor,
                        });
                      }}
                    />
                  </div>
                  <div className="sipco-option-btn">
                    <Button
                      label={LanguageProvider({
                        id: "16872",
                        alt: "Subir",
                      })}
                      icon="pi pi-upload"
                      onClick={clearFieldsVenta}
                    />
                  </div>
                  <div className="sipco-option-btn">
                    <Button
                      label={LanguageProvider({
                        id: "23148",
                        alt: "Restablecer registro/s",
                      })}
                      icon="pi pi-refresh"
                      onClick={reestabelecerRegVenta}
                    />
                  </div>
                </>
              ) : // Contabilizar
              mode === 1 ? (
                <>
                  <div className="sipco-option-btn">
                    <Button
                      label={LanguageProvider({
                        id: "7206",
                        alt: "Contabilizar",
                      })}
                      icon="pi pi-calculator"
                      onClick={contabilizarVenta}
                    />
                  </div>
                  <div className="sipco-option-btn">
                    <Button
                      label={LanguageProvider({
                        id: "23148",
                        alt: "Restablecer registro/s",
                      })}
                      icon="pi pi-refresh"
                      onClick={reestabelecerRegVenta}
                    />
                  </div>
                </>
              ) : // Anular
              mode === 2 ? (
                <>
                  <div className="sipco-option-btn">
                    <Button
                      label={LanguageProvider({
                        id: "2784",
                        alt: "Anular",
                      })}
                      icon="pi pi-ban"
                      onClick={anularVenta}
                    />
                  </div>
                  <div className="sipco-option-btn">
                    <Button
                      label={LanguageProvider({
                        id: "23148",
                        alt: "Restablecer registro/s",
                      })}
                      icon="pi pi-refresh"
                      onClick={reestabelecerRegVenta}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </Panel>
      </Panel>

      <div className="dialogs">
        {/* No hay registros en la grilla para procesar Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validFieldDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="2502"
              alt="No hay registros en la grilla para procesar."
            />
          </p>
        </Dialog>

        {/* Confirm Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={confirmProcessDialog}
          style={{ width: "5vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="36"
              alt="Su transacción se realizó con éxito."
            />
          </p>
        </Dialog>
      </div>
    </Dialog>
  );
};
export default ContabilizarViajesTercerosDialog;

const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
