import "./MantenerCatalogoServicios.scss";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import React, { useRef, useState } from "react";

import { Button } from "primereact/button";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { HttpStatusCode } from "axios";
import { LanguageProvider } from "@shared/components/language-provider";
import ModalAltaMantenerCatalogoServicios from "./modals/ModalAltaMantenerCatalogoServicios";
import ModalModificarMantenerCatalogoServicios from "./modals/ModalModificarMantenerCatalogoServicios";
import ModalVerMantenerCatalogoServicios from "./modals/ModalVerMantenerCatalogoServicios";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../protected-routes";
import { useNavigate } from "react-router-dom";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function MantenerCatalogoServicios() {
  const SCREEN_CODE = "abm0105_mantener_catalogo_servicios";
  ScreenCodeValue(SCREEN_CODE);
  let navigate = useNavigate();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [alta, setAlta] = useState(false);
  const [modificar, setModificar] = useState(false);
  const [ver, setVer] = useState(false);

  const showModalVer = () => {
    if (selectedRows.length === 0) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: (
          <LanguageProvider
            id="753"
            alt="Debe seleccionar al menos un registro de la Grilla."
          />
        ),
        life: 3000,
      });
    } else {
      setVer(true);
    }
  };

  const hideModalVer = () => {
    setVer(false);
  };

  const showModalModificar = () => {
    if (selectedRows.length === 0) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: (
          <LanguageProvider
            id="829"
            alt="Debe seleccionar al menos un registro"
          />
        ),
        life: 3000,
      });
    } else {
      setModificar(true);
    }
  };

  const hideModalModificar = () => {
    setModificar(false);
  };

  const showModalAlta = () => {
    if (selectedRows.length === 0) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: (
          <LanguageProvider
            id="829"
            alt="Debe seleccionar al menos un registro"
          />
        ),
        life: 3000,
      });
    } else {
      setAlta(true);
    }
  };

  const hideModalAlta = () => {
    setAlta(false);
  };

  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  function barrar() {
    return confirmDialog({
      header: <LanguageProvider id={"16239"} alt="Atención" />,
      message: (
        <LanguageProvider id={"829"} alt="¿Elimina el o los registros?" />
      ),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => remove(),
      reject: () => console.log("aceptar"),
    });
  }

  const data = convertDateObjects(reporte?.filas);

  const [mantenerCatalogoServicios, setMantenerCatalogoServicios] = useState(
    []
  );

  async function loadReport() {
    try {
      if (
        securityFilters?.securityValues?.country !== null ||
        securityFilters?.securityValues?.client !== null
      ) {
        setLoading(true);
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-catalogo-servicios/find",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
          }
        );
        if (status === HttpStatusCode.Ok) {
          const response = data.map((x: any) => {
            return {
              ...x,
              codigo: x?.id,
              color: x?.descripcion,
              standard: x?.horasStandard,
              fechaCotizacion: x?.fechaCotizacion?.date,
              cuentaTaller: x?.cuentaTaller?.descripcion || "SERVICIOS",
              grupoTaller: x?.grupoTaller?.id,
              modeloTaller: x?.modeloTaller?.id,
              compra: x?.compra === false ? "No" : "Si",
              venta: x?.venta === false ? "No" : "Si",
              ultimaModificacion: x?.usuarioUltimaModificacion?.id,
              fechaUltimaModificacion: x?.fechaUltimaModificacion?.date,
            };
          });

          setMantenerCatalogoServicios(response);
          setReporte(data);

          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsNamesTable = [
    { field: "codigo", header: "Código" },
    { field: "color", header: "Color" },
    { field: "standard", header: "Standard" },
    { field: "fechaCotizacion", header: "Fecha Cotización" },
    { field: "cuentaTaller", header: "Cuenta Taller" },
    { field: "grupoTaller", header: "Grupo Taller" },
    { field: "modeloTaller", header: "Modelo Taller" },
    { field: "compra", header: "Compra" },
    { field: "venta", header: "Venta" },
    { field: "ultimaModificacion", header: "Última Modificación" },
    { field: "fechaUltimaModificacion", header: "Fecha Última Modificación" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };

  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  const [removeLabel, setRemoveLabel] = useState([]);
  const [loadingRemove, setLoadingRemove] = useState(false);

  async function remove() {
    try {
      setLoadingRemove(true);
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-catalogo-servicios/remove",
        {
          catalogoServicio: compareArrays(selectedRows, reporte, "id", "id")[0],
        }
      );
      if (status === HttpStatusCode.Ok) {
        setRemoveLabel(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRemove(false);
    }
  }

  function compareArrays<T>(
    selectRows: T[],
    arrayBase: T[],
    selectKey: keyof T,
    baseKey: keyof T
  ): T[] {
    const result: T[] = [];

    if (selectRows.length > 0 && arrayBase.length > 0) {
      selectRows.forEach((row: any) => {
        const selectValue = row.id;
        const matchingObject = arrayBase.find(
          (item: any) => item.id === selectValue
        );

        if (matchingObject) {
          result.push(matchingObject);
        }
      });
    }

    return result;
  }

  const values = {
    state: {
      pais: securityFilters?.securityValues?.country,
      cuenta: securityFilters?.securityValues?.account,
      cliente: securityFilters?.securityValues?.client,
    },
  };

  return (
    <div className="orden-reparacion-proveedores">

      <ModalAltaMantenerCatalogoServicios
        visible={alta}
        onClose={hideModalAlta}
        selected={compareArrays(selectedRows, reporte, "id", "id")}
        values={values}
      />

      <ModalModificarMantenerCatalogoServicios
        visible={modificar}
        onClose={hideModalModificar}
        selected={compareArrays(selectedRows, reporte, "id", "id")}
        values={values}
      />

      <ModalVerMantenerCatalogoServicios
        visible={ver}
        onClose={hideModalVer}
        selected={selectedRows}
      />

      <Toast ref={toast} position="top-center" />
      <ConfirmDialog />

      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>

      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Listado</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({ id: "25", alt: "Alta" })}
                  text
                  onClick={() => showModalAlta()}
                />
                <Button
                  label={LanguageProvider({ id: "27", alt: "Modificar" })}
                  text
                  onClick={() => showModalModificar()}
                />
                <Button
                  label={LanguageProvider({ id: "12899", alt: "Ver" })}
                  text
                  onClick={() => showModalVer()}
                />
                <Button
                  label={LanguageProvider({ id: "2016", alt: "Borrar" })}
                  text
                  onClick={() => barrar()}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={mantenerCatalogoServicios}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e: any) => setSelectedRows(e.value)}
          metaKeySelection={true}
          dragSelection
        >
          {columnsNamesTable.map((colum, index) => {
            if (
              colum.header === "Fecha Cotización" ||
              colum.header === "Fecha Última Modificación"
            ) {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default MantenerCatalogoServicios;
