import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { TipoUbicacion } from "src/models/TipoUbicacion";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerTipoUbicacion = () => {
  const SCREEN_CODE = "abm0035_mantener_tipo_ubicacion";
  const URL_BASE = "/mantener-tipo-ubicacion";
  const SCREEN_TITLE = (
    <LanguageProvider id="451" alt="Mantener Tipo de Ubicación" />
  );
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: TipoUbicacion, filterValues: any) {
    try {
      if (filterValues?.sistema) {
        const tipoUbicacion = {
          ...model,
          sistema: filterValues?.sistema,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          tipoUbicacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: TipoUbicacion, filterValues: any) {
    try {
      const tipoUbicacion = {
        ...model,
        sistema: filterValues?.sistema,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        tipoUbicacion,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: TipoUbicacion, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        tipoUbicacion: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<TipoUbicacion[]> {
    try {
      if (filter?.sistema) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            sistema: filter?.sistema,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<TipoUbicacion> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          tipoUbicacion: model,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadSistema(filter: any): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/filter/sistema",
        null
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<TipoUbicacion>
      screenCode={SCREEN_CODE}
      title={SCREEN_TITLE}
      filterSettings={[
        {
          filter: "sistema",
          label: LanguageProvider({ id: "4676", alt: "Sistema" }),
          placeholder: LanguageProvider({ id: "4676", alt: "Sistema" }),
          type: "dropdown",
          dataSource: loadSistema,
          dropDownTemplate: DropdownOptionTemplate,
          required: true,
        },
      ]}
      defaultValues={{
        id: 0,
        activo: true,
        noOk: false,
        permiteTransferirUbicacion: false,
        reubicaAutomatico: false,
        sistema: null,
        techada: false,
        tieneCalle: false,
        tieneColumna: false,
        tieneNivel: false,
        ubicaAutomatico: false,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Código" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="Descripcion" />,
        },
        {
          field: "tieneCalle",
          header: <LanguageProvider id="990" alt="Calle" />,
          body: (rowData: any) => (rowData.tieneCalle ? "SI" : "NO"),
        },
        {
          field: "tieneColumna",
          header: <LanguageProvider id="991" alt="Columna" />,
          body: (rowData: any) => (rowData.tieneColumna ? "SI" : "NO"),
        },
        {
          field: "tieneNivel",
          header: <LanguageProvider id="992" alt="Nivel" />,
          body: (rowData: any) => (rowData.tieneNivel ? "SI" : "NO"),
        },
        {
          field: "permiteTransferirUbicacion",
          header: <LanguageProvider id="16302" alt="Adherido RAF" />,
          body: (rowData: any) =>
            rowData.permiteTransferirUbicacion ? "SI" : "NO",
        },
        {
          field: "techada",
          header: <LanguageProvider id="16313" alt="Techada" />,
          body: (rowData: any) => (rowData.techada ? "SI" : "NO"),
        },
        {
          field: "ubicaAutomatico",
          header: <LanguageProvider id="21381" alt="Ubica Automático" />,
          body: (rowData: any) => (rowData.ubicaAutomatico ? "SI" : "NO"),
        },
        {
          field: "reubicaAutomatico",
          header: <LanguageProvider id="21382" alt="Reubica Automático" />,
          body: (rowData: any) => (rowData.reubicaAutomatico ? "SI" : "NO"),
        },
        {
          field: "noOk",
          header: <LanguageProvider id="21383" alt="No Ok" />,
          body: (rowData: any) => (rowData.noOk ? "SI" : "NO"),
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
        },
      ]}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputNumber",
          length: 2,
          required: true,
          disabled: true,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripcion" />,
          type: "inputText",
          length: 50,
          required: true,
        },
        {
          field: "tieneCalle",
          label: <LanguageProvider id="990" alt="Calle" />,
          type: "checkbox",
        },
        {
          field: "tieneColumna",
          label: <LanguageProvider id="991" alt="Columna" />,
          type: "checkbox",
        },
        {
          field: "tieneNivel",
          label: <LanguageProvider id="992" alt="Nivel" />,
          type: "checkbox",
        },
        {
          field: "permiteTransferirUbicacion",
          label: <LanguageProvider id="16302" alt="Adherido RAF" />,
          type: "checkbox",
        },
        {
          field: "techada",
          label: <LanguageProvider id="16313" alt="Techada" />,
          type: "checkbox",
        },

        {
          field: "ubicaAutomatico",
          label: <LanguageProvider id="21381" alt="Ubica Automático" />,
          type: "checkbox",
        },
        {
          field: "reubicaAutomatico",
          label: <LanguageProvider id="21382" alt="Reubica Automático" />,
          type: "checkbox",
        },
        {
          field: "noOk",
          label: <LanguageProvider id="21383" alt="No Ok" />,
          type: "checkbox",
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
    />
  );
};

export default MantenerTipoUbicacion;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
