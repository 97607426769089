import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import {  Taller } from "src/models/Taller";

const MantenerTalleres = () => {
  const SCREEN_CODE = "abm0063_mantener_talleres";
  const URL_BASE = "/mantener-talleres";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: Taller, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const taller = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          taller,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: Taller, filterValues: any) {
    try {
      const taller = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        taller,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  
  async function handleRemove(model: Taller, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        taller: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Taller[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter.securityValues.country,
          cuenta: filter.securityValues.account,
          subcuenta: filter.securityValues.subaccount,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<Taller> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          taller: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <GenericAbm<Taller>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="647" alt="Mantener Talleres" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          account: true,
          client: true,
          subaccount: true,
        },
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Código" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="descripcion" />,
        },
        {
          field: "cuenta.descripcion",
          header: <LanguageProvider id="448" alt="cuenta" />,
        },
        {
          field: "subcuenta.descripcion",
          header: <LanguageProvider id="4791" alt="subcuenta" />,
        },
        {
          field: "usuarioUltimaModificacion.descripcion",
          header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => rowData.activo ? "SI" : "NO",
        },
      ]
      }
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: 0,
        activo: true,
        pais: null,
        cuenta: null,
        fechaUltimaModificacion: null,
        subcuenta: null,
        usuarioUltimaModificacion: null,
        descripcion: "",
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          disabled:true

        },
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripción" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerTalleres;
