import "./MantenerParametrosOperacionConcesionario.scss";
import "primeicons/primeicons.css";

import React, { useEffect, useRef, useState } from "react";

import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Panel } from "primereact/panel";

const dataTableColumns = [
  {
    field:
      "parametroOperacionConcesionario.validacionOperacion.operacion.descripcion",
    header: <LanguageProvider id="1569" alt="x" />,
  },
  {
    field: "parametroOperacionConcesionario.concesionario.id",
    header: <LanguageProvider id="2941" alt="x" />,
  },
  {
    field: "parametroOperacionConcesionario.concesionario.descripcion",
    header: <LanguageProvider id="3756" alt="Descripcion" />,
  },
  {
    field: "parametroOperacionDirEntrega.direccionEntrega.id",
    header: <LanguageProvider id="4216" alt="x" />,
  },
  {
    field: "parametroOperacionDirEntrega.direccionEntrega.direccion",
    header: <LanguageProvider id="3069" alt="x" />,
  },
  {
    field: "parametroOperacionConcesionario.todasDirEntrega",
    header: <LanguageProvider id="18223" alt="x" />,
    body: (rowData: any) =>
      rowData.parametroOperacionConcesionario.todasDirEntrega ? "SI" : "NO",
  },
  {
    field: "parametroOperacionConcesionario",
    header: <LanguageProvider id="72" alt="Activo" />,
    body: (rowData: any) =>
      rowData.parametroOperacionConcesionario.activo ? "SI" : "NO",
  },
  {
    field: "parametroOperacionConcesionario.usuarioUltimaModificacion.id",
    header: <LanguageProvider id="13489" alt="x" />,
  },
  {
    field: "parametroOperacionConcesionario.fechaUltimaModificacion.date",
    header: <LanguageProvider id="16958" alt="x" />,
    body: (rowData: any) => (
      <span>
        {new Date(
          rowData.parametroOperacionConcesionario.fechaUltimaModificacion.date
        ).toLocaleString()}
      </span>
    ),
  },
];

export function MantenerParametrosOperacionConcesionario() {
  const SCREEN_CODE = "abm0205_mantener_parametros_operacion_concesionario";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const toast = useRef<Toast>(null);

  const [loadingDataTable, setLoadingDataTable] = useState(false);
  const [selectedDataTableItem, setSelectedDataTableItem] = useState([]);
  const [dataTableValues, setDataTableValues] = useState([]);
  const [operationParameters, setOperationParameters] = useState<any>(null);

  async function findDtoBy() {
    try {
      setLoadingDataTable(true);
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-parametros-operacion-concesionario/findBy",
        {
          ...operationParameters,
        }
      );
      if (status === 200) {
        if (data.length > 0) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTable(false);
    }

    return [];
  }

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      //  Filtra mensagens do mesmo domínio
      if (event.origin !== window.origin) return;

      //  Verifica se a mensagem tem o identificador correto
      if (
        event.data &&
        typeof event.data === "object" &&
        event.data.type === "from-mantener-parametrizacion-operaciones-" + window.location.pathname
      ) {
        setOperationParameters(event.data.payload);
        console.log("Dados recebidos:", event.data.payload);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    const init = async () => {
      const result = await findDtoBy();
      if (result.length > 0) {
        setDataTableValues(result);
      }
    };

    if (operationParameters) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationParameters]);

  const FooterTamplete = () => {
    return (
      <div>
        <Button
          label={LanguageProvider({ id: "266", alt: "Concesionarios" })}
          onClick={() => {
            // openNewTabConcesionario();
          }}
        />
      </div>
    );
  };

  return (
    <div className="remito-electronico">
      <Toast ref={toast} position="top-center" />
      <Accordion activeIndex={0}>
        <AccordionTab
          header={<LanguageProvider id={"7795"} alt="Filtrar Por:" />}
        >
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"154"} alt="x" />
                </label>
                <SipcoInputText
                  value={operationParameters?.pais.descripcion}
                  readOnly
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"54"} alt="x" />
                </label>
                <SipcoInputText
                  value={operationParameters?.cliente?.descripcion}
                  readOnly
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"448"} alt="x" />
                </label>
                <SipcoInputText
                  value={operationParameters?.cuenta?.descripcion}
                  readOnly
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1569"} alt="x" />
                </label>
                <SipcoInputText
                  readOnly
                  value={
                    operationParameters?.validacionOperacion?.operacion
                      ?.descripcion
                  }
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
      <Panel
        className="generic-abm-panel"
        header={<LanguageProvider id={"6697"} alt="Listado" />}
        // footerTemplate={FooterTamplete}
      >
        <SipcoDataTable
          value={dataTableValues}
          // header={DataTableHeader}

          rowClassName={dataTableRowClassName}
          selectionMode="single"
          selection={selectedDataTableItem}
          onSelectionChange={(e) => setSelectedDataTableItem(e.value)}
          loading={loadingDataTable}
          rows={10}
        >
          {dataTableColumns.map((dataTableColumn, key) => (
            <SipcoColumn
              key={key}
              field={dataTableColumn.field}
              header={dataTableColumn.header}
              body={dataTableColumn.body}
              filter
              sortable
            />
          ))}
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default MantenerParametrosOperacionConcesionario;

const dataTableRowClassName = (rowData: any) => {
  let className = "";
  if (!rowData.activo) {
    className += "data-row-inactive";
  }
  return className;
};
