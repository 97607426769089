import "./MantenerPlayas.scss";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useEffect, useRef, useState } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Playas } from "src/models/Playas";
import { useFilterContext } from "../../../protected-routes";
import { TabPanel, TabView } from "primereact/tabview";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const MantenerPlayas: React.FC = () => {
  const toast = useRef<Toast>(null);
  const SCREEN_CODE = "abm0034_mantener_playas";
  const URL_BASE = "/mantener-playas";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values } = useFilterContext();
  const [activeTabIndex] = useState(0);
  // const [validDatosDialog, setValidDatosDialog] = useState(false);

  // const showError = (id: string, alt: string) => {
  //   try {
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Erro",
  //       detail: <LanguageProvider id={id} alt={alt} />,
  //       life: 3000,
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // async function validationFields() {
  //   try {
  //     if (
  //       !models.descripcion ||
  //       !models.direccion ||
  //       !models.pais ||
  //       !models.provincia ||
  //       !models.ciudad ||
  //       !models.localidad ||
  //       !models.zona ||
  //       !models.puerto ||
  //       !models.proveedor ||
  //       !models.proveedorEmbolsado
  //     ) {
  //       setValidDatosDialog(true);
  //       return false;
  //     }
  //     return true;
  //   } catch (error) {
  //     console.error(error);
  //     return false;
  //   }
  // }

  // const handleConfirmationClose = () => {
  //   try {
  //     setValidDatosDialog(false);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const [models, setModels] = useState({
    //Principal
    descripcion: "",
    email1: "",
    direccion: "",
    email2: "",
    pais: null,
    mailNotaPedido: "",
    provincia: null,
    proveedor: null,
    ciudad: null,
    proveedorEmbolsado: null,
    localidad: null,
    diasAdministrativos: null,
    zona: null,
    horasadministrativasTope: null,
    subcuentaPadre: null,
    impresoraLocal: "",
    puerto: null,
    impresoraExportacion: "",
    tipoCentroSMI: null,
    inscripcionEstadual: "",
    codigoPostal: "",
    cuil: "",
    codigoCentroFrancia: "",

    //Detalle
    cobraEstadia: false,
    stockeaVehiculosFrancia: false,
    compraEstadiaEspecial: false,
    controlaSalida: false,
    mantenimiento: false,
    jumelagePorFamilia: false,
    cobraGestion: false,
    permiteEcomManual: false,
    cobraControlDanios: false,
    generaPrestacionEcom: false,
    controlDaniosEcom: false,
    exigeRemito: false,
    activo: true,
    rf: false,
    administraGefco: false,
    priorizaPrealocacion: false,
    esOrigenCalcKM: false,
    esRetornoCalcKM: false,
    informaRecepcionImpo: false,
    esProcEcomUnificado: false,
    requierValidarOFyVIN: false,
    copiasColecta: null,
    cantidadUbicacionJumelage: null,
    metodoRotacion: null,
    cantidadCallesJumelage: null,
    algoritmoUbicacionPlaya: null,
    proveedorBaterias: null,
    rotacionModelo: null,
    proveedorDanio: null,
    rotacionMeses: null,
    tipoPlaya: null,
    documentoIngreso: null,
  });

  const [loadingPais, setloadingPais] = useState(false);
  const [pais, setPais] = useState([]);
  async function loadPais() {
    setloadingPais(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/pais",
          {}
        );
        if (status === 200) {
          const paisData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setPais(paisData);
          return paisData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloadingPais(false);
    }
    setPais([]);
    return [];
  }

  const [loadingProvincia, setLoadingProvincia] = useState(false);
  const [provincia, setProvincia] = useState([]);
  async function loadProvincia(pais: any) {
    setLoadingProvincia(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/provincia",
          {
            pais: pais,
          }
        );
        if (status === 200) {
          const provinciaData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setProvincia(provinciaData);
          return provinciaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProvincia(false);
    }
    setProvincia([]);
    return [];
  }

  const [loadingCiudad, setLoadingCiudad] = useState(false);
  const [ciudad, setCiudad] = useState([]);
  async function loadCiudad(provincia: any) {
    setLoadingCiudad(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/ciudad",
          {
            provincia: provincia,
          }
        );
        if (status === 200) {
          const ciudadData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setCiudad(ciudadData);
          return ciudadData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCiudad(false);
    }
    setCiudad([]);
    return [];
  }

  const [loadingLocalidad, setLoadingLocalidad] = useState(false);
  const [localidad, setLocalidad] = useState([]);
  async function loadLocalidad(ciudad: any) {
    setLoadingLocalidad(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/localidad",
          {
            ciudad: ciudad,
          }
        );
        if (status === 200) {
          const localidadData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setLocalidad(localidadData);
          return localidadData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingLocalidad(false);
    }
    setLocalidad([]);
    return [];
  }

  const [loadingZona, setLoadingZona] = useState(false);
  const [zona, setZona] = useState([]);
  async function loadZona() {
    setLoadingZona(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/zona",
          {
            pais: values.securityValues.country,
          }
        );
        if (status === 200) {
          const zonaData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setZona(zonaData);
          return zonaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingZona(false);
    }
    setZona([]);
    return [];
  }

  const [loadingTipoSubcuenta, setLoadingTipoSubcuenta] = useState(false);
  const [tipoSubcuenta, setTipoSubcuenta] = useState([]);
  async function loadTipoSubcuenta() {
    setLoadingTipoSubcuenta(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/tipo-subcuenta",
          {}
        );
        if (status === 200) {
          const tipoSubcuentaData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setTipoSubcuenta(tipoSubcuentaData);
          return tipoSubcuentaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoSubcuenta(false);
    }
    setTipoSubcuenta([]);
    return [];
  }

  const [loadingTipoCentroSmi, setLoadingTipoCentroSmi] = useState(false);
  const [tipoCentroSmi, setTipoCentroSmi] = useState([]);
  async function loadTipoCentroSmi() {
    setLoadingTipoCentroSmi(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/tipo-centro",
          {
            pais: values.securityValues.country,
          }
        );
        if (status === 200) {
          const tipoCentroSmiData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setTipoCentroSmi(tipoCentroSmiData);
          return tipoCentroSmiData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoCentroSmi(false);
    }
    setTipoCentroSmi([]);
    return [];
  }

  const [loadingProveedor, setLoadingProveedor] = useState(false);
  const [proveedor, setProveedor] = useState([]);
  async function loadProveedor() {
    setLoadingProveedor(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/proveedor",
          {
            pais: values.securityValues.country,
          }
        );
        if (status === 200) {
          const proveedorData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setProveedor(proveedorData);
          return proveedorData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedor(false);
    }
    setProveedor([]);
    return [];
  }

  const [loadingProveedorEmb, setLoadingProveedorEmb] = useState(false);
  const [proveedorEmb, setProveedorEmb] = useState([]);
  async function loadProveedorEmb() {
    setLoadingProveedorEmb(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/proveedor-embolsado",
          {
            pais: values.securityValues.country,
          }
        );
        if (status === 200) {
          const proveedorEmbData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setProveedorEmb(proveedorEmbData);
          return proveedorEmbData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedorEmb(false);
    }
    setProveedorEmb([]);
    return [];
  }

  const [loadingHoraAdminTope, setLoadingHoraAdminTope] = useState(false);
  const [horaAdminTope, setHoraAdminTope] = useState([]);
  async function loadHoraAdminTope() {
    setLoadingHoraAdminTope(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/hora-admin-tope",
          {
            pais: values.securityValues.country,
          }
        );
        if (status === 200) {
          const horaAdminTopeData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setHoraAdminTope(horaAdminTopeData);
          return horaAdminTopeData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHoraAdminTope(false);
    }
    setHoraAdminTope([]);
    return [];
  }

  const [loadingMetodoRotacion, setLoadingMetodoRotacion] = useState(false);
  const [metodoRotacion, setMetodoRotacion] = useState([]);
  async function loadMetodoRotacion() {
    setLoadingMetodoRotacion(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/tipo-rotacion-modelo",
          {
            pais: values.securityValues.country,
          }
        );
        if (status === 200) {
          const metodoRotacionData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setMetodoRotacion(metodoRotacionData);
          return metodoRotacionData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMetodoRotacion(false);
    }
    setMetodoRotacion([]);
    return [];
  }

  const [loadingAlgUbicacion, setLoadingAlgUbicacion] = useState(false);
  const [algUbicacion, setAlgUbicacion] = useState([]);
  async function loadAlgUbicacion() {
    setLoadingAlgUbicacion(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/algoritmo-ubicacion",
          {
            pais: values.securityValues.country,
          }
        );
        if (status === 200) {
          const algUbicacionData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setAlgUbicacion(algUbicacionData);
          return algUbicacionData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAlgUbicacion(false);
    }
    setAlgUbicacion([]);
    return [];
  }

  const [loadingDocIngreso, setLoadingDocIngreso] = useState(false);
  const [docIngreso, setDocIngreso] = useState([]);
  async function loadDocIngreso() {
    setLoadingDocIngreso(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/documento-ingreso",
          {
            pais: values.securityValues.country,
          }
        );
        if (status === 200) {
          const docIngresoData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setDocIngreso(docIngresoData);
          return docIngresoData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDocIngreso(false);
    }
    setDocIngreso([]);
    return [];
  }

  const [loadingProvBaterias, setLoadingProvBaterias] = useState(false);
  const [provBaterias, setProvBaterias] = useState([]);
  async function loadProvBaterias() {
    setLoadingProvBaterias(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/proveedor-bateria",
          {
            pais: values.securityValues.country,
          }
        );
        if (status === 200) {
          const provBateriasData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setProvBaterias(provBateriasData);
          return provBateriasData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProvBaterias(false);
    }
    setProvBaterias([]);
    return [];
  }

  const [loadingControlDanios, setLoadingControlDanios] = useState(false);
  const [controlDanios, setControlDanios] = useState([]);
  async function loadControlDanios() {
    setLoadingControlDanios(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/proveedor-danos",
          {
            pais: values.securityValues.country,
          }
        );
        if (status === 200) {
          const controlDaniosData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setControlDanios(controlDaniosData);
          return controlDaniosData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingControlDanios(false);
    }
    setControlDanios([]);
    return [];
  }

  const [loadingTipoPlaya, setLoadingTipoPlaya] = useState(false);
  const [tipoPlaya, setTipoPlaya] = useState([]);
  async function loadTipoPlaya() {
    setLoadingTipoPlaya(true);
    try {
      if (values?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-playas/tipo-playa",
          {
            pais: values.securityValues.country,
          }
        );
        if (status === 200) {
          const tipoPlayaData = data.map((x) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setTipoPlaya(tipoPlayaData);
          return tipoPlayaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoPlaya(false);
    }
    setTipoPlaya([]);
    return [];
  }

  async function handleSave() {
    try {
      if (values?.securityValues?.country) {
        const subcuenta = {
          ...models,
          pais: values.securityValues.country,
          cliente: values.securityValues.client,
          cuenta: values.securityValues.account,
          subcuentaDetalle: {
            subcuenta: values.securityValues.subaccount,
            ...models,
            direccion: models.direccion,
            pais: { id: models.pais.id },
            provincia: { id: models.provincia.id },
            ciudad: { id: models.ciudad.id },
            localidad: { id: models.localidad.id },
          },
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          subcuenta: subcuenta,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: Playas) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/update", {
        subcuenta: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove() {
    try {
      const subcuenta = {
        ...models,
        pais: values.securityValues.country,
        cliente: values.securityValues.client,
        cuenta: values.securityValues.account,
        subcuenta: values.securityValues.subaccount,
        subcuentaDetalle: {
          subcuenta: values.securityValues.subaccount,
          ...models,
          direccion: models.direccion,
          pais: { id: models.pais.id },
          provincia: { id: models.provincia.id },
          ciudad: { id: models.ciudad.id },
          localidad: { id: models.localidad.id },
        },
      };

      await sipcoAxiosService.post(URL_BASE + "/remove", {
        subcuentaDetalle: subcuenta,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<Playas[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          cuenta: filter?.securityValues?.account,
        }
      );
      if (status === 200) {
        const findByData = data;
        try {
          const { status: subStatus, data: subData } =
            await sipcoAxiosService.post(URL_BASE + "/findBySubcuentaDetalle", {
              pais: filter?.securityValues?.country,
              cuenta: filter?.securityValues?.account,
            });

          if (subStatus === 200) {
            const mergedData = findByData.map((item: any) => ({
              ...item,
              subcuentaDetalle:
                subData.find((sub: any) => sub.id === item.id) || null,
            }));

            return mergedData;
          }
        } catch (error) {
          console.error(error);
        }

        return findByData;
      }
    } catch (error) {
      console.error("Erro ao buscar findBy:", error);
    }

    return [];
  }

  async function handleGet(model: any): Promise<Playas | null> {
    try {
      const subcuenta = {
        ...model,
        pais: values.securityValues.country,
        cliente: values.securityValues.client,
        cuenta: values.securityValues.account,
        subcuenta: values.securityValues.subaccount,
        provincia: model.provincia,
        ciudad: model.ciudad,
        subcuentaDetalle: {
          ...model.subcuentaDetalle?.subcuentaDetalle,
          subcuenta: values.securityValues.subaccount,
        },
      };
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPkSubcuentaDetalle",
        {
          subcuenta: subcuenta,
        }
      );
      if (status === 200) {
        setModels((prevModels) => {
          return {
            ...prevModels,
            ...data,
            ...data.subcuentaDetalle,
          };
        });

        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  useEffect(() => {
    async function initialize() {
      if (values?.securityValues?.country) {
        try {
          const [
            paises,
            zonas,
            tpSubcuentas,
            tipoCtrSmi,
            proveedores,
            proveedorEmbs,
            hrsAdminTopes,
            metodoRotaciones,
            algUbicaciones,
            docIngresos,
            provBaterias,
            controlDanios,
            tipoPlayas,
          ] = await Promise.all([
            loadPais(),
            loadZona(),
            loadTipoSubcuenta(),
            loadTipoCentroSmi(),
            loadProveedor(),
            loadProveedorEmb(),
            loadHoraAdminTope(),
            loadMetodoRotacion(),
            loadAlgUbicacion(),
            loadDocIngreso(),
            loadProvBaterias(),
            loadControlDanios(),
            loadTipoPlaya(),
          ]);

          setModels((prevModels) => ({
            ...prevModels,
            pais: paises?.[0] || null,
            zona: zonas?.[0] || null,
            puerto: tpSubcuentas?.[0] || null,
            tipoCentroSMI: tipoCtrSmi?.[0] || null,
            proveedor: proveedores?.[0] || null,
            proveedorEmbolsado: proveedorEmbs?.[0] || null,
            horasadministrativasTope: hrsAdminTopes?.[0] || null,
            metodoRotacion: metodoRotaciones?.[0] || null,
            algoritmoUbicacionPlaya: algUbicaciones?.[0] || null,
            documentoIngreso: docIngresos?.[0] || null,
            proveedorBaterias: provBaterias?.[0] || null,
            proveedorDanio: controlDanios?.[0] || null,
            tipoPlaya: tipoPlayas?.[0] || null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }

    initialize();
  }, [values?.securityValues.country]);

  useEffect(() => {
    async function dependsOnPais() {
      if (values?.securityValues?.country && models.pais !== null) {
        try {
          const [provincias] = await Promise.all([loadProvincia(models?.pais)]);

          setModels((prevModels) => ({
            ...prevModels,
            provincia: provincias?.[0] || null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    dependsOnPais();
  }, [models.pais]);

  useEffect(() => {
    async function dependsOnProvincia() {
      if (values?.securityValues?.country && models.provincia !== null) {
        try {
          const [ciudades] = await Promise.all([loadCiudad(models?.provincia)]);

          setModels((prevModels) => ({
            ...prevModels,
            ciudad: ciudades?.[0] || null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    dependsOnProvincia();
  }, [models.provincia]);

  useEffect(() => {
    async function dependsOnCiudad() {
      if (values?.securityValues?.country && models.ciudad !== null) {
        try {
          const [localidades] = await Promise.all([
            loadLocalidad(models?.ciudad),
          ]);

          setModels((prevModels) => ({
            ...prevModels,
            localidad: localidades?.[0] || null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    dependsOnCiudad();
  }, [models.ciudad]);

  return (
    <GenericAbm<Playas>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="442" alt="Mantener Playas" />}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: "",
        secPais: null,
        secCliente: null,
        secCuenta: null,

        // Principal
        descripcion: "",
        email1: "",
        direccion: "",
        email2: "",
        pais: null,
        mailNotaPedido: "",
        provincia: null,
        proveedor: null,
        ciudad: null,
        proveedorEmbolsado: null,
        localidad: null,
        diasAdministrativos: null,
        zona: null,
        horasadministrativasTope: null,
        subcuentaPadre: null,
        impresoraLocal: "",
        puerto: null,
        impresoraExportacion: "",
        tipoCentroSMI: null,
        inscripcionEstadual: "",
        codigoPostal: "",
        cuil: "",
        codigoCentroFrancia: "",

        // Detalhe
        cobraEstadia: false,
        stockeaVehiculosFrancia: false,
        compraEstadiaEspecial: false,
        controlaSalida: false,
        mantenimiento: false,
        jumelagePorFamilia: false,
        cobraGestion: false,
        permiteEcomManual: false,
        cobraControlDanios: false,
        generaPrestacionEcom: false,
        controlDaniosEcom: false,
        exigeRemito: false,
        activo: true,
        rf: false,
        administraGefco: false,
        priorizaPrealocacion: false,
        esOrigenCalcKM: false,
        esRetornoCalcKM: false,
        informaRecepcionImpo: false,
        esProcEcomUnificado: false,
        requierValidarOFyVIN: false,
        copiasColecta: null,
        cantidadUbicacionJumelage: null,
        metodoRotacion: null,
        cantidadCallesJumelage: null,
        algoritmoUbicacionPlaya: null,
        proveedorBaterias: null,
        rotacionModelo: null,
        proveedorDanio: null,
        rotacionMeses: null,
        tipoPlaya: null,
        documentoIngreso: null,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
        },
      }}
      dataTableColumns={[
        {
          field: "descripcion",
          header: <LanguageProvider id="4791" alt="Subcuenta" />,
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.direccion",
          header: <LanguageProvider id="24014" alt="Dirección" />,
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.zona.descripcion",
          header: <LanguageProvider id="23996" alt="Zona" />,
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.localidad.descripcion",
          header: <LanguageProvider id="3186" alt="Localidad" />,
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.ciudad.descripcion",
          header: <LanguageProvider id="164" alt="Ciudad" />,
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.provincia.descripcion",
          header: <LanguageProvider id="159" alt="Provincia" />,
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.pais.descripcion",
          header: <LanguageProvider id="154" alt="País" />,
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.codigoPostal",
          header: <LanguageProvider id="7511" alt="C. Postal" />,
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.mantenimiento",
          header: <LanguageProvider id="5577" alt="Mantenimiento" />,
          body: (rowData: any) => {
            return rowData.subcuentaDetalle.subcuentaDetalle.mantenimiento
              ? "SI"
              : "NO";
          },
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.compraEstadiaEspecial",
          header: <LanguageProvider id="16409" alt="Compra Estadia Especial" />,
          body: (rowData: any) => {
            return rowData.subcuentaDetalle.subcuentaDetalle
              .compraEstadiaEspecial
              ? "SI"
              : "NO";
          },
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.stockeaVehiculosFrancia",
          header: <LanguageProvider id="16408" alt="Stockea Vehículos" />,
          body: (rowData: any) => {
            return rowData.subcuentaDetalle.subcuentaDetalle
              .stockeaVehiculosFrancia
              ? "SI"
              : "NO";
          },
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.generaPrestacionEcom",
          header: (
            <LanguageProvider id="11027" alt="Genera prestación en ECOM" />
          ),
          body: (rowData: any) => {
            return rowData.subcuentaDetalle.subcuentaDetalle
              .generaPrestacionEcom
              ? "SI"
              : "NO";
          },
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.permiteEcomManual",
          header: (
            <LanguageProvider id="10704" alt="Ingresa Vin manual en ECOM" />
          ),
          body: (rowData: any) => {
            return rowData.subcuentaDetalle.subcuentaDetalle.permiteEcomManual
              ? "SI"
              : "NO";
          },
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.exigeRemito",
          header: <LanguageProvider id="10946" alt="Exige Remito" />,
          body: (rowData: any) => {
            return rowData.subcuentaDetalle.subcuentaDetalle.exigeRemito
              ? "SI"
              : "NO";
          },
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.codigoCentroFrancia",
          header: <LanguageProvider id="16404" alt="Cod. Centro Francés" />,
        },
        {
          field: "subcuentaDetalle.subcuentaDetalle.cantidadCallesJumelage",
          header: <LanguageProvider id="11323" alt="Cant. Copias Colecta" />,
        },
        {
          field:
            "subcuentaDetalle.subcuentaDetalle.proveedorEmbolsado.descripcion",
          header: <LanguageProvider id="16405" alt="Proveedor Embolsado" />,
        },
        {
          field:
            "subcuentaDetalle.subcuentaDetalle.proveedorBaterias.descripcion",
          header: <LanguageProvider id="12290" alt="Proveedor Baterías" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      formSettings={[]}
      dialogClassName="mantener-playas-dialog"
      dialogStyle={{ minWidth: "55vw" }}
      customForm={
        <div className="mantener-playas">
          <div className="form">
            <Panel>
              <TabView activeIndex={activeTabIndex}>
                {/* Principal */}
                <TabPanel
                  className="principal"
                  header={<LanguageProvider id="17121" alt="Principal" />}
                >
                  <Panel>
                    <div className="form">
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <span className="required">*</span>
                            <LanguageProvider id="3756" alt="Descripción" />
                          </label>
                          <SipcoInputText
                            value={models.descripcion}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                descripcion: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id="24068" alt="Email" /> 1
                          </label>
                          <SipcoInputText
                            value={models.email1}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                email1: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <span className="required">*</span>
                            <LanguageProvider id="24014" alt="Dirección" />
                          </label>
                          <SipcoInputText
                            value={models.direccion}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                direccion: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id="24068" alt="Email" /> 2
                          </label>
                          <SipcoInputText
                            value={models.email2}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                email2: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <span className="required">*</span>
                            <LanguageProvider id="154" alt="País" />
                          </label>
                          <SipcoDropdown
                            value={models.pais}
                            options={pais}
                            onChange={(e) =>
                              setModels({ ...models, pais: e.value })
                            }
                            loading={loadingPais}
                            filter
                            optionLabel="label"
                            virtualScrollerOptions={{ itemSize: 30 }}
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="12288"
                              alt="Mails aviso Nota de Pedido"
                            />
                          </label>
                          <SipcoInputText
                            value={models.mailNotaPedido}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                mailNotaPedido: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <span className="required">*</span>
                            <LanguageProvider id="24020" alt="Provincia" />
                          </label>
                          <SipcoDropdown
                            value={models.provincia}
                            options={provincia}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                provincia: e.value,
                              })
                            }
                            loading={loadingProvincia}
                            filter
                            optionLabel="label"
                            virtualScrollerOptions={{ itemSize: 30 }}
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <span className="required">*</span>
                            <LanguageProvider id="24038" alt="Proveedor" />
                          </label>
                          <SipcoDropdown
                            value={models.proveedor}
                            options={proveedor}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                proveedor: e.value,
                              })
                            }
                            loading={loadingProveedor}
                            filter
                            optionLabel="label"
                            virtualScrollerOptions={{ itemSize: 30 }}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <span className="required">*</span>
                            <LanguageProvider id="164" alt="Ciudad" />
                          </label>
                          <SipcoDropdown
                            value={models.ciudad}
                            options={ciudad}
                            onChange={(e) =>
                              setModels({ ...models, ciudad: e.value })
                            }
                            loading={loadingCiudad}
                            filter
                            optionLabel="label"
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <span className="required">*</span>
                            <LanguageProvider
                              id="16405"
                              alt="Proveedor Embolsado"
                            />
                          </label>
                          <SipcoDropdown
                            value={models.proveedorEmbolsado}
                            options={proveedorEmb}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                proveedorEmbolsado: e.value,
                              })
                            }
                            loading={loadingProveedorEmb}
                            filter
                            optionLabel="label"
                            virtualScrollerOptions={{ itemSize: 30 }}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <span className="required">*</span>
                            <LanguageProvider id="3186" alt="Localidad" />
                          </label>
                          <SipcoDropdown
                            value={models.localidad}
                            options={localidad}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                localidad: e.value,
                              })
                            }
                            loading={loadingLocalidad}
                            filter
                            optionLabel="label"
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id="16400" alt="Días Admin." />
                          </label>
                          <SipcoInputText
                            value={models.diasAdministrativos}
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /\D/g,
                                ""
                              ); //numeric
                              setModels({
                                ...models,
                                diasAdministrativos: inputValue,
                              });
                            }}
                            maxLength={3}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <span className="required">*</span>
                            <LanguageProvider id="5255" alt="Zona" />
                          </label>
                          <SipcoDropdown
                            value={models.zona}
                            options={zona}
                            onChange={(e) =>
                              setModels({ ...models, zona: e.value })
                            }
                            loading={loadingZona}
                            filter
                            optionLabel="label"
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="16401"
                              alt="Hora Admin. Tope"
                            />
                          </label>
                          <SipcoDropdown
                            value={models.horasadministrativasTope}
                            options={horaAdminTope}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                horasadministrativasTope: e.value,
                              })
                            }
                            loading={loadingHoraAdminTope}
                            filter
                            optionLabel="label"
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        {/* <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="10945"
                              alt="Playa origen de referencia"
                            />
                          </label>
                          <SipcoDropdown
                            value={princModel.subcuentaPadre}
                            options={PlayaOrigenRef}
                            onChange={(e) =>
                              setPrincModel({
                                ...princModel,
                                subcuentaPadre: e.value,
                              })
                            }
                            loading={LoadingPlayaOrigenRef}
                            filter
                            optionLabel="label"
                          />
                        </div> */}
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="16403"
                              alt="Impresora Local"
                            />
                          </label>
                          <SipcoInputText
                            value={models.impresoraLocal}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                impresoraLocal: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <span className="required">*</span>
                            <LanguageProvider id="2354" alt="Tipo Subcuenta" />
                          </label>
                          <SipcoDropdown
                            value={models.puerto}
                            options={tipoSubcuenta}
                            onChange={(e) =>
                              setModels({ ...models, puerto: e.value })
                            }
                            loading={loadingTipoSubcuenta}
                            filter
                            optionLabel="label"
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id="16406" alt="Impresora Expo" />
                          </label>
                          <SipcoInputText
                            value={models.impresoraExportacion}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                impresoraExportacion: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="16402"
                              alt="Tipo Centro SMI"
                            />
                          </label>
                          <SipcoDropdown
                            value={models.tipoCentroSMI}
                            options={tipoCentroSmi}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                tipoCentroSMI: e.value,
                              })
                            }
                            loading={loadingTipoCentroSmi}
                            filter
                            optionLabel="label"
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="13979"
                              alt="Inscripción Estadual"
                            />
                          </label>
                          <SipcoInputText
                            value={models.inscripcionEstadual}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                inscripcionEstadual: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id="7511" alt="C. Postal" />
                          </label>
                          <SipcoInputText
                            value={models.codigoPostal}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                codigoPostal: e.target.value,
                              })
                            }
                            maxLength={8}
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id="24008" alt="CUIT" />
                          </label>
                          <SipcoInputText
                            value={models.cuil}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                cuil: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="16404"
                              alt="Cod. Centro Francés"
                            />
                          </label>
                          <SipcoInputText
                            value={models.codigoCentroFrancia}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                codigoCentroFrancia: e.target.value,
                              })
                            }
                            maxLength={10}
                          />
                        </div>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
                {/* //Detalle */}
                <TabPanel
                  className="detalle"
                  header={<LanguageProvider id="3397" alt="Detalle" />}
                >
                  <Panel>
                    <div className="form">
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id="16407" alt="Cobra Estadía" />
                          </label>
                          <Checkbox
                            checked={models?.cobraEstadia}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                cobraEstadia: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="16408"
                              alt="Stockea Vehículos"
                            />
                          </label>
                          <Checkbox
                            checked={models?.stockeaVehiculosFrancia}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                stockeaVehiculosFrancia: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="16409"
                              alt="Compra Estadia Especial"
                            />
                          </label>
                          <Checkbox
                            checked={models?.compraEstadiaEspecial}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                compraEstadiaEspecial: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="16410"
                              alt="Control Guardia"
                            />
                          </label>
                          <Checkbox
                            checked={models?.controlaSalida}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                controlaSalida: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id="5577" alt="Mantenimiento" />
                          </label>
                          <Checkbox
                            checked={models?.mantenimiento}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                mantenimiento: e.checked,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="10599"
                              alt="Control de Jumelage por Familia"
                            />
                          </label>
                          <Checkbox
                            checked={models?.jumelagePorFamilia}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                jumelagePorFamilia: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="10596"
                              alt="Cobra Gestión de Playa"
                            />
                          </label>
                          <Checkbox
                            checked={models?.cobraGestion}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                cobraGestion: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="10704"
                              alt="Ingresa Vin manual en ECOM"
                            />
                          </label>
                          <Checkbox
                            checked={models?.permiteEcomManual}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                permiteEcomManual: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="10597"
                              alt="Cobra Control de Averías"
                            />
                          </label>
                          <Checkbox
                            checked={models?.cobraControlDanios}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                cobraControlDanios: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="11027"
                              alt="Genera prestación en ECOM"
                            />
                          </label>
                          <Checkbox
                            checked={models?.generaPrestacionEcom}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                generaPrestacionEcom: e.checked,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="10598"
                              alt="Ingreso Produccion"
                            />
                          </label>
                          <Checkbox
                            checked={models?.controlDaniosEcom}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                controlDaniosEcom: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id="10946" alt="Exige Remito" />
                          </label>
                          <Checkbox
                            checked={models?.exigeRemito}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                exigeRemito: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id="72" alt="Activo" />
                          </label>
                          <Checkbox
                            checked={models?.activo}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                activo: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id="20191" alt="Tiene RF" />
                          </label>
                          <Checkbox
                            checked={models?.rf}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                rf: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="21380"
                              alt="Administra GEFCO"
                            />
                          </label>
                          <Checkbox
                            checked={models?.administraGefco}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                administraGefco: e.checked,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="22093"
                              alt="Prioriza Pre-alocación"
                            />
                          </label>
                          <Checkbox
                            checked={models?.priorizaPrealocacion}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                priorizaPrealocacion: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="25007"
                              alt="Considerar c/posible Origen Viaje"
                            />
                          </label>
                          <Checkbox
                            checked={models?.esOrigenCalcKM}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                esOrigenCalcKM: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="25008"
                              alt="Considerar c/posible Fin Viaje"
                            />
                          </label>
                          <Checkbox
                            checked={models?.esRetornoCalcKM}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                esRetornoCalcKM: e.checked,
                              })
                            }
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="25089"
                              alt="Proc. ECOM unificado"
                            />
                          </label>
                          <Checkbox
                            checked={models?.esProcEcomUnificado}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                esProcEcomUnificado: e.checked,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="25090"
                              alt="Val. en ECOM vin/OF"
                            />
                          </label>
                          <Checkbox
                            checked={models?.requierValidarOFyVIN}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                requierValidarOFyVIN: e.checked,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Panel>
                  <Panel className="dt">
                    <div className="form">
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="11323"
                              alt="Cant. Copias Colecta"
                            />
                          </label>
                          <SipcoInputText
                            value={models.copiasColecta}
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /\D/g,
                                ""
                              ); //numeric
                              setModels({
                                ...models,
                                copiasColecta: inputValue,
                              });
                            }}
                            maxLength={1}
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="10600"
                              alt="Ubic. a buscar por calle"
                            />
                          </label>
                          <SipcoInputText
                            value={models.cantidadUbicacionJumelage}
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /\D/g,
                                ""
                              ); //numeric
                              setModels({
                                ...models,
                                cantidadUbicacionJumelage: inputValue,
                              });
                            }}
                            maxLength={2}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="11151"
                              alt="Método de Rotación"
                            />
                          </label>
                          <SipcoDropdown
                            value={models.metodoRotacion}
                            options={metodoRotacion}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                metodoRotacion: e.value,
                              })
                            }
                            loading={loadingMetodoRotacion}
                            filter
                            optionLabel="label"
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="10601"
                              alt="Cant. calles a buscar"
                            />
                          </label>
                          <SipcoInputText
                            value={models.cantidadCallesJumelage}
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /\D/g,
                                ""
                              ); //numeric
                              setModels({
                                ...models,
                                cantidadCallesJumelage: inputValue,
                              });
                            }}
                            maxLength={2}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="22507"
                              alt="Algoritmo Ubicación"
                            />
                          </label>
                          <SipcoDropdown
                            value={models.algoritmoUbicacionPlaya}
                            options={algUbicacion}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                algoritmoUbicacionPlaya: e.value,
                              })
                            }
                            loading={loadingAlgUbicacion}
                            filter
                            optionLabel="label"
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="12290"
                              alt="Proveedor Baterías"
                            />
                          </label>
                          <SipcoDropdown
                            value={models.proveedorBaterias}
                            options={provBaterias}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                proveedorBaterias: e.value,
                              })
                            }
                            loading={loadingProvBaterias}
                            filter
                            optionLabel="label"
                            virtualScrollerOptions={{ itemSize: 30 }}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="11152"
                              alt="Cantidad de Modelos"
                            />
                          </label>
                          <SipcoInputText
                            value={models.rotacionModelo}
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /\D/g,
                                ""
                              ); //numeric
                              setModels({
                                ...models,
                                rotacionModelo: inputValue,
                              });
                            }}
                            maxLength={2}
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="14653"
                              alt="Control de Daños"
                            />
                          </label>
                          <SipcoDropdown
                            value={models.proveedorDanio}
                            options={controlDanios}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                proveedorDanio: e.value,
                              })
                            }
                            loading={loadingControlDanios}
                            filter
                            optionLabel="label"
                            virtualScrollerOptions={{ itemSize: 30 }}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="11153"
                              alt="Cantidad de Meses"
                            />
                          </label>
                          <SipcoInputText
                            value={models.rotacionMeses}
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /\D/g,
                                ""
                              ); //numeric
                              setModels({
                                ...models,
                                rotacionMeses: inputValue,
                              });
                            }}
                            maxLength={2}
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id="19033" alt="Tipo de Playa" />
                          </label>
                          <SipcoDropdown
                            value={models.tipoPlaya}
                            options={tipoPlaya}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                tipoPlaya: e.value,
                              })
                            }
                            loading={loadingTipoPlaya}
                            filter
                            optionLabel="label"
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id="21379"
                              alt="Documento de Ingreso"
                            />
                          </label>
                          <SipcoDropdown
                            value={models.documentoIngreso}
                            options={docIngreso}
                            onChange={(e) =>
                              setModels({
                                ...models,
                                documentoIngreso: e.value,
                              })
                            }
                            loading={loadingDocIngreso}
                            filter
                            optionLabel="label"
                          />
                        </div>
                      </div>
                    </div>
                  </Panel>
                </TabPanel>
              </TabView>
            </Panel>
            <div className="form-row"></div>
            <div className="dialog">
              {/* Faltan completar datos Dialog */}
              {/* <Dialog
                header={<LanguageProvider id="16239" alt="Atención" />}
                visible={validDatosDialog}
                style={{ width: "6vw", textAlign: "center" }}
                footer={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Button
                      label={getLabel("5599", "OK")}
                      icon="pi pi-check"
                      onClick={handleConfirmationClose}
                      autoFocus
                    />
                  </div>
                }
                onHide={handleConfirmationClose}
              >
                <p style={{ padding: "1rem 0", margin: "0" }}>
                  <LanguageProvider id="10531" alt="Faltan completar datos" />
                </p>
              </Dialog> */}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default MantenerPlayas;

// const getLabel = (id: string, alt: string): string =>
//   LanguageProvider({ id, alt }) as unknown as string;
