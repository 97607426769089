import React, { useEffect, useRef, useState } from "react";

import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Panel } from "primereact/panel";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoCalendar from "@shared/components/sipco-calendar";
import { Checkbox } from "primereact/checkbox";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";

export function RecepcionPlayaForm(props) {
  const { SCREEN_CODE } = props;
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const dt = useRef(null);
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();

  const [validQuantDatosdDialog, setValidQuantDatosdDialog] = useState(false);
  const [validVinFieldDialog, setValidVinFieldDialog] = useState(false);
  const [validDatosGrillaDialog, setValidDatosGrillaDialog] = useState(false);
  const [validDatosdDialog, setValidDatosdDialog] = useState(false);
  const [loadingVFRecepDialog, setLoadingVFRecepDialog] = useState(false);
  const [validFRecepSupDialog, setValidFRecepSupDialog] = useState(false);
  const [validFRecepInfDialog, setValidFRecepInfDialog] = useState(false);
  const [recepSiNoDialog, setRecepSiNoDialog] = useState(false);
  const [sucessFechaRecepDialog, setSucessFechaRecepDialog] = useState(false);

  const [selectedReportData, setSelectedReportData] = useState([]);

  const handleVinKeyDown = (event) => {
    try {
      if (event.key === "Enter") {
        if (filter.vin.length >= 8 && filter.vin.length <= 17) {
          loadVin(filter.vin);
        } else {
          setValidQuantDatosdDialog(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function procesarClick() {
    try {
      await !validateFields();
    } catch (error) {
      console.error(error);
    }
  }

  async function validateFields() {
    try {
      if (!selectedReportData) {
        setValidDatosGrillaDialog(true);
        return;
      }
      if (
        !transportistaValue ||
        !model.proveedorDanos ||
        !model.proveedorGancho
      ) {
        setValidDatosdDialog(true);
        return;
      }
      await validarCantidadSuperior();
    } catch (error) {
      console.error(error);
    }
  }

  async function validarCantidadSuperior() {
    try {
      const filters = {
        pais: { id: securityFilters.securityValues.country?.id },
        cuenta: { id: securityFilters.securityValues.account?.id },
        stockPlayaDtoList: [report?.stockPlaya],
        subcuenta: { id: securityFilters.securityValues.subaccount?.id },
        stockPlayaDestino: { ...stockPlayaDestModel },
      };
      const { status } = await sipcoAxiosService.post(
        "/recepcion-de-playa/validar-cantidad-superior",
        filters
      );
      if (status === 200) {
        const today = new Date();
        const oneDayBefore = new Date(today);
        oneDayBefore.setDate(today.getDate() - 2);

        if (status === 200) {
          if (model.fechaRecep < oneDayBefore) {
            setValidFRecepSupDialog(true);
            return;
          }
          if (model.fechaRecep >= today && oneDayBefore) {
            setValidFRecepInfDialog(true);
            return;
          }
          setRecepSiNoDialog(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleFechaRecepcion = (isConfirmed) => {
    setRecepSiNoDialog(false);
    if (isConfirmed) {
      //procesar();
    }
  };

  // async function procesar() {
  //   setLoadingVFRecepDialog(true);
  //   try {
  //     let filters = {
  //       pais: { id: securityFilters.securityValues.country?.id },
  //       cliente: { id: securityFilters.securityValues.client?.id },
  //       cuenta: { id: securityFilters.securityValues.account?.id },
  //       subcuenta: { id: securityFilters.securityValues.subaccount?.id },
  //       stockPlayaDtoList: [report?.stockPlaya],
  //       fecha: model?.fechaRecep ? model?.fechaRecep.getTime() : null,
  //       stockPlayaDestino: { ...stockPlayaDestModel },
  //       hojaRuta: {
  //         pais: { id: securityFilters.securityValues.country?.id },
  //         cliente: { id: securityFilters.securityValues.client?.id },
  //         ...filter?.hojaRuta,
  //       },
  //       proveedorControlDanios: { id: model?.proveedorDanos?.id },
  //       proveedorGancho: { id: model?.proveedorGancho?.id },
  //       transportista: transportistaValue || null,
  //       compra: model?.compraFlete,
  //       venta: model?.ventaFlete,
  //     };

  //     const { status, data } = await sipcoAxiosService.post(
  //       "/recepcion-de-playa/recepcion-playa-br",
  //       filters
  //     );
  //     if (status === 200) {
  //       setSucessFechaRecepDialog(true);
  //       loadReportData();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoadingVFRecepDialog(false);
  //   }
  // }

  const handleConfirmationClose = () => {
    try {
      setValidQuantDatosdDialog(false);
      setValidVinFieldDialog(false);
      setValidDatosGrillaDialog(false);
      setValidDatosdDialog(false);
      setValidFRecepSupDialog(false);
      setValidFRecepInfDialog(false);
      setRecepSiNoDialog(false);
      setSucessFechaRecepDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [filter, setFilter] = useState({
    vin: null,
    hojaRuta: null,
  });

  const [model, setModel] = useState({
    fechaRecep: new Date(),
    transportista: "",
    proveedorDanos: null,
    proveedorGancho: null,
    compraFlete: false,
    ventaFlete: false,
  });

  const [stockPlayaDestModel, setStockPlayaDestModel] = useState({
    calle: null,
    columna: null,
    nivel: null,
  });

  const [vin, setVin] = useState(null);
  async function loadVin(vin: any) {
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.account &&
        securityFilters?.securityValues?.subaccount &&
        vin
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-de-playa/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            vin: { id: vin },
          }
        );
        if (status === 200) {
          setVin(data);
          if (Array.isArray(data) && data.length === 0) {
            setValidVinFieldDialog(true);
          }
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [loadingHojaRuta, setLoadingHojaRuta] = useState(false);
  const [hojaRuta, setHojaRuta] = useState([]);
  async function loadHojaRuta() {
    setLoadingHojaRuta(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-de-playa/find-recepcion",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.id,
              ...securityFilters?.securityValues?.client,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id,
            },
            subcuenta: {
              id: securityFilters?.securityValues?.subaccount?.id,
            },
            vin: {
              id: filter?.vin || null,
            },
          }
        );
        if (status === 200) {
          setHojaRuta(data);
          setReport(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHojaRuta(false);
    }
    return [];
  }

  const [loadingUbicacione, setLoadingUbicacione] = useState(false);
  const [ubicacione, setUbicacione] = useState([]);
  async function loadUbicacione() {
    setLoadingUbicacione(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-de-playa/calles",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id || null,
            },
          }
        );
        if (status === 200) {
          setUbicacione(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUbicacione(false);
    }
    setUbicacione([]);
    return [];
  }

  const [loadingColumna, setLoadingColumna] = useState(false);
  const [columna, setColumna] = useState([]);
  async function loadColumna(calle: any) {
    setLoadingColumna(true);
    try {
      if (calle) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-de-playa/columnas",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
            cliente: {
              id: securityFilters.securityValues.client.id,
            },
            cuenta: {
              id: securityFilters.securityValues.account.id,
            },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id,
            },
            calle: calle,
          }
        );
        if (status === 200) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
    return [];
  }

  const [loadingNivel, setLoadingNivel] = useState(false);
  const [nivel, setNivel] = useState([]);
  async function loadNivel(calle: any, columna: any) {
    setLoadingNivel(true);
    try {
      if (calle && columna) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-de-playa/niveles",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id,
            },
            calle: calle,
            columna: columna,
          }
        );
        if (status === 200) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
    return [];
  }

  const [loadingProveedorDanos, setLoadingProveedorDanos] = useState(false);
  const [proveedorDanos, setProveedorDanos] = useState([]);
  async function loadProveedorDanos() {
    setLoadingProveedorDanos(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-de-playa/danios",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
            cliente: {
              id: securityFilters.securityValues.client.id,
            },
            cuenta: {
              id: securityFilters.securityValues.account.id,
            },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id,
            },
            tipoProveedor: {
              id: 27,
            },
          }
        );
        if (status === 200) {
          setProveedorDanos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedorDanos(false);
    }
    return [];
  }

  const [loadingProveedorGancho, setLoadingProveedorGancho] = useState(false);
  const [proveedorGancho, setProveedorGancho] = useState([]);
  async function loadProveedorGancho() {
    setLoadingProveedorGancho(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-de-playa/ganchos",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
            cliente: {
              id: securityFilters.securityValues.client.id,
            },
            cuenta: {
              id: securityFilters.securityValues.account.id,
            },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id,
            },
            tipoProveedor: {
              id: 26,
            },
          }
        );
        if (status === 200) {
          setProveedorGancho(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedorGancho(false);
    }
    return [];
  }

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [report, setReport] = useState(null);
  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "recepcion-de-playa/stock-playa",
        {
          pais: { id: securityFilters?.securityValues?.country?.id },
          cliente: {
            id: securityFilters?.securityValues?.client?.id,
            ...securityFilters?.securityValues?.client,
          },
          cuenta: { id: securityFilters?.securityValues?.account?.id },
          subcuenta: { id: securityFilters?.securityValues?.subaccount?.id },
          hojaRuta: {
            id: filter?.hojaRuta?.id || null,
          },
        }
      );
      if (status === 200) {
        setReport(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  useEffect(() => {
    async function loadRecepcionPlaya() {
      if (filter?.hojaRuta !== null) {
        try {
          await loadReportData();
        } catch (error) {
          console.error(error);
        }
      }
    }
    loadRecepcionPlaya();
  }, [filter?.hojaRuta]);

  useEffect(() => {
    const selectAllReportDatas = () => {
      try {
        if (report && report.length > 0) {
          setSelectedReportData(report);
        }
      } catch (error) {
        console.error(error);
      }
    };

    selectAllReportDatas();
  }, [report]);

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const [hojaRutas, provDanios, provGanchos] = await Promise.all([
            loadHojaRuta(),
            loadProveedorDanos(),
            loadProveedorGancho(),
          ]);

          setFilter((prevFilters) => ({
            ...prevFilters,
            hojaRuta: hojaRutas?.length > 0 ? hojaRutas[0] : null,
          }));

          setModel((prevModels) => ({
            ...prevModels,
            proveedorDanos: provDanios?.length > 0 ? provDanios[0] : null,
            proveedorGancho: provGanchos?.length > 0 ? provGanchos[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  useEffect(() => {
    async function updateUbicacione() {
      if (filter?.hojaRuta !== null) {
        try {
          const ubicaciones = await loadUbicacione();

          setStockPlayaDestModel((prevModels) => ({
            ...prevModels,
            calle: ubicaciones?.length > 0 ? ubicaciones[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateUbicacione();
  }, [filter?.hojaRuta]);

  useEffect(() => {
    async function updateColumna() {
      if (stockPlayaDestModel?.calle !== null) {
        try {
          const columnas = await loadColumna(stockPlayaDestModel.calle);

          setStockPlayaDestModel((prevModels) => ({
            ...prevModels,
            columna: columnas?.length > 0 ? columnas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateColumna();
  }, [filter.hojaRuta, stockPlayaDestModel?.calle]);

  useEffect(() => {
    async function updateNivel() {
      if (stockPlayaDestModel?.columna !== null) {
        try {
          const niveles = await loadNivel(
            stockPlayaDestModel.calle,
            stockPlayaDestModel.columna
          );

          setStockPlayaDestModel((prevModels) => ({
            ...prevModels,
            nivel: niveles?.length > 0 ? niveles[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateNivel();
  }, [filter.hojaRuta, stockPlayaDestModel?.columna]);

  useEffect(
    function updateFechaRecep() {
      if (filter.hojaRuta?.fechaHojaRuta?.date) {
        try {
          const firstDate = filter.hojaRuta.fechaHojaRuta.date;

          if (firstDate) {
            setModel((prev) => ({
              ...prev,
              fechaRecep: new Date(firstDate),
            }));
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    [filter.hojaRuta]
  );

  const transportistaValue = filter?.hojaRuta?.administradorTractor || null;

  return (
    <div className="recepcion-playa">
      <Filter
        onSearch={null}
        securityOptions={{
          country: true,
          account: true,
          client: true,
          subaccount: true,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Panel
        style={{ marginBottom: "4px" }}
        header={<LanguageProvider id="816" alt="Recepción de Playa" />}
      >
        <div className="form">
          <div className="form-row">
            <div className="sipco-option-vin">
              <label>
                <LanguageProvider id="" alt="Ingrese el VIN" />
              </label>
              <SipcoAutocomplete
                value={filter.vin}
                suggestions={vin}
                onKeyDown={handleVinKeyDown}
                onChange={(e) => setFilter({ ...filter, vin: e.value })}
                maxLength={17}
                field="id"
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id="924" alt="Hoja de Ruta" />
              </label>
              <SipcoDropdown
                value={filter.hojaRuta}
                options={hojaRuta}
                onChange={(e) => setFilter({ ...filter, hojaRuta: e.value })}
                optionLabel="id"
                loading={loadingHojaRuta}
                filter
              />
            </div>
          </div>
          <SipcoDataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={report}
            paginator
            scrollHeight="flex"
            rows={16}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            resizableColumns
            removableSort
            filterDisplay="menu"
            loading={loadingReportData}
            selectionMode="multiple"
            selection={selectedReportData}
            onSelectionChange={(e) => setSelectedReportData(e.value)}
          >
            <SipcoColumn
              field="stockPlaya.cliente.descripcion"
              header={<LanguageProvider id="54" alt="Cliente" />}
              sortable
              filter
            />
            <SipcoColumn
              field="stockPlaya.vin.id"
              header={<LanguageProvider id="1491" alt="VIN" />}
              sortable
              filter
            />
            <SipcoColumn
              field="stockPlaya.calle"
              header={<LanguageProvider id="990" alt="Calle" />}
              sortable
              filter
            />
            <SipcoColumn
              field="stockPlaya.columna"
              header={<LanguageProvider id="991" alt="Columna" />}
              sortable
              filter
            />
            <SipcoColumn
              field="stockPlaya.nivel"
              header={<LanguageProvider id="992" alt="Nivel" />}
              sortable
              filter
            />
            <SipcoColumn
              field="stockPlaya.vin.marca.descripcion"
              header={<LanguageProvider id="396" alt="Marca" />}
              sortable
              filter
            />
            <SipcoColumn
              field="stockPlaya.vin.modelo.descripcion"
              header={<LanguageProvider id="2139" alt="Modelo" />}
              sortable
              filter
            />
            <SipcoColumn
              field="stockPlaya.vin.color.descripcion"
              header={<LanguageProvider id="2140" alt="Color" />}
              sortable
              filter
            />
            <SipcoColumn
              field="stockPlaya.vin.patente"
              header={<LanguageProvider id="16846" alt="Patentado" />}
              sortable
              filter
            />
          </SipcoDataTable>
        </div>
      </Panel>
      <Panel
        header={
          <LanguageProvider
            id="883"
            alt="Ingreso de Datos para la Transferencia"
          />
        }
      >
        <div className="form">
          <div className="form-row">
            <div className="sipco-option">
              <label>
                <LanguageProvider id="921" alt="Ubicaciones" />
              </label>
              <SipcoDropdown
                value={stockPlayaDestModel.calle}
                options={ubicacione}
                onChange={(e) =>
                  setStockPlayaDestModel({
                    ...stockPlayaDestModel,
                    calle: e.value,
                  })
                }
                optionLabel="descripcionFull"
                loading={loadingUbicacione}
                filter
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id="991" alt="Columna" />
              </label>
              <SipcoDropdown
                value={stockPlayaDestModel.columna}
                options={columna}
                onChange={(e) =>
                  setStockPlayaDestModel({
                    ...stockPlayaDestModel,
                    columna: e.value,
                  })
                }
                optionLabel="descripcionFull"
                loading={loadingColumna}
                filter
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id="992" alt="Nivel" />
              </label>
              <SipcoDropdown
                value={stockPlayaDestModel.nivel}
                options={nivel}
                onChange={(e) =>
                  setStockPlayaDestModel({
                    ...stockPlayaDestModel,
                    nivel: e.value,
                  })
                }
                optionLabel="descripcionFull"
                loading={loadingNivel}
                filter
              />
            </div>
          </div>
          <div className="form-row">
            <div className="sipco-option">
              <label>
                <LanguageProvider id="889" alt="Fecha de Recepción" />
              </label>
              <SipcoCalendar
                value={model.fechaRecep}
                onChange={(e) => {
                  setModel({
                    ...model,
                    fechaRecep: new Date(e.value),
                  });
                }}
                showButtonBar
                showIcon
                showTime
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id="754" alt="Transportista" />
              </label>
              <SipcoInputText
                value={transportistaValue?.descripcion}
                onChange={(e) =>
                  setModel({ ...model, transportista: e.target.value })
                }
                disabled={true}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="sipco-option">
              <label>
                <LanguageProvider id="16844" alt="Proveedor daños" />
              </label>
              <SipcoDropdown
                value={model.proveedorDanos}
                options={proveedorDanos}
                onChange={(e) =>
                  setModel({ ...model, proveedorDanos: e.value })
                }
                optionLabel="descripcionFull"
                loading={loadingProveedorDanos}
                filter
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id="16874" alt="Proveedor de gancho" />
              </label>
              <SipcoDropdown
                value={model.proveedorGancho}
                options={proveedorGancho}
                onChange={(e) =>
                  setModel({ ...model, proveedorGancho: e.value })
                }
                optionLabel="descripcionFull"
                loading={loadingProveedorGancho}
                filter
              />
            </div>
          </div>
          <div className="form-row">
            <div className="sipco-option">
              <label>
                <LanguageProvider id="16875" alt="Compra flete" />
              </label>
              <Checkbox
                checked={!!filter?.hojaRuta}
                onChange={(e) =>
                  setModel({
                    ...model,
                    compraFlete: !model?.compraFlete,
                  })
                }
                disabled={true}
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id="16876" alt="Venta flete" />
              </label>
              <Checkbox
                checked={!!filter?.hojaRuta}
                onChange={(e) =>
                  setModel({
                    ...model,
                    ventaFlete: !model?.ventaFlete,
                  })
                }
                disabled={true}
              />
            </div>
          </div>
        </div>
      </Panel>
      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "34",
            alt: "Procesar o guardar cambios",
          })}
          icon="pi pi-save"
          onClick={procesarClick}
        />
      </div>
      <div className="dialogs">
        {/* Debe seleccionar al menos un registro de la Grilla. Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validDatosGrillaDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="753"
              alt="Debe seleccionar al menos un registro de la Grilla."
            />
          </p>
        </Dialog>
        {/* valid vin Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validVinFieldDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="821"
              alt="El VIN solicitado no se encuentra en Ubicación de Traslado."
            />
          </p>
        </Dialog>
        {/* valid quant vin Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validQuantDatosdDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="653"
              alt="Debe ingresar un minimo de 8 y un maximo de 17 dígitos"
            />
          </p>
        </Dialog>
        {/* Existen datos en blanco. Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validDatosdDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider id="39" alt="Existen datos en blanco." />
          </p>
        </Dialog>
        {/* La fecha de Recepción no es correcta. Debe ser superior a Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validFRecepSupDialog}
          style={{ width: "12vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="25235"
              alt="La fecha de Recepción no es correcta. Debe ser superior a"
            />{" "}
            {new Date(
              new Date().setDate(new Date().getDate() - 1)
            ).toLocaleDateString()}
          </p>
        </Dialog>
        {/* La fecha de Recepción no es correcta. Debe ser menor a Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validFRecepInfDialog}
          style={{ width: "12vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="25236"
              alt="La fecha de Recepción no es correcta. Debe ser menor a"
            />{" "}
            {new Date().toLocaleDateString()}
          </p>
        </Dialog>
        {/* ¿Desea procesar los cambios realizados? Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={recepSiNoDialog}
          style={{ width: "12vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("10043", "Si")}
                icon="pi pi-check"
                onClick={() => handleFechaRecepcion(true)}
                autoFocus
                disabled={loadingVFRecepDialog}
              />
              <Button
                label={getLabel("10044", "No")}
                icon="pi pi-times"
                onClick={() => handleFechaRecepcion(false)}
                className="p-button-text"
                disabled={loadingVFRecepDialog}
              />
            </div>
          }
          onHide={() => setRecepSiNoDialog(false)}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="13275"
              alt="¿Desea procesar los cambios realizados?"
            />
          </p>
          {loadingVFRecepDialog && (
            <ProgressSpinner style={{ width: "50px", height: "50px" }} />
          )}
        </Dialog>
        {/* Su transacción se realizó con éxito Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={sucessFechaRecepDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="36"
              alt="Su transacción se realizó con éxito."
            />
          </p>
        </Dialog>
      </div>
    </div>
  );
}
export default RecepcionPlayaForm;

const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
