import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { HttpStatusCode } from "axios";
import { set } from "date-fns";

enum StatusTipo {
  UNDEFINED,
  BUQUE,
  CAMION,
}

export function LlegadaBuqueCamionForm(props) {
  usePageViews();
  const toast = useRef<Toast>(null);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const { SCREEN_CODE } = props;
  const data = convertDateObjects(reporte?.filas);
  const [selectedRow, setSelectedRow] = useState(null);

  const dt = useRef(null);
  const [switchTipo, setSwitchTipo] = useState<StatusTipo>(StatusTipo.UNDEFINED);

  const columnsIds1 = [1491, 10830, 396, 2139, 2140, 1736, 13489, 13490];

  const columnsNames1 = filterColumnsIds(columnsIds1);

  function filterColumnsIds(ids) {
    const result = [];
    for (const element of ids) {
      result.push({
        id: element,
        label: LanguageProvider({
          id: element,
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  const columnsName = [
    { field: "vin.id", header: "VIN" },
    { field: "transporte", header: "Transp." },
    { field: "vin.marca.descripcion", header: "Marca" },
    { field: "vin.modelo.descripcion", header: "Modelo" },
    { field: "vin.color.descripcion", header: "Color" },
    { field: "fechaLLegada.date", header: "Fecha de Llegada" },
    { field: "usuarioUltimaModificacion.id", header: "Actualizó Usuario" },
    { field: "fechaUltimaModificacion.date", header: "Actualizó Fecha" },
  ]



  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({
    calle: null,
    columna: null,
    nivel: null,
    tipoTransporte: null,
    vin: null,
    hojaRuta: null,
    ciudad: null,
    terminal: null,
    despachante: null,
    proveedorDanios: null,
    puntoRetiro: null,
    transportista: null,
    tractor: null,
    batea: null,
    chofer: null,
    transporte: null,
    armador: null,
    dataRecepcao: new Date(),
    cantidadUnidades: null,
    marca: null,
    modelo: null,
    cobraFleteMaritimo: false,
    proveedorGancho: null,


    fildeModal: null
  });

  // Grid Brazil - Verificar 
  async function loadGrid() {
    setLoading(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/llegada-buque-camion/importado-form",
        {
          pais: securityFilters.securityValues.country,
          cuenta: securityFilters.securityValues.account,
          cliente: securityFilters.securityValues.client,
          subcuenta: securityFilters.securityValues.subaccount,
          vin: filter?.vin ?? null,
          marca: filter?.marca?.id === null ? null : filter?.marca,
          modelo: filter?.modelo?.id === null ? null : filter?.modelo,
          hojaRuta: filter?.hojaRuta ?? null,
          hayTerrestre: switchTipo === StatusTipo.CAMION ? true : false,
        }
      );

      if (status === HttpStatusCode.Ok) setReporte(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }


  // Ajustar a requisição no Back-End - VIN

  const [vin, setVin] = useState([]);
  async function seachVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    try {
      if (
        securityFilters?.securityValues?.country !== null &&
        securityFilters?.securityValues?.client !== null &&
        securityFilters?.securityValues?.account !== null &&
        query
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: { id: query }
          }
        );
        if (status === HttpStatusCode.Ok) {
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Ajustar a requisição no Back-End - Hoja Ruta
  const [hojaRuta, setHojaRuta] = useState([]);
  const [loadingHojaRuta, setLoadingHojaRuta] = useState(false);
  async function loadHojaRuta(value: any) {
    try {
      if (
        securityFilters?.securityValues?.country !== null &&
        securityFilters?.securityValues?.client !== null &&
        securityFilters?.securityValues?.account !== null &&
        securityFilters?.securityValues?.subaccount !== null &&
        value
      ) {
        setLoadingHojaRuta(true);
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/hoja-ruta",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            vin: { id: value }
          }
        );
        if (status === HttpStatusCode.Ok) {
          setHojaRuta(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHojaRuta(false);
    }
  }

  const [tipoTransporte, setTipoTransporte] = useState([]);
  const [loadingTipoTransporte, setLoadingTipoTransporte] = useState(false);
  async function loadTipoTransporte() {
    try {
      if (
        securityFilters?.securityValues?.country !== null
      ) {
        setLoadingTipoTransporte(true);
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/tipo-transporte",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setTipoTransporte(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoTransporte(false);
    }
  }

  const [calle, setCalle] = useState([]);
  const [loadingCalle, setLoadingCalle] = useState(false);
  async function loadCalle() {
    try {
      if (
        securityFilters?.securityValues?.country !== null &&
        securityFilters?.securityValues?.client !== null &&
        securityFilters?.securityValues?.account !== null &&
        securityFilters?.securityValues?.subaccount !== null
      ) {
        setLoadingCalle(true);
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/calle-ar",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            cliente: securityFilters.securityValues.client,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setCalle(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalle(false);
    }
  }

  const [columna, setColumna] = useState([]);
  const [loadingColumna, setLoadingColumna] = useState(false);
  async function loadColumna(calle: any) {
    try {
      if (
        securityFilters?.securityValues?.country !== null &&
        securityFilters?.securityValues?.client !== null &&
        securityFilters?.securityValues?.account !== null &&
        securityFilters?.securityValues?.subaccount !== null &&
        calle !== null
      ) {
        setLoadingColumna(true);
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/columna-ar",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            cliente: securityFilters.securityValues.client,
            subcuenta: securityFilters.securityValues.subaccount,
            calle: calle,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
  }
  const [nivel, setNivel] = useState([]);
  const [loadingNivel, setLoadingNivel] = useState(false);
  async function loadNivel(calle: any, columna: any) {
    try {
      if (
        securityFilters?.securityValues?.country !== null &&
        securityFilters?.securityValues?.client !== null &&
        securityFilters?.securityValues?.account !== null &&
        securityFilters?.securityValues?.subaccount !== null &&
        calle !== null &&
        columna !== null

      ) {
        setLoadingNivel(true);
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/nivel-ar",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            cliente: securityFilters.securityValues.client,
            subcuenta: securityFilters.securityValues.subaccount,
            calle,
            columna,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
  }

  const [ciudad, setCiudad] = useState([]);
  const [loadingCiudad, setLoadingCiudad] = useState(false);
  async function loadCiudad() {
    setLoadingCiudad(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/llegada-buque-camion/ciudad",
        {}
      );
      if (status === HttpStatusCode.Ok) {
        setCiudad(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCiudad(false);
    }
    return [];
  }

  const [terminais, setTerminais] = useState([]);
  const [loadingTerminais, setLoadingTerminais] = useState(false);
  async function loadTerminal() {
    setLoadingTerminais(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/proveedor",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          setTerminais(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTerminais(false);
    }
    return [];
  }

  const [despachantes, setDespachantes] = useState([]);
  const [loadingDespachantes, setLoadingDespachantes] = useState(false);
  async function loadDespachantes() {
    setLoadingDespachantes(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/despachante",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === 200) {
          setDespachantes(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDespachantes(false);
    }
    return [];
  }
  const [proveedorDanios, setProveedorDanios] = useState([]);
  const [loadingProvedorDanios, setLoadingProvedorDanios] = useState(false);

  async function loadDanios() {
    setLoadingProvedorDanios(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/danios",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === 200) {
          setProveedorDanios(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProvedorDanios(false);
    }
    return [];
  }
  const [puntosRetiro, setPuntosRetiro] = useState([]);
  const [loadingPuntosRetiro, setLoadingPuntosRetiro] = useState(false);
  async function loadPuntosRetiro() {
    setLoadingPuntosRetiro(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/contable-punto",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setPuntosRetiro(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPuntosRetiro(false);
    }
    return [];
  }

  const [transportistas, setTransportistas] = useState([]);
  const [loadingTransportistas, setLoadingTransportistas] = useState(false);
  async function loadTransportistas() {
    setLoadingTransportistas(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/transportista",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setTransportistas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransportistas(false);
    }
    return [];
  }

  const [tractores, setTractores] = useState([]);
  const [loadingTractores, setLoadingTractores] = useState(false);
  async function loadTractores(transportista: any) {
    setLoadingTractores(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        transportista != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/tractores",
          {
            pais: securityFilters.securityValues.country,
            transportista: transportista,
          }
        );
        if (status === 200) {
          setTractores(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTractores(false);
    }
    return [];
  }

  const [bateas, setBateas] = useState([]);
  const [loadingBateas, setLoadingBateas] = useState(false);
  async function loadBateas(transportista: any, tractor: any) {
    setLoadingBateas(true);
    try {
      if (transportista != null && tractor != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/remolque-br",
          {
            transportista: transportista,
            tractor: tractor,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setBateas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBateas(false);
    }
  }

  const [choferes, setChoferes] = useState([]);
  const [loadingChoferes, setLoadingChoferes] = useState(false);
  async function loadChoferes(transportista: any) {
    setLoadingChoferes(true);
    try {
      if (transportista != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/chofer-br",
          {
            transportista: transportista,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setChoferes(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChoferes(false);
    }
    return [];
  }

  const [transportes, setTransportes] = useState([]);
  const [loadingTransportes, setLoadingTransportes] = useState(false);
  async function loadTransportes(tipoTransporte: any) {
    setLoadingTransportes(true);
    try {
      if (
        tipoTransporte != null &&
        securityFilters?.securityValues?.country != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/transporte-playa-br",
          {
            pais: securityFilters.securityValues.country,
            tipoTransporte: tipoTransporte,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setTransportes(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransportes(false);
    }
    return [];
  }

  const [armadores, setArmadores] = useState([]);
  const [loadingArmadores, setLoadingArmadores] = useState(false);
  async function loadArmadores() {
    setLoadingArmadores(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/armador",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setArmadores(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingArmadores(false);
    }

  }

  const [marcas, setMarcas] = useState([]);
  const [loadingMarcas, setLoadingMarcas] = useState(false);

  async function loadMarca() {
    setLoadingMarcas(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/marca",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: null, descripcion: "TODOS" });
          setMarcas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
  }

  const [modelos, setModelos] = useState([]);
  const [loadingModelos, setLoadingModelos] = useState(false);

  async function loadModelos(marca: any) {
    setLoadingModelos(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/modelo",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: null, descripcion: "TODOS" });
          setModelos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
  }

  // Handles Functions 
  async function handleCalles(calle: any) {
    const columna = await loadColumna(calle);
    setFilter({ ...filter, columna, calle });
  }

  async function handleColumna(columna: any) {
    const nivel = await loadNivel(filter.calle, columna);
    setFilter({ ...filter, columna, nivel });
  }

  async function handleVin(vin: any) {
    const hojaRuta = await loadHojaRuta(vin);
    setFilter({ ...filter, vin, hojaRuta });
  }

  async function handleTransportistas(transportista: any) {
    const [tractor, chofer] = await Promise.all([
      loadTractores(transportista),
      loadChoferes(transportista),
    ]);

    setFilter({ ...filter, transportista, chofer, tractor });
  }

  async function handleTipoTransporte(tipoTransporte: any) {
    if (tipoTransporte.descripcion === "Buque") setSwitchTipo(StatusTipo.BUQUE);
    if (tipoTransporte.descripcion === "Camion") setSwitchTipo(StatusTipo.CAMION);
    setFilter({ ...filter, tipoTransporte });
  }

  async function handleMarca(marca: any) {
    const modelo = await loadModelos(marca);
    setFilter({ ...filter, marca, modelo });
  }

  async function procesar() {
    try {
      if (selectedRow) {
        const { status, data } = await sipcoAxiosService.post(
          "/llegada-buque-camion/llegada-buque-camion-ar",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            items: [selectedRow],
            importado: selectedRow ?? null,
            transporte: filter.transporte ?? null,
            transportista: filter.transportista ?? null,
            despachante: filter.despachante ?? null,
            terminal: filter.terminal ?? null,
            armador: filter.armador ?? null,
            puntoRetiro: filter.puntoRetiro ?? null,
            fecha: new Date(filter.dataRecepcao).getTime() ?? null,
          }
        );
        if (status === HttpStatusCode.Ok) {
          toast.current?.show({
            severity: "success",
            summary: "Sucesso",
            detail: "Procesar",
            life: 3000,
          });
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }


  // Realod´s   
  useEffect(() => {
    const init = async () => {
      try {
        if (securityFilters.securityValues.country !== null
          && securityFilters.securityValues.client !== null
          && securityFilters.securityValues.account !== null
          && securityFilters.securityValues.subaccount !== null
        ) {
          const [calle, tipoTransporte, terminal, despachante,
            ciudad, proveedorDanios, puntoRetiro, transportista, armador] = await Promise.all([
              loadCalle(),
              loadTipoTransporte(),
              loadTerminal(),
              loadDespachantes(),
              loadCiudad(),
              loadDanios(),
              loadPuntosRetiro(),
              loadTransportistas(),
              loadArmadores(),
            ]);

          setFilter({
            ...filter,
            calle,
            ciudad,
            terminal,
            tipoTransporte,
            despachante,
            proveedorDanios,
            puntoRetiro,
            transportista,
            armador
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    init();
  }, [securityFilters.securityValues]);

  useEffect(() => {
    const init = async () => {
      try {
        if (switchTipo === StatusTipo.BUQUE) {
          const [marca] = await Promise.all([
            loadMarca(),
          ]);
          setFilter({ ...filter, marca });
        } else {
          return;
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [switchTipo]);

  useEffect(() => {
    try {
      const init = async () => {
        if (filter.transportista && filter.tractor) {
          const batea = await loadBateas(filter.transportista, filter.tractor);
          setFilter({ ...filter, batea });
        }
      };

      init();
    } catch (error) {
      console.error(error);
    }
  }, [filter.transportista, filter.tractor]);



  return (
    <div className="llegada-buque-camion">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadGrid}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="sipco-option" style={{ marginTop: "1.5em" }}>
                <SipcoDropdown
                  value={filter.calle}
                  onChange={(e) => handleCalles(e.target.value)}
                  options={calle}
                  loading={loadingCalle}
                />
              </div>
              <div
                className="sipco-option"
                style={{ marginTop: "1.5em" }}
              >
                <SipcoDropdown
                  value={filter.columna}
                  onChange={(e) => handleColumna(e.target.value)}
                  options={columna}
                  loading={loadingColumna}
                />
              </div>
              <div
                className="sipco-option"
                style={{ marginTop: "1.5em" }}
              >

                <SipcoDropdown
                  value={filter.nivel}
                  options={nivel}
                  loading={loadingNivel}
                  onChange={(e) => setFilter({ ...filter, nivel: e.target.value })}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"493"} alt="Tipo Transporte" />
                </label>
                <SipcoDropdown
                  value={filter.tipoTransporte}
                  options={tipoTransporte}
                  onChange={(e: any) => handleTipoTransporte(e.target.value)}
                  loading={loadingTipoTransporte}
                  optionLabel="descripcion"
                />
              </div>
            </div>
          </div>
        </AccordionTab>

      </Filter>

      <Fieldset
        legend={
          <LanguageProvider id={"1052"} alt="Chegada de Navio ou Caminhão" />
        }
      >
        <div className="form">
          <div className="form-row">
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"662"} alt="Ingrese el VIN" />
              </label>
              <SipcoAutocomplete
                value={filter.vin}
                suggestions={vin}
                onChange={(e) => handleVin(e.target.value)}
                completeMethod={seachVin}
                field="id"
                dropdown
              />
            </div>
            {
              switchTipo === StatusTipo.BUQUE ?
                (
                  <>
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider id={"489"} alt="Marcas" />
                      </label>
                      <SipcoDropdown
                        value={filter.marca}
                        options={marcas}
                        loading={loadingMarcas}
                        onChange={(e: any) => handleMarca(e.target.value)}
                        optionLabel="descripcion"
                      />
                    </div>

                    <div className="sipco-option">
                      <label>
                        <LanguageProvider id={"5595"} alt="Modelos" />
                      </label>
                      <SipcoDropdown
                        value={filter.modelo}
                        options={modelos}
                        loading={loadingModelos}
                        onChange={(e) => setFilter({ ...filter, modelo: e.target.value })}
                        optionLabel="descripcion"
                      />
                    </div>

                  </>
                )
                :
                (
                  <>
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider id={"924"} alt="Hoja de Ruta" />
                      </label>
                      <SipcoDropdown
                        value={filter.hojaRuta}
                        options={hojaRuta}
                        loading={loadingHojaRuta}
                        onChange={(e) => setFilter({ ...filter, hojaRuta: e.target.value })}
                        optionLabel="descripcion"
                      />
                    </div>
                  </>
                )
            }

          </div>
          <SipcoDataTable
            size={"small"}
            header={
              <div className="header-data-table">
                <div className="chips">
                  <div className={"sipco-chip"}>
                    <label>
                      <LanguageProvider
                        id={"864"}
                        alt="Registros Selecionados"
                      />
                    </label>
                    <Chip label="0" />
                  </div>
                  <div className={"sipco-chip"}>
                    <label>
                      <LanguageProvider id={"TODO"} alt="Total de Registros" />
                    </label>
                    <Chip label="0" />
                  </div>
                </div>

                <div
                  className="action"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <ExportButtons
                    dt={dt}
                    data={data}
                    columns={columnsIds1}
                    screenName={"RecepcionPlaya"}
                  />
                </div>
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={reporte}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
            selectionMode="single"
            onRowSelect={(e) => {
              setSelectedRow(e.data);
            }}
            selection={selectedRow}
          >
            {columnsName.map((colum, index) => (
              <SipcoColumn
                key={index}
                field={colum.field}
                header={colum.header}
                sortable
                filter
              />
            ))}
          </SipcoDataTable>
        </div>

        <Divider />


        <Accordion>
          <AccordionTab
            header={
              <LanguageProvider
                id={"884"}
                alt="entrada de Dados para a Recepção"
              />
            }
          >
            <div className="form">
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"889"} alt="Data de Recepção" />
                  </label>
                  <SipcoCalendar
                    value={filter.dataRecepcao}
                    onChange={(e) => setFilter({ ...filter, dataRecepcao: e.value })}
                    showButtonBar
                    showIcon
                    showTime />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"2496"} alt="Origem" />
                  </label>
                  <SipcoDropdown
                    value={filter.ciudad}
                    options={ciudad}
                    onChange={(e) => setFilter({ ...filter, ciudad: e.target.value })}
                    loading={loadingCiudad}
                    optionLabel="descripcion"
                  />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1119"} alt="Terminais" />
                  </label>
                  <SipcoDropdown
                    value={filter.terminal}
                    options={terminais}
                    onChange={(e) => setFilter({ ...filter, terminal: e.target.value })}
                    loading={loadingTerminais}
                    optionLabel="descripcion"
                  />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1118"} alt="Despachantes" />
                  </label>
                  <SipcoDropdown
                    value={filter.despachante}
                    options={despachantes}
                    onChange={(e) => setFilter({ ...filter, despachante: e.target.value })}
                    loading={loadingDespachantes}
                    optionLabel="descripcion"
                  />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"16844"} alt="Fornecedor danhos" />
                  </label>
                  <SipcoDropdown
                    value={filter.proveedorDanios}
                    options={proveedorDanios}
                    onChange={(e) => setFilter({ ...filter, proveedorDanios: e.target.value })}
                    loading={loadingProvedorDanios}
                    optionLabel="descripcion"
                  />
                </div>
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1373"} alt="Pontos de Retiro" />
                  </label>
                  <SipcoDropdown
                    value={filter.puntoRetiro}
                    options={puntosRetiro}
                    onChange={(e) => setFilter({ ...filter, puntoRetiro: e.target.value })}
                    loading={loadingPuntosRetiro}
                    optionLabel="descripcion"
                  />
                </div>
              </div>
              {
                switchTipo === StatusTipo.CAMION ?
                  (<>
                    <div className="form-row">
                      <div className="sipco-option">
                        <label>
                          <LanguageProvider id={"754"} alt="Transportista" />
                        </label>
                        <SipcoDropdown
                          value={filter.transportista}
                          options={transportistas}
                          onChange={(e) => handleTransportistas(e.target.value)}
                          loading={loadingTransportistas}
                          optionLabel="descripcion"

                        />
                      </div>
                      <div className="sipco-option">
                        <label>
                          <LanguageProvider id={"4285"} alt="Trator" />
                        </label>
                        <SipcoDropdown
                          value={filter.tractor}
                          options={tractores}
                          onChange={(e) => setFilter({ ...filter, tractor: e.target.value })}
                          loading={loadingTractores}
                          optionLabel="descripcion"
                        />
                      </div>
                      <div className="sipco-option">
                        <label>
                          <LanguageProvider id={"4284"} alt="Batea / Transportín" />
                        </label>
                        <SipcoDropdown
                          value={filter.batea}
                          options={bateas}
                          onChange={(e) => setFilter({ ...filter, batea: e.target.value })}
                          loading={loadingBateas}
                          optionLabel="descripcion"
                        />
                      </div>
                      <div className="sipco-option">
                        <label>
                          <LanguageProvider id={"4655"} alt="Chofer" />
                        </label>
                        <SipcoDropdown
                          value={filter.chofer}
                          options={choferes}
                          onChange={(e) => setFilter({ ...filter, chofer: e.target.value })}
                          loading={loadingChoferes}
                          optionLabel="descripcion"
                        />
                      </div>
                      <div className="sipco-option">
                        <label>
                          <LanguageProvider
                            id={"8811"}
                            alt="Quantidade de Unidades"
                          />
                        </label>
                        <SipcoInputText
                          value={filter.cantidadUnidades}
                          onChange={(e) => setFilter({ ...filter, cantidadUnidades: e.target.value })}
                          readOnly
                        />
                      </div>
                    </div>
                  </>
                  )
                  :
                  (
                    <>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id={"23814"} alt="Transporte" />
                          </label>
                          <SipcoDropdown
                            value={filter.transporte}
                            options={transportes}
                            onChange={(e) => setFilter({ ...filter, transporte: e.target.value })}
                            loading={loadingTransportes}
                            optionLabel="descripcion"

                          />
                        </div>
                        <div className="sipco-option">
                          <div className="form-row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <label>
                              <LanguageProvider id={"1371"} alt="¿Cobra Flete Maritimo?" />
                            </label>
                            <Checkbox
                              checked={filter.cobraFleteMaritimo}
                              onChange={(e) => setFilter({ ...filter, cobraFleteMaritimo: e.checked })}
                            />
                          </div>
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id={"1120"} alt="Armadores" />
                          </label>
                          <SipcoDropdown
                            value={filter.armador}
                            options={armadores}
                            onChange={(e) => setFilter({ ...filter, armador: e.target.value })}
                            loading={loadingArmadores}
                            optionLabel="descripcion"
                          />
                        </div>
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider
                              id={"16874"}
                              alt="Proveedor de gancho"
                            />
                          </label>
                          <SipcoDropdown
                            value={filter.proveedorGancho}
                            options={puntosRetiro}
                            onChange={(e) => setFilter({ ...filter, proveedorGancho: e.target.value })}
                            loading={loadingPuntosRetiro}
                            optionLabel="descripcion"
                          />
                        </div>
                      </div>
                    </>
                  )
              }
            </div>
          </AccordionTab>
        </Accordion>
        <div className="form">
          <div>
            <Button
              label={LanguageProvider({
                id: "748",
                alt: "Seleccionar todo",
              })}
              text
              onClick={() => {
                setSelectedRow([...reporte]);
              }}
            />
            <Button
              label={LanguageProvider({
                id: "749",
                alt: "Deseleccionar todo",
              })}
              text
              onClick={() => {
                setSelectedRow([]);
              }}
            />
            <Button
              label={LanguageProvider({
                id: "34",
                alt: "Procesar o guardar cambios",
              })}
              text
              onClick={procesar}
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default LlegadaBuqueCamionForm;
