import "./RemitoElectronico.scss";
import "primeicons/primeicons.css";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { LanguageProviderString } from "@shared/components/language-provider-string";
import useAuth from "@shared/AuthContext";

const dataTableColumns = [
  {
    field: "formated",
    header: <LanguageProvider id="851" alt="x" />,
  },
  {
    field: "estado.descripcion",
    header: <LanguageProvider id="3344" alt="x" />,
  },
  {
    field: "impreso",
    header: <LanguageProvider id="7759" alt="Impreso" />,
    body: (rowData: any) => (rowData.impreso ? "SI" : "NO"),
  },
  {
    field: "numeroComprobanteRentas",
    header: <LanguageProvider id="17058" alt="x" />,
  },
  {
    field: "fechaEmision.date",
    header: <LanguageProvider id="1492" alt="x" />,
    body: (rowData: any) =>
      rowData.fechaEmision ? (
        <span>{new Date(rowData.fechaEmision.date).toLocaleDateString()}</span>
      ) : (
        "-"
      ),
  },
  {
    field: "fechaSalida.date",
    header: <LanguageProvider id="1719" alt="x" />,
    body: (rowData: any) =>
      rowData.fechaSalida ? (
        <span>{new Date(rowData.fechaSalida.date).toLocaleDateString()}</span>
      ) : (
        "-"
      ),
  },
  {
    field: "horaFormateada",
    header: <LanguageProvider id="1731" alt="x" />,
  },
  {
    field: "cliente",
    header: <LanguageProvider id="3750" alt="x" />,
  },
  {
    field: "transportista",
    header: <LanguageProvider id="754" alt="x" />,
  },
  {
    field: "patenteVehiculo",
    header: <LanguageProvider id="17064" alt="x" />,
  },
  {
    field: "patenteAcoplado",
    header: <LanguageProvider id="17065" alt="x" />,
  },
  {
    field: "fechaEnvioRentas.date",
    header: <LanguageProvider id="17059" alt="x" />,
    body: (rowData: any) =>
      rowData.fechaEnvioRentas ? (
        <span>{new Date(rowData.fechaEnvioRentas.date).toLocaleString()}</span>
      ) : (
        "-"
      ),
  },
  {
    field: "errorRentas",
    header: <LanguageProvider id="17060" alt="x" />,
  },
];

const TODOS_OBJ = {
  id: "TODOS",
  descripcionFull: "TODOS",
  descripcion: "TODOS",
  label: "TODOS",
  search: "TODOS",
};

export function RemitoElectronico() {
  const SCREEN_CODE = "fun0101_remito_electronico";
  const URL_BASE = "/remito-eletronico";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const { user } = useAuth();
  usePageViews();
  const toast = useRef<Toast>(null);
  
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState<any>({
    cmbSucursal: null,
    fechaEmisionDesde: { date: new Date() },
    fechaEmisionHasta: { date: new Date() },
    fechaSalidaDesde: { date: new Date() },
    fechaSalidaHasta: { date: new Date() },
    impreso: {},
    item: "",
    estado: {},
    errorRentas: {},
  });
  const [dataTableValue, setDataTableValue] = useState([]);

  const [sucursalOptionsFilter, setSucursalOptionsFilter] = useState<any[]>([]);
  const [loadingSucursalOptionsFilter, setLoadingSucursalOptionsFilter] =
    useState(false);
  async function loadSucursal() {
    if (securityFilters.securityValues.country) {
      try {
        setLoadingSucursalOptionsFilter(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/sucursal",
          {
            pais: securityFilters.securityValues.country,
            // cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            setSucursalOptionsFilter(updatedData);
            return updatedData;
          } else {
            toast.current?.show({
              severity: "warn",
              detail: LanguageProviderString({
                id: "9834",
                alt: "No tiene Sucursales habilitadas, no podrá utilizar esta función",
                user: user,
              }),
              life: 3000,
            });
            return [];
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingSucursalOptionsFilter(false);
      }
    }
  }

  const [estadoOptionsFilter, setEstadoOptionsFilter] = useState<any[]>([]);
  const [loadingEstadoOptionsFilter, setLoadingEstadoOptionsFilter] =
    useState(false);

  async function loadEstado() {
    if (securityFilters.securityValues.country) {
      try {
        setLoadingEstadoOptionsFilter(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/estado-remito-electronico",
          {
            pais: securityFilters.securityValues.country,
            // cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            updatedData.unshift(TODOS_OBJ);
            setEstadoOptionsFilter(updatedData);
            return updatedData;
          } else {
            return [];
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingEstadoOptionsFilter(false);
      }
    }
  }

  const [errorRentasOptionsFilter, setErrorRentasOptionsFilter] = useState<
    any[]
  >([]);
  const [loadingErrorRentasOptionsFilter, setLoadingErrorRentasOptionsFilter] =
    useState(false);
  const [impresoOptionsFilter, setImpresoOptionsFilter] = useState<any[]>([]);
  const [loadingImpresoOptionsFilter, setLoadingImpresoOptionsFilter] =
    useState(false);
  async function findSiNoBy() {
    if (securityFilters.securityValues.country) {
      try {
        setLoadingErrorRentasOptionsFilter(true);
        setLoadingImpresoOptionsFilter(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find-si-no-by",
          {
            pais: securityFilters.securityValues.country,
            // cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            updatedData.unshift(TODOS_OBJ);
            setErrorRentasOptionsFilter(updatedData);
            setImpresoOptionsFilter(updatedData);
            return updatedData;
          } else {
            return [];
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingErrorRentasOptionsFilter(false);
        setLoadingImpresoOptionsFilter(false);
      }
    }
  }

  useEffect(() => {
    async function initialize() {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.country
      ) {
        try {
          const [sucursal, estado, siNo] = await Promise.all([
            loadSucursal(),
            loadEstado(),
            findSiNoBy(),
          ]);
          setFilters({
            ...filters,
            cmbSucursal: sucursal[0],
            estado: estado[0],
            errorRentas: siNo[0],
            impreso: siNo[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues]);

  // Botão para buscar fica desabilitado caso não ajam Sucursales habilitadas, 
  // funcionalidade não implementada pois não a dados no banco para testes do desenvolvedor - Lucas Silvestre - 18/03/2025
  
  return (
    <div className="remito-electronico">
      <Toast ref={toast} position="bottom-left" />
      <Filter
        onSearch={null}
        securityOptions={{
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"7795"} alt="Filtrar Por:" />}
        >
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"6176"} alt="Sucursal" />
                </label>
                <SipcoDropdown
                  value={filters?.cmbSucursal}
                  options={sucursalOptionsFilter}
                  onChange={(e) =>
                    setFilters({ ...filters, cmbSucursal: e.value })
                  }
                  optionLabel="descripcion"
                  loading={loadingSucursalOptionsFilter}
                  filter
                  filterBy="search"
                  itemTemplate={DropdownOptionTemplate}
                  valueTemplate={DropdownOptionTemplate}
                  placeholder={LanguageProvider({
                    id: "6176",
                    alt: "Sucursal",
                  })}
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"8759"} alt="Fecha Emisión" />
                </label>
                <SipcoCalendar
                  value={filters.fechaEmisionDesde.date}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      fechaEmisionDesde: { date: e.value },
                    });
                  }}
                  showIcon
                />
              </div>

              <div className="sipco-option" style={{ marginTop: "1.2rem" }}>
                <label></label>
                <SipcoCalendar
                  value={filters.fechaEmisionHasta.date}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      fechaEmisionHasta: { date: e.value },
                    });
                  }}
                  showIcon
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"1719"} alt="Fecha Salida" />
                </label>
                <SipcoCalendar
                  value={filters.fechaSalidaDesde.date}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      fechaSalidaDesde: { date: e.value },
                    });
                  }}
                  showIcon
                />
              </div>

              <div className="sipco-option" style={{ marginTop: "1.2rem" }}>
                <label></label>
                <SipcoCalendar
                  value={filters.fechaSalidaHasta.date}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      fechaSalidaHasta: { date: e.value },
                    });
                  }}
                  showIcon
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"5007"} alt="Item" />
                </label>
                <SipcoInputText
                  value={filters?.item}
                  onChange={(e) =>
                    setFilters({ ...filters, item: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"1151"} alt="Estados" />
                </label>
                <SipcoDropdown
                  value={filters?.estado}
                  options={estadoOptionsFilter}
                  onChange={(e) => setFilters({ ...filters, estado: e.value })}
                  optionLabel="descripcion"
                  loading={loadingEstadoOptionsFilter}
                  filter
                  filterBy="search"
                  itemTemplate={DropdownOptionTemplate}
                  valueTemplate={DropdownOptionTemplate}
                  placeholder={LanguageProvider({
                    id: "1151",
                    alt: "Estados",
                  })}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"17046"} alt="Error Rentas" />
                </label>
                <SipcoDropdown
                  value={filters?.errorRentas}
                  options={errorRentasOptionsFilter}
                  onChange={(e) =>
                    setFilters({ ...filters, errorRentas: e.value })
                  }
                  optionLabel="descripcion"
                  loading={loadingErrorRentasOptionsFilter}
                  filter
                  filterBy="search"
                  itemTemplate={DropdownOptionTemplate}
                  valueTemplate={DropdownOptionTemplate}
                  placeholder={LanguageProvider({
                    id: "17046",
                    alt: "Error Rentas",
                  })}
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"7759"} alt="Impreso" />
                </label>
                <SipcoDropdown
                  value={filters?.impreso}
                  options={impresoOptionsFilter}
                  onChange={(e) => setFilters({ ...filters, impreso: e.value })}
                  optionLabel="descripcion"
                  loading={loadingImpresoOptionsFilter}
                  filter
                  filterBy="search"
                  itemTemplate={DropdownOptionTemplate}
                  valueTemplate={DropdownOptionTemplate}
                  placeholder={LanguageProvider({
                    id: "7759",
                    alt: "Impreso",
                  })}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset legend={<LanguageProvider id={"6697"} alt="Listado" />}>
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          value={dataTableValue}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {dataTableColumns.map((dataTableColumn, key) => (
            <SipcoColumn
              key={key}
              field={dataTableColumn.field}
              header={dataTableColumn.header}
              body={dataTableColumn.body}
              filter
              sortable
            />
          ))}
        </SipcoDataTable>
        <Divider />

        <div className="form" style={{ marginBottom: "2rem" }}>
          <div className="form-row">
            <div className="sipco-option" style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id={"6176"} alt="Sucursal" />
              </label>
              <Chip label="0" />
            </div>
            <div className="sipco-option">
              <div className="tags-sipco">
                <Tag
                  value={
                    <LanguageProvider id={"10737"} alt="Errores de Envío" />
                  }
                />
                <Tag
                  style={{ background: "black" }}
                  value={
                    <LanguageProvider
                      id={"TODO"}
                      alt="Generado Automaticamente"
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Button
          label={LanguageProvider({
            id: "17358",
            alt: "Origen/Destino",
          })}
          text
        />
        <Button
          label={LanguageProvider({
            id: "17492",
            alt: "Web",
          })}
          text
        />
        <Button
          label={LanguageProvider({
            id: "21062",
            alt: "Enviar PDF",
          })}
          text
        />
        <Button
          label={LanguageProvider({
            id: "22220",
            alt: "Imprimir Layout Daños",
          })}
          text
        />
      </Fieldset>
    </div>
  );
}
export default RemitoElectronico;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
