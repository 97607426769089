import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { TopeOperacion } from "src/models/TopeOperacion";

const MantenerTopeOperaciones = () => {
  const SCREEN_CODE = "abm0211_tope_operaciones";
  const URL_BASE = "/mantener-tope-operaciones";
  const SCREEN_TITLE = <LanguageProvider id="19049" alt="Tope Operación" />;
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: TopeOperacion, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues.securityValues.account &&
        filterValues.securityValues.subaccount
      ) {
        const topeOperacion = {
          ...model,
          pais: filterValues.securityValues.country,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          topeOperacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: TopeOperacion, filterValues: any) {
    try {
      const topeOperacion = {
        ...model,
        pais: filterValues.securityValues.country,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        topeOperacion,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: TopeOperacion, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        topeOperacion: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<TopeOperacion[]> {
    if (
      filter?.securityValues?.country &&
      filter.securityValues.account &&
      filter.securityValues.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            pais: filter.securityValues.country,
            cuenta: filter.securityValues.account,
            subcuenta: filter.securityValues.subaccount,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<TopeOperacion> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          topeOperacion: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadOperacion(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/operacion",
          {
            pais: filter.securityValues.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<TopeOperacion>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            account: true,
            subaccount: true,
          },
        }}
        // filterSettings={}
        dataTableColumns={[
          {
            field: "operacion.descripcion",
            header: <LanguageProvider id="18637" alt="x" />,
          },
          {
            field: "cantidadTope",
            header: <LanguageProvider id="19050" alt="x" />,
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => {
              return rowData.activo ? "SI" : "NO";
            },
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: <LanguageProvider id="13489" alt="x" />,
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="2131" alt="x" />,
            body: (rowData: any) => {
              return (
                <span>
                  {new Date(
                    rowData.fechaUltimaModificacion.date
                  ).toLocaleString()}
                </span>
              );
            },
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          cuenta: null,
          subcuenta: null,
          operacion: null,
          cantidadTope: 0,
        }}
        formSettings={[
          {
            field: "operacion",
            label: LanguageProvider({ id: "18637", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "18637", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadOperacion(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate: true
          },
          {
            field: "cantidadTope",
            label: LanguageProvider({ id: "16317", alt: "x" }),
            type: "inputNumber",
            max: 9999,
            useGrouping: false,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default MantenerTopeOperaciones;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
