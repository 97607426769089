import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useState } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { FuncionRutina } from "src/models/FuncionRutina";

const MantenerFuncionRutina = () => {
  const SCREEN_CODE = "abm0077_mantener_funcion_rutina";
  const URL_BASE = "/mantener-funcion-rutina";
  const sipcoAxiosService = useSipcoAxiosService();
  const [contableFunTipoContable, setContableFunTipoContable] = useState(null);

  async function handleSave(model: FuncionRutina, filterValues: any) {
    try {
      if (filterValues?.sistema) {
        await sipcoAxiosService.post(URL_BASE + "/save", {
          contableFuncionRutina: {
            ...model,
            sistema: filterValues.sistema,
            funcion: filterValues.funcion,
            contableFuncionTipoContable: contableFunTipoContable,
          },
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: FuncionRutina, filterValues: any) {
    try {
      const contableFuncionRutina = {
        ...model,
        pais: filterValues.securityValues.country,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        contableFuncionRutina: contableFuncionRutina,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: FuncionRutina) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        contableFuncionRutina: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<FuncionRutina[]> {
    try {
      setContableFunTipoContable(filter?.tipoContable);

      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          sistema: filter?.sistema,
          funcion: filter?.funcion,
          tipoContable: filter?.tipoContable,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<FuncionRutina> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          contableFuncionRutina: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadSistema(): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/sistema",
        {}
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadFunciones(filter): Promise<any[]> {
    try {
      if (filter?.sistema) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/funcion",
          {
            sistema: filter?.sistema,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadCodTipoContable(filter): Promise<any[]> {
    try {
      if (filter?.sistema && filter?.funcion) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/contable-funcion-tipo",
          {
            sistema: filter?.sistema,
            funcion: filter?.funcion,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadRutina(filter): Promise<any[]> {
    try {
      if (filter?.sistema) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/contable-rutina-prestacion",
          {
            sistema: filter?.sistema,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<FuncionRutina>
      screenCode={SCREEN_CODE}
      title={
        <LanguageProvider
          id="1418"
          alt="Mantener Función Rutina Contable Por Tipo"
        />
      }
      securityFilterProps={{}}
      filterSettings={[
        {
          filter: "sistema",
          label: LanguageProvider({ id: "4676", alt: "Sistema" }),
          placeholder: LanguageProvider({ id: "4676", alt: "Sistema" }),
          type: "dropdown",
          dataSource: loadSistema,
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          filter: "funcion",
          label: LanguageProvider({ id: "2788", alt: "Funciones" }),
          placeholder: LanguageProvider({ id: "2788", alt: "Funciones" }),
          type: "dropdown",
          dataSource: (filter: any) => loadFunciones(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["sistema"],
        },
        {
          filter: "tipoContable",
          label: LanguageProvider({ id: "16372", alt: "Código tipo contable" }),
          placeholder: LanguageProvider({
            id: "16372",
            alt: "Código tipo contable",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadCodTipoContable(filter),
          dropDownTemplate: DropdownCodTipoContableTemplate,
        },
      ]}
      dataTableColumns={[
        {
          field: "codigoRutinaContable",
          header: <LanguageProvider id="16370" alt="Rutina" />,
        },
        {
          field: "contableFuncionTipoContable.codigoTipoContable",
          header: <LanguageProvider id="16372" alt="Código tipo contable" />,
        },
        {
          field: "sistema.descripcion",
          header: <LanguageProvider id="4676" alt="Sistema" />,
        },
        {
          field: "funcion.descripcion",
          header: <LanguageProvider id="2788" alt="Funciones" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        sistema: null,
        funcion: null,
        tipoContable: null,
        contableFuncionRutina: null,
        activo: true,
      }}
      loadDropdownData={{
        sistema: loadSistema,
        funcion: loadFunciones,
        tipoContable: loadCodTipoContable,
        contableFuncionRutina: loadRutina,
      }}
      formSettings={[
        {
          field: "contableFuncionRutina",
          label: <LanguageProvider id="16370" alt="Rutina" />,
          dropDownPlaceholder: LanguageProvider({
            id: "16370",
            alt: "Rutina",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadRutina(filter),
          //dropDownTemplate: DropdownOptionTemplate,
          required: true,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerFuncionRutina;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div
        className="flex align-items-center"
        style={{ color: option.activo ? "inherit" : "red" }}
      >
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props?.placeholder}</span>;
};

const DropdownCodTipoContableTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div
        className="flex align-items-center"
        style={{ color: option.activo ? "inherit" : "red" }}
      >
        {option.codigoTipoContable} ({option.cliente.id})
      </div>
    );
  return <span>{props?.placeholder}</span>;
};
