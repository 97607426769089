import React from "react";
import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ContableRutinaPrestacion } from "src/models/ContableRutinaPrestacion";

const MantenerContableRutinaPrestacion = () => {
  const SCREEN_CODE = "abm0082_mantener_contable_rutina_prestacion";
  const URL_BASE = "/mantener-contable-rutina-prestacion";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ContableRutinaPrestacion, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const contableRutinaPrestacion = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          descripcion: model.descripcion,
          marca: filterValues.marca,
          active: filterValues.active,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          contableRutinaPrestacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: ContableRutinaPrestacion, filterValues: any) {
    try {
      const contableRutinaPrestacion = {
        ...model,
        sistema: model?.sistema,
        codigoOrigen: model?.codigoOrigen,
        codigoRutina: model?.codigoRutina,
        codigoPrestacion: model?.codigoPrestacion,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        contableRutinaPrestacion,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: ContableRutinaPrestacion, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        contableRutinaPrestacion: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<ContableRutinaPrestacion[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          sistema: filter?.sistema,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<ContableRutinaPrestacion> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          contableRutinaPrestacion: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadSistema(filter: any): Promise<any[]> {
    try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/sistema",
          {
          }
        );
        if (status === 200) {
          return data;
        }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<ContableRutinaPrestacion>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      dataTableColumns={[
        {
          field: "codigoRutina",
          header: <LanguageProvider id="16370" alt="Rutina" />,
        },
        {
          field: "codigoPrestacion",
          header: <LanguageProvider id="22641" alt="Prestación" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        
      
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        sistema: null,
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
        codigoOrigen: '',
        codigoPrestacion: '',
        codigoRutina: '',
        descripcion: '',
      }}
      loadDropdownData={{
        sistema: loadSistema,
      }}
      filterSettings={[
        {
          filter: "sistema",
          label: LanguageProvider({ id: "4676", alt: "Sistema" }),
          placeholder: LanguageProvider({ id: "4676", alt: "Sistema" }),
          type: "dropdown",
          dataSource: (filter:any) => loadSistema(filter),
          dropDownTemplate: DropdownOptionTemplate,
          required: true,
        },
      ]}
      formSettings={[
        {
          field: "codigoRutina",
          label: <LanguageProvider id="16370" alt="Rutina" />,
          type: "inputText",
          disableOnUpdate: true,
          length: 50,
        },
        {
          field: "codigoPrestacion",
          label: <LanguageProvider id="22641" alt="Prestación" />,
          type: "inputText",
          length: 50,
        },
      ]}
      list={handleList}
      get={handleGet}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerContableRutinaPrestacion;


const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};