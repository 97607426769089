import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useState } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { EstadiaParamPlayas } from "src/models/EstadiaParamPlayas";
import useAuth from "@shared/AuthContext";

const MantenerEstadiaParamPlayas = () => {
  const SCREEN_CODE = "abm0084_estadia_param_playas";
  const URL_BASE = "/mantener-estadia-param-playas";
  const sipcoAxiosService = useSipcoAxiosService();
  const [fechaUltimoCierre, setFechaUltimoCierre] = useState([]);
  const { user } = useAuth();

  async function handleSave(model: EstadiaParamPlayas, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const parametroPlayaEstadia = {
          ...model,
          pais: filterValues.securityValues.country,
          cuenta: filterValues.securityValues.account,
          cliente: filterValues.securityValues.client,
          subcuenta: filterValues.securityValues.subaccount,
          fechaUltimoCierre: fechaUltimoCierre,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          parametroPlayaEstadia: parametroPlayaEstadia,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: EstadiaParamPlayas, filterValues: any) {
    try {
      const parametroPlayaEstadia = {
        ...model,
        pais: filterValues.securityValues.country,
        cuenta: filterValues.securityValues.account,
        cliente: filterValues.securityValues.client,
        subcuenta: filterValues.securityValues.subaccount,
        fechaUltimoCierre: fechaUltimoCierre,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        parametroPlayaEstadia: parametroPlayaEstadia,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: EstadiaParamPlayas) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        parametroPlayaEstadia: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<EstadiaParamPlayas[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter.securityValues.account,
        }
      );
      setFechaUltimoCierre(data[0]?.fechaUltimoCierre);
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<EstadiaParamPlayas> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          parametroPlayaEstadia: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadSubcuenta(filter): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.client &&
        filter?.securityValues?.account
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/subcuenta",
          {
            usuario: {
              ...user,
              idioma: { id: user?.idioma?.codIdioma },
            },
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,
            nivel: user?.nivel,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<EstadiaParamPlayas>
      screenCode={SCREEN_CODE}
      title={
        <LanguageProvider
          id="1433"
          alt="Mantener Parametros de Playa para estadía"
        />
      }
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
        },
      }}
      dataTableColumns={[
        {
          field: "subcuenta.descripcion",
          header: <LanguageProvider id="4791" alt="Subcuenta" />,
        },
        {
          field: "fechaUltimoCierre.date",
          header: <LanguageProvider id="16434" alt="Fecha último cierre" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(rowData.fechaUltimoCierre.date).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "cobraEstadia",
          header: <LanguageProvider id="16407" alt="Cobra Estadía" />,
          body: (rowData: any) => {
            return rowData.cobraEstadia ? "SI" : "NO";
          },
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            );
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        cuenta: null,
        subcuenta: null,
        cobraEstadia: false,
      }}
      loadDropdownData={{ subcuenta: loadSubcuenta }}
      formSettings={[
        {
          field: "tipoDeMensaje",
          label: <LanguageProvider id="4791" alt="Subcuenta" />,
          dropDownPlaceholder: LanguageProvider({
            id: "4791",
            alt: "Subcuenta",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadSubcuenta(filter),
          dropDownTemplate: DropdownOptionTemplate,
          required: true,
        },
        {
          field: "cobraEstadia",
          label: <LanguageProvider id="16407" alt="Cobra Estadía" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerEstadiaParamPlayas;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
