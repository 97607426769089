import GenericAbm, {
  GenericAbmRef,
} from "@shared/components/genericAbm/generic-abm";
import React, { useRef } from "react";

import { Button } from "primereact/button";
import { LanguageProvider } from "@shared/components/language-provider";
import { Tramo } from "src/models/Tramo";
import { useNavigate } from "react-router-dom";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerTramos = () => {
  const SCREEN_CODE = "abm0024_mantener_tramos";
  const URL_BASE = "/mantener-tramos";
  const sipcoAxiosService = useSipcoAxiosService();

  const navigate = useNavigate();

  async function handleSave(model: Tramo, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const tramo = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          tramo,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: Tramo, filterValues: any) {
    try {
      const tramo = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        tramo,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Tramo, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        tramo: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Tramo[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<Tramo> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          tramo: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  const abmRef = useRef<GenericAbmRef<Tramo>>();

  return (
    <GenericAbm<Tramo>
      ref={abmRef}
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="269" alt="Mantener Tramos" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Código" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="Descripcion" />,
        },
        {
          field: "cliente.descripcion",
          header: <LanguageProvider id="54" alt="Cliente" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        activo: true,
        pais: null,
        cliente: null,
        descripcion: "",
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          length: 3,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="504" alt="Tipo Despacho" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      customActions={
        <Button
          label="Redirect to another ABM"
          onClick={() => {
            navigate("/mantener-transacciones", {
              state: abmRef.current.filter,
            });
          }}
        />
      }
    />
  );
};

export default MantenerTramos;
