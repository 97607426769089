import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { UbicacionOperacion } from "src/models/UbicacionOperacion";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerUbicacionesOperaciones = () => {
  const SCREEN_CODE = "abm0116_mantener_ubicaciones_operaciones";
  const URL_BASE = "/mantener-ubicaciones-operaciones";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: UbicacionOperacion, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const ubicacionOperacion = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
          calle: model.calle,
          columna: model.columna,
          nivel: model.nivel,
          activo: model.activo,
          operacionPlaya: model.operacionPlaya,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          ubicacionOperacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: UbicacionOperacion, filterValues: any) {
    try {
      const ubicacionOperacion = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
        calle: model.calle,
        columna: model.columna,
        activo: model.activo,
        nivel: model.nivel,
        operacionPlaya: model.operacionPlaya,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        ubicacionOperacion,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: UbicacionOperacion, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        ubicacionOperacion: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<UbicacionOperacion[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter.securityValues.country,
          cuenta: filter.securityValues.account,
          subcuenta: filter.securityValues.subaccount,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<UbicacionOperacion> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          ubicacionOperacion: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadCalle(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/calles",
          {
            pais: filter?.securityValues?.country,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadColuma(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/columnas",
          {
            pais: filter?.securityValues?.country,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            calle: filter?.calle,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadNivel(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/niveles",
          {
            pais: filter?.securityValues?.country,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            cliente: filter?.securityValues?.client,
            calle: filter?.calle,
            columna: filter?.columna,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadOperacionPlaya(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/operacion",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<UbicacionOperacion>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          account: true,
          client: true,
          subaccount: true,
        },
      }}
      dataTableColumns={[
        { field: "calle", header: <LanguageProvider id="990" alt="Calle" /> },
        {
          field: "columna",
          header: <LanguageProvider id="991" alt="Columna" />,
        },
        {
          field: "nivel",
          header: <LanguageProvider id="992" alt="Nivel" />,
        },
        {
          field: "operacionPlaya.descripcion",
          header: <LanguageProvider id="18637" alt="Operación" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        activo: true,
        pais: null,
        cuenta: null,
        fechaUltimaModificacion: null,
        subcuenta: null,
        usuarioUltimaModificacion: null,
        calle: "",
        columna: "",
        nivel: "",
        operacionPlaya: null,
      }}
      formSettings={[
        {
          field: "calle",
          label: <LanguageProvider id="990" alt="Calle" />,
          dropDownPlaceholder: LanguageProvider({ id: "990", alt: "Calle" }),
          type: "dropdown",
          dataSource: (filter: any) => loadCalle(filter),
          disableOnUpdate: true,
          dependency: [
            "securityValues.country",
            "securityValues.account",
            "securityValues.subaccount",
          ],
        },
        {
          field: "columna",
          label: <LanguageProvider id="991" alt="Columna" />,
          dropDownPlaceholder: LanguageProvider({ id: "991", alt: "Columna" }),
          type: "dropdown",
          disableOnUpdate: true,
          dataSource: (filter: any) => loadColuma(filter),
          dependency: ["calle"],
        },
        {
          field: "nivel",
          label: <LanguageProvider id="992" alt="Nivel" />,
          dropDownPlaceholder: LanguageProvider({ id: "992", alt: "Nivel" }),
          type: "dropdown",
          disableOnUpdate: true,
          dataSource: (filter: any) => loadNivel(filter),
          dependency: ["columna"],
        },

        {
          field: "operacionPlaya",
          label: <LanguageProvider id="18637" alt="Operación" />,
          dropDownPlaceholder: LanguageProvider({
            id: "18637",
            alt: "Operación",
          }),
          type: "dropdown",
          disableOnUpdate: true,
          dataSource: (filter: any) => loadOperacionPlaya(filter),
          dropDownTemplate: DropdownOptionTemplate,
        },

        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerUbicacionesOperaciones;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
