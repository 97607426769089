import "./nav-menu.scss";

import React, { useEffect, useState } from "react";

import Header from "../header";
import { LanguageProvider } from "@shared/components/language-provider";
import { Menubar } from "primereact/menubar";
import { NavLink } from "react-router-dom";
import useAuth from "@shared/AuthContext";

const NavMenu = () => {
  const [items, setItems] = useState([]);
  const { user } = useAuth();

  const menuItems = [
    //Datos Comunes
    {
      label: <LanguageProvider id="25199" alt="Datos Comunes" />,
      items: [
        //Datos Geográficos
        {
          label: <LanguageProvider id="487" alt="Datos Geográficos" />,
          items: [
            {
              label: (
                <LanguageProvider id="11240" alt="Mantener Días de entrega" />
              ),
              url: "/mantener-dias-entrega",
              code: "abm0140_mantener_dias_entrega",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="146" alt="Mantener Paises" />,
              url: "/mantener-paises",
              code: "abm0001_mantener_paises",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="150" alt="Mantener Provincias" />,
              url: "/mantener-provincias",
              code: "abm0002_mantener_provincias",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="155" alt="Mantener Ciudades" />,
              url: "/mantener-ciudades",
              code: "abm0003_mantener_ciudades",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="160" alt="Mantener Localidades" />,
              url: "/mantener-localidades",
              code: "abm0004_mantener_localidades",
              template: itemRenderer,
            },
          ],
        },
        //Calidad
        {
          label: <LanguageProvider id="11098" alt="Calidad" />,
          items: [
            {
              label: (
                <LanguageProvider id="168" alt="Mantener Tipos de Daños" />
              ),
              url: "/mantener-tipo-dano",
              code: "abm0005_mantener_tipodanios",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="11075" alt="Mantener Tipos de bloqueo" />
              ),
              url: "/mantener-tipo-bloqueo",
              code: "abm0138_mantener_tipos_bloqueo",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="177" alt="Mantener Partes Dañadas" />
              ),
              url: "/mantener-partes-daniadas",
              code: "abm0007_mantener_partesdanios",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="181" alt="Mantener Gravedad de Daños" />
              ),
              url: "/mantener-gravedaddanios",
              code: "abm0008_mantener_gravedaddanios",
              template: itemRenderer,
            },
            //Imputaciones
            {
              label: <LanguageProvider id="486" alt="Imputaciones" />,
              items: [
                {
                  label: (
                    <LanguageProvider id="476" alt="Mantener Imputaciones" />
                  ),
                  url: "/mantener-imputaciones",
                  code: "abm0040_mantener_imputaciones",
                  template: itemRenderer,
                },
                {
                  label: (
                    <LanguageProvider
                      id="481"
                      alt="Mantener Relación Clientes Imputaciones"
                    />
                  ),
                  url: "/mantener-cliente-imputaciones",
                  code: "abm0041_mantener_cliente_imputaciones",
                  template: itemRenderer,
                },
                {
                  label: (
                    <LanguageProvider
                      id="620"
                      alt="Mantener Relación Imputaciones Responsables"
                    />
                  ),
                  url: "/mantener-imputaciones-responsables",
                  code: "abm0061_mantener_imputaciones_responsables",
                  template: itemRenderer,
                },
              ],
            },
            //Responsables
            {
              label: <LanguageProvider id="336" alt="Responsables" />,
              items: [
                {
                  label: (
                    <LanguageProvider
                      id="331"
                      alt="Mantener Responsable del Clientes"
                    />
                  ),
                  url: "/mantener-responsables-cliente",
                  code: "abm0014_mantener_responsables_cliente",
                  template: itemRenderer,
                },
                {
                  label: (
                    <LanguageProvider id="338" alt="Mantener Responsables" />
                  ),
                  url: "/mantener-responsables",
                  code: "abm0015_mantener_responsables",
                  template: itemRenderer,
                },
                {
                  label: (
                    <LanguageProvider
                      id="346"
                      alt="Relación entre Responsables"
                    />
                  ),
                  url: "/mantener-relacion-responsables",
                  code: "abm0016_mantener_relacion_responsables",
                  template: itemRenderer,
                },
              ],
            },
            {
              label: <LanguageProvider id="248" alt="Mantener CheckPoints" />,
              url: "/mantener-checkpoints",
              code: "abm0039_mantener_checkpoints",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="630" alt="Mantener Permisos de Calidad" />
              ),
              url: "/mantener-permisos-calidad",
              code: "abm0062_mantener_permisos_calidad",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2530" alt="Rangos Saneamiento" />,
              url: "/rangos-saneamiento",
              code: "abm0111_rangos_saneamiento",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="11069"
                  alt="Mantener Inspectores Averias"
                />
              ),
              url: "/mantener-inspectores-averia",
              code: "abm0137_mantener_inspectores_averias",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="21674" alt="Mantener Cuadrantes" />,
              url: "/mantener-cuadrante",
              code: "abm0234_mantener_cuadrantes",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="172" alt="Mantener Grupos de Daños" />
              ),
              url: "/mantener-gruposdanios",
              code: "abm0006_mantener_gruposdanios",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="826" alt="Mantener Unidades de Medida" />
              ),
              url: "/mantener-unidades-medidas-playa",
              code: "abm0236_mantener_unidades_medidas_playa",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="20854" alt="Motivo Baja Daño" />,
              url: "/mantener-motivo-baja-danio",
              code: "abm0231_motivo_baja_danio",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="21694"
                  alt="Mantener Daños Unidades Medida"
                />
              ),
              url: "/mantener-danios-unidades-medidas",
              code: "abm0237_mantener_danios_unidades_medidas",
              template: itemRenderer,
            },
          ],
        },
        //Fecha Entrega
        {
          label: <LanguageProvider id="3244" alt="Fecha Entrega" />,
          items: [
            {
              label: (
                <LanguageProvider id="17911" alt="Mantener Matrices Tiempo" />
              ),
              url: "/mantener-matriz-tiempo",
              code: "abm0203_matriz_tiempo",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="19729"
                  alt="Mantener calendario desvio entrega"
                />
              ),
              url: "/mantener-calendario-desvio-entrega",
              code: "abm0216_mantener_calendario_desvio_entrega",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="19753"
                  alt="Mantener Playa Cliente Liberacion"
                />
              ),
              url: "/mantener-playa-cliente-liberacion",
              code: "abm0218_mantener_playa_cliente_liberacion",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="19766"
                  alt="Parámetros tiempos de entrega"
                />
              ),
              url: "/parametros-tiempo-entrega",
              code: "abm0223_parametros_tiempo_entrega",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="19501" alt="Mantener cupo taller" />,
              url: "/mantener-cupo-taller",
              code: "abm0220_mantener_cupo_taller",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="19729"
                  alt="Mantener calendario desvio entrega"
                />
              ),
              url: "/mantener-playa-cliente-guarda",
              code: "abm0217_mantener_playa_cliente_guarda",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="19762" alt="Mantener Cupos PPO" />,
              url: "/mantener-cupo-ppo",
              code: "abm0221_mantener_cupo_ppo",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="19763" alt="Mantener Cupo Despacho" />
              ),
              url: "/mantener-cupo-despacho",
              code: "abm0222_mantener_cupo_despacho",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="19754"
                  alt="Mantener Cupo Transferencia"
                />
              ),
              url: "/mantener-cupo-transferencia",
              code: "abm0219_mantener_cupo_transferencia",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="19724" alt="Mantener desvio entregas" />
              ),
              url: "/mantener-desvio-entrega",
              code: "abm0215_mantener_desvio_entrega",
              template: itemRenderer,
            },
          ],
        },
        //Playas/Talleres
        {
          label: <LanguageProvider id="5518" alt="Playas/Talleres" />,
          items: [
            {
              label: (
                <LanguageProvider
                  id="10625"
                  alt="Mantener Ubicaciones Familia"
                />
              ),
              url: "/mantener-ubicaciones-familia",
              code: "abm0125_mantener_ubicaciones_familia",
              template: itemRenderer,
            },

            {
              label: <LanguageProvider id="459" alt="Mantener Ubicaciones" />,
              url: "/mantener-ubicaciones",
              code: "abm0037_mantener_ubicacion",
              template: itemRenderer,
            },

            {
              label: (
                <LanguageProvider id="12476" alt="Bloqueo de ubicaciones" />
              ),
              url: "/bloqueo-ubicaciones",
              code: "fun0112_bloqueo_ubicaciones",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="10866"
                  alt="Mantener Subcuentas Datos CTRC"
                />
              ),
              url: "/subcuentas-datos-ctrc",
              code: "abm0133_subcuentas_datos_ctrc",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="647" alt="Mantener Talleres" />,
              url: "/mantener-talleres",
              code: "abm0063_mantener_talleres",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="724"
                  alt="Mantener Ubicaciones - Lineas de Carga"
                />
              ),
              url: "/mantener-ubicaciones-lineas-de-carga",
              code: "abm0065_mantener_ubicaciones_lineasdecarga",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="764" alt="Mantener Talleres Cliente" />
              ),
              url: "/mantener-talleres-cliente",
              code: "abm0068_mantener_talleres_cliente",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="2095" alt="Mantener Cuentas Taller" />
              ),
              url: "/mantener-cuentas-taller",
              code: "abm0098_mantener_cuentas_taller",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="770"
                  alt="Mantener Talleres Ubicaciones"
                />
              ),
              url: "/mantener-talleres-ubicaciones",
              code: "abm0069_mantener_talleres_ubicaciones",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="876"
                  alt="Mantener Ubicaciones Recepción"
                />
              ),
              url: "/mantener-ubicaciones-recepcion",
              code: "abm0075_mantener_ubicaciones_recepcion",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="7290"
                  alt="Mantener Ubicaciones Operaciones"
                />
              ),
              url: "/mantener-ubicaciones-operaciones",
              code: "abm0116_mantener_ubicaciones_operaciones",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2078" alt="Mantener Tipo Compras" />,
              url: "/mantener-tipo-compras",
              code: "abm0090_mantener_tipo_compras",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="10629" alt="Mantener Zonas Playa" />,
              url: "/mantener-zonas-playa",
              code: "abm0126_mantener_zonas_playa",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2081" alt="Mantener Rubros" />,
              url: "/mantener-rubros",
              code: "abm0091_mantener_rubros",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="2093" alt="Mantener Grupos de Talleres" />
              ),
              url: "/mantener-grupos-taller",
              code: "abm0094_mantener_grupos_taller",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="2171" alt="Mantener Modelos Taller" />
              ),
              url: "/mantener-modelos-taller",
              code: "abm0103_mantener_modelos_taller",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="2066" alt="Mantener Cargos Talleres" />
              ),
              url: "/mantener-param-cargos-talleres",
              code: "abm0086_mantener_cargos_talleres",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="21386"
                  alt="Mantener Ubicaciones Categoria"
                />
              ),
              url: "/ubicacion-categoria",
              code: "abm0217_ubicacion_categoria",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="24794"
                  alt="Mantener Clasificación Orden Reparación"
                />
              ),
              url: "/mantener-clasificacion-orden-reparacion",
              code: "abm0606_mantener_clasificacion_orden_reparacion",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="14481"
                  alt="Mantener Filtros Excel Preparacións "
                />
              ),
              url: "/mantener-filtros-preparacion",
              code: "abm0190_mantener_filtros_preparacion",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="442" alt="Mantener Playas" />,
              url: "/mantener-playas",
              code: "abm0034_mantener_playas",
              template: itemRenderer,
            },
          ],
        },
        //Gestión PVN
        {
          label: <LanguageProvider id="19869" alt="Gestión PVN" />,
          items: [
            {
              label: <LanguageProvider id="19870" alt="Mantener asuntos" />,
              url: "/mantener-asuntos",
              code: "abm0224_asuntos",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="19934" alt="Mantener Ocurrencias" />,
              url: "/mantener-ocurrencia",
              code: "abm0227_ocurrencias",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="22859" alt="Checklist" />,
              url: "/checklist",
              code: "abm0250_checklist",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="20174" alt="Observaciones PVN" />,
              url: "/observaciones-pvn",
              code: "abm0228_observacionespvn",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="9225" alt="Preguntas" />,
              url: "/preguntas",
              code: "abm0225_preguntas",
              template: itemRenderer,
            },
          ],
        },
        //Estadía
        {
          label: <LanguageProvider id="19569" alt="Estadia" />,
          items: [
            {
              label: (
                <LanguageProvider
                  id="1433"
                  alt="Mantener Parametros de Playa para estadía"
                />
              ),
              url: "/mantener-estadia-param-playas",
              code: "abm0084_estadia_param_playas",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="1434"
                  alt="Parametrización de Rangos por Estadía"
                />
              ),
              url: "/param-rangos-estadia",
              code: "abm0085_param_rangos_estadia",
              template: itemRenderer,
            },
          ],
        },
        //Clientes/Proveedores
        {
          label: <LanguageProvider id="5517" alt="Clientes/Proveedores" />,
          items: [
            {
              label: (
                <LanguageProvider
                  id="10617"
                  alt="Mantener Proveedores Boca Entrega"
                />
              ),
              url: "/mantener-proveedores-bocaentrega",
              code: "abm0123_mantener_proveedores_bocaentrega",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="10620"
                  alt="Mantener Proveedores Lineas Carga"
                />
              ),
              url: "/mantener-prov-lineas-carga",
              code: "abm0124_mantener_prov_lineascarga",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="10176" alt="Migracion Clientes" />,
              url: "/migracion-clientes",
              code: "fun0099_migracion_clientes",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="21309"
                  alt="Mantener Choferes Proveedores"
                />
              ),
              url: "/mantener-choferes-proveedores",
              code: "abm0233_mantener_choferes_proveedores",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="211" alt="Mantener Clientes" />,
              url: "/mantener-clientes",
              code: "fun0013_mantener_clientes",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="1460"
                  alt="Mantener Tipo de Proveedores"
                />
              ),
              url: "/mantener-tipo-provedores",
              code: "abm0032_mantener_tipo_proveedores",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="435" alt="Mantener Proveedores" />,
              url: "/mantener-proveedores",
              code: "abm0033_mantener_proveedores",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="638"
                  alt="Mantener Ubicaciones Traslados"
                />
              ),
              url: "/mantener-ubicaciones-traslados",
              code: "abm0060_mantener_ubicaciones_traslados",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="17772"
                  alt="Mantener Parametros Cliente SIPCO"
                />
              ),
              url: "/mantener-parametros-cliente-sipco",
              code: "abm0201_mantener_parametros_cliente_sipco",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="23834" alt="Mantener Choferes" />,
              url: "/mantener-choferes",
              code: "abm0232_mantener_choferes",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="5708"
                  alt="Proveedores habilitados por Cliente Playa"
                />
              ),
              url: "/mantener-cliente-proveedor",
              code: "abm0074_mantener_cliente_proveedor",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="580"
                  alt="Mantener Relación Proveedores Tipos"
                />
              ),
              url: "/mantener-proveedores-tipos",
              code: "abm0056_mantener_proveedores_tipos",
              template: itemRenderer,
            },
          ],
        },
        //Concesionarios
        {
          label: <LanguageProvider id="266" alt="Concesionarios" />,
          items: [
            {
              label: (
                <LanguageProvider id="259" alt="Mantener Concesionarios" />
              ),
              url: "/mantener-concesionarios",
              code: "abm0029_mantener_concesionarios",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="268"
                  alt="Mantener Distancias a Bocas de Entrega"
                />
              ),
              url: "/mantener-dist-playa-entregas",
              code: "abm0009_mantener_dist_playa_entregas",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="509"
                  alt="Mantener Tipo de Despacho a Bocas de Entrega"
                />
              ),
              url: "/mantener-concesionarios-tipo-despacho",
              code: "abm0044_mantener_concesionarios_tipo_despacho",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="503"
                  alt="Mantener Tipo de Transporte a Bocas de Entrega"
                />
              ),
              url: "/mantener-concesionarios-tipo-transporte",
              code: "abm0042_mantener_concesionarios_tipo_transporte",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="22213"
                  alt="Viaje fijo por boca de entrega"
                />
              ),
              url: "/mantener-viaje-fijo-boca-entrega",
              code: "abm0245_viaje_fijo_boca_entrega",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="12066"
                  alt="Mantener Concesionarios Códigos Nostra"
                />
              ),
              url: "/mantener-concesionarios-codigos-nostra",
              code: "abm0155_concesionarios_codigos_nostra",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="269" alt="Mantener Tramos" />,
              url: "/mantener-tramos",
              code: "abm0024_mantener_tramos",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="8467" alt="Mantener Corredores" />,
              url: "/mantener-corredores",
              code: "abm0118_mantener_corredores",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="10492"
                  alt="Mantener Corredores Prioridad"
                />
              ),
              url: "/mantener-corredores-prioridad",
              code: "abm0153_mantener_corredores_prioridad",
              template: itemRenderer,
            },
          ],
        },
        //Marcas/Modelos
        {
          label: <LanguageProvider id="5514" alt="Marcas/Modelos" />,
          items: [
            {
              label: <LanguageProvider id="23833" alt="Mantener Marcas" />,
              url: "/mantener-marcas",
              code: "abm0010_mantener_marcas",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="7821" alt="Mantener Marca Destinos" />
              ),
              url: "/mantener-marcas-destinos",
              code: "abm0117_mantener_marca_destinos",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="239" alt="Mantener Colores" />,
              url: "/mantener-colores",
              code: "abm0012_mantener_colores",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="246" alt="Mantener Modelos" />,
              url: "/mantener-modelos",
              code: "abm0027_mantener_modelos",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="401" alt="Mantener Familias" />,
              url: "/mantener-familias",
              code: "abm0028_mantener_familias",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="494" alt="Mantener Super Familia" />,
              url: "/mantener-super-familias",
              code: "abm0043_mantener_superfamilias",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="537" alt="Mantener Siluetas" />,
              url: "/mantener-siluetas",
              code: "abm0048_mantener_siluetas",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="544" alt="Mantener Acabados" />,
              url: "/mantener-acabados",
              code: "abm0049_mantener_acabados",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="532" alt="Mantener Géneros" />,
              url: "/mantener-generos",
              code: "abm0047_mantener_generos",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="549" alt="Mantener Motorizaciones" />
              ),
              url: "/mantener-motorizacion",
              code: "abm0050_mantener_motorizaciones",
              template: itemRenderer,
            },

            {
              label: <LanguageProvider id="554" alt="Mantener Cajas" />,
              url: "/mantener-cajas",
              code: "abm0051_mantener_cajas",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="559" alt="Mantener Base Concepcion" />
              ),
              url: "/mantener-base-concepcion",
              code: "abm0052_mantener_base_concepcion",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="564" alt="Mantener Cliente Concepción" />
              ),
              url: "/mantener-clientes-concepcion",
              code: "abm0053_mantener_cliente_concepcion",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="569" alt="Mantener Modificaciones" />
              ),
              url: "/mantener-modificaciones",
              code: "abm0054_mantener_modificaciones",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="595"
                  alt="Mantener Cabeceras Opcionales"
                />
              ),
              url: "/mantener-cabecera-opcionales",
              code: "abm0058_mantener_cabecera_opcionales",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="806" alt="Mantener Categorías" />,
              url: "/mantener-categorias",
              code: "abm0072_mantener_categorias",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="574" alt="Mantener Año de Fabricación" />
              ),
              url: "/mantener-anio-fabricacion",
              code: "abm0055_mantener_anio_fabricacion",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="994" alt="Mantener Versiones" />,
              url: "/mantener-versiones",
              code: "abm0076_mantener_versiones",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="585" alt="Mantener Tapizados" />,
              url: "/mantener-tapizados",
              code: "abm0057_mantener_tapizados",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="614" alt="Mantener Linea Opcionales" />
              ),
              url: "/mantener-linea-opcionales",
              code: "abm0059_mantener_linea_opcionales",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="10485"
                  alt="Mantener Coeficientes de Fletes"
                />
              ),
              url: "/mantener-coeficientes-fletes",
              code: "abm0121_mantener_coeficientes_fletes",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="18201"
                  alt="Mantener Denominación Comercial"
                />
              ),
              url: "/mantener-denominacion-comercial",
              code: "abm0204_mantener_denominacion_comercial",
              template: itemRenderer,
            },
          ],
        },
        //Orígenes/Destinos
        {
          label: <LanguageProvider id="4860" alt="Orígenes/Destinos" />,
          items: [
            {
              label: <LanguageProvider id="234" alt="Mantener Orígenes" />,
              url: "/mantener-origenes",
              code: "abm0011_mantener_origenes",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="290"
                  alt="Mantener Orígenes del Cliente"
                />
              ),
              url: "/mantener-origenes-cliente",
              code: "abm0013_mantener_origenes_cliente",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="360" alt="Mantener Destinos" />,
              url: "/mantener-destinos",
              code: "abm0020_mantener_destinos",
              template: itemRenderer,
            },
          ],
        },
        //Mercados/Regiones
        {
          label: <LanguageProvider id="5516" alt="Mercados/Regiones" />,
          items: [
            {
              label: <LanguageProvider id="348" alt="Mantener Mercados" />,
              url: "/mantener-mercados",
              code: "abm0017_mantener_mercados",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="353" alt="Mantener Mercados Cliente" />
              ),
              url: "/mantener-mercados-cliente",
              code: "abm0018_mantener_mercados_cliente",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="353" alt="Mantener Mercados Cliente" />
              ),
              url: "/mantener-regiones",
              code: "abm0019_mantener_regiones",
              template: itemRenderer,
            },
          ],
        },
        //Transporte
        {
          label: <LanguageProvider id="376" alt="Transporte" />,
          items: [
            {
              label: (
                <LanguageProvider
                  id="371"
                  alt="Mantener Tipos de Transportes"
                />
              ),
              url: "/mantener-tipo-transporte",
              code: "abm0022_mantener_tipo_transporte",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="265" alt="Mantener Transportes" />,
              url: "/mantener-transportes",
              code: "abm0031_mantener_transportes",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="18983"
                  alt="Mantener Coeficientes Familia Transporte"
                />
              ),
              url: "/coeficiente-familia-transporte",
              code: "abm0213_mantener_coeficientes_familia_transporte",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="22221" alt="Tipo de viaje contratado" />
              ),
              url: "/mantener-tipo-viaje-contratado",
              code: "abm0246_tipo_viaje_contratado",
              template: itemRenderer,
            },
          ],
        },
        //Despachos
        {
          label: <LanguageProvider id="292" alt="Despachos" />,
          items: [
            {
              label: (
                <LanguageProvider id="377" alt="Mantener Tipo de Despacho" />
              ),
              url: "/mantener-tipo-despacho",
              code: "abm0023_mantener_tipo_despacho",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="377" alt="Mantener Tipo de Despacho" />
              ),
              url: "/mantener-tope-solicitud",
              code: "abm0200_mantener_tope_solicitud",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="734" alt="Mantener Estados Remitos" />
              ),
              url: "/mantener-estados-remito",
              code: "abm0066_mantener_estados_remito",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="757" alt="Mantener Leyendas del Remito" />
              ),
              url: "/mantener-leyendas-remito",
              code: "abm0067_mantener_leyendas_remito",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="15148"
                  alt="Mantener Secuencias Documentos"
                />
              ),
              url: "/mantener-documentos",
              code: "abm0127_mantener_documentos",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="13582" alt="Mantener Tipos Desvio" />
              ),
              url: "/mantener-tipos-desvios",
              code: "abm0184_mantener_tipos_desvios",
              template: itemRenderer,
            },
            // Embarque
            {
              label: <LanguageProvider id="15064" alt="Embarques" />,
              items: [
                {
                  label: (
                    <LanguageProvider
                      id="14908"
                      alt="Mantener Motivos de Baja"
                    />
                  ),
                  url: "/mantener-motivos-baja",
                  code: "abm0191_mantener_motivos_baja",
                  template: itemRenderer,
                },
                {
                  label: (
                    <LanguageProvider
                      id="14909"
                      alt="Mantener Motivos Demora"
                    />
                  ),
                  url: "/mantener-motivos-demora",
                  code: "abm0192_mantener_motivos_demora",
                  template: itemRenderer,
                },
              ],
            },
            // Traslado
            {
              label: <LanguageProvider id="16147" alt="Traslado" />,
              items: [
                {
                  label: (
                    <LanguageProvider
                      id="16115"
                      alt="Mantener Perfil Traslado"
                    />
                  ),
                  url: "/mantener-perfil-translado",
                  code: "abm0193_mantener_perfil_traslado",
                  template: itemRenderer,
                },
              ],
            },
          ],
        },
        //Radios
        {
          label: <LanguageProvider id="261" alt="Radios" />,
          items: [
            {
              label: (
                <LanguageProvider
                  id="270"
                  alt="Relacionar Modelos de Autos y Radios"
                />
              ),
              url: "/mantener-modelos-radios",
              code: "abm0046_mantener_modelos_radios",
              template: itemRenderer,
            },
          ],
        },
        //Transacciones
        {
          label: <LanguageProvider id="466" alt="Transacciones" />,
          items: [
            {
              label: <LanguageProvider id="456" alt="Mantener Transacciones" />,
              url: "/mantener-transacciones",
              code: "abm0036_mantener_transacciones",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="678"
                  alt="Mantener Checkpoint Transaccion"
                />
              ),
              url: "/mantener-checkpoint-transaccion",
              code: "abm0036_mantener_transacciones",
              template: itemRenderer,
            },
          ],
        },
        //Contable
        {
          label: <LanguageProvider id="329" alt="Contable" />,
          items: [
            {
              label: (
                <LanguageProvider
                  id="15248"
                  alt="Mantener Parametros Cobro Prestación"
                />
              ),
              url: "/mantener-parametros-cobro-prestacion",
              code: "abm0184_mantener_parametros_cobro_prestacion",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="1298" alt="Mantener Contable Ciudades" />
              ),
              url: "/mantener-contable-ciudades",
              code: "abm0070_mantener_contable_ciudades",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="10812"
                  alt="Mantener Naturalezas Fiscales"
                />
              ),
              url: "/naturaleza-fiscal",
              code: "abm0128_mantener_naturalezas_fiscales",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="11054"
                  alt="Mantener Centros Costos GEFCO"
                />
              ),
              url: "/mantener-centro-costos-gefco",
              code: "abm0136_mantener_centros_costo_gefco",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="11122" alt="Mantener Impuestos ICMS" />
              ),
              url: "/mantener-impuestos-icms",
              code: "abm0139_mantener_impuestos_icms",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="10816" alt="Mantener Cuenta Gastos" />
              ),
              url: "/mantener-cuentas-gastos",
              code: "abm0131_mantener_cuentas_gastos",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="10813" alt="Mantener Ordenes Internas" />
              ),
              url: "/mantener-ordenes-internas",
              code: "abm0130_mantener_ordenes_internas",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="1305"
                  alt="Mantener Contable Familias Radio"
                />
              ),
              url: "/mantener-contable-familias-radio",
              code: "abm0078_mantener_contable_familias_radio",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="1326"
                  alt="Mantener Contable Subcuentas"
                />
              ),
              url: "/mantener-contable-subcuentas",
              code: "abm0080_mantener_contable_subcuentas",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="1335" alt="Mantener Rutina Prestacion" />
              ),
              url: "/mantener-contable-rutina-prestacion",
              code: "abm0082_mantener_contable_rutina_prestacion",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="1333"
                  alt="Mantener Contable Puntos de Retiro"
                />
              ),
              url: "/mantener-contable-puntos-de-retiro",
              code: "abm0081_mantener_contable_puntos_de_retiro",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="787"
                  alt="Mantener Funciones Contables (Asignar Tipo)"
                />
              ),
              url: "/mantener-funciones-contables",
              code: "abm0071_mantener_funciones_contables",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="1418"
                  alt="Mantener Función Rutina Contable Por Tipo)"
                />
              ),
              url: "/mantener-funcion-rutina",
              code: "abm0077_mantener_funcion_rutina",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="1310" alt="Mantener Contable Origenes" />
              ),
              url: "/mantener-contable-origenes-playas",
              code: "abm0079_mantener_contable_origenes_playas",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="5829"
                  alt="Mantener Parámetros Navieras"
                />
              ),
              url: "/mantener-parametros-navieras",
              code: "abm0115_mantener_parametros_navieras",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="10852" alt="Mantener Nota Pedido Flete" />
              ),
              url: "/mantener-nota-pedido-flete",
              code: "abm0132_mantener_nota_pedido_flete",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="1682" alt="Mantener Centro de Costo" />
              ),
              url: "/mantener-centro-costos",
              code: "abm0129_mantener_centro_costos",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="1685" alt="Mantener Servicios" />,
              url: "/mantener-servicios-extra",
              code: "abm0248_mantener_servicios_extra",
              template: itemRenderer,
            },
          ],
        },
        //Mantener Feriados
        {
          label: <LanguageProvider id="819" alt="Mantener Feriados" />,
          url: "/mantener-feriados",
          code: "abm0073_mantener_feriados",
          template: itemRenderer,
        },
        //Operaciones
        {
          label: <LanguageProvider id="2693" alt="Operaciones" />,
          items: [
            {
              label: (
                <LanguageProvider
                  id="14120"
                  alt="Mantener Resultado Operaciones"
                />
              ),
              url: "/mantener-resultado-operaciones",
              code: "abm0186_mantener_resultado_operaciones",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="19049" alt="Tope Operación" />,
              url: "/mantener-tope-operaciones",
              code: "abm0211_tope_operaciones",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="13132"
                  alt="Mantener Operaciones Exigidas por RF"
                />
              ),
              url: "/mantener-operaciones-exigidas-rf",
              code: "abm0183_registrar_operaciones_exigidas_rf",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="19051"
                  alt="Reglas Registración Operaciones"
                />
              ),
              url: "/mantener-reglas-registracion-operaciones",
              code: "abm0212_reglas_registracion_operaciones",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="11443" alt="Estado vehículo" />,
              url: "/mantener-estado-vehiculo",
              code: "abm0210_estado_vehiculo",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="14319" alt="Mantener Ciclo Operación" />
              ),
              url: "/ciclos-operacion",
              code: "abm0189_ciclos_operacion",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="14123"
                  alt="Mantener Relación Operaciones"
                />
              ),
              url: "/mantener-relacion-operaciones",
              code: "abm0187_mantener_relacion_operaciones",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="14318"
                  alt="Mantener Parametrización Operación"
                />
              ),
              url: "/mantener-parametrizacion-operaciones",
              code: "abm0188_mantener_parametrizacion_operaciones",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="19056" alt="Método Recomendación Vin" />
              ),
              url: "/metodo-recomendacion-vin",
              code: "abm0213_metodo_recomendacion_vin",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="12868" alt="Prestaciones Operación" />
              ),
              url: "/mantener-prestaciones-operacion",
              code: "abm0182_prestaciones_operacion",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="22213"
                  alt="Viaje fijo por boca de entrega"
                />
              ),
              url: "/mantener-grupo-operaciones",
              code: "abm0185_mantener_grupo_operaciones",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="12030" alt="Control EXW" />,
              url: "/control-exw",
              code: "abm0154_control_exw",
              template: itemRenderer,
            },
          ],
        },
        //Mantener Eventos Mails
        {
          label: <LanguageProvider id="12716" alt="Mantener Eventos Mails" />,
          url: "/mantener-eventos-mails",
          code: "abm0181_eventos_mails",
          template: itemRenderer,
        },
        //Transferencia
        {
          label: <LanguageProvider id="10906" alt="Transferencia" />,
          items: [
            {
              label: (
                <LanguageProvider
                  id="13233"
                  alt="Mantener Interfaz Transferencias"
                />
              ),
              url: "/mantener-interfaz-transferencia",
              code: "abm0141_mantener_interfaz_transferencia",
              template: itemRenderer,
            },
          ],
        },
        //Conex Contable
        {
          label: <LanguageProvider id="2118" alt="Conex Contable" />,
          items: [
            {
              label: (
                <LanguageProvider
                  id="1393"
                  alt="Exportación a Excel de las Prestaciones Contables"
                />
              ),
              url: "/exportacion-prestacion-contable",
              code: "fun0066_exportacion_prestacion_contable",
              template: itemRenderer,
            },
          ],
        },
      ],
    },

    //Funciones
    {
      label: <LanguageProvider id="2788" alt="Funciones" />,
      items: [
        {
          label: <LanguageProvider id="10836" alt="Ingreso Ecom" />,
          url: "/ingreso-ecom",
          code: "fun0068_ingreso_ecom",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider
              id="5391"
              alt="Mantener Solicitudes de Transportes"
            />
          ),
          url: "/mantener-solicitudes",
          code: "abm0152_mantener_solicitudes",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="11279" alt="Anulación ECOM" />,
          url: "/anulacion-ecom",
          code: "fun0111_anulacion_ecom",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="10835" alt="Reimpresión de Etiquetas" />,
          url: "/reimpresion-etiquetas",
          code: "fun0105_reimpresion_etiquetas",
          template: itemRenderer,
        },
        //Colectas
        {
          label: <LanguageProvider id="10764" alt="Colectas" />,
          items: [
            {
              label: <LanguageProvider id="16937" alt="Geracao de Coletas" />,
              url: "/colecta",
              code: "fun0103_colecta",
              template: itemRenderer,
            },
          ],
        },
        // Contable
        {
          label: <LanguageProvider id="329" alt="Contable" />,
          items: [
            {
              label: <LanguageProvider id="11008" alt="Cierre Contable" />,
              url: "/cierre-contable",
              code: "fun0108_cierre_contable",
              template: itemRenderer,
            },
          ],
        },
        {
          label: <LanguageProvider id="4301" alt="Mantener Hojas de Ruta" />,
          url: "/mantener-hojas-ruta",
          code: "fun0090_modificacion_manual_hr",
          template: itemRenderer,
        },
        // Oferta de Servicios
        {
          label: <LanguageProvider id="16641" alt="Oferta de Servicios" />,
          items: [
            {
              label: <LanguageProvider id="22953" alt="Recepción Terceros" />,
              url: "/recepcion-terceros",
              code: "fun0224_recepcion_terceros",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="3715" alt="Gastos Extraordinarios" />
              ),
              url: "/gastos-extra-playa",
              code: "fun0225_gastos_extra_playa",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="23095"
                  alt="Contabilizar Viajes de Terceros"
                />
              ),
              url: "/contabilizar-viajes-terceros",
              code: "fun0226_contabilizar_viajes_terceros",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="22778" alt="Ofertas Servicio Terceros" />
              ),
              url: "/ofertas-servicio-terceros",
              code: "abm0249_ofertas_servicio_terceros",
              template: itemRenderer,
            },
          ],
        },

        //Transferencias
        {
          label: <LanguageProvider id="325" alt="Transferencias" />,
          items: [
            {
              label: (
                <LanguageProvider
                  id="12840"
                  alt="Impresión Etiquetas Transferencias"
                />
              ),
              url: "/impresion-etiquetas",
              code: "fun0201_impresion_etiquetas",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="16148" alt="Listado de Transferencia" />
              ),
              url: "/translados",
              code: "con0065_consulta_traslados",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="315" alt="Transferencia de Ubicación" />
              ),
              url: "/transferencia-ubicacion",
              code: "fun0040_transferencia_ubicacion",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="746" alt="Transferencia entre Playas" />
              ),
              url: "/transferencia-playa",
              code: "fun0041_transferencia_playa",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="816" alt="Recepción de Playa" />,
              url: "/recepcion-de-playa",
              code: "fun0042_recepcion_de_playa",
              template: itemRenderer,
            },
          ],
        },
        //Reingresos
        {
          label: <LanguageProvider id="328" alt="Reingresos" />,
          items: [
            {
              label: <LanguageProvider id="318" alt="Reingreso Gefco" />,
              url: "/reingreso-stock",
              code: "fun0062_reingreso_stock",
              template: itemRenderer,
            },
          ],
        },

        // Taller
        {
          label: <LanguageProvider id="18487" alt="Taller" />,
          items: [
            {
              label: <LanguageProvider id="319" alt="Ingreso Taller" />,
              url: "/ingreso-taller",
              code: "fun0050_ingreso_taller",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2138" alt="Ingreso Taller Manual" />,
              url: "/ingreso-taller-manual",
              code: "fun0076_ingreso_taller_manual",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2245" alt="Ordenes de Reparación" />,
              url: "/orden-reparacion",
              code: "fun0079_orden_reparacion",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="2335" alt="Mantener Vales de Repuestos" />
              ),
              url: "/mantener-vales-de-repuestos",
              code: "fun0081_mantener_vales_de_repuestos",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2355" alt="Fun Compras Taller" />,
              url: "/fun-compras-taller",
              code: "fun0083_compras_taller",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2352" alt="Reingreso de Stock" />,
              url: "/reingreso-de-stock",
              code: "fun0082_reingreso_de_stock",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="320" alt="Egreso Taller" />,
              url: "/egreso-taller",
              code: "fun0054_egreso_taller",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="2180"
                  alt="Mantener Catalogo de Repuestos"
                />
              ),
              url: "/mantener-catalogo-repuestos",
              code: "abm0104_mantener_catalogo_repuestos",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="2204"
                  alt="Mantener Catalogo de Servicios"
                />
              ),
              url: "/mantener-catalogo-servicios",
              code: "abm0105_mantener_catalogo_servicios",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="9854" alt="Vehiculos Pendiente Taller" />
              ),
              url: "/vehiculos-pendiente-taller",
              code: "con0049_vehiculos_pendiente_taller",

              template: itemRenderer,
            },
          ],
        },

        // Remitos
        {
          label: <LanguageProvider id="275" alt="Remitos" />,
          items: [
            {
              label: (
                <LanguageProvider
                  id="10709"
                  alt="Administración de Notas Fiscales"
                />
              ),
              url: "/administracion-notas-fiscales",
              code: "fun0043_administracion_notas_fiscales",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="981" alt="Manejar Errores de Remito" />
              ),
              url: "/manejar-errores-remitos",
              code: "fun0049_manejar_errores_remitos",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="323" alt="Reimpresión de Remito" />,
              url: "/reimpresion-remitos",
              code: "fun0045_reimpresion_remitos",
              template: itemRenderer,
            },

            {
              label: (
                <LanguageProvider
                  id="24506"
                  alt="Remitos Ficticios->Reales / Reales -> Ficticios"
                />
              ),
              url: "/administracion-remitos-ficticio",
              code: "fun0162_administracion_remitos_ficticio",
              template: itemRenderer,
            },

            {
              label: <LanguageProvider id="5352" alt="Anular Remito" />,
              url: "/anular-remitos",
              code: "fun0047_anular_remitos",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="861" alt="Administración de Remitos" />
              ),
              url: "/administracion-remitos-a-concesionarios",
              code: "fun0043_administracion_remitos_a_concesionarios",

              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="25106" alt="Impresión de Checklist" />
              ),
              url: "/impresion-checklist",
              code: "fun0229_impresion_checklist",
              template: itemRenderer,
            },
          ],
        },

        //Retorno
        {
          label: <LanguageProvider id="295" alt="Retorno" />,
          items: [
            {
              label: <LanguageProvider id="303" alt="Carga de Retorno" />,
              url: "/carga-de-retornos",
              code: "fun0055_carga_de_retornos",
              template: itemRenderer,
            },
          ],
        },

        // Estadía
        {
          label: <LanguageProvider id="1384" alt="Estadía" />,
          items: [
            {
              label: <LanguageProvider id="1385" alt="Cierre de Estadía" />,
              url: "/cierre-estadia",
              code: "fun0065_cierre_estadia",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="1505"
                  alt="Listado de Apoyo para Estadías"
                />
              ),
              url: "/listado-apoyo-estadia",
              code: "fun0072_listado_apoyo_estadia",
              template: itemRenderer,
            },
          ],
        },

        //Modificación Datos Basicos
        {
          label: <LanguageProvider id="904" alt="Modificación Datos Básicos" />,
          items: [
            {
              label: <LanguageProvider id="903" alt="Modificación datos VIN" />,
              url: "/modificacion-vin",
              code: "fun0046_modificacion_vin",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="2477" alt="Modificacion Masiva Motor" />
              ),
              url: "/modificacion-masiva-motor",
              code: "fun0084_modificacion_masiva_motor",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="1483" alt="Levantar Indisponibles" />
              ),
              url: "/levantar-indisponibilidad",
              code: "fun0070_levantar_indisponibilidad",
              template: itemRenderer,
            },
          ],
        },

        //Carga Manual
        {
          label: <LanguageProvider id="1464" alt="Carga Manual" />,
          items: [
            {
              label: <LanguageProvider id="1465" alt="Carga Manual del Vin" />,
              url: "/carga-manual-vin",
              code: "fun0068_carga_manual_vin",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="1478" alt="Carga Manual de Remitos" />
              ),
              url: "/carga-manual-remitos",
              code: "fun0069_carga_manual_remitos",
              template: itemRenderer,
            },

            {
              label: (
                <LanguageProvider
                  id="23453"
                  alt="Ingreso Manual De Vehiculos"
                />
              ),
              items: [
                // opção foi comentada a pedido do Tech Lead Santiago por desuso da tela em questão - Lucas Silvestre - 19/03/2025
                // {
                //   label: (
                //     <LanguageProvider
                //       id="23455"
                //       alt="Ingreso Vines Por Archivo Texto"
                //     />
                //   ),
                //   url: "/interfaz-ingreso-ford",
                //   code: "fun0089_interfaz_ingreso_ford",
                //   template: itemRenderer,
                // },
                {
                  label: (
                    <LanguageProvider
                      id="23454"
                      alt="Ingreso Vehiculos Con Remito"
                    />
                  ),
                  url: "/ingreso-manual-de-vehiculos",
                  code: "fun0123_ingreso_manual_de_vehiculos",
                  template: itemRenderer,
                },
                {
                  label: (
                    <LanguageProvider
                      id="23456"
                      alt="Ingreso Vines y Daños por Archivo Texto"
                    />
                  ),
                  url: "/ingreso-ecom-danios-ford",
                  code: "fun0211_ingreso_ecom_danios_ford",
                  template: itemRenderer,
                },
              ],
            },
          ],
        },

        // Operaciones
        {
          label: <LanguageProvider id="2693" alt="Operaciones" />,
          items: [
            {
              label: (
                <LanguageProvider id="18591" alt="Gestión de preparación" />
              ),
              url: "/gestion-preparacion",
              code: "fun0206_gestion_preparacion",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="14379"
                  alt="Generación y Cierre de Ordenes de Trabajo"
                />
              ),
              url: "/generacion-ot",
              code: "fun0114_generacion_ot",
              template: itemRenderer,
            },

            {
              label: (
                <LanguageProvider
                  id="2783"
                  alt="Contab. y Anul. de Operaciones"
                />
              ),
              url: "/contabilizacion-anulacion-de-operaciones",
              code: "fun0086_contabilizacion_anulacion_de_operaciones",
              template: itemRenderer,
            },

            {
              label: (
                <LanguageProvider id="14618" alt="Gestion de Operaciones" />
              ),
              url: "/gestion-de-operaciones",
              code: "fun0116_gestion_de_operaciones",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="18312"
                  alt="Registración de operaciones por OT"
                />
              ),
              url: "/registracion-operacion-por-ot",
              code: "fun0202_registracion_operacion_por_ot",

              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="18330"
                  alt="Registración de Operaciones Automaticas"
                />
              ),
              url: "/registracion-operacion-automatica",
              code: "fun0205_registracion_operacion_automatica",

              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="19211" alt="Aprobación de Operaciones" />
              ),
              url: "/aprobacion-operaciones",
              code: "fun0210_aprobacion_operaciones",

              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="18316" alt="Carga operaciones forzadas" />
              ),
              url: "/carga-operaciones-forzadas",
              code: "fun0203_carga_operaciones_forzadas",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="25135" alt="Generación de Picking" />
              ),
              url: "/gen-picking",
              code: "fun0230_gen_picking",
              template: itemRenderer,
            },
          ],
        },

        {
          label: <LanguageProvider id="18325" alt="Modificar estado del vin" />,
          url: "/modificar-estado-del-vin",
          code: "fun0204_carga_estado_vin",
          template: itemRenderer,
        },

        {
          label: <LanguageProvider id="22138" alt="Pedidos Playa" />,
          url: "/pedidos-playa",
          code: "fun0221_pedidos_playa",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider id="15140" alt="Impresión Manual De Guias" />
          ),
          url: "/impresion-manual-guias",
          code: "fun0121_impresion_manual_guias",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="21543" alt="Devolución MAN" />,
          url: "/devolucion-man",
          code: "fun0219_devolucion_man",
          template: itemRenderer,
        },

        {
          label: <LanguageProvider id="21385" alt="Despacho MAN" />,
          url: "/despacho-man",
          code: "fun0217_despacho_man",
          template: itemRenderer,
        },
      ],
    },

    //Consultas
    {
      label: <LanguageProvider id="13826" alt="Consultas" />,
      items: [
        {
          label: <LanguageProvider id="25138" alt="Consulta Transport Order" />,
          url: "/transf-ord",
          code: "con0078_transf_ord",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="16641" alt="Oferta de Servicios" />,
          items: [
            {
              label: <LanguageProvider id="23234" alt="Consulta OS Terceros" />,
              url: "/consulta-os-terceros",
              code: "con0097_consulta_os_terceros",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="16814"
                  alt="Consulta Ofertas de Servicio"
                />
              ),
              url: "/ofertas-servicio",
              code: "con0066_consulta_ofertas_de_servicio",

              template: itemRenderer,
            },
          ],
        },
        {
          label: <LanguageProvider id="3124" alt="Impresion Hoja Ruta" />,
          url: "/impression-hoja-ruta",
          code: "con0046_impression_hoje_ruta",

          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider id="713" alt="Reimpresión de Hoja de Ruta" />
          ),
          url: "/impression-hoja-ruta",
          code: "con0046_impresion_hoja_ruta",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="5513" alt="Listado de hojas de Ruta" />,
          url: "/listado-de-hojas-de-rutas",
          code: "con0047_listado_hoja_ruta",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="273" alt="Consulta Vin" />,
          url: "/consulta-vin",
          code: "con0020_vin_danios",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="275" alt="Remitos" />,
          url: "/remitos",
          code: "con0021_remitos",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="18532" alt="Despachados" />,
          url: "/despachos",
          code: "con0025_despachos",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="1197" alt="Ingreso a Playas" />,
          url: "/ingreso-playa",
          code: "con0022_ingreso_playa",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="281" alt="Antigüedad de Stock" />,
          url: "/antiguedad-stock",
          code: "con0023_antiguedad_stock",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="274" alt="Stock" />,
          url: "/stock",
          code: "con0027_stock",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="254" alt="Histórico de Daños" />,
          url: "/historico-danos",
          code: "con0026_historico_danios",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider
              id="1358"
              alt="Consulta de daños por checkpoint"
            />
          ),
          url: "/danios-checkpoint",
          code: "con0028_danios_x_checkpoint",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider
              id="2050"
              alt="Consulta de Transacciones por Usuario"
            />
          ),
          url: "/transacciones-usuarios",
          code: "con0033_transacciones_usuarios",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="3451" alt="Liberacion y Despachos" />,
          url: "/liberacion-despacho",
          code: "con0044_liberacion_despachos",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="18487" alt="Taller" />,
          items: [
            {
              label: <LanguageProvider id="2911" alt="Stock en Taller" />,
              url: "/stock-taller",
              code: "con0042_stock_taller",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="17017" alt="Vehiculos en taller" />,
              url: "/vehiculos-taller",
              code: "con0035_vehiculos_en_taller",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2400" alt="Historia del Vin" />,
              url: "/historia-vin",
              code: "con0036_historia_del_vin",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2406" alt="Vale Repuestos" />,
              url: "/vale-repuestos",
              code: "con0037_vale_repuestos",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2512" alt="Compras Taller" />,
              url: "/compras-taller",
              code: "con0038_compras_taller",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="2599" alt="Movimiento Stock" />,
              url: "/movimento-stock",
              code: "con0039_calculo_stock",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="3440" alt="Fechas Ingreso Taller" />,
              url: "/ingresso-taller",
              code: "con0043_ingreso_taller",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider id="14482" alt="Consulta Vines a Preparar" />
              ),
              url: "/vines-preparar",
              code: "con0062_consulta_vines_a_preparar",
              template: itemRenderer,
            },
          ],
        },
        {
          label: (
            <LanguageProvider id="1437" alt="Consulta de Tiempos de Entrega" />
          ),
          url: "/tiempos-entrega",
          code: "con0029_tiempos_de_entrega",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="11130" alt="Consulta de Bloqueos" />,
          url: "/consulta-bloqueos",
          code: "con0053_bloqueos",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="2693" alt="Operaciones" />,
          items: [
            {
              label: (
                <LanguageProvider
                  id="2837"
                  alt="Listado Operaciones Realizadas"
                />
              ),
              url: "/listado-operaciones-realizadas",
              code: "con0041_listado_operaciones_realizadas",
              template: itemRenderer,
            },
            {
              label: <LanguageProvider id="14380" alt="Ordenes de Trabajo" />,
              url: "/consulta-ot",
              code: "con0061_consulta_de_ot",
            },
            {
              label: (
                <LanguageProvider
                  id="13209"
                  alt="Listado Operaciones Pendientes"
                />
              ),
              url: "/consulta-operaciones-pendientes",
              code: "con0058_consulta_operaciones_pendientes",
              template: itemRenderer,
            },
            {
              label: (
                <LanguageProvider
                  id="19252"
                  alt="Consulta Operaciones Canceladas WEB"
                />
              ),
              url: "/operaciones-canceladas-web",
              code: "con0092_consulta_operaciones_canceladas_web",
            },
          ],
        },
        {
          label: <LanguageProvider id="5614" alt="Liberados Vs Despachados" />,
          url: "/liberados-despachados",
          code: "con0048_liberados_vs_despachados",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider id="10494" alt="Listado de Armado de Viajes" />
          ),
          url: "/listado-armado-viajes",
          code: "con0051_listado_armado_viajes",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider id="10530" alt="Documentación de Transportes" />
          ),
          url: "/documentacion-transportes",
          code: "con0047_documentacion_de_transportes",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider
              id="12038"
              alt="Consulta General de Transacciones"
            />
          ),
          url: "/general-transacciones",
          code: "con0055_consulta_general_de_transacciones",

          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider
              id="25028"
              alt="Consulta Interface JD - HR Confirmada"
            />
          ),
          url: "/pedidos-confirmados-jd",
          code: "con0100_pedidos_confirmados_jd",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider id="TODO" alt="Transit Time Pedidos Playa" />
          ),
          url: "/transit-time-pedidos-playa",
          code: "con0096_transit_time_pedidos_playa",
          template: itemRenderer,
        },
      ],
    },

    //M.local
    {
      label: <LanguageProvider id="25198" alt="M. Local" />,
      items: [
        {
          label: <LanguageProvider id="287" alt="Despacho Mercado Local" />,
          url: "/despacho-mercado-nacional",
          code: "fun0052_despacho_mercado_nacional",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="10215" alt="Despacho Terceros" />,
          url: "/despacho-terceros",
          code: "fun0100_despacho_terceros",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="316" alt="Devolucion Nacional" />,
          url: "/devolucion-mercado-local",
          code: "fun0057_devolucion_mercado_local",
          template: itemRenderer,
        },
      ],
    },

    //Importación/Exportación
    {
      label: <LanguageProvider id="2442" alt="Importación/Exportación" />,
      items: [
        {
          label: <LanguageProvider id="1066" alt="Liberación Aduana" />,
          url: "/liberacion-aduana",
          code: "fun0060_liberacion_aduana",
          template: itemRenderer,
        },

        {
          label: <LanguageProvider id="11120" alt="Peritaje" />,
          url: "/peritaje",
          code: "fun0103_peritaje",
          template: itemRenderer,
        },

        {
          label: (
            <LanguageProvider id="8960" alt="Eliminar Llegada Buque Camión" />
          ),
          url: "/eliminar-llegada-buque-camion",
          code: "fun0094_eliminar_llegada_buque_camion",
          template: itemRenderer,
        },

        {
          label: (
            <LanguageProvider id="1085" alt="Disponibilidad de Importación" />
          ),
          url: "/disponibilidad-importacion",
          code: "fun0061_disponibilidad_importacion",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="1052" alt="Llegada de Buque o Camion" />,
          url: "/llegada-buque-camion",
          code: "fun0058_llegada_buque_camion",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="310" alt="Despacho Internacional" />,
          url: "/despacho-mercado-exportacion",
          code: "fun0063_despacho_mercado_exportacion",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="24762" alt="Aduana No liberados" />,
          items: [
            {
              label: (
                <LanguageProvider id="24763" alt="Informa Transportista" />
              ),
              url: "/informa-transportista",
              code: "fun0227_informa_transportista",
              template: itemRenderer,
            },
          ],
        },
      ],
    },

    //Calidad
    {
      label: <LanguageProvider id="11098" alt="Calidad" />,
      items: [
        {
          label: <LanguageProvider id="252" alt="Carga de Daños" />,
          url: "/carga-danos",
          code: "fun0059_carga_danos",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="18090" alt="Carga Masiva de Daños" />,
          url: "/carga-masiva-danos",
          code: "fun0124_carga_masiva_danos",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="12258" alt="Reparar Vines" />,
          url: "/reparar-vines",
          code: "fun0104_reparar_vines",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="4695" alt="Bloqueos/Desbloqueos" />,
          url: "/bloqueos-desbloqueos",
          code: "fun0110_bloqueos_desbloqueos",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider id="21676" alt="Mantener Partes Cuadrantes" />
          ),
          url: "/mantener-partes-cuadrantes",
          code: "abm0235_mantener_partes_cuadrantes",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider
              id="17772"
              alt="Mantener Parametros Cliente SIPCO"
            />
          ),
          url: "/mantener-resultado-analisis",
          code: "abm0202_mantener_resultado_analisis",
          template: itemRenderer,
        },
        {
          label: (
            <LanguageProvider
              id="20847"
              alt="Mantener Daño NO pendiente taller"
            />
          ),
          url: "/mantener-danio-pendiente-taller",
          code: "abm0230_danio_pendiente_taller",
          template: itemRenderer,
        },
      ],
    },

    // Facturación
    {
      label: <LanguageProvider id="2864" alt="Facturación" />,
      items: [
        {
          label: (
            <LanguageProvider id="22173" alt="Ingreso movimientos manuales" />
          ),
          url: "/ingreso-mov-manuales",
          code: "fun0222_ingreso_mov_manuales",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="22096" alt="Movimientos a facturar" />,
          url: "/mantener-movimientos-facturar",
          code: "abm0240_movimientos_facturar",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="1684" alt="Servicios" />,
          url: "/mantener-servicios-facturar",
          code: "abm0239_servicios_facturar",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="24911" alt="Estado Contable HR" />,
          url: "/contable-verticalizacion",
          code: "fun0228_contable_verticalizacion",
          template: itemRenderer,
        },
      ],
    },

    //Herramientas
    {
      label: <LanguageProvider id="25200" alt="Herramientas" />,
      items: [
        {
          label: <LanguageProvider id="10243" alt="Remitos Electrónicos" />,
          url: "/remito-electronico",
          code: "fun0101_remito_electronico",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="25201" alt="Monitor de Interfaces" />,
          url: "/monitor-interfaces",
          code: "fun0047_monitor_interfaces",
          template: itemRenderer,
        },
      ],
    },

    //Seguridad
    {
      label: <LanguageProvider id="65" alt="Seguridad" />,
      items: [
        {
          label: <LanguageProvider id="1685" alt="Mantener Servicos" />,
          url: "/mantener-servicos",
          code: "fun0213_mantener_servicios",
          template: itemRenderer,
        },
        {
          label: <LanguageProvider id="67" alt="Mantener Usuarios" />,
          url: "/mantener-usuarios",
          code: "fun0004_mantener_usuarios",
          template: itemRenderer,
        },
      ],
    },
  ];

  const setMenuVisibility = (menuArray) => {
    const { permisos } = user;
    // Função para verificar permissões
    const checkPermissions = (code) => {
      if (!permisos || !Array.isArray(permisos)) {
        return false;
      }
      return permisos.includes(code);
    };
    // Função recursiva para verificar visibilidade de um menu e seus submenus
    const checkVisibility = (menuItem) => {
      let hasVisibleChild = false;

      // Se o menu tem submenus, aplica a função recursivamente
      if (menuItem.items && Array.isArray(menuItem.items)) {
        menuItem.items = menuItem.items.map((subItem) =>
          checkVisibility(subItem)
        );
        // Verifica se algum submenu é visível
        hasVisibleChild = menuItem.items.some((item) => item.visible);
      }

      // Define a visibilidade do menu baseado nas permissões e se há submenus visíveis
      const isVisible = checkPermissions(menuItem.code) || hasVisibleChild;

      return {
        ...menuItem,
        visible: isVisible,
      };
    };
    // Aplica a função recursivamente para todos os menus
    return menuArray.map((menuItem) => checkVisibility(menuItem));
  };

  const updatedMenuArray = setMenuVisibility(menuItems);

  useEffect(() => {
    setItems(updatedMenuArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Header-Nav-Menu">
      <Header />
      <div className="nav-menu">
        <Menubar model={items} />
      </div>
    </div>
  );
};

const itemRenderer = (item) => (
  <NavLink
    className="flex align-items-center p-menuitem-link"
    to={item.url}
    onClick={(e) => {
      localStorage.setItem("pageCode", item.code);
    }}
  >
    <span className="mx-2">{item.label}</span>
  </NavLink>
);

export default NavMenu;
