import "./ReingresoStock.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";

export function ReingresoStock() {
  const SCREEN_CODE = "fun0062_reingreso_stock";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  usePageViews();

  const [validationVinDialog, setValidationVinDialog] = useState(false);
  const [elVinStockDialog, setElVinStockDialog] = useState(false);
  const [elVinTieneRemitoDialog, setElVinTieneRemitoDialog] = useState(false);
  const [noExisteVinDialog, setNoExisteVinDialog] = useState(false);
  const usuarioLogado = JSON.parse(localStorage.getItem("loginData"));

  const showError = (id: string, alt: string) => {
    toast.current.show({
      severity: "error",
      summary: "Erro",
      detail: <LanguageProvider id={id} alt={alt} />,
      life: 3000,
    });
  };

  const handleAlta = async () => {
    try {
      await validationFields();
      //setGuardarCambiosDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  async function validationFields() {
    try {
      if (filter.vin.length <= 0) {
        setValidationVinDialog(true);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleVinKeyDown = (event) => {
    if (event.key === "Enter") {
      if (filter.vin.length >= 8 && filter.vin.length <= 17) {
        validaVin();
        //loadVin(filter.vin);
      } else {
        return showError(
          "653",
          "Debe ingresar un minimo de 8 y un maximo de 17 dígitos"
        );
      }
    }
  };

  async function validaVin() {
    try {
      const filters = {
        pais: securityFilters.securityValues.country ?? null,
        cliente: securityFilters.securityValues.client ?? null,
        cuenta: securityFilters.securityValues.account ?? null,
        vin: { id: filter?.vin },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/reingreso-stock-ar/validar",
        filters
      );

      validationDialogs(status, data);
    } catch (error) {
      console.error(error);
    }
  }

  async function validationDialogs(status, data) {
    try {
      if (status === 200 && Number(data) === 1094) {
        setElVinStockDialog(true);
      } else if (status === 200 && Number(data) === 1096) {
        setElVinTieneRemitoDialog(true);
      } else if (status === 200 && Number(data) === 654) {
        setNoExisteVinDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // async function aProcesar() {
  //   setLoadingProcesar(true);
  //   try {
  //     const filters = {
  //       url: "",
  //       pais: securityFilters.securityValues.country ?? null,
  //       cliente: securityFilters.securityValues.client ?? null,
  //       cuenta: securityFilters.securityValues.account ?? null,
  //       subcuenta: {
  //         ...securityFilters.securityValues.subaccount,
  //         subcuentaDetalle: securityFilters.subAccountDetails,
  //       },
  //       vin: filter.vin,
  //       transportista: { id: model?.transportista.id || null },
  //       chofer: { id: model?.chofer.id || null },
  //       serie: model?.serieNfBenef || null,
  //       notaFiscal: { id: model?.codNfBenef || null },
  //       vinMarketing: model.vinMarketing,
  //       fechaNfProveedor: model.fechaNfBenef,
  //       fechaDevolucion: filter.fecha.getTime(),
  //     };

  //     const { status } = await sipcoAxiosService.post(
  //       "/devolucion-man/devolucion-man",
  //       filters
  //     );
  //     if (status === 200) {
  //       // setConfirmProcessDialog(true);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoadingProcesar(false);
  //   }
  // }

  const handleValidationClose = () => {
    setValidationVinDialog(false);
    setElVinStockDialog(false);
    setElVinTieneRemitoDialog(false);
    setNoExisteVinDialog(false);
  };

  const [filter, setFilter] = useState({
    vin: "",
  });

  const [model, setModel] = useState({
    marca: "",
    modelo: "",
    origen: "",
    color: "",
    motor: "",
    playas: [],
    calle: [],
    columna: [],
    nivel: [],
    nota: "",
    cobraFlete: false,
    tramo: [],
    provincia: [],
    ciudad: [],
    transportista: [],
  });

  const [vin, setVin] = useState([]);
  async function loadVin(vin: any) {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/vin",
          {
            vin: {
              pais: securityFilters.securityValues.country,
              cliente: securityFilters.securityValues.client,
              cuenta: securityFilters.securityValues.account,
              id: vin,
            },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [loadingPlaya, setLoadingPlaya] = useState(false);
  const [playa, setPlaya] = useState([]);
  async function loadPlayas() {
    setLoadingPlaya(true);
    try {
      if (
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.account
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/subcuenta",
          {
            usuario: {
              id: usuarioLogado.userName,
              idioma: {
                id: usuarioLogado.idioma.codIdioma,
              },
              nivel: usuarioLogado.nivel,
            },
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
          }
        );
        if (status === 200) {
          const playasData = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setPlaya(playasData);
          return playasData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlaya(false);
    }
    return [];
  }

  const [loadingCalle, setLoadingCalle] = useState(false);
  const [calle, setCalle] = useState([]);
  async function loadCalle(playa: any) {
    setLoadingCalle(true);
    try {
      if (playa !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/calle",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: playa,
          }
        );
        if (status === 200) {
          setCalle(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalle(false);
    }
    return [];
  }

  const [loadingColumna, setLoadingColumna] = useState(false);
  const [columna, setColumna] = useState([]);
  async function loadColumna(calle: any) {
    setLoadingColumna(true);
    try {
      if (calle !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/columna",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: model?.playas,
            calle: calle,
          }
        );
        if (status === 200) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
    return [];
  }

  const [loadingNivel, setLoadingNivel] = useState(false);
  const [nivel, setNivel] = useState([]);
  async function loadNivel(calle: any, columna: any, playa: any) {
    setLoadingNivel(true);
    try {
      if (calle && columna !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/nivel",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: playa,
            calle: calle,
            columna: columna,
          }
        );
        if (status === 200) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
    return [];
  }

  const [loadingTramo, setLoadingTramo] = useState(false);
  const [tramo, setTramo] = useState([]);
  async function loadTramo() {
    setLoadingTramo(true);
    try {
      if (
        model.cobraFlete === true &&
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/tramo",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          setTramo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTramo(false);
    }
    return [];
  }

  const [loadingProvincia, setLoadingProvincia] = useState(false);
  const [provincia, setProvincia] = useState([]);
  async function loadProvincia() {
    setLoadingProvincia(true);
    try {
      if (
        model.cobraFlete === true &&
        securityFilters?.securityValues?.country
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/provincia",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          const provData = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setProvincia(provData);
          return provData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProvincia(false);
    }
    return [];
  }

  const [loadingCiudad, setLoadingCiudad] = useState(false);
  const [ciudad, setCiudad] = useState([]);
  async function loadCiudad() {
    setLoadingCiudad(true);
    try {
      if (model.cobraFlete === true && model.provincia !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/ciudad",
          {
            provincia: model?.provincia,
          }
        );
        if (status === 200) {
          setCiudad(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCiudad(false);
    }
    return [];
  }

  const [loadingTransportista, setLoadingTransportista] = useState(false);
  const [transportista, setTransportista] = useState([]);
  async function loadTransportista() {
    setLoadingTransportista(true);
    try {
      if (
        model.cobraFlete === true &&
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.account &&
        securityFilters?.securityValues?.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/reingreso-stock-ar/proveedor",
          {
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === 200) {
          setTransportista(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransportista(false);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters.securityValues.account) {
        try {
          const playas = await loadPlayas();

          setModel((prevModel) => ({
            ...prevModel,
            playas: playas?.length > 0 ? playas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  useEffect(() => {
    async function updateCalle() {
      if (model?.playas) {
        try {
          const calles = await loadCalle(model?.playas);

          setModel((prevModel) => ({
            ...prevModel,
            calle: calles?.length > 0 ? calles[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateCalle();
  }, [model?.playas]);

  useEffect(() => {
    async function updateColumna() {
      if (model?.calle) {
        try {
          const columnas = await loadColumna(model?.calle);

          setModel((prevModel) => ({
            ...prevModel,
            columna: columnas?.length > 0 ? columnas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateColumna();
  }, [model?.calle]);

  useEffect(() => {
    async function updateNivel() {
      if (model?.calle && model?.columna) {
        try {
          const niveles = await loadNivel(
            model?.calle,
            model?.columna,
            model?.playas
          );

          setModel((prevModel) => ({
            ...prevModel,
            nivel: niveles?.length > 0 ? niveles[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateNivel();
  }, [model?.columna]);

  useEffect(() => {
    async function dependsOnProvincia() {
      if (provincia.length > 0) {
        try {
          const ciudads = await loadCiudad();

          setModel({
            ...model,
            ciudad: ciudads?.length > 0 ? ciudads[0] : null,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    dependsOnProvincia();
  }, [model?.provincia]);

  useEffect(() => {
    async function initializeCobra() {
      if (
        model.cobraFlete === true &&
        securityFilters.securityValues.country != null &&
        securityFilters.securityValues.client != null
      ) {
        try {
          const [tramos, provincia, transportistas] = await Promise.all([
            loadTramo(),
            loadProvincia(),
            loadTransportista(),
          ]);

          setModel({
            ...model,
            tramo: tramos[0],
            provincia: provincia[0],
            transportista: transportistas[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initializeCobra();
  }, [securityFilters.securityValues, model.cobraFlete]);

  return (
    <div className="reingreso-stock">
      <Toast ref={toast} position="top-center" />
      <Filter
        securityOptions={{
          country: true,
          client: true,
          account: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Panel header={<LanguageProvider id="318" alt="Reingreso Gefco" />}>
        <div className="form">
          <div className="form-row">
            <div className={"sipco-option-vin"}>
              <label>
                <LanguageProvider id="901" alt="Ingrese el VIN" />
              </label>
              <SipcoAutocomplete
                value={filter.vin}
                suggestions={vin}
                onKeyDown={handleVinKeyDown}
                onChange={(e) => setFilter({ ...filter, vin: e.value.trim() })}
                field="id"
              />
            </div>
          </div>
        </div>
        <div className="panel-container">
          <Panel
            className="left-panel"
            header={<LanguageProvider id="1136" alt="Datos del Vehículo" />}
          >
            <div className="form">
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id="396" alt="Marca" />
                  </label>
                  <SipcoInputText
                    value={model?.marca}
                    onChange={(e) =>
                      setModel({ ...model, marca: e.target.value })
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id="2139" alt="Modelo" />
                  </label>
                  <SipcoInputText
                    value={model?.modelo}
                    onChange={(e) =>
                      setModel({ ...model, modelo: e.target.value })
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id="2496" alt="Origen" />
                  </label>
                  <SipcoInputText
                    value={model?.origen}
                    onChange={(e) =>
                      setModel({ ...model, origen: e.target.value })
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id="2140" alt="Color" />
                  </label>
                  <SipcoInputText
                    value={model?.color}
                    onChange={(e) =>
                      setModel({ ...model, color: e.target.value })
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id="13335" alt="Motor" />
                  </label>
                  <SipcoInputText
                    value={model?.motor}
                    onChange={(e) =>
                      setModel({ ...model, motor: e.target.value })
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </Panel>
          <Panel
            className="right-panel"
            header={
              <LanguageProvider
                id="1138"
                alt="Ingrese los Datos para el Reingreso del Vehículo"
              />
            }
          >
            <div className="form">
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <span className="required">*</span>
                    <LanguageProvider id="441" alt="Playas" />
                  </label>
                  <SipcoDropdown
                    value={model.playas}
                    options={playa}
                    onChange={(e) => setModel({ ...model, playas: e.value })}
                    optionLabel="label"
                    loading={loadingPlaya}
                    filter
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <span className="required">*</span>
                    <LanguageProvider id="990" alt="Calle" />
                  </label>
                  <SipcoDropdown
                    value={model.calle}
                    options={calle}
                    onChange={(e) => setModel({ ...model, calle: e.value })}
                    optionLabel="label"
                    loading={loadingCalle}
                    filter
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <span className="required">*</span>
                    <LanguageProvider id="991" alt="Columna" />
                  </label>
                  <SipcoDropdown
                    value={model.columna}
                    options={columna}
                    onChange={(e) => setModel({ ...model, columna: e.value })}
                    optionLabel="label"
                    loading={loadingColumna}
                    filter
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <span className="required">*</span>
                    <LanguageProvider id="992" alt="Nivel" />
                  </label>
                  <SipcoDropdown
                    value={model.nivel}
                    options={nivel}
                    onChange={(e) => setModel({ ...model, nivel: e.value })}
                    optionLabel="label"
                    loading={loadingNivel}
                    filter
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id="1410" alt="Nota" />
                  </label>
                  <SipcoInputText
                    value={model.nota}
                    onChange={(e) =>
                      setModel({ ...model, nota: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <Panel
              className="right-panel-detail"
              header={
                <div className="header-container">
                  <Checkbox
                    checked={model.cobraFlete}
                    onChange={() => {
                      setModel({
                        ...model,
                        cobraFlete: !model.cobraFlete,
                      });
                    }}
                  />
                  <LanguageProvider id="1331" alt="¿Cobra Flete?" />
                </div>
              }
            >
              <div className="form">
                <div className="form-row">
                  <div className="sipco-option">
                    <label>
                      <span className="required">*</span>
                      <LanguageProvider id={"10672"} alt="Tramo" />
                    </label>
                    <SipcoDropdown
                      value={model.tramo}
                      options={tramo}
                      onChange={(e) => setModel({ ...model, tramo: e.value })}
                      optionLabel="descripcionFull"
                      loading={loadingTramo}
                      filter
                      disabled={!model.cobraFlete}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <span className="required">*</span>
                      <LanguageProvider id="159" alt="Provincia" />
                    </label>
                    <SipcoDropdown
                      value={model.provincia}
                      options={provincia}
                      onChange={(e) =>
                        setModel({ ...model, provincia: e.value })
                      }
                      optionLabel="label"
                      loading={loadingProvincia}
                      filter
                      disabled={!model.cobraFlete}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="sipco-option">
                    <label>
                      <span className="required">*</span>
                      <LanguageProvider id="164" alt="Ciudad" />
                    </label>
                    <SipcoDropdown
                      value={model.ciudad}
                      options={ciudad}
                      onChange={(e) => setModel({ ...model, ciudad: e.value })}
                      optionLabel="descripcion"
                      loading={loadingCiudad}
                      filter
                      disabled={!model.cobraFlete}
                      virtualScrollerOptions={{ itemSize: 30 }}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="sipco-option">
                    <label>
                      <span className="required">*</span>
                      <LanguageProvider id="754" alt="Transportista" />
                    </label>
                    <SipcoDropdown
                      value={model.transportista}
                      options={transportista}
                      onChange={(e) =>
                        setModel({ ...model, transportista: e.value })
                      }
                      optionLabel="descripcion"
                      loading={loadingTransportista}
                      filter
                      disabled={!model.cobraFlete}
                    />
                  </div>
                </div>
              </div>
            </Panel>
          </Panel>
        </div>
        <div className="flex flex-wrap gap-2 mt-5">
          <Button
            label={LanguageProvider({
              id: "23824",
              alt: "Alta",
            })}
            icon="pi pi-plus-circle"
            onClick={handleAlta}
          />
        </div>
        <div className="dialogs">
          {/* Debe ingresar el VIN Dialog */}
          <Dialog
            header={<LanguageProvider id="16239" alt="Atención" />}
            visible={validationVinDialog}
            style={{ width: "7vw", textAlign: "center" }}
            footer={
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Button
                  label={getLabel("5599", "OK")}
                  icon="pi pi-check"
                  onClick={handleValidationClose}
                  autoFocus
                />
              </div>
            }
            onHide={handleValidationClose}
          >
            <p>
              <LanguageProvider id="1062" alt="Debe ingresar el VIN." />
            </p>
          </Dialog>
          {/* El vin esta en stock, no se puede hacer el reingreso Dialog */}
          <Dialog
            header={<LanguageProvider id="16239" alt="Atención" />}
            visible={elVinStockDialog}
            style={{ width: "7vw", textAlign: "center" }}
            footer={
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Button
                  label={getLabel("5599", "OK")}
                  icon="pi pi-check"
                  onClick={handleValidationClose}
                  autoFocus
                />
              </div>
            }
            onHide={handleValidationClose}
          >
            <p>
              <LanguageProvider
                id="1094"
                alt="El vin esta en stock, no se puede hacer el reingreso."
              />
            </p>
          </Dialog>
          {/* El vin tiene un remito de devolución nuevo Dialog */}
          <Dialog
            header={<LanguageProvider id="29" alt="Mensaje del Sistema" />}
            visible={elVinTieneRemitoDialog}
            style={{ width: "15vw", textAlign: "center" }}
            footer={
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Button
                  label={getLabel("5599", "OK")}
                  icon="pi pi-check"
                  onClick={handleValidationClose}
                  autoFocus
                />
              </div>
            }
            onHide={handleValidationClose}
          >
            <p>
              <LanguageProvider
                id="25229"
                alt="El vin tiene un remito de devolución nuevo,retenido o liberado, asociado no se puede realizar elreingreso debe usar la funcion devolución para agresarlo a stock."
              />
            </p>
          </Dialog>
          {/* No existe el VIN Dialog */}
          <Dialog
            header={<LanguageProvider id="16239" alt="Atención" />}
            visible={noExisteVinDialog}
            style={{ width: "7vw", textAlign: "center" }}
            footer={
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <Button
                  label={getLabel("5599", "OK")}
                  icon="pi pi-check"
                  onClick={handleValidationClose}
                  autoFocus
                />
              </div>
            }
            onHide={handleValidationClose}
          >
            <p>
              <LanguageProvider id="654" alt="No existe el VIN" />
            </p>
          </Dialog>
        </div>
      </Panel>
    </div>
  );
}
export default ReingresoStock;

const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
