import "./MantenerPermisosCalidad.scss";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useEffect, useRef, useState } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { PermisosCalidad } from "src/models/PermisosCalidad";
import useAuth from "@shared/AuthContext";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoColumn from "@shared/components/sipco-column";
import { Checkbox } from "primereact/checkbox";
import { Panel } from "primereact/panel";
import { useFilterContext } from "../../../protected-routes";

const MantenerPermisosCalidad = () => {
  const SCREEN_CODE = "abm0062_mantener_permisos_calidad";
  const URL_BASE = "/mantener-permisos-calidad";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const dt = useRef(null);
  const { user } = useAuth();

  const seleccionatTodo = <LanguageProvider id="748" alt="Seleccionar todo" />;

  const [usuario, setUsuario] = useState(null);

  const [loadingReportCheck, setLoadingReportCheck] = useState(false);
  const [reporteCheckPoints, setReporteCheckPoints] = useState(null);
  const [selectedReportCheck, setSelectedReportCheck] = useState(null);

  const [loadingReportImput, setLoadingReportImput] = useState(false);
  const [reporteImputaciones, setReporteImputaciones] = useState(null);
  const [selectedReportImput, setSelectedReportImput] = useState(null);

  const [loadingReportResp, setLoadingReportResp] = useState(false);
  const [reporteResponsables, setReporteResponsables] = useState(null);
  const [selectedReportResp, setSelectedReportResp] = useState(null);

  const [models, setModels] = useState({
    checkpoint: false,
    imputacion: false,
    responsable: false,
  });

  async function handleSave(model: PermisosCalidad, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const permisoCalidad = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          usuario: { id: usuario?.id },
          checkpoint: { id: selectedReportCheck?.id },
          imputacion: { id: selectedReportImput?.id },
          responsable: { id: selectedReportResp?.id },
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          permisoCalidad: permisoCalidad,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: PermisosCalidad) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        permisoCalidad: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<PermisosCalidad[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter?.securityValues?.country,
          cliente: filter?.securityValues?.client,
          cuenta: filter?.securityValues?.account,
          usuario: { id: usuario?.id },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadUsuario(filter: any): Promise<[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/usuario",
          {
            pais: filter?.securityValues?.country,
            descripcionUsuario: user?.id,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadCheckPoints(): Promise<any[]> {
    try {
      if (securityFilters?.securityValues?.country !== null) {
        setLoadingReportCheck(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/checkpoint",
          {
            pais: { id: securityFilters?.securityValues?.country?.id },
          }
        );
        if (status === 200) {
          setReporteCheckPoints(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportCheck(false);
    }
    return [];
  }

  async function loadImputaciones(): Promise<any[]> {
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client !== null
      ) {
        setLoadingReportImput(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/imputacion",
          {
            pais: { id: securityFilters?.securityValues?.country?.id },
            cliente: { id: securityFilters?.securityValues?.client?.id },
          }
        );
        if (status === 200) {
          setReporteImputaciones(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportImput(false);
    }
    return [];
  }

  async function loadResponsables(): Promise<any[]> {
    try {
      if (
        securityFilters?.securityValues?.country &&
        selectedReportImput !== null &&
        models.imputacion === false
      ) {
        setLoadingReportResp(true);

        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/responsable",
          {
            pais: { id: securityFilters?.securityValues?.country?.id },
            imputaciones: [{ id: selectedReportImput?.id }],
          }
        );
        if (status === 200) {
          setReporteResponsables(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportResp(false);
    }
    return [];
  }

  async function loadAllResponsables(): Promise<any[]> {
    try {
      if (
        securityFilters?.securityValues?.country &&
        selectedReportImput !== null &&
        models.imputacion === true
      ) {
        setLoadingReportResp(true);

        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/responsable",
          {
            pais: { id: securityFilters?.securityValues?.country?.id },
            imputaciones: [],
          }
        );
        if (status === 200) {
          setReporteResponsables(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportResp(false);
    }
    return [];
  }

  useEffect(() => {
    loadCheckPoints();
  }, [usuario]);

  useEffect(() => {
    loadImputaciones();
  }, [usuario, securityFilters?.securityValues?.client]);

  useEffect(() => {
    loadResponsables();
  }, [usuario, selectedReportImput, !models.imputacion]);

  useEffect(() => {
    loadAllResponsables();
  }, [models.imputacion]);

  return (
    <GenericAbm<PermisosCalidad>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="630" alt="Mantener Permisos de Calidad" />}
      save={handleSave}
      update={null}
      remove={handleRemove}
      list={handleList}
      get={null}
      defaultValues={{
        id: "",
        pais: null,
        usuario: null,
        checkpoint: null,
        imputacion: null,
        responsable: null,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
        },
      }}
      filterSettings={[
        {
          filter: "usuario",
          label: LanguageProvider({ id: "3741", alt: "Usuario" }),
          placeholder: LanguageProvider({ id: "3741", alt: "Usuario" }),
          type: "dropdown",
          dataSource: loadUsuario,
          dropDownTemplate: DropdownOptionTemplate,
          onChange: (e) => {
            setUsuario(e);
          },
          dependency: ["securityValues.country"],
        },
      ]}
      dataTableColumns={[
        {
          field: "checkpoint.id",
          header: <LanguageProvider id="16622" alt="Cód. Checkpoint" />,
        },
        {
          field: "checkpoint.descripcion",
          header: <LanguageProvider id="11314" alt="CheckPoint" />,
        },
        {
          field: "imputacion.descripcion",
          header: <LanguageProvider id="7811" alt="Imputación" />,
        },
        {
          field: "responsables",
          header: <LanguageProvider id="336" alt="Responsables" />,
          body: (rowData) => {
            return rowData.responsables.map((r) => r.descripcion).join(", ");
          },
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      formSettings={[]}
      customForm={
        <div className="mantener-permisos-calidad">
          <div className="form">
            <Panel className="panel-checkPoints">
              <div className="sipco-option">
                <p>
                  <LanguageProvider id="256" alt="CheckPoints" />
                </p>
                <div className="sipco-check">
                  <label className="checkbox-label">{seleccionatTodo}</label>
                  <Checkbox
                    checked={models.checkpoint}
                    onChange={(e) => {
                      setModels({
                        ...models,
                        checkpoint: !models.checkpoint,
                      });
                    }}
                  />
                </div>
              </div>
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={reporteCheckPoints}
                paginator
                scrollHeight="flex"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                resizableColumns
                removableSort
                filterDisplay="menu"
                loading={loadingReportCheck}
                selectionMode="single"
                selection={selectedReportCheck}
                onSelectionChange={(e) => setSelectedReportCheck(e.value)}
              >
                <SipcoColumn field="descripcion" />
              </SipcoDataTable>
            </Panel>

            <Panel className="panel-Imputaciones">
              <div className="sipco-option">
                <p>
                  <LanguageProvider id="486" alt="Imputaciones" />
                </p>
                <div className="sipco-check">
                  <label className="checkbox-label">{seleccionatTodo}</label>
                  <Checkbox
                    checked={models.imputacion}
                    onChange={(e) => {
                      setModels({
                        ...models,
                        imputacion: !models.imputacion,
                      });
                    }}
                  />
                </div>
              </div>
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={reporteImputaciones}
                paginator
                scrollHeight="flex"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                resizableColumns
                removableSort
                filterDisplay="menu"
                loading={loadingReportImput}
                selectionMode="single"
                selection={selectedReportImput}
                onSelectionChange={(e) => setSelectedReportImput(e.value)}
              >
                <SipcoColumn field="descripcion" />
              </SipcoDataTable>
            </Panel>

            <Panel className="panel-responsables">
              <div className="sipco-option">
                <p>
                  <LanguageProvider id="336" alt="Responsables" />
                </p>
                <div className="sipco-check">
                  <label className="checkbox-label">{seleccionatTodo}</label>
                  <Checkbox
                    checked={models.responsable}
                    onChange={(e) => {
                      setModels({
                        ...models,
                        responsable: !models.responsable,
                      });
                    }}
                  />
                </div>
              </div>
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={reporteResponsables}
                paginator
                scrollHeight="flex"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                resizableColumns
                removableSort
                filterDisplay="menu"
                loading={loadingReportResp}
                selectionMode="single"
                selection={selectedReportResp}
                onSelectionChange={(e) => setSelectedReportResp(e.value)}
              >
                <SipcoColumn field="descripcion" />
              </SipcoDataTable>
            </Panel>
          </div>
        </div>
      }
    />
  );
};

export default MantenerPermisosCalidad;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
