import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Servicio } from "src/models/Servicio";

const ServiciosFacturar = () => {
  const SCREEN_CODE = "abm0239_servicios_facturar";
  const URL_BASE = "/mantener-servicios-facturar";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: Servicio, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const servicio = {
          ...model,
          pais: filterValues?.securityValues?.country,
          cuenta: filterValues?.securityValues?.account,
          cliente: filterValues?.securityValues?.client,
          movimientoFacturar: model.movimientoFacturar,
          precio: model.precio,
          centroCostoPSA: model.centroCostoPSA,
          ordenPSA: model.ordenPSA,
          cuentaPSA: model.cuentaPSA,
          cnpj: model.cnpj,
          centroCostoGefco: model.centroCostoGefco,
          tes: model.tes,
          naturaleza: model.naturaleza,
          producto: model.producto,
          cfop: model.cfop,
          fecBaja: model.fecBaja,
          fecUltimoCierre: model.fecUltimoCierre,
          activo: model.activo,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          servicio,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: Servicio, filterValues: any) {
    try {
      const servicio = {
        ...model,
        pais: filterValues?.securityValues?.country,
        cuenta: filterValues?.securityValues?.account,
        cliente: filterValues?.securityValues?.client,
        movimientoFacturar: model.movimientoFacturar,
        precio: model.precio,
        centroCostoPSA: model.centroCostoPSA,
        ordenPSA: model.ordenPSA,
        cuentaPSA: model.cuentaPSA,
        cnpj: model.cnpj,
        centroCostoGefco: model.centroCostoGefco,
        tes: model.tes,
        naturaleza: model.naturaleza,
        producto: model.producto,
        cfop: model.cfop,
        fecBaja: model.fecBaja,
        fecUltimoCierre: model.fecUltimoCierre,
        activo: model.activo,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        servicio,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Servicio, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        servicio: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Servicio[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter.securityValues.account,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<Servicio> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          servicio: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  // Forms Modail 
  async function loadMovimentoFacturar(filter: any): Promise<Servicio> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/form/movimiento-facutrar",
        {
          pais: filter?.securityValues?.country,
          cliente: filter?.securityValues?.client,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }


  return (
    <GenericAbm<Servicio>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
        },
      }}
      dataTableColumns={[
        {
          field: "id",
          header: <LanguageProvider id="23876" alt="Código" />,
        },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="Descripcion" />,
        },

        {
          field: "movimientoFacturar.descripcion",
          header: <LanguageProvider id="22098" alt="Movimiento a Facturar" />,
        },


        {
          field: "precio",
          header: <LanguageProvider id="17329" alt="Precio" />,
        },



        {
          field: "centroCostoPSA",
          header: <LanguageProvider id="15245" alt="Centro Costo PSA" />,
        },

        {
          field: "ordenPSA",
          header: <LanguageProvider id="13865" alt="Orden PSA" />,
        },


        {
          field: "cuentaPSA",
          header: <LanguageProvider id="7121" alt="Cuenta PSA" />,
        },

        {
          field: "cnpj",
          header: <LanguageProvider id="14794" alt="CNPJ" />,
        },

        {
          field: "centroCostoGefco",
          header: <LanguageProvider id="15243" alt="Centro Costo Gefco" />,
        },

        {
          field: "tes",
          header: <LanguageProvider id="22099" alt="Tes" />,
        },

        {
          field: "naturaleza",
          header: <LanguageProvider id="22100" alt="Naturaleza" />,
        },

        {
          field: "producto",
          header: <LanguageProvider id="15247" alt="Producto" />,
        },

        {
          field: "cfop",
          header: <LanguageProvider id="10974" alt="CFOP" />,
        },

        {
          field: "fecBaja.date",
          header: <LanguageProvider id="6197" alt="Fecha Baja" />,
        },

        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="2131" alt="Fecha" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
        cuenta: null,
        centroCostoGefco: '',
        centroCostoPSA: '',
        cfop: '',
        cnpj: '',
        cuentaPSA: '',
        fecBaja: null,
        fecUltimoCierre: null,
        movimientoFacturar: null,
        naturaleza: '',
        ordenPSA: '',
        precio: 0,
        producto: '',
        tes: '',
        cliente: null,
        descripcion: '',
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          disabled: true,
        },
        {
          field: "movimientoFacturar",
          label: <LanguageProvider id="22098" alt="Movimiento a Facturar" />,
          type: "dropdown",
          dataSource: filter => loadMovimentoFacturar(filter),
          optionLabel: "descripcion",
        },

        // Firt Columna 
        {
          field: "centroCostoPSA",
          label: <LanguageProvider id="15245" alt="Centro Costo PSA" />,
          type: "inputText",
          length: 10,
        },
        {
          field: "cuentaPSA",
          label: <LanguageProvider id="7121" alt="Cuenta PSA" />,
          type: "inputText",
          length: 8,
        },
        {
          field: "centroCostoGefco",
          label: <LanguageProvider id="15243" alt="Centro Costo Gefco" />,
          type: "inputText",
          length: 13,
        },
        {
          field: "naturaleza",
          label: <LanguageProvider id="22100" alt="Naturaleza" />,
          type: "inputText",
          length: 3,
        },
        {
          field: "cfop",
          label: <LanguageProvider id="10974" alt="CFOP" />,
          type: "inputText",
          length: 4,
        },



       // Secund Columna 
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripcion" />,
          type: "inputText",
          required: true,
        },
        {
          field: "precio",
          label: <LanguageProvider id="17329" alt="Precio" />,
          type: "inputText",
          required: true,
          length: 8,
        },
        {
          field: "ordenPSA",
          label: <LanguageProvider id="13865" alt="Orden PSA" />,
          type: "inputText",
          length: 10,
        },
        {
          field: "cnpj",
          label: <LanguageProvider id="14794" alt="CNPJ" />,
          type: "inputText",
          length: 20,
        },
        {
          field: "tes",
          label: <LanguageProvider id="22099" alt="Tes" />,
          type: "inputText",
          length: 3,
        },
        {
          field: "producto",
          label: <LanguageProvider id="15247" alt="Producto" />,
          type: "inputText",
          length: 8,
        },


        {
          field: "Activo",
          label: <LanguageProvider id="23849" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default ServiciosFacturar;
