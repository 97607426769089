import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useRef, useState } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ClienteImputacion } from "src/models/ClienteImputacion";

const MantenerClienteImputaciones = () => {
  const SCREEN_CODE = "abm0041_mantener_cliente_imputaciones";
  const URL_BASE = "/mantener-cliente-imputaciones";
  const SCREEN_TITLE = (
    <LanguageProvider id="476" alt="Mantener Imputaciones" />
  );
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ClienteImputacion, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client
      ) {
        const clienteImputacion = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          clienteImputacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ClienteImputacion, filterValues: any) {
    try {
      const clienteImputacion = {
        imputacion: {id: model.id},
        pais: model.pais,
        cliente: model.cliente,
        activo: model.activo
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        clienteImputacion,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: ClienteImputacion, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        clienteImputacion: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ClienteImputacion[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter?.securityValues?.country,
          cliente: filter?.securityValues?.client,
        }
      );
      if (status === 200)
        if (data.length > 0) {
          const newData = data.map((item: any) => ({
            ...item,
            id: item.imputacion.id,
          }));
          return newData;
        } else {
          return [];
        }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<ClienteImputacion> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          clienteImputacion: model,
        }
      );
      if (status === 200) {
        data.id = data.imputacion.id
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadImputacion(filter: any): Promise<ClienteImputacion[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/imputacion",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            return data;
          } else {
            return [];
          }
        }
      } catch (error) {
        console.error(error);
      }
      return [];
    }
  }

  return (
    <GenericAbm<ClienteImputacion>
      screenCode={SCREEN_CODE}
      title={SCREEN_TITLE}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      // filterSettings={}
      defaultValues={{
        id: "",
        activo: true,
        pais: null,
        descripcion: "",
        cliente: null,
        imputacion: null,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      dataTableColumns={[
        {
          field: "cliente.id",
          header: <LanguageProvider id="16245" alt=" Cód. Cliente" />,
        },
        {
          field: "cliente.descripcion",
          header: <LanguageProvider id="54" alt="Cliente" />,
        },
        {
          field: "imputacion.descripcion",
          header: <LanguageProvider id="486" alt="Imputaciones" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      loadDropdownData={{ imputacion: loadImputacion }}
      formSettings={[
        {
          field: "id",
          label: LanguageProvider({ id: "6590 ", alt: "Código" }),
          type: "inputText",
          disabled: true,
        },
        {
          field: "imputacion",
          label: LanguageProvider({ id: "486", alt: "x" }),
          dropDownPlaceholder: LanguageProvider({ id: "486", alt: "x" }),
          type: "dropdown",
          dataSource: (filter: any) => loadImputacion(filter),
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          field: "activo",
          label: LanguageProvider({ id: "72", alt: "Activo" }),
          type: "checkbox",
        },
      ]}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
    />
  );
};

export default MantenerClienteImputaciones;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
