import "./ImpresionManualGuias.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import useRowStyles from "../../../hooks/useRowStyles";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ImpresionManualGuias() {
  const SCREEN_CODE = "fun0121_impresion_manual_guias";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);
  const [reportePanel, setReportePanel] = useState(null);
  const [reporteDT, setReporteDT] = useState(null);

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [selectedReportData, setSelectedReportData] = useState(null);

  const [validationDatosDialog, setValidationDatosDialog] = useState(false);

  const [loadingSecuencia, setLoadingSecuencia] = useState(false);
  const [reportSecuencia, setReportSecuencia] = useState("");

  const [loadingImprimirDialog, setLoadingImprimirDialog] = useState(false);
  const [ImprimirDialog, setImprimirDialog] = useState(false);

  const [loadingConfirmImpDialog, setLoadingConfirmImpDialog] = useState(false);
  const [confirmImpDialog, setConfirmImpDialog] = useState(false);

  const rowStyles = useRowStyles([selectedReportData]);

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleValidationClose = () => {
    setValidationDatosDialog(false);
    setConfirmImpDialog(false);
  };

  function validationFields(actionType) {
    try {
      if (
        !filter?.concesionario?.id ||
        !filter?.dirEntrega?.id ||
        !filter?.vinI?.id
      ) {
        if (actionType === "buscar") {
          showError("39", "Existen datos en blanco.");
        } else if (actionType === "impresion") {
          setValidationDatosDialog(true);
        }
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  }

  const handleImpresionManualClick = () => {
    try {
      const actionType = "impresion";
      if (validationFields(actionType)) {
        return;
      }
      if (reportePanel !== null) {
        secuencia();
        setImprimirDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleImpresionManual = (isConfirmed) => {
    if (isConfirmed) {
      if (ImprimirDialog) {
        setImprimirDialog(false);
        setConfirmImpDialog(true);
      } else if (confirmImpDialog) {
        setConfirmImpDialog(false);
        if (stateTipoOperacion.id === "0") {
          impresion();
        } else if (stateTipoOperacion.id === "1") {
          reimpresion();
        }
      }
    } else {
      setImprimirDialog(false);
      setConfirmImpDialog(false);
    }
  };

  const handleReimpresionClick = () => {
    try {
      if (stateTipoOperacion.id === "1") {
        if (!selectedReportData || selectedReportData.length <= 0) {
          showError(
            "753",
            "Debe seleccionar al menos un registro de la Grilla."
          );
          return;
        }
        setConfirmImpDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [operacion, setOperacion] = useState({
    tipoOperacion: null,
  });

  const [filter, setFilter] = useState({
    concesionario: null,
    dirEntrega: null,
    vinI: null,

    vinR: null,
    nroGuia: null,
    fechaDesde: new Date(),
    fechaHasta: new Date(),
  });

  const [loadingTipoOperacion, setLoadingTipoOperacion] = useState(false);
  const [tipoOperacion, setTipoOperacion] = useState([]);
  async function loadTipoOperacion() {
    setLoadingTipoOperacion(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/impresion_manual_guias/tipo-operacion",
          {
            pais: { id: securityFilters.securityValues.country.id },
          }
        );

        if (status === 200) {
          setTipoOperacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoOperacion(false);
    }
    setTipoOperacion([]);
    return [];
  }

  const [loadingConcesionario, setLoadingConcesionario] = useState(false);
  const [concesionario, setConcesionario] = useState([]);
  async function loadConcesionario() {
    setLoadingConcesionario(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/impresion_manual_guias/concesionario",
          {
            pais: { id: securityFilters.securityValues.country.id },
            cliente: { id: securityFilters.securityValues.client.id },
          }
        );

        if (status === 200) {
          setConcesionario(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConcesionario(false);
    }
    setConcesionario([]);
    return [];
  }

  const [loadingDirEntrega, setLoadingDirEntrega] = useState(false);
  const [dirEntrega, setDirEntrega] = useState([]);
  async function loadDirEntrega(concesionarioId: any) {
    setLoadingDirEntrega(true);
    try {
      if (securityFilters.securityValues.country && concesionarioId !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/impresion_manual_guias/direccion-entrega",
          {
            pais: { id: securityFilters.securityValues.country.id },
            cliente: { id: securityFilters.securityValues.client.id },
            concesionario: { id: concesionarioId },
          }
        );

        if (status === 200) {
          const dirEntregaData = data.map((x) => ({
            ...x,
            label: `${x.direccion} - (${x.id})`,
          }));
          setDirEntrega(dirEntregaData);
          return dirEntregaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDirEntrega(false);
    }
    setDirEntrega([]);
    return [];
  }

  const [vinI, setVinI] = useState([]);
  async function searchVinI(event: any) {
    await loadVinI(event.query);
  }
  async function loadVinI(vin: any) {
    try {
      if (securityFilters.securityValues.country && vin !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/impresion_manual_guias/vin-im",
          {
            pais: { id: securityFilters.securityValues.country.id },
            cliente: { id: securityFilters.securityValues.client.id },
            cuenta: { id: securityFilters.securityValues.account.id },
            vin: { id: vin },
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError("654", "No existe el VIN");
          }
          setVinI(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    setVinI([]);
    return [];
  }

  const [vinR, setVinR] = useState([]);
  async function searchVinR(event: any) {
    await loadVinR(event.query);
  }
  async function loadVinR(vin: any) {
    try {
      if (securityFilters.securityValues.country && vin !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/impresion_manual_guias/vin-r",
          {
            pais: { id: securityFilters.securityValues.country.id },
            cliente: { id: securityFilters.securityValues.client.id },
            cuenta: { id: securityFilters.securityValues.account.id },
            vin: { id: vin },
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError("654", "No existe el VIN");
          }
          setVinR(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    setVinR([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const tipoOperaciones = await loadTipoOperacion();
          const concesionarios = await loadConcesionario();

          if (tipoOperaciones.length > 0) {
            setOperacion((prevFilter) => ({
              ...prevFilter,
              tipoOperacion: tipoOperaciones[0],
            }));
          }

          if (concesionarios.length > 0) {
            setFilter((prevFilter) => ({
              ...prevFilter,
              concesionario: concesionarios[0],
            }));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  useEffect(() => {
    async function updateDirEntrega() {
      if (filter?.concesionario !== null) {
        try {
          const dirEntregas = await loadDirEntrega(filter?.concesionario.id);

          if (dirEntregas.length > 0) {
            setFilter((prevFilter) => ({
              ...prevFilter,
              dirEntrega: dirEntregas[0],
            }));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateDirEntrega();
  }, [filter?.concesionario]);

  async function loadReportDataI(actionType) {
    try {
      if (validationFields(actionType)) return;

      const { status, data } = await sipcoAxiosService.post(
        "/impresion_manual_guias/impresion-guia-vin",
        {
          pais: { id: securityFilters.securityValues.country.id },
          cliente: { id: securityFilters.securityValues.client.id },
          cuenta: { id: securityFilters.securityValues.account.id },
          concesionario: { id: filter?.concesionario?.id },
          direccionEntrega: { id: filter?.dirEntrega?.id },
          vin: { id: filter?.vinI?.id },
        }
      );

      if (status === 200) {
        setReportePanel(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadReportDataR() {
    setLoadingReportData(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/impresion_manual_guias/impresion-guia",
        {
          pais: { id: securityFilters.securityValues.country.id || null },
          cliente: { id: securityFilters.securityValues.client.id || null },
          cuenta: { id: securityFilters.securityValues.account.id || null },

          fechaDesde: filter.fechaDesde ? filter.fechaDesde.getTime() : null,
          fechaHasta: filter.fechaHasta ? filter.fechaHasta.getTime() : null,
          vin: { id: filter?.vinR?.id ?? null },
          nroGuia: filter?.nroGuia || null,
        }
      );

      if (status === 200) {
        setReporteDT(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  async function secuencia() {
    setLoadingSecuencia(true);
    try {
      const filters = {
        pais: { id: securityFilters.securityValues.country.id || null },
        cuenta: { id: securityFilters.securityValues.account.id || null },
        subcuenta: { id: securityFilters.securityValues.subaccount.id || null },
        tipoDocumento: { id: "GUIA" },
      };
      const { status, data } = await sipcoAxiosService.post(
        "/impresion_manual_guias/secuencia",
        filters
      );
      if (status === 200) {
        setReportSecuencia(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSecuencia(false);
    }
  }

  async function impresion() {
    // setLoadingGuardarCambios(true);
    // try {
    //   const filters = {
    //     pais: { id: securityFilters.securityValues.country.id },
    //     cliente: { id: securityFilters.securityValues.client.id },
    //     cuenta: { id: securityFilters.securityValues.account.id },
    //     //guia
    //   };
    //   const { status } = await sipcoAxiosService.post(
    //     "/impresion_manual_guias/",
    //     filters
    //   );
    //   if (status === 200) {
    //     console.log("====================================");
    //     console.log("passou");
    //     console.log("====================================");
    //     // setGuardarCambiosDialog(false);
    //     // setConfirmGuardarCDialog(true);
    //   }
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   // setLoadingGuardarCambios(false);
    // }
  }

  async function reimpresion() {
    // setLoadingGuardarCambios(true);
    // try {
    //   const filters = {
    //     //url
    //     pais: { id: securityFilters.securityValues.country.id },
    //     cliente: { id: securityFilters.securityValues.client.id },
    //     cuenta: { id: securityFilters.securityValues.account.id },
    //     //lst-guias
    //     concesionario: { id: filter.concesionario.id ?? null },
    //     direccionEntrega: { id: filter.dirEntrega.id ?? null },
    //   };
    //   const { status } = await sipcoAxiosService.post(
    //     "/impresion_manual_guias/reimpresion",
    //     filters
    //   );
    //   if (status === 200) {
    //     console.log("====================================");
    //     console.log("passou");
    //     console.log("====================================");
    //     // setGuardarCambiosDialog(false);
    //     // setConfirmGuardarCDialog(true);
    //   }
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   // setLoadingGuardarCambios(false);
    // }
  }

  const stateTipoOperacion = operacion?.tipoOperacion || "";
  const nGuia = reportSecuencia ?? "";
  const cantidadGuias = nGuia ? nGuia.split(",").length : 0;

  return (
    <div className="impresion-manual-guias">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={
          stateTipoOperacion.id === "1"
            ? loadReportDataR
            : () => loadReportDataI("buscar")
        }
        securityOptions={{
          account: true,
          country: true,
          client: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"18637"} alt="Operación" />}
        >
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3192"} alt="Tipo de operación" />
                </label>
                <SipcoDropdown
                  value={operacion?.tipoOperacion}
                  options={tipoOperacion}
                  onChange={(e) =>
                    setOperacion({ ...operacion, tipoOperacion: e.value })
                  }
                  optionLabel="descripcionFull"
                  loading={loadingTipoOperacion}
                  filter
                />
              </div>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            {(stateTipoOperacion === "" || stateTipoOperacion.id === "0") && ( // IMPRESION MANUAL
              <div className={"sipco-options-line"}>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"2941"} alt="Concesionario" />
                  </label>
                  <SipcoDropdown
                    value={filter?.concesionario}
                    options={concesionario}
                    onChange={(e) =>
                      setFilter({ ...filter, concesionario: e.value })
                    }
                    optionLabel="descripcionFull"
                    loading={loadingConcesionario}
                    filter
                  />
                </div>

                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"10904"} alt="Dir. Entrega" />
                  </label>
                  <SipcoDropdown
                    value={filter?.dirEntrega}
                    options={dirEntrega}
                    onChange={(e) =>
                      setFilter({ ...filter, dirEntrega: e.value })
                    }
                    optionLabel="label"
                    loading={loadingDirEntrega}
                    filter
                  />
                </div>

                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"1491"} alt="VIN" />
                  </label>
                  <SipcoAutocomplete
                    value={filter?.vinI ?? ""}
                    suggestions={vinI}
                    completeMethod={searchVinI}
                    onChange={(e) => setFilter({ ...filter, vinI: e.value })}
                    field="id"
                    dropdown
                    forceSelection
                    maxLength={17}
                  />
                </div>
              </div>
            )}

            {stateTipoOperacion.id === "1" && ( // REIMPRESION
              <div className={"sipco-options-line"}>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"1491"} alt="VIN" />
                  </label>
                  <SipcoAutocomplete
                    value={filter.vinR}
                    suggestions={vinR}
                    completeMethod={searchVinR}
                    onChange={(e) => setFilter({ ...filter, vinR: e.value })}
                    field="id"
                    dropdown
                    forceSelection
                    maxLength={17}
                  />
                </div>

                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"15146"} alt="Nro. Guia" />
                  </label>
                  <SipcoAutocomplete
                    value={filter.nroGuia}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        nroGuia: e.value,
                      })
                    }
                    field="label"
                  />
                </div>

                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"6367"} alt="Fecha Generación" />
                  </label>
                  <SipcoCalendar
                    value={filter.fechaDesde}
                    onChange={(e) => {
                      const date = new Date(e.value);
                      setFilter({ ...filter, fechaDesde: date });
                    }}
                    showButtonBar
                    showIcon
                    dateFormat="dd/mm/yy"
                    disabled={!filter.fechaDesde}
                  />
                </div>

                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"2622"} alt="Hasta" />
                  </label>
                  <SipcoCalendar
                    value={filter.fechaHasta}
                    onChange={(e) => {
                      const date = new Date(e.value);
                      setFilter({ ...filter, fechaHasta: date });
                    }}
                    showButtonBar
                    showIcon
                    dateFormat="dd/mm/yy"
                    disabled={!filter.fechaHasta}
                  />
                </div>
              </div>
            )}
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {
                  <LanguageProvider
                    id="25203"
                    alt="Impresíon Manual de Guias"
                  />
                }
              </span>
            </div>
          );
        }}
      >
        {(stateTipoOperacion === "" || stateTipoOperacion.id === "0") && ( // IMPRESION MANUAL -panel-container
          <div className="panels-container">
            <Panel header={<LanguageProvider id="6693" alt="Cabecera" />}>
              <div className="filter-options">
                <div className="sipco-options-line">
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id="159" alt="Provincia" />
                    </label>
                    <SipcoInputText
                      value={
                        reportePanel?.direccionEntrega?.provincia.descripcion ??
                        ""
                      }
                      disabled={true}
                    />
                    <label>
                      <LanguageProvider id="10871" alt="Cod. Concesionario" />
                    </label>
                    <SipcoInputText
                      value={reportePanel?.concesionario?.id ?? ""}
                      disabled={true}
                    />
                    <label>
                      <LanguageProvider id="3069" alt="Dirección" />
                    </label>
                    <SipcoInputText
                      value={reportePanel?.direccionEntrega?.direccion ?? ""}
                      disabled={true}
                    />
                    <label>
                      <LanguageProvider id="15141" alt="RUT" />
                    </label>
                    <SipcoInputText
                      value={reportePanel?.direccionEntrega?.cuit ?? ""}
                      disabled={true}
                    />
                  </div>

                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id="2941" alt="Concesionario" />
                    </label>
                    <SipcoInputText
                      value={reportePanel?.direccionEntrega?.descripcion ?? ""}
                      disabled={true}
                    />
                    <label>
                      <LanguageProvider id="159" alt="Provincia" />
                    </label>
                    <SipcoInputText
                      value={
                        reportePanel?.direccionEntrega?.provincia.descripcion ??
                        ""
                      }
                      disabled={true}
                    />
                    <label>
                      <LanguageProvider id="3071" alt="Teléfono" />
                    </label>
                    <SipcoInputText
                      value={reportePanel?.direccionEntrega?.telefono ?? ""}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </Panel>

            <Panel header={<LanguageProvider id="1938" alt="Cuerpo" />}>
              <div className="filter-options">
                <div className="sipco-options-line">
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id="13514" alt="Código Modelo" />
                    </label>
                    <SipcoInputText
                      value={reportePanel?.vin?.modelo?.id ?? ""}
                      disabled={true}
                    />
                    <label>
                      <LanguageProvider id="10766" alt="Chasis" />
                    </label>
                    <SipcoInputText
                      value={reportePanel?.vin.id ?? ""}
                      disabled={true}
                    />
                    <label>
                      <LanguageProvider id="2789" alt="Año" />
                    </label>
                    <SipcoInputText
                      value={reportePanel?.vin?.modelo?.anioModelo ?? ""}
                      disabled={true}
                    />
                    <label>
                      <LanguageProvider id="32" alt="Valor" />
                    </label>
                    <SipcoInputText
                      value={reportePanel?.vin?.modelo?.valor ?? ""}
                      disabled={true}
                    />
                  </div>

                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id="14833" alt="Descipción Modelo" />
                    </label>
                    <SipcoInputText
                      value={reportePanel?.vin?.modelo?.descripcion ?? ""}
                      disabled={true}
                    />
                    <label>
                      <LanguageProvider id="2140" alt="Color" />
                    </label>
                    <SipcoInputText
                      value={reportePanel?.vin?.color?.descripcion ?? ""}
                      disabled={true}
                    />
                    <label>
                      <LanguageProvider id="15142" alt="Puntero" />
                    </label>
                    <SipcoInputText
                      value={
                        reportePanel?.vin?.puntero === null
                          ? 0
                          : reportePanel?.vin?.puntero || ""
                      }
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </Panel>
          </div>
        )}

        {stateTipoOperacion.id === "1" && ( // REIMPRESION -dataTable
          <div className="panels-container">
            <Panel
              headerTemplate={(props) => {
                return (
                  <div
                    className={props.className}
                    onClick={props.onTogglerClick}
                  >
                    <span className={props.titleClassName}>
                      <LanguageProvider
                        id={"15140"}
                        alt="Impresión Manual De Guias"
                      />
                    </span>
                  </div>
                );
              }}
            >
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={reporteDT}
                paginator
                scrollHeight="flex"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                resizableColumns
                removableSort
                rowClassName={rowStyles}
                filterDisplay="menu"
                loading={loadingReportData}
                selectionMode="single"
                onSelectionChange={(e) => setSelectedReportData(e.value)}
                selection={selectedReportData}
              >
                <SipcoColumn
                  field="vin.id"
                  header={<LanguageProvider id="1491" alt="VIN" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="concesionario.descripcion"
                  header={<LanguageProvider id="2941" alt="Concesionario" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="direccionEntrega.direccion"
                  header={<LanguageProvider id="3069" alt="Dirección" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="concesionario.telefono"
                  header={<LanguageProvider id="3071" alt="Teléfono" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="direccionEntrega.provincia.descripcion"
                  header={<LanguageProvider id="159" alt="Provincia" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="direccionEntrega.cuit"
                  header={<LanguageProvider id="15141" alt="RUT" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="vin.modelo.descripcion"
                  header={
                    <LanguageProvider id="14833" alt="Descipción Modelo" />
                  }
                  sortable
                  filter
                />
                <SipcoColumn
                  field="vin.modelo.id"
                  header={<LanguageProvider id="13514" alt="Código Modelo" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="vin.numeroMotor"
                  header={<LanguageProvider id="13335" alt="Motor" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="vin.color.descripcion"
                  header={<LanguageProvider id="2140" alt="Color" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="vin.modelo.anioModelo"
                  header={<LanguageProvider id="2789" alt="Año" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="vin.puntero"
                  header={<LanguageProvider id="15142" alt="Puntero" />}
                  sortable
                  filter
                  body={(rowData) => rowData.vin.puntero || 0}
                />
                <SipcoColumn
                  field="vin.modelo.valor"
                  header={<LanguageProvider id="32" alt="Valor" />}
                  sortable
                  filter
                />
                <SipcoColumn
                  field="id"
                  header={<LanguageProvider id="15146" alt="Nro. Guia" />}
                  sortable
                  filter
                />
              </SipcoDataTable>
            </Panel>
          </div>
        )}
      </Panel>
      <div className="flex flex-wrap gap-2 mt-5">
        {(stateTipoOperacion === "" || stateTipoOperacion.id === "0") && ( // IMPRESION MANUAL --btn
          <>
            <Button
              label={LanguageProvider({ id: "15143", alt: "Impresión Manual" })}
              icon="pi pi-save"
              onClick={handleImpresionManualClick}
            />
            {/* Completar datos Dialog */}
            <Dialog
              header={<LanguageProvider id="16239" alt="Atención" />}
              visible={validationDatosDialog}
              style={{ width: "10vw", textAlign: "center" }}
              footer={
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Button
                    label={getLabel("5599", "OK")}
                    icon="pi pi-check"
                    onClick={handleValidationClose}
                    autoFocus
                  />
                </div>
              }
              onHide={handleValidationClose}
            >
              <p>
                <LanguageProvider
                  id="15144"
                  alt="Debe completar VIN, Concesionario y Dirección de entrega."
                />
              </p>
            </Dialog>

            {/* Impresion Manual Dialog */}
            <Dialog
              header={<LanguageProvider id="16239" alt="Atención" />}
              visible={ImprimirDialog}
              style={{ width: "15vw", textAlign: "center" }}
              footer={
                <div style={{ textAlign: "center", marginTop: "1rem" }}>
                  <Button
                    label={getLabel("10043", "Si")}
                    icon="pi pi-check"
                    onClick={() => handleImpresionManual(true)}
                    autoFocus
                    disabled={loadingImprimirDialog}
                  />
                  <Button
                    label={getLabel("10044", "No")}
                    icon="pi pi-times"
                    onClick={() => handleImpresionManual(false)}
                    className="p-button-text"
                    disabled={loadingImprimirDialog}
                  />
                </div>
              }
              onHide={() => setImprimirDialog(false)}
            >
              <p style={{ padding: "1rem 0", margin: "0" }}>
                <LanguageProvider id="15131" alt="Próximo Nº de GUIA ->" />
                <strong>{nGuia}</strong>
                <LanguageProvider
                  id="15132"
                  alt="Se generaran la siguiente cantidad de guias:"
                />
                <strong>{cantidadGuias}</strong>
              </p>
              {loadingSecuencia && (
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
              )}
            </Dialog>
          </>
        )}

        {stateTipoOperacion.id === "1" && ( // REIMPRESION --btn
          <Button
            label={LanguageProvider({ id: "15145", alt: "Reimpresión" })}
            icon="pi pi-save"
            onClick={handleReimpresionClick}
          />
        )}

        {/* Confirm Impresion Manual/Reimpresion Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={confirmImpDialog}
          style={{ width: "15vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("10043", "Si")}
                icon="pi pi-check"
                onClick={() => handleImpresionManual(true)}
                autoFocus
                disabled={loadingConfirmImpDialog}
              />
              <Button
                label={getLabel("10044", "No")}
                icon="pi pi-times"
                onClick={() => handleImpresionManual(false)}
                className="p-button-text"
                disabled={loadingConfirmImpDialog}
              />
            </div>
          }
          onHide={() => setConfirmImpDialog(false)}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="13275"
              alt="¿Desea procesar los cambios realizados?"
            />
          </p>
        </Dialog>
      </div>
    </div>
  );
}
const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
