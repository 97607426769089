import React, { useEffect, useState } from "react";

import { GenericAbmFilterProps } from "../generic-abm";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import isEqual from "lodash/isEqual";
import lodashGet from "lodash/get";

export interface GenericAbmFilterItemRendererProps {
  settings: GenericAbmFilterProps;
  onChange: (value: any) => void;
  prevFilterValues: any;
  currentFilterValues: any;
}

const GenericAbmFilterItemRenderer: React.FC<
  GenericAbmFilterItemRendererProps
> = ({ settings, onChange, prevFilterValues, currentFilterValues }) => {
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [firstRender, setFirstRender] = useState(true);

  async function handleValueChange(value: any) {
    try {
      setValue(value);
      onChange(value);
      if (settings.onChange) {
        settings.onChange(value);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function handleDataLoad() {
      if (settings.type === "dropdown" || settings.type === "multiselect") {
        setLoadingData(true);
        try {
          if (!settings.dependency) {
            if (
              !isEqual(prevFilterValues, currentFilterValues) ||
              firstRender
            ) {
              const options = await settings.dataSource(currentFilterValues);
              setOptions(options ?? []);
            }
          } else if (Array.isArray(settings.dependency)) {
            let shouldReload = false;
            for (const dep of settings.dependency) {
              const prevDepValue = lodashGet(prevFilterValues, dep);
              const currDepValue = lodashGet(currentFilterValues, dep);
              if (!isEqual(prevDepValue, currDepValue)) {
                shouldReload = true;
                break;
              }
            }
            if (shouldReload) {
              const options = await settings.dataSource?.(currentFilterValues);
              setOptions(options ?? []);
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingData(false);
          setFirstRender(false);
        }
      }
    }
    handleDataLoad();
  }, [currentFilterValues]);

  return (
    <div className="sipco-option">
      <label htmlFor={settings.filter}>
        {settings.label} {settings.required && "*"}
      </label>
      {settings.type === "dropdown" &&
        (settings.virtualizeDropdown ? (
          <SipcoDropdown
            id={settings.filter}
            value={value}
            options={options}
            onChange={(e) => handleValueChange(e.value)}
            itemTemplate={settings.dropDownTemplate}
            valueTemplate={settings.dropDownTemplate}
            optionLabel={
              settings.optionLabel ? settings.optionLabel : "descripcion"
            }
            placeholder={settings.placeholder}
            required={settings.required}
            showClear={!settings.required}
            loading={loadingData}
            virtualScrollerOptions={{
              itemSize: 20,
            }}
            filterBy={settings.filterBy ? settings.filterBy : null}
          />
        ) : (
          <SipcoDropdown
            id={settings.filter}
            value={value}
            options={options}
            onChange={(e) => handleValueChange(e.value)}
            itemTemplate={settings.dropDownTemplate}
            valueTemplate={settings.dropDownTemplate}
            optionLabel={
              settings.optionLabel ? settings.optionLabel : "descripcion"
            }
            placeholder={settings.placeholder}
            required={settings.required}
            showClear={!settings.required}
            loading={loadingData}
            filterBy={settings.filterBy ? settings.filterBy : null}
          />
        ))}
      {settings.type === "multiselect" &&
        (settings.virtualizeDropdown ? (
          <SipcoMultiSelect
            id={settings.filter}
            value={value}
            options={options}
            onChange={(e) => handleValueChange(e.value)}
            placeholder={settings.placeholder}
            required={settings.required}
            showClear={!settings.required}
            loading={loadingData}
            itemTemplate={settings.dropDownTemplate}
            optionLabel={
              settings.optionLabel ? settings.optionLabel : "descripcion"
            }
            virtualScrollerOptions={{
              itemSize: 20,
            }}
            filterBy={settings.filterBy ? settings.filterBy : null}
          />
        ) : (
          <SipcoMultiSelect
            id={settings.filter}
            value={value}
            options={options}
            onChange={(e) => handleValueChange(e.value)}
            placeholder={settings.placeholder}
            required={settings.required}
            showClear={!settings.required}
            loading={loadingData}
            itemTemplate={settings.dropDownTemplate}
            optionLabel={
              settings.optionLabel ? settings.optionLabel : "descripcion"
            }
            filterBy={settings.filterBy ? settings.filterBy : null}
          />
        ))}
      {settings.type === "inputText" && (
        <SipcoInputText
          id={settings.filter}
          value={value ?? ""}
          onChange={(e) => handleValueChange(e.target.value)}
          placeholder={settings.placeholder}
          required={settings.required}
          keyfilter={settings.keyfilter}
        />
      )}
    </div>
  );
};

export default GenericAbmFilterItemRenderer;
