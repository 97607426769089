import React from "react";

/**
 * useInactivoColor
 *
 * Este hook retorna uma função que aplica uma cor ao texto com base na propriedade "activo" do objeto.
 * Se a opção estiver ativa (`activo` for `true`), a cor do texto será a padrão (`inherit`).
 * Caso contrário, a cor será vermelha (`red`).
 *
 * @returns {Function} - Uma função que recebe um objeto `option` e retorna um `span` com a cor adequada.
 *
 * @example
 * const option = { activo: false, descripcionFull: "Inativo" };
 * const coloredText = useInactivoColor()(option);
 * console.log(coloredText); // O texto será colorido de vermelho devido ao valor de "activo" ser false.
 */
function useInactivoColor() {
  return (option) => (
    // Se "activo" for falso, a cor do texto será vermelha.
    <span style={{ color: option.activo ? "inherit" : "red" }}>
      {option.descripcionFull}
    </span>
  );
}

/**
 * inactivoColorT
 *
 * Função que aplica a cor ao texto com uma lógica adicional para quando o valor de "label" for "TODOS".
 * Se o valor de "label" for "TODOS" ou se a propriedade "activo" for verdadeira, o texto será exibido
 * com a cor padrão. Caso contrário, o texto será vermelho.
 *
 * @param {Object} option - O objeto contendo a propriedade "label" e "activo".
 *
 * @returns {JSX.Element} - Um elemento `span` com o texto colorido de acordo com as condições.
 *
 * @example
 * const option = { label: "TODOS", activo: true };
 * const coloredLabel = inactivoColorT(option);
 * console.log(coloredLabel); // O texto será na cor padrão devido ao valor de "label" ser "TODOS".
 */
function inactivoColorTLabel(option) {
  // Verifica se o valor de "label" é "TODOS"
  const isTodos = option.label === "TODOS";

  return (
    // Se "label" for "TODOS" ou "activo" for true, a cor do texto será a cor padrão.
    // Caso contrário, o texto ficará vermelho.
    <span style={{ color: isTodos || option.activo ? "inherit" : "red" }}>
      {option.label}
    </span>
  );
}

function inactivoColorTDesc(option) {
  // Verifica se o valor de "label" é "TODOS"
  const isTodos = option.descripcion === "TODOS";

  return (
    // Se "label" for "TODOS" ou "activo" for true, a cor do texto será a cor padrão.
    // Caso contrário, o texto ficará vermelho.
    <span style={{ color: isTodos || option.activo ? "inherit" : "red" }}>
      {option.descripcion}
    </span>
  );
}

//CAMINHO IMPORT
// import { inactivoColor } from "../../../hooks/useInactivoColor";
// import { inactivoColorT } from "../../../hooks/useInactivoColor";

export { useInactivoColor, inactivoColorTLabel, inactivoColorTDesc };
