import React, { useEffect, useRef, useState } from "react";

import GenericAbm, {
  GenericAbmRef,
} from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { ValidacionOperacion } from "src/models/ValidacionOperacion";
import { Card } from "primereact/card";
import { useGenericAbm } from "@shared/GenericAbmContext";
import PanelCustom from "../../../../src/components/panel-custom/PanelCustom";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import SipcoCalendar from "@shared/components/sipco-calendar";
import { Button } from "primereact/button";

const INITIAL_FORM = {
  id: null,
  activo: true,
  pais: null,
  cliente: null,
  cuenta: null,
  subcuenta: null,
  operacion: null,
  mercado: null,
  origen: null,
  destino: null,
  marca: null,
  color: null,
  version: null,
  modelo: null,
  familia: null,
  interior: false,
  capital: false,
  verificaRangoTiempo: false,
  despacho: false,
  remitoDespacho: false,
  remitoTraslado: false,
  asignado: false,
  sinAsignar: false,
  leyendaRemito: null,
  denominacionComercial: null,
  todosConcesionarios: false,
  hrDespacho: false,
  fechaFabricacionDesde: { date: null },
  fechaFabricacionHasta: { date: null },
  sinHrDespacho: false,
  verificaDanios: false,
  verificaConcesionario: false,
  tierraDelFuego: false,
  noTierraDelFuego: false,
  estadoVehiculo: null,
  tieneConcesionario: false,
  tieneDanios: false,
  paisOrigen: null,
  tiene1023: false,
  noTiene1023: false,
  codExterno: "",
  chkFechaFabricacion: false,
};

const TODOS_LABEL = {
  id: "TODOS",
  label: "TODOS",
  descripcion: "TODOS",
  search: "TODOS",
};

const MantenerParametrizacionOperaciones = () => {
  const SCREEN_CODE = "abm0188_mantener_parametrizacion_operaciones";
  const URL_BASE = "/mantener-parametrizacion-operaciones";
  const SCREEN_TITLE = (
    <LanguageProvider id="14318" alt="Mantener Parametrización Operación" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const abmRef = useRef<GenericAbmRef<ValidacionOperacion>>();
  const { genericAbmStatesContextData } = useGenericAbm<any>();
  const [formState, setFormState] = useState<ValidacionOperacion>(INITIAL_FORM);

  const empityFilterGrupo = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - 
    ${LanguageProvider({ id: "18309", alt: "x" })}`;
  const empityFilterOperacion = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - 
    ${LanguageProvider({ id: "1569", alt: "x" })}`;
  const empityFilterMarca = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - 
    ${LanguageProvider({ id: "396", alt: "x" })}`;
  const empityFilterModelo = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - 
    ${LanguageProvider({ id: "408", alt: "x" })}`;
  const empitySelectGrid = `${LanguageProvider({
    id: "753",
    alt: "Debe seleccionar al menos un registro de la Grilla.",
  })}`;
  const showToastAndReturnEmptyArray = (message: string) => {
    toast.current?.show({
      severity: "warn",
      detail: message,
      life: 3000,
    });
    return [];
  };

  async function handleSave(model: ValidacionOperacion, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues.securityValues.client &&
        filterValues.securityValues.account
      ) {
        const validacionOperacion = {
          ...formState,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          validacionOperacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ValidacionOperacion, filterValues: any) {
    try {
      const validacionOperacion = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        validacionOperacion,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: ValidacionOperacion, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/update", {
        validacionOperacion: { ...model, activo: false },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ValidacionOperacion[]> {
    if (!filter?.gridChkGrupoOperaciones) {
      return showToastAndReturnEmptyArray(empityFilterGrupo);
    }
    if (!filter?.gridChkOperaciones) {
      return showToastAndReturnEmptyArray(empityFilterOperacion);
    }
    if (!filter?.cmbMarca) {
      return showToastAndReturnEmptyArray(empityFilterMarca);
    }
    if (filter?.cmbMarca?.id !== "TODOS" && filter?.cmbMarca?.id !== null) {
      if (!filter?.gridChkModelos) {
        return showToastAndReturnEmptyArray(empityFilterModelo);
      }
    }

    if (filter?.securityValues?.country && filter.securityValues.client) {
      try {
        const payload = {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter.securityValues.account,
          grupos: filter.gridChkGrupoOperaciones,
          operaciones: filter.gridChkOperaciones,
          marca: filter?.cmbMarca?.id === "TODOS" ? null : filter?.cmbMarca,
          modelos:
            filter?.cmbMarca?.id === "TODOS" ? null : filter.gridChkModelos,
        };
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          payload
        );
        if (status === 200) return data;
        return [];
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<ValidacionOperacion> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          validacionOperacion: model,
        }
      );
      if (status === 200) {
        setFormState(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadGridChkGrupoOperacionesFilter(
    filter: any
  ): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/grupo-operacion",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.label}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadGridChkOperacionesFilter(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/operacion",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.label}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadCmbMarcaFilter(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/marca",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            updatedData.unshift(TODOS_LABEL);
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadGridChkModelosFilter(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter.cmbMarca
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/modelo",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            marca: filter.cmbMarca,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.label}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  const [operacionOptionsForm, setOperacionOptionsForm] = useState<any[]>([]);
  const [loadingOperacionForm, setLoadingOperacionForm] = useState(false);
  async function loadOperacionOptionsForm() {
    if (
      abmRef.current.filter?.securityValues?.country &&
      abmRef.current.filter?.securityValues?.client
    ) {
      try {
        setLoadingOperacionForm(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/operacion",
          {
            pais: abmRef.current.filter.securityValues.country,
            cliente: abmRef.current.filter.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            setOperacionOptionsForm(updatedData);
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingOperacionForm(false);
      }
    }
  }

  const [subcuentaOptionsForm, setSubcuentaOptionsForm] = useState([]);
  const [loadingSubcuentaForm, setLoadingSubcuentaForm] = useState(false);
  async function loadSubcuentaOptionsForm() {
    if (
      abmRef.current.filter?.securityValues?.country &&
      abmRef.current.filter?.securityValues?.client &&
      abmRef.current.filter.securityValues.account
    ) {
      try {
        setLoadingSubcuentaForm(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/subcuenta",
          {
            pais: abmRef.current.filter.securityValues.country,
            cliente: abmRef.current.filter.securityValues.client,
            cuenta: abmRef.current.filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            setSubcuentaOptionsForm(updatedData);
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingSubcuentaForm(false);
      }
    }
  }

  const [mercadoOptionsForm, setMercadoOptionsForm] = useState([]);
  const [loadingMercadoForm, setLoadingMercadoForm] = useState(false);
  async function loadMercadoOptionsForm() {
    if (
      abmRef.current.filter?.securityValues?.country &&
      abmRef.current.filter?.securityValues?.client
    ) {
      try {
        setLoadingMercadoForm(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/mercado",
          {
            pais: abmRef.current.filter.securityValues.country,
            cliente: abmRef.current.filter.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            setMercadoOptionsForm(updatedData);
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingMercadoForm(false);
      }
    }
  }

  const [origenOptionsForm, setOrigenOptionsForm] = useState([]);
  const [loadingOrigenForm, setLoadingOrigenForm] = useState(false);
  async function loadOrigenOptionsForm() {
    if (
      abmRef.current.filter?.securityValues?.country &&
      abmRef.current.filter?.securityValues?.client
    ) {
      try {
        setLoadingOrigenForm(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/origen",
          {
            pais: abmRef.current.filter.securityValues.country,
            cliente: abmRef.current.filter.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            setOrigenOptionsForm(updatedData);
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingOrigenForm(false);
      }
    }
  }

  const [destinoOptionsForm, setDestinoOptionsForm] = useState([]);
  const [loadingDestinoForm, setLoadingDestinoForm] = useState(false);
  async function loadDestinoOptionsForm() {
    if (
      abmRef.current.filter?.securityValues?.country &&
      abmRef.current.filter?.securityValues?.client
    ) {
      try {
        setLoadingDestinoForm(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/destino",
          {
            pais: abmRef.current.filter.securityValues.country,
            cliente: abmRef.current.filter.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            setDestinoOptionsForm(updatedData);
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingDestinoForm(false);
      }
    }
  }

  const [marcaOptionsForm, setMarcaOptionsForm] = useState([]);
  const [loadingMarcaForm, setLoadingMarcaForm] = useState(false);
  async function loadMarcaOptionsForm() {
    if (
      abmRef.current.filter?.securityValues?.country &&
      abmRef.current.filter?.securityValues?.client
    ) {
      try {
        setLoadingMarcaForm(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/marca",
          {
            pais: abmRef.current.filter.securityValues.country,
            cliente: abmRef.current.filter.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            setMarcaOptionsForm(updatedData);
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingMarcaForm(false);
      }
    }
  }

  const [familiaOptionsForm, setFamiliaOptionsForm] = useState([]);
  const [loadingFamiliaForm, setLoadingFamiliaForm] = useState(false);
  async function loadFamiliaOptionsForm() {
    if (
      abmRef.current.filter?.securityValues?.country &&
      abmRef.current.filter?.securityValues?.client
    ) {
      try {
        setLoadingFamiliaForm(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/familia",
          {
            pais: abmRef.current.filter.securityValues.country,
            cliente: abmRef.current.filter.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            setFamiliaOptionsForm(updatedData);
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingFamiliaForm(false);
      }
    }
  }

  const [estadoVehiculoOptionsForm, setEstadoVehiculoOptionsForm] = useState(
    []
  );
  const [loadingEstadoVehiculoForm, setLoadingEstadoVehiculoForm] =
    useState(false);
  async function loadEstadoVehiculoOptionsForm() {
    if (
      abmRef.current.filter?.securityValues?.country &&
      abmRef.current.filter?.securityValues?.client &&
      abmRef.current.filter.securityValues.account
    ) {
      try {
        setLoadingEstadoVehiculoForm(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/estado-vehiculo",
          {
            pais: abmRef.current.filter.securityValues.country,
            cliente: abmRef.current.filter.securityValues.client,
            cuenta: abmRef.current.filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            setEstadoVehiculoOptionsForm(updatedData);
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingEstadoVehiculoForm(false);
      }
    }
  }

  const [leyendaRemitoOptionsForm, setLeyendaRemitoOptionsForm] = useState([]);
  const [loadingLeyendaRemitoForm, setLoadingLeyendaRemitoForm] =
    useState(false);
  async function loadLeyendaRemitoOptionsForm() {
    if (
      abmRef.current.filter?.securityValues?.country &&
      abmRef.current.filter?.securityValues?.client
    ) {
      try {
        setLoadingLeyendaRemitoForm(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/leyenda-remito",
          {
            pais: abmRef.current.filter.securityValues.country,
            cliente: abmRef.current.filter.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            setLeyendaRemitoOptionsForm(updatedData);
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingLeyendaRemitoForm(false);
      }
    }
  }

  const [colorOptionsForm, setColorOptionsForm] = useState([]);
  const [loadingColorForm, setLoadingColorForm] = useState(false);
  async function loadColorOptionsForm() {
    if (
      abmRef.current.filter?.securityValues?.country &&
      abmRef.current.filter?.securityValues?.client &&
      formState?.marca
    ) {
      try {
        setLoadingColorForm(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/color",
          {
            pais: abmRef.current.filter.securityValues.country,
            cliente: abmRef.current.filter.securityValues.client,
            marca: formState?.marca,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            setColorOptionsForm(updatedData);
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingColorForm(false);
      }
    }
  }

  const [versionOptionsForm, setVersionOptionsForm] = useState([]);
  const [loadingVersionForm, setLoadingVersionForm] = useState(false);
  async function loadVersionOptionsForm() {
    if (
      abmRef.current.filter?.securityValues?.country &&
      abmRef.current.filter?.securityValues?.client &&
      formState?.marca
    ) {
      try {
        setLoadingVersionForm(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/version",
          {
            pais: abmRef.current.filter.securityValues.country,
            cliente: abmRef.current.filter.securityValues.client,
            marca: formState?.marca,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            setVersionOptionsForm(updatedData);
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingVersionForm(false);
      }
    }
  }

  const [paisOrigenOptionsForm, setPaisOrigenOptionsForm] = useState([]);
  const [loadingPaisOrigenForm, setLoadingPaisOrigenForm] = useState(false);
  async function loadPaisOrigenOptionsForm() {
    if (
      abmRef.current.filter?.securityValues?.country &&
      abmRef.current.filter?.securityValues?.client
    ) {
      try {
        setLoadingPaisOrigenForm(true);
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/pais-origen",
          {
            pais: abmRef.current.filter.securityValues.country,
            cliente: abmRef.current.filter.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            setPaisOrigenOptionsForm(updatedData);
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingPaisOrigenForm(false);
      }
    }
  }

  function chkFechaFabricacionChange() {
    if (!formState?.chkFechaFabricacion) {
      formState.fechaFabricacionDesde.date = new Date();
      formState.fechaFabricacionHasta.date = new Date();
    } else {
      formState.fechaFabricacionDesde.date = null;
      formState.fechaFabricacionHasta.date = null;
    }
  }

  useEffect(() => {
    if (genericAbmStatesContextData?.isDialogOpen) {
      const init = async () => {
        try {
          const [
            operacionOptions,
            subcuentaOptions,
            mercadoOptions,
            origenOptions,
            destinoOptions,
            marcaOptions,
            familiaOptions,
            estadoVehiculoOptions,
            leyendaRemitoOptions,
            paisOrigenOptions,
          ] = await Promise.all([
            loadOperacionOptionsForm(),
            loadSubcuentaOptionsForm(),
            loadMercadoOptionsForm(),
            loadOrigenOptionsForm(),
            loadDestinoOptionsForm(),
            loadMarcaOptionsForm(),
            loadFamiliaOptionsForm(),
            loadEstadoVehiculoOptionsForm(),
            loadLeyendaRemitoOptionsForm(),
            loadPaisOrigenOptionsForm(),
          ]);
          if (genericAbmStatesContextData?.editMode) {
            const optionsMap = {
              operacion: operacionOptions,
              subcuenta: subcuentaOptions,
              mercado: mercadoOptions,
              origen: origenOptions,
              destino: destinoOptions,
              marca: marcaOptions,
              familia: familiaOptions,
              paisOrigen: paisOrigenOptions,
              estadoVehiculo: estadoVehiculoOptions,
              leyendaRemito: leyendaRemitoOptions,
            };

            // Atualizar `formState` substituindo os IDs pelos objetos correspondentes
            const updatedFormState = {
              ...formState,
              ...Object.fromEntries(
                Object.entries(optionsMap).map(([key, options]) => {
                  if (
                    key in formState &&
                    Array.isArray(options) &&
                    options.length > 0
                  ) {
                    const foundOption = options.find(
                      (option) => option.id === formState[key]?.id
                    );
                    return [key, foundOption || formState[key]];
                  }
                  return [key, formState[key]];
                })
              ),
            };
            setFormState(updatedFormState);
          }
        } catch (error) {
          console.error(error);
        }
      };
      init();
    } else {
      setFormState(INITIAL_FORM);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericAbmStatesContextData?.isDialogOpen]);

  useEffect(() => {
    if (genericAbmStatesContextData?.isDialogOpen) {
      const init = async () => {
        try {
          await Promise.all([loadColorOptionsForm(), loadVersionOptionsForm()]);
        } catch (error) {
          console.error(error);
        }
      };
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState?.marca]);

  const openNewTabConcesionario = (url: string) => {
    if (
      genericAbmStatesContextData?.selectedEntries === null ||
      genericAbmStatesContextData?.selectedEntries?.length === 0
    ) {
      return showToastAndReturnEmptyArray(empitySelectGrid);
    }

    const newTab = window.open(url, "_blank");

    const { selectedEntries, filter } = genericAbmStatesContextData;
    const dto = {
      pais: filter?.securityValues?.country,
      cliente: filter?.securityValues?.client,
      cuenta: filter?.securityValues?.account,
      validacionOperacion: selectedEntries[0],
    };

    if (newTab) {
      setTimeout(() => {
        const dataToSend = {
          type: "from-mantener-parametrizacion-operaciones-"+ url, // Identificador da mensagem
          payload: dto, // Dados dinâmicos
        };

        newTab.postMessage(dataToSend, window.origin);
      }, 1000);
    }
  };

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ValidacionOperacion>
        ref={abmRef}
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
          },
        }}
        filterSettings={[
          {
            filter: "gridChkGrupoOperaciones",
            label: LanguageProvider({ id: "18309", alt: "x" }),
            placeholder: LanguageProvider({ id: "18309", alt: "x" }),
            type: "multiselect",
            optionLabel: "label",
            dataSource: loadGridChkGrupoOperacionesFilter,
            dropDownTemplate: DropdownOptionTemplateLabelId,
            dependency: ["securityValues.country", "securityValues.client"],
            filterBy: "search",
          },
          {
            filter: "gridChkOperaciones",
            label: LanguageProvider({ id: "1569", alt: "x" }),
            placeholder: LanguageProvider({ id: "1569", alt: "x" }),
            type: "multiselect",
            optionLabel: "label",
            dataSource: loadGridChkOperacionesFilter,
            dropDownTemplate: DropdownOptionTemplateLabelId,
            dependency: ["securityValues.country", "securityValues.client"],
            filterBy: "search",
            virtualizeDropdown: true,
          },
          {
            filter: "cmbMarca",
            label: LanguageProvider({ id: "396", alt: "x" }),
            placeholder: LanguageProvider({ id: "396", alt: "x" }),
            type: "dropdown",
            optionLabel: "label",
            dataSource: loadCmbMarcaFilter,
            dropDownTemplate: DropdownOptionTemplate,
            dependency: ["securityValues.country", "securityValues.client"],
            filterBy: "search",
          },
          {
            filter: "gridChkModelos",
            label: LanguageProvider({ id: "408", alt: "x" }),
            placeholder: LanguageProvider({ id: "408", alt: "x" }),
            type: "multiselect",
            optionLabel: "label",
            dataSource: loadGridChkModelosFilter,
            dropDownTemplate: DropdownOptionTemplateLabelId,
            dependency: [
              "securityValues.country",
              "securityValues.client",
              "cmbMarca",
            ],
            virtualizeDropdown: true,
            filterBy: "search",
          },
        ]}
        dataTableColumns={[
          {
            field: "operacion.descripcion",
            header: <LanguageProvider id="2785" alt="x" />,
          },
          {
            field: "subcuenta",
            header: <LanguageProvider id="4791" alt="x" />,
            body: (rowData: any) =>
              rowData.subcuenta === null
                ? "TODOS"
                : rowData.subcuenta.descripcion,
          },
          {
            field: "mercado",
            header: <LanguageProvider id="367" alt="x" />,
            body: (rowData: any) =>
              rowData.mercado === null ? "TODOS" : rowData.mercado.descripcion,
          },
          {
            field: "origen",
            header: <LanguageProvider id="2496" alt="x" />,
            body: (rowData: any) =>
              rowData.origen === null ? "TODOS" : rowData.origen.descripcion,
          },
          {
            field: "destino",
            header: <LanguageProvider id="4102" alt="x" />,
            body: (rowData: any) =>
              rowData.destino === null ? "TODOS" : rowData.destino.descripcion,
          },
          {
            field: "marca",
            header: <LanguageProvider id="396" alt="x" />,
            body: (rowData: any) =>
              rowData.marca === null ? "TODOS" : rowData.marca.descripcion,
          },
          {
            field: "modelo.id",
            header: <LanguageProvider id="13514" alt="x" />,
          },
          {
            field: "modelo",
            header: <LanguageProvider id="2139" alt="x" />,
            body: (rowData: any) =>
              rowData.modelo === null ? "TODOS" : rowData.modelo.descripcion,
          },
          {
            field: "color.id",
            header: <LanguageProvider id="18305" alt="x" />,
          },
          {
            field: "color",
            header: <LanguageProvider id="2140" alt="x" />,
            body: (rowData: any) =>
              rowData.color === null ? "TODOS" : rowData.color.descripcion,
          },
          {
            field: "familia.id",
            header: <LanguageProvider id="16902" alt="x" />,
          },
          {
            field: "familia",
            header: <LanguageProvider id="10034" alt="x" />,
            body: (rowData: any) =>
              rowData.familia === null ? "TODOS" : rowData.familia.descripcion,
          },
          {
            field: "version",
            header: <LanguageProvider id="4169" alt="x" />,
            body: (rowData: any) =>
              rowData.version === null ? "TODOS" : rowData.version.descripcion,
          },
          {
            field: "interior",
            header: <LanguageProvider id="13093" alt="x" />,
            body: (rowData: any) => (rowData.interior ? "SI" : "NO"),
          },
          {
            field: "capital",
            header: <LanguageProvider id="13094" alt="x" />,
            body: (rowData: any) => (rowData.capital ? "SI" : "NO"),
          },
          {
            field: "remitoDespacho",
            header: <LanguageProvider id="321" alt="x" />,
            body: (rowData: any) => (rowData.remitoDespacho ? "SI" : "NO"),
          },
          {
            field: "remitoTraslado",
            header: <LanguageProvider id="14282" alt="x" />,
            body: (rowData: any) => (rowData.remitoTraslado ? "SI" : "NO"),
          },
          {
            field: "leyendaRemito",
            header: <LanguageProvider id="14283" alt="x" />,
            body: (rowData: any) =>
              rowData.leyendaRemito === null ? "TODOS" : rowData.leyendaRemito,
          },
          {
            field: "fechaFabricacionDesde.date",
            header: <LanguageProvider id="467" alt="x" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaFabricacionDesde.timestamp
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "fechaFabricacionHasta.date",
            header: <LanguageProvider id="468" alt="x" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaFabricacionHasta.timestamp
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={INITIAL_FORM}
        // formSettings={[]}
        customForm={
          <>
            <Card>
              <div className="form">
                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"2785"} alt="x" />
                    </label>
                    <SipcoDropdown
                      value={formState?.operacion}
                      options={operacionOptionsForm}
                      onChange={(e) =>
                        setFormState({ ...formState, operacion: e.value })
                      }
                      optionLabel="descripcion"
                      loading={loadingOperacionForm}
                      itemTemplate={DropdownOptionTemplate}
                      valueTemplate={DropdownOptionTemplate}
                      placeholder={LanguageProvider({
                        id: "2785",
                        alt: "x",
                      })}
                      virtualScrollerOptions={{
                        itemSize: 20,
                      }}
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4791"} alt="x" />
                    </label>
                    <SipcoDropdown
                      value={formState?.subcuenta}
                      options={subcuentaOptionsForm}
                      onChange={(e) =>
                        setFormState({ ...formState, subcuenta: e.value })
                      }
                      optionLabel="descripcion"
                      loading={loadingSubcuentaForm}
                      itemTemplate={DropdownOptionTemplate}
                      valueTemplate={DropdownOptionTemplate}
                      placeholder={LanguageProvider({ id: "4791", alt: "x" })}
                      showClear
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"367"} alt="x" />
                    </label>
                    <SipcoDropdown
                      value={formState?.mercado}
                      options={mercadoOptionsForm}
                      onChange={(e) =>
                        setFormState({ ...formState, mercado: e.value })
                      }
                      optionLabel="descripcion"
                      loading={loadingMercadoForm}
                      itemTemplate={DropdownOptionTemplate}
                      valueTemplate={DropdownOptionTemplate}
                      placeholder={LanguageProvider({ id: "367", alt: "x" })}
                      showClear
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"2496"} alt="x" />
                    </label>
                    <SipcoDropdown
                      value={formState?.origen}
                      options={origenOptionsForm}
                      onChange={(e) =>
                        setFormState({ ...formState, origen: e.value })
                      }
                      optionLabel="descripcion"
                      loading={loadingOrigenForm}
                      itemTemplate={DropdownOptionTemplate}
                      valueTemplate={DropdownOptionTemplate}
                      placeholder={LanguageProvider({ id: "2496", alt: "x" })}
                      showClear
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4102"} alt="x" />
                    </label>
                    <SipcoDropdown
                      value={formState?.destino}
                      options={destinoOptionsForm}
                      onChange={(e) =>
                        setFormState({ ...formState, destino: e.value })
                      }
                      optionLabel="descripcion"
                      loading={loadingDestinoForm}
                      itemTemplate={DropdownOptionTemplate}
                      valueTemplate={DropdownOptionTemplate}
                      placeholder={LanguageProvider({ id: "4102", alt: "x" })}
                      showClear
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"396"} alt="x" />
                    </label>
                    <SipcoDropdown
                      value={formState?.marca}
                      options={marcaOptionsForm}
                      onChange={(e) => {
                        setFormState({ ...formState, marca: e.value });
                      }}
                      optionLabel="descripcion"
                      loading={loadingMarcaForm}
                      itemTemplate={DropdownOptionTemplate}
                      valueTemplate={DropdownOptionTemplate}
                      placeholder={LanguageProvider({ id: "396", alt: "x" })}
                      showClear
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"10034"} alt="x" />
                    </label>
                    <SipcoDropdown
                      value={formState?.familia}
                      options={familiaOptionsForm}
                      onChange={(e) =>
                        setFormState({ ...formState, familia: e.value })
                      }
                      optionLabel="descripcion"
                      loading={loadingFamiliaForm}
                      itemTemplate={DropdownOptionTemplate}
                      valueTemplate={DropdownOptionTemplate}
                      placeholder={LanguageProvider({ id: "10034", alt: "x" })}
                      showClear
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"11443"} alt="x" />
                    </label>
                    <SipcoDropdown
                      value={formState?.estadoVehiculo}
                      options={estadoVehiculoOptionsForm}
                      onChange={(e) =>
                        setFormState({ ...formState, estadoVehiculo: e.value })
                      }
                      optionLabel="descripcion"
                      loading={loadingEstadoVehiculoForm}
                      itemTemplate={DropdownOptionTemplate}
                      valueTemplate={DropdownOptionTemplate}
                      placeholder={LanguageProvider({ id: "11443", alt: "x" })}
                      showClear
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"14283"} alt="x" />
                    </label>
                    <SipcoDropdown
                      value={formState?.leyendaRemito}
                      options={leyendaRemitoOptionsForm}
                      onChange={(e) =>
                        setFormState({ ...formState, leyendaRemito: e.value })
                      }
                      optionLabel="descripcion"
                      loading={loadingLeyendaRemitoForm}
                      itemTemplate={DropdownOptionTemplate}
                      valueTemplate={DropdownOptionTemplate}
                      placeholder={LanguageProvider({ id: "14283", alt: "x" })}
                      showClear
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"2140"} alt="x" />
                    </label>
                    <SipcoDropdown
                      value={formState?.color}
                      options={colorOptionsForm}
                      onChange={(e) =>
                        setFormState({ ...formState, color: e.value })
                      }
                      optionLabel="descripcion"
                      loading={loadingColorForm}
                      itemTemplate={DropdownOptionTemplate}
                      valueTemplate={DropdownOptionTemplate}
                      placeholder={LanguageProvider({ id: "2140", alt: "x" })}
                      showClear
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4169"} alt="x" />
                    </label>
                    <SipcoDropdown
                      value={formState?.version}
                      options={versionOptionsForm}
                      onChange={(e) =>
                        setFormState({ ...formState, version: e.value })
                      }
                      optionLabel="descripcion"
                      loading={loadingVersionForm}
                      itemTemplate={DropdownOptionTemplate}
                      valueTemplate={DropdownOptionTemplate}
                      placeholder={LanguageProvider({ id: "4169", alt: "x" })}
                      showClear
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"19030"} alt="x" />
                    </label>
                    <SipcoDropdown
                      value={formState?.paisOrigen}
                      options={paisOrigenOptionsForm}
                      onChange={(e) =>
                        setFormState({ ...formState, paisOrigen: e.value })
                      }
                      optionLabel="descripcion"
                      loading={loadingPaisOrigenForm}
                      itemTemplate={DropdownOptionTemplate}
                      valueTemplate={DropdownOptionTemplate}
                      placeholder={LanguageProvider({ id: "19030", alt: "x" })}
                      showClear
                    />
                  </div>
                </div>
              </div>
            </Card>
            <PanelCustom
              header={LanguageProvider({
                id: "275",
                alt: "x",
              })}
            >
              <PanelCustom
                header={LanguageProvider({
                  id: "13097",
                  alt: "Lugar de entrega",
                })}
              >
                <div className="form">
                  <div className="form-row">
                    <div className={"RadioButtonFilter"}>
                      <label>
                        <LanguageProvider id={"8588"} alt="x" />
                      </label>
                      <RadioButton
                        value="capital"
                        onChange={() =>
                          setFormState({
                            ...formState,
                            capital: true,
                            interior: false,
                          })
                        }
                        checked={formState?.capital}
                        disabled
                      />
                    </div>
                    <div className={"RadioButtonFilter"}>
                      <label>
                        <LanguageProvider id={"13093"} alt="x" />
                      </label>
                      <RadioButton
                        value="interior"
                        onChange={() =>
                          setFormState({
                            ...formState,
                            capital: false,
                            interior: true,
                          })
                        }
                        checked={formState?.interior}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </PanelCustom>
              <PanelCustom
                header={LanguageProvider({
                  id: "1617",
                  alt: "Tipo remito",
                })}
              >
                <div className="form">
                  <div className="form-row">
                    <div className={"RadioButtonFilter"}>
                      <label>
                        <LanguageProvider id={"14282"} alt="x" />
                      </label>
                      <RadioButton
                        value="remitoTraslado"
                        onChange={() =>
                          setFormState({
                            ...formState,
                            remitoTraslado: true,
                            remitoDespacho: false,
                          })
                        }
                        checked={formState?.remitoTraslado}
                      />
                    </div>
                    <div className={"RadioButtonFilter"}>
                      <label>
                        <LanguageProvider id={"321"} alt="x" />
                      </label>
                      <RadioButton
                        value="remitoDespacho"
                        onChange={() =>
                          setFormState({
                            ...formState,
                            remitoDespacho: true,
                            remitoTraslado: false,
                          })
                        }
                        checked={formState?.remitoDespacho}
                      />
                    </div>
                  </div>
                </div>
              </PanelCustom>
            </PanelCustom>
            <PanelCustom
              header={LanguageProvider({
                id: "14284",
                alt: "Asignación",
              })}
            >
              <div className="form">
                <div className="form-row">
                  <div className={"RadioButtonFilter"}>
                    <label>
                      <LanguageProvider id={"14285"} alt="x" />
                    </label>
                    <RadioButton
                      value="asignado"
                      onChange={() =>
                        setFormState({
                          ...formState,
                          asignado: true,
                          sinAsignar: false,
                        })
                      }
                      checked={formState?.asignado}
                    />
                  </div>
                  <div className={"RadioButtonFilter"}>
                    <label>
                      <LanguageProvider id={"14286"} alt="x" />
                    </label>
                    <RadioButton
                      value="sinAsignar"
                      onChange={() =>
                        setFormState({
                          ...formState,
                          sinAsignar: true,
                          asignado: false,
                        })
                      }
                      checked={formState?.sinAsignar}
                    />
                  </div>
                </div>
              </div>
            </PanelCustom>
            <PanelCustom
              header={LanguageProvider({
                id: "22619",
                alt: "Título Alternativo",
              })}
            >
              <div className="form">
                <div className="form-row">
                  <div className={"RadioButtonFilter"}>
                    <label>
                      <LanguageProvider id={"22620"} alt="x" />
                    </label>
                    <RadioButton
                      value="tiene1023"
                      onChange={() =>
                        setFormState({
                          ...formState,
                          tiene1023: true,
                          noTiene1023: false,
                        })
                      }
                      checked={formState?.tiene1023}
                    />
                  </div>
                  <div className={"RadioButtonFilter"}>
                    <label>
                      <LanguageProvider id={"22621"} alt="x" />
                    </label>
                    <RadioButton
                      value="noTiene1023"
                      onChange={() =>
                        setFormState({
                          ...formState,
                          noTiene1023: true,
                          tiene1023: false,
                        })
                      }
                      checked={formState?.noTiene1023}
                    />
                  </div>
                </div>
              </div>
            </PanelCustom>
            <PanelCustom
              header={LanguageProvider({
                id: "6348",
                alt: "Título Alternativo",
              })}
            >
              <div className="form">
                <div className="form-row">
                  <div className="RadioButtonFilter">
                    <label>
                      <LanguageProvider id={"13098"} alt="x" />
                    </label>
                    <Checkbox
                      checked={formState?.verificaRangoTiempo}
                      onChange={() =>
                        setFormState({
                          ...formState,
                          verificaRangoTiempo: !formState?.verificaRangoTiempo,
                        })
                      }
                    />
                  </div>

                  <div className="RadioButtonFilter">
                    <label>
                      <LanguageProvider id={"18251"} alt="x" />
                    </label>
                    <RadioButton
                      value="RadioButtonFilter"
                      checked={formState?.hrDespacho}
                      onChange={() =>
                        setFormState({
                          ...formState,
                          hrDespacho: true,
                          sinHrDespacho: false,
                        })
                      }
                    />
                  </div>

                  <div className="RadioButtonFilter">
                    <label>
                      <LanguageProvider id={"18282"} alt="x" />
                    </label>
                    <RadioButton
                      value="sinHrDespacho"
                      checked={formState?.sinHrDespacho}
                      onChange={() =>
                        setFormState({
                          ...formState,
                          sinHrDespacho: true,
                          hrDespacho: false,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="RadioButtonFilter">
                    <label>
                      <LanguageProvider id={"18283"} alt="x" />
                    </label>
                    <Checkbox
                      checked={formState?.verificaDanios}
                      onChange={() =>
                        setFormState({
                          ...formState,
                          verificaDanios: !formState?.verificaDanios,
                        })
                      }
                    />
                  </div>

                  <div className="RadioButtonFilter">
                    <label>
                      <LanguageProvider id={"72"} alt="x" />
                    </label>
                    <Checkbox
                      checked={formState?.activo}
                      onChange={() =>
                        setFormState({
                          ...formState,
                          activo: !formState?.activo,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </PanelCustom>
            <PanelCustom
              header={LanguageProvider({
                id: "266",
                alt: "Concesionarios",
              })}
            >
              <div className="form">
                <div className="form-row">
                  <div className="RadioButtonFilter">
                    <label>
                      <LanguageProvider id={"18284"} alt="x" />
                    </label>
                    <Checkbox
                      checked={formState?.verificaConcesionario}
                      onChange={() =>
                        setFormState({
                          ...formState,
                          verificaConcesionario:
                            !formState?.verificaConcesionario,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="RadioButtonFilter">
                    <label>
                      <LanguageProvider id={"5317"} alt="x" />
                    </label>
                    <Checkbox
                      checked={formState?.todosConcesionarios}
                      onChange={() =>
                        setFormState({
                          ...formState,
                          todosConcesionarios: !formState?.todosConcesionarios,
                        })
                      }
                      disabled={!formState?.verificaConcesionario}
                    />
                  </div>
                  <div className="RadioButtonFilter">
                    <label>
                      <LanguageProvider id={"18285"} alt="x" />
                    </label>
                    <Checkbox
                      checked={formState?.noTierraDelFuego}
                      onChange={() =>
                        setFormState({
                          ...formState,
                          noTierraDelFuego: !formState?.noTierraDelFuego,
                        })
                      }
                      disabled
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="RadioButtonFilter">
                    <label>
                      <LanguageProvider id={"9555"} alt="x" />
                    </label>
                    <Checkbox
                      checked={formState?.tierraDelFuego}
                      onChange={() =>
                        setFormState({
                          ...formState,
                          tierraDelFuego: !formState?.tierraDelFuego,
                        })
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
            </PanelCustom>
            <PanelCustom
              header={LanguageProvider({
                id: "12103",
                alt: "Fecha Fabricación",
              })}
            >
              <div className="form">
                <div className="form-row">
                  <div className="RadioButtonFilter">
                    <label>
                      <LanguageProvider id={"12103"} alt="Fecha Fabricación" />
                    </label>
                    <Checkbox
                      checked={formState?.chkFechaFabricacion}
                      onChange={() => {
                        setFormState({
                          ...formState,
                          chkFechaFabricacion: !formState?.chkFechaFabricacion,
                        });
                        chkFechaFabricacionChange();
                      }}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"467"} alt="x" />
                    </label>
                    <SipcoCalendar
                      value={formState?.fechaFabricacionDesde?.date}
                      onChange={(e) => {
                        setFormState({
                          ...formState,
                          fechaFabricacionDesde: { date: e.value },
                        });
                      }}
                      dateFormat="dd/mm/yy"
                      showButtonBar
                      showIcon
                      disabled={!formState?.chkFechaFabricacion}
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"468"} alt="x" />
                    </label>
                    <SipcoCalendar
                      value={formState?.fechaFabricacionHasta?.date}
                      onChange={(e) => {
                        setFormState({
                          ...formState,
                          fechaFabricacionHasta: { date: e.value },
                        });
                      }}
                      dateFormat="dd/mm/yy"
                      showButtonBar
                      showIcon
                      disabled={!formState?.chkFechaFabricacion}
                    />
                  </div>
                </div>
              </div>
            </PanelCustom>
          </>
        }
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        customActions={
          <>
            <Button
              label={LanguageProvider({ id: "266", alt: "Concesionarios" })}
              onClick={() => {
                openNewTabConcesionario(
                  "/mantener-parametros-operacion-concesionario"
                );
              }}
              disabled={
                genericAbmStatesContextData?.selectedEntries === null ||
                genericAbmStatesContextData?.selectedEntries?.length === 0
              }
            />
            <Button
              label={LanguageProvider({ id: "6160", alt: "Daños" })}
              onClick={() => {
                openNewTabConcesionario("/mantener-parametros-operacion-danio");
              }}
              disabled={
                genericAbmStatesContextData?.selectedEntries === null ||
                genericAbmStatesContextData?.selectedEntries?.length === 0
              }
            />
            <Button
              label={LanguageProvider({ id: "5595", alt: "Modelos" })}
              onClick={() => {
                openNewTabConcesionario("/mantener-parametros-operacion-modelo");
              }}
              disabled={
                genericAbmStatesContextData?.selectedEntries === null ||
                genericAbmStatesContextData?.selectedEntries?.length === 0
              }
            />
            <Button
              label={LanguageProvider({ id: "18979", alt: "Denom. Comercial" })}
              onClick={() => {
                openNewTabConcesionario("/mantener-prarmetros-operacion-denominacion-comercial");
              }}
              disabled={
                genericAbmStatesContextData?.selectedEntries === null ||
                genericAbmStatesContextData?.selectedEntries?.length === 0
              }
            />
          </>
        }
      />
    </>
  );
};

export default MantenerParametrizacionOperaciones;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionTemplateLabelId = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
