import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { HttpStatusCode } from "axios";

import "./../DespachoMercadoNacional.scss";
import { Divider } from "primereact/divider";
import { Calendar } from "primereact/calendar";

export function DespachoMercadoNacionalForm(props) {
  const { SCREEN_CODE } = props;
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState([]);
  const [oferta, setLisOferta] = useState(null);
  const toast = useRef<Toast>(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ingreseElVin, setIngreseElVin] = useState([]);
  const [hojaDeRuta, setHojaDeRuta] = useState([]);
  const [modal, setModal] = useState(false);
  const [mensaje, setMensajes] = useState("");

  const [filter, setFilter] = useState({
    ingreseElVin: null,
    hojaDeRuta: null,
    transportista: null,
    tractor: null,
    bateaTransportin: null,
    chofer: null,
    cantidadUnidades: null,
    ventaFlete: false,
    aforoVenta: null,
    aforoCompra: null,
    compraFlete: false,
    fechaDespacho: new Date(),
  });
  

  

  const columnsNames = [
    { field: "remito.cliente.descripcion", header: "Cliente" },
    { field: "remito.letra", header: "Letra" },
    { field: "remito.puntoDeVenta", header: "Punto Venta" },
    { field: "remito.id", header: "Código Remito" },
    { field: "remito.estadoRemito.descripcion", header: "Estado" },
    { field: "remito.fechaLiberacion.date", header: "Liberación" },
    { field: "remito.vin.id", header: "VIN" },
    { field: "remito.vin.marca.descripcion", header: "Marca" },
    { field: "remito.vin.modelo.descripcion", header: "Modelo" },
    { field: "remito.vin.color.descripcion", header: "Color" },
    { field: "remito.vin.origen.descripcion", header: "Origen" },
    { field: "remito.vin.motorizacion.id", header: "Motor" },
    { field: "remito.idPedidoCliente", header: "Cód." },
    { field: "remito.concesionario.id", header: "Concesionario" },
    { field: "remito.direccionEntrega.id", header: "Boca de Entrega" },
    { field: "remito.razonSocial", header: "Razón Social" },
    { field: "remito.domicilioImpresion", header: "Domicilio" },
    { field: "remito.provinciaImpresion", header: "Provincia" },
    { field: "remito.localidadImpresion", header: "Localidad" },
    { field: "remito.fechaSalidaReal.date", header: "Fecha Salida" },
    { field: "remito.observaciones", header: "Observaciones" },
  ];


  async function searchIngreseElVin(event: any) {
    await loadIngreseElVin(event.query);
  };

  async function loadIngreseElVin(query = "") {
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.country
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/despacho-mercado-nacional/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: filter.ingreseElVin,
          }
        );
        if (status === HttpStatusCode.Ok) {
          if (query) {
            setIngreseElVin(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setIngreseElVin(data);
          }
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "País, Clientes e Cuentas Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };


  const [loadingHojaDeRuta, setLoadingHojaDeRuta] = useState(false);
  async function loadHojaDeRuta() {
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.client?.id &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.account.id &&
        securityFilters.securityValues.subaccount
      ) {
        setLoadingHojaDeRuta(true);
        const params = {
          codPais: securityFilters.securityValues.country.id,
          codCliente: securityFilters.securityValues.client.id,
          descripcionCliente:
            securityFilters.securityValues.client.descripcion || "TODOS",
          codCuenta: securityFilters.securityValues.account.id,
          codSubcuenta: securityFilters.securityValues.subaccount?.id,
          vin: filter.ingreseElVin?.id || "",
        };
        const { status, data } = await sipcoAxiosService.get(
          "/despacho-mercado-nacional/hoja-ruta",
          {
            params,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setHojaDeRuta(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHojaDeRuta(false);
    }
  };

  const [findHojaRuta, setFindHojaRuta] = useState([]);
  const [loadingFindHojaRuta, setLoadingFindHojaRuta] = useState(false);
  async function loadFindByHojaDeRuta(hojaRuta: any) {
    try {
      if (hojaRuta) {
        setLoadingFindHojaRuta(true);
        const { status, data } = await sipcoAxiosService.post(
          "/despacho-mercado-nacional/hoja-ruta/findFullBy",
          {
            hojaRuta: hojaRuta
          }
        );
        if (status === HttpStatusCode.Ok) {
          setFindHojaRuta(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFindHojaRuta(false);
    }
  };

  async function loadReport() {
    setLoading(true);
    try {
      if (securityFilters.securityValues.client &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.subaccount &&
        filter.hojaDeRuta) {
        const { status, data } = await sipcoAxiosService.post(
          "/despacho-mercado-nacional/reporte",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            hojaRuta: filter.hojaDeRuta ?? null,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setReporte(data);
          console.log("Reporte ", reporte);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Handle Mathods 
  async function handleHojaRuta(hojaRuta: any) {
    try {
      const findHojaRuta : any = await loadFindByHojaDeRuta(hojaRuta);
      
      setFilter({
        ...filter, 
        hojaDeRuta: hojaRuta, 
        tractor: findHojaRuta?.tractor?.descripcion, 
        chofer: findHojaRuta?.chofer?.nombre + " " + findHojaRuta?.chofer?.apellido,
        bateaTransportin: findHojaRuta?.batea?.descripcion,
        aforoVenta: findHojaRuta?.aforoVenta,
        aforoCompra: findHojaRuta?.aforoCompra,
        cantidadUnidades: findHojaRuta?.batea?.tipoVehiculo?.cantidadDeVehiculos,
        ventaFlete: findHojaRuta?.venta,
        compraFlete: findHojaRuta?.compra,
        transportista: findHojaRuta?.batea?.proveedor?.descripcion
      });

    } catch (error) {
      console.error(error);
    }
  }

  function procesar() {
    let mensaje: number = 0;
    let mensajeAlert: string = "";
  
    if (selectedRows.length === 0) {
      mensaje = 1022;
    } else {
      mensaje = 4110;
    }
  
  
    if (
      (filter.hojaDeRuta?.limiteInferior !== null && filter.hojaDeRuta?.limiteInferior > 0) ||
      (filter.hojaDeRuta?.limiteSuperior !== null && filter.hojaDeRuta?.limiteSuperior > 0)
    ) {
      if (filter.hojaDeRuta?.limiteInferior > filter.hojaDeRuta?.coeficiente) {
        mensajeAlert = "coeficiente_saturacion"; // Substituir pela variável correta se existir
      }
    }
  
    toast.current?.show({
      severity: "info",
      summary: "Información",
      detail: "Iniciando proceso",
      life: 5000,
    });
  };  

  useEffect(() => {
    const init = async () => {
      try {
        const hojaDeRuta = await loadHojaDeRuta();

        setFilter({
          ...filter, hojaDeRuta
        });
      } catch (error) {
        console.error(error);
      }
    }

    init();
  }, [securityFilters.securityValues]);




  return (
    <div className="despacho-mercado-nacional">
      <Toast ref={toast} position="top-center" />
      <div className="card flex justify-content-center">
        <Dialog
          visible={modal}
          modal
          header={
            <span className="font-bold white-space-nowrap">Mensajes</span>
          }
          footer={
            <Button
              label="Ok"
              icon="pi pi-check"
              onClick={() => setModal(false)}
              autoFocus
            />
          }
          style={{ width: "50rem" }}
          onHide={() => {
            if (!modal) return;
            setModal(false);
          }}
        >
          <p className="m-0" style={{ padding: "1rem" }}>
            {mensaje}
          </p>
        </Dialog>
      </div>
      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter.ingreseElVin}
                  suggestions={ingreseElVin}
                  completeMethod={searchIngreseElVin}
                  onChange={(e) =>
                    setFilter({ ...filter, ingreseElVin: e.value })
                  }
                  field="id"
                  dropdown
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"924"} alt="Hoja de Ruta" />
                </label>
                <SipcoDropdown
                  value={filter.hojaDeRuta}
                  options={hojaDeRuta}
                  onChange={(e) => handleHojaRuta(e.value)}
                  optionLabel="id"
                  loading={loadingHojaDeRuta}
                />
              </div>
            </div>
          </div>
        </AccordionTab>


      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id={"861"} alt="Administración de Remitos" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              <Divider />
              <div className="form">
                <Accordion multiple activeIndex={[0]}>
                  <AccordionTab
                    header={
                      <LanguageProvider
                        id={"16641"}
                        alt="Oferta de Servicios"
                      />
                    }
                  >
                    <SipcoDataTable
                      size={"small"}
                      showGridlines
                      stripedRows
                      ref={dt}
                      value={oferta}
                      paginator
                      scrollHeight="flex"
                      filterDisplay="menu"
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"
                      style={{ maxWidth: "100%" }}
                      loading={loading}
                    >
                      {columnsNames.map((colum, index) => (
                        <SipcoColumn
                          key={index}
                          field={colum.field}
                          header={colum.header}
                          sortable
                          filter
                        />
                      ))}
                    </SipcoDataTable>
                    <div className="form" style={{ marginTop: "1rem" }}>
                      <div className="form-row">
                        <div className="sipco-option">
                          <label>
                            <LanguageProvider id={"1342"} alt="Fecha Despacho" />
                          </label>
                          <Calendar
                            value={filter.fechaDespacho}
                            onChange={(e) =>
                              setFilter({ ...filter, fechaDespacho: e.value })
                            }
                            showIcon
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionTab>
                  <AccordionTab
                    header={<LanguageProvider id={"17355"} alt="Hoja Ruta" />}
                  >
                    {/* Label´s Pendentes, ainda não estão captando os valores */}
                    <div className="form">
                      <div className="form-row">
                        <div className={"sipco-option"}>
                          <label>
                            <LanguageProvider id={"3127"} alt="Transportista :" />
                          </label>
                          <SipcoInputText
                            value={filter.transportista}
                            onChange={(e) =>
                              setFilter({ ...filter, transportista: e.target.value })
                            }
                            disabled
                          />
                          <label>
                            <LanguageProvider id={"4655"} alt="Chofer" />
                          </label>
                          <SipcoInputText
                            value={filter.chofer}
                            onChange={(e) =>
                              setFilter({ ...filter, chofer: e.target.value })}
                              disabled
                          />
                          <label>
                            <LanguageProvider id={"16455"} alt="Aforo Venta" />
                          </label>
                          <SipcoInputText
                            value={filter.aforoVenta}
                            onChange={(e) =>
                              setFilter({ ...filter, aforoVenta: e.target.value })
                            }
                            disabled
                          />
                        </div>
                        <div className={"sipco-option"}>
                          <label>
                            <LanguageProvider id={"4285"} alt="Tractor" />
                          </label>
                          <SipcoInputText
                            value={filter.tractor}
                            onChange={(e) =>
                              setFilter({ ...filter, tractor: e.target.value })
                            }
                            disabled
                          />
                          <label>
                            <LanguageProvider
                              id={"8811"}
                              alt="Cantidad de Unidades"
                            />
                          </label>
                          <SipcoInputText
                            value={filter.cantidadUnidades}
                            onChange={(e) => setFilter({ ...filter, cantidadUnidades: e.target.value })}
                            disabled
                          />
                          <label>
                            <LanguageProvider id={"16773"} alt="Aforo compra" />
                          </label>
                          <SipcoInputText
                            value={filter.aforoCompra}
                            onChange={(e) => setFilter({ ...filter, aforoCompra: e.target.value })}
                            disabled
                          />
                        </div>
                        <div className={"sipco-option"}>
                          <label>
                            <LanguageProvider
                              id={"4284"}
                              alt="Batea / Transportín"
                            />
                          </label>
                          <SipcoInputText
                            value={filter.bateaTransportin}
                            onChange={(e) => setFilter({ ...filter, bateaTransportin: e.target.value })}
                            disabled
                          />
                          <label>
                            <LanguageProvider id={"16876"} alt="Venta flete" />
                          </label>
                          <Checkbox
                            onChange={(e) => setFilter({ ...filter, ventaFlete: e.checked })}
                            checked={filter.ventaFlete}
                            disabled
                          />
                          <label>
                            <LanguageProvider id={"16875"} alt="Compra flete" />
                          </label>
                          <Checkbox
                            onChange={(e) => setFilter({ ...filter, compraFlete: e.checked })}
                            checked={filter.compraFlete}
                            disabled
                          />

                        </div>
                      </div>
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>
              {/* buttons no function */}
              <div>
                <Button
                  label={LanguageProvider({
                    id: "16283",
                    alt: "Procesar",
                  })}
                  text
                  icon="pi pi-check"
                  onClick={procesar}
                />
              </div>
            </div>
          );
        }}
      >

        <div className="tags">
          <Tag
            value="Vehículos ya Despachados en Puerta de Salida"
            style={{ background: "#00aa00" }}
          />
        </div>

        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e: any) => setSelectedRows(e.value)}
          metaKeySelection={true}
          dragSelection
        >

          {columnsNames.map((colum, index) => {
            return <SipcoColumn
              key={index}
              field={colum.field}
              header={colum.header}
              sortable
              filter
            // body={(x) =>  
            //   { console.log("ol ", x); return "oi" }}
            />

          }
          )}

        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default DespachoMercadoNacionalForm;
