import "./ModalVerOrdenReparacionState.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoInputTextArea from "@shared/components/sipco-input-text-area";
import SipcoCalendar from "@shared/components/sipco-calendar";

interface ModalVerOrdenReparacionStateProps {
  visible: boolean;
  onClose: () => void;
  model?: any;
  onConfirm?: (updatedList: any[]) => void;
  values?: any;
}


const ModalVerOrdenReparacionState: React.FC<ModalVerOrdenReparacionStateProps> = ({
  visible,
  onClose,
  model,
  onConfirm,
  values,
}) => {
  const { values: securityFilters } = useFilterContext();

  const sipcoAxiosService = useSipcoAxiosService();
  usePageViews();
  const dt = useRef(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }


const initialFormState = {
    nroOrden: null,
    estado: null,
    nroIngresoFistLabel: null,
    nroIngresoSecondLabel: null,
    inspector: null,
    imputado: null,
    ubicacionActual: null,
    incidentes: null,
    fechaAlta: new Date(),
    fechaCierreReal: null,
    fechaFacturacion: null,
    fechaAnulacion: null,
    fechaCierreEstimada: new Date(),
    danos: null,
    clasificacion: null,
    tiempoEstimado: null,
    costoTotalEstimado: null,
    subcuenta: null,
    historicoTaller: null,
    taller: null,
    vin: null,
  };

  const [formModel, setFormModel] = useState(initialFormState);


  useEffect(() => {
    const init = () => {
      try {
        if(values?.ordeReparacion !== null && visible){
          const body = values?.ordeReparacion;
          
          setFormModel({
            ...formModel,
            nroOrden: body?.id,
            estado: body?.estado?.descripcion,
            vin: body?.vin?.id,
            nroIngresoFistLabel: body?.numeroIngreso,
            inspector: body?.inspector?.descripcion,
            subcuenta: body?.subcuenta?.descripcion,
            taller: body?.taller?.descripcion,
            imputado: body?.cliente.descripcion,
            incidentes: body?.observaciones,
            fechaAlta: new Date(body?.fechaOrden.date) || null,
            fechaAnulacion: new Date(body?.fechaAnulacion.date) || null,
            fechaCierreEstimada: new Date(body?.fechaCierreEstimada?.date) || null,
            fechaCierreReal: new Date(body?.fechaCierreReal?.date) || null,
            fechaFacturacion: new Date(body?.fechaFacturacion?.date) || null,
            tiempoEstimado: body?.tiempoAprox || 0,
            costoTotalEstimado: body?.costoAprox || 0,
            clasificacion: body?.clasificacion || null
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    init();
  }, [visible])


  return (
    <div>
      <Dialog
        className="modalVerOrdenReparacionState"
        header="Orden de Reparación - Ver"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={LanguageProvider({ id: "15447", alt: "Confirmar" })}
                icon="pi pi-check"
                autoFocus
              />
              <Button
                label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                icon="pi pi-times"
                onClick={onClose}
                className="p-button-text"
              />
            </div>
          );
        }}
      >
         <div className="column-form">
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"5701"} alt="Nro. Orden" />
                  </label>
                  <SipcoInputText
                    value={formModel.nroOrden}
                    className="input-modal"
                    disabled
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"20804"} alt="Estado" />
                  </label>
                  <SipcoInputText
                    value={formModel.estado}
                    className="input-modal"
                    disabled
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"1491"} alt="VIN" />
                  </label>
                  <SipcoAutocomplete
                    value={formModel.vin}
                    disabled
                    className="input-modal"
                    dropdown
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"17014"} alt="Nro Ingreso" />
                  </label>
                  <SipcoInputText
                    value={formModel.nroIngresoFistLabel}
                    className="input-modal"
                    disabled
                  />
                  <SipcoInputText
                    value={formModel.nroIngresoSecondLabel}
                    className="input-modal"
                    disabled
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"17015"} alt="Inspector" />
                  </label>
                  <SipcoInputText
                    value={formModel.inspector}
                    disabled
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"4791"} alt="Subcuenta" />
                  </label>
                  <SipcoInputText
                    value={formModel.subcuenta}
                    disabled
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"18487"} alt="Taller" />
                  </label>
                  <SipcoInputText
                    value={formModel.taller}
                    disabled
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"3568"} alt="Imputado a" />
                  </label>
                  <SipcoInputText
                    value={formModel.imputado}
                    disabled
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"1165"} alt="Ubicación Actual" />
                  </label>
                  <SipcoInputText
                    value={formModel.ubicacionActual}
                    disabled
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"17012"} alt="Incidentes" />
                  </label>
                  <SipcoInputTextArea
                    value={formModel.incidentes}
                    disabled
                    className="input-modal"
                  />
                </div>

              </div>
            </div>
          </div>

          <div className="form">
            <div className="form-row">
              <div className="sipco-option">

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"15739"} alt="Fecha Alta" />
                  </label>
                  <SipcoCalendar
                    className="input-modal"
                    value={formModel.fechaAlta}
                    disabled
                    showIcon
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"16493"} alt="Fecha de cierre real" />
                  </label>
                  <SipcoCalendar
                    className="input-modal"
                    value={formModel.fechaCierreReal}
                    disabled
                    showIcon
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"3231"} alt="Fecha Facturación" />
                  </label>
                  <SipcoCalendar
                    className="input-modal"
                    value={formModel.fechaFacturacion}
                    disabled
                    showIcon
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"14683"} alt="Fecha Anulación" />
                  </label>
                  <SipcoCalendar
                    className="input-modal"
                    value={formModel.fechaAnulacion}
                    disabled
                    showIcon
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"16492"} alt="Fecha cierre estimada" />
                  </label>
                  <SipcoCalendar
                    className="input-modal"
                    value={formModel.fechaCierreEstimada}
                    disabled
                    showIcon
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"6160"} alt="Daños" />
                  </label>
                  <SipcoInputTextArea
                    value={formModel.danos}
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"24502"} alt="Clasificación" />
                  </label>
                  <SipcoDropdown
                    value={formModel.clasificacion}
                    disabled
                    optionLabel="descripcion"
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"24501"} alt="Tiempo Total Estimado" />
                  </label>
                  <SipcoInputText
                    value={formModel.tiempoEstimado}
                    className="input-modal"
                    disabled
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"24503"} alt="Costo Total Estimado" />
                  </label>
                  <SipcoInputText
                    value={formModel.costoTotalEstimado}
                    disabled
                    className="input-modal"
                  />
                </div>
              </div>
            </div>
          </div>


        </div>
      </Dialog>
    </div>
  );
};
export default ModalVerOrdenReparacionState;
