import React, { useEffect, useRef, useState } from "react";

import GenericAbm, {
  GenericAbmRef,
} from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { useGenericAbm } from "@shared/GenericAbmContext";
import { OperacionExigidaRf } from "src/models/OperacionExigidaRf";

const INITIAL_FORM = {
  id: null,
  activo: true,
  pais: null,
  cliente: null,
  cuenta: null,
  subcuenta: null,
  cantidadDias: 0,
  codLeyenda: "",
  repetitivo: false,
  unicaVez: false,
} as OperacionExigidaRf;

const RegistrarOperacionesExigidasRf = () => {
  const SCREEN_CODE = "abm0183_registrar_operaciones_exigidas_rf";
  const URL_BASE = "/mantener-operaciones-exigidas-rf";
  const SCREEN_TITLE = (
    <LanguageProvider id="13132" alt="Mantener Operaciones Exigidas por RF" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const abmRef = useRef<GenericAbmRef<OperacionExigidaRf>>();
  // const { genericAbmStatesContextData } = useGenericAbm<any>();

  const empityFilterServicio = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - 
    ${LanguageProvider({ id: "9912", alt: "x" })}`;
  const showToastAndReturnEmptyArray = (message: string) => {
    toast.current?.show({
      severity: "warn",
      detail: message,
      life: 3000,
    });
    return [];
  };

  async function handleSave(model: OperacionExigidaRf, filterValues: any) {
    // try {
    //   if (
    //     filterValues?.securityValues?.country &&
    //     filterValues.securityValues.client &&
    //     filterValues.securityValues.account
    //   ) {
    //     const validacionOperacion = {
    //       ...formState,
    //       pais: filterValues.securityValues.country,
    //       cliente: filterValues.securityValues.client,
    //       cuenta: filterValues.securityValues.account,
    //     };
    //     await sipcoAxiosService.post(URL_BASE + "/save", {
    //       validacionOperacion,
    //     });
    //     return true;
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
    return false;
  }
  async function handleUpdate(model: OperacionExigidaRf, filterValues: any) {
    // try {
    //   const validacionOperacion = {
    //     ...model,
    //     pais: filterValues.securityValues.country,
    //     cliente: filterValues.securityValues.client,
    //     cuenta: filterValues.securityValues.account,
    //   };
    //   await sipcoAxiosService.post(URL_BASE + "/update", {
    //     validacionOperacion,
    //   });
    //   return true;
    // } catch (error) {
    //   console.error(error);
    // }
    return false;
  }
  async function handleRemove(model: OperacionExigidaRf, filterValues: any) {
    // try {
    //   await sipcoAxiosService.post(URL_BASE + "/update", {
    //     validacionOperacion: { ...model, activo: false },
    //   });
    //   return true;
    // } catch (error) {
    //   console.error(error);
    // }
    return false;
  }
  async function handleList(filter: any): Promise<OperacionExigidaRf[]> {
    if (filter?.securityValues?.country && filter.securityValues.client) {
      try {
        const payload = {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter.securityValues.account,
        };
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          payload
        );
        if (status === 200) return data;
        return [];
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<OperacionExigidaRf> {
    // try {
    //   const { status, data } = await sipcoAxiosService.post(
    //     URL_BASE + "/findByPk",
    //     {
    //       validacionOperacion: model,
    //     }
    //   );
    //   if (status === 200) {
    //     setFormState(data);
    //     return data;
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
    return null;
  }

  async function loadGridChkServiciosFilter(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter.securityValues.account
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/servicio",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.label}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  // const openNewTabConcesionario = () => {
  //   if (
  //     genericAbmStatesContextData?.selectedEntries === null ||
  //     genericAbmStatesContextData?.selectedEntries?.length === 0
  //   ) {
  //     return showToastAndReturnEmptyArray(empitySelectGrid);
  //   }

  //   const newTab = window.open(
  //     "/mantener-parametros-operacion-concesionario",
  //     "_blank"
  //   );

  //   const { selectedEntries, filter } = genericAbmStatesContextData;
  //   const dto = {
  //     pais: filter?.securityValues?.country,
  //     cliente: filter?.securityValues?.client,
  //     cuenta: filter?.securityValues?.account,
  //     validacionOperacion: selectedEntries[0],
  //   };

  //   if (newTab) {
  //     setTimeout(() => {
  //       const dataToSend = {
  //         type: "from-mantener-parametrizacion-operaciones", // Identificador da mensagem
  //         payload: dto, // Dados dinâmicos
  //       };

  //       newTab.postMessage(dataToSend, window.origin);
  //     }, 1000);
  //   }
  // };

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<OperacionExigidaRf>
        ref={abmRef}
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
          },
        }}
        // filterSettings={}

        dataTableColumns={[
          {
            field: "operacion.descripcion",
            header: <LanguageProvider id="1569" alt="x" />,
          },
          {
            field: "subcuenta",
            header: <LanguageProvider id="4791" alt="x" />,
            body: (rowData: any) =>
              rowData.subcuenta === null
                ? "TODOS"
                : rowData.subcuenta.descripcion,
          },
          {
            field: "mercado",
            header: <LanguageProvider id="367" alt="x" />,
            body: (rowData: any) =>
              rowData.mercado === null ? "TODOS" : rowData.mercado.descripcion,
          },
          {
            field: "origen",
            header: <LanguageProvider id="2496" alt="x" />,
            body: (rowData: any) =>
              rowData.origen === null ? "TODOS" : rowData.origen.descripcion,
          },
          {
            field: "destino",
            header: <LanguageProvider id="3410" alt="x" />,
            body: (rowData: any) =>
              rowData.destino === null ? "TODOS" : rowData.destino.descripcion,
          },
          {
            field: "marca",
            header: <LanguageProvider id="396" alt="x" />,
            body: (rowData: any) =>
              rowData.marca === null ? "TODOS" : rowData.marca.descripcion,
          },
          {
            field: "modelo",
            header: <LanguageProvider id="2139" alt="x" />,
            body: (rowData: any) =>
              rowData.modelo === null ? "TODOS" : rowData.modelo.descripcion,
          },
          {
            field: "familia",
            header: <LanguageProvider id="10034" alt="x" />,
            body: (rowData: any) =>
              rowData.familia === null ? "TODOS" : rowData.familia.descripcion,
          },
          {
            field: "verificaRangoTiempo",
            header: <LanguageProvider id="13098" alt="x" />,
            body: (rowData: any) => (rowData.verificaRangoTiempo ? "SI" : "NO"),
          },
          {
            field: "unicaVez",
            header: <LanguageProvider id="13105" alt="x" />,
            body: (rowData: any) => (rowData.unicaVez ? "SI" : "NO"),
          },
          {
            field: "repetitivo",
            header: <LanguageProvider id="13106" alt="x" />,
            body: (rowData: any) => (rowData.repetitivo ? "SI" : "NO"),
          },
          {
            field: "despacho",
            header: <LanguageProvider id="382" alt="x" />,
            body: (rowData: any) => (rowData.despacho ? "SI" : "NO"),
          },
          {
            field: "cantidadDias",
            header: <LanguageProvider id="6813" alt="x" />,
          },
          {
            field: "hrDespacho",
            header: <LanguageProvider id="18251" alt="x" />,
            body: (rowData: any) => (rowData.hrDespacho ? "SI" : "NO"),
          },
          {
            field: "sinHrDespacho",
            header: <LanguageProvider id="18282" alt="x" />,
            body: (rowData: any) => (rowData.sinHrDespacho ? "SI" : "NO"),
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: <LanguageProvider id="13706" alt="x" />,
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="x" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={INITIAL_FORM}
        formSettings={[
          {
            field: "operacion",
            label: LanguageProvider({ id: "1569", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "1569", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadOperacion(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "destino",
            label: LanguageProvider({ id: "3410", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "3410", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadDestino(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "subcuenta",
            label: LanguageProvider({ id: "4791", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "4791", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadSubcuenta(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "marca",
            label: LanguageProvider({ id: "396", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "396", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadMarca(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "mercado",
            label: LanguageProvider({ id: "367", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "367", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadMercado(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "modelo",
            label: LanguageProvider({ id: "2139", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "2139", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadModelo(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "origen",
            label: LanguageProvider({ id: "2496", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "2496", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadOrigen(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "familia",
            label: LanguageProvider({ id: "10034", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "10034", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadFamilia(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "chkVerifica",
            type: "checkbox",
            label: LanguageProvider({ id: "13098", alt: "x" }),
          },
          {
            field: "unicaVez",
            type: "radiobutton",
            label: LanguageProvider({ id: "13105", alt: "x" }),
            radioButtonOptions: [
              {
                label: <LanguageProvider id="13105" alt="" />,
                value: "unicaVez",
              },
              {
                label: <LanguageProvider id="13106" alt="" />,
                value: "repetitivo",
              },
            ],
          },
          {
            field: "repetitivo",
            type: "radiobutton",
            label: LanguageProvider({ id: "13106", alt: "x" }),
            radioButtonOptions: [
              {
                label: <LanguageProvider id="13106" alt="" />,
                value: "repetitivo",
              },
              {
                label: <LanguageProvider id="382" alt="" />,
                value: "despacho",
              },
            ],
          },
          {
            field: "verificaRangoTiempo",
            type: "checkbox",
            label: LanguageProvider({ id: "13106", alt: "x" }),
          },
          {
            field: "cantidadDias",
            type: "inputNumber",
            label: LanguageProvider({ id: "6813", alt: "x" }),
          },
          {
            field: "verificaConcesionario",
            type: "checkbox",
            label: LanguageProvider({ id: "18284", alt: "x" }),
          },
          {
            field: "todosConcesionarios",
            type: "checkbox",
            label: LanguageProvider({ id: "5317", alt: "x" }),
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        // customActions={
        //   <>
        //     <Button
        //       label={LanguageProvider({ id: "266", alt: "Concesionarios" })}
        //       onClick={() => {
        //         // openNewTabConcesionario();
        //       }}
        //     />
        //   </>
        // }
      />
    </>
  );
};

export default RegistrarOperacionesExigidasRf;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionTemplateLabelId = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
