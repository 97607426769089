import "./ExportacionPrestacionContable.scss";

import React, { useEffect, useRef, useState } from "react";
import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import useFormattedDate from "../../../hooks/useFormattedDate";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { HttpStatusCode } from "axios";
import ExportButtons from "@shared/components/Export";
import { isString } from "formik";

export function ExportacionPrestacionContable() {
  const SCREEN_CODE = "fun0066_exportacion_prestacion_contable";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [filter, setFilter] = useState({
    movimientos: null,
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    prestaciones: null
  });

  const [loadingMarca, setLoadingMovimientos] = useState(false);
  const [movimientos, setMovimientos] = useState([]);
  async function loadMovimientos() {
    setLoadingMovimientos(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/exportacion-prestacion-contable/codigo-playa-movimiento",
          {
            pais: securityFilters.securityValues.country,
          }
        );

        if (status === HttpStatusCode.Ok) {
          setMovimientos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMovimientos(false);
    }
  }

  const [loadingPrestaciones, setLoadingPrestaciones] = useState(false);
  const [prestaciones, setPrestaciones] = useState([]);

  async function loadPrestaciones(movimiento: any) {
    setLoadingPrestaciones(true);
    try {
      if (movimiento) {
        const { status, data } = await sipcoAxiosService.post(
          "/exportacion-prestacion-contable/prestacion-contable",
          { movimiento }
        );
        if (status === HttpStatusCode.Ok) {
          setPrestaciones(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPrestaciones(false);
    }
  }

  async function handleChangeMovimientos(value: any) {
    try {
      const prestaciones = await loadPrestaciones(value.target.value);

      setFilter({ ...filter, movimientos: value.target.value, prestaciones })
    } catch (error) {
      console.error(error);
    }
  }

  async function loadReportData() {
    setLoading(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/exportacion-prestacion-contable/findBy",
        {
          cliente: securityFilters.securityValues.client,
          movimiento: filter.movimientos,
          fechaDesde: filter.fechaDesde ? new Date(filter.fechaDesde).getTime() : null,
          fechaHasta: filter.fechaHasta ? new Date(filter.fechaHasta).getTime() : null,
          prestacionList: filter.prestaciones
        }
      );
      if (status === HttpStatusCode.Ok) {
        const dadosTransformados = transformarFilas(data);
        setReporte(dadosTransformados);
        return dadosTransformados;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };



  function transformarFilas(response: any) {
    if (!response?.filas) return [];

    return response.filas.map((linha: any[]) => ({
      CODREG: linha[0], // Código de Registro
      CODEST: linha[1], // Código de Estado
      CODORI: linha[2], // Código de Origem
      CODPRE: linha[3], // Código de Prestação
      NRODOC: linha[4], // Número do Documento
      FECMOV: linha[5], // Data do Movimento
      HORMOV: linha[6], // Hora do Movimento
      SUBREN: linha[7], // Sub Renda
      CODCLI: linha[8], // Código do Cliente
      CFCUNI: linha[9], // Unidade de Cálculo
      CFCKMS: linha[10], // Cálculo de Quilometragem
      CFCPES: linha[11], // Cálculo de Peso
      CFCVOL: linha[12], // Cálculo de Volume
      CFCTIE: linha[13], // Cálculo de Tempo
      CFCVAL: linha[14], // Cálculo de Valor
      CODPRO: linha[15], // Código do Produto
      CFPUNI: linha[16], // Cálculo do Preço Unitário
      CFPKMS: linha[17], // Cálculo por Quilometragem
      CFPPES: linha[18], // Cálculo por Peso
      CFPVOL: linha[19], // Cálculo por Volume
      CFPTIE: linha[20], // Cálculo por Tempo
      CFPVAL: linha[21], // Cálculo por Valor
      DESCRI: linha[22], // Descrição
      SIGNO: linha[23], // Sinal
      CODCON: linha[24], // Código de Controle
      CODMOD: linha[25], // Código do Modelo
      CODRUT: linha[26], // Código da Rota
      DESRUT: linha[27], // Descrição da Rota
      MARCER: linha[28], // Marca de Serviço
      IMPSEV: linha[29], // Importância do Serviço
      NRORESENT: linha[30], // Número de Resent
      CENCOS: linha[31], // Centro de Custo
      NROLOTE: linha[32], // Número do Lote
      NROSEC: linha[33], // Número de Secção
      FILLER: linha[34], // Filler
      ERROR: linha[35], // Erro
      CONEX: linha[36], // Conexão
      ID: linha[37], // ID
      ULT_MODIFICACION: linha[38], // Última Modificação (Usuário)
      COT_CLIENTE: linha[39], // Cotação Cliente
      COT_PROVEEDOR: linha[40], // Cotação Fornecedor
      MARCA_ERROR: linha[41], // Marca de Erro    
      NROSEC_ORI: linha[42], // Número de Secção Original
      JOB_ID: linha[43], // ID do Job
      FH_LOCK: linha[44]?.date ?? null, // Data e Hora do Lock
      USU_ULT_MOD: linha[45], // Usuário da Última Modificação
      FEC_ULT_MOD: linha[46]?.date ?? null, // Data da Última Modificação
      USU_GENERACION_LOGISTICA: linha[47], // Usuário Geração Logística
      FEC_GENERACION_LOGISTICA: linha[48]?.date ?? null, // Data Geração Logística
      COD_PAIS: linha[49], // Código do País
    }));
  }




  useEffect(() => {
    const init = async () => {
      try {
        const movimientos = await loadMovimientos();

        setFilter({
          ...filter,
          movimientos
        });
      } catch (error) {
        console.error(error);
      };
    };

    init();
  }, [securityFilters.securityValues]);



  return (
    <div className="exportacion-prestacion-contable">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"7795"} alt="Filtrar Por:" />}>
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1394"} alt="Movimientos (Pendientes de Cobro / Cobrados / Todos)" />
                </label>
                <SipcoDropdown
                  loading={loadingMarca}
                  value={filter?.movimientos}
                  options={movimientos}
                  onChange={(e: any) => handleChangeMovimientos(e)}
                  optionLabel="descripcion"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1070"} alt="Fecha desde" />
                </label>
                <Calendar
                  value={filter?.fechaDesde}
                  onChange={(e: any) => setFilter({ ...filter, fechaDesde: e.value })}
                  showIcon
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1133"} alt="Fecha hasta" />
                </label>
                <Calendar
                  value={filter?.fechaHasta}
                  onChange={(e: any) => setFilter({ ...filter, fechaHasta: e.value })}
                  showIcon
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1395"} alt="Prestaciones" />
                </label>
                <MultiSelect
                  loading={loadingPrestaciones}
                  value={filter?.prestaciones}
                  options={prestaciones}
                  onChange={(e: any) => setFilter({ ...filter, prestaciones: e.value })}
                  filter
                />
              </div>

            </div>
          </div>
        </AccordionTab>

      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}><LanguageProvider id={"6697"} alt="Listado" /></span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={null}
                  columns={null}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          ref={dt}
          value={reporte}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRow}
          onSelectionChange={(e: any) => setSelectedRow(e.value)}
        >
          <SipcoColumn field="CODREG" header="CODREG" />
          <SipcoColumn field="CODEST" header="CODEST" />
          <SipcoColumn field="CODORI" header="CODORI" />
          <SipcoColumn field="CODPRE" header="CODPRE" />
          <SipcoColumn field="NRODOC" header="NRODOC" />
          <SipcoColumn field="FECMOV" header="FECMOV" />
          <SipcoColumn field="HORMOV" header="HORMOV" />
          <SipcoColumn field="SUBREN" header="SUBREN" />
          <SipcoColumn field="CODCLI" header="CODCLI" />
          <SipcoColumn field="CFCUNI" header="CFCUNI" />
          <SipcoColumn field="CFCKMS" header="CFCKMS" />
          <SipcoColumn field="CFCPES" header="CFCPES" />
          <SipcoColumn field="CFCVOL" header="CFCVOL" />
          <SipcoColumn field="CFCTIE" header="CFCTIE" />
          <SipcoColumn field="CFCVAL" header="CFCVAL" />
          <SipcoColumn field="CODPRO" header="CODPRO" />
          <SipcoColumn field="CFPUNI" header="CFPUNI" />
          <SipcoColumn field="CFPKMS" header="CFPKMS" />
          <SipcoColumn field="CFPPES" header="CFPPES" />
          <SipcoColumn field="CFPVOL" header="CFPVOL" />
          <SipcoColumn field="CFPTIE" header="CFPTIE" />
          <SipcoColumn field="CFPVAL" header="CFPVAL" />
          <SipcoColumn field="DESCRI" header="DESCRI" />
          <SipcoColumn field="SIGNO" header="SIGNO" />
          <SipcoColumn field="CODCON" header="CODCON" />
          <SipcoColumn field="CODMOD" header="CODMOD" />
          <SipcoColumn field="CODRUT" header="CODRUT" />  
          <SipcoColumn field="DESRUT" header="DESRUT" />
          <SipcoColumn field="MARCER" header="MARCER" />
          <SipcoColumn field="IMPSEV" header="IMPSEV" />
          <SipcoColumn field="NRORESENT" header="NRORESENT" />
          <SipcoColumn field="CENCOS" header="CENCOS" />
          <SipcoColumn field="NROLOTE" header="NROLOTE" />
          <SipcoColumn field="NROSEC" header="NROSEC" />
          <SipcoColumn field="FILLER" header="FILLER" />
          <SipcoColumn field="ERROR" header="ERROR" />
          <SipcoColumn field="CONEX" header="CONEX" />
          <SipcoColumn field="ID" header="ID" />
          <SipcoColumn field="ULT_MODIFICACION" header="ULT_MODIFICACION" />
          <SipcoColumn field="COT_CLIENTE" header="COT_CLIENTE" />
          <SipcoColumn field="COT_PROVEEDOR" header="COT_PROVEEDOR" />
          <SipcoColumn field="MARCA_ERROR" header="MARCA_ERROR" />
          <SipcoColumn field="NROSEC_ORI" header="NROSEC_ORI" />
          <SipcoColumn field="JOB_ID" header="JOB_ID" />
          <SipcoColumn field="FH_LOCK" header="FH_LOCK" />
          <SipcoColumn field="USU_ULT_MOD" header="USU_ULT_MOD" />
          <SipcoColumn field="FEC_ULT_MOD" header="FEC_ULT_MOD" />
          <SipcoColumn field="USU_GENERACION_LOGISTICA" header="USU_GENERACION_LOGISTICA" />
          <SipcoColumn field="FEC_GENERACION_LOGISTICA" header="FEC_GENERACION_LOGISTICA" />
          <SipcoColumn field="COD_PAIS" header="COD_PAIS" />
        </SipcoDataTable>

      </Panel>
    </div>
  );
}
export default ExportacionPrestacionContable;

