import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import logoBase64 from "../../../assets/excel-templates/imageBase64";
import { LanguageProviderString } from "../language-provider-string";

/**
 * Exporta dados para um arquivo Excel com um formato específico.
 *
 * O arquivo Excel exportado inclui:
 * - Uma imagem de logo no topo.
 * - Informações sobre o usuário e a data e hora atual.
 * - Uma linha de cabeçalho com os rótulos das colunas.
 * - As linhas de dados a partir da 7ª linha.
 * - Uma linha de resumo com a contagem dos registros.
 * - Informações de filtro opcionais, caso um filtro seja aplicado.
 *
 * O parâmetro `filterProps` é utilizado para passar os filtros que devem ser incluídos na exportação. Ele deve ser um array de objetos, onde cada objeto contém um identificador do filtro (como a chave) e o valor (como a descrição ou data) que será exibido como valor do filtro.
 *
 * O parâmetro `filter` é um booleano que determina se os filtros devem ser incluídos na exportação. Quando configurado como `true`, os filtros são incluídos; quando configurado como `false`, eles são excluídos.
 *
 * No componente pai, você deve criar um estado para armazenar os filtros e seus respectivos valores. Esse estado será passado para o parâmetro `filterProps` no componente `ExportButtons`.
 *
 * Para definir os filtros, uma função como `setFilterExport` deve ser implementada para construir o array `filterProps` na ordem desejada. A ordem dos filtros nesse array deve corresponder à ordem em que eles são exibidos na tela.
 *
 * **Importante**: A função `setFilterExport` deve ser acionada sempre após a execução bem-sucedida da função principal de preenchimento da tela, como, por exemplo, ao clicar no botão "Buscar" que preenche as informações em um `DataTable`. Isso garante que os filtros aplicados e seus valores sejam capturados corretamente antes da exportação.
 *
 * Exemplo:
 * ```javascript
 * 
 * const [filterParamsExport, setFilterParamsExport] = useState([]); *
 * function setFilterExport() {
 *   const filters = [
 *     { 1499: securityFilters.securityValues.country?.descripcion },
 *     { 488: securityFilters.securityValues.client?.descripcion },
 *     { 1791: securityFilters.securityValues.account?.descripcion },
 *     { 4791: securityFilters.securityValues.subaccount?.descripcion },
 *     { 467: filter.fechaInicio },
 *     { 468: filter.fechaFinal },
 *   ];
 *   setFilterParamsExport(filters);
 * }
 *
 * function loadReport() {
 *   // Função para buscar dados e preencher o DataTable
 *   // Após preencher o DataTable de forma bem sucedida, chama setFilterExport
 *   setFilterExport();
 * }
 *
 * versão para xlsx simples com apenas uma aba
 * <ExportButtons
 *   dt={dt}
 *   data={DataTableDataValue} 
 *   columns={columnsNames}     
 *   screenName={"Liberados Vs Despachados"}
 *   pdf={false}
 *   csv={false}
 *   filterProps={filterParamsExport} // Filtros a serem exportados
 *   filter={true} // Incluir filtros na exportação
 * />
 * 
 * versão para xlsx com multiplas abas
 * ExportButtons recebe dataArray ao invés de data e columns que é um objeto onde cada dataTable em tela 
 * sera uma posição do array que é um objeto DataTableResponse, com data, columns e tab 
 * 
 * interface DataTableResponse {
 *   data: any[]; // valores das linhas
 *   columns: any[]; // ids das colunas
 *   tab: {id?: number, alt?: string}; // ids das tabs(aba) da plhanilha
 * }
 * 
 * <ExportButtons
 *   dt={dt}
 *   dataArray={[
 *     {
 *       data: DataTableData1Value,
 *       columns: columnsNames1,
 *       tab: { id: 1, alt: "TabName1" },
 *     },
 *     {
 *       data: DataTableData2Value,
 *       columns: columnsNames2,
 *       tab: { id: 2, alt: "TabName1" },
 *     },
 *   ]}
 *   screenName={"Liberados Vs Despachados"}
 *   pdf={false}
 *   csv={false}
 *   filterProps={filterParamsExport} // Filtros a serem exportados
 *   filter={true} // Incluir filtros na exportação
 * />
 * 
 * ```
 *
 * No exemplo acima, `filterParamsExport` é um array de objetos, onde cada objeto representa um filtro com seu ID como chave e o valor do filtro como valor correspondente. A ordem dos objetos no array deve corresponder à ordem em que os filtros são exibidos na interface.
 *
 * @async
 * @function exportExcel
 * @param {Array} filterProps - Array de filtros a serem incluídos na exportação. Cada filtro é representado como um objeto com um identificador de filtro e seu valor correspondente.
 * @param {boolean} filter - Determina se os filtros devem ser incluídos na exportação. `true` inclui os filtros, `false` exclui-os.
 * @returns {Promise<void>} Uma promessa que resolve quando o arquivo Excel for gerado e salvo.
 */

interface DataTableResponse {
  data: any[]; // valores das linhas
  columns: any[]; // ids das colunas
  tab: { id?: number, alt?: string }; // ids das tabs(aba) da plhanilha
}

interface ExportPropsType {
  data?: any[];
  dataArray?: DataTableResponse[];
  columns?: any[];
  screenName: string;
  filterProps?: any[];
  filter?: boolean;
  user: any;
}

const ExportExcelDataTable = async ({
  data,
  dataArray,
  columns,
  screenName,
  filterProps = [],
  filter = false,
  user,
}: ExportPropsType): Promise<void> => {
  const workbook = new ExcelJS.Workbook();

  let dataToProcess = [];
  if (dataArray) {
    dataToProcess = dataArray;
  } else {
    dataToProcess = [{ data: data, tab: { alt: "data" }, columns: columns }];
  }

  dataToProcess.forEach((dt) => {
    const worksheet = workbook.addWorksheet(LanguageProviderString({
      user,
      id: dt.tab,
      alt: dt.tab.alt,
    }));

    // Adicionar imagem
    const imageId = workbook.addImage({
      base64: logoBase64,
      extension: "png",
    });
    worksheet.addImage(imageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 1115, height: 40 },
    });

    // Informações básicas na parte superior
    worksheet.mergeCells("A3:G3");
    worksheet.getCell("A3").value = "CEVA LOGISTICS";
    worksheet.getCell("A3").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.mergeCells("A4:G4");
    worksheet.getCell("A4").value = screenName;
    worksheet.getCell("A4").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.mergeCells("A5:G5");
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(user?.idioma?.codigoIso);
    const formattedTime = currentDate.toLocaleTimeString(user?.idioma?.codigoIso);

    worksheet.getCell("A5").value = `${LanguageProviderString({
      user,
      id: "9369",
      alt: "Usuário:",
    })} ${user?.nombre || "User Name"} - ${LanguageProviderString({
      user,
      id: "191",
      alt: "Data:",
    })} ${formattedDate} - ${LanguageProviderString({
      user,
      id: "2201",
      alt: "Hora",
    })}: ${formattedTime}`;
    worksheet.getCell("A5").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Cabeçalho
    const header = dt.columns.map((column) => column.label);
    worksheet.addRow(header);

    // Estilizar cabeçalho
    const headerRow = worksheet.getRow(6);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "003366" },
      };
      cell.font = { bold: true, color: { argb: "FFFFFF" } };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    
    if (dt.data?.length > 0) {
      dt.data.forEach((row) => { 
        if (Array.isArray(row)) {
          worksheet.addRow(row.map((cellValue) => cellValue || ""));
        } else {
          // Opcional: Pode registrar ou tratar de outra forma o caso de row não ser um array
          console.log('Row não é um array válido:', row);
        }        
      });
    }

    // Adicionar linhas em branco e resumo
    worksheet.addRow([]);
    worksheet.addRow([
      `${LanguageProviderString({
        user,
        id: "3116",
        alt: "Quantidade de Registros",
      })}:`,
      dt.data?.length,
    ]);


    // Adicionar filtros
    const addFiltersToWorksheet = (worksheet: ExcelJS.Worksheet): void => {
      filterProps.forEach((filter) => {
        const [key, value] = Object.entries(filter)[0] || ["", ""];
        worksheet.addRow([
          LanguageProviderString({ user, id: key }),
          value || "",
        ]);
      });
    };

    if (filter) {
      worksheet.addRow([]);
      worksheet.addRow([
        LanguageProviderString({ user, id: "5583", alt: "Filtros" }),
      ]);
      worksheet.addRow([]);
      addFiltersToWorksheet(worksheet);
    }

    // Ajustar largura das colunas
    worksheet.columns.forEach((column, index) => {
      const labelLength = dt.columns[index]?.label?.length || 0;
      column.width = Math.max(labelLength + 5, 10);
    });

  });

  try {
    // Gerar e salvar o arquivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const EXCEL_EXTENSION = ".xlsx";
    saveAs(new Blob([buffer]), `${screenName}_export${EXCEL_EXTENSION}`);
  } catch (error) {
    console.error("Erro ao exportar arquivo Excel:", error);
  }
};

export default ExportExcelDataTable;
