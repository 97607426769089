import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { PartesDanios } from "src/models/PartesDanios";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerPartesDanios = () => {
  const SCREEN_CODE = "abm0007_mantener_partesdanios";
  const URL_BASE = "/mantener-partes-danos";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: PartesDanios, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client
      ) {
        const partesDanos = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          partesDanos: partesDanos,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: PartesDanios) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/update", model);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: PartesDanios) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        partesDanos: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<PartesDanios[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<PartesDanios> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        { partesDanos: model }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }
  async function loadGrupos(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/grupo-danos",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<PartesDanios>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="177" alt="Mantener Partes Dañadas" />}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: "",
        descripcion: "",
        activo: true,
        cliente: null,
        grupo: null,
        pais: null,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Codigo" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="16243" alt="Descripcion" />,
        },
        {
          field: "grupo.descripcion",
          header: <LanguageProvider id="16244" alt="Grupo de daños" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.timestamp",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modifcicacion" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      loadDropdownData={{ grupo: loadGrupos }}
      formSettings={[
        {
          field: "id",
          required: true,
          label: <LanguageProvider id="6590" alt="Codigo" />,
          type: "inputText",
          length: 4,
        },
        {
          field: "descripcion",
          required: true,
          label: <LanguageProvider id="16240" alt="Descripcion" />,
          type: "textArea",
          length: 50,
        },
        {
          field: "grupo",
          label: <LanguageProvider id="176" alt="Seleccione un Grupo" />,
          type: "dropdown",
          dataSource: (filter: any) => loadGrupos(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dropDownPlaceholder: LanguageProvider({
            id: "176",
            alt: "Seleccione un Grupo",
          }),
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
    />
  );
};

export default MantenerPartesDanios;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
