import { Route, Routes } from "react-router-dom";

import AdministracionNotasFiscales from "@pages/functions/administracion-notas-fiscales/AdministracionNotasFiscales";
import AdministracionRemitosAConcesionarios from "@pages/functions/administracion-remitos-a-concesionarios/AdministracionRemitosAConcesionarios";
import { AdministracionRemitosFicticio } from "@pages/functions/administracion-remitos-ficticio/AdministracionRemitosFicticio";
import AntiguedadStock from "@pages/queries/antiguedad-stock/AntiguedadStock";
import AnulacionEcom from "@pages/functions/anulacion-ecom/AnulacionEcom";
import AnularRemitos from "@pages/functions/anular-remitos/AnularRemitos";
import { AprobacionOperaciones } from "@pages/functions/aprobacion-operaciones/AprobacionOperaciones";
import BloqueoUbicaciones from "@pages/functions/bloqueo-ubicaciones/BloqueoUbicaciones";
import { BloqueosDesbloqueos } from "@pages/functions/bloqueos-desbloqueos/BloqueosDesbloqueos";
import CargaDanos from "@pages/functions/carga-danos/CargaDanos";
import CargaDeRetornos from "@pages/functions/carga-de-retornos/CargaDeRetornos";
import { CargaEstadoVin } from "@pages/functions/carga-estado-vin/CargaEstadoVin";
import CargaManualRemitos from "@pages/functions/carga-manual-remitos/CargaManualRemitos";
import CargaManualVin from "@pages/functions/carga-manual-vin/CargaManualVin";
import { CargaMasivaDanos } from "@pages/functions/carga-masiva-danos/carga-masiva-danos";
import { CargaOperacionesForzadasBr } from "@pages/functions/CargaOpForzadas/Carga-operaciones-forzadasBr";
//import { CargaOperacionesForzadasAr } from "@pages/functions/CargaOpForzadas/Carga-operaciones-forzadasAr";
import CiclosOperacion from "@pages/abms/CiclosOperacion";
import { CierreContable } from "@pages/functions/cierre-contable/CierreContable";
import CierreEstadia from "@pages/functions/cierre-estadia/CierreEstadia";
import Colecta from "@pages/functions/colecta/Colecta";
import ComprasTaller from "@pages/queries/compras-taller/ComprasTaller";
import { ConIngresoTaller } from "@pages/queries/ingresso-taller/ConIngresoTaller";
import ConcesionariosCodigosNostra from "@pages/abms/ConcesionariosCodigosNostra";
import ConsultaBloqueos from "@pages/queries/consulta-bloqueos/ConsultaBloqueos";
import ConsultaGeneralTransacciones from "@pages/queries/consulta-general-de-transacciones/ConsultaGeneralTransacciones";
import ConsultaOfertasServicio from "@pages/queries/consulta-ofertas-servicio/ConsultaOfertasServicio";
import ConsultaOperacionesCanceladasWeb from "@pages/queries/consulta-operaciones-canceladas-web/ConsultaOperacionesCanceladasWeb";
import ConsultaOperacionesPendientes from "@pages/queries/consulta-operaciones-pendientes/ConsultaOperacionesPendientes";
import ConsultaOsTerceros from "@pages/queries/consulta-os-terceros/ConsultaOsTerceros";
import ConsultaOt from "@pages/queries/consulta-ot/ConsultaOt";
import ConsultaTranslado from "@pages/queries/consulta-translado/ConsultaTranslado";
import { ConsultaVin } from "@pages/queries/consulta-vin/consulta-vin";
import ConsultaVinesPreparar from "@pages/queries/consulta-vines-preparar/ConsultaVinesPreparar";
import { ContabilizacionAnulacion } from "@pages/functions/contabilizacion-anulacion-de-operaciones/ContabilizacionAnulacion";
import ContabilizarViajesTerceros from "@pages/functions/contabilizar-viajes-terceros/contabilizar-viajes-terceros";
import ContableSistemaOrigens from "@pages/abms/ContableSistemaOrigens";
import ControlExw from "@pages/abms/ControlExw";
import DanioPendienteTaller from "@pages/abms/DanioPendienteTaller";
import DaniosCheckpoint from "@pages/queries/danio-x-checkpoint/DaniosCheckpoint";
import DespachoMan from "@pages/functions/despacho-man/DespachoMan";
import DespachoMercadoExportacion from "@pages/functions/despacho-mercado-exportacion/DespachoMercadoExportacion";
import DespachoMercadoNacional from "@pages/functions/despacho-mercado-nacional/DespachoMercadoNacional";
import DespachoTerceros from "@pages/functions/despacho-terceros/DespachoTerceros";
import Despachos from "@pages/queries/despachos/despacho";
import { DetalleDeValesRepuestos } from "@pages/functions/mantener-vales-de-repuestos/componentes/DetalleDeValesRepuestos";
import DevolucionMan from "@pages/functions/devolucion-man/DevolucionMan";
import { DevolucionMercadoLocal } from "@pages/queries/devolucion-mercado-local/DevolucionMercadoLocal";
import DisponibilidadImportacion from "@pages/functions/disponibilidad-importacion/DisponibilidadImportacion";
import DocumentacionTransportes from "@pages/queries/documentacio-de-transportes/DocumentacionTransportes";
import EgresoTaller from "@pages/functions/egreso-taller/EgresoTaller";
import { EliminarBuqueCamion } from "@pages/functions/eliminar-llegada-buque-camion/EliminarBuqueCamion";
import { FilterTest } from "@pages/filter-test/filter-test";
import { FunComprasTaller } from "@pages/functions/compras-taller/fun-Compras-Taller";
import GastosExtraPlaya from "@pages/functions/gastos-extra-playa/GastosExtraPlaya";
import GenPicking from "@pages/functions/gen-picking/GenPicking";
import GeneracionOt from "@pages/functions/generacion-ot/GeneracionOt";
import GestionOperaciones from "@pages/functions/gestion-de-operaciones/GestionOperaciones";
import GestionPreparacion from "@pages/functions/gestion-preparacion/GestionPreparacion";
import HistoriaVin from "@pages/queries/historia-vin/HistoriaVin";
import HistoricoDanos from "@pages/queries/historico-danios/HistoricoDanos";
import Home from "@pages/home/home";
import ImpresionChecklist from "@pages/functions/impresion-checklist/ImpresionChecklist";
import ImpresionEtiquetas from "@pages/functions/impresion-etiquetas/ImpresionEtiquetas";
import { ImpresionManualGuias } from "@pages/functions/impresion-manual-guias/ImpresionManualGuias";
import ImpressionHojaRuta from "@pages/queries/impression-hoja-ruta/ImpressionHojaRuta";
import { InformaTransportista } from "@pages/functions/informa-transportista/InformaTransportista";
import IngresoEcom from "@pages/functions/ingreso-ecom/ingreso-ecom";
import { IngresoEcomDaniosFord } from "@pages/functions/ingreso-ecom-danios-ford/IngresoEcomDaniosFord";
import { IngresoManualVehiculos } from "@pages/functions/ingreso-manual-de-vehiculos/IngresoManualVehiculos";
import { IngresoMovManuales } from "@pages/functions/ingreso-mov-manuales/IngresoMovManuales";
import { IngresoTaler } from "@pages/functions/ingreso-taller/IngresoTaler";
import IngresoTallerManual from "@pages/functions/ingreso-taller-manual/IngresoTallerManual";
import IngressoPlaya from "@pages/queries/ingreso-playa/IngresoPlaya";
import { InterfazIngresoFord } from "@pages/functions/interfaz-ingreso-ford/InterfazIngresoFord";
import { LevantarIndisponibilidad } from "@pages/functions/levantar-indisponibilidad/LevantarIndisponibilidad";
import LiberacionAduana from "@pages/functions/liberacion-aduana/LiberacionAduana";
import LiberacionDespachos from "@pages/queries/liberacion-despachos/LiberacionDespachos";
import LiberadosDespachados from "@pages/queries/liberados-despachados/LiberadosDespachados";
import ListadoApoyoEstadia from "@pages/functions/listado-apoyo-estadia/ListadoApoyoEstadia";
import ListadoArmadoViajes from "@pages/queries/listado-armado-viajes/ListadoArmadoViajes";
import ListadoColectas from "@pages/queries/listado-colectas/ListadoColectas";
import ListadoHojaRuta from "@pages/queries/listado-hoja-ruta/listado-hoja-ruta";
import ListadoOperacionesRealizadas from "@pages/queries/listado-operaciones-realizadas/ListadoOperacionesRealizadas";
import LlegadaBuqueCamion from "@pages/functions/llegada-buque-camion/LlegadaBuqueCamion";
import Login from "@pages/admin/authentication/login";
import LoginLayout from "@shared/login-layout";
import MainLayout from "@shared/main-layout";
import ManejarErroresRemitos from "@pages/functions/manejar-errores-remitos/ManejarErroresRemitos";
import MantenerAcabados from "@pages/abms/MantenerAcabados";
import MantenerAnioFabricacion from "@pages/abms/MantenerAnioFabricacion";
import MantenerAsuntos from "@pages/abms/MantenerAsuntos";
import MantenerBaseConcepcion from "@pages/abms/MantenerBaseConcepcion";
import MantenerCabeceraOpcionales from "@pages/abms/MantenerCabeceraOpcionales";
import MantenerCajas from "@pages/abms/MantenerCajas";
import MantenerCalendarioDesvioEntrega from "@pages/abms/MantenerCalendarioDesvioEntrega";
import MantenerCatalogoRepuestos from "@pages/functions/mantener-catalogo-repuestos/MantenerCatalogoRepuestos";
import MantenerCatalogoServicios from "@pages/functions/mantener-catalogo-servicios/MantenerCatalogoServicios";
import MantenerCategorias from "@pages/abms/MantenerCategorias";
import MantenerCentroCostos from "@pages/abms/MantenerCentroCostos";
import MantenerCentrosCostoGefco from "@pages/abms/MantenerCentrosCostoGefco";
import MantenerChecklist from "@pages/abms/MantenerChecklist";
import MantenerCheckpointTransaccion from "@pages/abms/MantenerCheckpointTransaccion";
import MantenerCheckpoints from "@pages/abms/MantenerCheckpoints";
import MantenerChoferes from "@pages/abms/MantenerChoferes";
import MantenerChoferesProveedores from "@pages/abms/MantenerChoferesProveedores";
import MantenerCiudades from "@pages/abms/MantenerCiudades";
import MantenerClienteConcepcion from "@pages/abms/MantenerClienteConcepcion";
import MantenerClienteImputaciones from "@pages/abms/MantenerClienteImputaciones";
import MantenerClientes from "@pages/functions/mantener-clientes/MantenerClientes";
import MantenerCoeficientesFamiliaTransporte from "@pages/abms/MantenerCoeficientesFamiliaTransporte";
import MantenerCoeficientesFletes from "@pages/abms/MantenerCoeficientesFletes";
import MantenerColores from "@pages/abms/MantenerColores";
import MantenerConcesTipoDespacho from "@pages/abms/MantenerConcesionariosTipoDespacho";
import MantenerConcesionarios from "@pages/abms/MantenerConcesionarios";
import MantenerConcesionariosTipoTransporte from "@pages/abms/MantenerConcesionariosTipoTransporte";
import MantenerContableCiudades from "@pages/abms/MantenerContableCiudades";
import MantenerContableFamiliasRadio from "@pages/abms/MantenerContableFamiliasRadio";
import MantenerContableOrigenesPlayas from "@pages/abms/MantenerContableOrigenesPlayas";
import MantenerContablePuntosDeRetiro from "@pages/abms/MantenerContablePuntosDeRetiro";
import MantenerContableRutinaPrestacion from "@pages/abms/MantenerContableRutinaPrestacion";
import MantenerContableSubcuentas from "@pages/abms/MantenerContableSubcuentas";
import MantenerCorredores from "@pages/abms/MantenerCorredores";
import MantenerCorredoresPrioridad from "@pages/abms/MantenerCorredoresPrioridad";
import MantenerCuadrantes from "@pages/abms/MantenerCuadrantes";
import MantenerCuentasGastos from "@pages/abms/MantenerCuentasGastos";
import MantenerCuentasTaller from "@pages/abms/MantenerCuentasTaller";
import MantenerCupoDespacho from "@pages/abms/MantenerCupoDespacho";
import MantenerCupoPpo from "@pages/abms/MantenerCupoPpo";
import MantenerCupoTaller from "@pages/abms/MantenerCupoTaller";
import MantenerCupoTransferencia from "@pages/abms/MantenerCupoTransferencia";
import MantenerDaniosUnidadesMedidas from "@pages/abms/MantenerDaniosUnidadesMedidas";
import MantenerDenominacionComercial from "@pages/abms/MantenerDenominacionComercial";
import MantenerDestinos from "@pages/abms/MantenerDestinos";
import MantenerDesvioEntrega from "@pages/abms/MantenerDesvioEntrega";
import MantenerDiasEntrega from "@pages/abms/MantenerDiasEntrega";
import MantenerDistPlayaEntregas from "@pages/abms/MantenerDistPlayaEntregas";
import MantenerDocumentos from "@pages/abms/MantenerDocumentos";
import MantenerEstadiaParamPlayas from "@pages/abms/MantenerEstadiaParamPlayas";
import MantenerEstadoVehiculo from "@pages/abms/MantenerEstadoVehiculo";
import MantenerEstadosRemito from "@pages/abms/MantenerEstadosRemito";
import MantenerEventosMails from "@pages/abms/MantenerEventosMails";
import MantenerFamilias from "@pages/abms/MantenerFamilias";
import MantenerFeriados from "@pages/abms/MantenerFeriados";
import MantenerFiltrosPreparacion from "@pages/abms/MantenerFiltrosPreparacion";
import MantenerFuncionRutina from "@pages/abms/MantenerFuncionRutina";
import MantenerFuncionesContables from "@pages/abms/MantenerFuncionesContables";
import MantenerGeneros from "@pages/abms/MantenerGeneros";
import MantenerGravedadDeDanio from "@pages/abms/MantenerGravedadDeDanio";
import MantenerGrupoOperaciones from "@pages/abms/MantenerGrupoOperaciones";
import MantenerGruposDanios from "@pages/abms/MantenerGruposDanios";
import MantenerGruposTaller from "@pages/abms/MantenerGruposTaller";
import MantenerImpuestosIcms from "@pages/abms/MantenerImpuestosIcms";
import MantenerImputaciones from "@pages/abms/MantenerImputaciones";
import MantenerImputacionesResponsables from "@pages/abms/mantener-imputaciones-responsables/MantenerImputacionesResponsables";
import MantenerInspectoresAveria from "@pages/abms/MantenerInspectoresAveria";
import MantenerInterfazTransferencia from "@pages/abms/MantenerInterfazTransferencia";
import MantenerLeyendasRemito from "@pages/abms/MantenerLeyendasRemito";
import MantenerLineasOpcionales from "@pages/abms/MantenerLineasOpcionales";
import MantenerLocalidad from "@pages/abms/MantenerLocalidad";
import MantenerMarcaDestinos from "@pages/abms/MantenerMarcaDestinos";
import MantenerMarcas from "@pages/abms/MantenerMarcas";
import MantenerMatrizTimpoe from "@pages/abms/MantenerMatrizTimpoe";
import MantenerMecanicos from "@pages/abms/MantenerMecanicos";
import MantenerMercados from "@pages/abms/MantenerMercados";
import MantenerMercadosCliente from "@pages/abms/MantenerMercadosCliente";
import MantenerModelos from "@pages/abms/mantener-modelos/MantenerModelos";
import MantenerModelosRadios from "@pages/abms/MantenerModelosRadios";
import MantenerModelosTaller from "@pages/abms/MantenerModelosTaller";
import MantenerModificaciones from "@pages/abms/MantenerModificaciones";
import MantenerMotivoBajaDanio from "@pages/abms/MantenerMotivoBajaDanio";
import MantenerMotivosBaja from "@pages/abms/MantenerMotivosBaja";
import MantenerMotivosDemora from "@pages/abms/MantenerMotivosDemora";
import MantenerMotorizaciones from "@pages/abms/MantenerMotorizaciones";
import MantenerMovimientosFacturar from "@pages/abms/MantenerMovimientosFacturar";
import MantenerNaturalezasFiscales from "@pages/abms/MantenerNaturalezasFiscales";
import MantenerNotaPedidoFlete from "@pages/abms/MantenerNotaPedidoFlete";
import MantenerOcurrencias from "@pages/abms/MantenerOcurrencias";
import MantenerOrdenesInternas from "@pages/abms/MantenerOrdenesInternas";
import MantenerOrigen from "@pages/abms/MantenerOrigenes";
import MantenerOrigenesCliente from "@pages/abms/MantenerOrigenesCliente";
import MantenerPaises from "@pages/abms/MantenerPais";
import MantenerParamCargosTalleres from "@pages/abms/MantenerParamCargosTalleres";
import MantenerParametrosClienteSipco from "@pages/abms/MantenerParametrosClienteSipco";
import MantenerParametrosCobroPrestacion from "@pages/abms/MantenerParametrosCobroPrestacion";
import MantenerParametrosNavieras from "@pages/abms/MantenerParametrosNavieras";
import MantenerPartesCuadrantes from "@pages/abms/MantenerPartesCuadrantes";
import MantenerPartesDanios from "@pages/abms/MantenerPartesDanios";
import MantenerPermisosCalidad from "@pages/abms/mantener-permisos-calidad/MantenerPermisosCalidad";
import MantenerPlayaClienteGuarda from "@pages/abms/MantenerPlayaClienteGuarda";
import MantenerPlayaClienteLiberacion from "@pages/abms/MantenerPlayaClienteLiberacion";
import MantenerProveedores from "@pages/abms/MantenerProveedores";
import MantenerProveedoresBocaentrega from "@pages/abms/MantenerProveedoresBocaentrega";
import MantenerProveedoresLineascarga from "@pages/abms/MantenerProveedoresLineascarga";
import MantenerProvincias from "@pages/abms/MantenerProvincias";
import MantenerRangosSaneamiento from "@pages/abms/RangosSaneamiento";
import MantenerRecomendacionVin from "@pages/abms/MantenerRecomendacionVin";
import MantenerRegiones from "@pages/abms/MantenerRegiones";
import MantenerRelacionOperaciones from "@pages/abms/MantenerRelacionOperaciones";
import MantenerRelacionResponsables from "@pages/abms/MantenerRelacionResponsables";
import MantenerResponsables from "@pages/abms/MantenerResponsables";
import MantenerResponsablesCliente from "@pages/abms/MantenerResponsablesCliente";
import MantenerResultadoAnalisis from "@pages/abms/MantenerResultadoAnalisis";
import MantenerResultadoOperaciones from "@pages/abms/MantenerResultadoOperaciones";
import MantenerRubros from "@pages/abms/MantenerRubros";
import MantenerServiciosExtra from "@pages/abms/MantenerServiciosExtra";
import { MantenerServicos } from "@pages/functions/mantener-sevicos/MantenerServicos";
import MantenerSiluetas from "@pages/abms/MantenerSiluetas";
import MantenerSuperFamilias from "@pages/abms/MantenerSuperFamilias";
import MantenerTalleres from "@pages/abms/MantenerTalleres";
import MantenerTalleresCliente from "@pages/abms/MantenerTalleresCliente";
import MantenerTalleresUbicaciones from "@pages/abms/MantenerTalleresUbicaciones";
import MantenerTapizados from "@pages/abms/MantenerTapizados";
import MantenerTipoCompras from "@pages/abms/MantenerTipoCompras";
import MantenerTipoDanio from "@pages/abms/MantenerTipoDanios";
import MantenerTipoDespacho from "@pages/abms/MantenerTipoDespacho";
import MantenerTipoProvedores from "@pages/abms/MantenerTipoProvedores";
import MantenerTipoTransporte from "@pages/abms/MantenerTipoTransporte";
import MantenerTipoUbicacion from "@pages/abms/MantenerTipoUbicacion";
import MantenerTipoViajeContratado from "@pages/abms/MantenerTipoViajeContratado";
import MantenerTiposBloqueo from "@pages/abms/MantenerTiposBloqueo";
import MantenerTiposDesvios from "@pages/abms/MantenerTiposDesvios";
import MantenerTopeOperaciones from "@pages/abms/MantenerTopeOperaciones";
import MantenerTopeSolicitud from "@pages/abms/MantenerTopeSolicitud";
import MantenerTramos from "@pages/abms/MantenerTramos";
import MantenerTransacciones from "@pages/abms/MantenerTransacciones";
import MantenerTransportes from "@pages/abms/MantenerTransportes";
import MantenerUbicacionesFamilia from "@pages/abms/MantenerUbicacionesFamilia";
import MantenerUbicacionesLineasdeCarga from "@pages/abms/MantenerUbicacionesLineasdeCarga";
import MantenerUbicacionesOperaciones from "@pages/abms/MantenerUbicacionesOperaciones";
import MantenerUbicacionesRecepcion from "@pages/abms/MantenerUbicacionesRecepcion";
import MantenerUbicacionesTraslados from "@pages/abms/MantenerUbicacionesTraslados";
import MantenerUnidadesMedidaPlaya from "@pages/abms/MantenerUnidadesMedidaPlaya";
import MantenerUsuarios from "@pages/functions/mantener-usuarios/MantenerUsuarios";
import MantenerValesDeRepuestos from "@pages/functions/mantener-vales-de-repuestos/MantenerValesDeRepuestos";
import MantenerVersiones from "@pages/abms/MantenerVersiones";
import MantenerViajeFijoBocaEntrega from "@pages/abms/MantenerViajeFijoBocaEntrega";
import MantenerZonasPlaya from "@pages/abms/MantenerZonasPlaya";
import MentenerHojasRuta from "@pages/functions/mantener-hojas-ruta/mantener-hojas-ruta";
import { MigracionClientes } from "@pages/functions/migracion-clientes/MigracionClientes";
import { ModificacionMasivaMotor } from "@pages/functions/modificacion-masiva-motor/ModificacionMasivaMotor";
import ModificacionVin from "@pages/functions/modificacion-vin/ModificacionVin";
import { MonitorInterfaces } from "@pages/functions/monitor-interfaces/MonitorInterfaces";
import MovimentoStock from "@pages/queries/movimento-stock/MovimentoStock";
import NotFound from "./shared/not-found";
import Observacionespvn from "@pages/abms/Observacionespvn";
import OrdenReparacion from "@pages/functions/orden-reparacion/OrdenReparacion";
import OrdenReparacionProveedores from "@pages/functions/orden-reparacion/components/OrdenReparacionProveedores";
import OrdenReparacionRepuestos from "@pages/functions/orden-reparacion/components/OrdenReparacionRepuestos";
import { OrdenReparacionServicios } from "@pages/functions/orden-reparacion/components/OrdenReparacionServicios";
import ParamRangosEstadia from "@pages/abms/ParamRangosEstadia";
import ParametrosTiempoEntrega from "@pages/abms/ParametrosTiempoEntrega";
import PedidosConfirmadosJd from "@pages/queries/pedidos-confirmados-jd/PedidosConfirmadosJd";
import PedidosPlaya from "@pages/functions/pedidos-playa/PedidosPlaya";
import { Peritaje } from "@pages/functions/peritaje/Peritaje";
import ProtectedRoutes from "./protected-routes";
import RecepcionPlaya from "@pages/functions/recepcion-playa/RecepcionPlaya";
import { RecepcionTerceros } from "@pages/functions/recepcion-terceros/RecepcionTerceros";
import RegistracionOperacionAutomatica from "@pages/functions/registracion-operacion-automatica/RegistracionOperacionAutomatica";
import RegistracionOperacionPorOt from "@pages/functions/registracion-operacion-por-ot/RegistracionOperacionPorOt";
import ReglasRegistracionOperaciones from "@pages/abms/ReglasRegistracionOperaciones";
import ReimpresionEtiquetas from "@pages/functions/reimpresion-etiquetas/ReimpresionEtiquetas";
import ReimpresionRemitos from "@pages/functions/reimpresion-remitos/ReimpresionRemitos";
import ReingresoStock from "@pages/functions/reingreso-stock/ReingresoStock";
import ReingressoStock from "@pages/functions/reingreso-de-stock/ReingresoDeStock";
import RemitoElectronico from "@pages/functions/remito-electronico/RemitoElectronico";
import Remitos from "@pages/queries/remitos/Remitos";
import { RepararVines } from "@pages/functions/reparar-vines/RepararVines";
import SearchMenu from "@pages/SearchMenu";
import Stock from "@pages/queries/stock/stock";
import StockTaller from "@pages/queries/stock-taller/StockTaller";
import SubcuentasDatosCtrc from "@pages/abms/SubcuentasDatosCtrc";
import TestScreen from "@pages/test-screen";
import ThemeLayout from "@shared/theme-layout";
import TiemposCentroPerdidos from "@pages/queries/transit-time-pedidos-playa/TiemposCentroPerdidos";
import TiemposEntrega from "@pages/queries/tiempo-de-entrega/TiemposEntrega";
import TranferenciaPlaya from "@pages/functions/tranferencia-playa/TranferenciaPlaya";
import TransaccionesUsuarios from "@pages/queries/transacciones-usuario/TransaccionesUsuarios";
import TransfOrd from "@pages/queries/transf-ord/TransfOrd";
import TransferenciaUbicacion from "@pages/functions/transferencia-ubicacion/TransferenciaUbicacion";
import UbicacionCategoria from "@pages/abms/UbicacionCategoria";
import ValeRepuestos from "@pages/queries/vale-repuestos/ValeRepuestos";
import VehiculosPendienteTaller from "@pages/queries/vehiculos-pendiente-taller/VehiculosPendienteTaller";
import VehiculosTaller from "@pages/queries/vehiculos-en-taller/VehiculosTaller";
import RepuestosComprasTaller from "@pages/functions/compras-taller/components/RepuestosComprasTaller";
import MantenerPerfilesTranslado from "@pages/abms/MantenerPerfilesTranslado";
import MantenerClasificacionOrdenReparacion from "@pages/abms/MantenerClasificacionOrdenReparacion";
import AbmDemoScreen from "@pages/abm-demo-screen";
import ServiciosFacturar from "@pages/abms/ServiciosFacturar";
import MantenerClienteProveedor from "@pages/abms/mantener-cliente-proveedor/MantenerClienteProveedor";
import PrestacionesOperacion from "@pages/abms/PrestacionesOperacion";
import Preguntas from "@pages/abms/Preguntas";
import MantenerPlayas from "@pages/abms/mantener-playas/MantenerPlayas";
import MantenerProveedoresTipos from "@pages/abms/MantenerProveedoresTipos";
import OfertasServicioTerceros from "@pages/abms/ofertas-servicio-terceros/OfertasServicioTerceros";
import MantenerParametrizacionOperaciones from "@pages/abms/mantener-parametrizacion-operaciones/MantenerParametrizacionOperaciones";
import MantenerParametrosOperacionConcesionario from "@pages/abms/mantener-parametrizacion-operaciones/components/MantenerParametrosOperacionConcesionario";
import ReglasFacturacion from "@pages/abms/ReglasFacturacion";
import MantenerSolicitudesTransportes from "@pages/abms/mantener-solicitudes-transportes/MantenerSolicitudesTransportes";
import MantenerUbicacion from "@pages/abms/mantener-ubicacion/MantenerUbicacion";
import RegistrarOperacionesExigidasRf from "@pages/abms/RegistrarOperacionesExigidasRf";
import MantenerParametrosOperacionDanio from "@pages/abms/mantener-parametrizacion-operaciones/components/MantenerParametrosOperacionDanio";
import MantenerParametrosOperacionModelo from "@pages/abms/mantener-parametrizacion-operaciones/components/MantenerParametrosOperacionModelo";
import ExportacionPrestacionContable from "@pages/functions/exportacion-prestacion-contable/ExportacionPrestacionContable";
import ContableVerticalizacion from "@pages/functions/contable-verticalizacion/ContableVerticalizacion";

const NavProvider = () => {
  return (
    <Routes>
      <Route element={<ThemeLayout />}>
        <Route path="auth" element={<LoginLayout />}>
          <Route path="login" element={<Login />} />
        </Route>
        <Route path="/" element={<Login />} />
        <Route path="/" element={<ProtectedRoutes />}>
          <Route element={<MainLayout />}>
            <Route path="home" element={<Home />} />
            <Route path="filter-test" element={<FilterTest />} />
            <Route path="consulta-vin" element={<ConsultaVin />} />
            <Route path="stock" element={<Stock />} />
            <Route
              path="listado-de-hojas-de-rutas"
              element={<ListadoHojaRuta />}
            />
            <Route
              path="mantener-perfil-translado"
              element={<MantenerPerfilesTranslado />}
            />

            <Route path="abm-demo" element={<AbmDemoScreen />} />

            <Route
              path="mantener-clasificacion-orden-reparacion"
              element={<MantenerClasificacionOrdenReparacion />}
            />

            <Route
              path="mantener-servicios-facturar"
              element={<ServiciosFacturar />}
            />

            <Route
              path="mantener-proveedores-tipos"
              element={<MantenerProveedoresTipos />}
            />

            <Route
              path="mantener-motivos-baja"
              element={<MantenerMotivosBaja />}
            />
            <Route
              path="mantener-motivos-demora"
              element={<MantenerMotivosDemora />}
            />
            <Route
              path="mantener-servicios-extra"
              element={<MantenerServiciosExtra />}
            />
            <Route
              path="metodo-recomendacion-vin"
              element={<MantenerRecomendacionVin />}
            />
            <Route path="mantener-choferes" element={<MantenerChoferes />} />

            <Route
              path="exportacion-prestacion-contable"
              element={<ExportacionPrestacionContable />}
            />

            <Route
              path="mantener-cliente-proveedor"
              element={<MantenerClienteProveedor />}
            />
            <Route
              path="consulta-operaciones-pendientes"
              element={<ConsultaOperacionesPendientes />}
            />
            <Route path="ingreso-playa" element={<IngressoPlaya />} />
            <Route
              path="impression-hoja-ruta"
              element={<ImpressionHojaRuta />}
            />
            <Route path="remitos" element={<Remitos />} />
            <Route
              path="mantener-concesionarios-codigos-nostra"
              element={<ConcesionariosCodigosNostra />}
            />
            <Route
              path="mantener-grupo-operaciones"
              element={<MantenerGrupoOperaciones />}
            />
            <Route path="control-exw" element={<ControlExw />} />
            <Route
              path="mantener-danio-pendiente-taller"
              element={<DanioPendienteTaller />}
            />
            <Route
              path="mantener-partes-cuadrantes"
              element={<MantenerPartesCuadrantes />}
            />
            <Route
              path="mantener-viaje-fijo-boca-entrega"
              element={<MantenerViajeFijoBocaEntrega />}
            />
            <Route path="despachos" element={<Despachos />} />
            <Route path="consulta-ot" element={<ConsultaOt />} />
            <Route
              path="listado-operaciones-realizadas"
              element={<ListadoOperacionesRealizadas />}
            />
            <Route path="historico-danos" element={<HistoricoDanos />} />
            <Route path="listado-colectas" element={<ListadoColectas />} />
            <Route path="stock-taller" element={<StockTaller />} />
            <Route path="egreso-taller" element={<EgresoTaller />} />
            <Route
              path="impresion-manual-guias"
              element={<ImpresionManualGuias />}
            />
            <Route path="mantener-asuntos" element={<MantenerAsuntos />} />
            <Route
              path="mantener-estado-vehiculo"
              element={<MantenerEstadoVehiculo />}
            />
            <Route
              path="mantener-tipo-viaje-contratado"
              element={<MantenerTipoViajeContratado />}
            />
            <Route
              path="mantener-movimientos-facturar"
              element={<MantenerMovimientosFacturar />}
            />
            <Route
              path="mantener-matriz-tiempo"
              element={<MantenerMatrizTimpoe />}
            />
            <Route
              path="mantener-desvio-entrega"
              element={<MantenerDesvioEntrega />}
            />
            <Route
              path="mantener-cupo-taller"
              element={<MantenerCupoTaller />}
            />
            <Route path="mantener-cupo-ppo" element={<MantenerCupoPpo />} />
            <Route path="ciclos-operacion" element={<CiclosOperacion />} />
            <Route
              path="mantener-relacion-operaciones"
              element={<MantenerRelacionOperaciones />}
            />
            <Route
              path="mantener-ocurrencia"
              element={<MantenerOcurrencias />}
            />
            <Route
              path="mantener-tipos-desvios"
              element={<MantenerTiposDesvios />}
            />
            <Route path="checklist" element={<MantenerChecklist />} />
            <Route
              path="transacciones-usuarios"
              element={<TransaccionesUsuarios />}
            />
            <Route path="carga-masiva-danos" element={<CargaMasivaDanos />} />
            <Route
              path="listado-armado-viajes"
              element={<ListadoArmadoViajes />}
            />
            <Route path="movimento-stock" element={<MovimentoStock />} />
            <Route path="consulta-bloqueos" element={<ConsultaBloqueos />} />
            <Route
              path="liberados-despachados"
              element={<LiberadosDespachados />}
            />
            <Route path="transf-ord" element={<TransfOrd />} />
            <Route
              path="vehiculos-pendiente-taller"
              element={<VehiculosPendienteTaller />}
            />
            <Route path="historia-vin" element={<HistoriaVin />} />
            <Route path="compras-taller" element={<ComprasTaller />} />
            <Route path="vale-repuestos" element={<ValeRepuestos />} />
            <Route path="antiguedad-stock" element={<AntiguedadStock />} />
            <Route path="danios-checkpoint" element={<DaniosCheckpoint />} />
            <Route path="tiempos-entrega" element={<TiemposEntrega />} />
            <Route path="vehiculos-taller" element={<VehiculosTaller />} />
            <Route path="ingresso-taller" element={<ConIngresoTaller />} />
            <Route
              path="reimpresion-remitos"
              element={<ReimpresionRemitos />}
            />
            <Route path="anular-remitos" element={<AnularRemitos />} />
            <Route path="reparar-vines" element={<RepararVines />} />
            <Route
              path="contabilizacion-anulacion-de-operaciones"
              element={<ContabilizacionAnulacion />}
            />
            <Route path="ingreso-taller" element={<IngresoTaler />} />
            <Route path="recepcion-terceros" element={<RecepcionTerceros />} />
            <Route
              path="modificar-estado-del-vin"
              element={<CargaEstadoVin />}
            />
            <Route
              path="aprobacion-operaciones"
              element={<AprobacionOperaciones />}
            />
            <Route
              path="mantener-zonas-playa"
              element={<MantenerZonasPlaya />}
            />
            <Route
              path="mantener-cupo-transferencia"
              element={<MantenerCupoTransferencia />}
            />
            <Route
              path="mantener-cupo-despacho"
              element={<MantenerCupoDespacho />}
            />
            <Route path="mantener-rubros" element={<MantenerRubros />} />
            <Route
              path="mantener-grupos-taller"
              element={<MantenerGruposTaller />}
            />
            <Route
              path="mantener-modelos-taller"
              element={<MantenerModelosTaller />}
            />
            <Route
              path="mantener-contable-rutina-prestacion"
              element={<MantenerContableRutinaPrestacion />}
            />
            <Route
              path="mantener-tipo-compras"
              element={<MantenerTipoCompras />}
            />
            <Route path="migracion-clientes" element={<MigracionClientes />} />
            <Route
              path="informa-transportista"
              element={<InformaTransportista />}
            />
            <Route
              path="liberacion-despacho"
              element={<LiberacionDespachos />}
            />
            <Route
              path="documentacion-transportes"
              element={<DocumentacionTransportes />}
            />
            <Route path="mantener-cajas" element={<MantenerCajas />} />
            <Route path="mantener-generos" element={<MantenerGeneros />} />
            <Route
              path="general-transacciones"
              element={<ConsultaGeneralTransacciones />}
            />
            <Route path="vines-preparar" element={<ConsultaVinesPreparar />} />
            <Route path="translados" element={<ConsultaTranslado />} />
            <Route
              path="ofertas-servicio"
              element={<ConsultaOfertasServicio />}
            />
            <Route
              path="operaciones-canceladas-web"
              element={<ConsultaOperacionesCanceladasWeb />}
            />
            <Route
              path="transit-time-pedidos-playa"
              element={<TiemposCentroPerdidos />}
            />
            <Route
              path="mantener-ubicaciones-lineas-de-carga"
              element={<MantenerUbicacionesLineasdeCarga />}
            />

            <Route
              path="mantener-ubicaciones"
              element={<MantenerUbicacion />}
            />

            <Route
              path="mantener-solicitudes"
              element={<MantenerSolicitudesTransportes />}
            />

            <Route
              path="listado-apoyo-estadia"
              element={<ListadoApoyoEstadia />}
            />
            <Route
              path="consulta-os-terceros"
              element={<ConsultaOsTerceros />}
            />

            <Route
              path="ofertas-servicio-terceros"
              element={<OfertasServicioTerceros />}
            />

            <Route
              path="pedidos-confirmados-jd"
              element={<PedidosConfirmadosJd />}
            />
            <Route
              path="contabilizar-viajes-terceros"
              element={<ContabilizarViajesTerceros />}
            />
            <Route path="mantener-hojas-ruta" element={<MentenerHojasRuta />} />
            <Route
              path="administracion-remitos-a-concesionarios"
              element={<AdministracionRemitosAConcesionarios />}
            />
            <Route
              path="administracion-notas-fiscales"
              element={<AdministracionNotasFiscales />}
            />
            <Route
              path="despacho-mercado-nacional"
              element={<DespachoMercadoNacional />}
            />
            <Route path="carga-danos" element={<CargaDanos />} />
            <Route path="transferencia-playa" element={<TranferenciaPlaya />} />
            <Route path="recepcion-de-playa" element={<RecepcionPlaya />} />
            <Route path="generacion-ot" element={<GeneracionOt />} />
            <Route
              path="ingreso-taller-manual"
              element={<IngresoTallerManual />}
            />
            <Route path="orden-reparacion" element={<OrdenReparacion />} />
            <Route
              path="orden-reparacion-repuestos"
              element={<OrdenReparacionRepuestos />}
            />
            <Route
              path="orden-reparacion-servicios"
              element={<OrdenReparacionServicios />}
            />
            <Route
              path="detalle_de_vales_repuestos"
              element={<DetalleDeValesRepuestos />}
            />
            <Route
              path="orden-reparacion-proveedores"
              element={<OrdenReparacionProveedores />}
            />
            <Route
              path="mantener-contable-subcuentas"
              element={<MantenerContableSubcuentas />}
            />
            <Route
              path="mantener-catalogo-servicios"
              element={<MantenerCatalogoServicios />}
            />
            <Route
              path="mantener-catalogo-repuestos"
              element={<MantenerCatalogoRepuestos />}
            />
            <Route
              path="registracion-operacion-por-ot"
              element={<RegistracionOperacionPorOt />}
            />
            <Route
              path="registracion-operacion-automatica"
              element={<RegistracionOperacionAutomatica />}
            />
            <Route path="ingreso-ecom" element={<IngresoEcom />} />
            <Route path="carga-manual-vin" element={<CargaManualVin />} />
            <Route
              path="/carga-operaciones-forzadas"
              element={<CargaOperacionesForzadasBr />}
            />
            {/* <Route
              path="/carga-operaciones-forzadas"
              element={<CargaOperacionesForzadasAr />}
            /> */}
            <Route path="modificacion-vin" element={<ModificacionVin />} />
            <Route
              path="mantener-base-concepcion"
              element={<MantenerBaseConcepcion />}
            />
            <Route
              path="transferencia-ubicacion"
              element={<TransferenciaUbicacion />}
            />

            <Route
              path="contable-verticalizacion"
              element={<ContableVerticalizacion />}
            />

            <Route path="remito-electronico" element={<RemitoElectronico />} />
            <Route path="monitor-interfaces" element={<MonitorInterfaces />} />
            <Route
              path="ingreso-manual-de-vehiculos"
              element={<IngresoManualVehiculos />}
            />
            <Route
              path="disponibilidad-importacion"
              element={<DisponibilidadImportacion />}
            />
            <Route
              path="mantener-resultado-operaciones"
              element={<MantenerResultadoOperaciones />}
            />
            <Route
              path="mantener-contable-familias-radio"
              element={<MantenerContableFamiliasRadio />}
            />
            <Route
              path="devolucion-mercado-local"
              element={<DevolucionMercadoLocal />}
            />
            <Route path="anulacion-ecom" element={<AnulacionEcom />} />
            <Route path="peritaje" element={<Peritaje />} />
            <Route
              path="ingreso-ecom-danios-ford"
              element={<IngresoEcomDaniosFord />}
            />
            <Route path="devolucion-man" element={<DevolucionMan />} />
            <Route
              path="ingreso-mov-manuales"
              element={<IngresoMovManuales />}
            />
            <Route path="despacho-man" element={<DespachoMan />} />
            <Route
              path="interfaz-ingreso-ford"
              element={<InterfazIngresoFord />}
            />
            <Route
              path="bloqueos-desbloqueos"
              element={<BloqueosDesbloqueos />}
            />
            <Route path="liberacion-aduana" element={<LiberacionAduana />} />
            <Route path="pedidos-playa" element={<PedidosPlaya />} />
            <Route
              path="coeficiente-familia-transporte"
              element={<MantenerCoeficientesFamiliaTransporte />}
            />
            <Route
              path="administracion-remitos-ficticio"
              element={<AdministracionRemitosFicticio />}
            />
            <Route
              path="llegada-buque-camion"
              element={<LlegadaBuqueCamion />}
            />
            <Route
              path="despacho-mercado-exportacion"
              element={<DespachoMercadoExportacion />}
            />
            <Route path="carga-de-retornos" element={<CargaDeRetornos />} />
            <Route path="despacho-terceros" element={<DespachoTerceros />} />
            <Route path="colecta" element={<Colecta />} />
            <Route
              path="carga-manual-remitos"
              element={<CargaManualRemitos />}
            />
            <Route path="cierre-contable" element={<CierreContable />} />
            <Route
              path="mantener-ubicaciones-recepcion"
              element={<MantenerUbicacionesRecepcion />}
            />
            <Route
              path="mantener-motorizacion"
              element={<MantenerMotorizaciones />}
            />
            <Route
              path="mantener-parametros-cobro-prestacion"
              element={<MantenerParametrosCobroPrestacion />}
            />
            <Route
              path="mantener-contable-ciudades"
              element={<MantenerContableCiudades />}
            />
            <Route
              path="mantener-motorizacion"
              element={<MantenerMotorizaciones />}
            />
            <Route
              path="mantener-clientes-concepcion"
              element={<MantenerClienteConcepcion />}
            />
            <Route
              path="mantener-modificaciones"
              element={<MantenerModificaciones />}
            />
            <Route
              path="mantener-cabecera-opcionales"
              element={<MantenerCabeceraOpcionales />}
            />
            <Route
              path="modificacion-masiva-motor"
              element={<ModificacionMasivaMotor />}
            />
            <Route
              path="mantener-talleres-cliente"
              element={<MantenerTalleresCliente />}
            />
            <Route
              path="mantener-cuentas-taller"
              element={<MantenerCuentasTaller />}
            />
            <Route path="observaciones-pvn" element={<Observacionespvn />} />
            <Route path="preguntas" element={<Preguntas />} />
            <Route
              path="/mantener-talleres-ubicaciones"
              element={<MantenerTalleresUbicaciones />}
            />
            <Route
              path="/mantener-estadia-param-playas"
              element={<MantenerEstadiaParamPlayas />}
            />
            <Route
              path="eliminar-llegada-buque-camion"
              element={<EliminarBuqueCamion />}
            />
            <Route
              path="gestion-de-operaciones"
              element={<GestionOperaciones />}
            />
            <Route path="reingreso-de-stock" element={<ReingressoStock />} />

            <Route
              path="mantener-ubicaciones-operaciones"
              element={<MantenerUbicacionesOperaciones />}
            />
            <Route path="mantener-mecanicos" element={<MantenerMecanicos />} />
            <Route
              path="mantener-categorias"
              element={<MantenerCategorias />}
            />
            <Route
              path="mantener-anio-fabricacion"
              element={<MantenerAnioFabricacion />}
            />
            <Route path="mantener-versiones" element={<MantenerVersiones />} />
            <Route
              path="mantener-vales-de-repuestos"
              element={<MantenerValesDeRepuestos />}
            />
            <Route
              path="reimpresion-etiquetas"
              element={<ReimpresionEtiquetas />}
            />
            <Route path="cierre-estadia" element={<CierreEstadia />} />
            <Route
              path="impresion-etiquetas"
              element={<ImpresionEtiquetas />}
            />
            <Route
              path="impresion-checklist"
              element={<ImpresionChecklist />}
            />
            <Route
              path="gestion-preparacion"
              element={<GestionPreparacion />}
            />
            <Route path="gen-picking" element={<GenPicking />} />
            <Route path="fun-compras-taller" element={<FunComprasTaller />} />
            <Route
              path="repuestos-compras-taller"
              element={<RepuestosComprasTaller />}
            />
            <Route
              path="levantar-indisponibilidad"
              element={<LevantarIndisponibilidad />}
            />
            <Route path="mantener-servicos" element={<MantenerServicos />} />
            <Route
              path="mantener-ubicaciones-familia"
              element={<MantenerUbicacionesFamilia />}
            />
            <Route
              path="bloqueo-ubicaciones"
              element={<BloqueoUbicaciones />}
            />
            <Route path="mantener-usuarios" element={<MantenerUsuarios />} />
            <Route path="gastos-extra-playa" element={<GastosExtraPlaya />} />
            <Route path="mantener-clientes" element={<MantenerClientes />} />
            <Route
              path="manejar-errores-remitos"
              element={<ManejarErroresRemitos />}
            />
            <Route path="reingreso-stock" element={<ReingresoStock />} />
            <Route path="mantener-ciudades" element={<MantenerCiudades />} />
            <Route
              path="mantener-dias-entrega"
              element={<MantenerDiasEntrega />}
            />
            <Route path="mantener-paises" element={<MantenerPaises />} />
            <Route path="mantener-tipo-dano" element={<MantenerTipoDanio />} />
            <Route
              path="mantener-contable-puntos-de-retiro"
              element={<MantenerContablePuntosDeRetiro />}
            />
            <Route
              path="mantener-provincias"
              element={<MantenerProvincias />}
            />
            <Route
              path="mantener-localidades"
              element={<MantenerLocalidad />}
            />
            <Route
              path="mantener-partes-daniadas"
              element={<MantenerPartesDanios />}
            />
            <Route path="mantener-siluetas" element={<MantenerSiluetas />} />
            <Route path="mantener-acabados" element={<MantenerAcabados />} />
            <Route
              path="mantener-gravedaddanios"
              element={<MantenerGravedadDeDanio />}
            />
            <Route
              path="mantener-dist-playa-entregas"
              element={<MantenerDistPlayaEntregas />}
            />
            <Route
              path="mantener-concesionarios-tipo-despacho"
              element={<MantenerConcesTipoDespacho />}
            />
            <Route
              path="mantener-reglas-registracion-operaciones"
              element={<ReglasRegistracionOperaciones />}
            />
            <Route path="mantener-marcas" element={<MantenerMarcas />} />
            <Route path="mantener-origenes" element={<MantenerOrigen />} />
            <Route path="mantener-colores" element={<MantenerColores />} />
            <Route
              path="mantener-origenes-cliente"
              element={<MantenerOrigenesCliente />}
            />
            <Route
              path="mantener-responsables-cliente"
              element={<MantenerResponsablesCliente />}
            />
            <Route
              path="mantener-responsables"
              element={<MantenerResponsables />}
            />
            <Route
              path="/mantener-relacion-responsables"
              element={<MantenerRelacionResponsables />}
            />
            <Route path="/mantener-mercados" element={<MantenerMercados />} />
            <Route
              path="/mantener-mercados-cliente"
              element={<MantenerMercadosCliente />}
            />
            <Route path="/mantener-regiones" element={<MantenerRegiones />} />
            <Route path="/mantener-destinos" element={<MantenerDestinos />} />
            <Route
              path="/mantener-tipo-transporte"
              element={<MantenerTipoTransporte />}
            />
            <Route path="/mantener-talleres" element={<MantenerTalleres />} />
            <Route
              path="/mantener-super-familias"
              element={<MantenerSuperFamilias />}
            />
            <Route
              path="/mantener-tipo-despacho"
              element={<MantenerTipoDespacho />}
            />
            <Route
              path="/mantener-transacciones"
              element={<MantenerTransacciones />}
            />
            <Route path="/mantener-tramos" element={<MantenerTramos />} />
            <Route path="/mantener-familias" element={<MantenerFamilias />} />
            <Route
              path="/mantener-concesionarios"
              element={<MantenerConcesionarios />}
            />
            <Route
              path="/mantener-transportes"
              element={<MantenerTransportes />}
            />
            <Route
              path="/mantener-tipo-provedores"
              element={<MantenerTipoProvedores />}
            />
            <Route
              path="/mantener-tipo-ubicacion"
              element={<MantenerTipoUbicacion />}
            />
            <Route
              path="/mantener-proveedores"
              element={<MantenerProveedores />}
            />
            <Route
              path="/mantener-checkpoints"
              element={<MantenerCheckpoints />}
            />
            <Route
              path="/mantener-permisos-calidad"
              element={<MantenerPermisosCalidad />}
            />
            <Route
              path="/mantener-cuadrante"
              element={<MantenerCuadrantes />}
            />
            <Route
              path="/mantener-gruposdanios"
              element={<MantenerGruposDanios />}
            />
            <Route
              path="/mantener-motivo-baja-danio"
              element={<MantenerMotivoBajaDanio />}
            />
            <Route
              path="/mantener-unidades-medidas-playa"
              element={<MantenerUnidadesMedidaPlaya />}
            />
            <Route
              path="/mantener-inspectores-averia"
              element={<MantenerInspectoresAveria />}
            />
            <Route
              path="/rangos-saneamiento"
              element={<MantenerRangosSaneamiento />}
            />
            <Route
              path="/mantener-imputaciones"
              element={<MantenerImputaciones />}
            />
            <Route
              path="/mantener-cliente-imputaciones"
              element={<MantenerClienteImputaciones />}
            />
            <Route
              path="/mantener-imputaciones-responsables"
              element={<MantenerImputacionesResponsables />}
            />
            <Route
              path="/mantener-choferes-proveedores"
              element={<MantenerChoferesProveedores />}
            />
            <Route
              path="/mantener-concesionarios-tipo-transporte"
              element={<MantenerConcesionariosTipoTransporte />}
            />
            <Route
              path="/mantener-modelos-radios"
              element={<MantenerModelosRadios />}
            />
            <Route
              path="/parametros-tiempo-entrega"
              element={<ParametrosTiempoEntrega />}
            />
            <Route path="/mantener-tapizados" element={<MantenerTapizados />} />
            <Route
              path="/mantener-linea-opcionales"
              element={<MantenerLineasOpcionales />}
            />
            <Route
              path="/mantener-ubicaciones-traslados"
              element={<MantenerUbicacionesTraslados />}
            />
            <Route
              path="/mantener-playa-cliente-liberacion"
              element={<MantenerPlayaClienteLiberacion />}
            />
            <Route
              path="/mantener-checkpoint-transaccion"
              element={<MantenerCheckpointTransaccion />}
            />
            <Route
              path="/mantener-playa-cliente-guarda"
              element={<MantenerPlayaClienteGuarda />}
            />
            <Route
              path="/mantener-estados-remito"
              element={<MantenerEstadosRemito />}
            />
            <Route
              path="/mantener-leyendas-remito"
              element={<MantenerLeyendasRemito />}
            />
            <Route
              path="/mantener-documentos"
              element={<MantenerDocumentos />}
            />
            <Route
              path="/mantener-funciones-contables"
              element={<MantenerFuncionesContables />}
            />
            <Route
              path="/mantener-funcion-rutina"
              element={<MantenerFuncionRutina />}
            />
            <Route path="/mantener-feriados" element={<MantenerFeriados />} />
            <Route
              path="/mantener-contable-origenes-playas"
              element={<MantenerContableOrigenesPlayas />}
            />
            <Route
              path="/mantener-denominacion-comercial"
              element={<MantenerDenominacionComercial />}
            />
            <Route
              path="/mantener-calendario-desvio-entrega"
              element={<MantenerCalendarioDesvioEntrega />}
            />
            <Route
              path="/mantener-tope-operaciones"
              element={<MantenerTopeOperaciones />}
            />
            <Route
              path="/contable-sistema-origen"
              element={<ContableSistemaOrigens />}
            />
            <Route
              path="/param-rangos-estadia"
              element={<ParamRangosEstadia />}
            />
            <Route
              path="/mantener-ordenes-internas"
              element={<MantenerOrdenesInternas />}
            />
            <Route
              path="/mantener-param-cargos-talleres"
              element={<MantenerParamCargosTalleres />}
            />
            <Route
              path="/mantener-tipo-bloqueo"
              element={<MantenerTiposBloqueo />}
            />
            <Route
              path="/mantener-interfaz-transferencia"
              element={<MantenerInterfazTransferencia />}
            />
            <Route
              path="/mantener-impuestos-icms"
              element={<MantenerImpuestosIcms />}
            />
            <Route
              path="/ubicacion-categoria"
              element={<UbicacionCategoria />}
            />
            <Route
              path="/mantener-filtros-preparacion"
              element={<MantenerFiltrosPreparacion />}
            />
            <Route path="/mantener-playas" element={<MantenerPlayas />} />
            <Route
              path="/mantener-parametros-navieras"
              element={<MantenerParametrosNavieras />}
            />
            <Route
              path="/mantener-nota-pedido-flete"
              element={<MantenerNotaPedidoFlete />}
            />
            <Route
              path="/mantener-cuentas-gastos"
              element={<MantenerCuentasGastos />}
            />
            <Route
              path="/naturaleza-fiscal"
              element={<MantenerNaturalezasFiscales />}
            />
            <Route
              path="/mantener-resultado-analisis"
              element={<MantenerResultadoAnalisis />}
            />
            <Route
              path="/mantener-danios-unidades-medidas"
              element={<MantenerDaniosUnidadesMedidas />}
            />
            <Route
              path="/subcuentas-datos-ctrc"
              element={<SubcuentasDatosCtrc />}
            />
            <Route
              path="/mantener-parametros-cliente-sipco"
              element={<MantenerParametrosClienteSipco />}
            />
            <Route
              path="/mantener-centro-costos-gefco"
              element={<MantenerCentrosCostoGefco />}
            />
            <Route
              path="/mantener-centro-costos"
              element={<MantenerCentroCostos />}
            />
            <Route
              path="/mantener-tope-solicitud"
              element={<MantenerTopeSolicitud />}
            />
            <Route
              path="/mantener-marcas-destinos"
              element={<MantenerMarcaDestinos />}
            />
            <Route
              path="/mantener-corredores"
              element={<MantenerCorredores />}
            />
            <Route
              path="/mantener-prestaciones-operacion"
              element={<PrestacionesOperacion />}
            />
            <Route
              path="/mantener-corredores-prioridad"
              element={<MantenerCorredoresPrioridad />}
            />
            <Route
              path="/mantener-coeficientes-fletes"
              element={<MantenerCoeficientesFletes />}
            />
            <Route
              path="/mantener-proveedores-bocaentrega"
              element={<MantenerProveedoresBocaentrega />}
            />
            <Route
              path="/mantener-prov-lineas-carga"
              element={<MantenerProveedoresLineascarga />}
            />
            <Route
              path="/mantener-eventos-mails"
              element={<MantenerEventosMails />}
            />
            <Route path="/mantener-modelos" element={<MantenerModelos />} />
            <Route
              path="/mantener-operaciones-exigidas-rf"
              element={<RegistrarOperacionesExigidasRf />}
            />
            <Route
              path="/mantener-parametrizacion-operaciones"
              element={<MantenerParametrizacionOperaciones />}
            />
            <Route
              path="/mantener-parametros-operacion-concesionario"
              element={<MantenerParametrosOperacionConcesionario />}
            />
            <Route path="/reglas-facturacion" element={<ReglasFacturacion />} />
            <Route
              path="/mantener-parametros-operacion-danio"
              element={<MantenerParametrosOperacionDanio />}
            />
            <Route
              path="/mantener-parametros-operacion-modelo"
              element={<MantenerParametrosOperacionModelo />}
            />
          </Route>
        </Route>
      </Route>
      <Route path="test-screen" element={<TestScreen />} />
      <Route path="search-menu" element={<SearchMenu />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default NavProvider;
