import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { ChoferProveedor } from "src/models/ChoferProveedor";

const MantenerChoferesProveedores = () => {
  const SCREEN_CODE = "abm0233_mantener_choferes_proveedores";
  const URL_BASE = "/mantener-choferes-proveedores";
  const SCREEN_TITLE = (
    <LanguageProvider id="21309" alt="Mantener Choferes Proveedores" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: ChoferProveedor, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&        
        filterValues.securityValues.account
      ) {
        const choferProveedor = {
          ...model,
          pais: filterValues.securityValues.country,          
          cuenta: filterValues.securityValues.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          choferProveedor,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ChoferProveedor, filterValues: any) {
    try {
      const choferProveedor = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        choferProveedor,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: ChoferProveedor, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        choferProveedor: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ChoferProveedor[]> {
    if (filter?.securityValues?.country && filter.securityValues.account) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<ChoferProveedor> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          choferProveedor: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadProveedores(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/proveedor",
          {
            pais: filter.securityValues.country,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadChoferes(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/chofer",
          {
            pais: filter.securityValues.country,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ChoferProveedor>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            // client: true,
            account: true,
          },
        }}
        // filterSettings={}
        dataTableColumns={[
          {
            field: "proveedor.descripcion",
            header: <LanguageProvider id="2521" alt="x" />,
          },
          {
            field: "chofer.descripcion",
            header: <LanguageProvider id="4655" alt="x" />,
            body: (rowData: any) => (
              <span>
                {`${rowData.chofer.nombre} ${rowData.chofer.apellido}`}
              </span>
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          cuenta: null,
          chofer: null,
          proveedor: null,
        }}
        formSettings={[
          {
            field: "proveedor",
            label: LanguageProvider({ id: "2521", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "2521", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadProveedores(filter),
            dropDownTemplate: DropdownOptionTemplate,
            virtualizeDropdown:true,
            required: true,
          },
          {
            field: "chofer",
            label: LanguageProvider({ id: "4655", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "4655", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadChoferes(filter),
            dropDownTemplate: DropdownOptionTemplateChofer,
            virtualizeDropdown:true,
            required: true,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
            required: true,
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        // update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default MantenerChoferesProveedores;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionTemplateChofer = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {`${option.nombre} ${option.apellido}` } ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
