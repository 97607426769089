import "./CierreEstadia.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { LanguageProviderString } from "@shared/components/language-provider-string";
import useAuth from "@shared/AuthContext";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { ProgressBar } from "primereact/progressbar";

const INITIAL_ETIQUETA = {
  marca: "",
  modelo: "",
  color: "",
  lcdv: "",
  pais: "",
};

export function CierreEstadia() {
  const SCREEN_CODE = "fun0065_cierre_estadia";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const toast = useRef<Toast>(null);
  const { user } = useAuth();
  const [compraEspecial, setCompraEspecial] = useState(false);
  const [fechaDesde, setFechaDesde] = useState<any>({ date: new Date() });
  const [fechaHasta, setFechaHasta] = useState<any>({ date: new Date() });
  const [loading, setLoading] = useState(false);
  const [loadingProcesar, setLoadingProcesar] = useState(false);

  function checkSecurityFilters() {
    if (
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id &&
      securityFilters.securityValues.country?.id
    ) {
      return true;
    } else {
      return false;
    }
  }

  function showToast(id) {
    toast.current?.show({
      severity: "warn",
      detail: `${LanguageProviderString({ id: id.toString(), user })}`,
      life: 3000,
    });
  }

  async function filterChange() {
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        compraEspecial,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/cierre-estadias/find-fecha-cierre",
        params
      );
      if (status === 200) {
        fillFechaCierre(data.date);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function fillFechaCierre(date) {
    if (date) {
      setFechaDesde({ date: new Date(date) });
    } else {
      toast.current?.show({
        severity: "warn",
        detail: `${LanguageProviderString({ id: "1413", alt: "El Cliente seleccionado no paga Estadía en ninguna Playa", user })}`,
        life: 3000,
      });
    }
  }

  async function validateCierreEstadiaResult() {
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
        compraEspecial,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/cierre-estadias/validate-cierre",
        params
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function procesarCierreEstadia() {
    try {
      setLoadingProcesar(true);
      setLoading(false);
      const params = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
        compraEspecial,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/cierre-estadias/cierre-estadia",
        params
      );
      if (status === 200) {
        if (data) {
          toast.current?.show({
            severity: "success",
            detail: `${LanguageProviderString({ id: "36", alt: "Operación realizada con éxito", user })}`,
            life: 3000,
          });
          filterChange();
        }
      } else {
        toast.current?.show({
          severity: "error",
          detail: `${LanguageProviderString({ id: "11", alt: "Ha ocurrido un error, su ultima operación no fue realizada. Comuniquese con Sistemas", user })}`,
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingProcesar(false);
    }
  }

  function confirmProcess() {
    confirmDialog({
      header: <LanguageProvider id={"16239"} alt="Atención" />,
      message: (
        <LanguageProvider
          id={"13275"}
          alt="¿Desea procesar los cambios realizados?"
        />
      ),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => {
        setTimeout(() => {
          procesarCierreEstadia();
        }, 200);
      },

      reject: () => setLoading(false),
    });
  }

  async function valid() {
    setLoading(true);
    if (fechaHasta.date == null) {
      showToast(39);
      return;
    }
    if (fechaHasta.date >= new Date()) {
      showToast(6125);
      return;
    }

    if (fechaDesde.date > fechaHasta.date) {
      showToast(2110);
      return;
    }
    const result = await validateCierreEstadiaResult();

    if (Number(result) !== 0) {
      showToast(Number(result));
      return;
    }

    if (compraEspecial) {
      confirmDialog({
        header: LanguageProviderString({ id: "16239", alt: "Atención", user }),
        message: LanguageProviderString({
          id: "9654",
          alt: "CUIDADO - Esta por procesar una playa especial - ¿Desea Continuar?",
          user,
        }),
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "reject",
        accept: () => {
          setTimeout(() => {
            confirmProcess();
          }, 200);
        },
        reject: () => setLoading(false),
      });
    } else {
      confirmProcess();
    }
  }

  useEffect(() => {
    if (checkSecurityFilters()) {
      filterChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues, compraEspecial]);

  return (
    <div className="CierreEstadia">
      <Toast ref={toast} position="bottom-left" />
      <ConfirmDialog />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Fieldset legend={<LanguageProvider id={1385} alt="Cierre de Estadía" />}>
        <div className="form">
          <div className="form-row">
            <div
              className="sipco-option"
              style={{
                marginBottom: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <label style={{ marginRight: "5px" }}>
                <LanguageProvider
                  id={9653}
                  alt="Generar la Compra de Estadias Especiales"
                />
              </label>
              <Checkbox
                checked={compraEspecial}
                onChange={(e) => setCompraEspecial(e.checked)}
                style={{
                  justifyContent: "center",
                  position: "relative",
                  marginLeft: "10px",
                }}
              />
            </div>

            <div className="form">
              <div className="form-row" style={{ marginBottom: "10px" }}>
                <div className="sipco-option" style={{ marginBottom: "5px" }}>
                  <label>
                    <LanguageProvider
                      id={1412}
                      alt="Última Fecha de  Cierre de Estadía:"
                    />
                  </label>
                </div>
                <div className="sipco-option">
                  <SipcoCalendar
                    value={fechaDesde.date}
                    showButtonBar
                    showIcon
                    dateFormat="dd/mm/yy"
                    disabled
                  />
                </div>
              </div>

              <div className="sipco-option" style={{ marginBottom: "5px" }}>
                <label style={{ marginRight: "5px" }}>
                  <LanguageProvider id={1368} alt="Se cerrará la estadía al:" />
                </label>
              </div>
              <div className="sipco-option">
                <SipcoCalendar
                  value={fechaHasta.date}
                  onChange={(e) => setFechaHasta({ date: e.value })}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  style={{ marginRight: "5px" }}
                />
                <label>
                  <LanguageProvider id={12494} alt="(No Inclusive)" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div>
          {loadingProcesar ? (
            <ProgressBar
              mode="indeterminate"
              style={{ height: "6px" }}
            ></ProgressBar>
          ) : (
            <Button
              label={LanguageProvider({
                id: "16283",
                alt: "Procesar",
              })}
              onClick={() => valid()}
              loading={loading}
              text
            />
          )}
        </div>
      </Fieldset>
    </div>
  );
}
export default CierreEstadia;
