import "./IngressoTaller.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ConIngresoTaller() {
  const SCREEN_CODE = "con0043_ingreso_taller";
  const dt = useRef(null);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();

  const filterColumnsId = [
    5701, 3344, 1491, 2139, 319, 3445, 16492, 16493, 320, 3444, 3443, 3446,
    3447,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    taller: null,
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    estados: null,
  });

  const [loadingTaller, setLoadingTaller] = useState(false);
  const [taller, setTaller] = useState([]);
  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/consulta-ingreso-taller/taller",
          {
            codPais: securityFilters?.securityValues?.country?.id,
            codSubcuenta: securityFilters?.securityValues?.subaccount?.id,
            codCuenta: securityFilters?.securityValues?.account?.id,
          }
        );
        if (status === 200) {
          setTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
    return [];
  }

  const [loadingEstados, setLoadingEstados] = useState(false);
  const [estados, setEstados] = useState([]);

  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/consulta-ingreso-taller/estado",
          {
            codPais: securityFilters.securityValues.country.id || null,
          }
        );
        if (status === 200) {
          data.unshift({
            id: 0,
            descripcionFull: "TODOS",
            descripcion: "TODOS",
          });
          setEstados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
    return [];
  }

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);
  async function loadReport() {
    try {
      setLoadingReportData(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        taller: filter?.taller?.id || null,
        estado: filter.estados?.id || null,
        desde: filter.fechaDesde,
        hasta: filter.fechaHasta,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/consulta-ingreso-taller/ingreso",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  const data = convertDateObjects(reporte?.filas);

  useEffect(() => {
    const initialize = async () => {
      try {
        const talleres = await loadTaller();
        const estados = await loadEstados();

        setFilter({
          ...filter,
          estados: estados[0],
          taller: talleres[0],
        });
      } catch (e) {
        console.error(e);
      }
    };

    initialize();
  }, [securityFilters.securityValues]);

  return (
    <div className="ingresso-taller">
      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>Taller</label>
                <SipcoDropdown
                  loading={loadingTaller}
                  value={filter.taller}
                  options={taller}
                  onChange={(e) => setFilter({ ...filter, taller: e.value })}
                  optionLabel="descripcion"
                />
              </div>
              <div className={"sipco-option"}>
                <label>Desde</label>
                <SipcoCalendar
                  value={filter.fechaDesde}
                  onChange={(e) =>
                    setFilter({ ...filter, fechaDesde: e.value })
                  }
                  showIcon
                />
              </div>
              <div className={"sipco-option"}>
                <label>Hasta</label>
                <SipcoCalendar
                  value={filter.fechaHasta}
                  onChange={(e) =>
                    setFilter({ ...filter, fechaHasta: e.value })
                  }
                  showIcon
                />
              </div>
              <div className={"sipco-option"}>
                <label>Estados</label>
                <SipcoDropdown
                  loading={loadingEstados}
                  value={filter.estados}
                  options={estados}
                  onChange={(e) => setFilter({ ...filter, estados: e.value })}
                  optionLabel="descripcion"
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {<LanguageProvider id="3440" alt="Fechas Ingreso Taller" />}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Fechas Ingreso Taller"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          loading={loadingReportData}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn
            field="0"
            header={<LanguageProvider id="5701" alt="Nro. Orden" />}
            sortable
            filter
          />
          <SipcoColumn
            field="1"
            header={<LanguageProvider id="3344" alt="Estado" />}
            sortable
            filter
          />
          <SipcoColumn
            field="2"
            header={<LanguageProvider id="1491" alt="VIN" />}
            sortable
            filter
          />
          <SipcoColumn
            field="3"
            header={<LanguageProvider id="2139" alt="Modelo" />}
            sortable
            filter
          />
          <SipcoColumn
            field="4"
            header={<LanguageProvider id="319" alt="Ingreso Taller" />}
            sortable
            filter
          />
          <SipcoColumn
            field="5"
            header={<LanguageProvider id="3445" alt="Inicio Orden" />}
            sortable
            filter
          />
          <SipcoColumn
            field="6"
            header={<LanguageProvider id="16492" alt="Fecha cierre estimada" />}
            sortable
            filter
          />
          <SipcoColumn
            field="7"
            header={<LanguageProvider id="16493" alt="Fecha de cierre real" />}
            sortable
            filter
          />
          <SipcoColumn
            field="8"
            header={<LanguageProvider id="320" alt="Egreso Taller" />}
            sortable
            filter
          />
          <SipcoColumn
            field="9"
            header={<LanguageProvider id="3444" alt="Demora(Dias)" />}
            sortable
            filter
          />
          <SipcoColumn
            field="10"
            header={<LanguageProvider id="3443" alt="Demora(Horas)" />}
            sortable
            filter
          />
          <SipcoColumn
            field="11"
            header={
              <LanguageProvider id="3446" alt="Estadia en Taller(Dias)" />
            }
            sortable
            filter
          />
          <SipcoColumn
            field="12"
            header={
              <LanguageProvider id="3447" alt="Estadia en Taller(Horas)" />
            }
            sortable
            filter
          />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default ConIngresoTaller;
