import "./MantenerSolicitudesTransportes.scss";
import "primeicons/primeicons.css";

import React, { useEffect, useRef, useState } from "react";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Panel } from "primereact/panel";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoColumn from "@shared/components/sipco-column";
import { format } from "date-fns";
import { HttpStatusCode } from "axios";

export function MantenerSolicitudesTransportes() {
  const SCREEN_CODE = "abm0152_mantener_solicitudes";
  const sipcoAxiosService = useSipcoAxiosService();
  const dt = useRef(null);
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  
  const [selectedRowsGrilla, setSelectedGrilla] = useState([]);
  const [solicitudesTransportes, setSolicitudesTransportes] = useState([]);
  const [loading, setLoading] = useState(false);

  const [model, setModel] = useState({
    tipoVehiculo: null,
    fechaSolicitud: null,
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    sistema: "",
    cliente: null,
    cuenta: null,
    solicitante: null,
    tipoViaje: null,
    observaciones: "",
    descCarga: "",
    lugarCarga: "",
    horaCarga: "",
    tipoContenedor1: null,
    numeroContenedor1: "",
    pesoContenedor1: null,
    tipoContenedor2: null,
    numeroContenedor2: "",
    pesoContenedor2: null,
    numeroReferencia: "",
    tipoDistancia: null,
    fechaReal: null,
    cuentaDestino: null,
    reserva: null,


    estado: null,
  });

  const [tipoVehiculo, setTipoVehiculo] = useState([]);     
  const [loadingTipoVehiculo, setLoadingTipoVehiculo] = useState(false);
  async function loadTipoVehiculo() {
    setLoadingTipoVehiculo(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-solicitudes/filter/tipo-vehiculo",
          {
            pais: securityFilters?.securityValues?.country,
          }
        );

        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setTipoVehiculo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoVehiculo(false);
    }
  }

  const [tipoEstado, setTipoEstado] = useState([]);
  const [loadingTipoEstado, setLoadingTipoEstado] = useState(false);
  async function loadTipoEstado() {
    setLoadingTipoEstado(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-solicitudes/filter/estado-solicitud",
          {
            pais: securityFilters?.securityValues?.country,
          }
        );

        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setTipoEstado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoEstado(false);
    }
  }

  const [solicitudCuenta, setSolicitudCuenta] = useState([]);
  const [loadingSolicitudCuenta, setLoadingSolicitudCuenta] = useState(false);
  async function loadSolicitudCuenta() {
    setLoadingSolicitudCuenta(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-solicitudes/filter/cuenta",
        {}
      );
      if (status === HttpStatusCode.Ok) {
        setSolicitudCuenta(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSolicitudCuenta(false);
    }
  }

  async function load() {
    setLoadingSolicitudCuenta(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-solicitudes/findBy",
        {
          pais: securityFilters?.securityValues?.country,
          cliente: securityFilters?.securityValues?.client,
          cuenta: securityFilters?.securityValues?.account,
          tipoVehiculo: model.tipoVehiculo,
          estado: model.estado,
          cuentaDestino: model.cuentaDestino,
          fechaDesde: new Date(model.fechaDesde).getTime(),
          fechaHasta: new Date(model.fechaHasta).getTime()
        }
      );
      if (status === HttpStatusCode.Ok) {
        setSolicitudCuenta(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSolicitudCuenta(false);
    }
  }

  const grillaCabecera = [
    { field: "id", header: "Nro. Oferta Servicio" },
    { field: "cliente.descripcion", header: "Cliente" },
    { field: "fechaCotizacion.date", header: "Fecha Cotizacion" },
    { field: "fechaDeseada.date", header: "Fecha Deseada" },
    { field: "contacto", header: "Contacto" },
    { field: "mailContacto", header: "Email Contacto" },
    { field: "observacion", header: "Observacion" },
    { field: "referenciaCliente", header: "Referencia Cliente" },
    { field: "fechaAceptacion.date", header: "Fecha Aceptacion" },
    { field: "estado.descripcion", header: "Estado" },
    { field: "fechaUltimaModificacion.date", header: "Fecha Ultima Modificacion" },
    { field: "usuarioUltimaModificacion.id", header: "Usuario Ultima Modificacion" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  useEffect(() => {
    try {
      const init = () => {
        const tipoVehiculo = loadTipoVehiculo();
        const tipoEstado = loadTipoEstado();
        const solicitudCuenta = loadSolicitudCuenta();

        setModel({
          ...model,
          tipoVehiculo,
          estado: tipoEstado,
          cuentaDestino: solicitudCuenta
        });
      };

      init();
    } catch (error) {
      console.error(error);
    }
  }, [securityFilters.securityValues]);


  return (
    <div className="carga-manual-vin">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={load}
        securityOptions={{
          account: true,
          client: true,         
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab       
          header={
            <LanguageProvider
              id={"6697"}
              alt="Listado"
            />
          }         
        >
          <div className="form">
            <div className="form-row">                    
              <div className={"sipco-option"}>                        
                <label>
                  <LanguageProvider id={"11828"} alt="Tipo Vehículo" />
                </label>
                <SipcoDropdown
                  loading={loadingTipoVehiculo}
                  options={tipoVehiculo}
                  optionLabel="descripcion"           
                  value={model?.tipoVehiculo}
                  onChange={(e) => setModel({ ...model, tipoVehiculo: e.value })}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"20804"} alt="Estado" />
                </label>
                <SipcoDropdown
                  loading={loadingTipoEstado}
                  options={tipoEstado}
                  optionLabel="descripcion"
                  value={model?.estado}
                  onChange={(e) => setModel({ ...model, estado: e.value })}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"4391"} alt="Cuenta Destino" />
                </label>
                <SipcoDropdown
                  loading={loadingSolicitudCuenta}
                  options={solicitudCuenta}
                  optionLabel="descripcion"
                  value={model?.cuentaDestino}
                  onChange={(e) => setModel({ ...model, cuentaDestino: e.value })}
                />
              </div>
            </div>

            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16921"} alt="Desde" />
                </label>
                <Calendar
                  showIcon
                  value={model?.fechaDesde}
                  onChange={(e) => setModel({ ...model, fechaDesde: e.value })}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2622"} alt="Hasta" />
                </label>
                <Calendar
                  showIcon
                  value={model?.fechaHasta}
                  onChange={(e) => setModel({ ...model, fechaHasta: e.value })}
                />
              </div>
            </div>

          </div>

        </AccordionTab>

      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="22778" alt="Ofertas Servicio Terceros" />
              </span>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={solicitudesTransportes}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={8}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRowsGrilla}
          onSelectionChange={(e: any) => setSelectedGrilla(e.value)}
          metaKeySelection={true}
          dragSelection
        >
          {grillaCabecera.map((colum, index) => {
            if (colum.header === "Fecha Cotizacion"
              || colum.header === "Fecha Ultima Modificacion") {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </SipcoDataTable>

      </Panel>



    </div>
  );
}
export default MantenerSolicitudesTransportes;

const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
