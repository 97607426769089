import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputNumber from "@shared/components/sipco-input-number";
import SipcoInputText from "@shared/components/sipco-input-text";
import { useGenericAbm } from "@shared/GenericAbmContext";
import { Checkbox } from "primereact/checkbox";
import { Dialog, DialogProps } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import { Modelo } from "../../../../models/Modelo";

type ModalMantenerModelosProps = DialogProps & {
  model: Modelo;
};

const ModalMantenerModelos: React.FC<ModalMantenerModelosProps> = ({
  ...props
}) => {
  const URL_BASE = "/mantener-modelos";
  const sipcoAxiosService = useSipcoAxiosService();
  const { genericAbmStatesContextData } = useGenericAbm<any>();

  const [formData, setFormData] = useState<Modelo>(new Modelo());

  /** Dropdowns FORM */
  const [versiones, setVersiones] = useState([]);
  const [loadingVersiones, setLoadingVersiones] = useState(false);
  async function loadVersiones(pais: any, cliente: any, marca: any) {
    setLoadingVersiones(true);
    try {
      if (pais && cliente && marca) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/version",
          {
            pais: pais,
            cliente: cliente,
            marca: marca,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVersiones(false);
    }
    return [];
  }
  const [categorias, setCategorias] = useState([]);
  const [loadingCategorias, setLoadingCategorias] = useState(false);
  async function loadCategorias(pais: any, cliente: any) {
    setLoadingCategorias(true);
    try {
      if (pais && cliente) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/categoria",
          {
            pais: pais,
            cliente: cliente,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCategorias(false);
    }
    return [];
  }
  const [familias, setFamilias] = useState([]);
  const [loadingFamilias, setLoadingFamilias] = useState(false);
  async function loadFamilias(pais: any, cliente: any, marca: any) {
    setLoadingFamilias(true);
    try {
      if (pais && cliente && marca) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/familia",
          {
            pais: pais,
            cliente: cliente,
            marca: marca,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFamilias(false);
    }
    return [];
  }
  const [familiasDeRadios, setFamiliasDeRadios] = useState([]);
  const [loadingFamiliasRadio, setLoadingFamiliasRadio] = useState(false);
  async function loadFamiliasDeRadios(pais: any, cliente: any) {
    setLoadingFamiliasRadio(true);
    try {
      if (pais && cliente) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/familia-radio",
          {
            pais: pais,
            cliente: cliente,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFamiliasRadio(false);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      try {
        if (props.visible && genericAbmStatesContextData) {
          const { marca } = genericAbmStatesContextData.filter;
          const { country, client } =
            genericAbmStatesContextData.filter.securityValues;

          const [versiones, familias, categorias, familiasDeRadio] =
            await Promise.all([
              loadVersiones(country, client, marca),
              loadFamilias(country, client, marca),
              loadCategorias(country, client),
              loadFamiliasDeRadios(country, client),
            ]);
          setVersiones(versiones);
          setFamilias(familias);
          setCategorias(categorias);
          setFamiliasDeRadios(familiasDeRadio);

          if (props.model.id === "") {
            setFormData({
              ...formData,
              version: versiones[0],
              familia: familias[0],
              familiaRadio: familiasDeRadio[0],
              categoriaEstado: categorias[0],
              categoriaTranslado: categorias[0],
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [genericAbmStatesContextData, props.visible, props.model]);

  useEffect(() => {
    setFormData(props.model);
  }, [props.model]);

  return (
    <Dialog {...props} className="mantener-modelos-dialog">
      <TabView>
        <TabPanel header="Solapa 1">
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13514"} alt="Código Modelo" />*
                </label>
                <SipcoInputText
                  value={formData.id}
                  onChange={(e) => {
                    setFormData({ ...formData, id: e.target.value });
                  }}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"23864"} alt="Modelo" />*
                </label>
                <SipcoInputText
                  value={formData.descripcion}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      descripcion: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10034"} alt="Familia" />*
                </label>
                <SipcoDropdown
                  value={formData.familia}
                  options={familias}
                  loading={loadingFamilias}
                  onChange={(e) => {
                    setFormData({ ...formData, familia: e.value });
                  }}
                  optionLabel="descripcion"
                  placeholder={LanguageProvider({
                    id: "10034",
                    alt: "Familia",
                  })}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16437"} alt="Familias de Radios" />*
                </label>
                <SipcoDropdown
                  value={formData.familiaRadio}
                  options={familiasDeRadios}
                  loading={loadingFamiliasRadio}
                  onChange={(e) => {
                    setFormData({ ...formData, familiaRadio: e.value });
                  }}
                  optionLabel="descripcion"
                  placeholder={LanguageProvider({
                    id: "16437",
                    alt: "Familias de Radios",
                  })}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"4169"} alt="Versión" />*
                </label>
                <SipcoDropdown
                  value={formData.version}
                  options={versiones}
                  loading={loadingVersiones}
                  onChange={(e) => {
                    setFormData({ ...formData, version: e.value });
                  }}
                  optionLabel="descripcion"
                  placeholder={LanguageProvider({
                    id: "4169",
                    alt: "Versión",
                  })}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11497"} alt="Modelo Francia" />*
                </label>
                <SipcoInputText
                  value={formData.idModeloFra}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      idModeloFra: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={"16438"}
                    alt="Contable Prest. SEGINT_02"
                  />
                  *
                </label>
                <SipcoInputNumber
                  value={formData.codigoModeloContable}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      codigoModeloContable: e.value,
                    });
                  }}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16439"} alt="Peso en (Kgr.)" />
                </label>
                <SipcoInputNumber
                  value={formData.peso}
                  onChange={(e) => {
                    setFormData({ ...formData, peso: e.value });
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16440"} alt="Categoría Estadia" />*
                </label>
                <SipcoDropdown
                  value={formData.categoriaEstado}
                  options={categorias}
                  loading={loadingCategorias}
                  onChange={(e) => {
                    setFormData({ ...formData, categoriaEstado: e.value });
                  }}
                  optionLabel="descripcion"
                  placeholder={LanguageProvider({
                    id: "16440",
                    alt: "Categoría Estadia",
                  })}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"15538"} alt="Categoría Traslado" />*
                </label>
                <SipcoDropdown
                  value={formData.categoriaTranslado}
                  options={categorias}
                  loading={loadingCategorias}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      categoriaTranslado: e.value,
                    });
                  }}
                  optionLabel="descripcion"
                  placeholder={LanguageProvider({
                    id: "15538",
                    alt: "Categoría Traslado",
                  })}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"4657"} alt="Porcentaje (%)" />
                </label>
                <SipcoInputNumber
                  value={formData.porcentaje}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      porcentaje: e.value,
                    });
                  }}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11086"} alt="Año Modelo" />
                </label>
                <SipcoInputNumber
                  value={formData.anioModelo}
                  onChange={(e) => {
                    setFormData({ ...formData, anioModelo: e.value });
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"19447"} alt="Valor" />
                </label>
                <SipcoInputNumber
                  value={formData.valor}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      valor: e.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"23261"} alt="Horas Preparación" />
                </label>
                <SipcoInputNumber
                  value={formData.horasPreparacion}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      horasPreparacion: e.value,
                    });
                  }}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"24849"} alt="Source Unit" />
                </label>
                <SipcoInputText
                  value={formData.sourceUnit}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      sourceUnit: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3505"} alt="NCM" />
                </label>
                <SipcoInputText
                  value={formData.ncm}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      ncm: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-checkbox-container">
                <div className="check-box">
                  <label>
                    <LanguageProvider id="16360" alt="Cobra logo" />
                  </label>
                  <Checkbox
                    value={formData.cobraLogo}
                    checked={formData.cobraLogo}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        cobraLogo: !formData.cobraLogo,
                      });
                    }}
                  />
                </div>
                <div className="check-box">
                  <label>
                    <LanguageProvider id="16358" alt="Cobra gancho" />
                  </label>
                  <Checkbox
                    value={formData.cobraGancho}
                    checked={formData.cobraGancho}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        cobraGancho: !formData.cobraGancho,
                      });
                    }}
                  />
                </div>
                <div className="check-box">
                  <label>
                    <LanguageProvider id="16361" alt="Cobra sticker" />
                  </label>
                  <Checkbox
                    value={formData.cobraSticker}
                    checked={formData.cobraSticker}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        cobraSticker: !formData.cobraSticker,
                      });
                    }}
                  />
                </div>
                <div className="check-box">
                  <label>
                    <LanguageProvider id="22210" alt="Combo" />
                  </label>
                  <Checkbox
                    value={formData.combo}
                    checked={formData.combo}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        combo: !formData.combo,
                      });
                    }}
                  />
                </div>
                <div className="check-box">
                  <label>
                    <LanguageProvider id="23849" alt="Activo" />
                  </label>
                  <Checkbox
                    value={formData.activo}
                    checked={formData.activo}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        activo: !formData.activo,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Solapa 2"></TabPanel>
      </TabView>
    </Dialog>
  );
};

export default ModalMantenerModelos;
