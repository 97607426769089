import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

import { ClasificacionOR } from "src/models/ClasificacionOR";

const MantenerClasificacionOrdenReparacion = () => {
  
  const SCREEN_CODE = "abm0606_mantener_clasificacion_orden_reparacion";
  const URL_BASE = "/mantener-clasificacion-orden-reparacion";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ClasificacionOR, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        console.log(filterValues)
        const clasificacionOR = {
          ...model,
          pais: filterValues.securityValues.country,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
          taller: filterValues.taller,
          descripcion: model.descripcion,
          active: filterValues.active,
          codClasificacion: model.codClasificacion,
          descripcionCorta: model.descripcionCorta,
          limiteServicio: model.limiteServicio,
          limiteRepuesto: model.limiteRepuesto,
          autorizacion: model.autorizacion,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          clasificacionOR,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: ClasificacionOR, filterValues: any) {
    try {
      const clasificacionOR = {
        ...model,
        pais: filterValues.securityValues.country,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
        taller: filterValues.taller,
        descripcion: model.descripcion,
        active: filterValues.active,
        codClasificacion: model.codClasificacion,
        descripcionCorta: model.descripcionCorta,
        limiteServicio: model.limiteServicio,
        limiteRepuesto: model.limiteRepuesto,
        autorizacion: model.autorizacion,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        clasificacionOR,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: ClasificacionOR, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        clasificacionOR: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<ClasificacionOR[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cuenta: filter.securityValues.account,
          subcuenta: filter.securityValues.subaccount,
          taller: filter.taller,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<ClasificacionOR> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          clasificacionOR: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadTaller(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/taller",
          {
            pais: filter?.securityValues?.country,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<ClasificacionOR>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          account: true,
          subaccount: true,
        },
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Código" /> },
        { field: "descripcion", header: <LanguageProvider id="12419" alt="x" /> },
        { field: "limiteServicio", header: <LanguageProvider id="2249" alt="x" /> },
        { field: "limiteRepuesto", header: <LanguageProvider id="2248" alt="x" /> },
        {
          field: "autorizacion",
          header: <LanguageProvider id="20072" alt="x" />,
          body: (rowData: any) => (rowData.autorizacion ? "SI" : "NO"),
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.timestamp).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
        },
      ]
      }
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
        descripcion: '',
        autorizacion: false,
        codClasificacion: 0,
        cuenta: null,
        descripcionCorta: '',
        limiteRepuesto: 0,
        limiteServicio: 0,
        subcuenta: null,
        taller: null
      }}
      filterSettings={[
        {
          filter: "taller",
          label: LanguageProvider({ id: "18487", alt: "Taller" }),
          placeholder: LanguageProvider({ id: "18487", alt: "Taller" }),
          type: "dropdown",
          dataSource: loadTaller,
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country", "securityValues.account", "securityValues.subaccount"],
          required: true,
        },
      ]}
      formSettings={[
        {
          "field": "id",
          "label": LanguageProvider({ id: "6590", alt: "Código" }),
          "type": "inputText",
          "length": 1,
          "disabled": true
        },
        {
          "field": "descripcion",
          "label": LanguageProvider({ id: "12419", alt: "x" }),
          "type": "inputText",
          "length": 50
        },
        {
          "field": "limiteServicio",
          "label": LanguageProvider({ id: "20730", alt: "x" }),
          "type": "inputText",
          "length": 10
        },
        {
          "field": "limiteRepuesto",
          "label": LanguageProvider({ id: "20730", alt: "x" }),
          "type": "inputText",
          "length": 10
        },
        {
          "field": "autorizacion",
          "label": LanguageProvider({ id: "20072", alt: "x" }),
          "type": "checkbox"
        },
        {
          "field": "activo",
          "label": LanguageProvider({ id: "72", alt: "Activo" }),
          "type": "checkbox"
        }
      ]
      }
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerClasificacionOrdenReparacion;


const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};