import "./MantenerUbicacion.scss";
import "primeicons/primeicons.css";

import React, { useEffect, useRef, useState } from "react";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoColumn from "@shared/components/sipco-column";
import { format } from "date-fns";
import { HttpStatusCode } from "axios";

export function MantenerUbicacion() {
  const SCREEN_CODE = "abm0037_mantener_ubicacion";
  const sipcoAxiosService = useSipcoAxiosService();
  const dt = useRef(null);
  const { values: securityFilters } = useFilterContext();       
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();

  const [selectedRowsGrilla, setSelectedGrilla] = useState([]);
  const [solicitudesTransportes, setSolicitudesTransportes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState([]);

  const [model, setModel] = useState({
    nivel: null,
    columna: null,
    calle: null,
    tipoUbicacion: null,
  });

  const [columna, setColumna] = useState([]);
  const [loadingColumna, setLoadingColumna] = useState(false);

  async function loadColumna(calle: any) {
    setLoadingColumna(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-ubicaciones/filter/columna",
          {
            pais: securityFilters?.securityValues?.country,
            cuenta: securityFilters?.securityValues?.account,
            subcuenta: securityFilters?.securityValues?.subaccount,
            calle: calle,
          }
        );

        if (status === HttpStatusCode.Ok) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
  };

  const [nivel, setNivel] = useState([]);
  const [loadingNivel, setLoadingNivel] = useState(false);
  async function loadNivel(calle : any, columna : any) {
    setLoadingNivel(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-ubicaciones/filter/nivel",
          {
            pais: securityFilters?.securityValues?.country,
            cuenta: securityFilters?.securityValues?.account,
            subcuenta: securityFilters?.securityValues?.subaccount,
            calle: calle,
            columna: columna
          }
        );

        if (status === HttpStatusCode.Ok) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
  };

  const [calle, setCalle] = useState([]);
  const [loadingCalle, setLoadingCalle] = useState(false);
  async function loadCalle(tipoUbicacion: any) {
    setLoadingCalle(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-ubicaciones/filter/calle",
        {
          pais: securityFilters?.securityValues?.country,
          cuenta: securityFilters?.securityValues?.account,
          subcuenta: securityFilters?.securityValues?.subaccount,
          tipoUbicacion: tipoUbicacion,
        }
      );
      if (status === HttpStatusCode.Ok) {
        setCalle(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalle(false);
    }
  };

  const [tipoUbicacion, setTipoUbicacion] = useState([]);
  const [loadingTipoUbicacion, setLoadingTipoUbicacion] = useState(false);
  async function loadTipoUbicacion() {
    setLoadingTipoUbicacion(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-ubicaciones/filter/tipo-ubicacion",
        {}
      );
      if (status === HttpStatusCode.Ok) {
        data.unshift({ id: 0, descripcion: "TODOS" });
        setTipoUbicacion(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoUbicacion(false);
    }
  };

  async function handleChangeTipoUbicacion(value: any) {
    try {

      const calle = await loadCalle(value);

      setModel({ ...model, calle, tipoUbicacion: value });
    } catch (error) {
      console.error(error);
    }
  }

  async function handleChangeCalle(value: any) {
    try {
      const columna = await loadColumna(value);

      setModel({ ...model, columna, calle: value });
    } catch (error) {
      console.error(error);
    }
  }

  async function handleChangeColumna(value: any) {
    try {
      const nivel = await loadNivel(model.calle, value);

      setModel({ ...model, columna: value, nivel });
    } catch (error) {
      console.error(error);
    }
  }


  async function load() {
    setLoading(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-ubicaciones/findCrudBy",
        {
          pais: securityFilters?.securityValues?.country,
          cuenta: securityFilters?.securityValues?.account,
          subcuenta: securityFilters?.securityValues?.subaccount,
          tipoUbicacion: model.tipoUbicacion,
          calle: model.calle,
          columna: model.columna,
          nivel: model.nivel
        }
      );
      if (status === HttpStatusCode.Ok) {
        setReport(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const grillaCabecera = [
    { field: "calle", header: "Calle" },
    { field: "columna", header: "Columna" },
    { field: "nivel", header: "Nivel" },
    { field: "tipoUbicacion.descripcion", header: "Tipo Ubicacion" }, 
    { field: "tipoUbicacion.permiteTransferirUbicacion", header: "Transferir" },
    { field: "tipoUbicacion.techada", header: "Techada" },
    { field: "descripcionCalle", header: "Descripcion" },
    { field: "cantidadPermitida", header: "Cantidad Permitida" },
    { field: "cantidadActual", header: "Cantidad Actual" },
    { field: "cantidadMinima", header: "Cantidad Mínima" },
    { field: "cobraEntrada", header: "Cobra Entrada" },
    { field: "cobraSalida", header: "Cobra Salida" },
    { field: "controlaOperacion", header: "Controla Operación" },
    { field: "calleCliente", header: "Calle Cliente" },
    { field: "columnaCliente", header: "Columna Cliente" },
    { field: "usuarioUltimaModificacion.id", header: "Usuario Última Modificación" },
    { field: "fechaUltimaModificacion.date", header: "Fecha Última Modificación" },
    { field: "activo", header: "Activo" },
];


  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  useEffect(() => {
    try {
      const init = () => {
        const tipoUbicacion = loadTipoUbicacion();

        setModel({
          ...model,
          tipoUbicacion
        })
      };

      init();
    } catch (error) {
      console.error(error);
    }
  }, [securityFilters.securityValues]);


  return (
    <div className="mantener-ubicacion">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={load}
        securityOptions={{
          account: true,
          subaccount: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={
            <LanguageProvider
              id={"7795"}
              alt="Filtrar Por:"
            />
          }
        >
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"5256"} alt="Tipo Ubicación" />
                </label>
                <SipcoDropdown
                  loading={loadingTipoUbicacion}
                  options={tipoUbicacion}
                  optionLabel="descripcion"
                  value={model?.tipoUbicacion}
                  onChange={(e) => handleChangeTipoUbicacion(e.value)}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"990"} alt="Calle" />
                </label>
                <SipcoDropdown
                  loading={loadingCalle}
                  options={calle}
                  optionLabel="descripcion"
                  value={model?.calle}
                  onChange={(e) => handleChangeCalle(e.value)}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"991"} alt="Columna" />
                </label>
                <SipcoDropdown
                loading={loadingColumna}
                options={columna}
                optionLabel="descripcion"
                value={model?.columna}
                onChange={(e) => handleChangeColumna(e.value)}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"992"} alt="Nivel" />
                </label>
                <SipcoDropdown
                loading={loadingNivel}
                options={nivel}
                optionLabel="descripcion"
                value={model?.nivel}
                onChange={(e) => setModel({ ...model, nivel: e.value })}
                />
              </div>
            </div>
          </div>

        </AccordionTab>

      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="6697" alt="Listado" />
              </span>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={report}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={8}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRowsGrilla}
          onSelectionChange={(e: any) => setSelectedGrilla(e.value)}
          metaKeySelection={true}
          dragSelection
        >
          {grillaCabecera.map((colum, index) => {
            if (colum.header === "Fecha Última Modificación") {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable      
                  filter
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </SipcoDataTable>

      </Panel>



    </div>
  );
}
export default MantenerUbicacion;
