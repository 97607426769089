import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { UbicacionCategorias } from "src/models/UbicacionCategorias";

const UbicacionCategoria = () => {
  const SCREEN_CODE = "abm0217_ubicacion_categoria";
  const URL_BASE = "/ubicacion-categoria";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: UbicacionCategorias, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        await sipcoAxiosService.post(URL_BASE + "/save", {
          ...model,
          categorias:
            model.categorias && model.categorias.length > 0
              ? model.categorias
              : [],
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues?.securityValues?.account,
          subcuenta: filterValues?.securityValues?.subaccount,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: UbicacionCategorias) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", model);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<UbicacionCategorias[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter?.securityValues?.account,
          subcuenta: filter?.securityValues?.subaccount,
          calle: filter?.calle,
          categoria: filter?.categoria,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadCalle(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.account &&
        filter?.securityValues?.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/calle-desde",
          {
            pais: filter?.securityValues?.country,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadCategoria(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/categoria",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadColumnaDesde(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.account &&
        filter?.securityValues?.subaccount &&
        filter?.calleDesde
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/columna-desde",
          {
            pais: filter?.securityValues?.country,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            calleDesde: filter?.calleDesde,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadNivelDesde(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.account &&
        filter?.securityValues?.subaccount &&
        filter?.calleDesde &&
        filter?.columnaDesde
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/nivel-desde",
          {
            pais: filter?.securityValues?.country,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            calleDesde: filter?.calleDesde,
            columnaDesde: filter?.columnaDesde,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadCalleHasta(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.account &&
        filter?.securityValues?.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/calle-hasta",
          {
            pais: filter?.securityValues?.country,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadColumnaHasta(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.account &&
        filter?.securityValues?.subaccount &&
        filter?.calleHasta
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/columna-hasta",
          {
            pais: filter?.securityValues?.country,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            calleHasta: filter?.calleHasta,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadNivelHasta(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.account &&
        filter?.securityValues?.subaccount &&
        filter?.calleHasta &&
        filter?.columnaHasta
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/nivel-hasta",
          {
            pais: filter?.securityValues?.country,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            calleHasta: filter?.calleHasta,
            columnaHasta: filter?.columnaHasta,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<UbicacionCategorias>
      screenCode={SCREEN_CODE}
      title={
        <LanguageProvider id="21386" alt="Mantener Ubicaciones Categoria" />
      }
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
          subaccount: true,
        },
      }}
      filterSettings={[
        {
          filter: "calle",
          label: LanguageProvider({ id: "990", alt: "Calle" }),
          placeholder: LanguageProvider({ id: "990", alt: "Calle" }),
          type: "dropdown",
          dataSource: loadCalle,
          dependency: [
            "securityValues.country",
            "securityValues.account",
            "securityValues.subaccount",
          ],
        },
        {
          filter: "categoria",
          label: LanguageProvider({ id: "1256", alt: "Categoría" }),
          placeholder: LanguageProvider({ id: "1256", alt: "Categoría" }),
          type: "dropdown",
          dataSource: loadCategoria,
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
        },
      ]}
      dataTableColumns={[
        {
          field: "calle",
          header: <LanguageProvider id="990" alt="Calle" />,
        },
        {
          field: "columna",
          header: <LanguageProvider id="991" alt="Columna" />,
        },
        {
          field: "nivel",
          header: <LanguageProvider id="992" alt="Nivel" />,
        },
        {
          field: "prioridad",
          header: <LanguageProvider id="4976" alt="Prioridad" />,
        },
        {
          field: "categoria.descripcion",
          header: <LanguageProvider id="1256" alt="Categoría" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            );
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        cuenta: null,
        subcuenta: null,
        calleDesde: null,
        columnaDesde: null,
        nivelDesde: null,
        categoria: null,
        categorias: null,
        calleHasta: null,
        columnaHasta: null,
        nivelHasta: null,
        prioridad: "",
      }}
      formSettings={[
        {
          field: "calleDesde",
          label: <LanguageProvider id="11554" alt="Calle Inicio" />,
          dropDownPlaceholder: LanguageProvider({
            id: "11554",
            alt: "Calle Inicio",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadCalle(filter),
          required: true,
        },
        {
          field: "columnaDesde",
          label: <LanguageProvider id="11555" alt="Columna Inicio" />,
          dropDownPlaceholder: LanguageProvider({
            id: "11555",
            alt: "Columna Inicio",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadColumnaDesde(filter),
          dependency: ["calleDesde"],
          required: true,
        },
        {
          field: "nivelDesde",
          label: <LanguageProvider id="11556" alt="Nivel Inicio" />,
          dropDownPlaceholder: LanguageProvider({
            id: "11556",
            alt: "Nivel Inicio",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadNivelDesde(filter),
          dependency: ["calleDesde", "columnaDesde"],
          required: true,
        },
        {
          field: "categorias",
          label: LanguageProvider({ id: "1256", alt: "Categoría" }),
          dropDownPlaceholder: LanguageProvider({
            id: "1256",
            alt: "Categoría",
          }),
          type: "dropdown",
          dataSource: loadCategoria,
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
        },
        {
          field: "calleHasta",
          label: <LanguageProvider id="11557" alt="Calle Fin" />,
          dropDownPlaceholder: LanguageProvider({
            id: "11557",
            alt: "Calle Fin",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadCalleHasta(filter),
          required: true,
        },
        {
          field: "columnaHasta",
          label: <LanguageProvider id="11558" alt="Columna Fin" />,
          dropDownPlaceholder: LanguageProvider({
            id: "11558",
            alt: "Columna Fin",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadColumnaHasta(filter),
          dependency: ["calleHasta"],
          required: true,
        },
        {
          field: "nivelHasta",
          label: <LanguageProvider id="11559" alt="Nivel Fin" />,
          dropDownPlaceholder: LanguageProvider({
            id: "11559",
            alt: "Nivel Fin",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadNivelHasta(filter),
          dependency: ["calleHasta", "columnaHasta"],
          required: true,
        },
        {
          field: "prioridad",
          label: <LanguageProvider id="23791" alt="Prioridad" />,
          dropDownPlaceholder: LanguageProvider({
            id: "23791",
            alt: "Prioridad",
          }),
          type: "inputText",
          disabled: true,
          required: true,
        },
      ]}
      list={handleList}
      get={null}
      save={handleSave}
      update={null}
      remove={handleRemove}
    />
  );
};

export default UbicacionCategoria;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
