import React, { useEffect, useRef, useState } from "react";

import GenericAbm, {
  GenericAbmRef,
} from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { useGenericAbm } from "@shared/GenericAbmContext";
import { ReglaFacturacion } from "src/models/ReglaFacturacion";
import { Button } from "primereact/button";

const INITIAL_FORM = {
  id: null,
  activo: true,
  pais: null,
  cliente: null,
  cuenta: null,
  subcuenta: null,
  mercado: null,
  origen: null,
  marca: null,
  servicio: null,
  operacion: null,
  verificaConcesionario: false,
  verificaModelo: false,
  verificaDestino: false,
};

const ReglasFacturacion = () => {
  const SCREEN_CODE = "abm0241_reglas_facturacion";
  const URL_BASE = "/reglas-facturacion";
  const SCREEN_TITLE = (
    <LanguageProvider id="22106" alt="Reglas de Facturación" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const abmRef = useRef<GenericAbmRef<ReglaFacturacion>>();
  // const { genericAbmStatesContextData } = useGenericAbm<any>();
  const [formState, setFormState] = useState<ReglaFacturacion>(INITIAL_FORM);

  const empityFilterServicio = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - 
    ${LanguageProvider({ id: "9912", alt: "x" })}`;
  const showToastAndReturnEmptyArray = (message: string) => {
    toast.current?.show({
      severity: "warn",
      detail: message,
      life: 3000,
    });
    return [];
  };

  async function handleSave(model: ReglaFacturacion, filterValues: any) {
    // try {
    //   if (
    //     filterValues?.securityValues?.country &&
    //     filterValues.securityValues.client &&
    //     filterValues.securityValues.account
    //   ) {
    //     const validacionOperacion = {
    //       ...formState,
    //       pais: filterValues.securityValues.country,
    //       cliente: filterValues.securityValues.client,
    //       cuenta: filterValues.securityValues.account,
    //     };
    //     await sipcoAxiosService.post(URL_BASE + "/save", {
    //       validacionOperacion,
    //     });
    //     return true;
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
    return false;
  }
  async function handleUpdate(model: ReglaFacturacion, filterValues: any) {
    // try {
    //   const validacionOperacion = {
    //     ...model,
    //     pais: filterValues.securityValues.country,
    //     cliente: filterValues.securityValues.client,
    //     cuenta: filterValues.securityValues.account,
    //   };
    //   await sipcoAxiosService.post(URL_BASE + "/update", {
    //     validacionOperacion,
    //   });
    //   return true;
    // } catch (error) {
    //   console.error(error);
    // }
    return false;
  }
  async function handleRemove(model: ReglaFacturacion, filterValues: any) {
    // try {
    //   await sipcoAxiosService.post(URL_BASE + "/update", {
    //     validacionOperacion: { ...model, activo: false },
    //   });
    //   return true;
    // } catch (error) {
    //   console.error(error);
    // }
    return false;
  }
  async function handleList(filter: any): Promise<ReglaFacturacion[]> {
    if (!filter?.gridChkServicios || filter?.gridChkServicios?.length === 0) {
      return showToastAndReturnEmptyArray(empityFilterServicio);
    }

    if (filter?.securityValues?.country && filter.securityValues.client) {
      try {
        const payload = {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter.securityValues.account,
          servicios: filter.gridChkServicios,
        };
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          payload
        );
        if (status === 200) return data;
        return [];
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<ReglaFacturacion> {
    // try {
    //   const { status, data } = await sipcoAxiosService.post(
    //     URL_BASE + "/findByPk",
    //     {
    //       validacionOperacion: model,
    //     }
    //   );
    //   if (status === 200) {
    //     setFormState(data);
    //     return data;
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
    return null;
  }

  async function loadGridChkServiciosFilter(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter.securityValues.account
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/servicio",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.label}${item.id}`,
            }));
            return updatedData;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  // const openNewTabConcesionario = () => {
  //   if (
  //     genericAbmStatesContextData?.selectedEntries === null ||
  //     genericAbmStatesContextData?.selectedEntries?.length === 0
  //   ) {
  //     return showToastAndReturnEmptyArray(empitySelectGrid);
  //   }

  //   const newTab = window.open(
  //     "/mantener-parametros-operacion-concesionario",
  //     "_blank"
  //   );

  //   const { selectedEntries, filter } = genericAbmStatesContextData;
  //   const dto = {
  //     pais: filter?.securityValues?.country,
  //     cliente: filter?.securityValues?.client,
  //     cuenta: filter?.securityValues?.account,
  //     validacionOperacion: selectedEntries[0],
  //   };

  //   if (newTab) {
  //     setTimeout(() => {
  //       const dataToSend = {
  //         type: "from-mantener-parametrizacion-operaciones", // Identificador da mensagem
  //         payload: dto, // Dados dinâmicos
  //       };

  //       newTab.postMessage(dataToSend, window.origin);
  //     }, 1000);
  //   }
  // };

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ReglaFacturacion>
        ref={abmRef}
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
          },
        }}
        filterSettings={[
          {
            filter: "gridChkServicios",
            label: LanguageProvider({ id: "9912", alt: "Servicio" }),
            placeholder: LanguageProvider({ id: "9912", alt: "Servicio" }),
            type: "multiselect",
            optionLabel: "label",
            dataSource: loadGridChkServiciosFilter,
            dropDownTemplate: DropdownOptionTemplateLabelId,
            dependency: [
              "securityValues.country",
              "securityValues.client",
              "securityValues.account",
            ],
            virtualizeDropdown: true,
            filterBy: "search",
          },
        ]}
        dataTableColumns={[
          { field: "id", header: <LanguageProvider id="11409" alt="x" /> },
          { field: "servicio.descripcion", header: <LanguageProvider id="9912" alt="x" /> },
          {
            field: "operacion",
            header: <LanguageProvider id="2785" alt="x" />,
            body: (rowData: any) =>
              rowData.operacion === null
                ? "TODOS"
                : rowData.operacion.descripcion,
          },
          {
            field: "subcuenta",
            header: <LanguageProvider id="4791" alt="x" />,
            body: (rowData: any) =>
              rowData.subcuenta === null
                ? "TODOS"
                : rowData.subcuenta.descripcion,
          },
          {
            field: "mercado",
            header: <LanguageProvider id="367" alt="x" />,
            body: (rowData: any) =>
              rowData.mercado === null ? "TODOS" : rowData.mercado.descripcion,
          },
          {
            field: "origen",
            header: <LanguageProvider id="2496" alt="x" />,
            body: (rowData: any) =>
              rowData.origen === null ? "TODOS" : rowData.origen.descripcion,
          },
          {
            field: "marca",
            header: <LanguageProvider id="396" alt="x" />,
            body: (rowData: any) =>
              rowData.marca === null ? "TODOS" : rowData.marca.descripcion,
          },
          {
            field: "verificaConcesionario",
            header: <LanguageProvider id="18284" alt="x" />,
            body: (rowData: any) =>
              rowData.verificaConcesionario ? "SI" : "NO",
          },
          {
            field: "verificaModelo",
            header: <LanguageProvider id="22117" alt="x" />,
            body: (rowData: any) => (rowData.verificaModelo ? "SI" : "NO"),
          },
          {
            field: "verificaDestino",
            header: <LanguageProvider id="22118" alt="x" />,
            body: (rowData: any) => (rowData.verificaDestino ? "SI" : "NO"),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={INITIAL_FORM}
        formSettings={[
          {
            field: "servicio",
            label: LanguageProvider({ id: "9912", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "9912", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadServicios(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "subcuenta",
            label: LanguageProvider({ id: "4791", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "4791", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadSubcuentas(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "operacion",
            label: LanguageProvider({ id: "2785", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "2785", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadOperaciones(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "mercado",
            label: LanguageProvider({ id: "367", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "367", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadMercados(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "origen",
            label: LanguageProvider({ id: "2496", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "2496", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadOrigenes(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "marca",
            label: LanguageProvider({ id: "396", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "396", alt: "x" }),
            type: "dropdown",
            // dataSource: (filter: any) => loadMarcas(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        // customActions={
        //   <>
        //     <Button
        //       label={LanguageProvider({ id: "266", alt: "Concesionarios" })}
        //       onClick={() => {
        //         // openNewTabConcesionario();
        //       }}
        //     />
        //   </>
        // }
      />
    </>
  );
};

export default ReglasFacturacion;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionTemplateLabelId = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
