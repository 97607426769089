import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import {  PlayaClienteLiberacion } from "src/models/PlayaClienteLiberacion";

const MantenerPlayaClienteLiberacion = () => {
  const SCREEN_CODE = "abm0218_mantener_playa_cliente_liberacion";
  const URL_BASE = "/mantener-playa-cliente-liberacion";
  const SCREEN_TITLE = (
    <LanguageProvider id="19753" alt="Mantener Playa Cliente Liberacion" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: PlayaClienteLiberacion, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues.securityValues.client &&
        filterValues.securityValues.account
      ) {
        const playaClienteLiberacion = {
          ...model,

          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          playaClienteLiberacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: PlayaClienteLiberacion, filterValues: any) {
    try {
      const playaClienteLiberacion = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        playaClienteLiberacion,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: PlayaClienteLiberacion, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        playaClienteLiberacion: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<PlayaClienteLiberacion[]> {
    if (filter?.securityValues?.country && filter.securityValues.account) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<PlayaClienteLiberacion> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          playaClienteLiberacion: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadSubcuentas(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/subcuenta",
          {
            pais: filter.securityValues.country,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadTransportistas(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/proveedor",
          {
            pais: filter.securityValues.country,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<PlayaClienteLiberacion>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
          },
        }}
        // filterSettings={}
        dataTableColumns={[
          {
            field: "subcuenta.descripcion",
            header: <LanguageProvider id="3756" alt="Descripción" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]
        }
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          cuenta: null,
          cliente: null,
          subcuenta: null,
          proveedorInformado: null,
        }}
        formSettings={[
          {
            field: "subcuenta",
            label: LanguageProvider({ id: "2892", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "2892", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadSubcuentas(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate: true,
          },
          {
            field: "proveedorInformado",
            label: LanguageProvider({ id: "754", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "754", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadTransportistas(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "x" }),
            type: "checkbox",
          }
        ]
        }
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default MantenerPlayaClienteLiberacion;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
