import "./IngresoTaler.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

// Banco de dados não possui nenhum valor para retornar no dataTable
export function IngresoTaler() {
  const SCREEN_CODE = "fun0050_ingreso_taller";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [taller, setTaller] = useState(null);
  const [loadingTaller, setLoadingTaller] = useState(false);

  const [calle, setCalle] = useState(null);
  const [loadingCalle, setLoadingCalle] = useState(false);

  const [columna, setColumna] = useState(null);
  const [loadingColumna, setLoadingColumna] = useState(false);

  const [nivel, setNivel] = useState(null);
  const [loadingNivel, setLoadingNivel] = useState(false);

  const [filter, setFilter] = useState({
    taller: null,
    items: null,
    calle: "",
    columna: "",
    nivel: "",
    fechaPromesaEntrega: null,
    saneamiento6: false,
    saneamiento12: false,
  });
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];

  const filterColumnsId = [
    16949, 2518, 11833, 2519, 16483, 16480, 8627, 3756, 16968, 3756, 16481,
    16482, 3756, 13706, 23848,
  ];
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  async function loadReportData() {
    setLoading(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-taller/remito",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          subcuenta: securityFilters.securityValues.subaccount,
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  try {
  } catch (error) {
    console.error(error);
  }
  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-taller/taller",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === 200) {
          setTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
    return [];
  }
  useEffect(() => {});
  async function loadCalle(value: any) {
    setLoadingCalle(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null &&
        value != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-taller/calles",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            taller: value,
          }
        );
        if (status === 200) {
          setCalle(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalle(false);
    }
    return [];
  }
  async function loadColumna(value: any) {
    setLoadingColumna(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null &&
        value != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-taller/columnas",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            taller: value,
          }
        );
        if (status === 200) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
    return [];
  }
  async function loadNivel(value: any) {
    setLoadingNivel(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        securityFilters?.securityValues?.subaccount != null &&
        value != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-taller/niveles",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            taller: value,
          }
        );
        if (status === 200) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
    return [];
  }

  useEffect(() => {
    async function initialize() {
      try {
        const talleres = await loadTaller();
        handleTallerChange(talleres[0]);
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);
  async function handleTallerChange(value: any) {
    try {
      const calles = await loadCalle(value);
      const columnas = await loadColumna(value);
      const niveles = await loadNivel(value);
      setFilter({
        ...filter,
        taller: value,
        calle: calles[0] ?? null,
        nivel: niveles[0] ?? null,
        columna: columnas[0] ?? null,
      });
      setCalle(calles);
      setColumna(columnas);
      setNivel(niveles);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    console.log(filter.saneamiento6);
  }, [filter.saneamiento6]);
  useEffect(() => {
    console.log(filter.saneamiento12);
  }, [filter.saneamiento12]);
  return (
    <div className="ingreso-taller">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Ingreso Taller</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({
                    id: "748",
                    alt: "Seleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "749",
                    alt: "Deseleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "34",
                    alt: "Procesar o guardar cambios",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable ref={dt} value={data} loading={loading}>
          <SipcoColumn field="0" header={"VIN"} sortable filter />
          <SipcoColumn field="1" header={"Letra"} sortable filter />
          <SipcoColumn field="2" header={"Pto.Venta"} sortable filter />
          <SipcoColumn field="3" header={"Código Remito"} sortable filter />
          <SipcoColumn field="4" header={"Fecha Emisión"} sortable filter />
          <SipcoColumn field="5" header={"Marca"} sortable filter />
          <SipcoColumn field="6" header={"Modelo"} sortable filter />
          <SipcoColumn field="7" header={"Color"} sortable filter />
          <SipcoColumn field="8" header={"Fecha de carga"} sortable filter />
          <SipcoColumn field="9" header={"Estado"} sortable filter />
        </SipcoDataTable>

        <Divider />
        <div className="filter-options">
          <div className={"sipco-option-line"}>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Taller" />
              </label>
              <SipcoDropdown
                value={filter.taller}
                options={taller}
                onChange={(e) => handleTallerChange(e.value)}
                loading={loadingTaller}
                optionLabel="descripcion"
                filter
                showClear
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Fecha Promessa Entrega" />
              </label>
              <SipcoCalendar
                value={filter.fechaPromesaEntrega}
                onChange={(e) => {
                  setFilter({ ...filter, fechaPromesaEntrega: e.value });
                }}
                showIcon
              />
            </div>
          </div>
          <div className={"sipco-option-line"}>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Calle" />
              </label>
              <SipcoDropdown
                value={filter.calle}
                options={calle}
                onChange={(e) => setFilter({ ...filter, calle: e.value })}
                loading={loadingCalle}
                optionLabel="descripcion"
                filter
                showClear
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Columna" />
              </label>
              <SipcoDropdown
                value={filter.columna}
                options={columna}
                onChange={(e) => setFilter({ ...filter, columna: e.value })}
                loading={loadingColumna}
                optionLabel="descripcion"
                filter
                showClear
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Nivel" />
              </label>
              <SipcoDropdown
                value={filter.nivel}
                options={nivel}
                onChange={(e) => setFilter({ ...filter, nivel: e.value })}
                loading={loadingNivel}
                optionLabel="descripcion"
                filter
                showClear
              />
            </div>
          </div>
          <div className={"sipco-option-line"}>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Saneamiento" />
              </label>
              <div className={"sipco-option-radio"}>
                <RadioButton
                  checked={filter.saneamiento6}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      saneamiento6: true,
                      saneamiento12: false,
                    })
                  }
                />
                <label>
                  <LanguageProvider id={"TODO"} alt="6 messes" />
                </label>
              </div>
              <div className={"sipco-option-radio"}>
                <RadioButton
                  checked={filter.saneamiento12}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      saneamiento12: true,
                      saneamiento6: false,
                    })
                  }
                />
                <label>
                  <LanguageProvider id={"TODO"} alt="12 messes" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
}
