import "./ContableVerticalizacion.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import useRowStyles from "../../../hooks/useRowStyles";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { HttpStatusCode } from "axios";
import { StringIdLabelEntity } from "../../../models/StringIdLabelEntity";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { Filter } from "@shared/components/filter/filter";

export function ContableVerticalizacion() {
  const SCREEN_CODE = "fun0228_contable_verticalizacion";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  ScreenCodeValue(SCREEN_CODE);
  const toast = useRef<Toast>(null);
  usePageViews();
  const dt = useRef(null);
  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);
  const [selectedReportData, setSelectedReportData] = useState([]);
  const rowStyles = useRowStyles([selectedReportData]);

  const ESTADOS_REGISTRO = [
    new StringIdLabelEntity({ id: "TODOS", descripcion: "TODOS" }),
    new StringIdLabelEntity({ id: "ANULADA", descripcion: "ANULADA" }),
    new StringIdLabelEntity({ id: "GENERADO", descripcion: "GENERADO" }),
    new StringIdLabelEntity({ id: "PENDIENTE", descripcion: "PENDIENTE" }),
    new StringIdLabelEntity({
      id: "NO VERTICALIZADO",
      descripcion: "NO VERTICALIZADO",
    }),
  ];

  const [filter, setFilter] = useState({
    desde: new Date(),
    hasta: new Date(),
    codProforma: null,
    estadoRegistro: ESTADOS_REGISTRO[0],
    hr: null,
    tipoDespacho: null,
    proveedorTractor: null,
    estado: null,
  });

  const [estado, setEstado] = useState([]);
  const [loadingEstado, setLoadingEstado] = useState(false);
  async function loadEstado() {
    setLoadingEstado(true);
    try {
      if (securityFilters?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          "/contable-verticalizacion/filter/estado",
          { pais: securityFilters?.securityValues?.country }
        );

        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: null, descripcion: "TODOS" });
          setEstado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstado(false);
    }
  }
  const [tipoDespacho, setTipoDespacho] = useState([]);
  const [loadingTipoDespacho, setLoadingTipoDespacho] = useState(false);
  async function loadTipoDespacho() {
    setLoadingTipoDespacho(true);
    try {
      if (securityFilters?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          "/contable-verticalizacion/filter/despacho",
          { pais: securityFilters?.securityValues?.country }
        );

        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: null, descripcion: "TODOS" });
          setTipoDespacho(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoDespacho(false);
    }
  }
  const [proveedorTractor, setProveedorTractor] = useState([]);
  const [loadingProveedorTractor, setLoadingProveedorTractor] = useState(false);
  async function loadProveedorTractor() {
    setLoadingProveedorTractor(true);
    try {
      if (securityFilters?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          "/contable-verticalizacion/filter/proveedor",
          {}
        );

        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: null, descripcion: "TODOS" });
          setProveedorTractor(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedorTractor(false);
    }
  }
  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const filters = {
        pais: securityFilters?.securityValues?.country,
        cliente: securityFilters?.securityValues?.client,
        cuenta: securityFilters?.securityValues?.account,
        fechaDesde: { date: filter.desde, timestamp: filter.desde },
        fechaHasta: { date: filter.hasta, timestamp: filter.hasta },
        tipoDespacho: filter?.tipoDespacho,
        proveedor: filter?.proveedorTractor,
        estado: filter?.estado,
        nroHojaRuta: filter.hr,
        nroProforma: filter.codProforma,
        estadoRegistro: filter?.estadoRegistro,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/contable-verticalizacion/contable",
        filters
      );

      if (status === HttpStatusCode.Ok) {
        setReporte(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }
  async function btnProcesarClick() {
    try {
      if (selectedReportData.length === 0) {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: (
            <LanguageProvider
              id={"753"}
              alt="Debe seleccionar al menos un registro de la Grilla."
            />
          ),
          life: 3000,
        });
        return;
      }
      let hojasRuta = [];
      for (const hr of selectedReportData) {
        hojasRuta.push({ id: hr[0], observaciones: hr[34] });
      }
      await cancelarProvisionesCompraFleteVerticalizacion(
        securityFilters.securityValues.country,
        securityFilters.securityValues.account,
        hojasRuta
      );
    } catch (error) {
      console.error(error);
    }
  }
  async function cancelarProvisionesCompraFleteVerticalizacion(
    pais: any,
    cuenta: any,
    hrArray: any[]
  ) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/contable-verticalizacion/anular",
        {
          pais: pais,
          cuenta: cuenta,
          hojasRuta: hrArray,
        }
      );
      if (status === 200) {
        if (data.length > 0) {
          let dialogMessage = [];
          for (const hrError of data) {
            dialogMessage.push(`${hrError.hrId} => ${hrError.error}`);
          }
          confirmDialog({
            header: <LanguageProvider id={"16239"} alt="Atención" />,
            message: (
              <div className="flex flex-wrap gap-2 mt-5 ">
                {dialogMessage.map((x) => {
                  return <span>{x}</span>;
                })}
              </div>
            ),
            style: { width: 500 },
            icon: "pi pi-exclamation-triangle",
            defaultFocus: "accept",
            ariaCloseIconLabel: "Ok",
          });
        } else {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Todos los registros enviados fueron procesados",
            life: 3000,
          });
          await loadReportData();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function btnAnularClick() {
    try {
      if (selectedReportData.length === 0) {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: (
            <LanguageProvider
              id={"753"}
              alt="Debe seleccionar al menos un registro de la Grilla."
            />
          ),
          life: 3000,
        });
        return;
      }
      let hojasRuta = [];
      let pendientes = 0;
      let pendientesTxt = "";
      for (const hr of selectedReportData) {
        hojasRuta.push({ id: hr[0], observaciones: hr[34] });
        if (hr[34] === "PENDIENTE") {
          pendientes++;
          pendientesTxt += `${hr[0]}, `;
        }
      }

      if (pendientes > 0 && selectedReportData.length === pendientes) {
        confirmDialog({
          header: <LanguageProvider id={"16239"} alt="Atención" />,
          message:
            "Las Hojas de Ruta seleccionadas: " +
            pendientesTxt +
            " se encuentran Pendientes por lo que no se pueden anular.",
          icon: "pi pi-exclamation-triangle",
          defaultFocus: "accept",
        });
      } else if (pendientes > 0) {
        confirmDialog({
          header: <LanguageProvider id={"16239"} alt="Atención" />,
          message:
            "Las siguientes Hojas de Ruta se encuentran Pendientes de procesar: " +
            pendientesTxt +
            " ¿Procede a anular el resto de las Hojas de rutas seleccionadas? ",
          icon: "pi pi-exclamation-triangle",
          defaultFocus: "accept",
          accept: responseAnular,
        });
      } else {
        confirmDialog({
          header: <LanguageProvider id={"16239"} alt="Atención" />,
          message: (
            <LanguageProvider
              id="24989"
              alt="¿Anula las Hojas de Rutas seleccionadas?"
            />
          ),
          icon: "pi pi-exclamation-triangle",
          defaultFocus: "accept",
          accept: responseAnular,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function responseAnular() {
    try {
      let hojasRuta = [];
      for (const hr of selectedReportData) {
        if (hr[34] === "GENERADO") {
          hojasRuta.push({ id: hr[0], observaciones: hr[34] });
        }
      }
      await anularMovimiento(
        securityFilters.securityValues.country,
        securityFilters.securityValues.account,
        hojasRuta
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function anularMovimiento(pais: any, cuenta: any, hrArray: any[]) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/contable-verticalizacion/anular",
        {
          pais: pais,
          cuenta: cuenta,
          hojasRuta: hrArray,
        }
      );
      if (status === 200) {
        if (data.length > 0) {
          let dialogMessage = [];
          for (const hrError of data) {
            dialogMessage.push(`${hrError.hrId} => ${hrError.error}`);
          }
          toast.current?.show({
            severity: "warn",
            summary: "Aviso",
            detail: `Resultado de la anulación. Procesados ${data.length} ${dialogMessage.join(";")}`,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Todos los registros enviados fueron anulados ",
            life: 3000,
          });
          await loadReportData();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function init() {
      try {
        const [estado, tipoDespacho, proveedorTractor] = await Promise.all([
          loadEstado(),
          loadTipoDespacho(),
          loadProveedorTractor(),
        ]);

        setFilter({
          ...filter,
          tipoDespacho: tipoDespacho[0],
          proveedorTractor: proveedorTractor[0],
          estado: estado[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters?.securityValues]);

  const reportData = convertDateObjects(reporte?.filas);

  return (
    <div className="contable-verticalizacion">
      <Toast ref={toast} position="top-center" />
      <ConfirmDialog />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"2621"} alt="Desde" />
                </label>
                <Calendar
                  showIcon
                  value={filter.desde}
                  onChange={(e: any) =>
                    setFilter({ ...filter, desde: e.value })
                  }
                />
                <label>
                  <LanguageProvider id={"5431"} alt="HR" />
                </label>
                <InputText
                  value={filter.hr}
                  onChange={(e) => setFilter({ ...filter, hr: e.target.value })}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"2622"} alt="Hasta" />
                </label>
                <Calendar
                  showIcon
                  value={filter.hasta}
                  onChange={(e: any) =>
                    setFilter({ ...filter, hasta: e.value })
                  }
                />
                <label>
                  <LanguageProvider id={"504"} alt="Tipo Despacho" />
                </label>
                <SipcoDropdown
                  loading={loadingTipoDespacho}
                  value={filter.tipoDespacho}
                  options={tipoDespacho}
                  onChange={(e) =>
                    setFilter({ ...filter, tipoDespacho: e.value })
                  }
                  optionLabel="descripcion"
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"24922"} alt="Codigo Proforma" />
                </label>
                <InputText
                  value={filter.codProforma}
                  onChange={(e) =>
                    setFilter({ ...filter, codProforma: e.target.value })
                  }
                />
                <label>
                  <LanguageProvider id={"11787"} alt="Proveedor Tractor" />
                </label>
                <SipcoDropdown
                  loading={loadingProveedorTractor}
                  value={filter.proveedorTractor}
                  options={proveedorTractor}
                  onChange={(e) =>
                    setFilter({ ...filter, proveedorTractor: e.value })
                  }
                  optionLabel="descripcion"
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"13319"} alt="Estado Registro" />
                </label>
                <SipcoDropdown
                  value={filter.estadoRegistro}
                  options={ESTADOS_REGISTRO}
                  onChange={(e) =>
                    setFilter({ ...filter, estadoRegistro: e.value })
                  }
                  optionLabel="descripcion"
                />
                <label>
                  <LanguageProvider id={"3344"} alt="Estado" />
                </label>
                <SipcoDropdown
                  value={filter.estado}
                  options={estado}
                  onChange={(e) => setFilter({ ...filter, estado: e.value })}
                  loading={loadingEstado}
                  optionLabel="descripcion"
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        className="mb-3"
        header={<LanguageProvider id={"6697"} alt="Listado" />}
      >
        <SipcoDataTable
          ref={dt}
          value={reportData}
          rowClassName={rowStyles}
          loading={loadingReportData}
          selectionMode="multiple"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
        >
          <SipcoColumn
            field="0"
            sortable
            header={<LanguageProvider id="924" alt="Hoja de Ruta" />}
            filter
          />
          <SipcoColumn
            field="1"
            sortable
            header={<LanguageProvider id="24913" alt="Hoja Ruta BSM" />}
            filter
          />
          <SipcoColumn
            field="2"
            sortable
            header={<LanguageProvider id="6826" alt="GENERACION" />}
            filter
          />

          <SipcoColumn
            field="3"
            sortable
            header={<LanguageProvider id="20804" alt="Estado" />}
            filter
          />

          <SipcoColumn
            field="4"
            sortable
            filter
            header={<LanguageProvider id="25017" alt="KM_GEFCO" />}
          />
          <SipcoColumn
            field="5"
            sortable
            header={<LanguageProvider id="25018" alt="KM_BSM" />}
            filter
          />
          <SipcoColumn
            field="6"
            sortable
            filter
            header={<LanguageProvider id="11787" alt="Proveedor tractor" />}
          />
          <SipcoColumn
            field="7"
            sortable
            filter
            header={<LanguageProvider id="10948" alt="Administrador tractor" />}
          />
          <SipcoColumn
            field="8"
            sortable
            filter
            header={<LanguageProvider id="4724" alt="Patente" />}
          />
          <SipcoColumn
            field="9"
            sortable
            filter
            header={<LanguageProvider id="24971" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="10"
            sortable
            filter
            header={<LanguageProvider id="24972" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="11"
            sortable
            filter
            header={<LanguageProvider id="504" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="12"
            sortable
            filter
            header={<LanguageProvider id="24914" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="13"
            sortable
            filter
            header={<LanguageProvider id="24915" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="14"
            sortable
            filter
            header={<LanguageProvider id="24916" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="15"
            sortable
            filter
            header={<LanguageProvider id="24917" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="16"
            sortable
            filter
            header={<LanguageProvider id="1342" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="17"
            sortable
            filter
            header={<LanguageProvider id="15862" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="18"
            sortable
            filter
            header={<LanguageProvider id="25012" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="19"
            sortable
            filter
            header={<LanguageProvider id="24991" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="20"
            sortable
            filter
            header={<LanguageProvider id="24992" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="21"
            sortable
            filter
            header={<LanguageProvider id="25013" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="22"
            sortable
            filter
            header={<LanguageProvider id="25014" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="23"
            sortable
            filter
            header={<LanguageProvider id="25015" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="24"
            sortable
            filter
            header={<LanguageProvider id="25016" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="25"
            sortable
            filter
            header={<LanguageProvider id="24918" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="26"
            sortable
            filter
            header={<LanguageProvider id="23728" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="27"
            sortable
            filter
            header={<LanguageProvider id="24973" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="28"
            sortable
            filter
            header={<LanguageProvider id="24919" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="29"
            sortable
            filter
            header={<LanguageProvider id="24920" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="30"
            sortable
            filter
            header={<LanguageProvider id="24924" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="31"
            sortable
            filter
            header={<LanguageProvider id="24921" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="32"
            sortable
            filter
            header={<LanguageProvider id="24922" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="33"
            sortable
            filter
            header={<LanguageProvider id="18071" alt="Proveedor batea" />}
          />
          <SipcoColumn
            field="34"
            sortable
            filter
            header={<LanguageProvider id="13319" alt="Proveedor batea" />}
          />
        </SipcoDataTable>
      </Panel>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "16283",
            alt: "Procesar",
          })}
          icon="pi pi-save"
          onClick={btnProcesarClick}
        />
        <Button
          label={LanguageProvider({
            id: "20973",
            alt: "Anular",
          })}
          icon="pi pi-ban"
          onClick={btnAnularClick}
        />
        <Button
          label={LanguageProvider({
            id: "748",
            alt: "Seleccionar todo",
          })}
          icon="pi pi-caret-up"
          onClick={() => {
            setSelectedReportData(reportData);
          }}
        />
        <Button
          label={LanguageProvider({
            id: "749",
            alt: "Deseleccionar todo",
          })}
          icon="pi pi-caret-down"
          onClick={() => {
            setSelectedReportData([]);
          }}
        />
      </div>
    </div>
  );
}
export default ContableVerticalizacion;
