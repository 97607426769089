import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Caja } from "src/models/Caja";
import { ContableSubcuenta } from "src/models/ContableSubcuenta";

const MantenerContableSubcuentas = () => {
  
  const SCREEN_CODE = "abm0080_mantener_contable_subcuentas";
  const URL_BASE = "/mantener-contable-subcuentas";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ContableSubcuenta, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const contableSubcuenta = {
          ...model,
          pais: filterValues.securityValues.country,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
          mercado: filterValues.mercado,
          codigoSubcuentaContable: model.codigoSubcuentaContable,
          codigoCheckContable: model.codigoCheckContable,
          cobraSeguro: model.cobraSeguro,
          playaEspecialRadios: model.playaEspecialRadios,
          cobraGancho: model.cobraGancho,
          cobraControlDanos: model.cobraControlDanos,
          cobraLogo: model.cobraLogo,
          cobraSticker: model.cobraSticker,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          contableSubcuenta,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: ContableSubcuenta, filterValues: any) {
    try {
      const contableSubcuenta = {
        ...model,
        pais: filterValues.securityValues.country,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
        mercado: filterValues.mercado,
        codigoSubcuentaContable: model.codigoSubcuentaContable,
        codigoCheckContable: model.codigoCheckContable,
        cobraSeguro: model.cobraSeguro,
        playaEspecialRadios: model.playaEspecialRadios,
        cobraGancho: model.cobraGancho,
        cobraControlDanos: model.cobraControlDanos,
        cobraLogo: model.cobraLogo,
        cobraSticker: model.cobraSticker,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        contableSubcuenta,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: ContableSubcuenta, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        contableSubcuenta: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<ContableSubcuenta[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter.securityValues.country,
          cuenta: filter.securityValues.account,
          subcuenta: filter.securityValues.subaccount,
          mercado: filter.mercado,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<ContableSubcuenta> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          contableSubcuenta: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMercado(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/mercado",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<ContableSubcuenta>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          account: true,
          subaccount: true,
        },
      }}
      dataTableColumns={[
        { 
          field: "codigoSubcuentaContable", 
          header: <LanguageProvider id="16353" alt="Subcuenta contable" />, 
        },
          
        {
          field: "codigoCheckContable",
          header: <LanguageProvider id="16354" alt="Check contable" />,
          body: (rowData: any) => rowData.codigoCheckContable ? "SI" : "NO",
        },
        {
          field: "cobraSeguro",
          header: <LanguageProvider id="16356" alt="Cobra seguro" />,
          body: (rowData: any) => rowData.cobraSeguro ? "SI" : "NO",

        },
        {
          field: "playaEspecialRadios",
          header: <LanguageProvider id="16357" alt="Playa especial radios" />,
          body: (rowData: any) => rowData.playaEspecialRadios ? "SI" : "NO",
        },
        {
          field: "cobraGancho",
          header: <LanguageProvider id="16358" alt="Cobra gancho" />,
          body: (rowData: any) => rowData.cobraGancho ? "SI" : "NO",
        },
        {
          field: "cobraControlDanos",
          header: <LanguageProvider id="16359" alt="Cobra control daños" />,
          body: (rowData: any) => rowData.cobraControlDanos ? "SI" : "NO",
        },
        {
          field: "cobraLogo",
          header: <LanguageProvider id="16360" alt="Cobra logo" />,
          body: (rowData: any) => rowData.cobraLogo ? "SI" : "NO",
        },
        {
          field: "cobraSticker",
          header: <LanguageProvider id="16361" alt="Cobra sticker" />,
          body: (rowData: any) => rowData.cobraSticker ? "SI" : "NO",
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => rowData.activo ? "SI" : "NO",
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        cuenta: null,
        mercado: null,
        usuarioUltimaModificacion: null,
        descripcion: '',
        cobraControlDanos: false,
        cobraGancho: false,
        cobraLogo: false,
        cobraSeguro: false,
        cobraSticker: false,
        playaEspecialRadios: false,
        codigoCheckContable: '',
        codigoSubcuentaContable: '',
        subcuenta: null
      }}
      loadDropdownData={{
        mercado: loadMercado,
      }}
      filterSettings={[
        {
          filter: "mercado",
          label: LanguageProvider({ id: "367", alt: "Mercado" }),
          placeholder: LanguageProvider({ id: "367", alt: "Mercado" }),
          type: "dropdown",
          dataSource: (filter: any) => loadMercado(filter),
          dropDownTemplate: DropdownOptionTemplate,
          required: true,
        },
      ]}
      formSettings={[
        {
          field: "codigoSubcuentaContable",
          label: <LanguageProvider id="16353" alt="Subcuenta contable" />,
          type: "inputText",
          length: 1,
        },
        {
          field: "codigoCheckContable",
          label: <LanguageProvider id="16354" alt="Check contable" />,
          type: "inputText",
          length: 3,
        },
        {
          field: "cobraSeguro",
          label: <LanguageProvider id="16356" alt="Cobra seguro" />,
          type: "checkbox",
        },
        {
          field: "playaEspecialRadios",
          label: <LanguageProvider id="16357" alt="Playa especial radios" />,
          type: "checkbox",
        },
        {
          field: "cobraGancho",
          label: <LanguageProvider id="16358" alt="Cobra gancho" />,
          type: "checkbox",
        },
        {
          field: "cobraControlDanos",
          label: <LanguageProvider id="16359" alt="Cobra control daños" />,
          type: "checkbox",
        },
        {
          field: "cobraLogo",
          label: <LanguageProvider id="16360" alt="Cobra logo" />,
          type: "checkbox",
        },
        {
          field: "cobraSticker",
          label: <LanguageProvider id="16361" alt="Cobra sticker" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerContableSubcuentas;


const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};