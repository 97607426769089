import "./GenPicking.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import PanelCustom from "../../../components/panel-custom/PanelCustom";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { HttpStatusCode } from "axios";

//TODO: verificar essa tela que esta bem fora do padrão vindo do projeto antigo e do novo- Lucas Silvestre - 29/08/2024
export function GenPicking() {
  const SCREEN_CODE = "fun0230_gen_picking";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [historicoTaller, setHistoricoTaller] = useState([]);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());

  const [filter, setFilter] = useState({
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    flujoObtExternos: false,
  });

  const columnsNames1 = [
    "Cod. LL / HR",
    "Cod. Picking list",
    "Cliente",
    "Fecha creacion HR",
    "Fecha Ventana",
    "Ventana horaria",
    "Prioridad",
    "Operaciones pendientes",
    "Cod. Calesita",
    "Linea de carga",
    "Estado",
    "Cantidad total",
    "Procesados",
    "No procesados",
    "Fin",
    "Gate OUT",
  ];

  async function loadReportData() {
    try {
      setLoading(true);
      const reportResult = await sipcoAxiosService.post(
        "/generar-picking/remito-retorno",
        {
          pais: securityFilters.securityValues?.country,
          cuenta: securityFilters.securityValues?.account,
          cliente: securityFilters.securityValues?.client,
          subcuenta: securityFilters.securityValues?.subaccount,
          fechaDesde: new Date(filter.fechaDesde).getTime() || null,
          fechaHasta: new Date(filter.fechaHasta).getTime() || null,
          isFlujoOBTExternos: filter.flujoObtExternos,
        }
      );

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === HttpStatusCode.Ok && data) {
          setReporte(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  // Refatorar columnsName e métodos de date, não estão configurados com os resultados.
  const columnsName = [
    { field: "codLL", header: "Cod. LL / HR" },
    { field: "codPL", header: "Cod Picking list" },
    { field: "cliente.descripcion", header: "Cliente" },
    { field: "fechaCreacionHR.date", header: "Fecha creacion HR" },
    { field: "fechaVentana", header: "Fecha Ventana" },
    { field: "opPendiente", header: "Operaciones pendientes" },
    { field: "calesitaId", header: "Cod Calesita" },
    { field: "lineaCarga.id", header: "Linea de carga" },
    { field: "estado.descripcion", header: "Estado" },
    { field: "cantTotal", header: "Cantidad total" },
    { field: "cantProcesado", header: "Procesados" },
    { field: "cantNoProcesado", header: "No procesados" },
    { field: "fin", header: "Fin" },
    { field: "gateOut", header: "Gate OUT" },

  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  return (
    <div className="gen-picking">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"22550"} alt="Filtrar por" />}
        >
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"467"} alt="Desde :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaDesde}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaDesde: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  showTime
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"468"} alt="Hasta :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaHasta}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaHasta: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  showTime
                />
              </div>
              <div className={"RadioButtonFilter"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Flujo OBT Externos" />
                </label>
                <Checkbox
                  value={filter.flujoObtExternos}
                  onChange={(e) =>
                    setFilter({ ...filter, flujoObtExternos: e.checked })
                  }
                  checked={filter.flujoObtExternos}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={<LanguageProvider id={"25135"} alt="Generación de Picking" />}
      >
        <div className="fieldset-content">
          <div className="chips">
            <div className={"sipco-chip"}>
              <label>
                <LanguageProvider
                  id={"TODO"}
                  alt="Total de Vines Seleccionados"
                />
              </label>
              <Chip label="0" />
            </div>
            <div className={"sipco-chip"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Total de Vines" />
              </label>
              <Chip label="0" />
            </div>
          </div>
          <SipcoDataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={reporte}
                  screenName={"gen-picking"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={reporte}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsName.map((column, index) => {
              if (column.header === "Fecha creacion HR") {
                return (
                  <SipcoColumn
                    key={index}
                    body={(e) => dateColumnTemplate(e, column.field)}
                    field={column.field}
                    header={column.header}
                    sortable
                    filter
                  />
                );
              } else {
                return (
                  <SipcoColumn
                    key={index}
                    field={column.field}
                    header={column.header}
                    sortable
                  />
                );
              }
            })}
          </SipcoDataTable>
        </div>

        <Divider />
        <div>
          <Button
            label={LanguageProvider({
              id: "25",
              alt: "Alta",
            })}
            text
          />
          <Button
            label={LanguageProvider({
              id: "27",
              alt: "Modificar",
            })}
            text
          />
          <Button
            label={LanguageProvider({
              id: "748",
              alt: "Seleccionar todo",
            })}
            text
          />
          <Button
            label={LanguageProvider({
              id: "749",
              alt: "Deseleccionar todo",
            })}
            text
          />
          <Button
            label={LanguageProvider({
              id: "3634",
              alt: "Imprimir",
            })}
            text
          />

          <Button
            label={LanguageProvider({
              id: "42",
              alt: "Excel",
            })}
            text
          />
        </div>
      </Fieldset>
    </div>
  );
}
export default GenPicking;
