import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { CentroCostoGefco } from "src/models/CentroCostoGefco";

const MantenerCentrosCostoGefco = () => {
  const SCREEN_CODE = "abm0136_mantener_centros_costo_gefco";
  const URL_BASE = "/mantener-centro-costos-gefco";
  const SCREEN_TITLE = (
    <LanguageProvider id="11054" alt="Mantener Centros Costos GEFCO" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: CentroCostoGefco, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client &&
        filterValues?.securityValues?.account &&
        filterValues?.securityValues?.subaccount
      ) {
        const centroCostoGefco = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues?.securityValues?.account,
          subcuenta: filterValues?.securityValues?.subaccount,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          centroCostoGefco,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: CentroCostoGefco, filterValues: any) {
    try {
      const centroCostoGefco = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues?.securityValues?.account,
        subcuenta: filterValues?.securityValues?.subaccount,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        centroCostoGefco,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: CentroCostoGefco, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        centroCostoGefco: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<CentroCostoGefco[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<CentroCostoGefco> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          centroCostoGefco: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMercado(filter: any): Promise<CentroCostoGefco[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/mercado",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadMarca(filter: any): Promise<CentroCostoGefco[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/marca",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadPagaFlete(
    filter: any
  ): Promise<CentroCostoGefco[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client 
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find-flete-cuenta",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,            
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<CentroCostoGefco>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
            subaccount: true,
          },
        }}
        // filterSettings={[]}
        dataTableColumns={[
          { field: "mercado.descripcion", header: <LanguageProvider id="367" alt="Mercado" /> },
          { field: "marca.descripcion", header: <LanguageProvider id="396" alt="Marca" /> },

          {
            field: "pagaFlete.descripcion",
            header: <LanguageProvider id="Paga Flete" alt="x" />,
          },
          { field: "id", header: <LanguageProvider id="11055" alt="x" /> },
          {
            field: "descripcion",
            header: <LanguageProvider id="31" alt="x" />,
          },

          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => {
              return (
                <span>
                  {new Date(
                    rowData.fechaUltimaModificacion.date
                  ).toLocaleString()}
                </span>
              );
            },
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => {
              return rowData.activo ? "SI" : "NO";
            },
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          cliente: null,
          cuenta: null,
          subcuenta: null,
          marca: null,
          mercado: null,
          pagaFlete: null,
        }}
        formSettings={[
          {
            field: "mercado",
            label: LanguageProvider({ id: "367", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "367", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadMercado(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate: true
          },
          {
            field: "marca",
            label: LanguageProvider({ id: "396", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "396", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadMarca(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate: true
          },
          {
            field: "pagaFlete",
            label: LanguageProvider({ id: "10855", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "10855", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadPagaFlete(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate: true
          },
          {
            field: "id",
            label: LanguageProvider({ id: "11055", alt: "x" }),
            type: "inputText",
            length: 10,
            disableOnUpdate: true,
            keyfilter:"pint"
          },
          {
            field: "descripcion",
            label: LanguageProvider({ id: "31", alt: "x" }),
            type: "inputText",
            length: 100,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default MantenerCentrosCostoGefco;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionTemplateConcesionario = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
