import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ResponsableCliente } from "src/models/ResponsableCliente";

const MantenerResponsablesCliente = () => {
  const SCREEN_CODE = "abm0014_mantener_responsables_cliente";
  const URL_BASE = "/mantener-responsables-cliente";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ResponsableCliente, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const responsableCliente = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          responsableCliente,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: ResponsableCliente, filterValues: any) {
    try {
      const responsableCliente = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        responsableCliente,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: ResponsableCliente, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        responsableCliente: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ResponsableCliente[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<ResponsableCliente> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          responsableCliente: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <GenericAbm<ResponsableCliente>
      screenCode={SCREEN_CODE}
      title={
        <LanguageProvider id="331" alt="Mantener Responsable del Clientes" />
      }
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      dataTableColumns={[
        {
          field: "id",
          header: <LanguageProvider id="6590" alt="Código" />,
        },
        {
          field: "descripcion",
          header: <LanguageProvider id="16246" alt="x" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: null,
        activo: true,
        origen: "",
        pais: null,
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          length: 3,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="16246" alt="x" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerResponsablesCliente;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
