import "./CierreContable.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import useFormattedDate from "../../../hooks/useFormattedDate";
import usePageViews from "../../../hooks/usePageViews";
import useRowStyles from "../../../hooks/useRowStyles";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function CierreContable() {
  const SCREEN_CODE = "fun0108_cierre_contable";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);
  const [selectedReportData, setSelectedReportData] = useState(null);

  const [validationDatosDialog, setValidationDatosDialog] = useState(false);
  const [validationHastaDialog, setValidationHastaDialog] = useState(false);
  const [validHastaActualDialog, setValidHastaActualDialog] = useState(false);

  const [loadingGuardarCambios, setLoadingGuardarCambios] = useState(false);
  const [guardarCambiosDialog, setGuardarCambiosDialog] = useState(false);
  const [confirmGuardarCDialog, setConfirmGuardarCDialog] = useState(false);

  const rowStyles = useRowStyles([selectedReportData]);
  const { formatDate, formatDateTime } = useFormattedDate();

  async function validationFields() {
    try {
      if (filter.tipoProceso === null) {
        setValidationDatosDialog(true);
        return;
      }
      if (filter.fechaDesde.getTime() >= filter.fechaHasta.getTime()) {
        setValidationHastaDialog(true);
        return;
      }
      if (filter.fechaHasta.getTime() >= new Date().getTime()) {
        setValidHastaActualDialog(true);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleGuardarCambiosClick = async () => {
    try {
      await validationFields();
      setGuardarCambiosDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleValidationClose = () => {
    setValidationDatosDialog(false);
    setValidationHastaDialog(false);
    setValidHastaActualDialog(false);
    setConfirmGuardarCDialog(false);
  };

  const handleGuardarCambios = (isConfirmed) => {
    setGuardarCambiosDialog(false);
    if (isConfirmed) {
      setGuardarCambiosDialog(true);
      aGuardarCambios();
    }
  };

  const [filter, setFilter] = useState({
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    tipoProceso: null,
  });

  const [cierre, setCierre] = useState(null);
  async function loadCierre(tipoProcesoId) {
    try {
      if (securityFilters?.securityValues?.country && tipoProcesoId !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/cierre-contable/fecha-cierre",
          {
            pais: { id: securityFilters.securityValues.country.id },
            cliente: { id: securityFilters.securityValues.client.id },
            cuenta: { id: securityFilters.securityValues.account.id },
            proceso: { id: tipoProcesoId },
          }
        );
        if (status === 200) {
          setCierre(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [loadingTipoProceso, setLoadingTipoProceso] = useState(false);
  const [tipoProceso, setTipoProceso] = useState(null);
  async function loadTipoProceso() {
    setLoadingTipoProceso(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/cierre-contable/proceso-cierre",
          {
            pais: { id: securityFilters.securityValues.country.id },
          }
        );

        if (status === 200) {
          const tipoProcesoData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setTipoProceso(tipoProcesoData);
          return tipoProcesoData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoProceso(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country && filter?.tipoProceso) {
        try {
          const cierres = await loadCierre(filter?.tipoProceso.id);

          if (cierres !== null) {
            let fechaCierreDesde = new Date(cierres?.date);

            if (
              fechaCierreDesde instanceof Date &&
              !isNaN(fechaCierreDesde.getTime())
            ) {
              fechaCierreDesde.setDate(fechaCierreDesde.getDate() + 1); // 1+ day

              let fechaCierreHasta = new Date(fechaCierreDesde);
              fechaCierreHasta.setDate(fechaCierreDesde.getDate() + 7); // 7 dias a mais do fechaCierraDesde

              setFilter((prevFilter) => ({
                ...prevFilter,
                fechaDesde: fechaCierreDesde,
                fechaHasta: fechaCierreHasta,
              }));
            } else {
              console.error(cierres?.date);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [filter?.tipoProceso]);

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const tipoProcesos = await loadTipoProceso();

          if (tipoProcesos?.length > 0) {
            setFilter((prevFilter) => ({
              ...prevFilter,
              tipoProceso: tipoProcesos[0],
            }));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/cierre-contable/cronograma-contable",
        {
          pais: { id: securityFilters.securityValues.country.id },
          cliente: { id: securityFilters.securityValues.client.id },
          cuenta: { id: securityFilters.securityValues.account.id },
          proceso: { id: filter?.tipoProceso.id },
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  async function aGuardarCambios() {
    setLoadingGuardarCambios(true);
    try {
      const filters = {
        pais: { id: securityFilters.securityValues.country.id },
        cliente: { id: securityFilters.securityValues.client.id },
        cuenta: { id: securityFilters.securityValues.account.id },
        proceso: { id: filter?.tipoProceso?.id },
        fechaDesde: filter.fechaDesde ? filter.fechaDesde.getTime() : null,
        fechaHasta: filter.fechaHasta ? filter.fechaHasta.getTime() : null,
      };

      const { status } = await sipcoAxiosService.post(
        "/cierre-contable/procesar-cierre",
        filters
      );
      if (status === 200) {
        setGuardarCambiosDialog(false);
        setConfirmGuardarCDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGuardarCambios(false);
    }
  }

  useEffect(() => {
    async function refreshData() {
      if (!confirmGuardarCDialog) {
        try {
          const tipoProcesos = await loadTipoProceso();

          if (tipoProcesos?.length > 0) {
            setFilter((prevFilter) => ({
              ...prevFilter,
              tipoProceso: tipoProcesos[0],
            }));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    refreshData();
  }, [confirmGuardarCDialog]);

  return (
    <div className="cierre-contable">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"6168"} alt="Datos Requeridos" />}
        >
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"25204"} alt="Fecha de Cierre Desde" />
                </label>
                <SipcoCalendar
                  value={filter.fechaDesde}
                  onChange={(e) => {
                    const date = new Date(e.value);
                    setFilter({ ...filter, fechaDesde: date });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={cierre}
                />
                <label>
                  <LanguageProvider id={"25205"} alt="Fecha de Cierre Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.fechaHasta}
                  onChange={(e) => {
                    const date = new Date(e.value);
                    setFilter({ ...filter, fechaHasta: date });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11678"} alt="Tipo de Proceso " />
                </label>
                <SipcoDropdown
                  value={filter.tipoProceso}
                  options={tipoProceso}
                  onChange={(e) =>
                    setFilter({ ...filter, tipoProceso: e.value })
                  }
                  optionLabel="label"
                  loading={loadingTipoProceso}
                  filter
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id={"11008"} alt="Cierre Contable" />
              </span>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="single"
          onSelectionChange={(e) => setSelectedReportData(e.value)}
          selection={selectedReportData}
        >
          <SipcoColumn
            field="subcuenta.descripcion"
            header={<LanguageProvider id="2892" alt="Playa" />}
          />
          <SipcoColumn
            field="concepto"
            header={<LanguageProvider id="2665" alt="Concepto" />}
          />
          <SipcoColumn
            field="marca.descripcion"
            header={<LanguageProvider id="396" alt="Marca" />}
          />
          <SipcoColumn
            field="origen.descripcion"
            header={<LanguageProvider id="2496" alt="Origen" />}
          />
          <SipcoColumn
            field="unidades"
            header={<LanguageProvider id="3758" alt="Unidades" />}
          />
          <SipcoColumn
            field="valor"
            header={<LanguageProvider id="10983" alt="Valor Total" />}
            sortable
            filter
            body={(rowData) =>
              Number(rowData.valor).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
              })
            }
          />
          <SipcoColumn
            field="fechaDesde.date"
            header={<LanguageProvider id="1070" alt="Fecha desde" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaDesde?.date
                ? formatDate(rowData.fechaDesde.date)
                : rowData.fechaDesde || ""
            }
          />
          <SipcoColumn
            field="fechaHasta.date"
            header={<LanguageProvider id="1133" alt="Fecha hasta" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaHasta?.date
                ? formatDate(rowData.fechaHasta.date)
                : rowData.fechaHasta || ""
            }
          />
          <SipcoColumn
            field="usuarioUltimaModificacion.id"
            header={
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            }
            sortable
            filter
            body={(rowData) =>
              rowData.usuarioUltimaModificacion?.id
                ? `(${rowData.usuarioUltimaModificacion.id})`
                : ""
            }
          />
          <SipcoColumn
            field="fechaUltimaModificacion.date"
            header={<LanguageProvider id="5271" alt="Fec. Últ. Modificación" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaUltimaModificacion?.date
                ? formatDateTime(rowData.fechaUltimaModificacion.date)
                : rowData.fechaUltimaModificacion || ""
            }
          />
        </SipcoDataTable>
      </Panel>
      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "14594",
            alt: "Guardar cambios",
          })}
          icon="pi pi-save"
          onClick={handleGuardarCambiosClick}
        />
        {/* Completar datos Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validationDatosDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p>
            <LanguageProvider id="10531" alt="Faltan completar datos" />
          </p>
        </Dialog>

        {/* Validation Fecha Hasta >= Fecha Desde Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validationHastaDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p>
            <LanguageProvider
              id="1182"
              alt="La Fecha Hasta no puede ser menor a la Fecha Desde."
            />
          </p>
        </Dialog>

        {/* Validation Fecha Hasta >= Date actual Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validHastaActualDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p>
            <LanguageProvider
              id="11015"
              alt="La fecha Hasta no puede ser mayor o igual a la fecha actual."
            />
          </p>
        </Dialog>

        {/* Guardar Cambios Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={guardarCambiosDialog}
          style={{ width: "15vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("10043", "Si")}
                icon="pi pi-check"
                onClick={() => handleGuardarCambios(true)}
                autoFocus
                disabled={loadingGuardarCambios}
              />
              <Button
                label={getLabel("10044", "No")}
                icon="pi pi-times"
                onClick={() => handleGuardarCambios(false)}
                className="p-button-text"
                disabled={loadingGuardarCambios}
              />
            </div>
          }
          onHide={() => setGuardarCambiosDialog(false)}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="13275"
              alt="¿Desea procesar los cambios realizados?"
            />
          </p>
          {loadingGuardarCambios && (
            <ProgressSpinner style={{ width: "50px", height: "50px" }} />
          )}
        </Dialog>

        {/* Sucess Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={confirmGuardarCDialog}
          style={{ width: "30vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="36"
              alt="Su transacción se realizó con éxito."
            />
          </p>
        </Dialog>
      </div>
    </div>
  );
}

const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
