import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { CuentasTaller } from "src/models/CuentasTaller";

const MantenerCuentasTaller = () => {
  const SCREEN_CODE = "abm0098_mantener_cuentas_taller";
  const URL_BASE = "/mantener-cuentas-taller";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: CuentasTaller, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const cuentaTaller = {
          ...model,
          pais: filterValues.securityValues.country,
          cuenta: filterValues.securityValues.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          cuentaTaller: cuentaTaller,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: CuentasTaller, filterValues: any) {
    try {
      const cuentaTaller = {
        ...model,
        pais: filterValues.securityValues.country,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        cuentaTaller: cuentaTaller,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: CuentasTaller) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        cuentaTaller: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<CuentasTaller[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cuenta: filter.securityValues.account,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<CuentasTaller> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          cuentaTaller: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <GenericAbm<CuentasTaller>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="2095" alt="Mantener Cuentas Taller" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          account: true,
        },
      }}
      dataTableColumns={[
        {
          field: "id",
          header: <LanguageProvider id="6590" alt="Código" />,
        },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="Descripcion" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        pais: null,
        cuenta: null,
        descripcion: "",
        activo: true,
      }}
      loadDropdownData={{}}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          length: 5,
          required: true,
          capitalizeInputText: true,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripcion" />,
          type: "inputText",
          required: true,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerCuentasTaller;
