import { Concesionario } from "src/models/Concesionario";
import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerConcesionarios = () => {
  const SCREEN_CODE = "abm0029_mantener_concesionarios";
  const URL_BASE = "/mantener-concesionarios";
  const SCREEN_TITLE = (
    <LanguageProvider id="259" alt="Mantener Concesionarios" />
  );
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: Concesionario, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client
      ) {
        const concesionario = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          fechaBaja: { date: model.fechaBaja },
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          concesionario,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: Concesionario, filterValues: any) {
    try {
      const concesionario = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        marca: filterValues.marca,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        concesionario,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Concesionario, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        concesionario: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Concesionario[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          descripcionConcesionario: filter.descripcionConcesionario,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<Concesionario> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          concesionario: model,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }
  async function loadPaises(filter: any): Promise<Concesionario[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/pais",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadProvincias(filter: any): Promise<any[]> {
    try {
      if (filter?.paisConcesionario) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/provincia",
          {
            pais: filter?.paisConcesionario,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadCiudades(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.client &&
        filter?.provincia
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/ciudad",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            provincia: filter?.provincia,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadLocalidades(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.client &&
        filter?.ciudad
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/localidad",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            ciudad: filter?.ciudad,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadClientes(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/cliente",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<Concesionario>
      screenCode={SCREEN_CODE}
      title={SCREEN_TITLE}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      filterSettings={[
        {
          filter: "descripcionConcesionario",
          label: LanguageProvider({
            id: "gen.dealershipCode.label",
            alt: "Cod. Concesionarios",
          }),
          type: "inputText",
        },
      ]}
      defaultValues={{
        id: "",
        activo: true,
        cliente: null,
        pais: null,
        direccion: "",
        paisConcesionario: "",
        provincia: null,
        ciudad: null,
        codigoPostal: "",
        telefono: "",
        email: "",
        contacto: "",
        fechaBaja: null,
        localidad: null,
        cuit: "",
        clienteConcesionario: null,
        cupoSolicitud: 0,
        giro: "",
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Código" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="x" />,
        },
        {
          field: "direccion",
          header: <LanguageProvider id="3069" alt="Dirección" />,
        },
        {
          field: "localidad.descripcion",
          header: <LanguageProvider id="3186" alt="Localidad" />,
        },
        {
          field: "ciudad.descripcion",
          header: <LanguageProvider id="164" alt="Ciudad" />,
        },
        {
          field: "provincia.descripcion",
          header: <LanguageProvider id="159" alt="Provincia" />,
        },
        {
          field: "paisConcesionario.descripcion",
          header: <LanguageProvider id="154" alt="País" />,
        },
        {
          field: "codigoPostal",
          header: <LanguageProvider id="7511" alt="C. Postal" />,
        },
        {
          field: "telefono",
          header: <LanguageProvider id="3071" alt="Teléfono" />,
        },
        { field: "email", header: <LanguageProvider id="10415" alt="Email" /> },
        {
          field: "contacto",
          header: <LanguageProvider id="3600" alt="Contacto" />,
        },
        { field: "cuit", header: <LanguageProvider id="11178" alt="CUIT" /> },
        {
          field: "fechaBaja.date",
          header: <LanguageProvider id="6197" alt="Fecha Baja" />,
          body: (rowData: any) => (
            <span>{new Date(rowData.fechaBaja.date).toLocaleString()}</span>
          ),
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
        },
        {
          field: "clienteConcesionario.descripcion",
          header: <LanguageProvider id="16307" alt="Cliente concesionario" />,
        },
        {
          field: "cupoSolicitud",
          header: <LanguageProvider id="17006" alt="Cupo Solicitudes" />,
        },
        {
          field: "giro",
          header: <LanguageProvider id="25105" alt="Giro I.I.S." />,
        },
      ]}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          length: 10,
          disableOnUpdate: true,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descrição" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "direccion",
          label: <LanguageProvider id="3069" alt="Registros do Periodo" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "paisConcesionario",
          label: LanguageProvider({
            id: "154",
            alt: "País",
          }),
          dropDownPlaceholder: LanguageProvider({
            id: "154",
            alt: "País",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadPaises(filter),
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          field: "provincia",
          label: LanguageProvider({ id: "159", alt: "Provincia" }),
          dropDownPlaceholder: LanguageProvider({
            id: "159",
            alt: "Provincia",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadProvincias(filter),
          dependency: ["paisConcesionario"],
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          field: "ciudad",
          label: LanguageProvider({ id: "164", alt: "Ciudad" }),
          dropDownPlaceholder: LanguageProvider({ id: "164", alt: "Ciudad" }),
          type: "dropdown",
          dataSource: (filter: any) => loadCiudades(filter),
          dependency: ["provincia"],
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          field: "localidad",
          label: LanguageProvider({ id: "3186", alt: "Localidad" }),
          dropDownPlaceholder: LanguageProvider({
            id: "3186",
            alt: "Localidad",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadLocalidades(filter),
          dependency: ["ciudad"],
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          field: "cupoSolicitud",
          label: <LanguageProvider id="17006" alt="x" />,
          type: "inputNumber",
          length: 5,
          disableOnUpdate: true,
        },
        {
          field: "codigoPostal",
          label: <LanguageProvider id="7511" alt="x" />,
          type: "inputText",
          length: 8,
        },
        {
          field: "telefono",
          label: <LanguageProvider id="3071" alt="x" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "email",
          label: <LanguageProvider id="10415" alt="x" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "contacto",
          label: <LanguageProvider id="3600" alt="x" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "cuit",
          label: <LanguageProvider id="11178" alt="x" />,
          type: "inputText",
          length: 14,
        },
        {
          field: "fechaBaja",
          label: <LanguageProvider id="6197" alt="x" />,
          type: "calendar",
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="x" />,
          type: "checkbox",
        },
        {
          field: "clienteConcesionario",
          label: LanguageProvider({
            id: "16307",
            alt: "Cliente concesionario",
          }),
          dropDownPlaceholder: LanguageProvider({
            id: "16307",
            alt: "Cliente concesionario",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadClientes(filter),
          virtualizeDropdown: true,
          dropDownTemplate: DropdownClienteConcecionarioTemplate,
        },
        {
          field: "giro",
          label: <LanguageProvider id="25105" alt="x" />,
          type: "inputText",
          length: 100,
        },
      ]}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
    />
  );
};

export default MantenerConcesionarios;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};

const DropdownClienteConcecionarioTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
