import "./MigracionClientes.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import { useInactivoColor } from "../../../hooks/useInactivoColor";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function MigracionClientes() {
  const SCREEN_CODE = "fun0099_migracion_clientes";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);
  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);
  const [selectedReportData, setSelectedReportData] = useState(null);
  const [isFieldDisabled, setIsFieldDisabled] = useState(true);
  const [filteredReporte, setFilteredReporte] = useState(null);
  const [loadingProcesar, setLoadingProcesar] = useState(false);
  const [procesarDialog, setProcesarDialog] = useState(false);
  const [loadingBorrar, setLoadingBorrar] = useState(false);
  const [borrarDialog, setBorrarDialog] = useState(false);
  const [confirmProcessDialog, setConfirmProcessDialog] = useState(false);

  const inactivoColor = useInactivoColor();

  const enableElements = () => {
    try {
      setIsFieldDisabled(false);
      setModel((prevModel) => ({
        ...prevModel,
        chkDaniosChange: true,
        chkMaestrosChange: true,
        chkRadiosChange: true,
        chkMantenimientoChange: true,
        chkTalleresChange: true,
        chkConcesionariosChange: true,
        chkContableChange: true,
        chkOperacionesChange: true,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const getRowStyles = (data) => {
    let className = "";

    if (
      selectedReportData &&
      JSON.stringify(selectedReportData) === JSON.stringify(data)
    ) {
      className = "highlight-row";
    }

    if (data.tipoDeRegistro === "X") {
      className += " x-row ";
    }

    return className.trim();
  };

  const btnProcesarClick = () => {
    try {
      setProcesarDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleProcesar = (isConfirmed) => {
    try {
      setProcesarDialog(false);
      if (isConfirmed) {
        aProcesar();
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function aProcesar() {
    setLoadingProcesar(true);
    try {
      const filters = {
        pais: { id: securityFilters.securityValues.country.id || null },
        cuenta: { id: securityFilters.securityValues.account.id || null },
        clienteNuevo: { id: model.clienteNuevo.id || null },
        cliente: { id: model.copiarDesde.id || null },
        options: [
          model.chkDaniosChange,
          model.chkMaestrosChange,
          model.chkRadiosChange,
          model.chkMantenimientoChange,
          model.chkTalleresChange,
          model.chkConcesionariosChange,
          model.chkContableChange,
          model.chkOperacionesChange,
        ],
      };

      const { status } = await sipcoAxiosService.post(
        "/migracion_clientes/migrar",
        filters
      );

      if (status === 200) {
        setConfirmProcessDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcesar(false);
    }
  }

  const btnBorrarClick = () => {
    try {
      setBorrarDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleBorrar = (isConfirmed) => {
    try {
      setBorrarDialog(false);
      if (isConfirmed) {
        aBorrar();
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function aBorrar() {
    setLoadingBorrar(true);
    try {
      const filters = {
        pais: { id: securityFilters.securityValues.country.id || null },
        cuenta: { id: securityFilters.securityValues.account.id || null },
        clienteNuevo: { id: model.clienteNuevo.id || null },
        cliente: { id: model.copiarDesde.id || null },
      };

      const { status } = await sipcoAxiosService.post(
        "/migracion_clientes/borrar",
        filters
      );

      if (status === 200) {
        setConfirmProcessDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBorrar(false);
    }
  }

  const handleConfirmationClose = () => {
    try {
      setConfirmProcessDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [model, setModel] = useState({
    clienteNuevo: null,
    copiarDesde: null,
    chkDaniosChange: false,
    chkMaestrosChange: false,
    chkRadiosChange: false,
    chkMantenimientoChange: false,
    chkTalleresChange: false,
    chkConcesionariosChange: false,
    chkContableChange: false,
    chkOperacionesChange: false,
  });

  const [loadingNuevoCliente, setLoadingNuevoCliente] = useState(false);
  const [nuevoCliente, setNuevoCliente] = useState([]);
  async function loadNuevoCliente() {
    setLoadingNuevoCliente(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/migracion_clientes/cliente",
          {}
        );

        if (status === 200) {
          setNuevoCliente(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNuevoCliente(false);
    }
    setNuevoCliente([]);
    return [];
  }

  const [loadingCopiarDesde, setLoadingCopiarDesde] = useState(false);
  const [copiarDesde, setCopiarDesde] = useState([]);
  async function loadCopiarDesde() {
    setLoadingCopiarDesde(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/migracion_clientes/cliente-desde",
          {}
        );

        if (status === 200) {
          setCopiarDesde(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCopiarDesde(false);
    }
    setCopiarDesde([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (isFieldDisabled) {
        try {
          const nuevosClientes = await loadNuevoCliente();
          const copiarDesdes = await loadCopiarDesde();

          setModel((prevModel) => ({
            ...prevModel,
            clienteNuevo: nuevosClientes?.length > 0 ? nuevosClientes[0] : null,
            copiarDesde: copiarDesdes?.length > 0 ? copiarDesdes[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  useEffect(() => {
    try {
      let filteredData = reporte || [];

      const filtros = [];
      if (model.chkDaniosChange) filtros.push("2");
      if (model.chkMaestrosChange) filtros.push("1");
      if (model.chkRadiosChange) filtros.push("3");
      if (model.chkMantenimientoChange) filtros.push("7");
      if (model.chkTalleresChange) filtros.push("6");
      if (model.chkConcesionariosChange) filtros.push("4");
      if (model.chkContableChange) filtros.push("5");
      if (model.chkOperacionesChange) filtros.push("8");

      if (filtros.length === 0) {
        filteredData = [];
      } else {
        filteredData = filteredData.filter((item) =>
          filtros.includes(item.descripcion)
        );
      }

      setFilteredReporte(filteredData);
    } catch (error) {
      console.error(error);
    }
  }, [
    model.chkDaniosChange,
    model.chkMaestrosChange,
    model.chkRadiosChange,
    model.chkMantenimientoChange,
    model.chkTalleresChange,
    model.chkConcesionariosChange,
    model.chkContableChange,
    model.chkOperacionesChange,
    reporte,
  ]);

  async function loadReportData() {
    enableElements();
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/migracion_clientes/tipo_migracion",
        {
          pais: securityFilters?.securityValues?.country,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  return (
    <div className="migracion-clientes">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          screenCode: SCREEN_CODE,
        }}
      />
      <Panel header={<LanguageProvider id="10176" alt="Migracion Clientes" />}>
        <div className="panel-container">
          <Panel
            header={
              <LanguageProvider
                id="16286"
                alt="Seleccione los tipos a migrar"
              />
            }
            className="left-panel"
          >
            <div className="form">
              <div className="form-row">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"4567"} alt="Nuevo Cliente" />
                  </label>
                  <SipcoDropdown
                    value={model.clienteNuevo}
                    options={nuevoCliente}
                    onChange={(e) =>
                      setModel({ ...model, clienteNuevo: e.value })
                    }
                    optionLabel="descripcionFull"
                    itemTemplate={inactivoColor}
                    loading={loadingNuevoCliente}
                    disabled={isFieldDisabled}
                    filter
                    virtualScrollerOptions={{ itemSize: 10 }}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"126"} alt="Copiar desde" />
                  </label>
                  <SipcoDropdown
                    value={model.copiarDesde}
                    options={copiarDesde}
                    onChange={(e) =>
                      setModel({ ...model, copiarDesde: e.value })
                    }
                    optionLabel="descripcionFull"
                    itemTemplate={inactivoColor}
                    loading={loadingCopiarDesde}
                    disabled={isFieldDisabled}
                    filter
                    virtualScrollerOptions={{ itemSize: 10 }}
                  />
                </div>
              </div>
              <div className="checkbox-row">
                <label>
                  <LanguageProvider
                    id={"10193"}
                    alt="Copiar Parametros Danios - Calidad"
                  />
                </label>
                <Checkbox
                  checked={model.chkDaniosChange}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      chkDaniosChange: !model.chkDaniosChange,
                    });
                  }}
                  disabled={isFieldDisabled}
                />
              </div>
              <div className="checkbox-row">
                <label>
                  <LanguageProvider
                    id={"10191"}
                    alt="Copiar Parametros de Maestros del VIN (Si no los copia se generarán con datos Básicos)"
                  />
                </label>
                <Checkbox
                  checked={model.chkMaestrosChange}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      chkMaestrosChange: !model.chkMaestrosChange,
                    });
                  }}
                  disabled={isFieldDisabled}
                />
              </div>
              <div className="checkbox-row">
                <label>
                  <LanguageProvider
                    id={"10187"}
                    alt="Copiar parametros de Radios"
                  />
                </label>
                <Checkbox
                  checked={model.chkRadiosChange}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      chkRadiosChange: !model.chkRadiosChange,
                    });
                  }}
                  disabled={isFieldDisabled}
                />
              </div>
              <div className="checkbox-row">
                <label>
                  <LanguageProvider
                    id={"10186"}
                    alt="Copiar Parametros de Tareas de Mantenimiento"
                  />
                </label>
                <Checkbox
                  checked={model.chkMantenimientoChange}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      chkMantenimientoChange: !model.chkMantenimientoChange,
                    });
                  }}
                  disabled={isFieldDisabled}
                />
              </div>
              <div className="checkbox-row">
                <label>
                  <LanguageProvider
                    id={"10184"}
                    alt="Copiar Parametros Talleres"
                  />
                </label>
                <Checkbox
                  checked={model.chkTalleresChange}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      chkTalleresChange: !model.chkTalleresChange,
                    });
                  }}
                  disabled={isFieldDisabled}
                />
              </div>
              <div className="checkbox-row">
                <label>
                  <LanguageProvider
                    id={"10178"}
                    alt="Copiar Parametros Concesionarios"
                  />
                </label>
                <Checkbox
                  checked={model.chkConcesionariosChange}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      chkConcesionariosChange: !model.chkConcesionariosChange,
                    });
                  }}
                  disabled={isFieldDisabled}
                />
              </div>
              <div className="checkbox-row">
                <label>
                  <LanguageProvider
                    id={"10177"}
                    alt="Copiar Parametros contables con Cliente"
                  />
                </label>
                <Checkbox
                  checked={model.chkContableChange}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      chkContableChange: !model.chkContableChange,
                    });
                  }}
                  disabled={isFieldDisabled}
                />
              </div>
              <div className="checkbox-row">
                <label>
                  <LanguageProvider
                    id={"19046"}
                    alt="Copiar Parametros Operaciones"
                  />
                </label>
                <Checkbox
                  checked={model.chkOperacionesChange}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      chkOperacionesChange: !model.chkOperacionesChange,
                    });
                  }}
                  disabled={isFieldDisabled}
                />
              </div>
            </div>
          </Panel>

          <Panel
            header={<LanguageProvider id="1317" alt="Tipo" />}
            className="right-panel"
          >
            <SipcoDataTable
              size={"small"}
              showGridlines
              stripedRows
              ref={dt}
              value={filteredReporte}
              paginator
              scrollHeight="flex"
              rows={50}
              rowsPerPageOptions={[5, 10, 25, 50]}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              resizableColumns
              removableSort
              rowClassName={getRowStyles}
              filterDisplay="menu"
              loading={loadingReportData}
              selectionMode="single"
              selection={selectedReportData}
              onSelectionChange={(e) => setSelectedReportData(e.value)}
            >
              <SipcoColumn field="descripcion2" />
            </SipcoDataTable>
          </Panel>
        </div>
      </Panel>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "16283",
            alt: "Procesar",
          })}
          icon="pi pi-save"
          onClick={btnProcesarClick}
        />
        <Button
          label={LanguageProvider({
            id: "21880",
            alt: "Borrar",
          })}
          icon="pi pi-times"
          onClick={btnBorrarClick}
        />
      </div>

      <div className="dialogs">
        {/* Procesar Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={procesarDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("10043", "Si")}
                icon="pi pi-check"
                onClick={() => handleProcesar(true)}
                autoFocus
                disabled={loadingProcesar}
              />
              <Button
                label={getLabel("10044", "No")}
                icon="pi pi-times"
                onClick={() => handleProcesar(false)}
                className="p-button-text"
                disabled={loadingProcesar}
              />
            </div>
          }
          onHide={() => setProcesarDialog(false)}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="13275"
              alt="¿Desea procesar los cambios realizados?"
            />
          </p>
          {loadingProcesar && (
            <ProgressSpinner style={{ width: "50px", height: "50px" }} />
          )}
        </Dialog>

        {/* Borrar Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={borrarDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("10043", "Si")}
                icon="pi pi-check"
                onClick={() => handleBorrar(true)}
                autoFocus
                disabled={loadingBorrar}
              />
              <Button
                label={getLabel("10044", "No")}
                icon="pi pi-times"
                onClick={() => handleBorrar(false)}
                className="p-button-text"
                disabled={loadingBorrar}
              />
            </div>
          }
          onHide={() => setBorrarDialog(false)}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="10194"
              alt="Va a borrar todos los registros del Cliente :"
            />
          </p>
          {loadingProcesar && (
            <ProgressSpinner style={{ width: "50px", height: "50px" }} />
          )}
        </Dialog>

        {/* Confirm Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={confirmProcessDialog}
          style={{ width: "5vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="36"
              alt="Su transacción se realizó con éxito."
            />
          </p>
        </Dialog>
      </div>
    </div>
  );
}
const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
