import { InputNumber, InputNumberProps } from "primereact/inputnumber";

import React from "react";

type SipcoInputNumberProps = InputNumberProps & {};

const SipcoInputNumber = React.forwardRef<
  HTMLInputElement,
  SipcoInputNumberProps
>(({ ...props }, ref) => {
  return <InputNumber className="sipco-input-number" {...props} />;
});

export default SipcoInputNumber;
