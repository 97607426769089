import "./TransaccionesUsuarios.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import SipcoDropdown from "@shared/components/sipco-dropdown";

export function TransaccionesUsuarios() {
  const SCREEN_CODE = "con0033_transacciones_usuarios";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);

  const TODOS_OBJ = {
    id: "TODOS",
    label: "TODOS",
  };

  const filterColumnsId = [
    3750, 448, 4791, 14288, 4589, 1491, 396, 2139, 990, 13706, 5271,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    subCuentas: [],
    transacciones: [],
    fechaInicio: new Date(),
    fechaFinal: new Date(),
    usuario: TODOS_OBJ,
  });

  const [loadingSubCuenta, setLoadingSubCuenta] = useState(true);
  const [subCuenta, setSubCuenta] = useState([]);
  async function loadSubCuenta() {
    setLoadingSubCuenta(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/transacciones-usuarios/combo/subcuenta",
          {
            params: {
              codCuenta: securityFilters.securityValues.account.id,
            },
          }
        );
        if (status === 200) {
          setSubCuenta(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSubCuenta(false);
    }
    return [];
  }

  const [loadingTransacciones, setLoadingTransacciones] = useState(true);
  const [transacciones, setTransacciones] = useState([]);
  async function loadTransacciones() {
    setLoadingTransacciones(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/transacciones-usuarios/combo/transaccion",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setTransacciones(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransacciones(false);
    }
    return [];
  }

  const [loadingUsuario, setLoadingUsuario] = useState(false);
  const [usuario, setUsuario] = useState([]);
  async function loadUsuario() {
    setLoadingUsuario(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/transacciones-usuarios/combo/usuario",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          const usuarioData = data.map((x) => ({
            ...x,
            label: `${x.label} - (${x.id})`,
          }));
          usuarioData.unshift({ id: "TODOS", label: "TODOS" });

          setUsuario(usuarioData);
          return usuarioData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUsuario(false);
    }
    return [];
  }

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);
  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        subCuentas: filter?.subCuentas?.map((x) => x.id) || [],
        transacciones: filter?.transacciones?.map((x) => x.id) || [],
        rangeFecha: [filter?.fechaInicio, filter?.fechaFinal],
        usuario: filter?.usuario?.id || null,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/transacciones-usuarios/report",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const [subCuentas, transaccioness, usuarios] = await Promise.all([
            loadSubCuenta(),
            loadTransacciones(),
            loadUsuario(),
          ]);

          setFilter((prevFilter) => ({
            ...prevFilter,
            subCuentas,
            transacciones: transaccioness,
            usuario: usuarios[0],
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues.country]);

  const data = convertDateObjects(reporte?.filas);

  return (
    <div className="transacciones-por-usuario">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="4791" alt="Subcuenta" />
                </label>
                <SipcoMultiSelect
                  value={filter.subCuentas}
                  options={subCuenta}
                  onChange={(e) => {
                    setFilter({ ...filter, subCuentas: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingSubCuenta}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="466" alt="Transacciones" />
                </label>
                <SipcoMultiSelect
                  value={filter.transacciones}
                  options={transacciones}
                  onChange={(e) => {
                    setFilter({ ...filter, transacciones: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingTransacciones}
                  showClear
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="2621" alt="Desde" />
                </label>
                <SipcoCalendar
                  value={filter.fechaInicio}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaInicio: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="2622" alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.fechaFinal}
                  onChange={(e) => {
                    setFilter({ ...filter, fechaFinal: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="1" alt="Usuário" />
                </label>
                <SipcoDropdown
                  value={filter.usuario}
                  options={usuario}
                  onChange={(e) => setFilter({ ...filter, usuario: e.value })}
                  optionLabel="label"
                  filter
                  loading={loadingUsuario}
                  virtualScrollerOptions={{ itemSize: 20 }}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {
                  <LanguageProvider
                    id="2050"
                    alt="Consulta de Transacciones por Usuario"
                  />
                }
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta de Transacciones por Usuario"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          loading={loadingReportData}
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <SipcoColumn
            field="0"
            header={<LanguageProvider id="3750" alt="Cliente" />}
            sortable
            filter
          />
          <SipcoColumn
            field="1"
            header={<LanguageProvider id="448" alt="Cuenta" />}
            sortable
            filter
          />
          <SipcoColumn
            field="2"
            header={<LanguageProvider id="4791" alt="Subcuenta" />}
          />
          <SipcoColumn
            field="3"
            header={<LanguageProvider id="14288" alt="Transacción" />}
            sortable
            filter
          />
          <SipcoColumn
            field="4"
            header={<LanguageProvider id="4589" alt="Fecha Movimiento" />}
            sortable
            filter
          />
          <SipcoColumn
            field="5"
            header={<LanguageProvider id="1491" alt="VIN" />}
          />
          <SipcoColumn
            field="6"
            header={<LanguageProvider id="396" alt="Marca" />}
            sortable
            filter
          />
          <SipcoColumn
            field="7"
            header={<LanguageProvider id="2139" alt="Modelo" />}
            sortable
            filter
          />
          <SipcoColumn
            field="8"
            header={<LanguageProvider id="990" alt="Calle" />}
            sortable
            filter
          />
          <SipcoColumn
            field="9"
            header={
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            }
            sortable
            filter
          />
          <SipcoColumn
            field="10"
            header={<LanguageProvider id="5271" alt="Fec. Últ. Modificación" />}
            sortable
            filter
          />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default TransaccionesUsuarios;
