import { Ciudad } from "src/models/Ciudad";
import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerCiudades = () => {
  const SCREEN_CODE = "abm0003_mantener_ciudades";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: Ciudad, filterValues: any) {
    try {
      const ciudad = { ...model, provincia: filterValues?.provincia };
      await sipcoAxiosService.post("/mantener-ciudades/save", ciudad);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: Ciudad) {
    try {
      await sipcoAxiosService.post("/mantener-ciudades/update", model);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Ciudad) {
    try {
      await sipcoAxiosService.post("/mantener-ciudades/remove", model);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Ciudad[]> {
    try {
      if (filter?.provincia) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-ciudades/findBy",
          filter?.provincia
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(filter: any): Promise<Ciudad> {
    try {
      if (filter?.provincia) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-ciudades/findBy",
          filter?.provincia
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }
  async function loadProvincias(filter: any): Promise<any[]> {
    try {
      if (filter?.pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-ciudades/filter/provincia",
          filter?.pais
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadPaises(): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantener-ciudades/filter/pais"
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<Ciudad>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="24151" alt="Mantener Ciudades" />}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: 0,
        idAduana: null,
        idAgenciaFrancia: null,
        idCiudadFrancia: null,
        idPuerto: null,
        porcentaje: null,
        provincia: null,
        siglaTransporte: null,
      }}
      filterSettings={[
        {
          filter: "pais",
          label: LanguageProvider({ id: "23960", alt: "País" }),
          placeholder: LanguageProvider({ id: "23960", alt: "País" }),
          type: "dropdown",
          dataSource: loadPaises,
          dependency: null,
          required: true,
        },
        {
          filter: "provincia",
          label: LanguageProvider({ id: "24020", alt: "Provincia" }),
          placeholder: LanguageProvider({ id: "24020", alt: "Provincia" }),
          type: "dropdown",
          dataSource: loadProvincias,
          dependency: ["pais"],
          required: true,
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Codigo" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="31" alt="Descripcion" />,
        },
        {
          field: "alicuotaIss",
          header: <LanguageProvider id="10851" alt="Alicuota ISS" />,
        },
        {
          field: "porcentaje",
          header: <LanguageProvider id="4657" alt="Porcentaje" />,
        },
        {
          field: "idCiudadFrancia",
          header: <LanguageProvider id="16235" alt="Cod. Ciudad Francia" />,
        },
        {
          field: "idAgenciaFrancia",
          header: <LanguageProvider id="16585" alt="Cod. Agencia Francia" />,
        },
        {
          field: "idAduana",
          header: <LanguageProvider id="16236" alt="Cod. Aduana" />,
        },
        {
          field: "siglaTransporte",
          header: <LanguageProvider id="16237" alt="Sigla Transporte" />,
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuário Ult. Modificacion" />
          ),
        },
        {
          field: "fechaUltimaModificacion.timestamp",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modifcicacion" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "idPuerto",
          header: <LanguageProvider id="14175" alt="Cod. Puerto" />,
        },
      ]}
      formSettings={[
        {
          field: "id",
          required: true,
          label: <LanguageProvider id="6590" alt="Codigo" />,
          type: "inputText",
          disabled: true,
        },
        {
          field: "descripcion",
          required: true,
          label: <LanguageProvider id="31" alt="Descripcion" />,
          type: "textArea",
        },
        {
          field: "alicuotaIss",
          label: <LanguageProvider id="10851" alt="Alicuota ISS" />,
          type: "inputNumber",
          min: 0,
          max: 999,
        },
        {
          field: "idPuerto",
          label: <LanguageProvider id="14175" alt="Cod. Puerto" />,
          type: "inputText",
        },
        {
          field: "porcentaje",
          label: <LanguageProvider id="4657" alt="Porcentaje" />,
          type: "inputNumber",
          min: 0,
          max: 999,
        },
        {
          field: "idCiudadFrancia",
          label: <LanguageProvider id="16235" alt="Cod. Ciudad Francia" />,
          type: "inputText",
          length: 9,
        },
        {
          field: "idAgenciaFrancia",
          label: <LanguageProvider id="16585" alt="Cod. Agencia Francia" />,
          type: "inputText",
          length: 3,
        },
        {
          field: "idAduana",
          label: <LanguageProvider id="16236" alt="Cod. Aduana" />,
          type: "inputText",
          length: 10,
        },
        {
          field: "siglaTransporte",
          label: <LanguageProvider id="16237" alt="Sigla Transporte" />,
          type: "inputText",
          length: 2,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
    />
  );
};

export default MantenerCiudades;
