import GenericAbm from "@shared/components/genericAbm/generic-abm";
import React, { useRef } from "react";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ParametroNaviera } from "src/models/ParametroNaviera";

const MantenerParametrosNavieras = () => {
  const SCREEN_CODE = "abm0115_mantener_parametros_navieras";
  const URL_BASE = "/mantener-parametros-navieras";
  const SCREEN_TITLE = (
    <LanguageProvider id="5829" alt="Mantener Parámetros Navieras" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: ParametroNaviera, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.account &&
        filterValues?.securityValues?.client
      ) {
        const parametroNaviera = {
          ...model,
          clienteRecuepera: model.recuperaComision ? model.clienteRecuepera : null,
          pais: filterValues.securityValues.country,
          cliente: filterValues?.securityValues?.client,
          cuenta: filterValues?.securityValues?.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          parametroNaviera,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ParametroNaviera, filterValues: any) {
    try {
      const parametroNaviera = {
        ...model,
        clienteRecuepera: model.recuperaComision ? model.clienteRecuepera : null,
        pais: filterValues.securityValues.country,
        cliente: filterValues?.securityValues?.client,
        cuenta: filterValues?.securityValues?.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        parametroNaviera,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: ParametroNaviera, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        parametroNaviera: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ParametroNaviera[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.account &&
      filter?.securityValues?.client
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,
          }
        );
        if (status === 200)
          if (data.length > 0) {
            return data;
          } else {
            return [];
          }
      } catch (error) {
        console.error(error);
      }
    }

    return [];
  }
  async function handleGet(model: any): Promise<ParametroNaviera> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          parametroNaviera: { ...model },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadProveedores(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.account &&
      filter?.securityValues?.client
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/proveedor",
          {
            pais: filter.securityValues.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadMovimientos(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.account &&
      filter?.securityValues?.client
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/movimentos-estadia",
          { pais: filter.securityValues.country }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadCiudades(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.account &&
      filter?.securityValues?.client
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/ciudad",
          {}
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadClientes(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.account &&
      filter?.securityValues?.client
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/cliente",
          {}
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ParametroNaviera>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            account: true,
            client: true,
          },
        }}
        // filterSettings={}
        dataTableColumns={[
          {
            field: "proveedor.descripcion",
            header: <LanguageProvider id="2521" alt="x" />,
          },
          {
            field: "ciudad.descripcion",
            header: <LanguageProvider id="164" alt="x" />,
          },
          {
            field: "movimiento.descripcion",
            header: <LanguageProvider id="2639" alt="x" />,
          },
          {
            field: "compraBaf",
            header: <LanguageProvider id="16380" alt="x" />,
            body: (rowData: any) => (rowData.compraBaf ? "SI" : "NO"),
          },
          {
            field: "ventaBaf",
            header: <LanguageProvider id="16381" alt="x" />,
            body: (rowData: any) => (rowData.ventaBaf ? "SI" : "NO"),
          },
          {
            field: "compraCaf",
            header: <LanguageProvider id="16382" alt="x" />,
            body: (rowData: any) => (rowData.compraCaf ? "SI" : "NO"),
          },
          {
            field: "ventaCaf",
            header: <LanguageProvider id="16383" alt="x" />,
            body: (rowData: any) => (rowData.ventaCaf ? "SI" : "NO"),
          },
          {
            field: "recuperaComision",
            header: <LanguageProvider id="16385" alt="x" />,
            body: (rowData: any) => (rowData.recuperaComision ? "SI" : "NO"),
          },
          {
            field: "clienteRecuepera",
            header: <LanguageProvider id="16384" alt="x" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          activo: true,
          pais: null,
          cliente: null,
          cuenta: null,
          proveedor: null,
          movimiento: null,
          ciudad: null,
          ventaBaf: false,
          compraBaf: false,
          compraCaf: false,
          ventaCaf: false,
          recuperaComision: false,
          clienteRecuepera: null,
        }}
        formSettings={[
          {
            field: "proveedor",
            label: LanguageProvider({ id: "2521", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "2521", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadProveedores(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate: true
          },
          {
            field: "movimiento",
            label: LanguageProvider({ id: "2639", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "2639", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadMovimientos(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate: true
          },
          {
            field: "ciudad",
            label: LanguageProvider({ id: "164", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "164", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadCiudades(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate: true
          },
          {
            field: "compraBaf",
            label: LanguageProvider({ id: "16380", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "compraCaf",
            label: LanguageProvider({ id: "16382", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "ventaBaf",
            label: LanguageProvider({ id: "16381", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "ventaCaf",
            label: LanguageProvider({ id: "16383", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "recuperaComision",
            label: LanguageProvider({ id: "16385", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "clienteRecuepera",
            label: LanguageProvider({ id: "16384", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "16384", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadClientes(filter),
            dropDownTemplate: DropdownOptionTemplate,
            virtualizeDropdown: true
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default MantenerParametrosNavieras;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
