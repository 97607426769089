import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useState } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ConcesionariosTipoDespacho } from "src/models/ConcesionariosTipoDespacho";

const MantenerConcesionariosTipoDespacho = () => {
  const SCREEN_CODE = "abm0044_mantener_concesionarios_tipo_despacho";
  const URL_BASE = "/mantener-concesionarios-tipo-despacho";
  const sipcoAxiosService = useSipcoAxiosService();
  const [dirEntrega, setDirEntrega] = useState(null);

  async function handleSave(
    model: ConcesionariosTipoDespacho,
    filterValues: any
  ) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client
      ) {
        await sipcoAxiosService.post(URL_BASE + "/save", {
          concesionarioTipoDespacho: { ...model, direccionEntrega: dirEntrega },
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: ConcesionariosTipoDespacho) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/update", {
        concesionarioTipoDespacho: { ...model, direccionEntrega: dirEntrega },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: ConcesionariosTipoDespacho) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        concesionarioTipoDespacho: { ...model, direccionEntrega: dirEntrega },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(
    filter: any
  ): Promise<ConcesionariosTipoDespacho[]> {
    try {
      setDirEntrega(filter?.direccionEntrega);

      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter?.securityValues?.country,
          cliente: filter?.securityValues?.client,
          concesionario: filter?.concesionario,
          direccionEntrega: filter?.direccionEntrega,
        }
      );

      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<ConcesionariosTipoDespacho> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          concesionarioTipoDespacho: { ...model, direccionEntrega: dirEntrega },
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadConcesionario(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/concesionario",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadDireccionEntrega(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.client &&
        filter?.concesionario
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/direccion-entrega",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            concesionario: filter?.concesionario,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadTipoDespacho(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/tipo-despacho",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadDespachoTransportista(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/tipo-despacho",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<ConcesionariosTipoDespacho>
      screenCode={SCREEN_CODE}
      title={
        <LanguageProvider
          id="509"
          alt="Mantener Tipo de Despacho a Bocas de Entrega"
        />
      }
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        cuenta: null,
        concesionario: null,
        direccionEntrega: null,
        tipoDespacho: null,
        tipoDespachoTranporte: null,
        activo: true,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
        },
      }}
      loadDropdownData={{
        concesionario: loadConcesionario,
        direccionEntrega: loadDireccionEntrega,
        tipoDespacho: loadTipoDespacho,
        tipoDespachoTranporte: loadDespachoTransportista,
      }}
      filterSettings={[
        {
          filter: "concesionario",
          label: LanguageProvider({ id: "266", alt: "Concesionarios" }),
          placeholder: LanguageProvider({ id: "266", alt: "Concesionarios" }),
          type: "dropdown",
          dataSource: loadConcesionario,
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
          required: true,
        },
        {
          filter: "direccionEntrega",
          label: LanguageProvider({ id: "530", alt: "Dirección de Entrega" }),
          placeholder: LanguageProvider({
            id: "530",
            alt: "Dirección de Entrega",
          }),
          type: "dropdown",
          dataSource: loadDireccionEntrega,
          dropDownTemplate: DropdownOptionDirEntregaTemplate,
          dependency: [
            "securityValues.country",
            "securityValues.client",
            "concesionario",
          ],
          required: true,
        },
      ]}
      dataTableColumns={[
        {
          field: "tipoDespacho.descripcion",
          header: <LanguageProvider id="1306" alt="Despacho Transportista" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      formSettings={[
        {
          field: "tipoDespacho",
          label: <LanguageProvider id="504" alt="Tipo Despacho" />,
          dropDownPlaceholder: LanguageProvider({
            id: "504",
            alt: "Tipo Despacho",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadTipoDespacho(filter),
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          field: "tipoDespachoTranporte",
          label: <LanguageProvider id="1306" alt="Despacho Transportista" />,
          dropDownPlaceholder: LanguageProvider({
            id: "1306",
            alt: "Despacho Transportista",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadTipoDespacho(filter),
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
    />
  );
};

export default MantenerConcesionariosTipoDespacho;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};

const DropdownOptionDirEntregaTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.direccion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
