import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { ParametrosTiempoEntregaModel } from "src/models/ParametrosTiempoEntregaModel";

const ParametrosTiempoEntrega = () => {
  const SCREEN_CODE = "abm0223_parametros_tiempo_entrega";
  const URL_BASE = "/parametros-tiempo-entrega";
  const SCREEN_TITLE = (
    <LanguageProvider id="19766" alt="Parámetros tiempos de entrega" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(
    model: ParametrosTiempoEntregaModel,
    filterValues: any
  ) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues.securityValues.client &&
        filterValues.securityValues.account
      ) {
        const parametrosTiempoEntrega = {
          ...model,

          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          parametrosTiempoEntrega,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(
    model: ParametrosTiempoEntregaModel,
    filterValues: any
  ) {
    try {
      const parametrosTiempoEntrega = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        parametrosTiempoEntrega,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(
    model: ParametrosTiempoEntregaModel,
    filterValues: any
  ) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        parametrosTiempoEntrega: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(
    filter: any
  ): Promise<ParametrosTiempoEntregaModel[]> {
    if (filter?.securityValues?.country && filter.securityValues.account) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<ParametrosTiempoEntregaModel> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          parametrosTiempoEntrega: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadSubcuentas(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/subcuenta",
          {
            pais: filter.securityValues.country,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadTransportistas(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/proveedor",
          {
            pais: filter.securityValues.country,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ParametrosTiempoEntregaModel>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            // client: true,
            account: true,
          },
        }}
        // filterSettings={}
        dataTableColumns={[
          {
            field: "diasTaller",
            header: <LanguageProvider id="19771" alt="x" />,
          },
          {
            field: "diasUbicacionEspecial",
            header: <LanguageProvider id="19770" alt="x" />,
          },
          {
            field: "diasLiberacion",
            header: <LanguageProvider id="19769" alt="x" />,
          },
          {
            field: "cantidad",
            header: <LanguageProvider id="1253" alt="x" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]
        }
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          cuenta: null,
          diasTaller: 0,
          diasUbicacionEspecial: 0,
          diasLiberacion: 0,
          cantidad: 0,
        }}
        formSettings={[
          {
            field: "diasTaller",
            label: LanguageProvider({ id: "19771", alt: "x" }),
            type: "inputNumber",
            max: 999,
          },
          {
            field: "diasUbicacionEspecial",
            label: LanguageProvider({ id: "19770", alt: "x" }),
            type: "inputNumber",
            max: 999,
          },
          {
            field: "diasLiberacion",
            label: LanguageProvider({ id: "19769", alt: "x" }),
            type: "inputNumber",
            max: 999,
          },
          {
            field: "cantidad",
            label: LanguageProvider({ id: "1253", alt: "x" }),
            type: "inputNumber",
            max: 99999,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "x" }),
            type: "checkbox",
          }
        ]
        }
        list={handleList}
        get={handleGet}
        // save={handleSave}
        update={handleUpdate}
        // remove={handleRemove}
      />
    </>
  );
};

export default ParametrosTiempoEntrega;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
