import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { CupoTaller } from "src/models/CupoTaller";

const MantenerCupoTaller = () => {
  const SCREEN_CODE = "abm0220_mantener_cupo_taller";
  const URL_BASE = "/mantener-cupo-taller";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: CupoTaller, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const cupoTaller = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
          taller: model.taller,
          marca: model.marca,
          cantidad: model.cantidad,
          activo: model.activo,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          cupoTaller,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: CupoTaller, filterValues: any) {
    try {
      const cupoTaller = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
        taller: model.taller,
        marca: model.marca,
        cantidad: model.cantidad,
        activo: model.activo,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        cupoTaller,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  } 
  async function handleRemove(model: CupoTaller, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        cupoTaller: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<CupoTaller[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter.securityValues.account,
          subcuenta: filter.securityValues.subaccount,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<CupoTaller> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          cupoTaller: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }


  async function loadTaller(filter: any): Promise<CupoTaller> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/taller",
        {
          pais: filter.securityValues.country,
          cuenta: filter.securityValues.account,
          subcuenta: filter.securityValues.subaccount,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarca(filter: any): Promise<CupoTaller> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/marca",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }



  return (
    <GenericAbm<CupoTaller>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
          subaccount: true,
        },
      }}
      dataTableColumns={[
        { field: "taller.descripcion", header: <LanguageProvider id="327" alt="Taller" /> },
        {
          field: "marca.descripcion",
          header: <LanguageProvider id="396" alt="Marca" />,
        },
        {
          field: "cantidad",
          header: <LanguageProvider id="1253" alt="Cantidad" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => rowData.activo ? "SI" : "NO",
        },
      ]
      }
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
        cliente: null,
        cantidad: 0,
        cuenta: null,
        subcuenta: null,
        taller: null,
        marca: null,
      }}
      formSettings={[
        {
          field: "taller",
          label: <LanguageProvider id="327" alt="Taller" />,
          type: "dropdown",
          dataSource: filter => loadTaller(filter),
          optionLabel: "descripcion",
          disableOnUpdate: true,
        },
        {
          field: "marca",
          label: <LanguageProvider id="396" alt="Marca" />,
          type: "dropdown",
          dataSource: filter => loadMarca(filter),
          optionLabel: "descripcion",
          disableOnUpdate: true,
        },
        {
          field: "cantidad",
          label: <LanguageProvider id="1253" alt="Cantidad" />,
          type: "inputText",
          length: 4,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerCupoTaller;
