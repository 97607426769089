import React from "react";
import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ContablePuntoRetiro } from "src/models/ContablePuntoRetiro";

const MantenerContablePuntosDeRetiro = () => {
  const SCREEN_CODE = "abm0081_mantener_contable_puntos_de_retiro";
  const URL_BASE = "/mantener-contable-puntos-de-retiro";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ContablePuntoRetiro, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const contablePuntoRetiro = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          paisPuntoRetiro: model.paisPuntoRetiro,
          provinciaPuntoRetiro: model.provincia,
          ciudad: model.ciudad,
          localidad: model.localidad,
          codigoPuntoRetiroContable: model.codigoPuntoRetiroContable,
          codigoCheckContable: model.codigoCheckContable,
          cobraTrasladoTerrestre: model.cobraTrasladoTerrestre,
          descripcion: model.descripcion,
          active: filterValues.active,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          contablePuntoRetiro,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: ContablePuntoRetiro, filterValues: any) {
    try {
      const contablePuntoRetiro = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
        paisPuntoRetiro: model.paisPuntoRetiro,
        provinciaPuntoRetiro: model.provincia,
        ciudad: model.ciudad,
        localidad: model.localidad,
        codigoPuntoRetiroContable: model.codigoPuntoRetiroContable,
        codigoCheckContable: model.codigoCheckContable,
        cobraTrasladoTerrestre: model.cobraTrasladoTerrestre,
        descripcion: model.descripcion,
        active: filterValues.active,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        contablePuntoRetiro,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: ContablePuntoRetiro, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        contablePuntoRetiro: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<ContablePuntoRetiro[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter?.securityValues?.country,
          cliente: filter?.securityValues?.client,
          cuenta: filter?.securityValues?.account,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<ContablePuntoRetiro> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          contablePuntoRetiro: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadPais(filter: any): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/pais",
        {
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }


  async function loadProvincia(filter: any): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/provincia",
        {
          pais: filter?.paisPuntoRetiro
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadCiudad(filter: any): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/ciudad",
        {
          provincia: filter?.provincia
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }


  async function loadLocalidad(filter: any): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/localidad",
        {
          ciudad: filter?.ciudad
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }



  return (
    <GenericAbm<ContablePuntoRetiro>
      screenCode={SCREEN_CODE}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
        },
      }}
      title={<LanguageProvider id="6697" alt="Listado" />}
      dataTableColumns={[
        {
          field: "id",
          header: <LanguageProvider id="6590" alt="Código" />,
        },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="Descripcion" />,
        },
        {
          field: "codigoPuntoRetiroContable",
          header: <LanguageProvider id="16368" alt="Punto retiro contable" />,
        },
        {
          field: "codigoCheckContable",
          header: <LanguageProvider id="16354" alt="Check contable" />,
        },
        {
          field: "cobraTrasladoTerrestre",
          header: <LanguageProvider id="16368" alt="Punto retiro contable" />,
          body: (rowData: any) => {
            return rowData.cobraTrasladoTerrestre ? "SI" : "NO";
          },
        },
        {
          field: "localidad.descripcion",
          header: <LanguageProvider id="24026" alt="Localidad" />,
        },
        {
          field: "ciudad.descripcion",
          header: <LanguageProvider id="164" alt="Ciudad" />,
        },
        {
          field: "provincia.descripcion",
          header: <LanguageProvider id="24020" alt="Provincia" />,
        },
        {
          field: "paisPuntoRetiro.descripcion",
          header: <LanguageProvider id="154" alt="País" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },

      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        ciudad: null,
        cliente: null,
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
        cobraTrasladoTerrestre: false,
        codigoCheckContable: '',
        codigoPuntoRetiroContable: '',
        cuenta: null,
        localidad: null,
        pais: null,
        paisPuntoRetiro: null,
        provincia: null,
        descripcion: '',
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          disableOnUpdate: true,
          length: 1,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripcion" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "codigoPuntoRetiroContable",
          label: <LanguageProvider id="16368" alt="Punto retiro contable" />,
          type: "inputText",
          length: 1,
        },
        {
          field: "codigoCheckContable",
          label: <LanguageProvider id="16354" alt="Check contable" />,
          type: "inputText",
          length: 3,
        },
        
        {
          field: "paisPuntoRetiro",
          label: <LanguageProvider id="23960" alt="País" />,
          dropDownPlaceholder: LanguageProvider({ id: "23960", alt: "País" }),
          type: "dropdown",
          dataSource: (filter: any) => loadPais(filter),
          dropDownTemplate: DropdownOptionTemplate,
          length: 50,
        },
        {
          field: "provincia",
          label: <LanguageProvider id="24020" alt="Provincia" />,
          dropDownPlaceholder: LanguageProvider({ id: "24020", alt: "Provincia" }),
          type: "dropdown",
          dataSource: (filter: any) => loadProvincia(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["paisPuntoRetiro"],
        },
        {
          field: "ciudad",
          label: <LanguageProvider id="164" alt="Ciudad" />,
          dropDownPlaceholder: LanguageProvider({ id: "164", alt: "Ciudad" }),
          type: "dropdown",
          dataSource: (filter: any) => loadCiudad(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["provincia"],
        },
        {
          field: "localidad",
          label: <LanguageProvider id="3186" alt="Localidad" />,
          dropDownPlaceholder: LanguageProvider({ id: "3186", alt: "Localidad" }),
          type: "dropdown",
          dataSource: (filter: any) => loadLocalidad(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["ciudad"],
        },
        {
          field: "activo",
          label: <LanguageProvider id="23849" alt="Activo" />,
          type: "checkbox",
        },
        {
          field: "codigoRutina",
          label: <LanguageProvider id="16368" alt="Punto retiro contable" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerContablePuntosDeRetiro;


const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};