import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Marca } from "src/models/Marcas";

const MantenerMarcas = () => {
  const SCREEN_CODE = "abm0010_mantener_marcas";
  const URL_BASE = "/mantener-marcas";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: Marca, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client
      ) {
        const marca = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          marca,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: Marca, filterValues: any) {
    try {
      const marca = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        marca,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Marca, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        marca: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Marca[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter.securityValues.account,
          subcuenta: filter.securityValues.subaccount,
        }
      );
      if (status === 200) return data.sort((a, b) => a.id.localeCompare(b.id));
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<Marca> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          marca: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadTramo(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/tramo",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<Marca>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="23833" alt="Mantener Marcas" />}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: null,
        activo: true,
        descripcion: "",
        cuenta: null,
        subcuenta: null,
        cliente: null,
        pais: null,
        controlaFeriados: false,
        email: "",
        marcaCliente: "",
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      dataTableColumns={[
        {
          field: "id",
          header: <LanguageProvider id="6590" alt="Código" />,
        },
        {
          field: "marcaCliente",
          header: <LanguageProvider id="16275" alt="Marca cliente" />,
        },
        {
          field: "descripcion",
          header: <LanguageProvider id="396" alt="Marca" />,
        },
        {
          field: "email",
          header: <LanguageProvider id="10415" alt="Email" />,
        },
        {
          field: "controlaFeriados",
          header: (
            <LanguageProvider id="10495" alt="Controla Feriados y T. Entr." />
          ),
          body: (rowData: any) => {
            return rowData.controlaFeriados ? "SI" : "NO";
          },
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.timestamp",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="x" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          required: true,
          length: 2,
          keyfilter: "pint",
        },
        {
          field: "marcaCliente",
          label: <LanguageProvider id="16275" alt="Marca cliente" />,
          type: "inputText",
          required: true,
          length: 2,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="396" alt="Marca" />,
          type: "inputText",
          required: true,
          length: 50,
        },
        {
          field: "email",
          label: <LanguageProvider id="10415" alt="Email" />,
          type: "inputText",
          keyfilter: "email",
          length: 300,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
        {
          field: "controlaFeriados",
          label: (
            <LanguageProvider id="10495" alt="Controla Feriados y T. Entr." />
          ),
          type: "checkbox",
        },
      ]}
    />
  );
};

export default MantenerMarcas;
