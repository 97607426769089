import "./ModalAltaReparacionServicos.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../../../protected-routes";
import { useLocation } from "react-router-dom";
import usePageViews from "../../../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { HttpStatusCode } from "axios";
import { format } from "date-fns";

interface InterfaceModalAltaReparacionServicos {
  visible: boolean;
  onClose: () => void;
  model?: any;
  onConfirm?: (updatedList: any[]) => void;
  order?: any;
  listado?: any[];
}

const ModalAltaReparacionServicos: React.FC<
  InterfaceModalAltaReparacionServicos
> = ({ visible, onClose, model, onConfirm, order }) => {
  const location = useLocation();
  const [selectedRows, setSelectedRows] = useState([]);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mensageError, setMensageError] = useState(false);


  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    cliente: null,
    grupoTaller: null,
    cuentaTaller: null,
    modeloTaller: null,
    origen: null,
    codori: '',
    prestacion: null,
    labelPrestacion: null,
    labelSubpretacion: null,
    pieza: '',
    horas: null,
    mecanico: null
  });

  const [cliente, setCliente] = useState([]);
  const [loadingCliente, setLoadingCliente] = useState(false);

  async function loadCliente() {
    setLoadingCliente(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/orden-reparacion-servicios/cliente",
        {
          pais: order.pais,
          cuenta: order.cuenta,
        }
      );
      if (status === HttpStatusCode.Ok) {
        data.unshift({ id: 0, descripcion: "TODOS" });
        setCliente(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCliente(false);
    }
  }

  const [grupoTaller, setGrupoTaller] = useState([]);
  const [loadingGrupoTaller, setLoadingGrupoTaller] = useState(false);
  async function loadGrupoTaller() {
    setLoadingGrupoTaller(true);
    try {
      if (order.pais !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-servicios/grupo-taller",
          {
            pais: order.pais,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setGrupoTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrupoTaller(false);
    }
  }

  const [cuentaTaller, setCuentaTaller] = useState([]);
  const [loadingCuentaTaller, setLoadingCuentaTaller] = useState(false);

  async function loadCuentaTaller() {
    setLoadingCuentaTaller(true);
    try {
      if (order.pais !== null && order.cuenta !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-servicios/cuenta-taller",
          {
            pais: order.pais,
            cuenta: order.cuenta,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setCuentaTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCuentaTaller(false);
    }
  }

  const [modeloTaller, setModeloTaller] = useState([]);
  const [loadingModeloTaller, setLoadingModeloTaller] = useState(false);

  async function loadModeloTaller(grupo: any) {
    setLoadingModeloTaller(true);
    try {
      if (order.pais !== null && grupo !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-servicios/modelo-taller",
          {
            pais: order.pais,
            grupoTaller: grupo,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setModeloTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModeloTaller(false);
    }
  }


  const [origen, setOrigen] = useState([]);
  const [loadingOrigen, setLoadingOrigen] = useState(false);

  async function loadOrigen() {
    setLoadingOrigen(true);
    try {
      if (order.pais !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-servicios/contable-origen-users",
          {
          }
        );
        if (status === HttpStatusCode.Ok) {
          setOrigen(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigen(false);
    }
  }

  const [modalReport, setModalReport] = useState([]);
  const [loadingModalReport, setLoadingModalReport] = useState(false);



  const [prestacions, setPrestacions] = useState([]);
  const [loadingPrestacions, setLoadingPrestacions] = useState(false);

 async function loadPrestacions(origen: any) {
    setLoadingPrestacions(true);
    try {
      if (order.pais !== null && modalReport.length >= 0) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-servicios/contable-origen-prestacions",
          {
            pais: order.pais,
            codori: origen.id
          }
        );
        if (status === HttpStatusCode.Ok) {
          setPrestacions(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPrestacions(false);
    }
  }

  const [subprestacions, setSubPrestacions] = useState([]);
  const [loadingSubPrestacions, setLoadingSubPrestacions] = useState(false);


  async function loadSubPrestacions(prestacion: any, origen: any) {
    setLoadingSubPrestacions(true);
    try {
      if (order.pais !== null && prestacion) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-servicios/contable-origen-subprestacions",
          {
            prestacion: prestacion.id,
            codori: origen.id
          }
        );
        if (status === HttpStatusCode.Ok) {
          setSubPrestacions(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSubPrestacions(false);
    }
  }
  const [mecanico, setMecanico] = useState([]);
  const [loadingMecanico, setLoadingMecanico] = useState(false);

  async function loadMecanico() {
    setLoadingMecanico(true);
    try {
      if (order.pais !== null && modalReport.length >= 0) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-servicios/mecanico",
          {
            pais: order.pais,
            cuenta: order.cuenta,
            subcuenta: order.subcuenta,
            taller: order.taller
          }
        );
        if (status === HttpStatusCode.Ok) {
          setMecanico(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMecanico(false);
    }
  }


  async function loadReport(query: any) {
    setLoadingModalReport(true);
    try {
      if (order.pais !== null && order.cuenta !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-servicios/catalogo-servicio",
          {
            pais: order.pais,
            cuenta: order.cuenta,
            cliente: filter.cliente?.descripcion === "TODOS" ? null : filter.cliente,
            grupoTaller: filter.grupoTaller?.descripcion === "TODOS" ? null : filter.grupoTaller,
            modeloTaller: filter.modeloTaller || null,
            servicio: query || null
          }
        );
        if (status === HttpStatusCode.Ok) {
          setModalReport(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModalReport(false);
    }
  }

  const columnsNamesTable = [
    { field: "id", header: "Servicio" },
    { field: "descripcion", header: "Descripcion" },
    { field: "horasStandard", header: "Horas Standard" },
    { field: "fechaCotizacion.date", header: "Fecha Cotizacion" },
    { field: "fechaUltimaModificacion.date", header: "Fecha Ultima Modificacion" },
    { field: "codori", header: "Fecha Ultima Modificacion" },
    { field: "prestacion", header: "Fecha Ultima Modificacion" },
  ];
  function hadleLoadModeloTaller(grupo: any) {
    try {
      loadModeloTaller(grupo);

      setFilter({
        ...filter,
        grupoTaller: grupo
      })
    } catch (error) {
      console.error(error);
    }
  }

  function hadleLoadSubPrestacion(prestacion: any, origen: any) {
    try {
      loadSubPrestacions(prestacion, origen);



      setFilter({
        ...filter,
        prestacion: prestacion
      })
    } catch (error) {
      console.error(error);
    }
  }

  function hadleLoadPrestacion(origen: any) {
    try {
      loadPrestacions(origen);



      setFilter({
        ...filter,
        origen: origen
      })
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    const init = async () => {
      try {
        const [cliente, grupoTaller, cuentaTaller, origen, mecanico] = await Promise.all([
          loadCliente(),
          loadGrupoTaller(),
          loadCuentaTaller(),
          loadOrigen(),
          loadMecanico()
        ]);

        setFilter(({
          ...filter,
          cliente: cliente[0],
          grupoTaller: grupoTaller[0],
          cuentaTaller: cuentaTaller[0],
          origen: origen[0],
          mecanico: mecanico[0],
        }));
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
      }
    };

    init();
  }, [visible]);

  useEffect(() => {
    const init = () => {
      if (selectedRows.length > 0) {
        const value: any = selectedRows.map(x => x.horasStandard);

        setFilter({
          ...filter,
          horas: value
        })
      }
    }

    init();

  }, [selectedRows]);




  const [saveLabel, setSaveLabel] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);


  async function save() {
    setLoadingSave(true);
    try {

      const { status, data } = await sipcoAxiosService.post(
        "/orden-reparacion-servicios/save",
        {
          detallesOrdenServicio: {
            pais: order.pais ?? null,
            cuenta: order.cuenta ?? null,
            subcuenta: order.subcuenta ?? null,
            taller: order.taller ?? null,
            ordenReparacion: order,
            clienteServicio: filter.cliente ?? null,
            mecanico: filter.mecanico ?? null,
            prestacion: filter.prestacion.id ?? null,
            codori: filter.origen.id ?? null,
            servicio: selectedRows[0]
          }
        }
      );
      if (status === HttpStatusCode.Ok) {
        setSaveLabel(data);
        onClose();
        return data;
      } else {
        setMensageError(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSave(false);
    }
  }


  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  return (
    <div>
      <Dialog
        className="modalReparacion"
        header="Orden Reparación Servicios - Alta"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={LanguageProvider({ id: "15447", alt: "Confirmar" })}
                icon="pi pi-check"
                onClick={save}
                autoFocus
              />
              <Button
                label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                icon="pi pi-times"
                onClick={onClose}
                className="p-button-text"
              />
            </div>
          );
        }}
      >
        <div className="modal-container">
          <div className="form-modal">
            <div className="form-column">
              <label><LanguageProvider id={"3750"} alt="Cliente" /></label>
              <SipcoDropdown
                loading={loadingCliente}
                options={cliente}
                optionLabel="descripcion"
                value={filter.cliente}
                onChange={(e) => setFilter({ ...filter, cliente: e.value })}
                className="input-modal"
              />

              <label><LanguageProvider id={"2185"} alt="Grupo Taller" /></label>
              <SipcoDropdown
                loading={loadingGrupoTaller}
                options={grupoTaller}
                optionLabel="descripcion"
                value={filter.grupoTaller} onChange={(e) => hadleLoadModeloTaller(e.value)} className="input-modal" />

              <label><LanguageProvider id={"10746"} alt="Modelo" /></label>
              <SipcoDropdown loading={loadingModeloTaller} className="input-modal" options={modeloTaller} value={filter.modeloTaller} onChange={(e) => setFilter({ ...filter, modeloTaller: e.value })} optionLabel="descripcion" />
            </div>

            <div className="form-column">
              <label><LanguageProvider id={"2020"} alt="Pieza" /></label>
              <SipcoInputText value={filter.pieza} onChange={(e) => setFilter({ ...filter, pieza: e.target.value })} className="input-modal"
                onChangeCapture={(e) => {
                  loadReport(filter.pieza);
                }}
              />

              <label><LanguageProvider id={"2184"} alt="Cuenta Taller" /></label>
              <SipcoDropdown loading={loadingCuentaTaller} options={cuentaTaller} optionLabel="descripcion" value={filter.cuentaTaller} onChange={(e) => setFilter({ ...filter, cuentaTaller: e.value })} className="input-modal" />
            </div>
          </div>
        </div>


        {/* Data Table Modal */}
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={modalReport}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={8}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loadingModalReport}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e: any) => setSelectedRows(e.value)}
          metaKeySelection={true}
          dragSelection
        >
          {columnsNamesTable.map((colum, index) => {
            if (colum.header === "Fecha Cotizacion" || colum.header === "Fecha Ultima Modificacion") {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </SipcoDataTable>

        <div className="modal-container">
          <div className="form-modal">

            <div className="form-column">
              {mensageError ? <p>Campos vacios</p> : null}
              <label><LanguageProvider id={"2496"} alt="Origen" /></label>
              <SipcoDropdown
                loading={loadingOrigen}
                options={origen}
                optionLabel="descripcion"
                value={filter.origen}
                onChange={(e) => hadleLoadPrestacion(e.value)} 
                className="input-modal" />

              <label><LanguageProvider id={"4137"} alt="Prestación" /></label>
              <SipcoDropdown
                loading={loadingPrestacions}
                options={prestacions} optionLabel="descripcion" value={filter.prestacion}
                onChange={(e) => hadleLoadSubPrestacion(e.value, filter.origen)} className="input-modal" />

              <label><LanguageProvider id={"4587"} alt="Subprestación" /></label>

              <SipcoDropdown
                loading={loadingSubPrestacions}
                options={subprestacions}
                optionLabel="descripcion"
                value={filter.labelSubpretacion}
                onChange={(e) => setFilter({ ...filter, labelSubpretacion: e.value })}
                className="input-modal"
              />

            </div>
            <div className="form-column">
              <label><LanguageProvider id={"2372"} alt="Horas" /></label>
              <SipcoInputText value={filter.horas} onChange={(e) => setFilter({ ...filter, horas: e.target.value })} className="input-modal" />

              <label><LanguageProvider id={"17027"} alt="Mecánico" /></label>
              <SipcoDropdown
                loading={loadingMecanico}
                options={mecanico}
                optionLabel="descripcion"
                value={filter.mecanico} onChange={(e) => setFilter({ ...filter, mecanico: e.value })}
                className="input-modal" />
            </div>
          </div>
        </div>

      </Dialog>
    </div>
  );
};
export default ModalAltaReparacionServicos;
