import "./EliminarBuqueCamion.scss";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import useFormattedDate from "../../../hooks/useFormattedDate";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function EliminarBuqueCamion() {
  const SCREEN_CODE = "fun0094_eliminar_llegada_buque_camion";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);

  const { formatDateTime } = useFormattedDate();

  const confirmDialogBloquear = () => {
    confirmDialog({
      message: (
        <LanguageProvider
          id={"13275"}
          alt="Deseja processar as alterações realizadas?"
        />
      ),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => validar(),
      reject: null,
    });
  };

  const processar = async () => {
    try {
      const { data, status } = await sipcoAxiosService.post(
        "/eliminar-llegada-buque-camion/elliminar",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          transporte: filter?.transporte,
          ciudad: filter?.ciudadOrigen,
          fechaLlegada: filter?.fechaLlegada,
        }
      );

      if (status === 200) {
        if (data) {
          console.log("Sucess");
          toast.current.show({
            severity: "success",
            summary: "Sucesso",
            detail: "Processado com sucesso",
            life: 3000,
          });
        } else {
          console.log("Erro");
          toast.current.show({
            severity: "error",
            summary: "Erro",
            detail: "Erro ao processar",
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const validar = async () => {
    try {
      const { data, status } = await sipcoAxiosService.post(
        "/eliminar-llegada-buque-camion/llegadas-eliminables",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          transporte: filter?.transporte,
          ciudad: filter?.ciudadOrigen,
          fechaLlegada: filter?.fechaLlegada,
        }
      );

      if (status === 200) {
        if (data) {
          console.log("EXCEPTION");
        } else {
          console.log("VALIDO");
          processar();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [filter, setFilter] = useState({
    transporte: null,
    fechaLlegada: null,
    ciudadOrigen: null,
  });

  const [transporte, setTransporte] = useState([]);
  const [loadingTransporte, setLoadingTransporte] = useState(false);
  async function loadTransporte() {
    setLoadingTransporte(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/eliminar-llegada-buque-camion/transportes",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
          }
        );

        if (status === 200) {
          const transporteData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setTransporte(transporteData);
          return transporteData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransporte(false);
    }
    setTransporte([]);
    return [];
  }

  const [ciudadOrigen, setCiudadOrigen] = useState([]);
  const [loadingCiudadOrigen, setLoadingCiudadOrigen] = useState(false);
  async function loadCiudadOrigen(transporteId) {
    setLoadingCiudadOrigen(true);
    try {
      if (transporteId) {
        const { status, data } = await sipcoAxiosService.post(
          "/eliminar-llegada-buque-camion/ciudads",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            transporte: transporteId,
          }
        );

        if (status === 200) {
          const ciudadOrigenData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setCiudadOrigen(ciudadOrigenData);
          return ciudadOrigenData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCiudadOrigen(false);
    }
    setCiudadOrigen([]);
    return [];
  }

  const [fechaLlegada, setFechaLlegada] = useState([]);
  const [loadingFechaLlegada, setLoadingFechaLlegada] = useState(false);
  async function loadFechaLlegada(transporteId, ciudadId) {
    setLoadingFechaLlegada(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        transporteId &&
        ciudadId
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/eliminar-llegada-buque-camion/fechas",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            transporte: transporteId,
            ciudad: ciudadId,
          }
        );

        if (status === 200) {
          setFechaLlegada(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFechaLlegada(false);
    }
    setFechaLlegada([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const transportes = await loadTransporte();
          if (transportes.length > 0) {
            setFilter((prevFilter) => ({
              ...prevFilter,
              transporte: transportes?.length > 0 ? transportes[0] : null,
            }));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  // Carregar CiudadOrigen ao mudar Transporte
  useEffect(() => {
    async function updateCiudadOrigen() {
      if (filter?.transporte) {
        try {
          const ciudadOrigens = await loadCiudadOrigen(filter.transporte);
          setFilter((prevFilter) => ({
            ...prevFilter,
            ciudadOrigen: ciudadOrigens?.length > 0 ? ciudadOrigens[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateCiudadOrigen();
  }, [securityFilters?.securityValues, filter?.transporte]);

  // Carregar FechaLlegada ao mudar Transporte e CiudadOrigen
  useEffect(() => {
    async function updateFechaLlegada() {
      if (filter?.transporte && filter?.ciudadOrigen) {
        try {
          const fechaLlegadas = await loadFechaLlegada(
            filter.transporte,
            filter.ciudadOrigen
          );
          setFilter((prevFilter) => ({
            ...prevFilter,
            fechaLlegada: fechaLlegadas?.length > 0 ? fechaLlegadas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateFechaLlegada();
  }, [securityFilters?.securityValues, filter?.ciudadOrigen]);

  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "eliminar-llegada-buque-camion/importado",
        {
          pais: securityFilters?.securityValues?.country,
          cliente: securityFilters?.securityValues?.client,
          cuenta: securityFilters?.securityValues?.account,
          transporte: filter?.transporte,
          ciudad: filter?.ciudadOrigen,
          fechaLlegada: filter?.fechaLlegada || "",
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  return (
    <div className="eliminar-buque-camion">
      <div>
        <ConfirmDialog />
      </div>
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"23814"} alt="Transporte" />
                </label>
                <SipcoDropdown
                  value={filter.transporte}
                  options={transporte}
                  onChange={(e) =>
                    setFilter({ ...filter, transporte: e.value })
                  }
                  optionLabel="label"
                  loading={loadingTransporte}
                  filter
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="11237" alt="Ciudad Origen" />
                </label>
                <SipcoDropdown
                  value={filter.ciudadOrigen}
                  options={ciudadOrigen}
                  onChange={(e) =>
                    setFilter({ ...filter, ciudadOrigen: e.value })
                  }
                  optionLabel="label"
                  loading={loadingCiudadOrigen}
                  filter
                />
              </div>
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"1736"} alt="Fecha de Llegada" />
              </label>
              <SipcoDropdown
                value={filter.fechaLlegada}
                options={fechaLlegada}
                onChange={(e) =>
                  setFilter({ ...filter, fechaLlegada: e.value })
                }
                optionLabel="label"
                loading={loadingFechaLlegada}
                filter
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {
                  <LanguageProvider
                    id="8960"
                    alt="Eliminar Llegada Buque Camión"
                  />
                }
              </span>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          loading={loadingReportData}
        >
          <SipcoColumn
            field="importado.fechaLLegada.date"
            header={<LanguageProvider id="16932" alt="Fecha Llegada" />}
            sortable
            filter
            body={(rowData) =>
              rowData.importado.fechaLLegada?.date
                ? formatDateTime(rowData.importado.fechaLLegada.date)
                : rowData.importado.fechaLLegada || ""
            }
          />
          <SipcoColumn
            field="importado.vin.id"
            header={<LanguageProvider id="1491" alt="VIN" />}
          />
          <SipcoColumn
            field="mercado"
            header={<LanguageProvider id="10774" alt="Mercados" />}
          />
          <SipcoColumn
            field="marca"
            header={<LanguageProvider id="396" alt="Marca" />}
          />
          <SipcoColumn
            field="importado.fechaCarga.date"
            header={<LanguageProvider id="16541" alt="Fecha de carga" />}
            sortable
            filter
            body={(rowData) =>
              rowData.importado.fechaCarga?.date
                ? formatDateTime(rowData.importado.fechaCarga.date)
                : rowData.importado.fechaCarga || ""
            }
          />
          <SipcoColumn
            field="transportista"
            header={<LanguageProvider id="754" alt="Transportista" />}
          />
          <SipcoColumn
            field="despachante"
            header={<LanguageProvider id="1058" alt="Despachante" />}
          />
          <SipcoColumn
            field="terminal"
            header={<LanguageProvider id="5270" alt="Terminal" />}
          />
          <SipcoColumn
            field="importado.usuarioUltimaModificacion.id"
            header={
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            }
          />
          <SipcoColumn
            field="importado.fechaUltimaModificacion.date"
            header={<LanguageProvider id="5271" alt="Fec. Últ. Modificación" />}
            sortable
            filter
            body={(rowData) =>
              rowData.importado.fechaUltimaModificacion?.date
                ? formatDateTime(rowData.importado.fechaUltimaModificacion.date)
                : rowData.importado.fechaUltimaModificacion || ""
            }
          />
        </SipcoDataTable>
      </Panel>

      <div className="chips">
        <div className="sipco-chip">
          <label>
            <LanguageProvider id={"5239"} alt="Cantidad de Autos" />
          </label>
          <Chip
            label={
              reporte && Array.isArray(reporte) ? String(reporte.length) : "0"
            }
          />
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "34",
            alt: "Procesar o guardar cambios",
          })}
          icon="pi pi-save"
          onClick={() => confirmDialogBloquear()}
        />
      </div>
    </div>
  );
}
