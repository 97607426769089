import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { InspectorAveria } from "src/models/InspectorAveria";

const MantenerInspectoresAveria = () => {
  const SCREEN_CODE = "abm0137_mantener_inspectores_averias";
  const URL_BASE = "/mantener-inspectores-averia";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: InspectorAveria, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const inspectorAveria = {
          ...model,
          pais: filterValues.securityValues.country,
          id: model.id,
          descripcion: model.descripcion,
          activo: model.activo,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          inspectorAveria,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: InspectorAveria, filterValues: any) {
    try {
      const inspectorAveria = {
        ...model,
        pais: filterValues.securityValues.country,
        id: model.id,
        descripcion: model.descripcion,
        activo: model.activo,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        inspectorAveria,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  
  async function handleRemove(model: InspectorAveria, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        inspectorAveria: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<InspectorAveria[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<InspectorAveria> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          inspectorAveria: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <GenericAbm<InspectorAveria>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="647" alt="Mantener Talleres" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
        },
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="11070" alt="Cod. Inspector" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="descripcion" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => rowData.activo ? "SI" : "NO",
        },
      ]
      }
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
        descripcion: "",
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="11070" alt="Cod. Inspector" />,
          type: "inputText",
          length: 5,
          disableOnUpdate: true,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripción" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerInspectoresAveria;
