import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { MatrizTiempo } from "src/models/MatrizTiempo";

const MantenerMatrizTimpoe = () => {
  const SCREEN_CODE = "abm0203_matriz_tiempo";
  const URL_BASE = "/mantener-matriz-tiempo";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: MatrizTiempo, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const matrizTiempo = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          eventoEntrega: model.eventoEntrega,
          matrizImportacion: model.matrizImportacion,
          zonaImportacion: model.zonaImportacion,
          diasTeoricos: model.diasTeoricos,
          orden: model.orden,
          secuencial: model.secuencial,
          marca: model.marca,
          activo: model.activo,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          matrizTiempo,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: MatrizTiempo, filterValues: any) {
    try {
      const matrizTiempo = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues.securityValues.account,
        eventoEntrega: model.eventoEntrega,
        matrizImportacion: model.matrizImportacion,
        zonaImportacion: model.zonaImportacion,
        diasTeoricos: model.diasTeoricos,
        orden: model.orden,
        secuencial: model.secuencial,
        marca: model.marca,
        activo: model.activo,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        matrizTiempo,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: MatrizTiempo, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        matrizTiempo: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<MatrizTiempo[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client
        && filter?.matrizImportacion && filter?.securityValues?.account && filter?.zonaImportacion) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,
            matriz: filter?.matrizImportacion,
            zonaImportacion: filter?.zonaImportacion,
          }
        );
        if (status === 200) return data;
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<MatrizTiempo> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          matrizTiempo: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }



  async function loadFormMarca(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/marca",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadFormEventoEntrega(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client
        && filter?.securityValues?.account) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/evento-entrega",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,

          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadFormZonaImportacion(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client
        && filter?.securityValues?.account) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/zona-importacion",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadFormMatriz(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client
        && filter?.matrizImportacion && filter?.securityValues?.account) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/matriz",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }



  // Filtro
  async function loadFilterMatriz(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/matriz",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadFilterZonaImportacion(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client
        && filter?.matrizImportacion && filter?.securityValues?.account) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/zona-importacion",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,
            matriz: filter?.matrizImportacion,

          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<MatrizTiempo>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
        },
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Código" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="Descripcion" />,
        },
        {
          field: "cliente.descripcion",
          header: <LanguageProvider id="3750" alt="Cliente" />,
        },
        {
          field: "marca.descripcion",
          header: <LanguageProvider id="396" alt="Marca" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => rowData.activo ? "SI" : "NO",
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        cliente: null,
        marca: null,
        usuarioUltimaModificacion: null,
        cuenta: null,
        diasTeoricos: 0,
        eventoEntrega: null,
        matrizImportacion: null,
        orden: 0,
        secuencial: false,
        zonaImportacion: null,
      }}
      filterSettings={[
        {
          filter: "matrizImportacion",
          label: LanguageProvider({ id: "2115", alt: "Matriz" }),
          placeholder: LanguageProvider({ id: "2115", alt: "Matriz" }),
          type: "dropdown",
          dataSource: filter => loadFilterMatriz(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country", "securityValues.account"],
          required: true,
        },
        {
          filter: "zonaImportacion",
          label: LanguageProvider({ id: "17906", alt: "Zona Importación" }),
          placeholder: LanguageProvider({ id: "17906", alt: "Zona Importación" }),
          type: "dropdown",
          dataSource: filter => loadFilterZonaImportacion(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["matrizImportacion"],
          required: true,
        },
      ]}
      formSettings={[
        {
          field: "marca",
          label: <LanguageProvider id="23888" alt="Marca" />,
          dataSource: filter => loadFormMarca(filter),
          optionLabel: "descripcion",
          type: "dropdown",
        },
        {
          field: "eventoEntrega",
          label: <LanguageProvider id="17905" alt="Evento Entrega" />,
          dataSource: filter => loadFormEventoEntrega(filter),
          optionLabel: "descripcion",
          type: "dropdown",
        },
        {
          field: "matrizImportacion",
          label: <LanguageProvider id="2115" alt="Matriz" />,
          dataSource: filter => loadFormMatriz(filter),
          optionLabel: "descripcion",
          type: "dropdown",
        },
        {
          field: "zonaImportacion",
          label: <LanguageProvider id="17906" alt="Zona Importación" />,
          dataSource: filter => loadFormZonaImportacion(filter),
          optionLabel: "descripcion",
          type: "dropdown",
        },
        {
          field: "diasTeoricos",
          label: <LanguageProvider id="17907" alt="DíasTeóricos" />,
          type: "inputText",
          length: 3,
        },
        {
          field: "orden",
          label: <LanguageProvider id="11370" alt="Orden" />,
          type: "inputText",
          length: 2,
        },
        {
          field: "secuencial",
          label: <LanguageProvider id="17908" alt="Secuencial" />,
          type: "checkbox",
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerMatrizTimpoe;


const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};