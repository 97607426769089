import { LanguageProvider } from "@shared/components/language-provider";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import '../OfertasServicioTerceros.scss';
import { Calendar } from "primereact/calendar";
import { CaratulaOfertaServicio } from "src/models/CaratulaOfertaServicio";
import { HttpStatusCode } from "axios";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

type NuevoOsProps = {
    visible: boolean;
    onClose: () => void;
    className?: string;
    securityFilters?: any
}



const NuevoOs = ({ visible, onClose, className,  securityFilters }: NuevoOsProps) => {
    const sipcoAxiosService = useSipcoAxiosService();
    const [filter, setFilter] = useState<CaratulaOfertaServicio>({
        id: '',
        activo: true,
        cliente: null,
        contacto: '',
        detalleOsPlaya: null,
        estado: null,
        fechaUltimaModificacion: null,
        mailContacto: '',
        observacion: '',
        referenciaCliente: '',
        usuarioUltimaModificacion: null,
        vin: null,
        fechaCotizacion: new Date(),
        fechaDeseada: new Date(),
        fechaAceptacion: new Date(),
        descCliente: ''
    });

    const [loadingCliente, setLoadingCliente] = useState(false);
    const [cliente, setCliente] = useState(null);

    async function clienteLabel() {
        try {
            if (securityFilters?.securityValues?.country) {
                setLoadingCliente(true);
                const { status, data } = await sipcoAxiosService.post(
                    "ofertas-servicio-terceros/cliente-service",
                    {
                        pais: securityFilters.securityValues.country
                    }
                );
                if (status === HttpStatusCode.Ok) {
                    setCliente(data);
                    return data;
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingCliente(false);
        }
    }

    // save 
    async function nuevaOs() {
        try {
            if (
                securityFilters?.securityValues?.country
            ) {
                const { status, data } = await sipcoAxiosService.post(
                    "ofertas-servicio-terceros/nuevo-os",
                    {
                        caratulaOfertaServicio: {
                            pais: securityFilters.securityValues.country,
                            cuenta: securityFilters.securityValues.account,
                            subCuenta: securityFilters.securityValues.subaccount,
                            cliente: filter.cliente,
                            fechaCotizacion: new Date(filter.fechaCotizacion).getTime(),
                            fechaDeseada: new Date(filter.fechaDeseada).getTime(),
                            fechaAceptacion: new Date(filter.fechaAceptacion).getTime(),
                            estado: {
                                id: "1",
                                descripcion: "Nueva"
                            },
                            contacto: filter.contacto,
                            mailContacto: filter.mailContacto,
                            observacion: filter.observacion,
                            referenciaCliente: filter.referenciaCliente,
                            detalleOsPlaya: filter.detalleOsPlaya
                        }
                    }
                );
                if (status === HttpStatusCode.Ok) {
                    return data;
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(
        () => {
            const init = async () => {
                try {
                    if (visible) {
                        const data = await clienteLabel();
                        setFilter({
                            ...filter,
                            cliente: data[0],
                        });
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            init();
        }, [visible]);



    return (
        <>
            <Dialog
                className={className}
                header="NuevoOs - Alta"
                visible={visible}
                style={{ width: "40vw" }}
                onHide={onClose}
                modal
                footer={() => {
                    return (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button
                                label={LanguageProvider({ id: "15447", alt: "Confirmar" })}
                                icon="pi pi-check"
                                onClick={nuevaOs}
                                autoFocus
                            />
                            <Button
                                label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                                icon="pi pi-times"
                                onClick={onClose}
                                className="p-button-text"
                            />
                        </div>
                    );
                }}
            >

                <div className="form">
                    <div className="form-row" style={{ justifyContent: "center" }}>
                        <div className={"sipco-option"}>
                            <label>
                                <LanguageProvider id={"24080"} alt="Cliente" />
                            </label>
                            <SipcoDropdown
                                value={filter?.cliente}
                                onChange={(e) => setFilter({ ...filter, cliente: e.target.value })}
                                loading={loadingCliente}
                                options={cliente}
                                optionLabel="descripcion"
                            />
                            <label>
                                <LanguageProvider id={"6256"} alt="Fecha Cotización" />
                            </label>
                            <Calendar
                                value={filter?.fechaCotizacion}
                                onChange={(e: any) => setFilter({ ...filter, fechaCotizacion: e.value })}
                                showIcon
                            />
                            <label>
                                <LanguageProvider id={"22795"} alt="Contacto (Nombre y/o Apellido)" />
                            </label>
                            <SipcoInputText
                                value={filter?.contacto}
                                onChange={(e) => setFilter({ ...filter, contacto: e.target.value })}
                            />
                            <label>
                                <LanguageProvider id={"23245"} alt="Observación/Descripción" />
                            </label>
                            <SipcoInputText
                                value={filter?.observacion}
                                onChange={(e) => setFilter({ ...filter, observacion: e.target.value })}
                            />
                        </div>

                        <div className={"sipco-option"}>
                            <label>
                                <LanguageProvider id={"18997"} alt="Desc. Cliente" />
                            </label>
                            <SipcoInputText
                                value={filter?.descCliente}
                                onChange={(e) => setFilter({ ...filter, descCliente: e.target.value })}
                            />
                            <label>
                                <LanguageProvider id={"2781"} alt="Fecha de Realización" />
                            </label>
                            <Calendar
                                value={filter?.fechaDeseada}
                                onChange={(e: any) => setFilter({ ...filter, fechaDeseada: e.value })}
                                showIcon
                            />
                            <label>
                                <LanguageProvider id={"17983"} alt="Email Contacto" />
                            </label>
                            <SipcoInputText
                                value={filter?.mailContacto}
                                onChange={(e) => setFilter({ ...filter, mailContacto: e.target.value })}
                            />
                            <label>
                                <LanguageProvider id={"22796"} alt="Referencia Cliente" />
                            </label>
                            <SipcoInputText
                                value={filter?.referenciaCliente}
                                onChange={(e) => setFilter({ ...filter, referenciaCliente: e.target.value })}
                            />
                        </div>


                    </div>
                    <div className="form-row" style={{ justifyContent: "center" }}>
                        <div className={"sipco-option"}>
                            <label>
                                <LanguageProvider id={"8388"} alt="Fecha Aceptación" />
                            </label>
                            <Calendar
                                value={filter?.fechaAceptacion}
                                onChange={(e: any) => setFilter({ ...filter, fechaAceptacion: e.value })}
                                showIcon
                            />
                        </div>
                    </div>
                </div>
            </Dialog>

        </>);
};

export default NuevoOs;