import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ControlsExw } from "src/models/ControlsExw";
import useAuth from "@shared/AuthContext";

const ControlExw = () => {
  const SCREEN_CODE = "abm0154_control_exw";
  const URL_BASE = "/control-exw";
  const sipcoAxiosService = useSipcoAxiosService();
  const { user } = useAuth();

  async function handleSave(model: ControlsExw, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const controlEXW = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues?.securityValues?.client,
          cuenta: filterValues?.securityValues?.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          controlEXW: controlEXW,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: ControlsExw, filterValues: any) {
    try {
      const controlEXW = {
        ...model,
        pais: filterValues.securityValues.country,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        controlEXW: controlEXW,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: ControlsExw, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        controlEXW: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<ControlsExw[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter?.securityValues?.client,
          cuenta: filter?.securityValues?.account,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<ControlsExw> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          controlEXW: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarca(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/marca",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadMercado(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/mercado",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadPrestacion(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/contable-rutina-prestacion",
          {}
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadSubCuenta(filter): Promise<any[]> {
    try {
      if (filter.securityValues.client && filter.securityValues.account) {
        const { status, data } = await sipcoAxiosService.get(
          "/get-subcuenta-options",
          {
            params: {
              codCliente: filter.securityValues.client,
              codCuenta: filter.securityValues.account,
              codIdioma: filter.securityValues.language,
              screenCode: user?.permisos,
              level: user?.nivel,
            },
            headers: {
              Authorization: `Bearer ${user?.jwtToken}`,
            },
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadDestino(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/destino",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadConcesionario(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/concesionario",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadClienteExw(filter): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/cliente",
        {}
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<ControlsExw>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="12030" alt="Control EXW" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
        },
      }}
      dataTableColumns={[
        {
          field: "marca.descripcion",
          header: <LanguageProvider id="23888" alt="Marca" />,
        },
        {
          field: "mercado.descripcion",
          header: <LanguageProvider id="367" alt="Mercado" />,
        },
        {
          field: "prestacion",
          header: <LanguageProvider id="22724" alt="Prestacion" />,
        },
        {
          field: "subcuenta",
          header: <LanguageProvider id="4791" alt="Subcuenta" />,
        },
        {
          field: "destino.descripcion",
          header: <LanguageProvider id="3410" alt="Destino" />,
        },
        {
          field: "concesionario.descripcion",
          header: <LanguageProvider id="2941" alt="Concesionario" />,
        },
        {
          field: "clienteExw.descripcion",
          header: <LanguageProvider id="12040" alt="Cliente EXW" />,
        },
        {
          field: "adicionaSubprestacion",
          header: <LanguageProvider id="22642" alt="Subprestación" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        cuenta: null,
        marca: null,
        mercado: null,
        prestacion: null,
        subcuenta: null,
        destino: null,
        concesionario: null,
        clienteExw: null,
        adicionaSubprestacion: "",
      }}
      formSettings={[
        {
          field: "marca",
          label: <LanguageProvider id="23888" alt="Marca" />,
          dropDownPlaceholder: LanguageProvider({
            id: "23888",
            alt: "Marca",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadMarca(filter),
          dropDownTemplate: DropdownInactivoTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "mercado",
          label: <LanguageProvider id="367" alt="Mercado" />,
          dropDownPlaceholder: LanguageProvider({
            id: "367",
            alt: "Mercado",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadMercado(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country"],
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "prestacion",
          label: <LanguageProvider id="22724" alt="Prestacion" />,
          dropDownPlaceholder: LanguageProvider({
            id: "22724",
            alt: "Prestacion",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadPrestacion(filter),
          dependency: ["securityValues.country"],
          disableOnUpdate: true,
          virtualizeDropdown: true,
          required: true,
        },
        {
          field: "subcuenta",
          label: <LanguageProvider id="4791" alt="Subcuenta" />,
          dropDownPlaceholder: LanguageProvider({
            id: "4791",
            alt: "Subcuenta",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadSubCuenta(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: [
            "securityValues.country",
            "securityValues.client",
            "securityValues.subaccount",
          ],
          virtualizeDropdown: true,
        },
        {
          field: "destino",
          label: <LanguageProvider id="3410" alt="Destino" />,
          dropDownPlaceholder: LanguageProvider({
            id: "3410",
            alt: "Destino",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadDestino(filter),
          dropDownTemplate: DropdownInactivoTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
          disableOnUpdate: true,
          virtualizeDropdown: true,
          required: true,
        },
        {
          field: "concesionario",
          label: <LanguageProvider id="2941" alt="Concesionario" />,
          dropDownPlaceholder: LanguageProvider({
            id: "2941",
            alt: "Concesionario",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadConcesionario(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
          disableOnUpdate: true,
          virtualizeDropdown: true,
          required: true,
        },
        {
          field: "clienteExw",
          label: <LanguageProvider id="12040" alt="Cliente EXW" />,
          dropDownPlaceholder: LanguageProvider({
            id: "12040",
            alt: "Cliente EXW",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadClienteExw(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["concesionario"],
          disableOnUpdate: true,
          virtualizeDropdown: true,
          required: true,
        },
        {
          field: "adicionaSubprestacion",
          label: <LanguageProvider id="22642" alt="Subprestación" />,
          type: "inputText",
          length: 2,
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default ControlExw;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};

const DropdownInactivoTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div
        className="flex align-items-center"
        style={{ color: option.activo ? "inherit" : "red" }}
      >
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props?.placeholder}</span>;
};
