import React, { useEffect, useRef, useState } from "react";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { Filter } from "@shared/components/filter/filter";
import { AccordionTab } from "primereact/accordion";
import { TabPanel, TabView } from "primereact/tabview";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoColumn from "@shared/components/sipco-column";
import { format } from "date-fns";
import { Panel } from "primereact/panel";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Calendar } from "primereact/calendar";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import './OfertasServicioTerceros.scss';
import { CaratulaOfertaServicio } from "src/models/CaratulaOfertaServicio";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { HttpStatusCode } from "axios";
import { Button } from "primereact/button";
import NuevoOs from "./modal/NuevoOs";
import ModificarCaratula from "./modal/ModificarCaratula";

const OfertasServicioTerceros = () => {
  const SCREEN_CODE = "abm0249_ofertas_servicio_terceros";
  ScreenCodeValue(SCREEN_CODE);     
  usePageViews();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const [reporte, setReporte] = useState(null);
  const [visibleNuevo, setVisibleNuevo] = useState(false);
  const [visibleModificar, setVisibleModificar] = useState(false);
  const toast = useRef<Toast>(null);
  const dt = useRef(null);
  const data = convertDateObjects(reporte?.filas);

  const [cliente, setCliente] = useState(null);
  const [loadingCliente, setLoadingCliente] = useState(false);

  const [loading, setLoading] = useState(false);
  const [grilla, setGrilla] = useState([]);

  const [loadingGrillaDetalle, setLoadingGrillaDetalle] = useState(false);
  const [grillaDetalle, setGrillaDetalle] = useState([]);

  const [loadingGrillaDocumentos, setLoadingGrillaDocumentos] = useState(false);
  const [grillaDocumentos, setGrillaDocumentos] = useState([]);


  const [selectedDetalle, setSelectedDetalle] = useState([]);
  const [selectedRowsGrilla, setSelectedGrilla] = useState([]);


  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  const columnsGrillaDocumentosAsociados = [
    { field: "id", header: "ID" },
    { field: "pais.descripcion", header: "País" },
    { field: "caratula.id", header: "Nro. Oferta Servicio" },
    { field: "nombreArchivo", header: "Nombre Archivo" },
    { field: "fechaCarga.date", header: "Fecha Carga" },
    { field: "fechaUltimaModificacion.date", header: "Fec. Últ. Modificacion" },
    { field: "usuarioUltimaModificacion.id", header: "Usuario Últ. Modificacion" },
  ];

  const columnsGrillaDetalle = [
    { field: "vin.id", header: "VIN" },
    { field: "vin.patente", header: "Patente" },
    { field: "vin.marca.descripcion", header: "Marca" },
    { field: "vin.modelo.descripcion", header: "Modelo" },
    { field: "puntoOrigen.descripcion", header: "Punto orígen" },
    { field: "puntoDestino.descripcion", header: "Punto destino" },
    { field: "estado.descripcion", header: "Estado" },
    { field: "usuarioUltimaModificacion.id", header: "Usuario Carga" },
    { field: "fechaUltimaModificacion.date", header: "Fecha Carga" },
  ];

  const grillaCabecera = [
    { field: "id", header: "Nro. Oferta Servicio" },
    { field: "cliente.descripcion", header: "Cliente" },
    { field: "fechaCotizacion.date", header: "Fecha Cotizacion" },
    { field: "fechaDeseada.date", header: "Fecha Deseada" },
    { field: "contacto", header: "Contacto" },
    { field: "mailContacto", header: "Email Contacto" },
    { field: "observacion", header: "Observacion" },
    { field: "referenciaCliente", header: "Referencia Cliente" },
    { field: "fechaAceptacion.date", header: "Fecha Aceptacion" },
    { field: "estado.descripcion", header: "Estado" },
    { field: "fechaUltimaModificacion.date", header: "Fecha Ultima Modificacion" },
    { field: "usuarioUltimaModificacion.id", header: "Usuario Ultima Modificacion" },
  ];

  const [filter, setFilter] = useState<CaratulaOfertaServicio>({
    id: '',
    activo: true,
    cliente: null,
    contacto: '',
    detalleOsPlaya: [],
    estado: null,
    fechaUltimaModificacion: null,
    mailContacto: '',
    observacion: '',
    referenciaCliente: '',
    usuarioUltimaModificacion: null,
    vin: null,
    fechaCotizacion: new Date(),
    fechaDeseada: new Date(),
    fechaAceptacion: new Date(),
  });


  // Filters 
  async function clienteDropDown() {
    try {
      if (
        securityFilters?.securityValues?.country
      ) {
        setLoadingCliente(true);
        const { status, data } = await sipcoAxiosService.post(
          "ofertas-servicio-terceros/cliente-service",
          {
            pais: securityFilters.securityValues.country
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: null, descripcion: "TODOS" });
          setCliente(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCliente(false);
    }
  }

  async function gridDetalle(selected: any[]) {
    try {
      if (selected.length !== 0) {
        setLoadingGrillaDetalle(true)
        const { status, data } = await sipcoAxiosService.post(
          "ofertas-servicio-terceros/detalle-os-playa",
          {
            caratulaOfertaServicio: selected[0]
          }
        );
        if (status === HttpStatusCode.Ok) {
          setGrillaDetalle(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrillaDetalle(false);
    }
  }

  async function gridDocumentos(selected: any[]) {
    try {
      if (selected.length !== 0) {
        setLoadingGrillaDocumentos(true);
        const { status, data } = await sipcoAxiosService.post(
          "ofertas-servicio-terceros/documento-os-playa",
          {
            caratulaOfertaServicio: selected[0]
          }
        );
        if (status === HttpStatusCode.Ok) {
          setGrillaDocumentos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrillaDocumentos(false);
    }
  }

  async function filterGrid() {
    try {
      if (securityFilters?.securityValues?.country && securityFilters?.securityValues?.account) {
        setLoading(true);
        const { status, data } = await sipcoAxiosService.post(
          "ofertas-servicio-terceros/grilla-cabeceira",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            vin: filter.vin,
            nroOferta: filter.id,
            cliente: filter.cliente,
            activo: filter.activo,
            fechaDesde: new Date(filter.fechaDeseada).getTime(),
            fechaHasta: new Date(filter.fechaAceptacion).getTime(),
            descCliente: filter.referenciaCliente
          }
        );
        if (status === HttpStatusCode.Ok) {
          setGrilla(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function loadGrid(value: any[]) {
    try {
      console.log(value)
      setSelectedGrilla(value);
      const [datalle, documentos] = await Promise.all([
        gridDetalle(value),
        gridDocumentos(value)
      ]);

      return { detalle: datalle, documentos: documentos };
    } catch (error) {
      console.error(error)
    } finally {

    }
  }


  // Functions 

  async function cancelarOs(selected: any[]) {
    try {
      if (selected.length !== 0) {
        setLoadingGrillaDocumentos(true);
        const { status, data } = await sipcoAxiosService.post(
          "ofertas-servicio-terceros/cancelar-os",
          {
            caratulaOfertaServicio: selected[0]
          }
        );
        if (status === HttpStatusCode.Ok) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrillaDocumentos(false);
    }
  }

  async function cerrarOs(selected: any[]) {
    try {
      if (selected.length !== 0) {
        setLoadingGrillaDocumentos(true);
        const { status, data } = await sipcoAxiosService.post(
          "ofertas-servicio-terceros/cerrar-os",
          {
            caratulaOfertaServicio: selected[0]
          }
        );
        if (status === HttpStatusCode.Ok) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrillaDocumentos(false);
    }
  }


  // Effects  
  useEffect(() => {
    const init = async () => {
      try {
        const clienteLabel = clienteDropDown();

        setFilter({
          ...filter,
          cliente: clienteLabel,
        })
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [securityFilters.securityValues]);

  function handlerModificar() {
    if (selectedRowsGrilla.length !== 0) {
      setVisibleModificar(true);
    } else {
      toast.current?.show({
        severity: "error",
        detail: "Seleccione una oferta de servicio",
        life: 3000
      });
    }
  }

  return (
    <>
      <div className="ofertas-servicio-terceros">
        <Toast ref={toast} position="top-center" />
        <Filter
          securityOptions={{
            account: true,
            country: true,
            subaccount: true,
            screenCode: SCREEN_CODE,
          }}
          onSearch={filterGrid}
        >
          <AccordionTab
            header={
              <LanguageProvider
                id={"7795"}
                alt="Filtrar Por:"
              />
            }
          >
            <div className="form">
              <div className="form-row">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"16563"} alt="Nro. Oferta Servicio" />
                  </label>
                  <SipcoInputText
                    value={filter?.id}
                    onChange={(e) => setFilter({ ...filter, id: e.target.value })}
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"18997"} alt="Desc. Cliente" />
                  </label>
                  <SipcoInputText
                    value={filter?.referenciaCliente}
                    onChange={(e) => setFilter({ ...filter, referenciaCliente: e.target.value })}
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"2621"} alt="Desde" />
                  </label>
                  <Calendar
                    value={filter?.fechaDeseada}
                    onChange={(e) => setFilter({ ...filter, fechaDeseada: e.value })}
                    showIcon
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"2622"} alt="Hasta" />
                  </label>
                  <Calendar
                    value={filter?.fechaAceptacion}
                    onChange={(e) => setFilter({ ...filter, fechaAceptacion: e.value })}
                    showIcon
                  />
                </div>
              </div>

              <div className="form-row">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"662"} alt="Ingrese el VIN" />
                  </label>
                  <SipcoAutocomplete
                    value={filter?.vin}
                    suggestions={null}
                    completeMethod={null}
                    dropdown
                    forceSelection
                  />
                </div>
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"3750"} alt="Cliente" />
                  </label>
                  <SipcoDropdown
                    value={filter?.cliente}
                    options={cliente}
                    loading={loadingCliente}
                    onChange={(e) => setFilter({ ...filter, cliente: e.target.value })}
                    optionLabel="descripcion"
                  />
                </div>
              </div>

            </div>

          </AccordionTab>

        </Filter>


        <Panel
          headerTemplate={(props) => {
            return (
              <div className={props.className} onClick={props.onTogglerClick}>
                <span className={props.titleClassName}>
                  <LanguageProvider id="22778" alt="Ofertas Servicio Terceros" />
                </span>
              </div>
            );
          }}
        >
          <SipcoDataTable
            size={"small"}
            showGridlines
            stripedRows       
            ref={dt}
            value={grilla}      
            paginator 
            scrollHeight="flex"             
            filterDisplay="menu"      
            rows={8}        
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
            selectionMode="multiple"
            selection={selectedRowsGrilla}
            onSelectionChange={(e: any) => loadGrid(e.value)}
            metaKeySelection={true}         
            dragSelection
          >
            {grillaCabecera.map((colum, index) => {
              if (colum.header === "Fecha Cotizacion"         
                || colum.header === "Fecha Aceptacion"
                || colum.header === "Fecha Deseada"
                || colum.header === "Fecha Entrega"
                || colum.header === "Fecha Ultima Modificacion") {
                return (        
                  <SipcoColumn
                    key={index}
                    field={colum.field}
                    body={(e) => dateColumnTemplate(e, colum.field)}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              } else {
                return (
                  <SipcoColumn
                    key={index}
                    field={colum.field}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              }
            })}
          </SipcoDataTable>

          <TabView>
            <TabPanel
              header={<LanguageProvider id={"3397"} alt="Detalle" />}
            >
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={grillaDetalle}
                paginator
                scrollHeight="flex"
                filterDisplay="menu"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                style={{ maxWidth: "100%" }}
                loading={loadingGrillaDetalle}
                selectionMode="multiple"
                selection={selectedDetalle}
                onSelectionChange={(e) => setSelectedDetalle(e.value)}
                metaKeySelection={true}
                dragSelection
              >
                {columnsGrillaDetalle.map((colum, index) => {
                  if (colum.header === "Fecha Carga") {
                    return (
                      <SipcoColumn
                        key={index}
                        field={colum.field}
                        body={(e) => dateColumnTemplate(e, colum.field)}
                        header={colum.header}
                        sortable
                        filter
                      />
                    );
                  } else {
                    return (
                      <SipcoColumn
                        key={index}
                        field={colum.field}
                        header={colum.header}
                        sortable
                        filter
                      />
                    );
                  }
                })}
              </SipcoDataTable>
            </TabPanel>

            <TabPanel
              header={<LanguageProvider id={"22841"} alt="Documentos Asociados" />}
            >
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={grillaDocumentos}
                paginator
                scrollHeight="flex"
                filterDisplay="menu"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                style={{ maxWidth: "100%" }}
                loading={loadingGrillaDocumentos}
                selectionMode="multiple"
                selection={selectedDetalle}
                onSelectionChange={(e) => setSelectedDetalle(e.value)}
                metaKeySelection={true}
                dragSelection
              >
                {columnsGrillaDocumentosAsociados.map((colum, index) => {
                  if (colum.header === "Fecha Carga"
                    || colum.header === "Fec. Últ. Modificacion"
                  ) {
                    return (
                      <SipcoColumn
                        key={index}
                        field={colum.field}
                        body={(e) => dateColumnTemplate(e, colum.field)}
                        header={colum.header}
                        sortable
                        filter
                      />
                    );
                  } else {
                    return (
                      <SipcoColumn
                        key={index}
                        field={colum.field}
                        header={colum.header}
                        sortable
                        filter
                      />
                    );
                  }
                })}
              </SipcoDataTable>
            </TabPanel>
          </TabView>

          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <Button
                  label={LanguageProvider({
                    id: "22789",
                    alt: "Nueva OS",
                  })}
                  className="Button-option"
                  onClick={() => setVisibleNuevo(true)}
                />

              </div>
              <div className="sipco-option">
                <Button
                  label={LanguageProvider({
                    id: "22790",
                    alt: "Cancelar OS",
                  })}
                  className="Button-option"
                  onClick={() => cancelarOs(selectedRowsGrilla)}
                />
              </div>
              <div className="sipco-option">
                <Button
                  label={LanguageProvider({
                    id: "22791",
                    alt: "Modificar Carátula",
                  })}
                  className="Button-option"
                  onClick={() => handlerModificar()}
                />
              </div>
              <div className="sipco-option">
                <Button
                  label={LanguageProvider({
                    id: "22792",
                    alt: "Cargar / Modificar detalle",
                  })}
                  className="Button-option"
                />
              </div>
              <div className="sipco-option">
                <Button
                  label={LanguageProvider({
                    id: "23516",
                    alt: "Cerrar OS",
                  })}
                  className="Button-option"
                  onClick={() => cerrarOs(selectedRowsGrilla)}
                />
              </div>
            </div>
          </div>
        </Panel>
      </div>
      <NuevoOs
        className={"ofertas-servicio-terceros"}
        visible={visibleNuevo}
        onClose={() => setVisibleNuevo(false)}
        securityFilters={securityFilters}
      />
      <ModificarCaratula
        className={"ofertas-servicio-terceros"}
        visible={visibleModificar}
        selected={selectedRowsGrilla}
        onClose={() => setVisibleModificar(false)}
        securityFilters={securityFilters}
      />
    </>
  );
}

export default OfertasServicioTerceros;



