import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useRef } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ModeloRadioModeloAuto } from "src/models/ModeloRadioModeloAuto";
import { Toast } from "primereact/toast";

const MantenerTapizados = () => {
  const SCREEN_CODE = "abm0057_mantener_tapizados";
  const URL_BASE = "/mantener-tapizados";
  const SCREEN_TITLE = (
    <LanguageProvider id="270" alt="Relacionar Modelos de Autos y Radios" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const marcaEmpty = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - ${LanguageProvider({ id: "396", alt: "Marca" })} `;

  async function handleSave(model: ModeloRadioModeloAuto, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client
      ) {
        const tapizado = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          marca: filterValues?.marca,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          tapizado,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ModeloRadioModeloAuto, filterValues: any) {
    try {
      const tapizado = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        marca: filterValues?.marca,
        modelo: filterValues?.modelo,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        tapizado,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: ModeloRadioModeloAuto, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        tapizado: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ModeloRadioModeloAuto[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.marca
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            marca: filter.marca,
          }
        );
        if (status === 200)
          if (data.length > 0) {
            return data;
          } else {
            return [];
          }
      } catch (error) {
        console.error(error);
      }
    }
    toast.current?.show({
      severity: "warn",
      detail: marcaEmpty,
      life: 3000,
    });
    return [];
  }
  async function handleGet(model: any): Promise<ModeloRadioModeloAuto> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          tapizado: { ...model },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarca(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/marca",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ModeloRadioModeloAuto>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
          },
        }}
        filterSettings={[
          {
            filter: "marca",
            label: LanguageProvider({ id: "396", alt: "Marca" }),
            placeholder: LanguageProvider({ id: "396", alt: "Marca" }),
            type: "dropdown",
            dataSource: loadMarca,
            dropDownTemplate: DropdownOptionTemplate,
            dependency: ["securityValues.country", "securityValues.client"],
            required: true,
          },
        ]}
        dataTableColumns={[
          {
            field: "id",
            header: <LanguageProvider id="6590" alt="Código" />,
          },
          {
            field: "descripcion",
            header: <LanguageProvider id="12419" alt="x" />,
          },
          {
            field: "cliente.descripcion",
            header: <LanguageProvider id="54" alt="x" />,
          },
          {
            field: "marca.descripcion",
            header: <LanguageProvider id="396" alt="x" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => {
              return (
                <span>
                  {new Date(
                    rowData.fechaUltimaModificacion.timestamp
                  ).toLocaleString()}
                </span>
              );
            },
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => {
              return rowData.activo ? "SI" : "NO";
            },
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          activo: true,
          pais: null,
          cliente: null,
          modelo: null,
          modeloRadio: null,
        }}
        // loadDropdownData={{}}
        formSettings={[
          {
            field: "id",
            label: LanguageProvider({ id: "6590", alt: "Código" }),
            type: "inputText",
            length: 4,
            capitalizeInputText: true,
          },
          {
            field: "descripcion",
            label: LanguageProvider({ id: "12419", alt: "Descripcion" }),
            type: "inputText",
            length: 50,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default MantenerTapizados;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
