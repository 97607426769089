import React, { useEffect, useRef, useState } from "react";
import { LanguageProvider } from "@shared/components/language-provider";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import "./modal-geral.scss";
import ExportButtons from "@shared/components/Export";
import MarcasAltaModificar from "./MarcasAltaModificar";

export function Marcas({ titleModal, setTitleModal, userData }) {
  const dt = useRef(null);

  const sipcoAxiosService = useSipcoAxiosService();
  const [marcaSelectedObject, setMarcaSelectedObject] = useState(null);
  const [listConcesionarios, setListConcesionarios] = useState([]);
  const [loadingConc, setLoadingConc] = useState(true);
  const [showModal, setShowModal] = useState("");

  useEffect(() => {
    findMarcasBy();
  }, []);

  async function findMarcasBy() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/findMarcasBy",
        {
          params: {
            codPais: userData.pais.id,
            codUsuario: userData.id,
          },
        }
      );
      if (status === 200) {
        setListConcesionarios(data);
        console.log("LISTA DE MARCAS: ", data);
        setLoadingConc(false);
      }
    } catch (error) {
      setLoadingConc(false);
      console.error(error);
    }
  }

  function dateTime(dateTime) {
    const [date, time] = dateTime.split("T");
    console.log(date, time);

    const [year, month, day] = date.split("-");
    const [anyTime, z] = time.split(".");

    const formatedDate = `${day}/${month}/${year}`;
    return formatedDate + " " + anyTime;
  }

  async function remover() {
    //TODO popup confirmação

    try {
      const { status, data } = await sipcoAxiosService.post(
        `/mantenerUsuario/deleteMarcaUsuario`,
        marcaSelectedObject
      );

      if (status === 200) {
        // toast.current.show({
        //   severity: "success",
        //   summary: "Success",
        //   detail: "Carrera forzada con éxito!",
        //   life: 3000,
        // });
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={"modal-geral"}>
      <Dialog
        header={"Listado"}
        visible={titleModal}
        maximizable
        style={{ width: "70vw", height: "60vh" }}
        footer={""}
        onHide={() => {
          if (!titleModal) return;
          setTitleModal(false);
        }}
      >
        <div className="cp-option-modal-alignVert">
          
            <div className="m-0" style={{ padding: "1rem", width: "100%" }}>
              <DataTable
                showGridlines
                stripedRows
                paginator
                scrollable
                scrollHeight="flex"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                resizableColumns
                removableSort
                tableStyle={{ width: "100%", height: "100%" }}
                value={listConcesionarios}
                selectionMode={"single"}
                selection={marcaSelectedObject}
                metaKeySelection={false}
                loading={loadingConc}
                onSelectionChange={(e) => {
                  setMarcaSelectedObject(e.value);
                }}
              >
                <Column
                  field="cliente.descripcion"
                  header={<LanguageProvider id={"TODO"} alt="Cliente" />}
                ></Column>
                <Column
                  field="marca.descripcion"
                  header={<LanguageProvider id={"TODO"} alt="Marca" />}
                ></Column>

                <Column
                  field="usuarioUltimaModificacion.id"
                  header={
                    <LanguageProvider
                      id={"TODO"}
                      alt="Usuario Últ. Modificacion"
                    />
                  }
                  body={(rowData) => (
                    <span>{`(${rowData.usuarioUltimaModificacion.id})`}</span>
                  )}
                ></Column>
                <Column
                  field="fechaUltimaModificacion.date"
                  header={
                    <LanguageProvider
                      id={"TODO"}
                      alt="Fec. Últ. Modificación"
                    />
                  }
                  body={(rowData) =>
                    dateTime(rowData.fechaUltimaModificacion.timestamp)
                  }
                ></Column>
                <Column
                  field=""
                  header={<LanguageProvider id={"TODO"} alt="Activo" />}
                  body={(rowData) => (
                    <span>{rowData.activo ? "Sí" : "No"}</span>
                  )}
                ></Column>
              </DataTable>
            </div>
          
          <div className="orgHor">
            <div className="">
              <Button
                label={LanguageProvider({
                  id: "TODO",
                  alt: "Alta",
                })}
                onClick={() => {
                  setShowModal("Marca - Alta");
                }}
                text
              />
              <Button
                label={LanguageProvider({
                  id: "TODO",
                  alt: "Modificar",
                })}
                onClick={() => {
                  if (!marcaSelectedObject || marcaSelectedObject?.length === 0)
                    return alert(
                      "Debe seleccionar al menos un registro de la Grilla."
                    );
                  setShowModal("Marca - Modificar");
                }}
                text
              />
              <Button
                label={LanguageProvider({
                  id: "TODO",
                  alt: "Borrar",
                })}
                onClick={() => {
                  if (!marcaSelectedObject || marcaSelectedObject?.length === 0)
                    return alert(
                      "Debe seleccionar al menos un registro de la Grilla."
                    );
                  remover();
                }}
                text
              />
            </div>

            <ExportButtons
              dt={dt}
              data={[]}
              columns={[]}
              csv={false}
              pdf={false}
              screenName={"Marcas"}
            />
          </div>
        </div>
      </Dialog>
      {showModal && (
        <MarcasAltaModificar
          showModal={showModal}
          setShowModal={setShowModal}
          userData={userData}
          marca={marcaSelectedObject}
        />
      )}
    </div>
  );
}

export default Marcas;
