import "./Compras-Taller.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../protected-routes";
import { useNavigate } from "react-router-dom";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";


// Filtro não está sendo carregado corretamente, apesar de estár configurado
export function FunComprasTaller() {
  const SCREEN_CODE = "fun0083_compras_taller";
  ScreenCodeValue(SCREEN_CODE);
  const navigate = useNavigate();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [taller, setTaller] = useState([]);
  const [loadingTaller, setLoadingTaller] = useState(false);

  const [compraTaller, setCompraTaller] = useState([]);

  function navigateFunction(selected : any[], objects: {}, ways: string){
    if(selected.length !== 0){
      navigate(ways, objects)
    }else{
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: <LanguageProvider id={"753"} alt="Debe seleccionar al menos un registro de la Grilla." />,
        life: 3000,
      });
    }
  }

  const [filter, setFilter] = useState({
    taller: null,
    codCompra: null,
  });

  const data = convertDateObjects(reporte?.filas);

  const columns = [];

  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];
  const filterColumnsId = [
    16949, 2518, 11833, 2519, 16483, 16480, 8627, 3756, 16968, 3756, 16481,
    16482, 3756, 13706, 23848,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  async function loadTaller() {
    try {
      setLoadingTaller(true);
      const { status, data } = await sipcoAxiosService.post(
        "/compras-taller/taller",
        {
          pais: securityFilters.securityValues.country,
          cuenta: securityFilters.securityValues.account,
          subcuenta: securityFilters.securityValues.subaccount,
        }
      );
      if (status === 200) {
        setTaller(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      const taller = await loadTaller();

      setFilter({
        ...filter,
        taller: taller,
      });
    };
    init();
  }, [securityFilters.securityValues]);

  async function loadReportData() {
    try {
      setLoading(true);
      const reportResult = await sipcoAxiosService.post(
        "/compras-taller/findBy",
        {
          codPais: securityFilters.securityValues?.country.id,
          codCuenta: securityFilters.securityValues?.account.id,
          codSubcuenta: securityFilters.securityValues?.subaccount.id,
          codTaller: filter.taller.id,
        }
      );

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === 200 && data) {
          const response = data.map((x: any) => {
            return {
              ...x,
              codCompra: x?.id,
              tipoTaller: x?.taller?.id,
              descripcion: x?.tipoCompra.descripcion,
              fechaCompra: x?.fechaUltimaModificacion.date,
              codEstado: x?.estado.descripcion,
              nroPedidoPSA: x?.nroPedidPsa,
              codProveedor: x?.proveedor.id,
              descripcionProveedor: x?.proveedor.descripcion,
              codCliente: x?.cliente.id,
              descripcionCliente: x?.cliente.descripcion,
              codRemito: x?.remito,
              codMoneda: x?.moneda.id,
              descripcionMoneda: x?.moneda.descripcion,
            };
          });

          setCompraTaller(response);
          setReporte(data);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsNamesTable = [
    { field: "codCompra", header: "Codigo Compra" },
    { field: "tipoTaller", header: "Tipo Taller" },
    { field: "descripcion", header: "Descripcion" },
    { field: "fechaCompra", header: "Fecha Compra" },
    { field: "codEstado", header: "Estado" },
    { field: "nroPedidoPSA", header: "Nro Pedido PSA" },
    { field: "codProveedor", header: "Proveedor" },
    { field: "descripcionProveedor", header: "Descripcion Proveedor" },
    { field: "codCliente", header: "Cliente" },
    { field: "descripcionCliente", header: "Descripcion Cliente" },
    { field: "codRemito", header: "Remito" },
    { field: "codMoneda", header: "Moneda" },
    { field: "descripcionMoneda", header: "Descripcion Moneda" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  const objectsValues = {
    state: {
      pais: securityFilters?.securityValues?.country,
      cuenta: securityFilters?.securityValues?.account,
      subcuenta: securityFilters?.securityValues?.subaccount,
      codCompra: selectedRows.map((x) => x?.codCompra) || "",
      tallerFilter: filter?.taller,
    },
  };

  return (
    <div className="orden-reparacion">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"327"} alt="Taller" />}>
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"327"} alt="Taller: " />
                </label>
                <Dropdown
                  value={filter.taller}
                  options={taller}
                  onChange={(e) => {
                    setFilter({ ...filter, taller: e.value });
                  }}
                  optionLabel="descripcion"
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Listado</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              <div>
                <Button
                  label={LanguageProvider({ id: "25", alt: "Alta" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "27", alt: "Modificar" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "12899", alt: "Ver" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "2016", alt: "Borrar" })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "2598",
                    alt: "Cerrar y facturar Compra",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "6388",
                    alt: "Repuesto",
                  })}
                  text
                  onClick={() => navigateFunction(selectedRows, objectsValues, "/repuestos-compras-taller")}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={compraTaller}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e: any) => setSelectedRows(e.value)}
          metaKeySelection={true}
          dragSelection
        >
          {columnsNamesTable.map((colum, index) => {
            if (colum.header === "Fecha Compra") {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </DataTable>
      </Panel>
    </div>
  );
}
