import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useState } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Pregunta } from "src/models/Pregunta";

const Preguntas = () => {
  const SCREEN_CODE = "abm0225_preguntas";
  const URL_BASE = "/preguntas";
  const sipcoAxiosService = useSipcoAxiosService();

  const [operacion, setOperacion] = useState(null);

  async function handleSave(model: Pregunta, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const pregunta = {
          ...model,
          pais: filterValues.securityValues.country,
          operacion: operacion,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          pregunta: pregunta,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: Pregunta, filterValues: any) {
    try {
      const pregunta = {
        ...model,
        pais: filterValues.securityValues.country,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        pregunta: pregunta,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: Pregunta) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        pregunta: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<Pregunta[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          operacion: operacion,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<Pregunta> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          pregunta: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadOperacion(filter: any): Promise<[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/operacion",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadAsunto(filter: any): Promise<[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/asunto",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<Pregunta>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="9225" alt="Preguntas" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
        },
      }}
      filterSettings={[
        {
          filter: "usuario",
          label: LanguageProvider({ id: "1569", alt: "Operación" }),
          placeholder: LanguageProvider({ id: "1569", alt: "Operación" }),
          type: "dropdown",
          dataSource: loadOperacion,
          dropDownTemplate: DropdownInactivoTemplate,
          onChange: (e) => {
            setOperacion(e);
          },
          virtualizeDropdown: true,
          dependency: ["securityValues.country"],
        },
      ]}
      dataTableColumns={[
        {
          field: "id",
          header: <LanguageProvider id="23876" alt="Código" />,
        },
        {
          field: "descripcion",
          header: <LanguageProvider id="31" alt="Descripción" />,
        },
        {
          field: "solicitaFoto",
          header: <LanguageProvider id="19871" alt="Solicita foto" />,
          body: (rowData: any) => {
            return rowData.solicitaFoto ? "SI" : "NO";
          },
        },
        {
          field: "obligaFoto",
          header: <LanguageProvider id="19872" alt="Obliga foto" />,
          body: (rowData: any) => {
            return rowData.obligaFoto ? "SI" : "NO";
          },
        },
        {
          field: "ordenPregunta",
          header: <LanguageProvider id="5701" alt="Nro. Orden" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "naNo",
          header: <LanguageProvider id="22454" alt="No se Aplica = No" />,
          body: (rowData: any) => {
            return rowData.naNo ? "SI" : "NO";
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        pais: null,
        operacion: null,
        asunto: null,
        descripcion: "",
        indCompl1: "",
        descCompl1: "",
        indCompl2: "",
        descCompl2: "",
        indCompl3: "",
        descCompl3: "",
        indCompl4: "",
        descCompl4: "",
        ordenPregunta: null,
        solicitaFoto: false,
        obligaFoto: false,
        naNo: false,
        activo: true,
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="23876" alt="Código" />,
          type: "inputText",
          disabled: true,
          required: true,
        },
        {
          field: "asunto",
          label: <LanguageProvider id="10434" alt="Asunto" />,
          dropDownPlaceholder: LanguageProvider({
            id: "10434",
            alt: "Asunto",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadAsunto(filter),
          dropDownTemplate: DropdownInactivoTemplate,
          dependency: ["securityValues.country"],
          required: true,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="31" alt="Descripción" />,
          type: "inputText",
          required: true,
        },
        {
          field: "indCompl1",
          label: (
            <>
              <LanguageProvider id="19873" alt="Complemento" /> 1
            </>
          ),
          type: "inputText",
          length: 4,
        },
        {
          field: "descCompl1",
          label: <LanguageProvider id="31" alt="Descripción" />,
          type: "inputText",
        },
        {
          field: "indCompl2",
          label: (
            <>
              <LanguageProvider id="19873" alt="Complemento" /> 2
            </>
          ),
          type: "inputText",
          length: 4,
        },
        {
          field: "descCompl2",
          label: <LanguageProvider id="31" alt="Descripción" />,
          type: "inputText",
        },
        {
          field: "indCompl3",
          label: (
            <>
              <LanguageProvider id="19873" alt="Complemento" /> 3
            </>
          ),
          type: "inputText",
          length: 4,
        },
        {
          field: "descCompl3",
          label: <LanguageProvider id="31" alt="Descripción" />,
          type: "inputText",
        },
        {
          field: "descCompl2",
          label: <LanguageProvider id="31" alt="Descripción" />,
          type: "inputText",
        },
        {
          field: "indCompl4",
          label: (
            <>
              <LanguageProvider id="19873" alt="Complemento" /> 4
            </>
          ),
          type: "inputText",
          length: 4,
        },
        {
          field: "descCompl4",
          label: <LanguageProvider id="31" alt="Descripción" />,
          type: "inputText",
        },
        {
          field: "ordenPregunta",
          label: <LanguageProvider id="11370" alt="Orden" />,
          min: 0,
          max: 99999,
          type: "inputNumber",
          required: true,
        },
        {
          field: "solicitaFoto",
          label: <LanguageProvider id="19871" alt="Solicita foto" />,
          type: "checkbox",
        },
        {
          field: "obligaFoto",
          label: <LanguageProvider id="19872" alt="Obliga foto" />,
          type: "checkbox",
        },
        {
          field: "naNo",
          label: <LanguageProvider id="22454" alt="No se Aplica = No" />,
          type: "checkbox",
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default Preguntas;

const DropdownInactivoTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div
        className="flex align-items-center"
        style={{ color: option.activo ? "inherit" : "red" }}
      >
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props?.placeholder}</span>;
};
