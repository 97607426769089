import "./RegistracionOperacionAutomatica.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import Lectora from "./components/Lectora";
import Manual from "./components/Manual";
import Masiva from "./components/Masiva";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { HttpStatusCode } from "axios";

export function RegistracionOperacionAutomatica() {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const SCREEN_CODE = "fun0205_registracion_operacion_automatica";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const [filter, setFilter] = useState({
    vin: "",
    fechaRealizacion: new Date(),
  });
  const [activeIndex, setActiveIndex] = useState(0);


  const [manualState, setManualState] = useState(false);
  const [lectoraState, setLectoraState] = useState(false);
  const [masivaState, setMasivaState] = useState(false);

  async function consultaStock() {
    setLoading(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        filter.vin != ""
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-automatica/vin-stock",
          {
            pais: securityFilters?.securityValues?.country || null,
            cliente: securityFilters?.securityValues?.client || null,
            cuenta: securityFilters?.securityValues?.account || null,
            vin: {
              id: filter.vin,
            },
          }
        );

        if (status === 200) {
          if (data.length > 0) {
            await loadReportManual();
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Vin fora do Stock",
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error al consultar stock",
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  }
  async function handleCalendarChange(event : any) {
    const value = event.target.value;
    setFilter({ ...filter, fechaRealizacion: value });
  }
  async function loadReportManual() {
    setLoading(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        filter.vin != ""
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-automatica/manual/operacion-pendiente",
          {
            pais: securityFilters?.securityValues?.country || null,
            cliente: securityFilters?.securityValues?.client || null,
            cuenta: securityFilters?.securityValues?.account || null,
            vin: {
              id: filter.vin,
            },
          }
        );

        if (status === 200) {
          setReporte(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const [gridManual, setGridManual] = useState([]);
  const [loadingGridManual, setLoadingGridManual] = useState(false);

  async function loadGridManual(vin: any) {
    setLoadingGridManual(true);
    try {
      if (securityFilters?.securityValues?.country !== null
        && securityFilters?.securityValues?.client !== null
        && securityFilters?.securityValues?.account !== null
        && filter.vin) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-automatica/manual/operacion-pendiente",
          {
            pais: securityFilters?.securityValues?.country ?? null,
            cliente: securityFilters?.securityValues?.client ?? null,
            cuenta: securityFilters?.securityValues?.account ?? null,
            vin: { id: vin },
          }
        );

        if (status === HttpStatusCode.Ok) {
          setGridManual(data);
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: "Vin encontrado",
            life: 3000,
          });
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGridManual(false);
    }
  }


  const value = {
    state: {
      pais: securityFilters?.securityValues?.country,
      cliente: securityFilters?.securityValues?.client,
      cuenta: securityFilters?.securityValues?.account,
      subcuenta: securityFilters?.securityValues?.subaccount,
    },
    vin: filter.vin,
    fechaRealizacion: filter.fechaRealizacion,
  }

  function handleStateIndex(index: number) {
    const states = {
      0: { lectora: true, manual: false, masiva: false },
      1: { lectora: false, manual: true, masiva: false },
      2: { lectora: false, manual: false, masiva: true },
    };
  
    setLectoraState(states[index]?.lectora ?? false);
    setManualState(states[index]?.manual ?? false);
    setMasivaState(states[index]?.masiva ?? false);
    setActiveIndex(index);
  }

  return (
    <div className="registracion-operacion-automatica">
      <Toast ref={toast} position="top-center" />
      <Filter
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      />
      <Accordion>
        <AccordionTab header={<LanguageProvider id={"1491"} alt="VIN" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"1491"} alt="VIN" />
                </label>
                <SipcoInputText
                  value={filter.vin}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      loadGridManual(filter.vin);
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    setFilter({ ...filter, vin: e.target.value });
                  }
                  }
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"2781"} alt="Fecha de Realización" />
                </label>
                <SipcoCalendar
                  showIcon
                  showTime
                  showButtonBar
                  value={filter.fechaRealizacion}
                  onChange={handleCalendarChange}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
      <Fieldset
        legend={
          <LanguageProvider
            id={"18330"}
            alt="Registración de Operaciones Automaticas"
          />
        }
      >
        <TabView activeIndex={activeIndex} onTabChange={(e) => handleStateIndex(e.index)}>
          <TabPanel header={<LanguageProvider id={"TODO"} alt="Lectora" />}>
            <Lectora />
          </TabPanel>
          <TabPanel header={<LanguageProvider id={"6850"} alt="Manual" />}>
            <Manual value={value} state={manualState} />
          </TabPanel>
          <TabPanel header={<LanguageProvider id={"TODO"} alt="Masiva" />}>
            <Masiva />
          </TabPanel>
        </TabView>

      </Fieldset>
    </div>
  );
}
export default RegistracionOperacionAutomatica;
