import React, { useRef, useState } from "react";
import GenericAbm, { GenericAbmRef } from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { PerfilTraslado } from "src/models/PerfilTraslado";
import { Dialog } from "primereact/dialog";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import '../functions/compras-taller/components/ModalVerComprasTaller.scss';
import { Toast } from "primereact/toast";

const MantenerPerfilesTranslado = () => {
  const SCREEN_CODE = "abm0193_mantener_perfil_traslado";
  const URL_BASE = "/mantener-perfil-translado";
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function save(abm: any, model: PerfilTraslado) {
    try {
      const perfilTraslado = {
        ...model,
        pais: abmRef.current?.filter?.securityValues?.country,
        cliente: abmRef.current?.filter?.securityValues?.client,
        cuenta: abmRef.current?.filter?.securityValues?.account,
        asignado: model.asignado,
        id: model.id || '',
        noAsignado: model.noAsignado,
        bloqueoCalidad: model.bloqueoCalidad,
        bloqueoTerceros: model.bloqueoTerceros,
        noBloqueoTerceros: model.noBloqueoTerceros,
        nacionalizado: model.nacionalizado,
        noNacionalizado: model.noNacionalizado,
        pendientePPO: model.pendientePPO,
        pendienteTaller: model.pendienteTaller,
        remitoTraslado: model.remitoTraslado,
        remitoDespacho: model.remitoDespacho,
        mercadoLocal: model.mercadoLocal,
        mercadoExportacion: model.mercadoExportacion,
        origenNacional: model.origenNacional,
        origenImportado: model.origenImportado,
        noLiberoAduana: model.noLiberoAduana,
        noExportable: model.noExportable,
      };
      await sipcoAxiosService.post(URL_BASE + "/save", {
        perfilTraslado,
      });
      setDisplayLocalAlta(false);
      toast.current?.show({
        severity: "success",
        detail: (
          <LanguageProvider
            id={"36"}
            alt="Sua transação se realizou com sucesso."
          />
        ),
        life: 3000,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function update(model: any, abm: any) {
    try {
      const modelBody = abmRef.current?.selectedEntries;
      if (!(modelBody.length >= 0)) {
        return false;
      }

      const perfilTraslado = {
        ...model,
        pais: abm.current?.filter?.securityValues?.country,
        cliente: abm.current?.filter?.securityValues?.client,
        cuenta: abm.current?.filter?.securityValues?.account,
        asignado: model.asignado,
        id: modelBody[0].id ?? '',
        noAsignado: model.noAsignado,
        bloqueoCalidad: model.bloqueoCalidad,
        bloqueoTerceros: model.bloqueoTerceros,
        noBloqueoTerceros: model.noBloqueoTerceros,
        nacionalizado: model.nacionalizado,
        noNacionalizado: model.noNacionalizado,
        pendientePPO: model.pendientePPO,
        pendienteTaller: model.pendienteTaller,
        remitoTraslado: model.remitoTraslado,
        remitoDespacho: model.remitoDespacho,
        mercadoLocal: model.mercadoLocal,
        mercadoExportacion: model.mercadoExportacion,
        origenNacional: model.origenNacional,
        origenImportado: model.origenImportado,
        noLiberoAduana: model.noLiberoAduana,
        noExportable: model.noExportable,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        perfilTraslado,
      });

      setDisplayLocalModificar(false);
      toast.current?.show({
        severity: "success",
        detail: (
          <LanguageProvider
            id={"36"}
            alt="Sua transação se realizou com sucesso."
          />
        ),
        life: 3000,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function remove(abm: any) {
    try {
      const model = abm.current?.selectedEntries[0];
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        perfilTraslado: { ...model },
      });
      toast.current?.show({
        severity: "success",
        detail: (
          <LanguageProvider
            id={"36"}
            alt="Sua transação se realizou com sucesso."
          />
        ),
        life: 3000,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  function handleModificar(abm: any) {
    try {
      const get = abm.current?.selectedEntries;
      if (!(get.length >= 0)) {
        return false;
      } else {
        const modelBody = get[0];
        setFilter(modelBody);
        setDisplayLocalModificar(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleList(filter: any): Promise<PerfilTraslado[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter.securityValues.account,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<PerfilTraslado> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          perfilTraslado: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }


  const abmRef = useRef<GenericAbmRef<PerfilTraslado>>(null);
  const [displayLocalAlta, setDisplayLocalAlta] = useState(false);
  const [displayLocalModificar, setDisplayLocalModificar] = useState(false);
  const [filter, setFilter] = useState<PerfilTraslado>();




  return (
    <>
      <Toast ref={toast} position="top-right" />
      <GenericAbm<PerfilTraslado>
        screenCode={SCREEN_CODE}
        ref={abmRef}
        title={<LanguageProvider id="6697" alt="Listado" />}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
          },
        }}
        dataTableColumns={[
          { field: "descripcion", header: <LanguageProvider id="6590" alt="Código" /> },
          {
            field: "asignado",
            header: <LanguageProvider id="14285" alt="x" />,
            body: (rowData: any) => {
              return rowData.asignado ? "SI" : "NO";
            },
          },
          {
            field: "noAsignado",
            header: <LanguageProvider id="16167" alt="x" />,
            body: (rowData: any) => {
              return rowData.noAsignado ? "SI" : "NO";
            },
          },
          {
            field: "bloqueoCalidad",
            header: <LanguageProvider id="1595" alt="x" />,
            body: (rowData: any) => {
              return rowData.bloqueoCalidad ? "SI" : "NO";
            },
          },
          {
            field: "bloqueoTerceros",
            header: <LanguageProvider id="16047" alt="x" />,
            body: (rowData: any) => {
              return rowData.bloqueoTerceros ? "SI" : "NO";
            },
          },
          {
            field: "noBloqueoTerceros",
            header: <LanguageProvider id="16169" alt="x" />,
            body: (rowData: any) => {
              return rowData.noBloqueoTerceros ? "SI" : "NO";
            },
          },
          {
            field: "nacionalizado",
            header: <LanguageProvider id="16048" alt="x" />,
            body: (rowData: any) => {
              return rowData.nacionalizado ? "SI" : "NO";
            },
          },
          {
            field: "noNacionalizado",
            header: <LanguageProvider id="16168" alt="x" />,
            body: (rowData: any) => {
              return rowData.noNacionalizado ? "SI" : "NO";
            },
          },
          {
            field: "pendientePPO",
            header: <LanguageProvider id="16049" alt="x" />,
            body: (rowData: any) => {
              return rowData.pendientePPO ? "SI" : "NO";
            },
          },
          {
            field: "pendienteTaller",
            header: <LanguageProvider id="14722" alt="x" />,
            body: (rowData: any) => {
              return rowData.pendienteTaller ? "SI" : "NO";
            },
          },
          {
            field: "remitoTraslado",
            header: <LanguageProvider id="14282" alt="x" />,
            body: (rowData: any) => {
              return rowData.remitoTraslado ? "SI" : "NO";
            },
          },
          {
            field: "remitoDespacho",
            header: <LanguageProvider id="321" alt="x" />,
            body: (rowData: any) => {
              return rowData.remitoDespacho ? "SI" : "NO";
            },
          },
          {
            field: "mercadoLocal",
            header: <LanguageProvider id="16050" alt="x" />,
            body: (rowData: any) => {
              return rowData.mercadoLocal ? "SI" : "NO";
            },
          },
          {
            field: "mercadoExportacion",
            header: <LanguageProvider id="16051" alt="x" />,
            body: (rowData: any) => {
              return rowData.mercadoExportacion ? "SI" : "NO";
            },
          },
          {
            field: "origenNacional",
            header: <LanguageProvider id="16053" alt="x" />,
            body: (rowData: any) => {
              return rowData.origenNacional ? "SI" : "NO";
            },
          },
          {
            field: "origenImportado",
            header: <LanguageProvider id="16054" alt="x" />,
            body: (rowData: any) => {
              return rowData.origenImportado ? "SI" : "NO";
            },
          },
          {
            field: "noLiberoAduana",
            header: <LanguageProvider id="16164" alt="x" />,
            body: (rowData: any) => {
              return rowData.noLiberoAduana ? "SI" : "NO";
            },
          },
          {
            field: "noExportable",
            header: <LanguageProvider id="8596" alt="x" />,
            body: (rowData: any) => {
              return rowData.noExportable ? "SI" : "NO";
            },
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
            body: (rowData: any) => {
              return (
                <span>
                  {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
                </span>
              );
            },
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => {
              return rowData.activo ? "SI" : "NO";
            },
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          pais: null,
          activo: true,
          asignado: false,
          noAsignado: false,
          bloqueoCalidad: false,
          bloqueoTerceros: false,
          noBloqueoTerceros: false,
          nacionalizado: false,
          noNacionalizado: false,
          pendientePPO: false,
          pendienteTaller: false,
          remitoTraslado: false,
          remitoDespacho: false,
          mercadoLocal: false,
          mercadoExportacion: false,
          origenNacional: false,
          origenImportado: false,
          noLiberoAduana: false,
          noExportable: false,
          cliente: null,
          cuenta: null,
          descripcion: "",
          fechaUltimaModificacion: null,
          usuarioUltimaModificacion: null,
        }}
        formSettings={[
          {
            "field": "id",
            "label": LanguageProvider({ id: "6590", alt: "Código" }),
            "type": "inputText",
            "length": 6
          },
          {
            "field": "activo",
            "label": LanguageProvider({ id: "72", alt: "Activo" }),
            "type": "checkbox"
          },
          {
            "field": "descripcion",
            "label": LanguageProvider({ id: "3756", alt: "x" }),
            "type": "inputText",
            "length": 100
          },
          {
            field: "tipoGasto",
            label: <LanguageProvider id="TODO" alt="Tipo de Gasto" />,
            type: "radiobutton",
            radioButtonOptions: [
              {
                "value": "remitoTraslado",
                "label": LanguageProvider({ id: "16147", alt: "x" }),
              },
              {
                "value": "remitoDespacho",
                "label": LanguageProvider({ id: "382", alt: "x" }),
              },
            ],
          },

          {
            "field": "pendientePPO",
            "label": LanguageProvider({ id: "16162", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "pendienteTaller",
            "label": LanguageProvider({ id: "327", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "origenNacional",
            "label": LanguageProvider({ id: "1997", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "origenImportado",
            "label": LanguageProvider({ id: "1998", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "mercadoLocal",
            "label": LanguageProvider({ id: "16163", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "mercadoExportacion",
            "label": LanguageProvider({ id: "411", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "bloqueoTerceros",
            "label": LanguageProvider({ id: "68", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "noBloqueoTerceros",
            "label": LanguageProvider({ id: "69", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "asignado",
            "label": LanguageProvider({ id: "68", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "noAsignado",
            "label": LanguageProvider({ id: "69", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "nacionalizado",
            "label": LanguageProvider({ id: "68", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "noNacionalizado",
            "label": LanguageProvider({ id: "69", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "bloqueoCalidad",
            "label": LanguageProvider({ id: "1595", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "noLiberoAduana",
            "label": LanguageProvider({ id: "16164", alt: "x" }),
            "type": "checkbox"
          },
          {
            "field": "noExportable",
            "label": LanguageProvider({ id: "8596", alt: "x" }),
            "type": "checkbox"
          },

        ]
        }
        customActions={
          <>
            <Button
              label="Borrar"
              onClick={() => {
                remove(abmRef)
              }}
            />

            <Button
              label="Modificar"
              onClick={() => {
                handleModificar(abmRef)
              }}
            />
            <Button
              label="Alta"
              onClick={() => {
                setDisplayLocalAlta(true);
              }}
            />
          </>
        }
        list={handleList}
        get={handleGet}
      />
      <Dialog
        visible={displayLocalAlta}
        onHide={() => setDisplayLocalAlta(false)}
        className="modalComprasTallerVer"
      >


        <div className="column-form">
          <div className="form" >
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"23876"} alt="Código" />
                </label>
                <SipcoInputText
                  className="input-modal"
                  value={filter?.id}
                  onChange={(e) => setFilter({ ...filter, id: e.target.value })}
                  maxLength={6}
                  disabled
                />
                <label>
                  <LanguageProvider id={"12419"} alt="Descripcion" />
                </label>
                <SipcoInputText
                  value={filter?.descripcion}
                  onChange={(e) => setFilter({ ...filter, descripcion: e.target.value })}
                  className="input-modal"
                  maxLength={100}
                />

                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"23849"} alt="Activo" />
                  </label>
                  <Checkbox
                    checked={filter?.activo}
                    onChange={(e) => setFilter({ ...filter, activo: e.target.checked })}
                  />
                </div>
                {/* Chebox */}
                <div className="form-row" style={{
                  marginTop: "2rem", display:
                    "flex", alignItems: "center"
                }}>

                  <div className="sipco-option" style={{ marginRight: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"3184"} alt="Remito" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"16147"} alt="Traslado" />
                      </label>
                      <Checkbox
                        checked={filter?.remitoTraslado}
                        onChange={(e) => setFilter({ ...filter, remitoTraslado: e.target.checked })}
                      />
                      <label>
                        <LanguageProvider id={"382"} alt="Despacho" />
                      </label>
                      <Checkbox
                        checked={filter?.remitoDespacho}
                        onChange={(e) => setFilter({ ...filter, remitoDespacho: e.target.checked })}
                      />
                    </div>
                  </div>

                  <div className="sipco-option" style={{ marginLeft: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"5411"} alt="Pendiente" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"16162"} alt="PPO" />
                      </label>
                      <Checkbox
                        checked={filter?.pendientePPO}
                        onChange={(e) => setFilter({ ...filter, pendientePPO: e.target.checked })}
                      />
                      <label>
                        <LanguageProvider id={"18487"} alt="Taller" />
                      </label>
                      <Checkbox
                        checked={filter?.pendienteTaller}
                        onChange={(e) => setFilter({ ...filter, pendienteTaller: e.target.checked })}
                      />
                    </div>
                  </div>

                </div>

                <div className="form-row" style={{
                  marginTop: "2rem", display:
                    "flex", alignItems: "center"
                }}>

                  <div className="sipco-option" style={{ marginRight: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"2496"} alt="Origen" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"1997"} alt="Nacional" />
                      </label>
                      <Checkbox
                        checked={filter?.origenNacional}
                        onChange={(e) => setFilter({ ...filter, origenNacional: e.target.checked })}
                      />
                      <label>
                        <LanguageProvider id={"1998"} alt="Importado" />
                      </label>
                      <Checkbox
                        checked={filter?.origenImportado}
                        onChange={(e) => setFilter({ ...filter, origenImportado: e.target.checked })}
                      />
                    </div>
                  </div>

                  <div className="sipco-option" style={{ marginLeft: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"367"} alt="Mercado" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"16163"} alt="Local" />
                      </label>
                      <Checkbox
                        checked={filter?.mercadoLocal}
                        onChange={(e) => setFilter({ ...filter, mercadoLocal: e.target.checked })}
                      />
                      <label>
                        <LanguageProvider id={"411"} alt="Exportación" />
                      </label>
                      <Checkbox
                        checked={filter?.mercadoExportacion}
                        onChange={(e) => setFilter({ ...filter, mercadoExportacion: e.target.checked })}
                      />
                    </div>
                  </div>

                </div>

                <div className="form-row" style={{
                  marginTop: "2rem", display:
                    "flex", alignItems: "center"
                }}>

                  <div className="sipco-option" style={{ marginRight: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"16047"} alt="Bloqueo Terceros" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"68"} alt="SI" />
                      </label>
                      <Checkbox
                        checked={filter?.bloqueoTerceros}
                        onChange={(e) => setFilter({ ...filter, bloqueoTerceros: e.target.checked, noBloqueoTerceros: false })}
                      />
                      <label>
                        <LanguageProvider id={"69"} alt="NO" />
                      </label>
                      <Checkbox
                        checked={filter?.noBloqueoTerceros}
                        onChange={(e) => setFilter({ ...filter, noBloqueoTerceros: e.target.checked, bloqueoTerceros: false })}
                      />
                    </div>
                  </div>

                  <div className="sipco-option" style={{ marginLeft: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"14285"} alt="Asignado" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"68"} alt="SI" />
                      </label>
                      <Checkbox
                        checked={filter?.asignado}
                        onChange={(e) => setFilter({ ...filter, asignado: e.target.checked, noAsignado: false })}
                      />
                      <label>
                        <LanguageProvider id={"69"} alt="NO" />
                      </label>
                      <Checkbox
                        checked={filter?.noAsignado}
                        onChange={(e) => setFilter({ ...filter, noAsignado: e.target.checked, asignado: false })}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-row" style={{
                  marginTop: "2rem", display:
                    "flex", alignItems: "center"
                }}>

                  <div className="sipco-option" style={{ marginRight: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"16048"} alt="Nacionalizado" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"68"} alt="SI" />
                      </label>
                      <Checkbox
                        checked={filter?.nacionalizado}
                        onChange={(e) => setFilter({ ...filter, noNacionalizado: false, nacionalizado: e.target.checked })}
                      />

                      <label>
                        <LanguageProvider id={"69"} alt="NO" />
                      </label>
                      <Checkbox
                        checked={filter?.noNacionalizado}
                        onChange={(e) => setFilter({ ...filter, noNacionalizado: e.target.checked, nacionalizado: false })}
                      />

                    </div>
                  </div>

                  <div className="sipco-option" style={{ marginLeft: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"6150"} alt="Otros" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"1595"} alt="Bloqueo Calidad" />
                      </label>
                      <Checkbox
                        checked={filter?.bloqueoCalidad}
                        onChange={(e) => setFilter({ ...filter, bloqueoCalidad: e.target.checked })}
                      />
                      <label>
                        <LanguageProvider id={"16164"} alt="No liberado aduana" />
                      </label>
                      <Checkbox
                        checked={filter?.noLiberoAduana}
                        onChange={(e) => setFilter({ ...filter, noLiberoAduana: e.target.checked })}
                      />
                      <label>
                        <LanguageProvider id={"8596"} alt="No Exportable" />
                      </label>
                      <Checkbox
                        checked={filter?.noExportable}
                        onChange={(e) => setFilter({ ...filter, noExportable: e.target.checked })}
                      />
                    </div>
                  </div>
                </div>


                <div className="sipco-option" style={{ marginLeft: "1rem" }}>

                  <div className="form-row">

                    <Button
                      label="Confirmar"
                      onClick={(e: any) => save(abmRef, filter)}
                    />

                    <Button
                      label="Cancelar"
                      onClick={(e: any) => setDisplayLocalAlta(false)}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={displayLocalModificar}
        onHide={() => setDisplayLocalModificar(false)}
        className="modalComprasTallerVer"
      >


        <div className="column-form">
          <div className="form" >
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"23876"} alt="Código" />
                </label>
                <SipcoInputText
                  className="input-modal"
                  value={filter?.id}
                  onChange={(e) => setFilter({ ...filter, id: e.target.value })}
                  maxLength={6}
                  disabled
                />
                <label>
                  <LanguageProvider id={"12419"} alt="Descripcion" />
                </label>
                <SipcoInputText
                  value={filter?.descripcion}
                  onChange={(e) => setFilter({ ...filter, descripcion: e.target.value })}
                  className="input-modal"
                  maxLength={100}
                />

                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"23849"} alt="Activo" />
                  </label>
                  <Checkbox
                    checked={filter?.activo}
                    onChange={(e) => setFilter({ ...filter, activo: e.target.checked })}
                  />
                </div>
                {/* Chebox */}
                <div className="form-row" style={{
                  marginTop: "2rem", display:
                    "flex", alignItems: "center"
                }}>

                  <div className="sipco-option" style={{ marginRight: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"3184"} alt="Remito" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"16147"} alt="Traslado" />
                      </label>
                      <Checkbox
                        checked={filter?.remitoTraslado}
                        onChange={(e) => setFilter({ ...filter, remitoTraslado: e.target.checked })}
                      />
                      <label>
                        <LanguageProvider id={"382"} alt="Despacho" />
                      </label>
                      <Checkbox
                        checked={filter?.remitoDespacho}
                        onChange={(e) => setFilter({ ...filter, remitoDespacho: e.target.checked })}
                      />
                    </div>
                  </div>

                  <div className="sipco-option" style={{ marginLeft: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"5411"} alt="Pendiente" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"16162"} alt="PPO" />
                      </label>
                      <Checkbox
                        checked={filter?.pendientePPO}
                        onChange={(e) => setFilter({ ...filter, pendientePPO: e.target.checked })}
                      />
                      <label>
                        <LanguageProvider id={"18487"} alt="Taller" />
                      </label>
                      <Checkbox
                        checked={filter?.pendienteTaller}
                        onChange={(e) => setFilter({ ...filter, pendienteTaller: e.target.checked })}
                      />
                    </div>
                  </div>

                </div>

                <div className="form-row" style={{
                  marginTop: "2rem", display:
                    "flex", alignItems: "center"
                }}>

                  <div className="sipco-option" style={{ marginRight: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"2496"} alt="Origen" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"1997"} alt="Nacional" />
                      </label>
                      <Checkbox
                        checked={filter?.origenNacional}
                        onChange={(e) => setFilter({ ...filter, origenNacional: e.target.checked })}
                      />
                      <label>
                        <LanguageProvider id={"1998"} alt="Importado" />
                      </label>
                      <Checkbox
                        checked={filter?.origenImportado}
                        onChange={(e) => setFilter({ ...filter, origenImportado: e.target.checked })}
                      />
                    </div>
                  </div>

                  <div className="sipco-option" style={{ marginLeft: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"367"} alt="Mercado" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"16163"} alt="Local" />
                      </label>
                      <Checkbox
                        checked={filter?.mercadoLocal}
                        onChange={(e) => setFilter({ ...filter, mercadoLocal: e.target.checked })}
                      />
                      <label>
                        <LanguageProvider id={"411"} alt="Exportación" />
                      </label>
                      <Checkbox
                        checked={filter?.mercadoExportacion}
                        onChange={(e) => setFilter({ ...filter, mercadoExportacion: e.target.checked })}
                      />
                    </div>
                  </div>

                </div>

                <div className="form-row" style={{
                  marginTop: "2rem", display:
                    "flex", alignItems: "center"
                }}>

                  <div className="sipco-option" style={{ marginRight: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"16047"} alt="Bloqueo Terceros" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"68"} alt="SI" />
                      </label>
                      <Checkbox
                        checked={filter?.bloqueoTerceros}
                        onChange={(e) => setFilter({ ...filter, bloqueoTerceros: e.target.checked, noBloqueoTerceros: false })}
                      />
                      <label>
                        <LanguageProvider id={"69"} alt="NO" />
                      </label>
                      <Checkbox
                        checked={filter?.noBloqueoTerceros}
                        onChange={(e) => setFilter({ ...filter, noBloqueoTerceros: e.target.checked, bloqueoTerceros: false })}
                      />
                    </div>
                  </div>

                  <div className="sipco-option" style={{ marginLeft: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"14285"} alt="Asignado" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"68"} alt="SI" />
                      </label>
                      <Checkbox
                        checked={filter?.asignado}
                        onChange={(e) => setFilter({ ...filter, asignado: e.target.checked, noAsignado: false })}
                      />
                      <label>
                        <LanguageProvider id={"69"} alt="NO" />
                      </label>
                      <Checkbox
                        checked={filter?.noAsignado}
                        onChange={(e) => setFilter({ ...filter, noAsignado: e.target.checked, asignado: false })}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-row" style={{
                  marginTop: "2rem", display:
                    "flex", alignItems: "center"
                }}>

                  <div className="sipco-option" style={{ marginRight: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"16048"} alt="Nacionalizado" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"68"} alt="SI" />
                      </label>
                      <Checkbox
                        checked={filter?.nacionalizado}
                        onChange={(e) => setFilter({ ...filter, noNacionalizado: false, nacionalizado: e.target.checked })}
                      />

                      <label>
                        <LanguageProvider id={"69"} alt="NO" />
                      </label>
                      <Checkbox
                        checked={filter?.noNacionalizado}
                        onChange={(e) => setFilter({ ...filter, noNacionalizado: e.target.checked, nacionalizado: false })}
                      />

                    </div>
                  </div>

                  <div className="sipco-option" style={{ marginLeft: "1rem" }}>
                    <div className="label-style">
                      <label style={{ color: "white" }}>
                        <LanguageProvider id={"6150"} alt="Otros" />
                      </label>
                    </div>
                    <div className="form-row">
                      <label>
                        <LanguageProvider id={"1595"} alt="Bloqueo Calidad" />
                      </label>
                      <Checkbox
                        checked={filter?.bloqueoCalidad}
                        onChange={(e) => setFilter({ ...filter, bloqueoCalidad: e.target.checked })}
                      />
                      <label>
                        <LanguageProvider id={"16164"} alt="No liberado aduana" />
                      </label>
                      <Checkbox
                        checked={filter?.noLiberoAduana}
                        onChange={(e) => setFilter({ ...filter, noLiberoAduana: e.target.checked })}
                      />
                      <label>
                        <LanguageProvider id={"8596"} alt="No Exportable" />
                      </label>
                      <Checkbox
                        checked={filter?.noExportable}
                        onChange={(e) => setFilter({ ...filter, noExportable: e.target.checked })}
                      />
                    </div>
                  </div>
                </div>


                <div className="sipco-option" style={{ marginLeft: "1rem" }}>

                  <div className="form-row">

                    <Button
                      label="Confirmar"
                      onClick={(e: any) => update(filter, abmRef)}
                    />

                    <Button
                      label="Cancelar"
                      onClick={(e: any) => setDisplayLocalModificar(false)}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default MantenerPerfilesTranslado;
