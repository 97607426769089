import "./OrdenReparacionRepuestos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import LoadingIndicator from "@shared/loading-indicator";
import ModalAltaOrdenReparacion from "./modals/ModalAltaOrdenReparacion";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../../protected-routes";
import { useLocation } from "react-router-dom";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { HttpStatusCode } from "axios";

export function OrdenReparacionRepuestos() {
  const SCREEN_CODE = "abm0107_orden_reparacion_repuestos";
  ScreenCodeValue(SCREEN_CODE);
  const toast = useRef<Toast>(null);
  const [visibleBorrar, setVisibleBorrar] = useState(false);
  const [visibleAlta, setVisibleAlta] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const showModalBorrar = () => {
    if (selectedRows.length === 0) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: (
          <LanguageProvider
            id="753"
            alt="Debe seleccionar al menos un registro de la Grilla."
          />
        ),
        life: 3000,
      });
    } else {
      remove();
      setVisibleBorrar(true);
    }
  };
  const hideModalBorrar = () => {
    setVisibleBorrar(false);
  };
  const showModalAlta = () => {
    setVisibleAlta(true);
  };
  const hideModalAlta = () => {
    setVisibleAlta(false);
  };

  const location = useLocation();

  const ordemReparacao = location.state.order;


  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);

  const columns = [];

  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  console.log(selectedRows)
  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    checkVinTaller: false,
    taller: "",
    estados: null,
    vin: null,
    checkFueraTaller: false,
    desde: new Date(),
    hasta: new Date(),
  });

  const [ordenReparacionRepuesto, setOrdenReparacionRepuesto] = useState([]);
  async function loadReportData() {
    setLoading(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/orden-reparacion-repuestos/find",
        {
          pais: ordemReparacao.pais,
          cuenta: ordemReparacao.cuenta,
          subcuenta: ordemReparacao.subcuenta,
          taller: ordemReparacao.taller,
          ordenReparacion: ordemReparacao,
        }
      );
      if (status === 200) {
        const response = data.map((x: any) => ({
          ...x,
          linea: x?.id,
          codigoCliente: x?.clienteRepuesto?.id,
          codigoPieza: x?.pieza?.id,
          codigoPiezaDescripcion: x?.pieza?.descripcion,
          cantidad: x?.cantidad,
          importe: x?.importe,
          ordenReparacion: x?.ordenReparacion,
          importeNeto: x?.importeNeto,
          origen: x?.codori,
          prestacion: x?.prestacion,
          subprestacion: x?.subprestacion || "",
        }));

        setOrdenReparacionRepuesto(data);
        setReporte(response);
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        await loadReportData();
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [ordemReparacao]);

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  const columnsNamesTable = [
    { field: "linea", header: "Linea" },
    { field: "codigoCliente", header: "Codigo Cliente" },
    { field: "codigoPieza", header: "Codigo Pieza" },
    { field: "codigoPiezaDescripcion", header: "Descripcion" },
    { field: "cantidad", header: "Cantidad" },
    { field: "importe", header: "Importe" },
    { field: "ordenReparacion", header: "Orden Reparacion" },
    { field: "importeNeto", header: "Importe Neto" },

    { field: "origen", header: "Origen" },
    { field: "prestacion", header: "Prestacion" },
    { field: "subprestacion", header: "Sub Prestacion" },
  ];

  const data = convertDateObjects(reporte?.filas);


  const [removeLabel, setRemoveLabel] = useState([]);
  const [loadingRemoveLabel, setLoadingRemoveLabel] = useState(false);
  async function remove() {
    setLoadingRemoveLabel(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/orden-reparacion-repuestos/remove",
        {
          detalleOrden: {
            activo: true,
            pais: ordemReparacao?.pais,
            cuenta: ordemReparacao?.cuenta,
            subcuenta: ordemReparacao?.subcuenta,
            taller: ordemReparacao?.taller,
            codori: selectedRows[0].codori ?? null,
            ordenReparacion: ordemReparacao,
            id: selectedRows[0].id ?? null,
            importe: Number(selectedRows[0].importe) ?? null,
            fechaUltimaModificacion: ordemReparacao?.fechaUltimaModificacion ?? null,
            usuarioUltimaModificacion: ordemReparacao?.usuarioUltimaModificacion ?? null,
            descuentoTaller: selectedRows[0].descuentoTaller ?? null,
            importeNeto: Number(selectedRows[0].importeNeto) ?? null,
            prestacion: selectedRows[0].prestacion ?? null,
            subprestacion: selectedRows[0].prestacion.subPrestacion ?? null,
            cantidad: Number(selectedRows[0].cantidad) ?? null,
            clienteRepuesto: selectedRows[0].clienteRepuesto ?? null,
            pieza: selectedRows[0].pieza ?? null,
            estadoRepuesto: ordemReparacao?.estado ?? null,
          },
        }
      );
      if (status === HttpStatusCode.Ok) {
        setRemoveLabel(data);
        return data;
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error",
          life: 3000,
        });
      }
    }
    catch (error) {
      console.error(error);
    } finally {
      setLoadingRemoveLabel(false);
    }

  }


  if (!ordemReparacao) {
    return <LoadingIndicator />;
  }
  return (
    <div className="orden-reparacion">
      <ModalAltaOrdenReparacion
        visible={visibleAlta}
        onClose={hideModalAlta}
        ordemReparacao={ordemReparacao}
      />
      <Toast ref={toast} position="top-center" />
      <Filter onSearch={loadReportData}>
        <AccordionTab header={<LanguageProvider id={"65"} alt="Seguridad" />}>
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"5044"} alt="País" />
                </label>
                <SipcoInputText
                  value={ordemReparacao.pais.descripcion}
                  disabled={true}
                />
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <SipcoInputText
                  value={ordemReparacao.taller.descripcion}
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"24328"} alt="Cuenta" />
                </label>
                <SipcoInputText
                  value={ordemReparacao.cuenta.descripcion}
                  disabled={true}
                />
                <label>
                  <LanguageProvider id={"5701"} alt="Nro. Orden" />
                </label>
                <SipcoInputText value={ordemReparacao.id} disabled={true} />
              </div>
              <div className={"sipco-option"} style={{ marginBottom: "5em" }}>
                <label>
                  <LanguageProvider id={"441"} alt="Playas" />
                </label>
                <SipcoInputText
                  value={ordemReparacao.subcuenta.descripcion}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Listado</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              <div>
                <Button
                  label={LanguageProvider({ id: "25", alt: "Alta" })}
                  text
                  onClick={showModalAlta}
                />
                <Button
                  label={LanguageProvider({ id: "2016", alt: "Borrar" })}
                  text
                  onClick={showModalBorrar}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          ref={dt}
          value={reporte}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e: any) => {
            setSelectedRows(e.value);
          }}
          metaKeySelection={true}
          dragSelection
        >
          {columnsNamesTable.map((colum, index) => {
            if (
              colum.header === "Fecha Alta" ||
              colum.header === "Fecha Cierre Real" ||
              colum.header === "Fecha Cierre Estimada" ||
              colum.header === "Fecha Facturacion" ||
              colum.header === "Fecha Anulacion"
            ) {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                />
              );
            }
          })}
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default OrdenReparacionRepuestos;
