import "./ModalModificarComprasTaller.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import { useLocation } from "react-router-dom";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { HttpStatusCode } from "axios";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { format } from "date-fns";

interface ModalComprasTaller {
  visible: boolean;
  onClose: () => void;
  model?: any;
  onConfirm?: (updatedList: any[]) => void;
  selected?: any[];
}

const ModalModificarComprasTaller: React.FC<ModalComprasTaller> = ({
  visible,
  onClose,
  model,
  onConfirm,
  selected,
}) => {
  const location = useLocation();
  const { pais, cuenta, tallerFilter, subcuenta, nroOrden } =
    location.state || {};
  const [selectedRows, setSelectedRows] = useState([]);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  function compareArrays(selectRows: any[], arrayBase: any[]): any[] {
    const result = [];
    if (selectRows.length > 0 && arrayBase.length > 0) {
      selectRows.forEach((row: any) => {
        const vinValue = row.piezaId;

        const matchingObject = arrayBase.find(
          (item: any) => item?.pieza?.id === vinValue
        );

        if (matchingObject) {
          result.push(matchingObject);
        }
      });
    }
    return result;
  }

  const [filter, setFilter] = useState({
    cliente: null,
    rubro: null,
    cuentaTaller: null,
    grupoTaller: null,
    peiza: null,
    modelo: null,
    cantidad: '',
    catEntrega: '',
    descuento: null,
    origen: null,
    prestacion: null,
    subPrestacion: null
  });

  const data = convertDateObjects(reporte?.filas);

  const [cliente, setCliente] = useState([]);
  const [loadingCliente, setLoadingCliente] = useState(false);
  async function loadCliente() {
    setLoadingCliente(true);
    try {
      if (pais && cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/cliente",
          {
            pais: pais,
            cuenta: cuenta,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setCliente(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCliente(false);
    }
  }

  const [rubro, setRubro] = useState([]);
  const [loadingRubro, setLoadingRubro] = useState(false);
  async function loadRubro() {
    setLoadingRubro(true);
    try {
      if (pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/rubros",
          {
            pais: pais,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setRubro(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRubro(false);
    }
  }

  const [cuentaTaller, setCuentaTaller] = useState([]);
  const [loadingCuentaTaller, setLoadingCuentaTaller] = useState(false);
  async function loadCuentaTaller() {
    setLoadingCuentaTaller(true);
    try {
      if (pais && cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/rubros",
          {
            pais: pais,
            cuenta: cuenta,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setCuentaTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCuentaTaller(false);
    }
  }

  const [grupoTaller, setGrupoTaller] = useState([]);
  const [loadingGrupoTaller, setLoadingGrupoTaller] = useState(false);
  async function loadGrupoTaller() {
    setLoadingGrupoTaller(true);
    try {
      if (pais && cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/rubros",
          {
            pais: pais,
            cuenta: cuenta,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setGrupoTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrupoTaller(false);
    }
  }

  const [modelo, setModelo] = useState([]);
  const [loadingModelo, setLoadingModelo] = useState(false);

  async function loadModelo() {
    setLoadingModelo(true);
    try {
      if (filter.grupoTaller !== null && pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/modelo-taller",
          {
            pais: pais,
            grupoTaller: filter.grupoTaller,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setModelo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelo(false);
    }
  }

  const [duescuento, setDescuento] = useState([]);
  const [loadingDuescuento, setLoadingDescuento] = useState(false);

  async function loadDuescuento() {
    setLoadingDescuento(true);
    try {
      if (pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/descuento-taller",
          {
            pais: pais,
            cuenta: cuenta,
            subcuenta: subcuenta,
            taller: tallerFilter
          }
        );
        if (status === HttpStatusCode.Ok) {
          setDescuento(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDescuento(false);
    }
  }


  const [origen, setOrigen] = useState([]);
  const [loadingOrigen, setLoadingOrigen] = useState(false);

  async function loadOrigen() {
    setLoadingOrigen(true);
    try {
      if (selected) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/find-by-user",
          {
          }
        );
        if (status === HttpStatusCode.Ok) {
          setOrigen(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigen(false);
    }
  }

  const [prestacion, setPrestacion] = useState([]);
  const [loadingPrestacion, setLoadingPrestacion] = useState(false);

  async function loadPrestacion() {
    setLoadingPrestacion(true);
    try {
      if (selected) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/find-prestacion",
          {
            codori: selected.map(x => x.codori)[0],
            pais: pais
          }
        );
        if (status === HttpStatusCode.Ok) {
          setPrestacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPrestacion(false);
    }
  }

  const [subPrestacion, setSubPrestacion] = useState([]);
  const [loadingSubPrestacion, setLoadingSubPrestacion] = useState(false);

  async function loadSubPrestacion() {
    setLoadingSubPrestacion(true);
    try {
      if (selected && filter.prestacion) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/find-subprestacion",
          {
            codori: selected.map(x => x.codori)[0],
            prestacion: filter.prestacion?.descripcion
          }
        );
        if (status === HttpStatusCode.Ok) {
          setSubPrestacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSubPrestacion(false);
    }
  }

  const [comprasTaller, setComprasTaller] = useState([]);
  async function loadReport() {
    setLoading(true);
    try {
      if (pais && cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/catalogos",
          {
            pais: pais,
            cuenta: cuenta,
            cliente: filter.cliente,
            rubro: filter.rubro,
            cuentaTaller: filter.cuentaTaller,
            pieza: filter.peiza,
            grupoTaller: filter.grupoTaller,
            modeloTaller: filter.modelo
          }
        );
        if (status === HttpStatusCode.Ok) {
          const response = data.map((x: any) => {
            return {
              ...x,
              piezaId: x?.id,
              pieza: x?.descripcion,
              fechaCotizacion: x?.fechaCotizacion?.date,
              costoPeso: x?.costoPeso,
              costoDolar: x?.costoDolar,
              iva: x?.iva,
              fechaUltimaModificacion: x?.fechaUltimaModificacion?.date,
            }
          });

          setComprasTaller(response);
          setReporte(data);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  // Modelo - Origen - Descuento - Prestacion - SubPrestacion
  useEffect(() => {
    const init = async () => {
      if (visible) {
        try {
          const [
            grupoTaller,
            modelo,
            origen,
            descuento,
            prestacion
          ] = await Promise.all([
            loadGrupoTaller(),
            loadModelo(),
            loadOrigen(),
            loadDuescuento(),
            loadPrestacion(),

          ])

          setFilter({
            ...filter,
            grupoTaller: grupoTaller[0],
            modelo: modelo[0],
            origen: origen[0],
            descuento: descuento[0],
            prestacion: prestacion[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }

    init();
  }, [visible]);

// SubPrestacinn
  useEffect(() => {
    const init = async () => {
      try {
        const [
          subPrestacion,
        ] = await Promise.all([
          loadSubPrestacion(),
        ]);

        setFilter({
          ...filter,
          subPrestacion: subPrestacion[0]
        })
      } catch (error) {
        console.error(error);
      }
    }

    init();
  }, [filter.prestacion]);

  const [updateLabel, setUpdateLabel] = useState([]);
  const [loadingUpdateLabel, setLoadingUpdateLabel] = useState(false);
  async function update() {
    setLoadingUpdateLabel(true);
    try {
      if (selected) {
        const { status, data } = await sipcoAxiosService.post(
          "/detalles-compras-taller/update",
          {
            detalleCompraTaller: {
              activo: true,
              pais: pais,
              cuenta: cuenta,
              subcuenta: subcuenta,
              taller: tallerFilter,
              linea: selected.map(x => x.linea)[0] || null,
              codori: selected.map(x => x.codori)[0] || null,
              importe: selected.map(x => x.importe)[0] || 0,
              fechaUltimaModificacion: selected.map(x => x.fechaUltimaModificacion)[0] || null,
              usuarioUltimaModificacion: selected.map(x => x.usuarioUltimaModificacion)[0] || null,
              detalleOrdenRepuesto: selected.map(x => x.detalleOrdenRepuesto)[0] || null,
              importeNeto: selected.map(x => x.importeNeto)[0] || 0,
              prestacion: filter.prestacion?.id || null,
              subprestacion: filter.subPrestacion || null,
              cantidad: Number(filter.cantidad) || null,
              id: selected.map(x => x.id)[0],
              descuento: filter.descuento || null,
              clienteRepuesto: selected.map(x => x.clienteRepuesto)[0] || null,
              pieza: selected.map(x => x.pieza)[0] || 0,
              cantidadEntregada: Number(filter.catEntrega) || null,
              compraTaller: {
                id: selected.map(x => x.compraTaller?.id)[0],
                pais: pais,
                cuenta: cuenta,
                subcuenta: subcuenta,
                taller: tallerFilter,
              },
            },
          }
        );
        if (status === HttpStatusCode.Ok) {
          setUpdateLabel(data);
          onClose();
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUpdateLabel(false);
    }
  }

  return (
    <div>
      <Dialog
        className="modalModificacionComprasTaller"
        header="Detalhes - Modificar"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={LanguageProvider({ id: "15447", alt: "Confirmar" })}
                icon="pi pi-check"
                onClick={update}
                autoFocus
              />
              <Button
                label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                icon="pi pi-times"
                onClick={onClose}
                className="p-button-text"
              />
            </div>
          );
        }}
      >


        <div className="column-form">
          <div className="form" >
            <div className="form-row">
              <div className="sipco-option">
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"3773"} alt="Cantidad" />
                  </label>
                  <SipcoInputText
                    className="input-modal"
                    value={filter.cantidad}
                    onChange={(e) => setFilter({ ...filter, cantidad: e.target.value })}
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"17041"} alt="Cant. Entregada" />
                  </label>
                  <SipcoInputText
                    className="input-modal"
                    value={filter.catEntrega}
                    onChange={(e) => setFilter({ ...filter, catEntrega: e.target.value })}
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"2263"} alt="Descuento (%)" />
                  </label>
                  <SipcoDropdown
                    loading={loadingDuescuento}
                    value={filter.descuento}
                    options={duescuento}
                    onChange={(e) => setFilter({ ...filter, descuento: e.value })}
                    optionLabel="descripcion"
                    className="input-modal" />
                </div>
              </div>
            </div>
          </div>


          <div className="form" >
            <div className="form-row">
              <div className="sipco-option">
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"2496"} alt="Origen" />
                  </label>
                  <SipcoDropdown
                    loading={loadingOrigen}
                    value={filter.origen}
                    options={origen}
                    onChange={(e) => setFilter({ ...filter, origen: e.value })}
                    optionLabel="descripcion"
                    className="input-modal"
                    filter
                  />
                </div>
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"22724"} alt="Prestacion" />
                  </label>
                  <SipcoDropdown
                    loading={loadingPrestacion}
                    value={filter.prestacion}
                    options={prestacion}
                    onChange={(e) => setFilter({ ...filter, prestacion: e.value })}
                    optionLabel="descripcion"
                    className="input-modal"
                    filter
                  />
                </div>


                <div className="input-group">
                  <label>
                    <LanguageProvider id={"22725"} alt="Subprestacion" />
                  </label>
                  <SipcoDropdown
                    loading={loadingSubPrestacion}
                    value={filter.subPrestacion}
                    options={subPrestacion}
                    onChange={(e) => setFilter({ ...filter, subPrestacion: e.value })}
                    optionLabel="descripcion"
                    className="input-modal"
                    filter />
                </div>
              </div>
            </div>
          </div>
        </div>

      </Dialog>
    </div>
  );
};
export default ModalModificarComprasTaller;
