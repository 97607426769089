import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ParametrosCobroPrestacion } from "src/models/ParametrosCobroPrestacion";

const MantenerParametrosCobroPrestacion = () => {
  const SCREEN_CODE = "abm0184_mantener_parametros_cobro_prestacion";
  const URL_BASE = "/mantener-parametros-cobro-prestacion";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(
    model: ParametrosCobroPrestacion,
    filterValues: any
  ) {
    try {
      if (filterValues?.securityValues?.country) {
        const parametroCobroPrestacion = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues?.securityValues?.client,
          cuenta: filterValues?.securityValues?.account,
          subcuenta: filterValues?.securityValues?.subaccount,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          parametroCobroPrestacion: parametroCobroPrestacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(
    model: ParametrosCobroPrestacion,
    filterValues: any
  ) {
    try {
      const parametroCobroPrestacion = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues?.securityValues?.client,
        cuenta: filterValues?.securityValues?.account,
        subcuenta: filterValues?.securityValues?.subaccount,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        parametroCobroPrestacion: parametroCobroPrestacion,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: ParametrosCobroPrestacion) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        parametroCobroPrestacion: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<ParametrosCobroPrestacion[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter?.securityValues?.client,
          cuenta: filter?.securityValues?.account,
          subcuenta: filter?.securityValues?.subaccount,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<ParametrosCobroPrestacion> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          parametroCobroPrestacion: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarca(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/marca",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadOrigen(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/origen",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadMercado(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/mercado",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadProceso(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/proceso",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<ParametrosCobroPrestacion>
      screenCode={SCREEN_CODE}
      title={
        <LanguageProvider
          id="15248"
          alt="Mantener Parametros Cobro Prestación"
        />
      }
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
          subaccount: true,
        },
      }}
      dataTableColumns={[
        {
          field: "marca.descripcion",
          header: <LanguageProvider id="23888" alt="Marca" />,
        },
        {
          field: "origen.descripcion",
          header: <LanguageProvider id="2496" alt="Origen" />,
        },
        {
          field: "mercado.descripcion",
          header: <LanguageProvider id="367" alt="Mercado" />,
        },
        {
          field: "proceso.descripcion",
          header: <LanguageProvider id="12409" alt="Proceso" />,
        },
        {
          field: "centroCostoGefco",
          header: <LanguageProvider id="15243" alt="Centro Costo Gefco" />,
        },
        {
          field: "conDespensaPsa",
          header: <LanguageProvider id="15244" alt="Con Despensa PSA" />,
        },
        {
          field: "centroCostoPsa",
          header: <LanguageProvider id="15245" alt="Centro Costo PSA" />,
        },
        {
          field: "ordenInternaPsa",
          header: <LanguageProvider id="15246" alt="Orden Interna PSA" />,
        },
        {
          field: "cliente.id",
          header: <LanguageProvider id="54" alt="Cliente" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        cuenta: null,
        subcuenta: null,
        marca: null,
        origen: null,
        mercado: null,
        proceso: null,
        centroCostoGefco: "",
        centroCostoPsa: "",
        conDespensaPsa: "",
        ordenInternaPsa: "",
        codClientePrestacion: "",
        activo: true,
      }}
      formSettings={[
        {
          field: "marca",
          label: <LanguageProvider id="23888" alt="Marca" />,
          dropDownPlaceholder: LanguageProvider({
            id: "23888",
            alt: "Marca",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadMarca(filter),
          dropDownTemplate: DropdownInactivoTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "origen",
          label: <LanguageProvider id="2496" alt="Origen" />,
          dropDownPlaceholder: LanguageProvider({
            id: "2496",
            alt: "Origen",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadOrigen(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country"],
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "mercado",
          label: <LanguageProvider id="367" alt="Mercado" />,
          dropDownPlaceholder: LanguageProvider({
            id: "367",
            alt: "Mercado",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadMercado(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country"],
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "proceso",
          label: <LanguageProvider id="12409" alt="Proceso" />,
          dropDownPlaceholder: LanguageProvider({
            id: "12409",
            alt: "Proceso",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadProceso(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country"],
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "centroCostoGefco",
          label: <LanguageProvider id="15243" alt="Centro Costo Gefco" />,
          type: "inputText",
          length: 4,
          capitalizeInputText: true,
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "centroCostoPsa",
          label: <LanguageProvider id="15245" alt="Centro Costo PSA" />,
          type: "inputText",
          length: 4,
          capitalizeInputText: true,
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "conDespensaPsa",
          label: <LanguageProvider id="15244" alt="Con Despensa PSA" />,
          type: "inputText",
          length: 4,
          capitalizeInputText: true,
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "ordenInternaPsa",
          label: <LanguageProvider id="15246" alt="Orden Interna PSA" />,
          type: "inputText",
          length: 4,
          capitalizeInputText: true,
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "producto",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          length: 4,
          capitalizeInputText: true,
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "codClientePrestacion",
          label: <LanguageProvider id="54" alt="Cliente" />,
          type: "inputText",
          length: 4,
          capitalizeInputText: true,
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerParametrosCobroPrestacion;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};

const DropdownInactivoTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div
        className="flex align-items-center"
        style={{ color: option.activo ? "inherit" : "red" }}
      >
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props?.placeholder}</span>;
};
