import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Toast } from "primereact/toast";
import SipcoInputText from "@shared/components/sipco-input-text";

export function ConsultaOsTerceros() {
  const SCREEN_CODE = "con0097_consulta_os_terceros";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);

  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [selectedReportData, setSelectedReportData] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterColumnsId = [
    16563, 54, 3600, 8388, 6256, 22833, 1669, 22796, 1491, 4724, 396, 10746,
    22908, 22909, 16817, 23241, 22893, 22895, 14712, 1342, 2521, 23242, 22356,
    5496, 5490, 16590,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {}
  };

  const [filter, setFilter] = useState({
    nroOfertaServicio: "",
    descCliente: "",
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    vin: null,
    cliente: null,
  });

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/consulta-os-terceros/combo/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              vin: vin,
            },
          }
        );
        if (status === 200) {
          if (data.length === 0) {
            return showError("1480", "El VIN no existe");
          }
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [loadingCliente, setLoadingCliente] = useState(false);
  const [cliente, setCliente] = useState([]);
  async function loadCliente() {
    setLoadingCliente(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/consulta-os-terceros/combo/cliente",
          {
            params: {},
          }
        );
        if (status === 200) {
          data.unshift({
            id: "TODOS",
            descripcionFull: "TODOS",
            descripcion: "TODOS",
          });
          setCliente(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCliente(false);
    }
    return [];
  }

  const [loadingReportData, setLoadingReportData] = useState(false);
  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/consulta-os-terceros/report",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCuenta: securityFilters?.securityValues?.account?.id || null,
          vin: filter?.vin?.id || "",
          nroOfertaServ: filter?.nroOfertaServicio || "",
          descCliente: filter?.descCliente || "",
          codCliente: filter?.cliente.id || null,
          rangeFecha: [filter?.fechaDesde, filter?.fechaHasta],
          clienteDescripcion: filter?.cliente.descripcion,
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const clientes = await loadCliente();
        setFilter({
          ...filter,
          cliente: clientes[0] ?? null,
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues.country]);

  return (
    <div className="consulta-os-terceros">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          account: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id="16563" alt="Nro. Oferta Servicio" />
              </label>
              <SipcoInputText
                value={filter.nroOfertaServicio}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    nroOfertaServicio: e.target.value,
                  })
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id="18997" alt="Desc. Cliente" />
              </label>
              <SipcoInputText
                value={filter.descCliente}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    descCliente: e.target.value,
                  })
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id="25182" alt="Fecha Cotización Desde" />
              </label>
              <SipcoCalendar
                value={filter.fechaDesde}
                onChange={(e) => {
                  setFilter({ ...filter, fechaDesde: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id="2622" alt="Hasta" />
              </label>
              <SipcoCalendar
                value={filter.fechaHasta}
                onChange={(e) => {
                  setFilter({ ...filter, fechaHasta: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id="662" alt="Ingrese el VIN" />
              </label>
              <SipcoAutocomplete
                value={filter.vin}
                suggestions={vin}
                completeMethod={searchVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value })}
                field="id"
                dropdown
                maxLength={17}
                forceSelection
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id="54" alt="Cliente" />
              </label>
              <SipcoDropdown
                value={filter.cliente}
                options={cliente}
                onChange={(e) => setFilter({ ...filter, cliente: e.value })}
                optionLabel="descripcionFull"
                virtualScrollerOptions={{ itemSize: 15 }}
                loading={loadingCliente}
                filter
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {<LanguageProvider id="23234" alt="Consulta OS Terceros" />}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta OS Terceros"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReportData}
          selectionMode="single"
          filterDisplay="menu"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
        >
          <SipcoColumn
            field="0"
            header={<LanguageProvider id="16563" alt="Nro. Oferta Servicio" />}
          />
          <SipcoColumn
            field="1"
            header={<LanguageProvider id="54" alt="Cliente" />}
          />
          <SipcoColumn
            field="2"
            header={<LanguageProvider id="3600" alt="Contacto" />}
          />
          <SipcoColumn
            field="3"
            header={<LanguageProvider id="8388" alt="Fecha Aceptación" />}
          />

          <SipcoColumn
            field="4"
            header={<LanguageProvider id="6256" alt="" />}
          />
          <SipcoColumn
            field="5"
            header={<LanguageProvider id="22833" alt="" />}
          />
          <SipcoColumn
            field="6"
            header={<LanguageProvider id="1669" alt="Fecha Cotización" />}
          />
          <SipcoColumn
            field="7"
            header={<LanguageProvider id="22833" alt="Fecha Deseada" />}
          />
          <SipcoColumn
            field="8"
            header={<LanguageProvider id="1669" alt="Observación" />}
          />
          <SipcoColumn
            field="9"
            header={<LanguageProvider id="22796" alt="Referencia Cliente" />}
          />
          <SipcoColumn
            field="10"
            header={<LanguageProvider id="1491" alt="VIN" />}
          />
          <SipcoColumn
            field="11"
            header={<LanguageProvider id="4724" alt="Patente" />}
          />
          <SipcoColumn
            field="12"
            header={<LanguageProvider id="396" alt="Marca" />}
          />
          <SipcoColumn
            field="13"
            header={<LanguageProvider id="10746" alt="Modelo" />}
          />
          <SipcoColumn
            field="14"
            header={<LanguageProvider id="22908" alt="Punto orígen" />}
          />
          <SipcoColumn
            field="15"
            header={<LanguageProvider id="22909" alt="Punto destino" />}
          />
          <SipcoColumn
            field="16"
            header={
              <LanguageProvider id="16817" alt="Estado Oferta Servicio" />
            }
          />
          <SipcoColumn
            field="17"
            header={<LanguageProvider id="23241" alt="Estado Venta" />}
          />
          <SipcoColumn
            field="18"
            header={<LanguageProvider id="22893" alt="Orígen viaje" />}
          />
          <SipcoColumn
            field="19"
            header={<LanguageProvider id="22895" alt="Destino viaje" />}
          />
          <SipcoColumn
            field="20"
            header={<LanguageProvider id="14712" alt="Nro. Hoja Ruta" />}
          />
          <SipcoColumn
            field="21"
            header={<LanguageProvider id="1342" alt="Fecha Despacho" />}
          />
          <SipcoColumn
            field="22"
            header={<LanguageProvider id="2521" alt="Proveedor" />}
          />
          <SipcoColumn
            field="23"
            header={<LanguageProvider id="23242" alt="Estado Compra" />}
          />
          <SipcoColumn
            field="24"
            header={<LanguageProvider id="22356" alt="Tipo de Servicio" />}
          />
          <SipcoColumn
            field="25"
            header={<LanguageProvider id="5496" alt="Valor Compra" />}
          />
          <SipcoColumn
            field="26"
            header={<LanguageProvider id="5490" alt="Valor Venta" />}
          />
          <SipcoColumn
            field="27"
            header={<LanguageProvider id="16590" alt="Valor Compra Fletero" />}
          />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default ConsultaOsTerceros;
