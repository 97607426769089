import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { RelacionResponsable } from "src/models/RelacionResponsable";

const MantenerRelacionResponsables = () => {
  const SCREEN_CODE = "abm0016_mantener_relacion_responsables";
  const URL_BASE = "/mantener-relacion-responsables";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: RelacionResponsable, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const relacionResponsable = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          relacionResponsable,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: RelacionResponsable, filterValues: any) {
    try {
      const relacionResponsable = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        relacionResponsable,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: RelacionResponsable, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        relacionResponsable: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<RelacionResponsable[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<RelacionResponsable> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          relacionResponsable: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadResponsable(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/responsable",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadResponsableCliente(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/responsableCliente",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<RelacionResponsable>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="346" alt="Relación entre Responsables" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      dataTableColumns={[
        {
          field: "responsableCliente.descripcion",
          header: <LanguageProvider id="16246" alt="Responsable del cliente" />,
        },
        {
          field: "responsable.descripcion",
          header: <LanguageProvider id="336" alt="Responsables" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        activo: true,
        pais: null,
        cliente: null,
        responsable: null,
        responsableCliente: null,
      }}
      loadDropdownData={{
        responsableCliente: loadResponsableCliente,
        responsable: loadResponsable,
      }}
      formSettings={[
        {
          field: "responsableCliente",
          label: <LanguageProvider id="16247" alt=" Codigo Responsable del Cliente" />,
          type: "dropdown",
          dataSource: (filter: any) => loadResponsableCliente(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dropDownPlaceholder: LanguageProvider({
            id: "16247",
            alt: "Codigo Responsable del Cliente",
          }),
        },
        {
          field: "responsable",
          label: <LanguageProvider id="16248" alt="Codigo de Responsables" />,
          type: "dropdown",
          dataSource: (filter: any) => loadResponsable(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dropDownPlaceholder: LanguageProvider({
            id: "16248",
            alt: "Codigo de Responsables",
          }),
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerRelacionResponsables;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
