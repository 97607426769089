import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { useFilterContext } from "../../../../protected-routes";

export function CopiarPermisos({
  titleModal,
  userData,
  activeModal,
  setTitleModal
}) {
  const sipcoAxiosService = useSipcoAxiosService();
    const { values: securityFilters } = useFilterContext();
  
  
  const [listPaises, setListPaises] = useState([]);
  const [paisSelectedObject, setPaisSelectedObject] = useState(null);

  const [listAllUsuarios, setListAllUsuarios] = useState([]);
  const [usuarioSelectedObject, setUsuarioSelectedObject] = useState(null);

  const [checkedMarcas, setCheckedMarcas] = useState(false);
  const [checkedConcesionarios, setCheckedConcesionarios] = useState(false);
  const [checkedOperaciones, setCheckedOperaciones] = useState(false);
  

  useEffect(()=> {
    findPaises();
  }, []);

  const footerContent = (
    <div>
      <Button
        label="Aceptar"
        icon="pi pi-check"
        onClick={() => aceptar()}
        autoFocus
      />

      <Button
        label="Cerrar"
        icon="pi pi-times"
        onClick={() => {
          setTitleModal(null);
        }}
        className="p-button-text"
      />
    </div>
  );

  function aceptar(): void {
    findPermisosUsuarioPais();
  }

  async function findPaises() {
    try {
      const { data, status } = await sipcoAxiosService.get(
        "/mantenerUsuario/findBySistema",
        {
          params: {
            codSistema: "02",
          },
        }
      );

      if (status === 200) {
        setListPaises(data);
        setPaisSelectedObject(data[0]);
        findAllusuarios(data[0].id); 
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findAllusuarios(paisId) {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/findAllUsuarios",
        {
          params: {
            codPais: paisId,
          },
        }
      );
      if (status === 200) {
        console.log(data);
        setListAllUsuarios(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findPermisosUsuarioPais() {


    try {
      const { status } = await sipcoAxiosService.get(
        "/mantenerUsuario/permisosUsuarioPais",
        {
          params: {
            codPais: paisSelectedObject?.id,
            codUsuario: userData?.id
          },
        }
      );
      if (status === 200) {
        if(!false) alert("El usuario seleccionado no posee permisos para este país.");
        executeCopiarPermisos();     
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function executeCopiarPermisos () {

    try {
      const { status } = await sipcoAxiosService.post(
        "/mantenerUsuario/executeCopiarPermisos",
        {},
        {
          params: { 
            codPais: paisSelectedObject?.id,
            codUsuarioOrigem: userData?.id,
            codPaisUsuarioOrigem: userData?.pais.id,
            codUsuarioDestino: usuarioSelectedObject?.id,
                marcas: checkedMarcas,
                concesionarios: checkedConcesionarios,
                operaciones: checkedOperaciones        
           },
        }
      );

      if (status === 200) {
        // toast.current.show({
        //   severity: "success",
        //   summary: "Success",
        //   detail: "Carrera forzada con éxito!",
        //   life: 3000,
        // });
      }
    } catch (error) {
      console.error(error);
    }

  }

  return (
    <>
      <div className={""}>
        <Dialog
          header={titleModal}
          visible={activeModal}
          maximizable
          style={{ width: "45vw" }}
          footer={footerContent}
          onHide={() => {
            if (!activeModal) return;
            setTitleModal(false);
          }}
        >
          <div className="cp-option-modal">
           
              <div className={"sipco-option-modal"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="País" />
                </label>
                <SipcoDropdown
                  value={paisSelectedObject?.descripcion}
                  placeholder={paisSelectedObject?.descripcion}
                  options={listPaises}
                  onChange={(e) => {
                    setPaisSelectedObject(e.value)
                    findAllusuarios(e.value.id);
                  }}
                  filter={false}
                  optionLabel="descripcion"
                  className="w-full md:w-14rem"
                  loading={listPaises.length > 0 ? false : true}
                />
              </div>

              <div className={"sipco-option-modal"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Usuarios" />
                </label>
                <SipcoDropdown
                  value={usuarioSelectedObject?.id}
                  placeholder={usuarioSelectedObject?.id}
                  options={listAllUsuarios}
                  className="w-full md:w-14rem"
                  onChange={(e) => 
                  {
                    setUsuarioSelectedObject(e.value);
                  }}
                  itemTemplate={(option) => `${option.apellido}, ${option.nombre} (${option.id})`}
                  loading={listAllUsuarios.length > 0 ? false : true}
                  filter
                  optionLabel="nombre"
                />
              </div>
            
            <div className={"cp-org-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Marcas" />
              </label>
              <Checkbox
                checked={checkedMarcas}
                onClick={() => {
                  setCheckedMarcas(!checkedMarcas);
                }}
              ></Checkbox>
            </div>
            <div className={"cp-org-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Consecionarios" />
              </label>
              <Checkbox
                checked={checkedConcesionarios}
                onClick={() => {
                  setCheckedConcesionarios(!checkedConcesionarios);
                }}
              ></Checkbox>
            </div>
            <div className={"cp-org-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Operaciones" />
              </label>
              <Checkbox
                checked={checkedOperaciones}
                onClick={() => {
                  setCheckedOperaciones(!checkedOperaciones);
                }}
              ></Checkbox>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default CopiarPermisos;
