import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { CalendarioDesvio } from "src/models/CalendarioDesvio";

const MantenerCalendarioDesvioEntrega = () => {
  const SCREEN_CODE = "abm0216_mantener_calendario_desvio_entrega";
  const URL_BASE = "/mantener-calendario-desvio-entrega";
  const SCREEN_TITLE = <LanguageProvider id="19729" alt="Mantener calendario desvio entrega" />;
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: CalendarioDesvio, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues.securityValues.account
      ) {
        const calendarioDesvio = {
          ...model,
          fecha: {data: model.fecha},
          pais: filterValues.securityValues.country,
          cuenta: filterValues.securityValues.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          calendarioDesvio,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: CalendarioDesvio, filterValues: any) {
    try {
      const calendarioDesvio = {
        ...model,
        fecha: {data: model.fecha},
        pais: filterValues.securityValues.country,
        cuenta: filterValues.securityValues.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        calendarioDesvio,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: CalendarioDesvio, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        calendarioDesvio: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<CalendarioDesvio[]> {
    if (filter?.securityValues?.country && filter.securityValues.account) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            pais: filter.securityValues.country,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<CalendarioDesvio> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          calendarioDesvio: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadDesvioEntrega(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/desvio-entrega",
          {
            pais: filter.securityValues.country,
            cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<CalendarioDesvio>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            account: true,
            
          },
        }}
        // filterSettings={}
        dataTableColumns={[
          {
            field: "desvioEntrega.descripcion",
            header: <LanguageProvider id="1838" alt="x" />,
          },
          {
            field: "fecha.date",
            header: <LanguageProvider id="16898" alt="x" />,
            body: (rowData: any) => {
              return (
                <span>{new Date(rowData.fecha.date).toLocaleDateString()}</span>
              );
            },
          },
          {
            field: "utilizado",
            header: <LanguageProvider id="10069" alt="x" />,
            body: (rowData: any) => {
              return rowData.utilizado ? "SI" : "NO";
            },
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => {
              return (
                <span>
                  {new Date(
                    rowData.fechaUltimaModificacion.date
                  ).toLocaleString()}
                </span>
              );
            },
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => {
              return rowData.activo ? "SI" : "NO";
            },
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          cuenta: null,
          desvioEntrega: null,
          fecha: new Date(),
          utilizado: false,
        }}
        formSettings={[
          {
            field: "desvioEntrega",
            label: LanguageProvider({ id: "1838", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "1838", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadDesvioEntrega(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "dtChooser",
            label: LanguageProvider({ id: "16898", alt: "x" }),
            type: "calendar",
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        // update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default MantenerCalendarioDesvioEntrega;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
