import "./ComprasTaller.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ComprasTaller() {
  const SCREEN_CODE = "con0038_compras_taller";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();

  const dt = useRef(null);
  const filterColumnsId = [
    5372, 16480, 932, 2519, 10829, 31, 1253, 1974, 4137, 4587, 2520, 2521,
    16481, 16482, 16483, 1974, 13706, 5271, 5788, 16476, 2263, 8818, 17041,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    taller: null,
    desde: true,
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    estado: null,
    proveedor: null,
  });

  const [loadingTaller, setLoadingTaller] = useState(false);
  const [taller, setTaller] = useState([]);
  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/compras-taller/combo/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
            },
          }
        );
        if (status === 200) {
          setTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
    return [];
  }

  const [loadingEstados, setLoadingEstados] = useState(false);
  const [estados, setEstados] = useState([]);
  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/compras-taller/combo/estado-documento",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setEstados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
    return [];
  }

  const [loadingProveedor, setLoadingProveedor] = useState(false);
  const [proveedor, setProveedor] = useState([]);
  async function loadProvedores() {
    setLoadingProveedor(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/compras-taller/combo/proveedor"
        );
        if (status === 200) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setProveedor(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedor(false);
    }
    return [];
  }

  // const [findBy, setFindBy] = useState(null);
  // async function loadFindBy() {
  //   try {
  //     const filters = {
  //       codPais: securityFilters?.securityValues?.country?.id,
  //       codCuenta: securityFilters?.securityValues?.account?.id,
  //       codSubcuenta: securityFilters?.securityValues?.subaccount?.id,
  //       codTaller: filter?.taller?.id || null,
  //     };
  //     const { status, data } = await sipcoAxiosService.post(
  //       "/compras-taller/findBy",
  //       filters
  //     );
  //     if (status === 200) {
  //       setFindBy(data);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reportData, setReportData] = useState(null);
  async function loadReportData() {
    setLoadingReportData(true);
    try {
      let filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        rangeFecha: [filter?.fechaDesde, filter?.fechaHasta],
        codTaller: filter?.taller?.id || null,
        proveedor: filter?.proveedor?.id || null,
        estado: filter?.estado?.id || null,
      };
      const { status, data } = await sipcoAxiosService.post(
        "compras-taller/report",
        filters
      );
      if (status === 200) {
        setReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const talleres = await loadTaller();
        const estados = await loadEstados();
        setFilter({
          ...filter,
          taller: talleres[0],
          estado: estados[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  useEffect(() => {
    async function handleCountryChange() {
      try {
        const provedores = await loadProvedores();
        setFilter({
          ...filter,
          proveedor: provedores[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    handleCountryChange();
  }, [securityFilters.securityValues.country]);

  const data = convertDateObjects(reportData?.filas);

  return (
    <div className="compras-taller">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id="743" alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="327" alt="Taller" />
                </label>
                <SipcoDropdown
                  value={filter.taller}
                  options={taller}
                  onChange={(e) => {
                    setFilter({ ...filter, taller: e.value });
                  }}
                  filter
                  optionLabel="descripcion"
                  loading={loadingTaller}
                  showClear
                />
              </div>
              <div className="sipco-option-check">
                <label>
                  <LanguageProvider id="" alt="" />
                </label>
                <Checkbox
                  checked={filter.desde}
                  onChange={(e) => {
                    const isChecked = e.checked;
                    setFilter({
                      ...filter,
                      desde: isChecked,
                      fechaDesde: isChecked ? new Date() : null,
                      fechaHasta: isChecked ? new Date() : null,
                    });
                  }}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="467" alt="Desde :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaDesde}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaDesde: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                  disabled={!filter.desde}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="468" alt="Hasta :" />
                </label>
                <SipcoCalendar
                  value={filter.fechaHasta}
                  selectionMode="single"
                  onChange={(e) => {
                    setFilter({ ...filter, fechaHasta: e.value });
                  }}
                  dateFormat="dd/mm/yy"
                  hideOnRangeSelection
                  disabled={!filter.desde}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="1151" alt="Estados" />
                </label>
                <SipcoDropdown
                  value={filter.estado}
                  options={estados}
                  onChange={(e) => {
                    setFilter({ ...filter, estado: e.value });
                  }}
                  filter
                  optionLabel="descripcion"
                  loading={loadingEstados}
                  showClear
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="2521" alt="Proveedor" />
                </label>
                <SipcoDropdown
                  value={filter.proveedor}
                  options={proveedor}
                  onChange={(e) => {
                    setFilter({ ...filter, proveedor: e.value });
                  }}
                  filter
                  optionLabel="descripcion"
                  loading={loadingProveedor}
                  virtualScrollerOptions={{ itemSize: 38 }}
                  showClear
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="2355" alt="Compras Taller" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Compras Taller"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          sortField="0"
          sortOrder={1}
          paginator
          scrollHeight="flex"
          rows={15}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="single"
        >
          <SipcoColumn
            field="0"
            header={<LanguageProvider id="5372" alt="Compra" />}
            sortable
            filter
          />
          <SipcoColumn
            field="1"
            header={<LanguageProvider id="16480" alt="Nro. Pedido PSA" />}
            sortable
            filter
          />
          <SipcoColumn
            field="2"
            header={<LanguageProvider id="932" alt="Código Remito" />}
            sortable
            filter
          />
          <SipcoColumn
            field="3"
            header={<LanguageProvider id="2519" alt="Fecha Compra" />}
            sortable
            filter
          />
          <SipcoColumn
            field="4"
            header={<LanguageProvider id="10829" alt="Código Pieza" />}
            sortable
            filter
          />
          <SipcoColumn
            field="5"
            header={<LanguageProvider id="31" alt="Descripción" />}
            sortable
            filter
          />
          <SipcoColumn
            field="6"
            header={<LanguageProvider id="1253" alt="Cantidad" />}
            sortable
            filter
          />
          <SipcoColumn
            field="7"
            header={<LanguageProvider id="1974" alt="Importe" />}
            sortable
            filter
          />
          <SipcoColumn
            field="8"
            header={<LanguageProvider id="4137" alt="Prestación" />}
            sortable
            filter
          />
          <SipcoColumn
            field="9"
            header={<LanguageProvider id="4587" alt="Subprestación" />}
            sortable
            filter
          />
          <SipcoColumn
            field="10"
            header={<LanguageProvider id="2520" alt="Fecha Cierre" />}
            sortable
            filter
          />
          <SipcoColumn
            field="11"
            header={<LanguageProvider id="2521" alt="Proveedor" />}
            sortable
            filter
          />
          <SipcoColumn
            field="12"
            header={<LanguageProvider id="16481" alt="Código vale" />}
            sortable
            filter
          />
          <SipcoColumn
            field="13"
            header={<LanguageProvider id="16482" alt="Código moneda" />}
            sortable
            filter
          />
          <SipcoColumn
            field="14"
            header={<LanguageProvider id="16483" alt="Código estado" />}
            sortable
            filter
          />
          <SipcoColumn
            field="15"
            header={<LanguageProvider id="1974" alt="Importe" />}
            sortable
            filter
          />
          <SipcoColumn
            field="16"
            header={
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            }
            sortable
            filter
          />
          <SipcoColumn
            field="17"
            header={<LanguageProvider id="5271" alt="Fec. Últ. Modificación" />}
            sortable
            filter
          />
          <SipcoColumn
            field="18"
            header={<LanguageProvider id="5788" alt="Línea" />}
            sortable
            filter
          />
          <SipcoColumn
            field="19"
            header={
              <LanguageProvider id="16476" alt="Código Cliente Repuestos" />
            }
            sortable
            filter
          />
          <SipcoColumn
            field="20"
            header={<LanguageProvider id="2263" alt="Descuento (%)" />}
            sortable
            filter
          />
          <SipcoColumn
            field="21"
            header={<LanguageProvider id="8818" alt="Importe Neto" />}
            sortable
            filter
          />
          <SipcoColumn
            field="22"
            header={<LanguageProvider id="17041" alt="Cant. Entregada" />}
            sortable
            filter
          />
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default ComprasTaller;
