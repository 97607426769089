import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { CicloOperacion } from "src/models/CicloOperacion";

const CiclosOperacion = () => {
  const SCREEN_CODE = "abm0189_ciclos_operacion";
  const URL_BASE = "/ciclos-operacion";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: CicloOperacion, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const cicloOperacion = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          cicloOperacion: cicloOperacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: CicloOperacion) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        cicloOperacion: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<CicloOperacion[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadOperacion(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/operacion",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadTransacion(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/transaccion",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadOrigen(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/origen",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<CicloOperacion>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="14319" alt="Mantener Ciclo Operación" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      dataTableColumns={[
        {
          field: "pais.descripcion",
          header: <LanguageProvider id="5044" alt="País" />,
        },
        {
          field: "cliente.descripcion",
          header: <LanguageProvider id="54" alt="Cliente" />,
        },
        {
          field: "operacion.descripcion",
          header: <LanguageProvider id="18637" alt="Operación" />,
        },
        {
          field: "transaccion.descripcion",
          header: <LanguageProvider id="14288" alt="Transacción" />,
        },
        {
          field: "desde",
          header: <LanguageProvider id="2621" alt="Desde" />,
        },
        {
          field: "intervalo",
          header: <LanguageProvider id="4880" alt="Intervalo" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "origen.descripcion",
          header: <LanguageProvider id="2496" alt="Origen" />,
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
        {
          field: "verificaReingreso",
          header: <LanguageProvider id="14383" alt="Verifica Reingreso" />,
          body: (rowData: any) => {
            return rowData.verificaReingreso ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        operacion: null,
        transaccion: null,
        origen: null,
        desde: "",
        intervalo: "",
        activo: true,
        verificaReingreso: false,
      }}
      formSettings={[
        {
          field: "operacion",
          label: <LanguageProvider id="18637" alt="Operación" />,
          dropDownPlaceholder: LanguageProvider({
            id: "18637",
            alt: "Operación",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadOperacion(filter),
          dropDownTemplate: DropdownInactivoTemplate,
          virtualizeDropdown: true,
          required: true,
        },
        {
          field: "transaccion",
          label: <LanguageProvider id="14288" alt="Transacción" />,
          dropDownPlaceholder: LanguageProvider({
            id: "14288",
            alt: "Transacción",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadTransacion(filter),
          dropDownTemplate: DropdownOptionTemplate,
          virtualizeDropdown: true,
          required: true,
        },
        {
          field: "origen",
          label: <LanguageProvider id="2496" alt="Origen" />,
          dropDownPlaceholder: LanguageProvider({
            id: "2496",
            alt: "Origen",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadOrigen(filter),
          dropDownTemplate: DropdownOptionTemplate,
          required: true,
        },
        {
          field: "desde",
          label: <LanguageProvider id="12282" alt="Día Desde" />,
          type: "inputNumber",
          min: 0,
          max: 9999,
          required: true,
        },
        {
          field: "intervalo",
          label: <LanguageProvider id="4880" alt="Intervalo" />,
          type: "inputNumber",
          min: 0,
          max: 9999,
          required: true,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
        {
          field: "verificaReingreso",
          label: <LanguageProvider id="14383" alt="Verifica Reingreso" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={null}
      save={handleSave}
      update={null}
      remove={handleRemove}
    />
  );
};

export default CiclosOperacion;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};

const DropdownInactivoTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div
        className="flex align-items-center"
        style={{ color: option.activo ? "inherit" : "red" }}
      >
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props?.placeholder}</span>;
};
