import React, { useEffect, useRef, useState } from "react";
import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { InputText } from "primereact/inputtext";
import { Fieldset } from "primereact/fieldset";
import ModalAltaReparacionServicos from "./modals/servicos/ModalAltaReparacionServicos";
import { HttpStatusCode } from "axios";
import ModalModificationReparacionServicos from "./modals/servicos/ModalModificationReparacionServicos";

export function OrdenReparacionServicios() {
  const SCREEN_CODE = "abm0108_orden_reparacion_servicios";
  ScreenCodeValue(SCREEN_CODE);
  const [selectedRows, setSelectedRows] = useState([]);

  const [selectedRowsServices, setSelectedRowsServices] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  const { order } = location.state || {};
  const toast = useRef<Toast>(null);
  const [alta, setAlta] = useState(false);
  const [modification, setModification] = useState(false);

  const showModalAlta = () => {
    setAlta(true);
  };

  const hideModalAlta = () => {
    setAlta(false);
  };

  const showModalModification = () => {
    if (selectedRows.length <= 0) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: (
          <LanguageProvider
            id="753"
            alt="Debe seleccionar al menos un registro de la Grilla."
          />
        ),
        life: 3000,
      });
    } else {
      setModification(true);
    }
  };

  const hideModalModification = () => {
    setModification(false);
  };

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);

  const [ordenReparacionServicios, setOrdenReparacionServicios] = useState([]);

  const [filter, setFilter] = useState({
    taller: null,
    codCompra: null,
  });

  const data = convertDateObjects(reporte?.filas);

  const columnsNames = [];

  const filterColumnsId = [
    16949, 2518, 11833, 2519, 16483, 16480, 8627, 3756, 16968, 3756, 16481,
    16482, 3756, 13706, 23848,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [reparacionServicesTable, setReparacionServicesTable] = useState([]);

  async function loadReportData() {
    try {
      setLoading(true);
      const reportResult = await sipcoAxiosService.post(
        "/orden-reparacion-servicios/historico-danios",
        {
          pais: order.pais,
          cuenta: order.cuenta,
          vin: order.vin,
          numeroOrden: order.id,
        }
      );

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === 200 && HttpStatusCode.Ok) {
          setReparacionServicesTable(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsNamesTableServices = [
    { field: "fechaCarga.date", header: "Fecha Carga" },
    { field: "fechaReparado.date", header: "Fecha Reparado" },
    { field: "tipoDeDanio.descripcionFull", header: "Código Daño" },
    { field: "partesDanios.descripcionFull", header: "Parte" },
    { field: "gravedadDeDanio.descripcionFull", header: "Cod. Gravedad" },
    { field: "imputacion.descripcion", header: "Imputación" },
    { field: "responsable.descripcion", header: "Responsable" },
    { field: "estadoDanio.descripcion", header: "Estado" },
    { field: "usuarioUltimaModificacion.id", header: "Usuario" },
    { field: "checkpoint.descripcion", header: "CheckPoint" },
    { field: "transaccion.descripcion", header: "Transacción" },
    { field: "fechaDanio.date", header: "Fecha de Daño" },
    { field: "usuarioUltimaModificacion.id", header: "Modificó Usuario" },
  ];

  async function loadReportDataListado() {
    try {
      setLoading(true);
      const reportResult = await sipcoAxiosService.post(
        "/orden-reparacion-servicios/find-by",
        {
          pais: order.pais,
          cuenta: order.cuenta,
          subcuenta: order.subcuenta,
          taller: order.taller,
          orden: {
            id: order.id,
          },
        }
      );

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === 200 && data) {
          setOrdenReparacionServicios(data);
          setReporte(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsNamesTableListado = [
    { field: "id", header: "Linea" },
    { field: "clienteServicio.id", header: "Cliente Servicio" },
    { field: "servicio.id", header: "Código Servicio" },
    { field: "servicio.descripcion", header: "Descripción" },
    { field: "horasEstimadas", header: "Horas Estimadas" },
    { field: "mecanico.descripcion", header: "Mecánico" },
    { field: "codori", header: "Origen" },
    { field: "prestacion", header: "Prestación" },
    { field: "subprestacion", header: "Subprestación" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  useEffect(() => {
    const init = async () => {
      try {
        await loadReportDataListado();
        await loadReportData();
      } catch (error) {
        console.log(error);
      }
    };

    init();
  }, [order]);

  const [loadingRemoveLabel, setLoadingRemoveLabel] = useState(false);
  const [removeLabel, setRemoveLabel] = useState(null);

  async function remove() {
    setLoadingRemoveLabel(true);
    try {
      if (selectedRows.length !== 0) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-servicios/remove",
          {
            detallesOrdenServicio: {
              pais: order.pais ?? null,
              cuenta: order.cuenta ?? null,
              subcuenta: order.subcuenta ?? null,
              taller: order.taller ?? null,
              ordenReparacion: order,
              id: selectedRows.map((x) => x.id)[0],
              servicio: selectedRows[0] ?? null,
            },
          }
        );
        if (status === HttpStatusCode.Ok) {
          setRemoveLabel(data);
          return data;
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: (
            <LanguageProvider
              id={"753"}
              alt="Debe seleccionar al menos un registro de la Grilla."
            />
          ),
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRemoveLabel(false);
    }
  }

  return (
    <div className="orden-reparacion">
      <Toast ref={toast} position="top-center" />

      <ModalAltaReparacionServicos
        visible={alta}
        onClose={hideModalAlta}
        order={order}
      />
      <ModalModificationReparacionServicos
        visible={modification}
        onClose={hideModalModification}
        order={order}
        listado={selectedRows}
      />

      <Filter>
        <AccordionTab header={<LanguageProvider id={"65"} alt="Seguridad" />}>
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"5044"} alt="País" />
                </label>
                <InputText value={order.pais?.descripcion} disabled={true} />
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <InputText value={order?.taller?.descripcion} disabled={true} />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"24328"} alt="Cuenta" />
                </label>
                <InputText value={order.cuenta?.descripcion} disabled={true} />
                <label>
                  <LanguageProvider id={"5701"} alt="Nro. Orden" />
                </label>
                <InputText value={order?.id} disabled={true} />
              </div>
              <div className={"sipco-option"} style={{ marginBottom: "5em" }}>
                <label>
                  <LanguageProvider id={"441"} alt="Playas" />
                </label>
                <InputText
                  value={order.subcuenta?.descripcion}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Fieldset
        legend={
          <LanguageProvider id={"2297"} alt="Orden Reparación Servicios" />
        }
      >
        <div className="fieldset-content">
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={reparacionServicesTable}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={25}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
            selectionMode="multiple"
            selection={selectedRowsServices}
            onSelectionChange={(e: any) => setSelectedRowsServices(e.value)}
            metaKeySelection={true}
            dragSelection
          >
            {columnsNamesTableServices.map((colum, index) => {
              if (
                colum.header === "Fecha Carga" ||
                colum.header === "Fecha Reparado" ||
                colum.header === "Fecha de Daño"
              ) {
                return (
                  <Column
                    key={index}
                    field={colum.field}
                    body={(e) => dateColumnTemplate(e, colum.field)}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              } else {
                return (
                  <Column
                    key={index}
                    field={colum.field}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              }
            })}
          </DataTable>
        </div>

        <Panel
          className="sipco-AdmRemitosConcesionarios"
          headerTemplate={(props) => {
            return (
              <div className={props.className} onClick={props.onTogglerClick}>
                <span className={props.titleClassName}>Listado</span>
                <div className="action">
                  <ExportButtons
                    dt={dt}
                    data={data}
                    columns={columnsNames}
                    screenName={"AdministracionRemitos"}
                  />
                </div>
              </div>
            );
          }}
          footerTemplate={(props) => {
            return (
              <div className={props.className}>
                <div>
                  <Button
                    label={LanguageProvider({ id: "25", alt: "Alta" })}
                    text
                    onClick={showModalAlta}
                  />
                  <Button
                    label={LanguageProvider({ id: "27", alt: "Modificar" })}
                    text
                    onClick={showModalModification}
                  />
                  <Button
                    label={LanguageProvider({ id: "2016", alt: "Borrar" })}
                    text
                    onClick={remove}
                  />

                  <Button
                    label={LanguageProvider({
                      id: "2247",
                      alt: "Detalle Proveedores",
                    })}
                    text
                    onClick={() => {
                      if (selectedRows.length === 0) {
                        toast.current?.show({
                          severity: "error",
                          summary: "Error",
                          detail: (
                            <LanguageProvider
                              id={"753"}
                              alt="Debe seleccionar al menos un registro de la Grilla."
                            />
                          ),
                          life: 3000,
                        });
                      } else {
                        navigate("/orden-reparacion-proveedores", {
                          state: {
                            listado: selectedRows[0],
                            order: order,
                          },
                        });
                      }
                    }}
                  />
                </div>
              </div>
            );
          }}
        >
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={ordenReparacionServicios}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={25}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
            selectionMode="multiple"
            selection={selectedRows}
            onSelectionChange={(e: any) => setSelectedRows(e.value)}
            metaKeySelection={true}
            dragSelection
          >
            {columnsNamesTableListado.map((colum, index) => {
              if (colum.header === "Fecha Compra") {
                return (
                  <Column
                    key={index}
                    field={colum.field}
                    body={(e) => dateColumnTemplate(e, colum.field)}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              } else {
                return (
                  <Column
                    key={index}
                    field={colum.field}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              }
            })}
          </DataTable>
        </Panel>
      </Fieldset>
    </div>
  );
}
