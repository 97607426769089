import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ZonaPlaya } from "src/models/ZonaPlaya";

const MantenerZonasPlaya = () => {
  const SCREEN_CODE = "abm0126_mantener_zonas_playa";
  const URL_BASE = "/mantener-zonas-playa";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ZonaPlaya, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const zonaPlaya = {
          ...model,
          pais: filterValues.securityValues.country,
          cuenta: filterValues.securityValues.account,
          subcuenta: filterValues.securityValues.subaccount,
          descripcion: model.descripcion,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          zonaPlaya,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ZonaPlaya, filterValues: any) {
    try {
      const zonaPlaya = {
        ...model,
        pais: filterValues.securityValues.country,
        cuenta: filterValues.securityValues.account,
        subcuenta: filterValues.securityValues.subaccount,
        descripcion: model.descripcion,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        zonaPlaya,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  
  async function handleRemove(model: ZonaPlaya, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        zonaPlaya: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ZonaPlaya[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter.securityValues.country,
          cuenta: filter.securityValues.account,
          subcuenta: filter.securityValues.subaccount,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<ZonaPlaya> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          zonaPlaya: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <GenericAbm<ZonaPlaya>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="647" alt="Mantener Talleres" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          account: true,
          subaccount: true,
        },
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Código" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="Descripcion" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => rowData.activo ? "SI" : "NO",
        },
      ]
      }
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        pais: null,
        cuenta: null,
        fechaUltimaModificacion: null,
        subcuenta: null,
        usuarioUltimaModificacion: null,
        descripcion: "",
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          disableOnUpdate: true,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripción" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerZonasPlaya;
