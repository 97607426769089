import React, { useEffect, useState } from "react";
import { LanguageProvider } from "@shared/components/language-provider";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { useFilterContext } from "../../../../protected-routes";

export function GeralConcesionarios({
  showModal,
  setShowModal,
  userData,
  concesionarios,
}) {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const [clienteSelectedObject, setClienteSelectedObject] = useState(null);
  const [listClientes, setListClientes] = useState([]);
  const [listDirDeliveryObject, setListDirDeliveryObject] = useState(
    showModal === "Mantener Usuarios concesionarios - Modificar"
      ? [concesionarios?.direccionEntrega]
      : []
  );
  const [dirDeliverySelecteds, setDirDeliverySelecteds] = useState([]);
  const [loadingConc, setLoadingConc] = useState(false);
  const [loadingDirDelivery, setLoadingDirDelivery] = useState(false);

  const [concesionarioSelectedObject, setConcesionarioSelectedObject] =
    useState(null);
  const [listConcesionarios, setListConcesionarios] = useState([]);

  const [activoChecked, setActivoChecked] = useState(true);

  useEffect(() => {
    console.log(concesionarios);
    findClientes();
    if (showModal === "Mantener Usuarios concesionarios - Modificar")
      findConcesionarios(concesionarios?.cliente?.id);
  }, []);

  const footerContent = (
    <div>
      <Button
        label="Aceptar"
        icon="pi pi-check"
        onClick={() => {
          if(dirDeliverySelecteds.length === 0 || dirDeliverySelecteds === null) {
            return alert("Debe seleccionar al menos un registro de la Grilla.")
          }
          //TODO popup confirmação
          showModal === "Mantener Usuarios concesionarios - Modificar"
            ? modifyConcesionario()
            : save();
        }}
        autoFocus
      />

      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => {
          setShowModal(false);
        }}
        className="p-button-text"
      />
    </div>
  );

  async function save() {
    if(!clienteSelectedObject || !setDirDeliverySelecteds || !concesionarioSelectedObject){
      return alert("falta dados")
    }
    let param = "concesionariosSave";
    if (dirDeliverySelecteds.length > 1) param = "concesionariosSaveMultiples";

    if (listClientes.length < 1 || listConcesionarios.length < 1)
      return alert("aguarde a pesquisa");

    let body = {
      ...concesionarios,
      activo: activoChecked,
      pais: securityFilters.securityValues.country,
      usuario: userData,
      cliente: clienteSelectedObject,
      concesionario: concesionarioSelectedObject,
      direccionEntrega:
        dirDeliverySelecteds.length > 1 ? null : dirDeliverySelecteds[0],
      direccionesDeEntrega:
        dirDeliverySelecteds.length > 1 ? dirDeliverySelecteds : null,
      usuarioUltimaModificacion: userData,
    };

    try {
      const { status, data } = await sipcoAxiosService.post(
        `/mantenerUsuario/${param}`,
        body,
        {}
      );

      if (status === 200) {
        console.log(data);
        setDirDeliverySelecteds([]);
        setClienteSelectedObject(null);
        setConcesionarioSelectedObject(null);

        // toast.current.show({
        //   severity: "success",
        //   summary: "Success",
        //   detail: "Carrera forzada con éxito!",
        //   life: 3000,
        // });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function modifyConcesionario() {
    if(!clienteSelectedObject || !setDirDeliverySelecteds || !concesionarioSelectedObject){
      return alert("falta dados")
    }
    if (dirDeliverySelecteds?.length === 0) return;

    let body = {
      ...concesionarios,
      activo: activoChecked,
      pais: securityFilters.securityValues.country,
      usuario: userData,
      cliente: clienteSelectedObject,
      concesionario: concesionarioSelectedObject,
      direccionEntrega:
        dirDeliverySelecteds?.length > 1 ? null : dirDeliverySelecteds[0],
      direccionesDeEntrega:
        dirDeliverySelecteds?.length > 1 ? dirDeliverySelecteds : null,
      usuarioUltimaModificacion: userData,
    };

    console.log("BODY: ", body);

    try {
      const { status, data } = await sipcoAxiosService.post(
        `/mantenerUsuario/modificarConcesionario`,
        body,
        {}
      );

      if (status === 200) {
        console.log(status, data);

        // toast.current.show({
        //   severity: "success",
        //   summary: "Success",
        //   detail: "Carrera forzada con éxito!",
        //   life: 3000,
        // });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findClientes() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/findClientes"
      );
      if (status === 200) {
        setListClientes(data);
        if (showModal === "Mantener Usuarios concesionarios - Modificar") {
          const fullData = data.find(
            (value) => value?.id === concesionarios?.cliente?.id
          );
          setClienteSelectedObject(fullData);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findConcesionarios(codCliente: string) {
    setLoadingConc(true);

    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/findConcesionarios",
        {
          params: {
            codPais: Number(securityFilters.securityValues?.country?.id),
            codCliente: codCliente,
          },
        }
      );
      if (status === 200) {
        setListConcesionarios(data);
        setLoadingConc(false);

        if (showModal === "Mantener Usuarios concesionarios - Modificar") {
          const fullData = data.find(
            (value) => value?.id === concesionarios?.concesionario?.id
          );
          setConcesionarioSelectedObject(fullData);
        }
      }
    } catch (error) {
      console.error(error);
      setLoadingConc(false);
    }
  }

  async function findDirdelivery(idConcesionario: string) {
    setLoadingDirDelivery(true);

    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/findDirDelivery",
        {
          params: {
            codPais: Number(securityFilters.securityValues?.country?.id),
            codCliente: clienteSelectedObject?.id,
            codConcesionario: idConcesionario,
          },
        }
      );
      if (status === 200) {
        setListDirDeliveryObject(data);
        setLoadingDirDelivery(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingDirDelivery(false);
    }
  }

  return (
    <>
      <div className={"modal-geral"}>
        <Dialog
          header={showModal}
          visible={showModal}
          maximizable
          style={{ width: "55vw" }}
          footer={footerContent}
          onHide={() => {
            if (!showModal) return;
            setShowModal(false);
          }}
        >
          <div className="cp-option-modal">
            <div className="organizeHorizontally-col">
              <div className={"sipco-option-modal"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Cliente" />
                </label>
                <Dropdown
                  value={clienteSelectedObject?.descripcion}
                  placeholder={clienteSelectedObject?.descripcion}
                  options={listClientes}
                  onChange={(e) => {
                    setClienteSelectedObject(e.value);
                    findConcesionarios(e.value?.id);
                  }}
                  optionLabel="descripcion"
                  itemTemplate={(option) =>
                    `${option.descripcion} (${option.id})`
                  }
                  className="w-full md:w-30rem"
                  loading={listClientes.length > 0 ? false : true}
                  filter
                  key={"id"}
                  disabled={
                    showModal === "Mantener Usuarios concesionarios - Modificar"
                  }
                />
              </div>

              <div className={"sipco-option-modal"}>
                <label>
                  <LanguageProvider id={"TODO"} alt="Concesionario" />
                </label>
                <Dropdown
                  value={concesionarioSelectedObject?.id}
                  placeholder={concesionarioSelectedObject?.descripcion}
                  options={listConcesionarios}
                  optionLabel="descripcion"
                  onChange={(e) => {
                    findDirdelivery(e.value.id);
                    setConcesionarioSelectedObject(e.value);
                  }}
                  itemTemplate={(option) =>
                    `${option.descripcion} (${option.id})`
                  }
                  className="w-full md:w-30rem"
                  loading={loadingConc}
                  filter
                  key={"id"}
                  disabled={
                    showModal === "Mantener Usuarios concesionarios - Modificar"
                  }
                />
              </div>
            </div>

            <div className={"org-option-col orgHori"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Activo" />
              </label>
              <Checkbox
                checked={activoChecked}
                onClick={() => {
                  setActivoChecked(!activoChecked);
                }}
              ></Checkbox>
            </div>

            <div className={"sipco-option-modal-widMax"}>
              <div className="m-0" style={{ padding: "1rem", width: "100%" }}>
                <DataTable
                  tableStyle={{ width: "100%" }}
                  value={listDirDeliveryObject}
                  selectionMode="multiple"
                  selection={dirDeliverySelecteds}
                  metaKeySelection={false}
                  onSelectionChange={(e) => {
                    setDirDeliverySelecteds(e.value);
                  }}
                  dataKey={"id"}
                  loading={loadingDirDelivery}
                >
                  <Column
                    field="descripcion"
                    header={
                      <LanguageProvider
                        id={"TODO"}
                        alt="Dirección de Entrega"
                      />
                    }
                    body={(rowData) => (
                      <>
                        {`(${rowData?.id})`} - {rowData?.direccion}{" "}
                      </>
                    )}
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default GeralConcesionarios;
