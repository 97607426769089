import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { TipoDanio } from "src/models/TipoDanio";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerTipoDanio = () => {
  const SCREEN_CODE = "abm0005_mantener_tipodanios";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: TipoDanio, filterValues: any) {
    try {
      const tipoDanio = {
        ...model,
        pais: filterValues?.securityValues?.country,
        cliente: filterValues?.securityValues?.client,
      };
      await sipcoAxiosService.post("/mantener-tipo-danio/save", {
        tipoDeDanio: tipoDanio,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: TipoDanio) {
    try {
      await sipcoAxiosService.post("/mantener-tipo-danio/update", {
        tipoDeDanio: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: TipoDanio) {
    try {
      await sipcoAxiosService.post("/mantener-tipo-danio/remove", {
        tipoDeDanio: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<TipoDanio[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-tipo-danio/findBy",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<TipoDanio> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-tipo-danio/findByPk",
        { tipoDeDanio: model }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <GenericAbm<TipoDanio>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="TODO" alt="Mantener Tipos de Daños" />}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: null,
        descripcion: "",
        bloquea: false,
        cliente: null,
        codExternoObt: null,
        estadisticas: false,
        pais: null,
        activo: true,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Codigo" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="3756" alt="Descripcion" />,
        },

        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.timestamp",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modifcicacion" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
      ]}
      formSettings={[
        {
          field: "id",
          required: true,
          label: <LanguageProvider id="6590" alt="Codigo" />,
          type: "inputText",
          length: 2,
        },
        {
          field: "descripcion",
          required: true,
          label: <LanguageProvider id="31" alt="Descripcion" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "bloquea",
          label: <LanguageProvider id="TODO" alt="Parte Daño" />,
          type: "checkbox",
        },
        {
          field: "estadisticas",
          label: <LanguageProvider id="TODO" alt="Estadística" />,
          type: "checkbox",
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      securityFilterProps={{
        securityOptions: {
          country: true,
          client: true,
          screenCode: SCREEN_CODE,
        },
      }}
    />
  );
};

export default MantenerTipoDanio;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
