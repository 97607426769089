import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import React, { useState } from "react";
import { useFilterContext } from "../../src/protected-routes";
import { Filter } from "@shared/components/filter/filter";

function searchJsonKeys(json: any, searchValue: string, path = ""): string[] {
  let keysFound: string[] = [];

  if (Array.isArray(json)) {
    json.forEach((item, index) => {
      const newPath = `${path}[${index}]`;
      const result = searchJsonKeys(item, searchValue, newPath);
      keysFound = keysFound.concat(result);
    });
  } else if (typeof json === "object" && json !== null) {
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        const newPath = path ? `${path}.${key}` : key;

        if (typeof json[key] === "object" && json[key] !== null) {
          const result = searchJsonKeys(json[key], searchValue, newPath);
          keysFound = keysFound.concat(result);
        } else if (json[key] === searchValue) {
          keysFound.push(newPath);
        }
      }
    }
  }

  return keysFound;
}

// Função para criar a descrição do caminho com base nos trechos
function buildDescriptionPath(jsonData: any, idPath: string): string {
  // Divide o idPath em partes
  const keys = idPath.split(".");
  let current = jsonData;
  let path = "";

  // Percorre cada parte do caminho
  for (let key of keys) {
    // Verifica se a chave contém um índice de array (ex: "funciones[0]")
    const arrayMatch = key.match(/^(.+)\[(\d+)\]$/);

    if (arrayMatch) {
      // Se for um índice de array, usamos a chave do objeto e o índice
      const objectKey = arrayMatch[1]; // Ex: "funciones"
      const index = parseInt(arrayMatch[2], 10); // Ex: 0

      // Navega até o objeto e acessa o array pelo índice
      current = current[objectKey][index];
    } else {
      // Caso contrário, simplesmente acessa a chave do objeto
      current = current[key];
    }

    // Adiciona a descrição à cadeia de caminho
    if (current && current.descripcion) {
      path += current.descripcion + " > ";
    }
  }

  // Remove o último " > " extra
  return path.slice(0, -3);
}

const SearchMenu: React.FC = () => {
  
  const [searchTerm, setSearchTerm] = useState("");
  const [resultPath, setResultPath] = useState("");
  const [resultDescription, setResultDescription] = useState("");

  const sipcoAxiosService = useSipcoAxiosService();
  //   const { values: securityFilters } = useFilterContext();
  async function loadJsonMenu() {
    try {
      //   if (securityFilters.securityValues?.country) {
      const { status, data } = await sipcoAxiosService.get(
        "/application/get-menu"
      );
      if (status === 200) {
        return data;
      }
      //   }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  const handleSearch = async () => {
    // Passo 1: Encontrar o caminho para o id
    const jsonData = await loadJsonMenu();
    const pathForId = searchJsonKeys(jsonData, searchTerm);

    if (pathForId.length > 0) {
      const idPath = pathForId[0]; // Selecionando o primeiro caminho encontrado
      setResultPath(idPath);

      // Passo 2: Criar o caminho com os trechos e as descrições
      const descriptionChain = buildDescriptionPath(jsonData, idPath);
      setResultDescription(descriptionChain); // Aqui estamos chamando a função buildDescriptionPath para montar a descrição
    }
  };

  return (
    <div>
      <h2>Buscar Caminho no medo pelo code</h2>      
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Digite um valor..."
      />
      <button onClick={handleSearch}>Buscar</button>

      <h3>Resultado:</h3>
      {resultPath ? (
        <>
          <p>
            <strong>Caminho do ID:</strong> {resultPath}
          </p>
          <p>
            <strong>Caminho com Descrições:</strong> {resultDescription}
          </p>
        </>
      ) : (
        <p>Nenhuma chave encontrada.</p>
      )}
    </div>
  );
};

export default SearchMenu;
