import { Ciudad } from "src/models/Ciudad";
import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { Provincia } from "src/models/Provincia";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerProvincias = () => {
  const SCREEN_CODE = "abm0002_mantener_provincias";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: Ciudad, filterValues: any) {
    try {
      const provincia = { ...model, pais: filterValues.pais };
      await sipcoAxiosService.post("/mantener-provincias/save", provincia);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: Ciudad) {
    try {
      await sipcoAxiosService.post("/mantener-provincias/update", model);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Ciudad) {
    try {
      await sipcoAxiosService.post("/mantener-provincias/remove", model);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Ciudad[]> {
    try {
      if (filter?.pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-provincias/findBy",
          filter.pais
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: Ciudad): Promise<Ciudad> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-provincias/findByPk",
        model
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadPaises(): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantener-provincias/filter/pais"
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadRegion(filter: any): Promise<any[]> {
    try {
      if (filter?.pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-provincias/filter/regiones",
          filter.pais
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<Provincia>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="150" alt="Mantener Provincias" />}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: 0,
        alicuotaIcms: null,
        codigoUf: null,
        codRentas: null,
        idIngresosBrutos: null,
        pais: null,
        region: null,
      }}
      filterSettings={[
        {
          filter: "pais",
          label: LanguageProvider({ id: "23960", alt: "País" }),
          placeholder: LanguageProvider({ id: "23960", alt: "País" }),
          type: "dropdown",
          dataSource: loadPaises,
          dependency: null,
          required: true,
        },
      ]}
      loadDropdownData={{ region: loadRegion }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Codigo" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="31" alt="Descripcion" />,
        },
        {
          field: "region.descripcion",
          header: <LanguageProvider id="10846" alt="Región" />,
        },
        {
          field: "idIngresosBrutos",
          header: <LanguageProvider id="16233" alt="Ing.Brutos" />,
        },
        {
          field: "codigoUf",
          header: <LanguageProvider id="16234" alt="Código UF" />,
        },
        {
          field: "alicuotaIcms",
          header: <LanguageProvider id="10845" alt="Alícuota ICMS" />,
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.timestamp",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
      ]}
      formSettings={[
        {
          field: "id",
          required: true,
          label: <LanguageProvider id="6590" alt="Codigo" />,
          type: "inputText",
          disabled: true,
        },
        {
          field: "descripcion",
          required: true,
          label: <LanguageProvider id="31" alt="Descripcion" />,
          type: "textArea",
          length: 50,
        },
        {
          field: "region",
          label: <LanguageProvider id="10846" alt="Región" />,
          type: "dropdown",
          dataSource: (filter: any) => loadRegion(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dropDownPlaceholder: LanguageProvider({ id: "10846", alt: "Región" }),
        },
        {
          field: "idIngresosBrutos",
          required: true,
          label: <LanguageProvider id="16233" alt="Ing.Brutos" />,
          type: "inputNumber",
          max: 999,
          min: 0,
        },
        {
          field: "alicuotaIcms",
          label: <LanguageProvider id="10845" alt="Alícuota ICMS" />,
          type: "inputNumber",
          max: 999,
          min: 0,
        },
        {
          field: "codigoUf",
          label: <LanguageProvider id="16234" alt="Código UF" />,
          type: "inputText",
          length: 2,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
    />
  );
};

export default MantenerProvincias;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
