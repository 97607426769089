import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { GrupoOperacion } from "src/models/GrupoOperacion";

const MantenerGrupoOperaciones = () => {
  const SCREEN_CODE = "abm0185_mantener_grupo_operaciones";
  const URL_BASE = "/mantener-grupo-operaciones";
  const SCREEN_TITLE = (
    <LanguageProvider id="22213" alt="Viaje fijo por boca de entrega" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: GrupoOperacion, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const grupoOperacion = {
          ...model,
          pais: filterValues.securityValues.country,
          // cliente: filterValues.securityValues.client,
          // cuenta: filterValues.securityValues.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          grupoOperacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: GrupoOperacion, filterValues: any) {
    try {
      const grupoOperacion = {
        ...model,
        pais: filterValues.securityValues.country,
        // cliente: filterValues.securityValues.client,
        // cuenta: filterValues.securityValues.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        grupoOperacion,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: GrupoOperacion, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        grupoOperacion: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<GrupoOperacion[]> {
    if (filter?.securityValues?.country) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            pais: filter.securityValues.country,
            // cliente: filter.securityValues.client,
            // cuenta: filter.securityValues.account,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<GrupoOperacion> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          grupoOperacion: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<GrupoOperacion>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            // client: true,
            // account: true,
          },
        }}
        // filterSettings={}
        dataTableColumns={[
          {
            field: "id",
            header: <LanguageProvider id="14104" alt="x" />,
          },
          {
            field: "descripcion",
            header: <LanguageProvider id="11833" alt="x" />,
          },
          {
            field: "finalizaOperacion",
            header: <LanguageProvider id="15086" alt="x" />,
            body: (rowData: any) => (rowData.finalizaOperacion ? "SI" : "NO"),
          },
          {
            field: "impresionAutomaticaColecta",
            header: <LanguageProvider id="18587" alt="x" />,
            body: (rowData: any) =>
              rowData.impresionAutomaticaColecta ? "SI" : "NO",
          },
          {
            field: "impresionGuia",
            header: <LanguageProvider id="18588" alt="x" />,
            body: (rowData: any) => (rowData.impresionGuia ? "SI" : "NO"),
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          finalizaOperacion: false,
          impresionAutomaticaColecta: false,
          impresionGuia: false,
        }}
        formSettings={[
          {
            field: "id",
            label: LanguageProvider({ id: "14104", alt: "x" }),
            type: "inputNumber",            
            required: true,
            disabled: true,

          },
          {
            field: "descripcion",
            label: LanguageProvider({ id: "11833", alt: "x" }),
            type: "inputText",
            length: 50,
            required: true,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
          {
            field: "finalizaOperacion",
            label: LanguageProvider({ id: "15086", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "impresionAutomaticaColecta",
            label: LanguageProvider({ id: "18587", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "impresionGuia",
            label: LanguageProvider({ id: "18588", alt: "x" }),
            type: "checkbox",
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default MantenerGrupoOperaciones;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionTemplateDireccion = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.direccion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
