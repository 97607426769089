import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useState } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { RangosSaneamiento } from "src/models/RangosSaneamiento";

const MantenerRangosSaneamiento = () => {
  const SCREEN_CODE = "abm0111_rangos_saneamiento";
  const URL_BASE = "/rangos-saneamiento";
  const sipcoAxiosService = useSipcoAxiosService();
  const [mercado, setMercado] = useState(null);

  async function handleSave(model: RangosSaneamiento, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        await sipcoAxiosService.post(URL_BASE + "/save", {
          rangoSaneamiento: {
            ...model,
            pais: filterValues.securityValues.country,
            cliente: filterValues.securityValues.client,
            cuenta: filterValues.securityValues.account,
            color: parseInt(model.color.replace("#", ""), 16),
            mercado: mercado,
          },
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: RangosSaneamiento) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/update", {
        rangoSaneamiento: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: RangosSaneamiento) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        rangoSaneamiento: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<RangosSaneamiento[]> {
    try {
      setMercado(filter?.mercado);

      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter?.securityValues?.country,
          cliente: filter?.securityValues?.client,
          cuenta: filter?.securityValues?.account,
          mercado: filter?.mercado,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<RangosSaneamiento> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          rangoSaneamiento: model, // TRATAR REDUNDANCIA QUEBRANDO NO GENERICO DEVIDO A COLOR
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMercado(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/mercado",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<RangosSaneamiento>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="2530" alt="Rangos Saneamiento" />}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        cuenta: null,
        mercado: null,
        diasInicio: "",
        diasFin: "",
        color: "",
        bloquea: false,
        activo: true,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
        },
      }}
      loadDropdownData={{
        mercado: loadMercado,
      }}
      filterSettings={[
        {
          filter: "mercado",
          label: LanguageProvider({ id: "10774", alt: "Mercados" }),
          placeholder: LanguageProvider({ id: "10774", alt: "Mercados" }),
          type: "dropdown",
          dataSource: loadMercado,
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country"],
        },
      ]}
      dataTableColumns={[
        {
          field: "diasInicio",
          header: <LanguageProvider id="16262" alt="Días Inicio" />,
        },
        {
          field: "diasFin",
          header: <LanguageProvider id="16263" alt="Días Fin" />,
        },
        {
          field: "color",
          header: <LanguageProvider id="2140" alt="Color" />,
        },
        {
          field: "bloquea",
          header: <LanguageProvider id="16264" alt="Bloquea" />,
          body: (rowData: any) => {
            return rowData.bloquea ? "SI" : "NO";
          },
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
        {
          field: "secuencia",
          header: <LanguageProvider id="8854" alt="Secuencia" />,
        },
      ]}
      formSettings={[
        {
          field: "diasInicio",
          label: <LanguageProvider id="16262" alt="Días Inicio" />,
          type: "inputNumber",
          min: 0,
          max: 9999,
          required: true,
        },
        {
          field: "diasFin",
          label: <LanguageProvider id="16262" alt="Días Fin" />,
          type: "inputNumber",
          min: 0,
          max: 9999,
          required: true,
        },
        {
          field: "color",
          label: <LanguageProvider id="2140" alt="Color" />,
          type: "colorpicker",
          required: true,
        },
        {
          field: "bloquea",
          label: <LanguageProvider id="16264" alt="Bloquea" />,
          type: "checkbox",
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
    />
  );
};

export default MantenerRangosSaneamiento;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
