import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { ServicioExtra } from "src/models/ServicioExtra";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerServiciosExtra = () => {
  const SCREEN_CODE = "abm0248_mantener_servicios_extra";
  const URL_BASE = "/mantener-servicios-extra";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ServicioExtra, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const servicioExtra = {
          ...model,
          pais: filterValues?.securityValues?.country,
          cuenta: filterValues?.securityValues?.account,
          codPre: model.codPre,
          gastoExtra: model.tipoGasto === "gastoExtra",
          gastoTransporte: model.tipoGasto === "gastoTransporte",
          activo: model.activo,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          servicioExtra,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ServicioExtra, filterValues: any) {
    try {
      const servicioExtra = {
        ...model,
        pais: filterValues?.securityValues?.country,
        cuenta: filterValues?.securityValues?.account,
        codPre: model.codPre,
        gastoExtra: model.gastoExtra,
        gastoTransporte: model.gastoTransporte,
        activo: model.activo,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        servicioExtra,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: ServicioExtra, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        servicioExtra: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ServicioExtra[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cuenta: filter.securityValues.account,
          subcuenta: filter.securityValues.subaccount,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<ServicioExtra> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          servicioExtra: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <GenericAbm<ServicioExtra>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          account: true,
        },
      }}
      dataTableColumns={[
        {
          field: "id",
          header: <LanguageProvider id="6590" alt="Código" />,
        },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="Descripcion" />,
        },
        {
          field: "codPre",
          header: <LanguageProvider id="22724" alt="Prestacion" />,
        },
        {
          field: "gastoExtra",
          header: <LanguageProvider id="23269" alt="Gasto Extra" />,
          body: (rowData: any) => {
            return rowData.gastoExtra ? "SI" : "NO";
          },
        },
        {
          field: "gastoTransporte",
          header: <LanguageProvider id="23270" alt="Gasto Transporte" />,
          body: (rowData: any) => {
            return rowData.gastoTransporte ? "SI" : "NO";
          },
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="2131" alt="Fecha" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
        cuenta: null,
        codPre: "",
        tipoGasto: "gastoExtra",
        gastoExtra: false,
        gastoTransporte: false,
        descripcion: "",
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          disabled: true,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripcion" />,
          type: "inputText",
        },
        {
          field: "codPre",
          label: <LanguageProvider id="22724" alt="Prestacion" />,
          type: "inputText",
        },
        {
          field: "tipoGasto",
          label: <LanguageProvider id="TODO" alt="Tipo de Gasto" />,
          type: "radiobutton",
          radioButtonOptions: [
            {
              label: <LanguageProvider id="23269" alt="Gasto Extra" />,
              value: "gastoExtra",
            },
            {
              label: <LanguageProvider id="23270" alt="Gasto Transporte" />,
              value: "gastoTransporte",
            },
          ],
        },
        {
          field: "Activo",
          label: <LanguageProvider id="23849" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerServiciosExtra;
