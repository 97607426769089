import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { UbicacionesFamilia } from "src/models/UbicacionesFamilia";

const MantenerUbicacionesFamilia = () => {
  const SCREEN_CODE = "abm0125_mantener_ubicaciones_familia";
  const URL_BASE = "/mantener-ubicaciones-familia";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: UbicacionesFamilia, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        await sipcoAxiosService.post(URL_BASE + "/save", {
          ubicacionFamilia: {
            ...model,
            pais: filterValues.securityValues.country,
            cliente: filterValues.securityValues.client,
            cuenta: filterValues.securityValues.account,
            subcuenta: filterValues.securityValues.subaccount,
          },
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: UbicacionesFamilia) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/update", {
        ubicacionFamilia: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: UbicacionesFamilia) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        ubicacionFamilia: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<UbicacionesFamilia[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter?.securityValues?.country,
          cliente: filter?.securityValues?.client,
          cuenta: filter?.securityValues?.account,
          subcuenta: filter?.securityValues?.subaccount,
          marca: filter?.marca,
          familia: filter?.familia,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<UbicacionesFamilia> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          ubicacionFamilia: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarca(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/marcas",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadMarcaI(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/marcas-all",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadFamilia(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/familia",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            marca: filter?.marca,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadCalle(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.client &&
        filter?.securityValues?.account &&
        filter?.securityValues?.subaccount
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/calles",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadColumna(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.client &&
        filter?.securityValues?.account &&
        filter?.securityValues?.subaccount &&
        filter?.calle
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/columna",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            calle: filter?.calle,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadNivel(filter: any): Promise<any[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.client &&
        filter?.securityValues?.account &&
        filter?.securityValues?.subaccount &&
        filter?.calle &&
        filter?.columna
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/nivel",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
            calle: filter?.calle,
            columna: filter?.columna,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<UbicacionesFamilia>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="10625" alt="Mantener Ubicaciones Familia" />}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        cuenta: null,
        subcuenta: null,
        marca: null,
        familia: null,
        calle: null,
        columna: null,
        nivel: false,
        activo: true,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
          subaccount: true,
        },
      }}
      filterSettings={[
        {
          filter: "marca",
          label: LanguageProvider({ id: "23888", alt: "Marca" }),
          placeholder: LanguageProvider({ id: "23888", alt: "Marca" }),
          type: "dropdown",
          dataSource: loadMarca,
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
        },
        {
          filter: "familia",
          label: LanguageProvider({ id: "10034", alt: "Familia" }),
          placeholder: LanguageProvider({
            id: "10034",
            alt: "Familia",
          }),
          type: "dropdown",
          dataSource: loadFamilia,
          dropDownTemplate: DropdownOptionTemplate,
          dependency: [
            "securityValues.country",
            "securityValues.client",
            "marca",
          ],
        },
      ]}
      dataTableColumns={[
        {
          field: "marca.descripcion",
          header: <LanguageProvider id="396" alt="Marca" />,
        },
        {
          field: "familia.descripcion",
          header: <LanguageProvider id="10034" alt="Familia" />,
        },
        {
          field: "calle",
          header: <LanguageProvider id="990" alt="Calle" />,
        },
        {
          field: "columna",
          header: <LanguageProvider id="991" alt="Columna" />,
        },
        {
          field: "nivel",
          header: <LanguageProvider id="992" alt="Nivel" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      formSettings={[
        {
          field: "marca",
          label: <LanguageProvider id="396" alt="Marca" />,
          dropDownPlaceholder: LanguageProvider({
            id: "396",
            alt: "Marca",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadMarcaI(filter),
          dropDownTemplate: DropdownInactivoTemplate,
          dependency: ["securityValues.country", "securityValues.client"],
          required: true,
          disableOnUpdate: true,
        },
        {
          field: "familia",
          label: <LanguageProvider id="10034" alt="Familia" />,
          dropDownPlaceholder: LanguageProvider({
            id: "10034",
            alt: "Familia",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadFamilia(filter),
          dropDownTemplate: DropdownInactivoTemplate,
          dependency: [
            "securityValues.country",
            "securityValues.client",
            "marca",
          ],
          required: true,
          disableOnUpdate: true,
        },
        {
          field: "calle",
          label: <LanguageProvider id="990" alt="Calle" />,
          dropDownPlaceholder: LanguageProvider({
            id: "990",
            alt: "Calle",
          }),
          type: "dropdown",
          dataSource: loadCalle,
          dependency: [
            "securityValues.country",
            "securityValues.client",
            "securityValues.account",
            "securityValues.subaccount",
          ],
          required: true,
          disableOnUpdate: true,
        },
        {
          field: "columna",
          label: <LanguageProvider id="991" alt="Columna" />,
          dropDownPlaceholder: LanguageProvider({
            id: "991",
            alt: "Columna",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadColumna(filter),
          dependency: [
            "securityValues.country",
            "securityValues.client",
            "securityValues.account",
            "securityValues.subaccount",
            "calle",
          ],
          required: true,
          disableOnUpdate: true,
        },
        {
          field: "nivel",
          label: <LanguageProvider id="992" alt="Nivel" />,
          dropDownPlaceholder: LanguageProvider({
            id: "992",
            alt: "Nivel",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadNivel(filter),
          dependency: [
            "securityValues.country",
            "securityValues.client",
            "securityValues.account",
            "securityValues.subaccount",
            "calle",
            "columna",
          ],
          required: true,
          disableOnUpdate: true,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
    />
  );
};

export default MantenerUbicacionesFamilia;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};

const DropdownInactivoTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div
        className="flex align-items-center"
        style={{ color: option.activo ? "inherit" : "red" }}
      >
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props?.placeholder}</span>;
};
