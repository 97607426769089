export class Modelo {
  id: string;
  descripcion: string;
  pais: Object;
  cliente: Object;
  marca: Object;
  familia: Object;
  sourceUnit: string;
  familiaRadio: Object;
  codigoModeloContable: number;
  peso: number;
  categoriaEstado: Object;
  categoriaTranslado: Object;
  version: Object;
  cobraGancho: boolean;
  cobraLogo: boolean;
  cobraSticker: boolean;
  porcentaje: number;
  rotacion: number;
  anioModelo: number;
  idModeloFra: string;
  nivelRotacion: Object;
  modeloComercial: string;
  valor: number;
  piezas: Object[];
  combo: boolean;
  horasPreparacion: number;
  ncm: string;
  activo: boolean;

  constructor(init?: Partial<Modelo>) {
    Object.assign(this, init);
  }
}
