import GenericAbm, {
  GenericAbmRef,
} from "@shared/components/genericAbm/generic-abm";
import React, { useEffect, useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

import { Checkbox } from "primereact/checkbox";
import { LanguageProvider } from "@shared/components/language-provider";
import { Modelo } from "../../../models/Modelo";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputNumber from "@shared/components/sipco-input-number";
import SipcoInputText from "@shared/components/sipco-input-text";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { useGenericAbm } from "@shared/GenericAbmContext";
import ModalMantenerModelos from "./components/ModalMantenerModelos";
import { Button } from "primereact/button";
import "./MantenerModelos.scss";

const MantenerModelos = () => {
  const SCREEN_CODE = "abm0027_mantener_modelos";
  const URL_BASE = "/mantener-modelos";
  const sipcoAxiosService = useSipcoAxiosService();

  const { genericAbmStatesContextData } = useGenericAbm<any>();

  async function handleSave(model: Modelo, filterValues: any) {
    try {
      // TODO
      //   if (filterValues?.securityValues?.country) {
      //     const modelo = {
      //       ...model,
      //       pais: filterValues.securityValues.country,
      //       cliente: filterValues.securityValues.client,
      //     };
      //     await sipcoAxiosService.post(URL_BASE + "/save", {
      //       modelo,
      //     });
      //     return true;
      //   }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: Modelo, filterValues: any) {
    try {
      //TODO
      //   const modelo = {
      //     ...model,
      //     pais: filterValues.securityValues.country,
      //     cliente: filterValues.securityValues.client,
      //     ma
      //   };
      //   await sipcoAxiosService.post(URL_BASE + "/update", {
      //     modelo,
      //   });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Modelo, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        modelo: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Modelo[]> {
    try {
      if (
        filter?.securityValues?.country &&
        filter?.securityValues?.client &&
        filter?.securityValues?.account &&
        filter?.marca
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter.securityValues.account,
            marca: filter.marca,
            descripcionModelo: filter.descripcionModelo,
          }
        );
        if (status === 200) return data;
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<Modelo> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          modelo: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }
  async function loadMarca(filter: any) {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/marca",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const abmRef = useRef<GenericAbmRef<Modelo>>();

  const [displayCreateDialog, setDisplayCreateDialog] = useState(false);
  const [model, setModel] = useState<Modelo>(new Modelo());

  return (
    <div className="mantener-modelos">
      <GenericAbm<Modelo>
        ref={abmRef}
        screenCode={SCREEN_CODE}
        title={<LanguageProvider id="246" alt="Mantener Modelos" />}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
          },
        }}
        filterSettings={[
          {
            filter: "marca",
            label: <LanguageProvider id="396" alt="Marca" />,
            placeholder: LanguageProvider({ id: "396", alt: "Marca" }),
            dataSource: loadMarca,
            type: "dropdown",
            dependency: ["securityValues.country", "securityValues.client"],
            dropDownTemplate: DropdownOptionTemplateDescripcionId,
          },
          {
            filter: "descripcionModelo",
            label: <LanguageProvider id="2139" alt="Modelo" />,
            type: "inputText",
          },
        ]}
        dataTableColumns={[
          {
            field: "id",
            header: <LanguageProvider id="13514" alt="Código Modelo" />,
          },
          {
            field: "descripcion",
            header: <LanguageProvider id="2139" alt="Modelo" />,
          },
          {
            field: "familia.descripcion",
            header: <LanguageProvider id="10034" alt="Familia" />,
          },
          {
            field: "sourceUnit",
            header: <LanguageProvider id="24849" alt="Source Unit" />,
          },
          {
            field: "familiaRadio.descripcion",
            header: <LanguageProvider id="16437" alt="Familias de Radios" />,
          },
          {
            field: "codigoModeloContable.descripcion",
            header: (
              <LanguageProvider id="16438" alt="Contable Prest. SEGINT_02" />
            ),
          },
          {
            field: "peso",
            header: <LanguageProvider id="16439" alt="Peso en (Kgr.)" />,
          },
          {
            field: "categoriaEstadia.descripcion",
            header: <LanguageProvider id="16440" alt="Categoría Estadia" />,
          },
          {
            field: "categoriaTraslado.descripcion",
            header: <LanguageProvider id="15538" alt="Categoría Traslado" />,
          },
          {
            field: "version.descripcion",
            header: <LanguageProvider id="4169" alt="Versión" />,
          },
          {
            field: "cobraLogo",
            header: <LanguageProvider id="16360" alt="Cobra logo" />,
            body: (rowData: any) => (rowData.cobraLogo ? "SI" : "NO"),
          },
          {
            field: "cobraGancho",
            header: <LanguageProvider id="16358" alt="Cobra gancho" />,
            body: (rowData: any) => (rowData.cobraGancho ? "SI" : "NO"),
          },
          {
            field: "cobraSticker",
            header: <LanguageProvider id="16361" alt="Cobra sticker" />,
            body: (rowData: any) => (rowData.cobraSticker ? "SI" : "NO"),
          },
          {
            field: "idModeloFra",
            header: <LanguageProvider id="11497" alt="Modelo Francia" />,
          },
          {
            field: "valor",
            header: <LanguageProvider id="4675" alt="Valor" />,
          },
          {
            field: "combo",
            header: <LanguageProvider id="22210" alt="Combo" />,
            body: (rowData: any) => (rowData.combo ? "SI" : "NO"),
          },
          {
            field: "horasPreparacion",
            header: <LanguageProvider id="23261" alt="Horas Preparación" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        list={handleList}
        get={handleGet}
        remove={handleRemove}
        customActions={
          <>
            <Button
              label={LanguageProvider({ id: "19267", alt: "Modificar" })}
              onClick={() => setDisplayCreateDialog(true)}
              icon="pi pi-pencil"
              iconPos="right"
              raised
              tooltip={LanguageProvider({ id: "19267", alt: "Modificar" })}
              tooltipOptions={{ position: "bottom" }}
            />

            <Button
              label={LanguageProvider({ id: "25", alt: "Alta" })}
              onClick={() => setDisplayCreateDialog(true)}
              icon="pi pi-plus"
              iconPos="right"
              raised
              tooltip={LanguageProvider({ id: "25", alt: "Alta" })}
              tooltipOptions={{ position: "bottom" }}
            />
          </>
        }
        defaultValues={{
          activo: null,
          anioModelo: null,
          categoriaEstado: null,
          categoriaTranslado: null,
          cliente: null,
          cobraGancho: null,
          cobraLogo: null,
          cobraSticker: null,
          codigoModeloContable: null,
          combo: null,
          descripcion: null,
          familia: null,
          familiaRadio: null,
          horasPreparacion: null,
          id: null,
          idModeloFra: null,
          marca: null,
          modeloComercial: null,
          ncm: null,
          nivelRotacion: null,
          pais: null,
          peso: null,
          piezas: null,
          porcentaje: null,
          rotacion: null,
          sourceUnit: null,
          valor: null,
          version: null,
        }}
      />
      <ModalMantenerModelos
        visible={displayCreateDialog}
        onHide={() => {
          setDisplayCreateDialog(false);
        }}
        model={model}
      />
    </div>
  );
};

export default MantenerModelos;

const DropdownOptionTemplateDescripcionId = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
