import GenericAbm, {
  GenericAbmRef,
} from "@shared/components/genericAbm/generic-abm";
import React, { useRef } from "react";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ContableOrigenPlaya } from "src/models/ContableOrigenPlaya";

const MantenerContableOrigenesPlayas = () => {
  const SCREEN_CODE = "abm0079_mantener_contable_origenes_playas";
  const URL_BASE = "/mantener-contable-origenes-playas";
  const SCREEN_TITLE = (
    <LanguageProvider id="1310" alt="Mantener Contable Origenes" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const empityFilter = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - 
  ${LanguageProvider({ id: "23888", alt: "Marca" })} `;

  const abmRef = useRef<GenericAbmRef<ContableOrigenPlaya>>(null);

  async function handleSave(model: ContableOrigenPlaya, filterValues: any) {
    if (!filterValues?.marca) {
      toast.current?.show({
        severity: "warn",
        detail: empityFilter,
        life: 3000,
      });
      return false;
    }
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues.securityValues.client &&
        filterValues?.securityValues?.account
      ) {
        const contableOrigenPlaya = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues?.securityValues?.account,
          marca: filterValues?.marca,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          contableOrigenPlaya,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ContableOrigenPlaya, filterValues: any) {
    try {
      const contableOrigenPlaya = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        marca: filterValues?.marca,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        contableOrigenPlaya,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: ContableOrigenPlaya, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        contableOrigenPlaya: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ContableOrigenPlaya[]> {
    if (!filter?.marca) {
      toast.current?.show({
        severity: "warn",
        detail: empityFilter,
        life: 3000,
      });
      return [];
    }

    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.account &&
      filter?.marca
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            marca: filter?.marca,
          }
        );
        if (status === 200)
          if (data.length > 0) {
            return data;
          } else {
            return [];
          }
      } catch (error) {
        console.error(error);
      }
    }

    return [];
  }
  async function handleGet(model: any): Promise<ContableOrigenPlaya> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          contableOrigenPlaya: { ...model },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarca(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country && filter?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/marca",
          {
            pais: filter?.securityValues?.country,
            cliente: filter?.securityValues?.client,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ContableOrigenPlaya>
        ref={abmRef}
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
          },
        }}
        filterSettings={[
          {
            filter: "marca",
            label: LanguageProvider({ id: "23888", alt: "Marca" }),
            placeholder: LanguageProvider({ id: "23888", alt: "Marca" }),
            type: "dropdown",
            dataSource: loadMarca,
            dropDownTemplate: DropdownOptionTemplate,
            dependency: ["securityValues.country", "securityValues.client"],
            required: true,
          },
        ]}
        dataTableColumns={[
          {
            field: "codigoOrigenContable",
            header: <LanguageProvider id="6111" alt="Año" />,
          },

          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          activo: true,
          codigoOrigenContable: "",
        }}
        formSettings={[
          {
            field: "codigoOrigenContable",
            label: LanguageProvider({ id: "12419", alt: "Descripcion" }),
            type: "inputText",
            length: 50,
          },
        ]}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default MantenerContableOrigenesPlayas;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
