import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useRef } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { TransaccionCheckpoint } from "src/models/TransaccionCheckpoint";

const MantenerCheckpointTransaccion = () => {
  const SCREEN_CODE = "abm0064_mantener_checkpoint_transaccion";
  const URL_BASE = "/mantener-checkpoint-transaccion";
  const SCREEN_TITLE = (
    <LanguageProvider id="678" alt="Mantener Checkpoint Transaccion" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: TransaccionCheckpoint, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const transaccionCheckpoint = {
          ...model,
          pais: filterValues.securityValues.country,
          transaccion: filterValues.transaccion,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          transaccionCheckpoint,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: TransaccionCheckpoint, filterValues: any) {
    try {
      const transaccionCheckpoint = {
        ...model,
        pais: filterValues.securityValues.country,
        transaccion: filterValues.transaccion,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        transaccionCheckpoint,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: TransaccionCheckpoint, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        transaccionCheckpoint: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<TransaccionCheckpoint[]> {
    if (filter?.securityValues?.country) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            transaccion: filter.transaccion,
          }
        );
        if (status === 200)
          if (data.length > 0) {
            return data;
          } else {
            return [];
          }
      } catch (error) {
        console.error(error);
      }
    }

    return [];
  }
  async function handleGet(model: any): Promise<TransaccionCheckpoint> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          transaccionCheckpoint: { ...model },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadTransaccion(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/transaccion",
          {
            pais: filter?.securityValues.country,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadCheckpoint(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/checkpoint",
          {
            pais: filter?.securityValues.country,
            cliente: filter?.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }

    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<TransaccionCheckpoint>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
          },
        }}
        filterSettings={[
          {
            filter: "transaccion",
            label: LanguageProvider({ id: "14288", alt: "Transacción" }),
            placeholder: LanguageProvider({ id: "14288", alt: "Transacción" }),
            type: "dropdown",
            dataSource: loadTransaccion,
            dropDownTemplate: DropdownOptionTemplate,
            dependency: ["securityValues.country"],
            required: true,
          },
        ]}
        dataTableColumns={[
          {
            field: "transaccion.descripcion",
            header: <LanguageProvider id="14288" alt="Transacción" />,
          },
          {
            field: "checkpoint.descripcion",
            header: <LanguageProvider id="11314" alt="Checkpoint" />,
          },
          {
            field: "transaccion.id",
            header: (
              <LanguageProvider id="16388" alt="Transacción (Formato ID)" />
            ),
          },
          {
            field: "checkpoint.id",
            header: (
              <LanguageProvider id="16389" alt="Checkpoint (Formato ID)" />
            ),
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          activo: true,
          pais: null,
          checkpoint: null,
          transaccion: null,
        }}
        formSettings={[
          {
            field: "checkpoint",
            label: LanguageProvider({ id: "256", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "256", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadCheckpoint(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default MantenerCheckpointTransaccion;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
