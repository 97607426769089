import GenericAbm from "@shared/components/genericAbm/generic-abm";
import React, { useRef } from "react";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Corredor } from "src/models/Corredor";

const MantenerCorredores = () => {
  const SCREEN_CODE = "abm0118_mantener_corredores";
  const URL_BASE = "/mantener-corredores";
  const SCREEN_TITLE = <LanguageProvider id="8467" alt="Mantener Corredores" />;
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: Corredor, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.account
      ) {
        const corredor = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues?.securityValues?.client,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          corredor,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: Corredor, filterValues: any) {
    try {
      const corredor = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues?.securityValues?.client,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        corredor,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Corredor, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        corredor: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Corredor[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.account) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter?.securityValues?.client,
            cuenta: filter?.securityValues?.account,
          }
        );
        if (status === 200)
          if (data.length > 0) {
            return data;
          } else {
            return [];
          }
      } catch (error) {
        console.error(error);
      }
    }

    return [];
  }
  async function handleGet(model: any): Promise<Corredor> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          corredor: { ...model },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<Corredor>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
          },
        }}
        // filterSettings={}
        dataTableColumns={[
          {
            field: "id",
            header: <LanguageProvider id="6590" alt="Código" />,
          },
          {
            field: "descripcion",
            header: <LanguageProvider id="12419" alt="Descripcion" />,
          },
          {
            field: "cliente.descripcion",
            header: <LanguageProvider id="54" alt="Cliente" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          activo: true,
          pais: null,
          cliente: null,
        }}
        formSettings={[
          {
            field: "id",
            label: LanguageProvider({ id: "6590", alt: "Código" }),
            type: "inputText",
            length: 10,
            disableOnUpdate: true,
          },
          {
            field: "descripcion",
            label: LanguageProvider({ id: "12419", alt: "Descripcion" }),
            type: "inputText",
            length: 200,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default MantenerCorredores;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
