import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useRef } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { ContableFuncionTipoContable } from "src/models/ContableFuncionTipoContable";

const MantenerFuncionesContables = () => {
  const SCREEN_CODE = "abm0071_mantener_funciones_contables";
  const URL_BASE = "/mantener-funciones-contables";
  const SCREEN_TITLE = (
    <LanguageProvider
      id="787"
      alt="Mantener Funciones Contables (Asignar Tipo)"
    />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const empityFilter = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - 
  ${LanguageProvider({ id: "5198", alt: "Sistemas" })} -  ${LanguageProvider({ id: "2788", alt: "Funciones" })}`;

  async function handleSave(
    model: ContableFuncionTipoContable,
    filterValues: any
  ) {
    if (!filterValues?.funcion || !filterValues?.sistema) {
      toast.current?.show({
        severity: "warn",
        detail: empityFilter,
        life: 3000,
      });
      return false;
    }
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client &&
        filterValues?.securityValues?.account &&
        filterValues?.securityValues?.subaccount
      ) {
        const contableFuncionTipoContable = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues?.securityValues?.account,
          subcuenta: filterValues?.securityValues?.subaccount,
          funcion: filterValues?.funcion,
          sistema: filterValues?.sistema,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          contableFuncionTipoContable,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(
    model: ContableFuncionTipoContable,
    filterValues: any
  ) {
    try {
      const contableFuncionTipoContable = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues?.securityValues?.account,
        subcuenta: filterValues?.securityValues?.subaccount,
        funcion: filterValues?.funcion,
        sistema: filterValues?.sistema,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        contableFuncionTipoContable,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(
    model: ContableFuncionTipoContable,
    filterValues: any
  ) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        contableFuncionTipoContable: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(
    filter: any
  ): Promise<ContableFuncionTipoContable[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            funcion: filter?.funcion,
            sistema: filter?.sistema,
          }
        );
        if (status === 200)
          if (data.length > 0) {
            return data;
          } else {
            return [];
          }
      } catch (error) {
        console.error(error);
      }
    }

    return [];
  }
  async function handleGet(model: any): Promise<ContableFuncionTipoContable> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          contableFuncionTipoContable: { ...model },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadSistema(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/sistema",
          {}
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadFuncion(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount &&
      filter?.sistema
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/funcion",
          {
            pais: filter?.securityValues.country,
            cliente: filter?.securityValues.client,
            cuenta: filter?.securityValues?.account,

            sistema: filter?.sistema,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ContableFuncionTipoContable>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
          },
        }}
        filterSettings={[
          {
            filter: "sistema",
            label: LanguageProvider({ id: "5198", alt: "Sistemas" }),
            placeholder: LanguageProvider({ id: "5198", alt: "Sistemas" }),
            type: "dropdown",
            dataSource: loadSistema,
            dropDownTemplate: DropdownOptionTemplate,
            required: true,
          },
          {
            filter: "funcion",
            label: LanguageProvider({ id: "2788", alt: "Funciones" }),
            placeholder: LanguageProvider({ id: "2788", alt: "Funciones" }),
            type: "dropdown",
            dataSource: loadFuncion,
            dropDownTemplate: DropdownOptionTemplate,
            dependency: ["sistema"],
            required: true,
          },
        ]}
        dataTableColumns={[
          {
            field: "codigoTipoContable",
            header: <LanguageProvider id="16372" alt="Código tipo contable" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          activo: true,
          cliente: null,
          cuenta: null,
          sistema: null,
          funcion: null,
          codigoTipoContable: 0,
        }}
        formSettings={[
          {
            field: "codigoTipoContable",
            label: LanguageProvider({
              id: "16372",
              alt: "Código tipo contable",
            }),
            type: "inputNumber",
            max: 9999,
            useGrouping: false,
            disableOnUpdate: true,
            required: true,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default MantenerFuncionesContables;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
