import "./ModalAltaOrdenReparacionState.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { HttpStatusCode } from "axios";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import SipcoInputTextArea from "@shared/components/sipco-input-text-area";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import { filter } from "jszip";

interface ModalAltaOrdenReparacionStateProps {
  visible: boolean;
  onClose: () => void;
  model?: any;
  onConfirm?: (updatedList: any[]) => void;
  values?: any;
}

const ModalAltaOrdenReparacionState: React.FC<ModalAltaOrdenReparacionStateProps> = ({
  visible,
  onClose,
  model,
  onConfirm,
  values,
}) => {
  const { values: securityFilters } = useFilterContext();

  const sipcoAxiosService = useSipcoAxiosService();
  usePageViews();
  const dt = useRef(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const initialFormState = {
    nroOrden: null,
    estado: null,
    nroIngresoFistLabel: null,
    nroIngresoSecondLabel: null,
    inspector: null,
    imputado: null,
    ubicacionActual: null,
    incidentes: null,
    fechaAlta: new Date(),
    fechaCierreReal: null,
    fechaFacturacion: null,
    fechaAnulacion: null,
    fechaCierreEstimada: new Date(),
    danos: null,
    clasificacion: null,
    tiempoEstimado: null,
    costoTotalEstimado: null,
    subcuenta: null,
    historicoTaller: null,
    taller: null,
    vin: null,
  };

  const [formModel, setFormModel] = useState(initialFormState);
  const hasReload = useRef(false);


  const data = convertDateObjects(reporte?.filas);

  const [clienteImputado, setClienteImputado] = useState([]);
  const [loadingClienteImputado, setLoadingClienteImputado] = useState(false);

  async function loadClienteImputado() {
    setLoadingClienteImputado(true);
    try {
      if (values.pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/cliente",
          {
            pais: values.pais,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setClienteImputado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClienteImputado(false);
    }
  }

  const [vinActive, setVinActive] = useState([]);
  const [loadingVinActive, setLoadingVinActive] = useState(false);
  const [vinOrdenReparacion, setVinOrdenReparacion] = useState([]);
  const [loadingOrdenReparacion, setLoadingOrdenReparacion] = useState(false);

  async function searchVin(event: any) {
    const vinOrdenReparacion = await loadVinOrdenRepoaracion(event.query);
    if (vinOrdenReparacion.length !== 0) {
      const [historicoTaller, vinActive] = await Promise.all([
        loadHistoricoTaller(event.query),
        loadVinActivo(event.query),
      ]);

      console.log(historicoTaller);


      if (vinActive.length !== 0) {
        setFormModel({
          ...formModel,
          nroIngresoFistLabel: historicoTaller?.numeroIngreso,
          nroIngresoSecondLabel: historicoTaller?.vin?.modelo?.descripcion,
        });
      }
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Error",
        life: 3000,
      })
    }
  }

  async function loadVinOrdenRepoaracion(query: any) {
    setLoadingOrdenReparacion(true);
    try {
      if (values.pais && values.cuenta && values.subcuenta && values.cliente) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/vin-orden-reparacion",
          {
            pais: values.pais,
            cliente: values.cliente,
            cuenta: values.cuenta,
            subcuenta: values.subcuenta,
            taller: values.taller,
            vin: {
              id: query,
            },
          }
        );
        if (status === HttpStatusCode.Ok) {
          setVinOrdenReparacion(data);
          return data;
        }
        else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: <LanguageProvider id="17344" alt="La operación no se pudo completar" />,
            life: 3000,
          })
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrdenReparacion(false);
    }
  }

  async function loadVinActivo(query: any) {
    setLoadingVinActive(true);
    try {
      if (values.pais && values.cuenta && values.subcuenta && values.cliente) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/vin-activo",
          {
            pais: values.pais,
            cliente: values.cliente,
            cuenta: values.cuenta,
            subcuenta: values.subcuenta,
            vin: {
              id: query,
            },
          }
        );
        if (status === HttpStatusCode.Ok) {
          setVinActive(data);
          return data;
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: <LanguageProvider id="17344" alt="La operación no se pudo completar" />,
            life: 3000,
          })
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVinActive(false);
    }
  }

  const [historicoTaller, setHistoricoTaller] = useState([]);
  const [loadingHistoricoTaller, setLoadingHistoricoTaller] = useState(false);

  async function loadHistoricoTaller(query: any) {
    setLoadingHistoricoTaller(true);
    try {
      if (values.subcuenta && values.pais && values.cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/historico-talle",
          {
            pais: values.pais,
            cliente: values.cliente,
            cuenta: values.cuenta,
            subcuenta: values.subcuenta,
            taller: values.taller,
            vin: {
              id: query,
            },
          }
        );
        if (status === HttpStatusCode.Ok) {
          setHistoricoTaller(data);
          return data;
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: <LanguageProvider id="17344" alt="La operación no se pudo completar" />,
            life: 3000,
          })
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHistoricoTaller(false);
    }
  }

  const [subcuenta, setSubcuenta] = useState([]);
  const [loadingSubcuenta, setLoadingSubcuenta] = useState(false);
  async function loadSubcuenta() {
    setLoadingSubcuenta(true);
    try {
      if (values.pais && values.cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/subcuenta",
          {
            cliente: values.cliente,
            cuenta: values.cuenta,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setSubcuenta(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSubcuenta(false);
    }
  }

  const [mecanicoInspector, setMecanicoInspector] = useState([]);
  const [loadingMecanicoInspector, setLoadingMecanicoInspector] = useState(false);

  async function loadMecanicoInspector() {
    setLoadingMecanicoInspector(true);
    try {
      if (values.pais && values.cuenta) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/mecanico",
          {
            pais: values.pais,
            cuenta: values.cuenta,
            subcuenta: formModel.subcuenta ?? null,
            taller: formModel.taller ?? null,
            ordenReparacion: values.ordereparacion ?? null,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setMecanicoInspector(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMecanicoInspector(false);
    }
  }

  const [taller, setTaller] = useState([]);
  const [loadingTaller, setLoadingTaller] = useState(false);

  async function loadTaller(subCuenta: any) {
    setLoadingTaller(true);
    try {
      if (values.pais && values.cuenta) {
        const { status, data } = await sipcoAxiosService.get(
          "/orden-reparacion/taller",
          {
            params: {
              codPais: values.pais.id,
              codSubcuenta: subCuenta.id,
              codCuenta: values.cuenta.id,
            }
          }
        );
        if (status === HttpStatusCode.Ok) {
          setTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
  }


  const [clasificacion, setClasificacion] = useState([]);
  const [loadingClasificacion, setLoadingClasificacion] = useState(false);

  async function loadClasificacion() {
    setLoadingClasificacion(true);
    try {
      if (values.cuenta && values.pais) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/clasificacion-or",
          {
            pais: values.pais,
            cuenta: values.cuenta,
            subcuenta: formModel.subcuenta ?? null,
            taller: formModel.taller ?? null,
            ordenReparacion: values.ordereparacion ?? null,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setClasificacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClasificacion(false);
    }
  }


  const [saveLabel, setSaveLabel] = useState([]);
  const [loadingSabeLabel, setLoadingSaveLabel] = useState(false);

  async function save() {
    setLoadingSaveLabel(true);
    try {
      if (values.pais && values.cuenta
        && values.subcuenta && values.cliente
        && values.taller) {

        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/save",
          {
            ordenReparacion: {
              inspector: formModel.inspector ?? null,
              pais: values.pais,
              cliente: values.cliente,
              cuenta: values.cuenta,
              subcuenta: values.subcuenta,
              taller: values.taller,
              fechaCierreReal: null,
              fechaCierreEstimada: new Date(formModel.fechaCierreEstimada).getTime() ?? null,
              fechaFacturacion: null,
              fechaAnulacion: null,
              fechaAprobacion: new Date().getTime(),
              fechaOrden: new Date(formModel.fechaAlta).getTime() ?? null,
              estado: {
                id: 1
              },
              observaciones: formModel.incidentes ?? null,
              numeroIngreso: formModel.nroIngresoFistLabel ?? null,
              vin: formModel.vin ?? null,
              imputadoA: formModel.imputado ?? null,
              tiempoAprox: formModel.tiempoEstimado ?? null,
              costoAprox: formModel.costoTotalEstimado ?? null,
              clasificacion: formModel.clasificacion ?? null,
            },
          }
        );
        if (status === HttpStatusCode.Ok) {
          setSaveLabel(data);
          onClose();
          toast.current.show({
            severity: "success",
            summary: "Éxito",
            detail: <LanguageProvider id="36" alt="Su transacción se realizó con éxito." />,
            life: 3000,
          })
          return data;
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error",
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSaveLabel(false);
    }
  }


  async function hendleTallerLoad(subCuenta: any) {
    const taller = await loadTaller(subCuenta);


    if (taller !== null) {
      setFormModel({
        ...formModel,
        taller: taller ?? null,
        subcuenta: subCuenta
      });
    }
  }


  const [acetarOrden, setAceptarOrden] = useState([]);
  const [loadingAceptarOrden, setLoadingAceptarOrden] = useState(false);
  async function aceptarOrden() {
    setLoadingAceptarOrden(true);
    try {
      if (values.pais && values.cuenta
        && values.subcuenta && values.cliente
        && values.taller) {

        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/update-subcuenta-taller",
          {
            ordenReparacion: {
              activo: true,
              inspector: formModel.inspector ?? null,
              pais: values.pais,
              cliente: values.cliente,
              id: values.ordeReparacion.id,
              cuenta: values.cuenta,
              subcuenta: formModel.subcuenta,
              taller: formModel.taller,
              fechaCierreReal: new Date(formModel.fechaCierreReal).getTime() ?? null,
              fechaCierreEstimada: new Date(formModel.fechaCierreEstimada).getTime() ?? null,
              fechaFacturacion: new Date(formModel.fechaFacturacion).getTime() ?? null,
              fechaAnulacion: new Date(formModel.fechaAnulacion).getTime() ?? null,
              fechaAprobacion: new Date().getTime(),
              fechaOrden: new Date(formModel.fechaAlta).getTime() ?? null,
              estado: values.ordeReparacion.estado ?? null,
              observaciones: formModel.incidentes ?? null,
              numeroIngreso: formModel.nroIngresoFistLabel ?? null,
              vin: values.ordeReparacion.vin ?? null,
              imputadoA: formModel.imputado ?? null,
              tiempoAprox: formModel.tiempoEstimado ?? null,
              costoAprox: formModel.costoTotalEstimado ?? null,
              clasificacion: formModel.clasificacion ?? null,
            },
          }
        );
        if (status === HttpStatusCode.Ok) {
          setAceptarOrden(data);
          onClose();
          toast.current?.show({
            severity: "success",
            summary: "Success",
            life: 3000,
          });
          return data;
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error",
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAceptarOrden(false);
    }
  }

  const [completarlabel, setCompletarLabel] = useState([]);
  const [loadingCompletar, setLoadingCompletar] = useState(false);
  async function completar() {
    setLoadingCompletar(true);
    try {
      if (values.pais && values.cuenta) {

        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/completar",
          {
            pais: values.pais,
            cuenta: values.cuenta,
            subcuenta: formModel.subcuenta ?? null,
            taller: formModel.taller ?? null,
            ordenReparacion: {
              activo: true,
              inspector: formModel.inspector ?? null,
              pais: values.pais,
              cliente: values.cliente,
              id: values.ordeReparacion.id,
              cuenta: values.cuenta,
              subcuenta: formModel.subcuenta,
              taller: formModel.taller,
              fechaCierreReal: new Date(formModel.fechaCierreReal).getTime() ?? null,
              fechaCierreEstimada: new Date(formModel.fechaCierreEstimada).getTime() ?? null,
              fechaFacturacion: new Date(formModel.fechaFacturacion).getTime() ?? null,
              fechaAnulacion: new Date(formModel.fechaAnulacion).getTime() ?? null,
              fechaAprobacion: new Date().getTime(),
              fechaOrden: new Date(formModel.fechaAlta).getTime() ?? null,
              estado: values.ordeReparacion.estado ?? null,
              observaciones: formModel.incidentes ?? null,
              numeroIngreso: formModel.nroIngresoFistLabel ?? null,
              vin: values.ordeReparacion.vin ?? null,
              imputadoA: formModel.imputado ?? null,
              tiempoAprox: formModel.tiempoEstimado ?? null,
              costoAprox: formModel.costoTotalEstimado ?? null,
              clasificacion: formModel.clasificacion ?? null,
            },
          }
        );
        if (status === HttpStatusCode.Ok) {
          setCompletarLabel(data);
          onClose();
          toast.current?.show({
            severity: "success",
            summary: "Success",
            life: 3000,
          });
          return data;
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error",
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAceptarOrden(false);
    }
  }
  function ButtonModificarFunctions(body: any) {
    if (body) {
      if (body.estado.descripcion === "Abierto") {
        return (
          <Button
            label={LanguageProvider({ id: "25209", alt: "Aceptar Orden" })}
            icon="pi pi-check"
            onClick={aceptarOrden}
            autoFocus
          />
        )
      }
      if (body.estado.descripcion === "Aceptado") {
        return (
          <Button
            label={LanguageProvider({ id: "25210", alt: "Orden Completada" })}
            icon="pi pi-check"
            onClick={completar}
            autoFocus
          />
        )
      }
    }
  }


  useEffect(() => {
    const init = async () => {
      try {
        if (visible && values.ordeReparacion && !hasReload.current) {
          hasReload.current = true;

          const body = values.ordeReparacion;
          const [clienteImputado, subcuenta, mecanicoInspector, clasificacion] = await Promise.all([
            loadClienteImputado(),
            loadSubcuenta(),
            loadMecanicoInspector(),
            loadClasificacion()
          ]);

          setFormModel({
            ...formModel,
            imputado: clienteImputado[0] ?? null,
            subcuenta: subcuenta[0] ?? null,
            inspector: mecanicoInspector[0] ?? null,
            clasificacion: clasificacion[0] ?? null
          });
        }
      } catch (error) {
        console.error(error)
      }
    }


    if (visible) {
      hasReload.current = false;
      init();
    }
  }, [visible])


  return (
    <div>
      <Toast ref={toast} position="top-center" />
      <Dialog
        className="modalAltaOrdenReparacionState"
        header="Orden de Reparación - Alta"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={LanguageProvider({ id: "21441", alt: "Guardar" })}
                icon="pi pi-check"
                onClick={save}
                autoFocus
              />
              <Button
                label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                icon="pi pi-times"
                onClick={onClose}
                className="p-button-text"
              />
              {
                ButtonModificarFunctions(values.ordeReparacion)
              }
            </div>
          );
        }}
      >


        <div className="column-form">
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <div className="input-group">
                  <label>
                    <LanguageProvider id={"5701"} alt="Nro. Orden" />
                  </label>
                  <SipcoInputText
                    value={formModel.nroOrden}
                    onChange={(e) => setFormModel({ ...formModel, nroOrden: e.target.value })}
                    className="input-modal"
                    disabled
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"20804"} alt="Estado" />
                  </label>
                  <SipcoInputText
                    value={formModel.estado}
                    onChange={(e) => setFormModel({ ...formModel, estado: e.target.value })}
                    className="input-modal"
                    disabled
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"1491"} alt="VIN" />
                  </label>
                  <SipcoAutocomplete
                    suggestions={vinActive}
                    completeMethod={searchVin}
                    value={formModel.vin}
                    onChange={(e) =>
                      setFormModel({ ...formModel, vin: e.value })
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        loadHistoricoTaller(e.target);
                      }
                    }}
                    field="id"
                    className="input-modal"
                    dropdown
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"17014"} alt="Nro Ingreso" />
                  </label>
                  <SipcoInputText
                    value={formModel.nroIngresoFistLabel}
                    disabled
                    className="input-modal"
                  />
                  <SipcoInputText
                    value={formModel.nroIngresoSecondLabel}
                    disabled
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"17015"} alt="Inspector" />
                  </label>
                  <SipcoDropdown
                    loading={loadingMecanicoInspector}
                    value={formModel.inspector}
                    options={mecanicoInspector}
                    onChange={(e) =>
                      setFormModel({ ...formModel, inspector: e.target.value })
                    }
                    optionLabel="descripcion"
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"4791"} alt="Subcuenta" />
                  </label>
                  <SipcoDropdown
                    loading={loadingSubcuenta}
                    value={formModel.subcuenta}
                    options={subcuenta}
                    onChange={(e) => {
                      hendleTallerLoad(e.target.value);
                    }}
                    optionLabel="descripcion"
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"18487"} alt="Taller" />
                  </label>
                  <SipcoDropdown
                    loading={loadingTaller}
                    value={formModel.taller}
                    options={taller}
                    onChange={(e) =>
                      setFormModel({ ...formModel, taller: e.target.value })
                    }
                    optionLabel="descripcion"
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"3568"} alt="Imputado a" />
                  </label>
                  <SipcoDropdown
                    loading={loadingClienteImputado}
                    value={formModel.imputado}
                    options={clienteImputado}
                    onChange={(e) =>
                      setFormModel({ ...formModel, imputado: e.target.value })
                    }
                    optionLabel="descripcion"
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"1165"} alt="Ubicación Actual" />
                  </label>
                  <SipcoInputText
                    value={formModel.ubicacionActual}
                    onChange={(e) =>
                      setFormModel({ ...formModel, ubicacionActual: e.target.value })
                    }
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"17012"} alt="Incidentes" />
                  </label>
                  <SipcoInputTextArea
                    value={formModel.incidentes}
                    onChange={(e) =>
                      setFormModel({ ...formModel, incidentes: e.target.value })
                    }
                    className="input-modal"
                  />
                </div>

              </div>
            </div>
          </div>

          <div className="form">
            <div className="form-row">
              <div className="sipco-option">

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"15739"} alt="Fecha Alta" />
                  </label>
                  <SipcoCalendar
                    className="input-modal"
                    value={formModel.fechaAlta}
                    onChange={(e) =>
                      setFormModel({ ...formModel, fechaAlta: e.target.value })
                    }
                    showIcon
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"16493"} alt="Fecha de cierre real" />
                  </label>
                  <SipcoCalendar
                    className="input-modal"
                    value={formModel.fechaCierreReal}
                    onChange={(e) =>
                      setFormModel({ ...formModel, fechaCierreReal: e.target.value })
                    }
                    disabled
                    showIcon
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"3231"} alt="Fecha Facturación" />
                  </label>
                  <SipcoCalendar
                    className="input-modal"
                    value={formModel.fechaFacturacion}
                    onChange={(e) =>
                      setFormModel({ ...formModel, fechaFacturacion: e.target.value })
                    }
                    disabled
                    showIcon
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"14683"} alt="Fecha Anulación" />
                  </label>
                  <SipcoCalendar
                    className="input-modal"
                    value={formModel.fechaAnulacion}
                    onChange={(e) =>
                      setFormModel({ ...formModel, fechaAnulacion: e.target.value })
                    }
                    disabled
                    showIcon
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"16492"} alt="Fecha cierre estimada" />
                  </label>
                  <SipcoCalendar
                    className="input-modal"
                    value={formModel.fechaCierreEstimada}
                    onChange={(e) =>
                      setFormModel({ ...formModel, fechaCierreEstimada: e.target.value })
                    }
                    showIcon
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"6160"} alt="Daños" />
                  </label>
                  <SipcoInputTextArea
                    value={formModel.danos}
                    onChange={(e) =>
                      setFormModel({ ...formModel, danos: e.target.value })}
                    className="input-modal"
                    disabled
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"24502"} alt="Clasificación" />
                  </label>
                  <SipcoDropdown
                    loading={loadingClasificacion}
                    value={formModel.clasificacion}
                    options={clasificacion}
                    onChange={(e) =>
                      setFormModel({ ...formModel, clasificacion: e.target.value })}
                    optionLabel="descripcion"
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"24501"} alt="Tiempo Total Estimado" />
                  </label>
                  <SipcoInputText
                    value={formModel.tiempoEstimado}
                    onChange={(e) =>
                      setFormModel({ ...formModel, tiempoEstimado: e.target.value })}
                    className="input-modal"
                  />
                </div>

                <div className="input-group">
                  <label>
                    <LanguageProvider id={"24503"} alt="Costo Total Estimado" />
                  </label>
                  <SipcoInputText
                    value={formModel.costoTotalEstimado}
                    onChange={(e) =>
                      setFormModel({ ...formModel, costoTotalEstimado: e.target.value })}
                    className="input-modal"
                  />
                </div>


              </div>
            </div>
          </div>


        </div>


      </Dialog>
    </div>
  );
};
export default ModalAltaOrdenReparacionState;
