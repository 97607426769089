import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ContableCiudad } from "src/models/ContableCiudad";

const MantenerContableCiudades = () => {
  const SCREEN_CODE = "abm0070_mantener_contable_ciudades";
  const URL_BASE = "/mantener-contable-ciudades";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ContableCiudad, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const contableCiudad = {
          ...model,
          pais: filterValues.securityValues.country,
          ciudad: model.ciudad,
          ciudadContable: model.ciudadContable

        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          contableCiudad,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ContableCiudad, filterValues: any) {
    try {
      console.log(model)
      const contableCiudad = {
        ...model,
        ciudad: model.ciudad,
        ciudadContable: model.ciudadContable
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        contableCiudad,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: ContableCiudad, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        contableCiudad: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ContableCiudad[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter.securityValues.country,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<ContableCiudad> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          contableCiudad: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadCiudad(): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/ciudad",
        {

        }
      );
      if (status === 200) {
        return data;

      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<ContableCiudad>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="647" alt="Mantener Talleres" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
        },
      }}
      dataTableColumns={[
        { field: "ciudad.id", header: <LanguageProvider id="6590" alt="Código" /> },
        {
          field: "ciudad.descripcion",
          header: <LanguageProvider id="164" alt="Ciudad" />,
        },
        {
          field: "ciudadContable",
          header: <LanguageProvider id="12419" alt="Descripcion" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => rowData.activo ? "SI" : "NO",
        },
      ]
      }
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        ciudadContable: '',
        ciudad: null,
        usuarioUltimaModificacion: null,
      }}
      loadDropdownData={{
        ciudad: loadCiudad,
      }}
      formSettings={[
        {
          field: "ciudad",
          label: LanguageProvider({ id: "164", alt: "Ciudad" }),
          dropDownPlaceholder: LanguageProvider({ id: "164", alt: "Ciudad" }),
          type: "dropdown",
          dataSource: loadCiudad,
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          field: "ciudadContable",
          label: <LanguageProvider id="12419" alt="Descripción" />,
          type: "inputText",
          length: 3,
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerContableCiudades;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};