import "./MantenerImputacionesResponsables.scss";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useEffect, useRef, useState } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ImputacionesResponsables } from "src/models/ImputacionesResponsables";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoColumn from "@shared/components/sipco-column";
import { Checkbox } from "primereact/checkbox";

const MantenerImputacionesResponsables = () => {
  const SCREEN_CODE = "abm0061_mantener_imputaciones_responsables";
  const URL_BASE = "/mantener-imputaciones-responsables";
  const sipcoAxiosService = useSipcoAxiosService();
  //const abmRef = useRef<GenericAbmRef<ImputacionesResponsables>>();
  const dt = useRef(null);

  const [findBy, setFindBy] = useState(null);
  const [imputacion, setImputacion] = useState(null);
  const [activo, setActivo] = useState(false);

  const [loadingReportFirst, setLoadingReportFirst] = useState(false);
  const [reporteFirst, setReporteFirst] = useState(null);
  const [selectedReporFirst, setSelectedReporFirst] = useState(null);

  const [loadingReportSec, setLoadingReportSec] = useState(false);
  const [reporteSecond, setReporteSecond] = useState(null);
  const [selectedReportSec, setSelectedReportSec] = useState(null);

  async function handleSave(
    model: ImputacionesResponsables,
    filterValues: any
  ) {
    try {
      if (filterValues?.securityValues?.country) {
        const imputacionResponsable = {
          ...model,
          pais: filterValues.securityValues.country,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          imputacionResponsable: imputacionResponsable,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: ImputacionesResponsables) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/update", {
        imputacionResponsable: { ...model, activo },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: ImputacionesResponsables) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        imputacionResponsable: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<ImputacionesResponsables[]> {
    setReporteSecond(null);
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter?.securityValues?.country,
          imputacion: filter?.imputacion,
        }
      );
      if (status === 200) {
        setFindBy(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<ImputacionesResponsables> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          imputacionResponsable: model,
        }
      );
      if (status === 200) {
        if (findBy && reporteFirst !== null) {
          setLoadingReportSec(true);
          setReporteSecond(data);
        }
        setActivo(model.activo);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportSec(false);
    }
    return null;
  }

  async function loadImputacion(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/imputacion",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  useEffect(() => {
    async function loadResponsable(filter: any): Promise<any[]> {
      if (imputacion !== null) {
        setLoadingReportFirst(true);
        try {
          const { status, data } = await sipcoAxiosService.post(
            URL_BASE + "/responsable",
            {
              pais: { id: filter?.pais?.id },
              imputacion: { id: imputacion?.id },
            }
          );
          if (status === 200) {
            setReporteFirst(data);
            return data;
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingReportFirst(false);
        }
      }
      return [];
    }
    loadResponsable(imputacion);
  }, [imputacion]);

  return (
    <GenericAbm<ImputacionesResponsables>
      screenCode={SCREEN_CODE}
      title={
        <LanguageProvider
          id="620"
          alt="Mantener Relación Imputaciones Responsables"
        />
      }
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: "",
        pais: null,
        imputacion: null,
        imputacionResponsable: null,
        activo: true,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
        },
      }}
      filterSettings={[
        {
          filter: "imputacion",
          label: LanguageProvider({ id: "7811", alt: "Imputación" }),
          placeholder: LanguageProvider({ id: "7811", alt: "Imputación" }),
          type: "dropdown",
          dataSource: loadImputacion,
          dropDownTemplate: DropdownOptionTemplate,
          onChange: (e) => {
            setImputacion(e);
          },
          dependency: ["securityValues.country"],
        },
      ]}
      dataTableColumns={[
        {
          field: "responsable.descripcion",
          header: <LanguageProvider id="11097" alt="Responsable" />,
        },
        {
          field: "imputacion.descripcion",
          header: <LanguageProvider id="7811" alt="Imputación" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      formSettings={[]}
      customForm={
        <div className="mantener-imputaciones-responsables">
          <div className="form">
            <p>
              <LanguageProvider
                id="625"
                alt="Seleccione Responsable de la Lista"
              />
            </p>
            <div className="form-row">
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={reporteFirst}
                paginator
                scrollHeight="flex"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                resizableColumns
                removableSort
                filterDisplay="menu"
                loading={loadingReportFirst}
                selectionMode="single"
                selection={selectedReporFirst}
                onSelectionChange={(e) => setSelectedReporFirst(e.value)}
              >
                <SipcoColumn field="descripcion" />
              </SipcoDataTable>
              <SipcoDataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={reporteSecond?.responsables}
                paginator
                scrollHeight="flex"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                resizableColumns
                removableSort
                filterDisplay="menu"
                loading={loadingReportSec}
                selectionMode="single"
                selection={selectedReportSec}
                onSelectionChange={(e) => setSelectedReportSec(e.value)}
              >
                <SipcoColumn field="descripcion" />
              </SipcoDataTable>
            </div>
            <div className="sipco-check">
              <label>
                <LanguageProvider id={"23849"} alt="Activo" />
              </label>
              <Checkbox
                checked={activo}
                onChange={(e) => setActivo(e.target.checked)}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default MantenerImputacionesResponsables;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
