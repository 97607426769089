import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Color } from "src/models/Color";

const MantenerColores = () => {
  const SCREEN_CODE = "abm0012_mantener_colores";
  const URL_BASE = "/mantener-colores";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: Color, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const color = {
          ...model,
          pais: filterValues?.securityValues?.country,
          cliente: filterValues?.securityValues?.client,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          color,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: Color, filterValues: any) {
    try {
      const color = {
        ...model,
        pais: filterValues?.securityValues?.country,
        cliente: filterValues?.securityValues?.client,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        color,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Color, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        color: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Color[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
        }
      );
      const marcas = await loadMarca(filter);
      if (status === 200 && marcas.length > 0 && data.length > 0) {
        // Percorre o array 'data' e substitui a chave 'marca' quando encontra correspondência
        data.forEach((item: any) => {
          const marcaCorrespondente = marcas.find(
            (marca: any) => marca.id === item.marca.id
          );
          if (marcaCorrespondente) {
            item.marca = marcaCorrespondente; // Substitui o valor de 'marca'
          }
        });

        return data;
      }

      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<Color> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          color: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMarca(filter: any): Promise<Color[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/marca",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<Color>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="239" alt="Mantener Colores" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
        },
      }}
      dataTableColumns={[
        {
          field: "id",
          header: <LanguageProvider id="6590" alt="Código" />,
        },
        {
          field: "descripcion",
          header: <LanguageProvider id="2140" alt="Color" />,
        },
        {
          field: "marca.descripcion",
          header: <LanguageProvider id="396" alt="Marca" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: null,
        activo: true,
        pais: null,
        cliente: null,
        color: "",
        marca: "",
      }}
      loadDropdownData={{ marca: loadMarca }}
      formSettings={[
        {
          field: "marca",
          label: <LanguageProvider id="396" alt="396" />,
          dropDownPlaceholder: LanguageProvider({ id: "396", alt: "Marca" }),
          type: "dropdown",
          dataSource: (filter: any) => loadMarca(filter),
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          length: 4,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="2140" alt="Color" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerColores;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
