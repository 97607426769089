import GenericAbm, {
  GenericAbmRef,
} from "@shared/components/genericAbm/generic-abm";
import React, { useRef } from "react";

import { Button } from "primereact/button";
import { Feriado } from "src/models/Feriado";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const MantenerFeriados = () => {
  const SCREEN_CODE = "abm0073_mantener_feriados";
  const URL_BASE = "/mantener-feriados";
  const SCREEN_TITLE = <LanguageProvider id="819" alt="Mantener Feriados" />;
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const empityFilter = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - 
  ${LanguageProvider({ id: "5198", alt: "Sistemas" })} -  ${LanguageProvider({ id: "2788", alt: "Funciones" })}`;

  const abmRef = useRef<GenericAbmRef<Feriado>>(null);

  async function handleSave(model: Feriado, filterValues: any) {
    if (!filterValues?.anio || !filterValues?.sistema) {
      toast.current?.show({
        severity: "warn",
        detail: empityFilter,
        life: 3000,
      });
      return false;
    }
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.account
      ) {
        const feriado = {
          ...model,
          pais: filterValues.securityValues.country,
          cuenta: filterValues?.securityValues?.account,
          funcion: filterValues?.funcion,
          sistema: filterValues?.sistema,
          fechaFeriado: { date: model.fechaFeriado },
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          feriado,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function activarDesactivar(model: Feriado[]) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/update", {
        feriados: model,
      });
      abmRef.current?.refreshDataTable();
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Feriado, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        feriado: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Feriado[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.account &&
      filter?.anio &&
      filter?.sistema
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            sistema: filter?.sistema,
            anio: filter?.anio,
          }
        );
        if (status === 200)
          if (data.length > 0) {
            return data;
          } else {
            return [];
          }
      } catch (error) {
        console.error(error);
      }
    }

    return [];
  }
  async function handleGet(model: any): Promise<Feriado> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          feriado: { ...model },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadSistema(filter: any): Promise<any[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.account) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/sistema",
          {}
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadFuncion(filter: any): Promise<any[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.account &&
      filter?.sistema
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/filter/ano",
          {
            pais: filter?.securityValues.country,
            cliente: filter?.securityValues.client,
            cuenta: filter?.securityValues?.account,
            sistema: filter?.sistema,
          }
        );
        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<Feriado>
        ref={abmRef}
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            account: true,
          },
        }}
        filterSettings={[
          {
            filter: "sistema",
            label: LanguageProvider({ id: "5198", alt: "Sistemas" }),
            placeholder: LanguageProvider({ id: "5198", alt: "Sistemas" }),
            type: "dropdown",
            dataSource: loadSistema,
            dropDownTemplate: DropdownOptionTemplate,
            required: true,
          },
          {
            filter: "anio",
            label: LanguageProvider({ id: "2789", alt: "Año" }),
            placeholder: LanguageProvider({ id: "2789", alt: "Año" }),
            type: "dropdown",
            dataSource: loadFuncion,
            dependency: ["sistema"],
            required: true,
          },
        ]}
        dataTableColumns={[
          {
            field: "fechaFeriado.date",
            header: <LanguageProvider id="13064" alt="Fecha Feriado" />,
            body: (rowData: any) => (
              <span>
                {new Date(rowData.fechaFeriado.date).toLocaleDateString()}
              </span>
            ),
          },
          {
            field: "anio",
            header: <LanguageProvider id="2789" alt="Año" />,
          },
          {
            field: "descripcion",
            header: <LanguageProvider id="12419" alt="Descripcion" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          activo: true,
          sistema: null,
          pais: null,
          cuenta: null,
          anio: 0,
          fechaFeriado: new Date(),
          descripcion: "",
          marcarSabadoDomingo: false,
        }}
        formSettings={[
          {
            field: "anio",
            label: LanguageProvider({ id: "2789", alt: "Año" }),
            type: "inputNumber",
            useGrouping: false,
          },
          {
            field: "fechaFeriado",
            label: LanguageProvider({ id: "13064", alt: "Fecha Feriado" }),
            type: "calendar",
          },
          {
            field: "descripcion",
            label: LanguageProvider({ id: "12419", alt: "Descripcion" }),
            type: "inputText",
            length: 50,
          },
          {
            field: "marcarSabadoDomingo",
            label: LanguageProvider({
              id: "840",
              alt: "¿Marcar Sábados y Domingos",
            }),
            type: "checkbox",
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        dataTableSelectionMode="multiple"
        customActions={
          <Button
            label={LanguageProvider({ id: "106", alt: "Activar / Desactivar" })}
            onClick={() => {
              activarDesactivar(abmRef.current?.selectedEntries);
            }}
            raised
            tooltip={LanguageProvider({
              id: "106",
              alt: "Activar / Desactivar",
            })}
            tooltipOptions={{ position: "bottom" }}
          />
        }
        save={handleSave}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default MantenerFeriados;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
