import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { ColorPicker } from "primereact/colorpicker";
import { GenericAbmFieldProps } from "../generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputNumber from "@shared/components/sipco-input-number";
import SipcoInputText from "@shared/components/sipco-input-text";
import SipcoInputTextArea from "@shared/components/sipco-input-text-area";
import SipcoMultiSelect from "@shared/components/sipco-multiselect";
import isEqual from "lodash/isEqual";
import lodashGet from "lodash/get";
import lodashSet from "lodash/set";
import { usePrevious } from "primereact/hooks";

export interface GenericAbmFormInputRendererProps {
  filterValues: any;
  formModel: any;
  settings: GenericAbmFieldProps;
  value: any;
  onChange: (field: string, value: any) => void;
  updateFormModel: (value: any) => void;
  isEditMode: boolean;
}

const GenericAbmFormInputRenderer: React.FC<
  GenericAbmFormInputRendererProps
> = ({
  settings,
  value,
  onChange,
  updateFormModel,
  formModel,
  filterValues,
  isEditMode,
}) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [loadingDropdownData, setLoadingDropdownData] = useState(true);

  const [firstLoad, setFirstLoad] = useState(true);

  const prevFilters = usePrevious(filterValues);
  const prevModel = usePrevious(formModel);

  const [listBoxInputTextValue, setListBoxInputTextValue] = useState("");
  const [listBoxSelectedValue, setListBoxSelectedValue] = useState();

  useEffect(() => {
    async function handleDataSourceLoad() {
      try {
        if (settings.type === "dropdown" || settings.type === "multiselect") {
          if (settings.dataSource) {
            if (!settings.dependency && firstLoad) {
              /**
               * Carrega as opções de das dropdowns que não possuem dependência
               * e que estão sendo carregadas pela primeira vez.
               */
              const options = await settings.dataSource({
                ...formModel,
                ...filterValues,
              });

              if (isEditMode) {
                const valueFromModel = lodashGet(formModel, settings.field);
                if (valueFromModel) {
                  const option = options.find(
                    (option) => option.id === valueFromModel.id
                  );
                  if (option) {
                    onChange(settings.field, option);
                  }
                }
              }
              setDropdownOptions(options);
            } else if (Array.isArray(settings.dependency)) {
              /** Carrega as opções das dropdowns do form que possuem
               * dependencias validando que algum dos campos dos quais eles dependem tenham sido alterados
               * */
              let shouldReload = false;
              for (const dep of settings.dependency) {
                const prevDepFilterValue = lodashGet(prevFilters, dep);
                const currDepFilterValue = lodashGet(filterValues, dep);
                if (!isEqual(prevDepFilterValue, currDepFilterValue)) {
                  shouldReload = true;
                  break;
                }
                const prevDepModelValue = lodashGet(prevModel, dep);
                const currDepModelValue = lodashGet(formModel, dep);
                if (!isEqual(prevDepModelValue, currDepModelValue)) {
                  shouldReload = true;
                  break;
                }
              }
              if (shouldReload) {
                setLoadingDropdownData(true);
                const options = await settings.dataSource?.({
                  ...formModel,
                  ...filterValues,
                });
                if (firstLoad && isEditMode) {
                  const valueFromModel = lodashGet(formModel, settings.field);
                  if (valueFromModel) {
                    const option = options.find(
                      (option) => option.id === valueFromModel.id
                    );
                    if (option) {
                      onChange(settings.field, option);
                    }
                  }
                }
                setDropdownOptions(options);
              }
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setFirstLoad(false);
        setLoadingDropdownData(false);
      }
    }
    handleDataSourceLoad();
  }, [settings.dataSource, filterValues, formModel]);

  const disableInput =
    settings.disabled || (isEditMode && settings.disableOnUpdate);

  if (settings.type === "listbox") {
    return (
      <div className="form-row">
        <div className="generic-abm-list-box">
          <div className="sipco-option">
            <label htmlFor={settings.field}>
              {settings.label}
              {settings.required && "*"}
            </label>
            <SipcoInputText
              id={settings.field ?? ""}
              value={listBoxInputTextValue}
              onChange={(e) => {
                setListBoxInputTextValue(e.target.value);
              }}
              required={settings.required}
              disabled={disableInput}
              maxLength={settings.length}
              keyfilter={settings.keyfilter}
            />
          </div>
          <div className="generic-abm-list-box-actions">
            <Button
              label={LanguageProvider({ id: "2339", alt: "Agregar" })}
              onClick={() => {
                let updatedValue = [...value, listBoxInputTextValue];
                onChange(settings.field, updatedValue);
                setListBoxInputTextValue("");
              }}
              icon="pi pi-plus"
              iconPos="right"
            />
            <Button
              label={LanguageProvider({ id: "12725", alt: "Eliminar" })}
              onClick={() => {
                let updatedValue = value.filter(
                  (x) => x != listBoxSelectedValue
                );
                onChange(settings.field, updatedValue);
              }}
              icon="pi pi-trash"
              iconPos="right"
            />
          </div>
          <div className="sipco-option">
            <ListBox
              className="w-full"
              options={value}
              value={listBoxSelectedValue}
              onChange={(e) => setListBoxSelectedValue(e.value)}
              listStyle={{ minHeight: 200 }}
            />
          </div>
        </div>
      </div>
    );
  }

  if (settings.type === "radiobutton") {
    return (
      <div className="form-row radio-button">
        <label htmlFor={settings.field}>
          {settings.label}
          {settings.required && "*"}
        </label>
        <div className="radio-options-container">
          {settings.radioButtonOptions.map(
            (radioOption: {
              label: string;
              value: any;
              disabled?: boolean;
            }) => {
              return (
                <div className={"RadioButtonFilter"} key={radioOption.label}>
                  <label htmlFor={settings.field}>{radioOption.label}</label>
                  <RadioButton
                    value={radioOption}
                    checked={value === radioOption.value}
                    onChange={(e) => {
                      console.log(e, value);
                      onChange(settings.field, radioOption.value);
                    }}
                    disabled={radioOption.disabled}
                  />
                </div>
              );
            }
          )}
        </div>
      </div>
    );
  }

  return (
    <div key={settings.field} className="form-row">
      <div className="sipco-option">
        <label htmlFor={settings.field}>
          {settings.label}
          {settings.required && "*"}
        </label>
        {settings.type === "inputText" &&
          (settings.field.includes(".") ? (
            <SipcoInputText
              id={settings.field ?? ""}
              value={value}
              onChange={(e) => {
                if (settings.capitalizeInputText) {
                  e.target.value = e.target.value.toUpperCase();
                }
                const updatedFormData = { ...formModel };
                lodashSet(updatedFormData, settings.field, e.target.value);
                updateFormModel(updatedFormData);
              }}
              required={settings.required}
              disabled={disableInput}
              maxLength={settings.length}
              keyfilter={settings.keyfilter}
            />
          ) : (
            <SipcoInputText
              id={settings.field ?? ""}
              value={value}
              onChange={(e) => {
                if (settings.capitalizeInputText) {
                  e.target.value = e.target.value.toUpperCase();
                }
                onChange(settings.field, e.target.value);
              }}
              required={settings.required}
              disabled={disableInput}
              maxLength={settings.length}
              keyfilter={settings.keyfilter}
            />
          ))}
        {settings.type === "inputNumber" && (
          <SipcoInputNumber
            id={settings.field}
            value={value}
            onValueChange={(e) => {
              onChange(settings.field, e.value);
            }}
            required={settings.required}
            disabled={disableInput}
            min={settings.min}
            max={settings.max}
            maxFractionDigits={settings.maxFractionDigits}
            useGrouping={settings.useGrouping}
          />
        )}
        {settings.type === "dropdown" &&
          (settings.virtualizeDropdown ? (
            <SipcoDropdown
              id={settings.field}
              value={value}
              options={dropdownOptions ?? []}
              onChange={(e) => {
                onChange(settings.field, e.value);
              }}
              optionLabel={
                settings.optionLabel ? settings.optionLabel : "descripcion"
              }
              filter
              showClear
              loading={loadingDropdownData}
              itemTemplate={settings.dropDownTemplate}
              valueTemplate={settings.dropDownTemplate}
              placeholder={settings.dropDownPlaceholder}
              virtualScrollerOptions={{
                itemSize: 20,
              }}
              disabled={disableInput}
              filterBy={settings.filterBy ? settings.filterBy : null}
            />
          ) : (
            <SipcoDropdown
              id={settings.field}
              value={value}
              options={dropdownOptions ?? []}
              onChange={(e) => {
                onChange(settings.field, e.value);
              }}
              optionLabel={
                settings.optionLabel ? settings.optionLabel : "descripcion"
              }
              filter
              showClear
              loading={loadingDropdownData}
              itemTemplate={settings.dropDownTemplate}
              valueTemplate={settings.dropDownTemplate}
              placeholder={settings.dropDownPlaceholder}
              disabled={disableInput}
              filterBy={settings.filterBy ? settings.filterBy : null}
            />
          ))}
        {settings.type === "multiselect" && (
          <SipcoMultiSelect
            id={settings.field}
            value={value}
            options={dropdownOptions ?? []}
            onChange={(e) => {
              onChange(settings.field, e.value);
            }}
            optionLabel={
              settings.optionLabel ? settings.optionLabel : "descripcion"
            }
            filter
            showClear
            loading={loadingDropdownData}
            itemTemplate={settings.dropDownTemplate}
            placeholder={settings.dropDownPlaceholder}
            disabled={disableInput}
            filterBy={settings.filterBy ? settings.filterBy : null}
          />
        )}
        {settings.type === "checkbox" && (
          <Checkbox
            inputId={settings.field}
            checked={value}
            onChange={(e) => {
              onChange(settings.field, e.checked);
            }}
            disabled={disableInput}
          />
        )}
        {settings.type === "calendar" && (
          <SipcoCalendar
            inputId={settings.field}
            value={value}
            onChange={(e) => {
              onChange(settings.field, e.value);
            }}
            disabled={disableInput}
          />
        )}
        {settings.type === "textArea" && (
          <SipcoInputTextArea
            id={settings.field}
            value={value ?? ""}
            onChange={(e) => {
              if (settings.capitalizeInputText) {
                e.target.value = e.target.value.toUpperCase();
              }
              onChange(settings.field, e.target.value);
            }}
            required={settings.required}
            disabled={disableInput}
            maxLength={settings.length}
          />
        )}
        {settings.type === "colorpicker" && (
          <ColorPicker
            id={settings.field}
            value={value ?? "00000"}
            onChange={(e) => {
              onChange(settings.field, e.value);
            }}
            format="hex"
            required={settings.required}
            disabled={disableInput}
          />
        )}
      </div>
    </div>
  );
};

export default GenericAbmFormInputRenderer;
