import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Documentos } from "src/models/Documentos";

const MantenerDocumentos = () => {
  const SCREEN_CODE = "abm0127_mantener_documentos";
  const URL_BASE = "/mantener-documentos";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: Documentos, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        await sipcoAxiosService.post(URL_BASE + "/save", {
          secuenciaDocumento: {
            ...model,
            pais: filterValues.securityValues.country,
            cuenta: filterValues.securityValues.account,
            subcuenta: filterValues.securityValues.subaccount,
            fechaEmision: { date: model?.fechaEmision },
          },
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: Documentos) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/update", {
        secuenciaDocumento: {
          ...model,
          fechaEmision: { date: model?.fechaEmision.toISOString() },
        },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: Documentos) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        secuenciaDocumento: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<Documentos[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter?.securityValues?.country,
          cuenta: filter?.securityValues?.account,
          subcuenta: filter?.securityValues?.subaccount,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<Documentos> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          secuenciaDocumento: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadTipoDocumento(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/tipo-secuencia-documento",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) {
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<Documentos>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="10641" alt="Mantener Documentos" />}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: "",
        pais: null,
        cuenta: null,
        subcuenta: null,
        tipoDocumento: null,
        serie: "",
        numerador: "",
        fechaEmision: new Date(),
        confirmaFecha: false,
        activo: true,
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          account: true,
          subaccount: true,
        },
      }}
      loadDropdownData={{
        tipoDocumento: loadTipoDocumento,
      }}
      dataTableColumns={[
        {
          field: "pais.descripcion",
          header: <LanguageProvider id="23960" alt="País" />,
        },
        {
          field: "subcuenta.descripcion",
          header: <LanguageProvider id="4791" alt="Subcuenta" />,
        },
        {
          field: "tipoDocumento.descripcion",
          header: <LanguageProvider id="10643" alt="Tipo Documento" />,
        },
        {
          field: "serie",
          header: <LanguageProvider id="1316" alt="Serie" />,
        },
        {
          field: "numerador",
          header: <LanguageProvider id="10644" alt="Numerador" />,
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "fechaEmision.date",
          header: <LanguageProvider id="1492" alt="Fecha Emisión" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(rowData.fechaEmision.timestamp).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "confirmaFecha",
          header: <LanguageProvider id="16297" alt="Confirma Fecha Emisión" />,
          body: (rowData: any) => {
            return rowData.confirmaFecha ? "SI" : "NO";
          },
        },
      ]}
      formSettings={[
        {
          field: "tipoDocumento",
          label: <LanguageProvider id="10643" alt="Tipo Documento" />,
          dropDownPlaceholder: LanguageProvider({
            id: "10643",
            alt: "Tipo Documento",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadTipoDocumento(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["securityValues.country"],
        },
        {
          field: "serie",
          label: <LanguageProvider id="1316" alt="Serie" />,
          type: "inputNumber",
          min: 0,
          max: 9,
          required: true,
        },
        {
          field: "numerador",
          label: <LanguageProvider id="10644" alt="Numerador" />,
          type: "inputNumber",
          min: 0,
          max: 10,
          required: true,
        },
        {
          field: "fechaEmision",
          label: <LanguageProvider id="1492" alt="Fecha Emisión" />,
          type: "calendar",
          required: true,
        },
        {
          field: "confirmaFecha",
          label: <LanguageProvider id="16297" alt="Confirma Fecha Emisión" />,
          type: "checkbox",
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
    />
  );
};

export default MantenerDocumentos;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
