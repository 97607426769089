import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { ImpuestoIcms } from "src/models/ImpuestoIcms";

const MantenerImpuestosIcms = () => {
  const SCREEN_CODE = "abm0139_mantener_impuestos_icms";
  const URL_BASE = "/mantener-impuestos-icms";
  const SCREEN_TITLE = (
    <LanguageProvider id="11122" alt="Mantener Impuestos ICMS" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: ImpuestoIcms, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const impuestoIcms = {
          ...model,
          pais: filterValues.securityValues.country,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          impuestoIcms,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ImpuestoIcms, filterValues: any) {
    try {
      const impuestoIcms = {
        ...model,

        pais: filterValues.securityValues.country,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        impuestoIcms,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: ImpuestoIcms, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        impuestoIcms: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ImpuestoIcms[]> {
    if (filter?.securityValues?.country) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<ImpuestoIcms> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          impuestoIcms: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadProvincias(filter: any): Promise<ImpuestoIcms[]> {
    if (filter?.securityValues?.country) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/provincia",
          {
            pais: filter.securityValues.country,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ImpuestoIcms>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
          },
        }}
        // filterSettings={[]}
        dataTableColumns={[
          {
            field: "provinciaOrigen.descripcion",
            header: <LanguageProvider id="2496" alt="x" />,
          },
          {
            field: "provinciaDestino.descripcion",
            header: <LanguageProvider id="3410" alt="x" />,
          },
          {
            field: "alicuota",
            header: <LanguageProvider id="10845" alt="x" />,
            body: (rowData: any) => rowData.alicuota.toFixed(2),
          },

          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => {
              return (
                <span>
                  {new Date(
                    rowData.fechaUltimaModificacion.date
                  ).toLocaleString()}
                </span>
              );
            },
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          provinciaOrigen: null,
          provinciaDestino: null,
          alicuota: 0,
        }}
        formSettings={[
          {
            field: "provinciaOrigen",
            label: LanguageProvider({ id: "2496", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "2496", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadProvincias(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate:true
          },
          {
            field: "provinciaDestino",
            label: LanguageProvider({ id: "3410", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "3410", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadProvincias(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate:true
          },
          {
            field: "alicuota",
            label: LanguageProvider({ id: "10845", alt: "x" }),
            type: "inputNumber",
            max: 99,
            maxFractionDigits: 2,
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default MantenerImpuestosIcms;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
