import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { SubcuentaDatosCtrc } from "src/models/SubcuentaDatosCtrc";

const SubcuentasDatosCtrc = () => {
  const SCREEN_CODE = "abm0133_subcuentas_datos_ctrc";
  const URL_BASE = "/mantener-subcuentas-datos-ctrc";
  const SCREEN_TITLE = (
    <LanguageProvider id="10866" alt="Mantener Subcuentas Datos CTRC" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: SubcuentaDatosCtrc, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client &&
        filterValues?.securityValues?.account &&
        filterValues?.securityValues?.subaccount
      ) {
        const subcuentaDatosCtrc = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues?.securityValues?.account,
          subcuenta: filterValues?.securityValues?.subaccount,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          subcuentaDatosCtrc,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: SubcuentaDatosCtrc, filterValues: any) {
    try {
      const subcuentaDatosCtrc = {
        ...model,
        pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues?.securityValues?.account,
          subcuenta: filterValues?.securityValues?.subaccount,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        subcuentaDatosCtrc,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: SubcuentaDatosCtrc, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        subcuentaDatosCtrc: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<SubcuentaDatosCtrc[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account &&
      filter?.securityValues?.subaccount
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
            subcuenta: filter?.securityValues?.subaccount,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<SubcuentaDatosCtrc> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          subcuentaDatosCtrc: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadCodigo(filter: any): Promise<SubcuentaDatosCtrc[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/tipo-codigo",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadConcesionario(filter: any): Promise<SubcuentaDatosCtrc[]> {
    if (filter?.securityValues?.country && filter?.securityValues?.client) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/concesionario",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function loadDireccionEntrega(
    filter: any
  ): Promise<SubcuentaDatosCtrc[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.concesionario
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/direccion-entrega",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            concesionario: filter.concesionario,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<SubcuentaDatosCtrc>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
            subaccount: true,
          },
        }}
        // filterSettings={[]}
        dataTableColumns={[
          {
            field: "codigo.id",
            header: <LanguageProvider id="6590" alt="Código" />,
          },
          {
            field: "concesionario.id",
            header: <LanguageProvider id="10871" alt="Cod. Concesionario" />,
          },
          {
            field: "concesionario.descripcion",
            header: <LanguageProvider id="2941" alt="Concesionario" />,
          },
          {
            field: "direccionEntrega.id",
            header: <LanguageProvider id="10868" alt="Cod. Dir. Entrega" />,
          },
          {
            field: "direccionEntrega.descripcion",
            header: <LanguageProvider id="3069" alt="Dirección" />,
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          cliente: null,
          cuenta: null,
          subcuenta: null,
          concesionario: null,
          direccionEntrega: null,
          codigo: null,
        }}
        formSettings={[
          {
            field: "codigo",
            label: LanguageProvider({ id: "6590", alt: "Código" }),
            dropDownPlaceholder: LanguageProvider({
              id: "6590",
              alt: "Código",
            }),
            type: "dropdown",
            dataSource: (filter: any) => loadCodigo(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate: true,
          },
          {
            field: "concesionario",
            label: LanguageProvider({ id: "10871", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "10871", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadConcesionario(filter),
            dropDownTemplate: DropdownOptionTemplateConcesionario,
            disableOnUpdate: true,
            virtualizeDropdown:true,
          },
          {
            field: "direccionEntrega",
            label: LanguageProvider({ id: "10868", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "10868", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadDireccionEntrega(filter),
            dropDownTemplate: DropdownOptionTemplate,
            disableOnUpdate: true,            
            dependency: ["concesionario"]
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default SubcuentasDatosCtrc;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionTemplateConcesionario = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
