import "./ModalModificarMantenerCatalogoRepuestos.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import { useLocation } from "react-router-dom";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { HttpStatusCode } from "axios";

interface InterfaceModalModificarMantenerCatalogoRepuestos {
  visible: boolean;
  onClose: () => void;
  model?: any;
  onConfirm?: (updatedList: any[]) => void;
  selected?: any[];
  values?: any;
}

const ModalModificarMantenerCatalogoRepuestos: React.FC<
  InterfaceModalModificarMantenerCatalogoRepuestos
> = ({ visible, onClose, model, onConfirm, selected, values }) => {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    pieza: "",
    descripcion: "",
    costo: "",
    costoDollar: "",
    cantidad: "",
    fechaCotizacion: new Date(),
    peso: "",
    iva: "",
    rubro: null,
    cuentaTaller: null,
    grupoTaller: null,
    modeloTaller: null,
  });

  const data = convertDateObjects(reporte?.filas);

  const [saveLabel, setSaveLabel] = useState(null);
  const [loadingSave, setLoadingSave] = useState(false);

  async function update() {
    try {

      setLoadingSave(true);
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-catalogos-repuestos/update",
        {
          catalogoRepuesto: {
            pais: values?.stage?.pais,
            cliente: values?.stage?.cliente,
            cuenta: values?.stage?.cuenta,
            usuarioUltimaModificacion: selected.map((x: any) => x.usuarioUltimaModificacion)[0],
            id: filter.pieza,
            descripcion: filter.descripcion,
            costoPeso: Number(filter.costo),
            costoDolar: Number(filter.costoDollar),
            cantidad: Number(filter.cantidad),
            fechaCotizacion: {
              date: filter.fechaCotizacion || null,
              timestamp: filter.fechaCotizacion || null
            },
            peso: Number(filter.peso),
            iva: Number(filter.iva),
            rubro: filter.rubro,
            cuentaTaller: filter.cuentaTaller,
            grupoTaller: filter.grupoTaller,
            modeloTaller: filter.modeloTaller,
            prioridad: 0
          }
        }
      );
      if (status === HttpStatusCode.Ok) {
        setSaveLabel(data);
        onClose();
        toast.current?.show({
          severity: "success",
          summary: "Success",
          detail: "Saved successfully",
          life: 3000,
        })
        return data;
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSave(false);
    }
  }

  const [rubro, setRubro] = useState([]);
  const [loadingRubro, setLoadingRubro] = useState(false);
  async function loadRubro() {
    try {
      setLoadingRubro(true);
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-catalogos-repuestos/rubro",
        {
          pais: values?.stage?.pais,
        }
      );
      if (status === HttpStatusCode.Ok) {
        setRubro(data);
        return data;
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRubro(false);
    }
  }

  const [cuenta, setCuenta] = useState([]);
  const [loadingCuenta, setLoadingCuenta] = useState(false);
  async function loadCuentaTaller() {
    try {
      setLoadingCuenta(true);
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-catalogos-repuestos/cuenta-taller",
        {
          pais: values?.stage?.pais,
          cuenta: values?.stage?.cuenta,
        }
      );
      if (status === HttpStatusCode.Ok) {
        setCuenta(data);
        return data;
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCuenta(false);
    }
  }

  const [grupo, setGrupo] = useState([]);
  const [loadingGrupo, setLoadingGrupo] = useState(false);

  async function loadGrupoTaller() {
    try {
      setLoadingGrupo(true);
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-catalogos-repuestos/grupo-taller",
        {
          pais: values?.stage?.pais,
          cliente: values?.stage?.cliente,
        }
      );
      if (status === HttpStatusCode.Ok) {
        setGrupo(data);
        return data;
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrupo(false);
    }
  }

  const [modelo, setModelo] = useState([]);
  const [loadingModelo, setLoadingModelo] = useState(false);

  async function loadModeloTaller() {
    try {
      setLoadingModelo(true);
      const { status, data } = await sipcoAxiosService.post(
        "/mantener-catalogos-repuestos/modelo-taller",
        {
          pais: values?.stage?.pais,
          grupoTaller: filter.grupoTaller || null,
        }
      );
      if (status === HttpStatusCode.Ok) {
        setModelo(data);
        return data;
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelo(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      if (visible) {
        const rubro = await loadRubro();
        const cuenta = await loadCuentaTaller();
        const grupo = await loadGrupoTaller();



        setFilter({
          ...filter,
          descripcion: selected.map((x: any) => x.descripcion)[0],
          pieza: selected.map((x: any) => x.id)[0],
          costo: selected.map((x: any) => x.costoPeso)[0],
          peso: selected.map((x: any) => x.peso)[0],
          costoDollar: selected.map((x: any) => x.costoDolar)[0],
          iva: selected.map((x: any) => x.iva)[0],
          cantidad: selected.map((x: any) => x.cantidad)[0],
          rubro: rubro[0],
          cuentaTaller: cuenta[0],
          grupoTaller: grupo[0],
        });
      }
    };

    init();
  }, [visible || selected]);


  useEffect(() => {
    const init = async () => {
      if (visible
        && grupo.length !== 0
        && filter.grupoTaller !== null) {
        const modelo = await loadModeloTaller();


        setFilter({
          ...filter,
          modeloTaller: modelo[0],
        });
      }
    };

    init();
  }, [filter.grupoTaller]);


  return (
    <div>
      <Dialog
        className="modalAltaReparacionServicios"
        header="Orden Reparación Proveedores - Alta"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={LanguageProvider({ id: "9491", alt: "Aceptar" })}
                icon="pi pi-check"
                onClick={update}
                autoFocus
              />
              <Button
                label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                icon="pi pi-times"
                onClick={onClose}
                className="p-button-text"
              />
            </div>
          );
        }}
      >
        <div className="modal-container">
          <div className="form-modal">
            <div className="form-row-modal">
              <label>
                <LanguageProvider id={"2020"} alt="Pieza" />
              </label>
              <SipcoInputText className="input-modal" value={filter.pieza} disabled />
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"12419"} alt="Descripcion" />
                </label>
                <SipcoInputText className="input-modal" value={filter.descripcion} onChange={(e) => setFilter({ ...filter, descripcion: e.target.value })} />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"16953"} alt="Costo($)" />
                </label>
                <SipcoInputText className="input-modal" value={filter.costo} onChange={(e) => setFilter({ ...filter, costo: e.target.value })} />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"16954"} alt="Costo(U$D)" />
                </label>
                <SipcoInputText className="input-modal" value={filter.costoDollar} onChange={(e) => setFilter({ ...filter, costoDollar: e.target.value })} />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"1253"} alt="Cantidad" />
                </label>
                <SipcoInputText className="input-modal" value={filter.cantidad} onChange={(e) => setFilter({ ...filter, cantidad: e.target.value })} />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"6256"} alt="Fecha Cotización" />
                </label>
                <SipcoCalendar
                  value={filter.fechaCotizacion}
                  onChange={(e) =>
                    setFilter({ ...filter, fechaCotizacion: e.value })
                  }
                  className="input-modal"
                  showIcon
                />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"16955"} alt="Peso(Gr.)" />
                </label>
                <SipcoInputText className="input-modal" value={filter.peso} onChange={(e) => setFilter({ ...filter, peso: e.target.value })} />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"16956"} alt="I.V.A (%)" />
                </label>
                <SipcoInputText className="input-modal" value={filter.iva} onChange={(e) => setFilter({ ...filter, iva: e.target.value })} />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2183"} alt="Rubro" />
                </label>
                <SipcoDropdown
                  loading={loadingRubro}
                  value={filter.rubro}
                  onChange={(e) => setFilter({ ...filter, rubro: e.value })}
                  options={rubro}
                  className="input-modal"
                  optionLabel="descripcion"
                />
              </div>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2184"} alt="Cuenta Taller" />
                </label>
                <SipcoDropdown loading={loadingCuenta} value={filter.cuentaTaller} onChange={(e) => setFilter({ ...filter, cuentaTaller: e.value })}
                  options={cuenta} className="input-modal" optionLabel="descripcion" />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2185"} alt="Grupo Taller" />
                </label>
                <SipcoDropdown loading={loadingGrupo} value={filter.grupoTaller} optionLabel="descripcion" onChange={(e) => setFilter({ ...filter, grupoTaller: e.value })} options={grupo} className="input-modal" />
              </div>
            </div>
          </div>
          <div className="form-modal">
            <div className="form-modal">
              <div className="form-row-modal">
                <label>
                  <LanguageProvider id={"2186"} alt="Modelo Taller" />
                </label>
                <SipcoDropdown value={filter.modeloTaller} optionLabel="descripcion" onChange={(e) => setFilter({ ...filter, modeloTaller: e.value })} options={modelo} className="input-modal" />
              </div>
            </div>
          </div>
        </div>

      </Dialog>
    </div>
  );
};
export default ModalModificarMantenerCatalogoRepuestos;
