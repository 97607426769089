import "./RecepcionTerceros.scss";

import { AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import useRowStyles from "../../../hooks/useRowStyles";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

export function RecepcionTerceros() {
  const SCREEN_CODE = "fun0224_recepcion_terceros";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();

  const dt = useRef(null);
  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);
  const [selectedReportData, setSelectedReportData] = useState(null);
  const [loadingProcesar, setLoadingProcesar] = useState(false);
  const [validFieldDialog, setValidFieldDialog] = useState(false);
  const [procesarDialog, setProcesarDialog] = useState(false);
  const [confirmProcessDialog, setConfirmProcessDialog] = useState(false);

  const rowStyles = useRowStyles([selectedReportData]);

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const btnProcesarClick = () => {
    try {
      if (!selectedReportData) {
        setValidFieldDialog(true);
        return;
      }
      setProcesarDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleProcesar = (isConfirmed) => {
    try {
      setProcesarDialog(false);
      if (isConfirmed) {
        validaProcesar();
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function validaProcesar() {
    try {
      const filters = {
        pais: { id: securityFilters.securityValues.country?.id || null },
        cliente: { id: securityFilters.securityValues.client?.id || null },
        cuenta: { id: securityFilters.securityValues.account?.id || null },
        subcuenta: {
          id: securityFilters.securityValues.subaccount?.id || null,
        },
        dtoList: reporte.map((item) => ({
          ...item,
        })),
        fecha: model.fechaRecepcion.getTime(),
        stockPlayaDestino: {
          calle: model?.ubicacione,
          columna: model?.columna,
          nivel: model?.nivel,
        },
      };

      const { status } = await sipcoAxiosService.post(
        "/recepcion-terceros/validate-recepcion-playa-terceros",
        filters
      );

      if (status === 200) {
        aProcesar();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function aProcesar() {
    setLoadingProcesar(true);
    try {
      const filters = {
        pais: { id: securityFilters.securityValues.country?.id || null },
        cliente: { id: securityFilters.securityValues.client?.id || null },
        cuenta: { id: securityFilters.securityValues.account?.id || null },
        subcuenta: {
          id: securityFilters.securityValues.subaccount?.id || null,
        },
        dtoList: reporte.map((item) => ({
          ...item,
        })),
        fecha: model.fechaRecepcion.getTime(),
        stockPlayaDestino: {
          calle: model?.ubicacione,
          columna: model?.columna,
          nivel: model?.nivel,
        },
      };

      const { status } = await sipcoAxiosService.post(
        "/recepcion-terceros/recepcion-playa-terceros",
        filters
      );

      if (status === 200) {
        setConfirmProcessDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcesar(false);
    }
  }

  const handleConfirmationClose = () => {
    try {
      setValidFieldDialog(false);
      setConfirmProcessDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [filter, setFilter] = useState({
    vin: null,
    hojaRuta: null,
    proveedor: null,
    puntoOrigen: null,
  });

  const [model, setModel] = useState({
    ubicacione: null,
    columna: null,
    nivel: null,
    fechaRecepcion: new Date(),
  });

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters.securityValues.country && vin !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/vin",
          {
            pais: { id: securityFilters.securityValues.country.id },
            cuenta: { id: securityFilters.securityValues.account.id },
            vin: { id: vin },
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError("1480", "El VIN no existe");
          }
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    setVin([]);
    return [];
  }

  const [loadingHojaRuta, setLoadingHojaRuta] = useState(false);
  const [hojaRuta, setHojaRuta] = useState([]);
  async function loadHojaRuta() {
    setLoadingHojaRuta(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/hoja-ruta",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id || null,
            },
          }
        );

        if (status === 200) {
          setHojaRuta(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHojaRuta(false);
    }
    setHojaRuta([]);
    return [];
  }

  const [loadingProveedor, setLoadingProveedor] = useState(false);
  const [proveedor, setProveedor] = useState([]);
  async function loadProveedor() {
    setLoadingProveedor(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/transportistas",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
          }
        );

        if (status === 200 && data.length > 0) {
          data.unshift({ id: "TODOS", descripcionFull: "TODOS" });
          setProveedor(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedor(false);
    }
    setProveedor([]);
    return [];
  }

  const [loadingPuntoOrigen, setLoadingPuntoOrigen] = useState(false);
  const [puntoOrigen, setPuntoOrigen] = useState([]);
  async function loadPuntoOrigen() {
    setLoadingPuntoOrigen(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/punto-playa",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
          }
        );

        if (status === 200 && data.length > 0) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setPuntoOrigen(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPuntoOrigen(false);
    }
    setPuntoOrigen([]);
    return [];
  }

  const [loadingUbicacione, setLoadingUbicacione] = useState(false);
  const [ubicacione, setUbicacione] = useState([]);
  async function loadUbicacione() {
    setLoadingUbicacione(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/calles",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id || null,
            },
          }
        );

        if (status === 200) {
          setUbicacione(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUbicacione(false);
    }
    setUbicacione([]);
    return [];
  }

  const [loadingColumna, setLoadingColumna] = useState(false);
  const [columna, setColumna] = useState([]);
  async function loadColumna(calle) {
    setLoadingColumna(true);
    try {
      if (calle && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/columnas",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id || null,
            },
            calle: calle,
          }
        );

        if (status === 200) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
    setColumna([]);
    return [];
  }

  const [loadingNivel, setLoadingNivel] = useState(false);
  const [nivel, setNivel] = useState([]);
  async function loadNivel(ubicacione, columna) {
    setLoadingNivel(true);
    try {
      if (ubicacione && columna && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/recepcion-terceros/niveles",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id || null,
            },
            calle: ubicacione,
            columna: columna,
          }
        );

        if (status === 200) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
    setNivel([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const [hojaRutas, proveedores, puntoOrigens, ubicaciones] =
            await Promise.all([
              loadHojaRuta(),
              loadProveedor(),
              loadPuntoOrigen(),
              loadUbicacione(),
            ]);

          setFilter((prevFilter) => ({
            ...prevFilter,
            hojaRuta: hojaRutas?.length > 0 ? hojaRutas[0] : null,
            proveedor: proveedores?.length > 0 ? proveedores[0] : null,
            puntoOrigen: puntoOrigens?.length > 0 ? puntoOrigens[0] : null,
          }));

          setModel((prevModel) => ({
            ...prevModel,
            ubicacione: ubicaciones?.length > 0 ? ubicaciones[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues?.subaccount]);

  useEffect(() => {
    async function updateColumna() {
      if (model?.ubicacione) {
        try {
          const columnas = await loadColumna(model.ubicacione);

          setModel((prevModel) => ({
            ...prevModel,
            columna: columnas?.length > 0 ? columnas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateColumna();
  }, [model?.ubicacione]);

  useEffect(() => {
    async function updateNivel() {
      if (model?.columna) {
        try {
          const niveles = await loadNivel(model.ubicacione, model.columna);

          setModel((prevModel) => ({
            ...prevModel,
            nivel: niveles?.length > 0 ? niveles[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateNivel();
  }, [model?.columna]);

  useEffect(() => {
    async function refreshReportData() {
      if (filter?.hojaRuta && filter?.proveedor && filter?.puntoOrigen) {
        try {
          loadReportData();
        } catch (error) {
          console.error(error);
        }
      }
    }
    refreshReportData();
  }, [filter?.hojaRuta]);

  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/recepcion-terceros/recepcion-terceros",
        {
          pais: { id: securityFilters.securityValues.country.id },
          cuenta: { id: securityFilters.securityValues.account.id },
          subcuenta: { id: securityFilters.securityValues.subaccount.id },
          vin: { id: filter?.vin?.id },
          transportista: { id: filter?.proveedor?.id },
          hojaRuta: { id: filter?.hojaRuta?.id },
          puntoOrigen: { id: filter?.puntoOrigen?.id },
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  return (
    <div className="recepcion-terceros">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          account: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN " />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="id"
                  dropdown
                  forceSelection
                  maxLength={17}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"924"} alt="Hoja de Ruta " />
                </label>
                <SipcoDropdown
                  value={filter.hojaRuta}
                  options={hojaRuta}
                  onChange={(e) => setFilter({ ...filter, hojaRuta: e.value })}
                  optionLabel="id"
                  loading={loadingHojaRuta}
                  virtualScrollerOptions={{ itemSize: 10 }}
                  filter
                />
              </div>
            </div>

            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2521"} alt="Proveedor " />
                </label>
                <SipcoDropdown
                  value={filter.proveedor}
                  options={proveedor}
                  onChange={(e) => setFilter({ ...filter, proveedor: e.value })}
                  optionLabel="descripcionFull"
                  loading={loadingProveedor}
                  virtualScrollerOptions={{ itemSize: 10 }}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"22908"} alt="Punto orígen " />
                </label>
                <SipcoDropdown
                  value={filter.puntoOrigen}
                  options={puntoOrigen}
                  onChange={(e) =>
                    setFilter({ ...filter, puntoOrigen: e.value })
                  }
                  optionLabel="descripcion"
                  loading={loadingPuntoOrigen}
                  virtualScrollerOptions={{ itemSize: 10 }}
                  filter
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        header={<LanguageProvider id="22953" alt="Recepción de Terceros" />}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={16}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="single"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
        >
          <SipcoColumn
            field="pais.descripcion"
            header={<LanguageProvider id="154" alt="País" />}
            sortable
            filter
          />
          <SipcoColumn
            field="cliente.descripcion"
            header={<LanguageProvider id="3750" alt="Cliente" />}
            sortable
            filter
          />
          <SipcoColumn
            field="cuenta.descripcion"
            header={<LanguageProvider id="448" alt="Cuenta" />}
            sortable
            filter
          />
          <SipcoColumn
            field="detalleOs.vin.id"
            header={<LanguageProvider id="1491" alt="VIN" />}
            sortable
            filter
          />
          <SipcoColumn
            field="detalleOs.puntoOrigen.descripcion"
            header={<LanguageProvider id="22908" alt="Punto orígen" />}
            sortable
            filter
          />
          <SipcoColumn
            field="hojaRuta.id"
            header={<LanguageProvider id="2940" alt="Nro. Hoja Ruta" />}
            sortable
            filter
          />
          <SipcoColumn
            field="hojaRuta.administradorTractor.descripcion"
            header={<LanguageProvider id="2521" alt="Proveedor" />}
            sortable
            filter
          />
          <SipcoColumn
            field="detalleOs.caratula.id"
            header={<LanguageProvider id="16563" alt="Nro. Oferta Servicio" />}
            sortable
            filter
          />
        </SipcoDataTable>
      </Panel>

      <div className="datos-recepcion">
        <Panel
          headerTemplate={(props) => {
            return (
              <div className={props.className} onClick={props.onTogglerClick}>
                <span className={props.titleClassName}>
                  <LanguageProvider
                    id="884"
                    alt="Ingreso de Datos para la Recepción"
                  />
                </span>
              </div>
            );
          }}
        >
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"921"} alt="Ubicaciones" />
                </label>
                <SipcoDropdown
                  value={model.ubicacione}
                  options={ubicacione}
                  onChange={(e) => setModel({ ...model, ubicacione: e.value })}
                  optionLabel="descripcionFull"
                  loading={loadingUbicacione}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"991"} alt="Columna" />
                </label>
                <SipcoDropdown
                  value={model.columna}
                  options={columna}
                  onChange={(e) => setModel({ ...model, columna: e.value })}
                  optionLabel="descripcionFull"
                  loading={loadingColumna}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"992"} alt="Nivel" />
                </label>
                <SipcoDropdown
                  value={model.nivel}
                  options={nivel}
                  onChange={(e) => setModel({ ...model, nivel: e.value })}
                  optionLabel="descripcionFull"
                  loading={loadingNivel}
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"889"} alt="Fecha de Recepción" />
                </label>
                <SipcoCalendar
                  value={model.fechaRecepcion}
                  onChange={(e) => {
                    const date = new Date(e.value);
                    setModel({
                      ...model,
                      fechaRecepcion: date,
                    });
                  }}
                  showButtonBar
                  showTime
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
            </div>
          </div>
        </Panel>
      </div>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "34",
            alt: "Procesar o guardar cambios",
          })}
          icon="pi pi-save"
          onClick={btnProcesarClick}
        />
      </div>

      <div className="dialogs">
        {/* Debe seleccionar al menos un registro de la Grilla Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validFieldDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="753"
              alt="Debe seleccionar al menos un registro de la Grilla."
            />
          </p>
        </Dialog>

        {/* Procesar Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={procesarDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("10043", "Si")}
                icon="pi pi-check"
                onClick={() => handleProcesar(true)}
                autoFocus
                disabled={loadingProcesar}
              />
              <Button
                label={getLabel("10044", "No")}
                icon="pi pi-times"
                onClick={() => handleProcesar(false)}
                className="p-button-text"
                disabled={loadingProcesar}
              />
            </div>
          }
          onHide={() => setProcesarDialog(false)}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="13275"
              alt="¿Desea procesar los cambios realizados?"
            />
          </p>
          {loadingProcesar && (
            <ProgressSpinner style={{ width: "50px", height: "50px" }} />
          )}
        </Dialog>

        {/* Confirm Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={confirmProcessDialog}
          style={{ width: "5vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="36"
              alt="Su transacción se realizó con éxito."
            />
          </p>
        </Dialog>
      </div>
    </div>
  );
}
const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
