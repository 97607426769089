import "./generic-abm.scss";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Filter, FilterProps } from "../filter/filter";
import { Panel, PanelHeaderTemplateOptions } from "primereact/panel";
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import GenericAbmFilterItemRenderer from "./componentes/generic-abm-filter-item-renderer";
import GenericAbmFormInputRenderer from "./componentes/generic-abm-form-input-renderer";
import { LanguageProvider } from "../language-provider";
import { LanguageProviderString } from "../language-provider-string";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "../sipco-column";
import SipcoDataTable from "../sipco-datatable";
import { Toast } from "primereact/toast";
import isEqual from "lodash/isEqual";
import useAuth from "@shared/AuthContext";
import { useFilterContext } from "../../../protected-routes";
import { usePrevious } from "primereact/hooks";
import { useGenericAbm } from "@shared/GenericAbmContext";
import logoBase64 from "../../../assets/excel-templates/imageBase64";
export interface GenericAbmProps<TValue> {
  screenCode: string;
  /** Função que controla a criação de novos dados */
  save?: (item: TValue, filterValues: object) => Promise<boolean>;
  /** Função que controla a atualização de dados existentes */
  update?: (item: TValue, filterValues: object) => Promise<boolean>;
  /** Função que controla a remoção dedados */
  remove?: (item: TValue, filterValues: object) => Promise<boolean>;
  /** Função responsável por carregar os dados do dataTable principal */
  list: (filter: any) => Promise<TValue[]>;
  /** Função responsável por consultar os dados da linha selecionada antes da edição dos dados */
  get: (filter: any) => Promise<TValue>;
  /** Lista que realiza a parametrização do formulário de criação e atualização */
  fixedFilters?: any;
  formSettings?: GenericAbmFieldProps[];
  /** @deprecated Parametro deixou de ser utilizado. */
  loadDropdownData?: Record<string, (filter: any) => Promise<any[]>>;
  /** Configura os valores iniciais do formulário quando ele é aberto no modo cadastro */
  defaultValues: TValue;
  /** Titulo da tela  */
  title: string | JSX.Element;
  filterSettings?: GenericAbmFilterProps[];
  securityFilterProps?: FilterProps;
  dataTableColumns: {
    field: string;
    header: JSX.Element | string;
    body?: (rowData: any) => JSX.Element | string;
  }[];
  dataTableRowClassName?: (rowData: any) => string;
  /** Parametro para criar botões customizados para funções fora do fluxo padrão */
  customActions?: JSX.Element;
  /** Paraemtro para criar um formulário customizado quando o form customizado é utilizado deixa de ser usado o parametro de formSettings
   * Ex: Forms complexos e com layouts fora do padrão.
   */
  customForm?: JSX.Element;
  //** Controla o tipo de seleção no dataTable */
  dataTableSelectionMode?: "multiple" | "checkbox";
  dialogClassName?: string;
  dialogStyle?: React.CSSProperties;
}
export interface GenericAbmFieldProps {
  field: string;
  label: React.ReactNode | string;
  type:
    | "inputText"
    | "inputNumber"
    | "dropdown"
    | "multiselect"
    | "checkbox"
    | "calendar"
    | "textArea"
    | "colorpicker"
    | "listbox"
    | "radiobutton";
  disabled?: boolean;
  required?: boolean;
  min?: number;
  max?: number;
  length?: number;
  dataSource?: (filters: any) => Promise<any>;
  dependency?: string[];
  dropDownTemplate?: (option: any, props?: any) => React.JSX.Element;
  dropDownPlaceholder?: string;
  optionLabel?: string;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  /**
   * Campo pelo qual os dados devem ser filtrados.
   * Esta propriedade é opcional e, se fornecida, será usada para determinar
   * o campo específico a ser utilizado no filtro.
   */
  filterBy?: string;
  useGrouping?: boolean;
  /**
   * Força upper case em campos de texto
   */
  capitalizeInputText?: boolean;
  /**
   * Desabilita os campos no modo edição para que o usuário não possa atualizar os valores.
   */
  disableOnUpdate?: boolean;
  /**
   * Ativa a virtualização do dropdown, permitindo a renderização de grandes quantidades de dados.
   */
  virtualizeDropdown?: boolean;
  /**
   * Keyfilter types:
   *
   * - "int": Apenas números inteiros, positivos e negativos.
   * - "pint": Apenas números inteiros positivos.
   * - "num": Números inteiros e decimais, positivos e negativos.
   * - "pnum": Apenas números positivos, inteiros ou decimais.
   * - "money": Números com ponto decimal, podendo ser zero ou positivo.
   * - "hex": Caracteres hexadecimais (0-9, A-F).
   * - "alpha": Apenas letras (sem números ou caracteres especiais).
   * - "alphanum": Letras e números, sem espaços ou caracteres especiais.
   * - "email": Formato de e-mail válido.
   */
  keyfilter?:
    | "int"
    | "pint"
    | "num"
    | "pnum"
    | "money"
    | "hex"
    | "alpha"
    | "alphanum"
    | "email";
  /** Utilizado para criar as opções dentro do tipo radioButton */
  radioButtonOptions?: {
    label: string | React.ReactNode;
    value: any;
    disabled?: boolean;
  }[];
}
export interface GenericAbmFilterProps {
  filter: string;
  label: React.ReactNode | string;
  type: "inputText" | "dropdown" | "multiselect";
  dataSource?: (filter: any) => Promise<any>;
  dropDownTemplate?: (option: any, props?: any) => React.JSX.Element;
  dependency?: string[];
  placeholder?: string;
  required?: boolean;
  optionLabel?: string;
  /**
   * Campo pelo qual os dados devem ser filtrados.
   * Esta propriedade é opcional e, se fornecida, será usada para determinar
   * o campo específico a ser utilizado no filtro.
   */
  filterBy?: string;
  /**
   * Ativa a virtualização do dropdown, permitindo a renderização de grandes quantidades de dados.
   */
  virtualizeDropdown?: boolean;
  /**
   * Keyfilter types:
   * Funcional apenas para campos "inputText"
   * - "int": Apenas números inteiros, positivos e negativos.
   * - "pint": Apenas números inteiros positivos.
   * - "num": Números inteiros e decimais, positivos e negativos.
   * - "pnum": Apenas números positivos, inteiros ou decimais.
   * - "money": Números com ponto decimal, podendo ser zero ou positivo.
   * - "hex": Caracteres hexadecimais (0-9, A-F).
   * - "alpha": Apenas letras (sem números ou caracteres especiais).
   * - "alphanum": Letras e números, sem espaços ou caracteres especiais.
   * - "email": Formato de e-mail válido.
   */
  keyfilter?:
    | "int"
    | "pint"
    | "num"
    | "pnum"
    | "money"
    | "hex"
    | "alpha"
    | "alphanum"
    | "email";
  onChange?: (value: any) => void;
}
export interface GenericAbmRef<TValue> {
  /** Valor atual das opções selecionadas */
  selectedEntries: TValue[];
  /** Função para disparar a consulta do dataTable padrão */
  refreshDataTable: () => void;
  /** Valores atuais carregados no dataTable */
  entries: TValue[];
  /** Valores atuais carregados nos filtros */
  filter: any;
  isDialogOpen: boolean;
}

const GenericAbm = <TValue extends { id: number | string }>(
  props: GenericAbmProps<TValue>,
  ref: React.Ref<GenericAbmRef<TValue>>
) => {
  const {
    list, // Function to fetch data
    screenCode,
    save,
    update,
    remove,
    get,
    formSettings,
    defaultValues,
    title,
    fixedFilters,
    filterSettings,
    securityFilterProps,
    dataTableColumns,
    dataTableRowClassName,
    customActions,
    customForm,
    dialogClassName,
    dialogStyle,
  } = props;

  const { values: securityFilters } = useFilterContext();
  const { user } = useAuth();

  /** Aramazena a informação mostrada no DataTable */
  const [items, setItems] = useState<TValue[]>([]);
  /** Controla a visibilidade do formulário de criação/edição */
  const [showDialog, setShowDialog] = useState(false);
  const [dropdownData, setDropdownData] = useState<Record<string, any[]>>({});
  const [filters, setFilters] = useState<Record<string, any>>({});
  //** Armazena a informação da linha selecionada dentro da tabela */
  const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
  //** Armazena a informação do modelo gernenciado pelo formulário */
  const [formData, setFormData] = useState<TValue>(defaultValues);
  //** Armazena os valores atualizados dos filtros selecionados na tela */
  const [filterValues, setFilterValues] = useState<Record<string, any>>({});
  //** Controla o modo de funcionamento do formulário entre Salvar e Atualizar */
  const [editMode, setEditMode] = useState(false);

  const { setGenericAbmStatesContextData: setGenericAbmStatesData } =
    useGenericAbm<{}>();

  const toast = useRef<Toast>(null);
  const dataTableRef = useRef(null);

  /** Controla o estado de carregamento do DataTable */
  const [loadingDataTable, setLoadingDataTable] = useState(false);

  function handleFilterChange(key: string, value: any) {
    const newValue = { ...filterValues, [key]: value };
    setFilterValues(newValue);
  }
  async function applyFilters() {
    setItems(await handleDataTableLoad());
  }
  async function saveItem() {
    confirmDialog({
      header: LanguageProviderString({ id: "16239", alt: "Atención", user }),
      message: LanguageProviderString({
        id: "13275",
        alt: "¿Desea procesar los cambios realizados?",
        user,
      }),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: async () => {
        const combinedFilters = { ...securityFilters, ...filterValues };
        if (!editMode) {
          const result = await save(formData, combinedFilters);
          if (result) {
            toast.current?.show({
              severity: "success",
              summary: LanguageProviderString({
                id: "TODO",
                alt: "Exito",
                user,
              }),
              detail: LanguageProviderString({
                id: "36",
                alt: "Su transacción se realizó con éxito.",
                user,
              }),
              life: 3000,
            });
          } else {
            toast.current?.show({
              severity: "error",
              summary: LanguageProviderString({
                id: "TODO",
                alt: "Error",
                user,
              }),
              detail: LanguageProviderString({
                id: "16612",
                alt: "error al procesar alta",
                user,
              }),
              life: 3000,
            });
          }
        } else {
          const result = await update(formData, combinedFilters);
          if (result) {
            toast.current?.show({
              severity: "success",
              summary: LanguageProviderString({
                id: "TODO",
                alt: "Exito",
                user,
              }),
              detail: LanguageProviderString({
                id: "15099",
                alt: "Los datos se actualizaron con exito.",
                user,
              }),
              life: 3000,
            });
          } else {
            toast.current?.show({
              severity: "error",
              summary: LanguageProviderString({
                id: "TODO",
                alt: "Error",
                user,
              }),
              detail: LanguageProviderString({
                id: "35",
                alt: "Su transacción no fue realizada.",
                user,
              }),
              life: 3000,
            });
          }
        }
        setShowDialog(false);
        setItems(await handleDataTableLoad());
      },
      reject: null,
      acceptLabel: LanguageProviderString({ id: "10043", alt: "Si", user }),
      rejectLabel: LanguageProviderString({ id: "10044", alt: "No", user }),
    });
  }
  async function deleteItem(item: TValue) {
    confirmDialog({
      header: LanguageProviderString({ id: "16239", alt: "Atención", user }),
      message: LanguageProviderString({
        id: "829",
        alt: "¿Elimina el o los registros?",
        user,
      }),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: async () => {
        const result = await remove(item, filterValues);
        if (result) {
          toast.current?.show({
            severity: "success",
            summary: LanguageProviderString({
              id: "TODO",
              alt: "Exito",
              user,
            }),
            detail: LanguageProviderString({
              id: "15099",
              alt: "Los datos se actualizaron con exito.",
              user,
            }),
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: LanguageProviderString({
              id: "TODO",
              alt: "Error",
              user,
            }),
            detail: LanguageProviderString({
              id: "35",
              alt: "Su transacción no fue realizada.",
              user,
            }),
            life: 3000,
          });
        }
        setItems(await handleDataTableLoad());
      },
      reject: null,
      acceptLabel: LanguageProviderString({ id: "10043", alt: "Si", user }),
      rejectLabel: LanguageProviderString({ id: "10044", alt: "No", user }),
    });
  }
  function handleChange(field: string, value: any) {
    setFormData((prev) => ({ ...prev, [field]: value }));
  }
  function handleFormModelUpdate(value: any) {
    setFormData(value);
  }
  async function handleDataTableLoad() {
    setLoadingDataTable(true);
    setSelectedDataTableItem(null);
    try {
      const resultList = await list({ ...securityFilters, ...filterValues });
      return resultList;
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTable(false);
    }
    return [];
  }

  async function exportDataToExcel() {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(props.screenCode);
      const imageId = workbook.addImage({
        base64: logoBase64,
        extension: "png",
      });
      worksheet.addImage(imageId, {
        tl: { col: 0, row: 0 },
        ext: { width: 1115, height: 40 },
      });
      // Informações básicas na parte superior
      worksheet.mergeCells("A3:G3");
      worksheet.getCell("A3").value = "CEVA LOGISTICS";
      worksheet.getCell("A3").alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      worksheet.mergeCells("A4:G4");
      worksheet.getCell("A4").value = props.screenCode;
      worksheet.getCell("A4").alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      worksheet.mergeCells("A5:G5");
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString(
        user?.idioma?.codigoIso
      );
      const formattedTime = currentDate.toLocaleTimeString(
        user?.idioma?.codigoIso
      );

      worksheet.getCell("A5").value = `${LanguageProviderString({
        user,
        id: "9369",
        alt: "Usuário:",
      })} ${user?.nombre || "User Name"} - ${LanguageProviderString({
        user,
        id: "191",
        alt: "Data:",
      })} ${formattedDate} - ${LanguageProviderString({
        user,
        id: "2201",
        alt: "Hora",
      })}: ${formattedTime}`;
      worksheet.getCell("A5").alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      const dataTableChildren = dataTableRef.current.props.children;
      // Cabeçalho
      const header = dataTableChildren.map((dataTableChildren) => {
        return LanguageProviderString({
          user: user,
          id: dataTableChildren.props.header.props.id,
          alt: dataTableChildren.props.header.props.alt,
        });
      });
      worksheet.addRow(header);

      // Estilizar cabeçalho
      const headerRow = worksheet.getRow(6);
      headerRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "003366" },
        };
        cell.font = { bold: true, color: { argb: "FFFFFF" } };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

      //Valores
      if (items?.length > 0) {
        for (const row of items) {
          let values = [];
          for (const collumn of dataTableChildren) {
            values.push(row[collumn.props.field]);
          }
          worksheet.addRow(values);
        }
      }

      // Adicionar linhas em branco e resumo
      worksheet.addRow([]);
      worksheet.addRow([
        `${LanguageProviderString({
          user,
          id: "3116",
          alt: "Quantidade de Registros",
        })}:`,
        items?.length,
      ]);

      try {
        // Gerar e salvar o arquivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const EXCEL_EXTENSION = ".xlsx";
        saveAs(
          new Blob([buffer]),
          `${props.screenCode}_export${EXCEL_EXTENSION}`
        );
      } catch (error) {
        console.error("Erro ao exportar arquivo Excel:", error);
      }
    } catch (error) {
      console.error(error);
    }
  }

  /** Componente responsável pela renderização do header da tabela */
  const DataTableHeader = () => {
    return (
      <div className="flex flex-wrap  justify-content-end gap-2">
        {customActions}
        <Button
          onClick={exportDataToExcel}
          icon="pi pi-file-excel"
          iconPos="right"
          raised
          tooltip={LanguageProvider({ id: "42", alt: "Excel" })}
          tooltipOptions={{ position: "bottom" }}
        />
        {remove && (
          <Button
            label={LanguageProvider({ id: "21880", alt: "Borrar" })}
            onClick={() =>
              deleteItem(
                props.dataTableSelectionMode === "checkbox" ||
                  props.dataTableSelectionMode === "multiple"
                  ? selectedDataTableItem
                  : selectedDataTableItem[0]
              )
            }
            icon="pi pi-trash"
            iconPos="right"
            raised
            tooltip={LanguageProvider({ id: "21880", alt: "Borrar" })}
            tooltipOptions={{ position: "bottom" }}
            disabled={selectedDataTableItem === null}
          />
        )}
        {update && (
          <Button
            label={LanguageProvider({ id: "19267", alt: "Modificar" })}
            onClick={async () => {
              const model = await get(
                props.dataTableSelectionMode === "checkbox" ||
                  props.dataTableSelectionMode === "multiple"
                  ? selectedDataTableItem
                  : selectedDataTableItem[0]
              );
              setFormData(model);
              setEditMode(true);
              setShowDialog(true);
            }}
            icon="pi pi-pencil"
            iconPos="right"
            raised
            tooltip={LanguageProvider({ id: "19267", alt: "Modificar" })}
            tooltipOptions={{ position: "bottom" }}
            disabled={selectedDataTableItem === null}
          />
        )}
        {save && (
          <Button
            label={LanguageProvider({ id: "25", alt: "Alta" })}
            onClick={() => {
              setFormData(defaultValues);
              setEditMode(false);
              setShowDialog(true);
            }}
            icon="pi pi-plus"
            iconPos="right"
            raised
            tooltip={LanguageProvider({ id: "25", alt: "Alta" })}
            tooltipOptions={{ position: "bottom" }}
          />
        )}
      </div>
    );
  };
  /** Componente responsável pela renderização do header do panel */
  const PanelHeaderTemplate = (options: PanelHeaderTemplateOptions) => {
    const className = `${options.className} flex justify-content-space-between`;

    return (
      <div className={className}>
        <span className="text-xl text-900 font-bold">{title}</span>
        <Button
          label="Buscar"
          onClick={applyFilters}
          icon={"pi pi-search"}
          iconPos="right"
        />
      </div>
    );
  };

  useEffect(() => {
    async function loadData() {
      try {
        let newFilters: Record<string, any[]> = { ...filters };
        let newFilterValues: Record<string, any> = { ...filterValues };
        let newDropdownData: Record<string, any[]> = {};
        if (!isEqual(newFilters, filters)) {
          setFilters(newFilters);
        }
        if (!isEqual(newFilterValues, filterValues)) {
          setFilterValues(newFilterValues);
        }
        if (!isEqual(newDropdownData, dropdownData)) {
          setDropdownData(newDropdownData);
        }
      } catch (error) {
        console.error(error);
      }
    }

    loadData();
  }, [filterValues, filters, dropdownData]);

  ScreenCodeValue(screenCode);

  const combinedFilters = { ...securityFilters, ...filterValues };
  const prevCombinedFilters = usePrevious(combinedFilters);

  /** Cria variaves memoriazadas dos valores
   * para evitar loops de renderização nos campos dinamicos */
  const memoizedFormData = useMemo(() => formData, [formData]);
  const memoizedCombinedFilters = useMemo(
    () => combinedFilters,
    [combinedFilters]
  );
  const memoizedPrevCombinedFilters = useMemo(
    () => prevCombinedFilters,
    [prevCombinedFilters]
  );

  const dialogClassNames = ["generic-abm-dialog", dialogClassName ?? ""];

  useImperativeHandle(ref, () => ({
    selectedEntries: selectedDataTableItem,
    refreshDataTable: async () => {
      setItems(await handleDataTableLoad());
    },
    entries: items,
    filter: combinedFilters,
    isDialogOpen: showDialog,
  }));

  /**
   * Hook useEffect que atualiza o estado global do contexto GenericAbmContext.
   *
   * Este hook é executado sempre que os valores de showDialog, selectedDataTableItem,
   * items, securityFilters ou filterValues mudarem. Ele define o estado global com
   * os seguintes valores:
   *
   * - selectedEntries: os itens selecionados na tabela de dados.
   * - refreshDataTable: uma função assíncrona que recarrega os dados da tabela.
   * - entries: os itens atuais carregados na tabela de dados.
   * - filter: os filtros combinados de securityFilters e filterValues.
   * - isDialogOpen: um booleano que indica se o diálogo está aberto.
   */
  useEffect(() => {
    setGenericAbmStatesData({
      selectedEntries: selectedDataTableItem,
      refreshDataTable: async () => {
        setItems(await handleDataTableLoad());
      },
      entries: items,
      filter: { ...securityFilters, ...filterValues },
      isDialogOpen: showDialog,
      editMode: editMode,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialog, selectedDataTableItem, items, securityFilters, filterValues]);

  return (
    <div className="generic-abm">
      {securityFilterProps ? (
        <Filter {...securityFilterProps}>
          {filterSettings && (
            <AccordionTab
              header={<LanguageProvider id={"743"} alt="Filtros" />}
            >
              <div className="form">
                <div className="form-row">
                  {filterSettings.map((filter: GenericAbmFilterProps) => {
                    return (
                      <GenericAbmFilterItemRenderer
                        key={filter.filter}
                        settings={filter}
                        prevFilterValues={memoizedPrevCombinedFilters}
                        currentFilterValues={memoizedCombinedFilters}
                        onChange={(e) => handleFilterChange(filter.filter, e)}
                      />
                    );
                  })}
                </div>
              </div>
            </AccordionTab>
          )}
        </Filter>
      ) : (
        filterSettings && (
          <Accordion multiple activeIndex={0}>
            <AccordionTab
              header={<LanguageProvider id={"743"} alt="Filtros" />}
            >
              <div className="form">
                <div className="form-row">
                  {filterSettings.map((filter: GenericAbmFilterProps) => {
                    return (
                      <GenericAbmFilterItemRenderer
                        key={filter.filter}
                        settings={filter}
                        prevFilterValues={memoizedPrevCombinedFilters}
                        currentFilterValues={memoizedCombinedFilters}
                        onChange={(e) => handleFilterChange(filter.filter, e)}
                      />
                    );
                  })}
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        )
      )}
      <Panel className="generic-abm-panel" headerTemplate={PanelHeaderTemplate}>
        <SipcoDataTable
          ref={dataTableRef}
          value={items}
          responsiveLayout="scroll"
          header={DataTableHeader}
          rowClassName={dataTableRowClassName}
          selectionMode={props.dataTableSelectionMode ?? "checkbox"}
          selection={selectedDataTableItem}
          onSelectionChange={(e) => setSelectedDataTableItem(e.value)}
          loading={loadingDataTable}
          rows={10}
        >
          {dataTableColumns.map((dataTableColumn, key) => (
            <SipcoColumn
              key={key}
              field={dataTableColumn.field}
              header={dataTableColumn.header}
              body={dataTableColumn.body}
              filter
              sortable
            />
          ))}
        </SipcoDataTable>
      </Panel>
      <Dialog
        className={dialogClassNames.join(" ")}
        style={dialogStyle}
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        header={
          editMode ? (
            <LanguageProvider id="generic.abm.edit.entry.dialog.title" />
          ) : (
            <LanguageProvider id="generic.abm.add.entry.dialog.title" />
          )
        }
        footer={() => (
          <div className="flex gap-2 justify-content-end">
            <Button
              label={LanguageProvider({ id: "3155", alt: "Cancelar" })}
              icon="pi pi-times"
              onClick={() => setShowDialog(false)}
            />
            <Button
              label={LanguageProvider({ id: "9491", alt: "Aceptar" })}
              icon="pi pi-check"
              onClick={saveItem}
            />
          </div>
        )}
      >
        <div className="form">
          {(customForm ?? formSettings) ? (
            formSettings.map((settings: GenericAbmFieldProps) => {
              return (
                <GenericAbmFormInputRenderer
                  key={settings.field}
                  settings={settings}
                  onChange={handleChange}
                  updateFormModel={handleFormModelUpdate}
                  filterValues={memoizedCombinedFilters}
                  value={formData[settings.field]}
                  formModel={memoizedFormData}
                  isEditMode={editMode}
                />
              );
            })
          ) : (
            <></>
          )}
        </div>
      </Dialog>
      <ConfirmDialog />
      <Toast ref={toast} position="top-right" />
    </div>
  );
};

export default React.forwardRef(GenericAbm) as <
  TValue extends { id: number | string },
>(
  props: GenericAbmProps<TValue> & { ref?: React.Ref<GenericAbmRef<TValue>> }
) => JSX.Element;
