import "./RepuestosComprasTaller.scss";
import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { useLocation } from "react-router-dom";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { format } from "date-fns";
import { HttpStatusCode } from "axios";
import ModalVerComprasTaller from "./ModalVerComprasTaller";
import ModalAltaComprasTaller from "./ModalAltaComprasTaller";
import ModalModificarComprasTaller from "./ModalModificarComprasTaller";

export function RepuestosComprasTaller() {
  const SCREEN_CODE = "abm0110_detalle_compras_taller";
  ScreenCodeValue(SCREEN_CODE);

  const [alta, setAlta] = useState(false);
  const [modificar, setModificar] = useState(false);
  const [ver, setVer] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  function compareArrays(selectRows: any[], arrayBase: any[]): any[] {
    const result = [];
    if (selectRows.length > 0 && arrayBase.length > 0) {
      selectRows.forEach((row: any) => {
        const vinValue = row.linea;

        const matchingObject = arrayBase.find(
          (item: any) => item?.id === vinValue
        );

        if (matchingObject) {
          result.push(matchingObject);
        }
      });
    }
    return result;
  }

  const showModalVer = () => {
    if (selectedRows.length === 0) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: <LanguageProvider id={"753"} alt="Debe seleccionar al menos un registro de la Grilla." />,
        life: 3000,
      });
    } else {
      setVer(true);
    }
  };

  const hideModalVer = () => {
    setVer(false);
  };

  const showModalModificar = () => {
    if (selectedRows.length === 0) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: <LanguageProvider id={"753"} alt="Debe seleccionar al menos un registro de la Grilla." />,
        life: 3000,
      });
    } else {
      setModificar(true);
    }
  };

  const hideModalModificar = () => {
    setModificar(false);
  };

  const showModalAlta = () => {

    setAlta(true);

  };

  const hideModalAlta = () => {
    setAlta(false);
  };

  const location = useLocation();
  const { pais, cuenta, tallerFilter, subcuenta, codCompra } =
    location.state || {};

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const columns = [];

  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [removeLabel, setRemoveLabel] = useState([]);
  const [loadingRemoveLabel, setLoadingRemoveLabel] = useState(false);

  function barrar() {
    return confirmDialog({
      header: <LanguageProvider id={"16239"} alt="Atención" />,
      message: (
        <LanguageProvider id={"829"} alt="¿Elimina el o los registros?" />
      ),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => remove(),
      reject: () => console.log("aceptar"),
    });
  }

  const [filter, setFilter] = useState({
    checkVinTaller: false,
    taller: "",
    estados: null,
    vin: null,
    checkFueraTaller: false,
    desde: new Date(),
    hasta: new Date(),
  });

  const [repuestoComprasTaller, setRepuestoComprasTaller] = useState([]);
  async function loadReportData() {
    setLoading(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/detalles-compras-taller/find",
        {
          pais: pais,
          cuenta: cuenta,
          subcuenta: subcuenta,
          taller: tallerFilter,
          compraTaller: {
            id: codCompra[0],
          },
        }
      );
      if (status === 200) {
        const response = data.map((x: any) => {
          return {
            ...x,
            linea: x?.linea,
            proveedor: x?.clienteRepuesto?.descripcionFull,
            codPieza: x?.pieza?.descripcionFull,
            cantidad: x?.cantidad,
            cantidadEntregada: x?.cantidadEntregada,
            importe: x?.importe,
            descuento: x?.descuento?.descripcion,
            importeNeto: x?.importeNeto,
            origen: x?.contableOrigen?.descripcionFull,
            prestacion: x?.prestacion,
            subprestacion: x?.subprestacion,
          };
        });
        setReporte(response);
        setRepuestoComprasTaller(data);
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsNamesTable = [
    { field: "linea", header: "Linea" },
    { field: "proveedor", header: "Proveedor" },
    { field: "codPieza", header: "Código Pieza" },
    { field: "cantidad", header: "Cantidad" },
    { field: "cantidadEntregada", header: "Cantidad Entregada" },
    { field: "importe", header: "Importe" },
    { field: "descuento", header: "Descuento (%)" },
    { field: "importeNeto", header: "Importe Neto" },
    { field: "origen", header: "Origen" },
    { field: "prestacion", header: "Prestación" },
    { field: "subprestacion", header: "Sub Prestación" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };

  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  useEffect(() => {
    const init = async () => {
      try {
        await loadReportData();
      } catch (error) {
        console.log(error);
      }
    };

    init();
  }, [pais || tallerFilter || cuenta || subcuenta || codCompra]);

  const data = convertDateObjects(reporte?.filas);

  const [loadingUpdateLabel, setLoadingUpdateLabel] = useState(false);
  async function remove() {
    setLoadingUpdateLabel(true);
    try {
      if (selectedRows.length === 0) {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: <LanguageProvider id={"753"} alt="Debe seleccionar al menos un registro de la Grilla." />,
          life: 3000,
        });
      } else {
        if (compareArrays(selectedRows, repuestoComprasTaller)) {
          const { status, data } = await sipcoAxiosService.post(
            "/detalles-compras-taller/remove",
            {
              detalleCompraTaller: {
                activo: true,
                pais: pais,
                cuenta: cuenta,
                subcuenta: subcuenta,
                taller: tallerFilter,
                linea: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.linea)[0] || null,
                codori: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.codori)[0] || null,
                importe: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.importe)[0] || 0,
                fechaUltimaModificacion: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.fechaUltimaModificacion)[0] || null,
                usuarioUltimaModificacion: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.usuarioUltimaModificacion)[0] || null,
                detalleOrdenRepuesto: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.detalleOrdenRepuesto)[0] || null,
                importeNeto: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.importeNeto)[0] || 0,
                prestacion: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.prestacion)[0] || null,
                subprestacion: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.subprestacion)[0] || null,
                cantidad: Number(compareArrays(selectedRows, repuestoComprasTaller).map(x => x.cantidad)[0]) || null,
                id: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.id)[0],
                descuento: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.descuento)[0] || null,
                clienteRepuesto: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.clienteRepuesto)[0] || null,
                pieza: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.pieza)[0] || 0,
                cantidadEntregada: Number(compareArrays(selectedRows, repuestoComprasTaller).map(x => x.cantidadEntregada)[0]) || null,
                compraTaller: {
                  id: compareArrays(selectedRows, repuestoComprasTaller).map(x => x.compraTaller?.id)[0],
                  pais: pais,
                  cuenta: cuenta,
                  subcuenta: subcuenta,
                  taller: tallerFilter,
                },
              },
            }
          );
          if (status === HttpStatusCode.Ok) {
            setRemoveLabel(data);
            return data;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUpdateLabel(false);
    }
  }

  return (
    <div className="repuestos-compras-taller">

      <ModalAltaComprasTaller visible={alta}
        onClose={hideModalAlta}
        selected={compareArrays(selectedRows, repuestoComprasTaller)} />

      <ModalModificarComprasTaller
        visible={modificar}
        onClose={hideModalModificar}
        selected={compareArrays(selectedRows, repuestoComprasTaller)}
      />

      <ModalVerComprasTaller
        visible={ver}
        onClose={hideModalVer}
        selected={compareArrays(selectedRows, repuestoComprasTaller)} />

      <ConfirmDialog />

      <Toast ref={toast} position="top-center" />

      <Filter>

        <AccordionTab header={<LanguageProvider id={"65"} alt="Seguridad" />}>
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"5044"} alt="País" />
                </label>
                <InputText value={pais?.descripcion} disabled={true} />
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <InputText value={tallerFilter?.descripcion} disabled={true} />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"24328"} alt="Cuenta" />
                </label>
                <InputText value={cuenta?.descripcion} disabled={true} />
                <label>
                  <LanguageProvider id={"16949"} alt="Cód. Compra" />
                </label>
                <InputText value={codCompra} disabled={true} />
              </div>
              <div className={"sipco-option"} style={{ marginBottom: "5em" }}>
                <label>
                  <LanguageProvider id={"441"} alt="Playas" />
                </label>
                <InputText value={subcuenta?.descripcion} disabled={true} />
              </div>
            </div>
          </div>
        </AccordionTab>

      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Listado</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              <div>
                <Button
                  label={LanguageProvider({ id: "25", alt: "Alta" })}
                  text
                  onClick={showModalAlta}
                />
                <Button
                  label={LanguageProvider({ id: "19267", alt: "Modificar" })}
                  text
                  onClick={showModalModificar}
                />
                <Button
                  label={LanguageProvider({ id: "12899", alt: "Ver" })}
                  text
                  onClick={showModalVer}
                />
                <Button
                  label={LanguageProvider({ id: "2016", alt: "Borrar" })}
                  text
                  onClick={barrar}
                />
              </div>
            </div>
          );
        }}
      >

        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e: any) => setSelectedRows(e.value)}
          metaKeySelection={true}
          dragSelection
        >
          {columnsNamesTable.map((colum, index) => {
            if (colum.header === "Fecha Compra") {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </DataTable>
      </Panel>
    </div>
  );
}
export default RepuestosComprasTaller;
