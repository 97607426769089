import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Panel } from "primereact/panel";
import SipcoCalendar from "@shared/components/sipco-calendar";
import { ProgressSpinner } from "primereact/progressspinner";

interface MantenerValesDeRepuestosDialogProps {
  visible: boolean;
  mode: any;
  reporte: any;
  selectedReporte: any;
  model: any;
  refreshData: () => void;
  onClose: () => void;
}

const MantenerValesDeRepuestosDialog: React.FC<
  MantenerValesDeRepuestosDialogProps
> = ({
  visible,
  mode,
  reporte,
  selectedReporte,
  model: taller,
  refreshData,
  onClose,
}) => {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const [loadingconfirmModDialog, setLoadingconfirmModDialog] = useState(false);
  const [confirmModificDialog, setConfirmModificDialog] = useState(false);
  const [ordenCompAscDialog, setOrdenCompAscDialog] = useState(false);
  const [sucessDialog, setSucessDialog] = useState(false);

  const [modelValeRepuestos, setModelValeRepuestos] = useState({
    nroVale: "",
    estado: "1",
    fechaAlta: new Date(),
    nroOrden: null,
    observaciones: "",
  });

  const btnAceptarClick = () => {
    try {
      //insert
      if (mode === 0) {
        validaAlta();
      }
      //update
      if (mode === 1) {
        setConfirmModificDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function validaAlta() {
    setLoadingconfirmModDialog(true);
    try {
      const filters = {
        valeRepuesto: {
          pais: { id: securityFilters.securityValues.country.id },
          cuenta: { id: securityFilters.securityValues.account.id },
          subcuenta: { id: securityFilters.securityValues.subaccount.id },
          id: modelValeRepuestos.nroVale,
          taller: { id: taller?.id },
          observaciones: modelValeRepuestos?.observaciones,
          ordenReparacion: { id: modelValeRepuestos?.nroOrden?.id },
          fechaVale: modelValeRepuestos.fechaAlta.getTime(),
        },
      };

      const { status } = await sipcoAxiosService.post(
        "/mantener-vales-repuestos/validar-alta-vale-repuesto",
        filters
      );

      if (status === 200) {
        alta();
      } else if (status === 401) {
        setOrdenCompAscDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingconfirmModDialog(false);
    }
  }

  async function alta() {
    setLoadingconfirmModDialog(true);
    try {
      const filters = {
        valeRepuesto: {
          pais: { id: securityFilters.securityValues.country.id },
          cuenta: { id: securityFilters.securityValues.account.id },
          subcuenta: { id: securityFilters.securityValues.subaccount.id },
          id: modelValeRepuestos.nroVale,
          estado: { id: modelValeRepuestos.estado },
          taller: { id: taller?.id },
          observaciones: modelValeRepuestos?.observaciones,
          ordenReparacion: { id: modelValeRepuestos?.nroOrden?.id },
          fechaVale: modelValeRepuestos.fechaAlta.getTime(),
        },
      };

      const { status } = await sipcoAxiosService.post(
        "/mantener-vales-repuestos/save-vale-repuesto",
        filters
      );
      if (status === 200) {
        setSucessDialog(true);
        refreshData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingconfirmModDialog(false);
    }
  }

  async function modificar() {
    setLoadingconfirmModDialog(true);
    try {
      const filters = {
        valeRepuesto: {
          pais: { id: securityFilters.securityValues.country.id },
          cuenta: { id: securityFilters.securityValues.account.id },
          subcuenta: { id: securityFilters.securityValues.subaccount.id },
          id: modelValeRepuestos.nroVale,
          taller: { id: taller?.id },
          observaciones: modelValeRepuestos?.observaciones,
          fechaVale: modelValeRepuestos.fechaAlta.getTime(),
        },
      };

      const { status } = await sipcoAxiosService.post(
        "/mantener-vales-repuestos/update-vale-repuesto",
        filters
      );
      if (status === 200) {
        setSucessDialog(true);
        refreshData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingconfirmModDialog(false);
    }
  }

  const handleModificar = (isConfirmed) => {
    setConfirmModificDialog(false);
    if (isConfirmed) {
      modificar();
    }
  };

  const handleClose = () => {
    try {
      //setCallCerrar(true);
      //cerrarClearFields();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmationClose = () => {
    try {
      if (ordenCompAscDialog) {
        setOrdenCompAscDialog(false);
        return;
      }
      setSucessDialog(false);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const [loadingNroOrden, setLoadingNroOrden] = useState(false);
  const [nroOrden, setNroOrden] = useState([]);
  async function loadNroOrden() {
    setLoadingNroOrden(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/mantener-vales-repuestos/numero-orden-reparacion",
          {
            pais: { id: securityFilters.securityValues.country.id },
            cuenta: { id: securityFilters.securityValues.account.id },
            subcuenta: { id: securityFilters.securityValues.subaccount.id },
            taller: { id: taller?.id },
          }
        );

        if (status === 200) {
          const nroOrdenData = data.map((x) => {
            return { ...x, label: `${x.vin.id} - (${x.id})` };
          });
          setNroOrden(nroOrdenData);
          return nroOrdenData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNroOrden(false);
    }
    setNroOrden([]);
    return [];
  }

  const [loadingNroOrdenSelect, setLoadingNroOrdenSelect] = useState(false);
  const [nroOrdenSelect, setNroOrdenSelect] = useState([]);
  async function loadNroOrdenSelect() {
    setLoadingNroOrdenSelect(true);
    try {
      if (selectedReporte?.ordenReparacion?.vin?.id) {
        const selectedData = [
          {
            label: `${selectedReporte.ordenReparacion.vin.id} - (${selectedReporte.ordenReparacion.id})`,
          },
        ];
        setNroOrdenSelect(selectedData);
        return selectedData;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNroOrdenSelect(false);
    }
    setNroOrdenSelect([]);
    return [];
  }

  useEffect(() => {
    async function handleNroOrden() {
      try {
        if (mode === 0 && taller) {
          const nroOrdens = await loadNroOrden();

          setModelValeRepuestos((prevFilter) => ({
            ...prevFilter,
            nroOrden: nroOrdens?.length > 0 ? nroOrdens[0] : [],
          }));
        }

        if (mode === 1 && taller) {
          const nroOrdensSelect = await loadNroOrdenSelect();

          setModelValeRepuestos((prevFilter) => ({
            ...prevFilter,
            nroOrden: nroOrdensSelect?.length > 0 ? nroOrdensSelect[0] : [],
          }));
        }
      } catch (error) {
        console.error(error);
      }
    }
    handleNroOrden();
  }, [mode, taller, selectedReporte]);

  useEffect(
    function updateModelValeRepuestos() {
      try {
        if (mode === 0) {
          const ultNroVale = reporte[reporte.length - 1]?.id || 0;
          setModelValeRepuestos((prev) => ({
            ...prev,
            nroVale: (ultNroVale + 1).toString(),
            observaciones: "",
          }));
        }

        if (mode === 1) {
          setModelValeRepuestos((prev) => ({
            ...prev,
            nroVale: selectedReporte?.id || "",
            observaciones: selectedReporte?.observaciones || "",
          }));
        }
      } catch (error) {
        console.error(error);
      }
    },
    [mode, selectedReporte]
  );

  return (
    <Dialog
      className="mantener-vales-de-repuestos-dialog"
      visible={visible}
      onHide={onClose}
      style={{
        width: "clamp(200px, 23vw, 500px)",
        height: "clamp(380px, 55vw, 690px)",
      }}
      footer={() => {
        return (
          <div className="dialog-footer">
            <Button
              label={LanguageProvider({ id: "23827", alt: "Aceptar" })}
              icon="pi pi-check"
              onClick={btnAceptarClick}
              autoFocus
            />
            <Button
              label={LanguageProvider({ id: "3155", alt: "Cancelar" })}
              icon="pi pi-times"
              className="p-button-text"
              onClick={handleClose}
            />
          </div>
        );
      }}
    >
      <Panel
        header={
          <>
            <LanguageProvider id="2335" alt="Mantener Vales de Repuestos" /> -{" "}
            {mode === 0 ? (
              <LanguageProvider id="23824" alt="Alta" />
            ) : mode === 1 ? (
              <LanguageProvider id="19267" alt="Modificar" />
            ) : (
              ""
            )}
          </>
        }
      >
        <div className="form">
          <div className="form-row">
            <div className="sipco-option">
              <label>
                <LanguageProvider id="12231" alt="Nro. de Vale" />
              </label>
              <SipcoInputText
                value={modelValeRepuestos.nroVale}
                onChange={(e) =>
                  setModelValeRepuestos({
                    ...modelValeRepuestos,
                    nroVale: e.target.value,
                  })
                }
                disabled={true}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="sipco-option">
              <label>
                <LanguageProvider id="20804" alt="Estado" />
              </label>
              <SipcoInputText
                value={modelValeRepuestos.estado}
                onChange={(e) =>
                  setModelValeRepuestos({
                    ...modelValeRepuestos,
                    estado: e.target.value,
                  })
                }
                disabled={true}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="sipco-option">
              <label>
                <LanguageProvider id="15739" alt="Fecha Alta" />
              </label>
              <SipcoCalendar
                value={
                  mode === 0
                    ? new Date()
                    : selectedReporte?.fechaVale?.date
                      ? new Date(selectedReporte.fechaVale.date)
                      : new Date()
                }
                onChange={(e) =>
                  setModelValeRepuestos({
                    ...modelValeRepuestos,
                    fechaAlta: e.target.value,
                  })
                }
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="sipco-option">
              <label>
                <LanguageProvider id="5701" alt="Nro. Orden" />
              </label>
              <SipcoDropdown
                value={modelValeRepuestos.nroOrden}
                options={mode === 0 ? (nroOrden ?? []) : (nroOrdenSelect ?? [])}
                onChange={(e) => {
                  if (e?.target?.value) {
                    setModelValeRepuestos({
                      ...modelValeRepuestos,
                      nroOrden: e.target.value,
                    });
                  }
                }}
                virtualScrollerOptions={{ itemSize: 10 }}
                optionLabel="label"
                loading={mode === 0 ? loadingNroOrden : loadingNroOrdenSelect}
                filter
                disabled={mode === 1}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="sipco-option-obs">
              <label>
                <LanguageProvider id="9306" alt="Observaciones" />
              </label>
              <SipcoInputText
                value={modelValeRepuestos.observaciones}
                onChange={(e) =>
                  setModelValeRepuestos({
                    ...modelValeRepuestos,
                    observaciones: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </Panel>

      <div className="dialogs">
        {/* Confirma las modificaciones Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={confirmModificDialog}
          style={{ width: "12vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("10043", "Si")}
                icon="pi pi-check"
                onClick={() => handleModificar(true)}
                autoFocus
                disabled={loadingconfirmModDialog}
              />
              <Button
                label={getLabel("10044", "No")}
                icon="pi pi-times"
                onClick={() => handleModificar(false)}
                className="p-button-text"
                disabled={loadingconfirmModDialog}
              />
            </div>
          }
          onHide={() => setConfirmModificDialog(false)}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider id="28" alt="¿Confirma las modificaciones?" />
          </p>
          {loadingconfirmModDialog && (
            <ProgressSpinner style={{ width: "50px", height: "50px" }} />
          )}
        </Dialog>

        {/* Existe una Orden de Compra Asociada Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={ordenCompAscDialog}
          style={{ width: "5vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="2337"
              alt="Existe una Orden de Compra Asociada"
            />
          </p>
        </Dialog>

        {/* Sucess Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={sucessDialog}
          style={{ width: "5vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="36"
              alt="Su transacción se realizó con éxito."
            />
          </p>
        </Dialog>
      </div>
    </Dialog>
  );
};
export default MantenerValesDeRepuestosDialog;

const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
