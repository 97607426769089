import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { MetodoRecomendacionVin } from "src/models/MetodoRecomendacionVin";

const MantenerRecomendacionVin = () => {
  const SCREEN_CODE = "abm0213_metodo_recomendacion_vin";
  const URL_BASE = "/metodo-recomendacion-vin";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: MetodoRecomendacionVin, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const metodoRecomendacionVin = {
          ...model,
          pais: filterValues?.securityValues?.country,
          cuenta: filterValues?.securityValues?.account,
          subcuenta: filterValues?.securityValues?.subaccount,
          grupoOperacion: model.grupoOperacion,
          metodo: model.metodo,
          activo: model.activo,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          metodoRecomendacionVin,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: MetodoRecomendacionVin, filterValues: any) {
    try {
      const metodoRecomendacionVin = {
        ...model,
        pais: filterValues?.securityValues?.country,
        cuenta: filterValues?.securityValues?.account,
        subcuenta: filterValues?.securityValues?.subaccount,
        grupoOperacion: model.grupoOperacion,
        metodo: model.metodo,
        activo: model.activo,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        metodoRecomendacionVin,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: MetodoRecomendacionVin, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        metodoRecomendacionVin: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<MetodoRecomendacionVin[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cuenta: filter.securityValues.account,
          subcuenta: filter.securityValues.subaccount,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<MetodoRecomendacionVin> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          metodoRecomendacionVin: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  // Forms Modail 
  async function loadGrupoOperacion(filter: any): Promise<MetodoRecomendacionVin> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/form/grupo-operacion",
        {
          pais: filter?.securityValues?.country,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadMetodo(filter: any): Promise<MetodoRecomendacionVin> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/form/metodo",
        {
          pais: filter?.securityValues?.country,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }


  return (
    <GenericAbm<MetodoRecomendacionVin>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          account: true,
          subaccount: true,
        },
      }}
      dataTableColumns={[
        {
          field: "grupoOperacion.descripcion",
          header: <LanguageProvider id="1799" alt="Grupo" />,
        },
        {
          field: "metodo.descripcion",
          header: <LanguageProvider id="19057" alt="Método" />,
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="2131" alt="Fecha" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
        cuenta: null,
        grupoOperacion: null,
        metodo: null,
        subcuenta: null,
      }}
      formSettings={[
        {
          field: "grupoOperacion",
          label: <LanguageProvider id="1799" alt="Grupo" />,
          type: "dropdown",
          dataSource: filter => loadGrupoOperacion(filter),
          optionLabel: "descripcion",
          disableOnUpdate: true,
        },
        {
          field: "metodo",
          label: <LanguageProvider id="19057" alt="Método" />,
          type: "dropdown",
          dataSource: filter => loadMetodo(filter),
          optionLabel: "descripcion",
        },
        {
          field: "Activo",
          label: <LanguageProvider id="23849" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerRecomendacionVin;
