import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Chofer } from "src/models/Chofer";

const MantenerChoferes = () => {
  const SCREEN_CODE = "abm0232_mantener_choferes";
  const URL_BASE = "/mantener-choferes";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: Chofer, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const chofer = {
          ...model,
          pais: filterValues?.securityValues?.country,
          nombre: model.nombre,
          apellido: model.apellido,
          tipoDocumento: model.tipoDocumento,
          numeroDocumento: model.numeroDocumento,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          chofer,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: Chofer, filterValues: any) {
    try {
      const chofer = {
        ...model,
        pais: filterValues?.securityValues?.country,
        nombre: model.nombre,
        apellido: model.apellido,
        tipoDocumento: model.tipoDocumento,
        numeroDocumento: model.numeroDocumento,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        chofer,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Chofer, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        chofer: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Chofer[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter.securityValues.country,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<Chofer> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          chofer: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <GenericAbm<Chofer>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
        },
      }}
      dataTableColumns={[
        {
          field: "id",
          header: <LanguageProvider id="6590" alt="Código" />,
        },
        {
          field: "nombre",
          header: <LanguageProvider id="23900" alt="Nombre" />,
        },
        {
          field: "apellido",
          header: <LanguageProvider id="23906" alt="Apellido" />,
        },
        {
          field: "tipoDocumento",
          header: <LanguageProvider id="5367" alt="Tipo de Documento" />,
        },
        {
          field: "numeroDocumento",
          header: <LanguageProvider id="3627" alt="Nro. Documento" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: null,
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        usuarioUltimaModificacion: null,
        administrador: null,
        apellido: "",
        ciudad: null,
        cuil: "",
        direccion: "",
        fechaAlta: null,
        fechaBaja: null,
        localidad: null,
        nombre: "",
        numeroDocumento: "",
        proveedor: null,
        reciboSueldo: false,
        telefono1: "",
        telefono2: "",
        tipoDocumento: "",
        provincia: null,
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          disabled: true,
        },
        {
          field: "nombre",
          label: <LanguageProvider id="23900" alt="Nombre" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "apellido",
          label: <LanguageProvider id="23906" alt="Apellido" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "tipoDocumento",
          label: <LanguageProvider id="5367" alt="Tipo de Documento" />,
          type: "inputText",
          length: 3,
        },
        {
          field: "numeroDocumento",
          label: <LanguageProvider id="3627" alt="Nro. Documento" />,
          type: "inputText",
          length: 11,
        },

      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerChoferes;
