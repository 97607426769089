import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useRef, useState } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Proveedor } from "src/models/Proveedor";
import { Toast } from "primereact/toast";

// const TODOS_OBJ = {
//   id: "TODOS",
//   descripcionFull: "TODOS",
//   descripcion: "TODOS",
//   label: "TODOS",
// };

const MantenerProveedores = () => {
  const SCREEN_CODE = "abm0033_mantener_proveedores";
  const URL_BASE = "/mantener-proveedores";
  const SCREEN_TITLE = <LanguageProvider id="435" alt="Mantener Proveedores" />;
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  const teste = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - ${LanguageProvider({ id: "16276", alt: "Tipo de proveedor" })} `;

  // async function handleSave(model: Proveedor, filterValues: any) {
  //   try {
  //     if (filterValues?.securityValues?.country) {
  //       const proveedor = {
  //         ...model,
  //         pais: filterValues.securityValues.country,
  //         cliente: filterValues.securityValues.client,
  //       };
  //       await sipcoAxiosService.post(URL_BASE + "/save", {
  //         proveedor,
  //       });
  //       return true;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   return false;
  // }
  async function handleUpdate(model: Proveedor, filterValues: any) {
    try {
      const proveedor = {
        ...model,        
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        proveedor,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Proveedor, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        proveedor: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Proveedor[]> {
    if (filter?.tipoDeProveedor === undefined) {
      toast.current?.show({
        severity: "warn",
        detail: teste,
        life: 3000,
      });
      return [];
    }
    const params = {
      pais: filter?.securityValues?.country,
      tipoDeProveedor: filter?.tipoDeProveedor,
      codigo: filter?.codigo || "",
      descripcion: filter?.descripcion || "",
    };

    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/proveedor",
        params
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<Proveedor> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          proveedor: model,
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadTipoProveedor(filter): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/tipo-proveedor",
          {
            pais: filter?.securityValues?.country,
          }
        );
        if (status === 200) {
          // data.unshift(TODOS_OBJ);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<Proveedor>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
          },
        }}
        filterSettings={[
          {
            filter: "tipoDeProveedor",
            label: LanguageProvider({ id: "16276", alt: "Tipo de proveedor" }),
            placeholder: LanguageProvider({
              id: "16276",
              alt: "Tipo de proveedor",
            }),
            type: "dropdown",
            dataSource: loadTipoProveedor,
            dropDownTemplate: DropdownOptionTemplate,
            dependency: ["securityValues.country"],
          },
          {
            filter: "codigo",
            label: <LanguageProvider id="6590" alt="Código" />,
            type: "inputText",
          },
          {
            filter: "descripcion",
            label: <LanguageProvider id="3756" alt="Descripción" />,
            type: "inputText",
          },
        ]}
        defaultValues={{
          id: "",
          activo: true,
          cliente: null,
          pais: null,
          cuit: "",
          direccion: "",
          provincia: null,
          ciudad: null,
          localidad: null,
          cp: "",
          tel: "",
          email: "",
          observacion: "",
          tipoDeDocumento: "",
          numeroDeDocumento: "",
          origen: "",
          eventual: false,
          estatuto: false,
          facturaBlanco: false,
          inscripcionDgi: false,
          inscripcionIibb: false,
          certExencionGcia: false,
          certExencionIibb: false,
          descripcionCorta: "",
          idioma: null,
          interno: false,
          proveedorModelo: "",
          idInternacional: "",
          idProveedorFra: "",
          isygoIdPproveedor: "",
          exigeNroControl: false,
          cierraHrSinDoc: false,
          idMicrosiga: "",
          cnpj: "",
          micCuit: "",
          micDireccion: "",
          micIdPais: "",
          micIdProvincia: "",
          micIdLocalidad: "",
          micIdCciudad: "",
          tipoDePersona: "",
          dependentes: false,
          inss: false,
          inssSimplificado: false,
          tipoContratado: false,
          agencia: null,
          dvAgencia: false,
          ctaCte: 0,
          dvCtaCte: "",
          titularCtaCte: "",
          cnpjCtaCte: 0,
          codigoTyc: "",
          exigeReserva: false,
          cobraRetorno: false,
          nrtc: "",
          fnPais: 0,
          tipoValido: false,
        }}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        dataTableColumns={[
          { field: "id", header: <LanguageProvider id="6590" alt="Código" /> },
          {
            field: "cnpj",
            header: <LanguageProvider id="11178" alt="Código de CNPJ" />,
          },
          {
            field: "idMicrosiga",
            header: <LanguageProvider id="20471" alt="ID Microsiga" />,
          },
          { field: "cuit", header: <LanguageProvider id="11178" alt="CUIT" /> },
          {
            field: "descripcion",
            header: <LanguageProvider id="2521" alt="Descrição" />,
          },
          {
            field: "direccion",
            header: <LanguageProvider id="3069" alt="Dirección" />,
          },
          {
            field: "localidad.descripcion",
            header: <LanguageProvider id="3186" alt="Localidad" />,
          },
          {
            field: "ciudad.descripcion",
            header: <LanguageProvider id="164" alt="Ciudad" />,
          },
          {
            field: "provincia.descripcion",
            header: <LanguageProvider id="159" alt="Provincia" />,
          },
          {
            field: "pais.descripcion",
            header: <LanguageProvider id="154" alt="País" />,
          },
          {
            field: "cp",
            header: <LanguageProvider id="11176" alt="Código Postal" />,
          },
          {
            field: "tel",
            header: <LanguageProvider id="3071" alt="Teléfono" />,
          },
          {
            field: "email",
            header: <LanguageProvider id="10415" alt="Correo Electrónico" />,
          },
          {
            field: "idInternacional",
            header: <LanguageProvider id="16279" alt="ID Internacional" />,
          },
          {
            field: "observacion",
            header: <LanguageProvider id="3625" alt="Observación" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: (
              <LanguageProvider id="5271" alt="Fecha Últ. Modificación" />
            ),
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
          {
            field: "descripcionCorta",
            header: <LanguageProvider id="16280" alt="Descripción Corta" />,
          },
          {
            field: "idProveedorFra",
            header: <LanguageProvider id="16281" alt="ID Proveedor Fra" />,
          },
          {
            field: "isygoIdPproveedor",
            header: <LanguageProvider id="11637" alt="ID Proveedor Isygo" />,
          },
          {
            field: "exigeNroControl",
            header: (
              <LanguageProvider id="12318" alt="Exige Número de Control" />
            ),
            body: (rowData: any) => (rowData.exigeNroControl ? "SI" : "NO"),
          },
          {
            field: "interno",
            header: <LanguageProvider id="1867" alt="Interno" />,
            body: (rowData: any) => (rowData.interno ? "SI" : "NO"),
          },
          {
            field: "exigeReserva",
            header: <LanguageProvider id="15269" alt="Exige Reserva" />,
            body: (rowData: any) => (rowData.exigeReserva ? "SI" : "NO"),
          },
          {
            field: "codigoTyc",
            header: <LanguageProvider id="15325" alt="Código TyC" />,
          },
          {
            field: "cobraRetorno",
            header: <LanguageProvider id="16229" alt="Cobra Retorno" />,
            body: (rowData: any) => (rowData.cobraRetorno ? "SI" : "NO"),
          },
        ]}
        // loadDropdownData={}
        formSettings={[
          {
            field: "id",
            label: <LanguageProvider id="6590" alt="Código" />,
            type: "inputText",
            length: 8,
            disabled: true,
          },
          {
            field: "descripcion",
            label: <LanguageProvider id="3756" alt="Descripción" />,
            type: "inputText",
            length: 50,
            disabled: true,
          },
          {
            field: "direccion",
            label: <LanguageProvider id="3069" alt="Dirección" />,
            type: "inputText",
            length: 100,
            disabled: true,
          },
          {
            field: "pais.descripcion",
            label: <LanguageProvider id="154" alt="País" />,
            type: "inputText",
            disabled: true,
          },
          {
            field: "provincia.descripcion",
            label: <LanguageProvider id="159" alt="Provincia" />,
            type: "inputText",
            disabled: true,
          },
          {
            field: "ciudad.descripcion",
            label: <LanguageProvider id="164" alt="Ciudad" />,
            type: "inputText",
            disabled: true,
          },
          {
            field: "localidad.descripcion",
            label: <LanguageProvider id="3186" alt="Localidad" />,
            type: "inputText",
            disabled: true,
          },
          {
            field: "cp",
            label: <LanguageProvider id="11176" alt="Código Postal" />,
            type: "inputText",
            length: 10,
            disabled: true,
          },
          {
            field: "tel",
            label: <LanguageProvider id="3071" alt="Teléfono" />,
            type: "inputText",
            length: 50,
            disabled: true,
          },
          {
            field: "email",
            label: <LanguageProvider id="10415" alt="Email" />,
            type: "inputText",
            length: 256,
            disabled: true,
          },
          {
            field: "isygoIdPproveedor",
            label: <LanguageProvider id="11637" alt="Código Isygo" />,
            type: "inputText",
            length: 17,
          },
          {
            field: "idMicrosiga",
            label: <LanguageProvider id="20471" alt="Código Microsiga" />,
            type: "inputText",
            length: 8,
          },
          {
            field: "cuit",
            label: <LanguageProvider id="11178" alt="CUIT" />,
            type: "inputText",
            length: 20,
            disabled: true,
          },
          {
            field: "activo",
            label: <LanguageProvider id="72" alt="Activo" />,
            type: "checkbox",
            disabled: true,
          },
          {
            field: "idInternacional",
            label: <LanguageProvider id="16279" alt="Cód.Internacional" />,
            type: "inputText",
            length: 20,
            disabled: true,
          },
          {
            field: "descripcionCorta",
            label: <LanguageProvider id="16280" alt="Abreviado" />,
            type: "inputText",
            disabled: true,
          },
          {
            field: "idProveedorFra",
            label: <LanguageProvider id="16281" alt="Cód. Proveedor Francia" />,
            type: "inputText",
            length: 9,
          },
          {
            field: "observacion",
            label: <LanguageProvider id="3625" alt="Observaciones" />,
            type: "textArea",
          },
          {
            field: "codigoTyc",
            label: <LanguageProvider id="15325" alt="Código TYC" />,
            type: "inputText",
            length: 20,
          },
          {
            field: "exigeNroControl",
            label: (
              <LanguageProvider id="12318" alt="Exige Nº de control en HR" />
            ),
            type: "checkbox",
          },
          {
            field: "cobraRetorno",
            label: <LanguageProvider id="16229" alt="Cobra por retorno" />,
            type: "checkbox",
          },
          {
            field: "interno",
            label: <LanguageProvider id="1867" alt="Interno" />,
            type: "checkbox",
            disabled: true,
          },
          {
            field: "exigeReserva",
            label: <LanguageProvider id="15269" alt="Exige reserva" />,
            type: "checkbox",
          },
        ]}
        // save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default MantenerProveedores;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
