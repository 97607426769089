import "./OrdenReparacion.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { HttpStatusCode } from "axios";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../protected-routes";
import { useNavigate } from "react-router-dom";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import ModalAltaOrdenReparacionState from "./modals/ModalAltaOrdenReparacionState";
import ModalModificarOrdenReparacionState from "./modals/ModalModificarOrdenReparacionState";
import ModalVerOrdenReparacionState from "./modals/ModalVerOrdenReparacionState";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export function OrdenReparacion() {
  const SCREEN_CODE = "fun0079_orden_reparacion";
  ScreenCodeValue(SCREEN_CODE);
  const navigate = useNavigate();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [alta, setAlta] = useState(false);
  const [modificar, setModificar] = useState(false);
  const [ver, setVer] = useState(false);
  const toast = useRef<Toast>(null);

  const showModalAlta = () => {
    setAlta(true);
  };

  const hideModalAlta = () => {
    setAlta(false);
  };

  const showModalModificar = () => {
    if (selectedRows.length !== 0) {
      setModificar(true);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: (
          <LanguageProvider
            id={"753"}
            alt="Debe seleccionar al menos un registro de la Grilla."
          />
        ),
        life: 3000,
      });
    }
  };

  const hideModalModificar = () => {
    setModificar(false);
  };

  const showModalVer = () => {
    if (selectedRows.length !== 0) {
      setVer(true);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: (
          <LanguageProvider
            id={"753"}
            alt="Debe seleccionar al menos un registro de la Grilla."
          />
        ),
        life: 3000,
      });
    }
  };

  const hideModalVer = () => {
    setVer(false);
  };

  const columnsNames = [];
  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    checkVinTaller: false,
    taller: null,
    estados: null,
    nroOrden: null,
    vin: null,
    checkFueraTaller: false,
    desde: new Date(),
    hasta: new Date(),
  });

  const [vin, setVin] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.get(
          "/orden-reparacion/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codCuenta: securityFilters.securityValues.account.id,
              vin: query,
              descripcionCliente: "",
            },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  const [taller, setTaller] = useState([]);
  const [loadingTaller, setLoadingTaller] = useState(false);
  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.get(
          "/orden-reparacion/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
              codCuenta: securityFilters.securityValues.account.id,
            },
          }
        );
        if (status === 200) {
          setTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
  }

  const [estados, setEstados] = useState(null);
  const [loadingEstados, setLoadingEstados] = useState(false);
  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (
        securityFilters?.securityValues?.country == null ||
        securityFilters?.securityValues?.client == null
      )
        return;
      const { status, data } = await sipcoAxiosService.get(
        "/orden-reparacion/estados",
        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );
      if (status === 200) {
        data.unshift({ id: "", descripcion: "TODOS" });
        setEstados(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
  }

  const [ValidateAnularLabel, setValidateAnularLabel] = useState([]);
  const [loadingValidateAnularLabel, setLoadingValidateAnularLabel] =
    useState(false);

  async function validateAnular() {
    setLoadingValidateAnularLabel(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/orden-reparacion/validate-anular",
        {
          pais: securityFilters?.securityValues?.country,
          cuenta: securityFilters?.securityValues?.account,
          subcuenta: securityFilters?.securityValues?.subaccount,
          taller: filter.taller ?? null,
          ordenReparacion: {
            activo: true,
            ...selectedRows[0],
          },
        }
      );
      if (status === HttpStatusCode.Ok) {
        setValidateAnularLabel(data);
        return data;
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error",
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingValidateAnularLabel(false);
    }
  }

  const [anularLabel, setAnularLabel] = useState([]);
  const [loadingAnularLabel, setLoadingAnularLabel] = useState(false);

  async function anular() {
    setLoadingAnularLabel(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/orden-reparacion/anular",
        {
          pais: securityFilters?.securityValues?.country,
          cuenta: securityFilters?.securityValues?.account,
          subcuenta: securityFilters?.securityValues?.subaccount,
          taller: filter.taller ?? null,
          ordenReparacion: {
            activo: true,
            ...selectedRows[0],
          },
        }
      );
      if (status === HttpStatusCode.Ok) {
        setAnularLabel(data);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Anular",
          life: 3000,
        });
        return data;
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error",
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAnularLabel(false);
    }
  }

  async function loadReportData() {
    setLoading(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/orden-reparacion/findBy",
        {
          pais: securityFilters?.securityValues?.country,
          cuenta: securityFilters?.securityValues?.account,
          subcuenta: securityFilters?.securityValues?.subaccount,
          taller: filter.taller ? filter.taller : null,
          estado: filter.estados ? filter.estados : null,
          vin: filter.vin ? filter.vin : null,
          enTaller: filter.checkVinTaller,
          fueraTaller: filter.checkFueraTaller,
          fechaDesde: filter.desde ? filter.desde.getTime() : null,
          fechaHasta: filter.hasta ? filter.hasta.getTime() : null,
          cliente: securityFilters?.securityValues?.client,
          clientes: securityFilters?.securityValues?.clientValues,
          nroOrdem: filter.nroOrden ?? null,
        }
      );
      if (status === HttpStatusCode.Ok) {
        return setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        const [taller, estados] = await Promise.all([
          loadTaller(),
          loadEstados(),
        ]);

        setFilter({ ...filter, taller: taller[0], estados: estados[0] });
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [securityFilters.securityValues]);

  const data = convertDateObjects(reporte?.filas);

  const columnsNamesTable = [
    { field: "numeroIngreso", header: "Nro Ingreso" },
    { field: "id", header: "Nro Orden" },
    { field: "fechaOrden.timestamp", header: "Fecha Alta" },
    { field: "vin.id", header: "Vin" },
    { field: "vin.marca.descripcion", header: "Marca" },
    { field: "vin.modelo.descripcion", header: "Modelo" },
    { field: "inspector.descripcion", header: "Inspector" },
    { field: "subcuenta.descripcion", header: "Subcuenta" },
    { field: "taller.descripcion", header: "Taller" },
    { field: "fechaCierreReal.timestamp", header: "Fecha Cierre Real" },
    { field: "fechaCierreEstimada.timestamp", header: "Fecha Cierre Estimada" },
    { field: "fechaFacturacion.timestamp", header: "Fecha Facturacion" },
    { field: "fechaAnulacion.timestamp", header: "Fecha Anulacion" },
    { field: "estado.descripcion", header: "Estado" },
    { field: "observaciones", header: "Incidentes" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };

  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  const valuesObjects = {
    pais: securityFilters.securityValues.country,
    cliente: securityFilters.securityValues.client,
    cuenta: securityFilters.securityValues.account,
    subcuenta: securityFilters.securityValues.subaccount,
    taller: filter.taller,
    ordeReparacion: selectedRows[0],
  };
  async function anularFunctions() {
    try {
      await Promise.all([validateAnular(), anular()]);
    } catch (error) {
      console.error(error);
    }
  }

  function barrar() {
    return confirmDialog({
      header: <LanguageProvider id={"16239"} alt="Atención" />,
      message: <LanguageProvider id={"829"} alt="Anular el o los registros?" />,
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      reject: () => console.log("aceptar"),
      accept: () => anularFunctions(),
    });
  }

  // Cerrar
  const [cerrar, setCerrar] = useState([]);
  const [loadingCerrar, setLoadingCerrar] = useState(false);
  async function loadCerrar() {
    setLoadingCerrar(true);
    try {
      if (selectedRows.length !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/facturar",
          {
            pais: selectedRows[0].pais || null,
            subcuenta: selectedRows[0].subcuenta || null,
            cuenta: selectedRows[0].cuenta || null,
            taller: selectedRows[0].taller || null,
            ordenReparacion: selectedRows[0],
          }
        );
        if (status === 200) {
          setCerrar(data);
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: (
              <LanguageProvider
                id={"24901"}
                alt="Desea realizar el cierre de colecta"
              />
            ),
            life: 3000,
          });
          return data;
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            life: 3000,
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un registro",
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCerrar(false);
    }
  }

  const [validateCerrar, setValidateCerrar] = useState([]);
  const [loadingValidateCerrar, setLoadingValidateCerrar] = useState(false);
  async function loadValidateCerrar() {
    setLoadingValidateCerrar(true);
    try {
      if (selectedRows.length !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/validate-facturar",
          {
            pais: selectedRows[0].pais || null,
            subcuenta: selectedRows[0].subcuenta || null,
            cuenta: selectedRows[0].cuenta || null,
            taller: selectedRows[0].taller || null,
            ordenReparacion: selectedRows[0],
          }
        );
        if (status === 200) {
          setValidateCerrar(data);
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: (
              <LanguageProvider
                id={"24901"}
                alt="Desea realizar el cierre de colecta"
              />
            ),
            life: 3000,
          });
          return data;
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            life: 3000,
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: (
            <LanguageProvider id={"870"} alt="Debe Seleccionar un Registro" />
          ),
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingValidateCerrar(false);
    }
  }

  async function cerrarFunctions() {
    try {
      await Promise.all([loadValidateCerrar(), loadCerrar()]);
    } catch (error) {
      console.error(error);
    }
  }

  // Reabrir
  const [reabrir, setReabrir] = useState([]);
  const [loadingReabrir, setLoadingReabrir] = useState(false);
  async function loadReabrir() {
    setLoadingReabrir(true);
    try {
      if (selectedRows.length !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/reabrir",
          {
            pais: selectedRows[0].pais || null,
            subcuenta: selectedRows[0].subcuenta || null,
            cuenta: selectedRows[0].cuenta || null,
            taller: selectedRows[0].taller || null,
            ordenReparacion: selectedRows[0],
          }
        );
        if (status === 200) {
          setReabrir(data);
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: (
              <LanguageProvider id={"2398"} alt="La Orden debe estar Cerrada" />
            ),
            life: 3000,
          });
          return data;
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            life: 3000,
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un registro",
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReabrir(false);
    }
  }

  // Aprobar
  const [aprobar, setAprobar] = useState([]);
  const [loadingAprobar, setLoadingAprobar] = useState(false);
  async function loadAprobar() {
    setLoadingAprobar(true);
    try {
      if (selectedRows.length !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion/aprobar",
          {
            pais: selectedRows[0].pais || null,
            subcuenta: selectedRows[0].subcuenta || null,
            cuenta: selectedRows[0].cuenta || null,
            taller: selectedRows[0].taller || null,
            ordenReparacion: selectedRows[0],
          }
        );
        if (status === 200) {
          setAprobar(data);
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: (
              <LanguageProvider
                id={"24798"}
                alt="Solo se pueden aprobar una Orden de Reparación en estado Pendiente de Aprobación"
              />
            ),
            life: 3000,
          });
          return data;
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            life: 3000,
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un registro",
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAprobar(false);
    }
  }

  return (
    <div className="orden-reparacion">
      <Toast ref={toast} position="top-center" />
      <ConfirmDialog />
      <ModalAltaOrdenReparacionState
        onClose={hideModalAlta}
        visible={alta}
        values={valuesObjects}
      />
      <ModalModificarOrdenReparacionState
        onClose={hideModalModificar}
        visible={modificar}
        values={valuesObjects}
      />
      <ModalVerOrdenReparacionState
        onClose={hideModalVer}
        visible={ver}
        values={valuesObjects}
      />

      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"7795"} alt="Filtrar Por:" />}
        >
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <SipcoDropdown
                  loading={loadingTaller}
                  options={taller}
                  value={filter.taller}
                  onChange={(e) => setFilter({ ...filter, taller: e.value })}
                  optionLabel="descripcion"
                />
              </div>
            </div>
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1151"} alt="Estados" />
                </label>
                <SipcoDropdown
                  loading={loadingEstados}
                  value={filter.estados}
                  options={estados}
                  onChange={(e) => setFilter({ ...filter, estados: e.value })}
                  optionLabel="descripcion"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1491"} alt="VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.value || null })
                  }
                  field="label"
                  dropdown
                  forceSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"5701"} alt="Nro. Orden" />
                </label>
                <SipcoInputText
                  value={filter.nroOrden}
                  onChange={(e) =>
                    setFilter({ ...filter, nroOrden: e.target.value })
                  }
                />
              </div>
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.checkVinTaller}
                  onChange={(e) => {
                    setFilter({ ...filter, checkVinTaller: e.checked });
                  }}
                  style={{
                    justifyContent: "center",
                    position: "relative",
                  }}
                />
                <label>
                  <LanguageProvider id={"4009"} alt="VIN en taller" />
                </label>
              </div>
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.checkFueraTaller}
                  onChange={(e) => {
                    setFilter({ ...filter, checkFueraTaller: e.checked });
                  }}
                  style={{
                    justifyContent: "center",
                    position: "relative",
                  }}
                />
                <label>
                  <LanguageProvider id={"4010"} alt="VIN fuera de taller" />
                </label>
              </div>
            </div>
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2621"} alt="Desde" />
                </label>
                <SipcoCalendar
                  value={filter.desde}
                  onChange={(e) => setFilter({ ...filter, desde: e.value })}
                  showIcon
                  showButtonBar
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2622"} alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.hasta}
                  onChange={(e) => setFilter({ ...filter, hasta: e.value })}
                  showIcon
                  showButtonBar
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}></span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                  pdf={false}
                  csv={false}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              <div className="flex gap-2">
                <Button
                  label={LanguageProvider({ id: "25", alt: "Alta" })}
                  icon="pi pi-plus"
                  iconPos="right"
                  onClick={showModalAlta}
                />
                <Button
                  label={LanguageProvider({ id: "27", alt: "Modificar" })}
                  icon="pi pi-pencil"
                  iconPos="right"
                  onClick={showModalModificar}
                />
                <Button
                  label={LanguageProvider({ id: "12899", alt: "Ver" })}
                  icon="pi pi-eye"
                  iconPos="right"
                  onClick={showModalVer}
                />
                <Button
                  label={LanguageProvider({ id: "20973", alt: "Anular" })}
                  icon="pi pi-times"
                  iconPos="right"
                  onClick={barrar}
                />
                <Button
                  label={LanguageProvider({ id: "16582", alt: "Cerrar" })}
                  onClick={cerrarFunctions}
                />
                <Button
                  label={LanguageProvider({ id: "17491", alt: "Reabrir" })}
                  onClick={loadReabrir}
                />
                <Button
                  label={LanguageProvider({ id: "19673", alt: "Aprobar" })}
                  onClick={loadAprobar}
                />
                <Button
                  label={LanguageProvider({ id: "2248", alt: "Repuestos" })}
                  onClick={() => {
                    if (selectedRows.length <= 0) {
                      toast.current?.show({
                        severity: "error",
                        summary: "Error",
                        detail: (
                          <LanguageProvider
                            id={"753"}
                            alt="Debe seleccionar al menos un registro de la Grilla."
                          />
                        ),
                        life: 3000,
                      });
                    } else {
                      navigate("/orden-reparacion-repuestos", {
                        state: {
                          order: selectedRows[0],
                        },
                      });
                    }
                  }}
                />
                <Button
                  label={LanguageProvider({ id: "2249", alt: "Servicios" })}
                  onClick={() => {
                    if (selectedRows.length <= 0) {
                      toast.current?.show({
                        severity: "error",
                        summary: "Error",
                        detail: (
                          <LanguageProvider
                            id={"753"}
                            alt="Debe seleccionar al menos un registro de la Grilla."
                          />
                        ),
                        life: 3000,
                      });
                    } else {
                      navigate("/orden-reparacion-servicios", {
                        state: {
                          order: selectedRows[0],
                        },
                      });
                    }
                  }}
                />
                <Button
                  label={LanguageProvider({
                    id: "2689",
                    alt: "Imp.Orden Taller",
                  })}
                />
                <Button
                  label={LanguageProvider({
                    id: "2688",
                    alt: "Imp.Cierre de Orden",
                  })}
                />
                <Button
                  label={LanguageProvider({
                    id: "24504",
                    alt: "Imp.Orden Cotizacion",
                  })}
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          ref={dt}
          value={reporte}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e: any) => {
            setSelectedRows(e.value);
          }}
          metaKeySelection={true}
          dragSelection
        >
          {columnsNamesTable.map((colum, index) => {
            if (
              colum.header === "Fecha Alta" ||
              colum.header === "Fecha Cierre Real" ||
              colum.header === "Fecha Cierre Estimada" ||
              colum.header === "Fecha Facturacion" ||
              colum.header === "Fecha Anulacion"
            ) {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default OrdenReparacion;
