import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { FiltrosPreparacion } from "src/models/FiltrosPreparacion";

const MantenerFiltrosPreparacion = () => {
  const SCREEN_CODE = "abm0190_mantener_filtros_preparacion";
  const URL_BASE = "/mantener-filtros-preparacion";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: FiltrosPreparacion, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const filtroPreparacion = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues.securityValues.account,
          textoA: model.texto === 0,
          fecha: model.texto === 1,
          celdaVacia: model.texto === 2,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          filtroPreparacion: filtroPreparacion,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: FiltrosPreparacion) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        filtroPreparacion: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<FiltrosPreparacion[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          cuenta: filter.securityValues.account,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function getAlfabeto(filter: any): Promise<any[]> {
    try {
      if (filter?.securityValues?.country) {
        const alfabeto = Array.from({ length: 26 }, (_, i) => {
          const letra = String.fromCharCode(65 + i); // 65 é o código ASCII do 'A'
          return { value: letra, descripcion: letra };
        });

        return Promise.resolve(alfabeto);
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<FiltrosPreparacion>
      screenCode={SCREEN_CODE}
      title={
        <LanguageProvider id="14481" alt="Mantener Filtros Excel Preparación" />
      }
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          account: true,
        },
      }}
      dataTableColumns={[
        {
          field: "columna",
          header: <LanguageProvider id="991" alt="Columna" />,
        },
        {
          field: "patronFiltro",
          header: <LanguageProvider id="14453" alt="Patrón Filtro" />,
        },
        {
          field: "texto",
          header: <LanguageProvider id="14454" alt="Texto" />,
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: <LanguageProvider id="13489" alt="Actualizó Usuario" />,
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="13490" alt="Actualizó con Fecha" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            );
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        pais: null,
        cliente: null,
        cuenta: null,
        texto: 0,
        textoA: false,
        fecha: false,
        celdaVacia: false,
        columna: null,
        patronFiltro: "",
        activo: true,
      }}
      formSettings={[
        {
          field: "texto",
          label: <LanguageProvider id="" alt="" />,
          type: "radiobutton",
          radioButtonOptions: [
            {
              label: <LanguageProvider id="14454" alt="Texto" />,
              value: 0,
            },
            {
              label: <LanguageProvider id="2131" alt="Fecha" />,
              value: 1,
            },
            {
              label: <LanguageProvider id="14483" alt="Celda Vacía" />,
              value: 2,
            },
          ],
        },
        {
          field: "columna",
          label: <LanguageProvider id="991" alt="Columna" />,
          dropDownPlaceholder: LanguageProvider({
            id: "991",
            alt: "Columna",
          }),
          type: "dropdown",
          dataSource: getAlfabeto,
        },
        {
          field: "patronFiltro",
          label: <LanguageProvider id="" alt="" />,
          type: "inputText",
        },
        {
          field: "",
          label: <LanguageProvider id="" alt="" />,
          type: "calendar",
          disabled: true,
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={null}
      save={handleSave}
      update={null}
      remove={handleRemove}
    />
  );
};

export default MantenerFiltrosPreparacion;
