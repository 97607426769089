import React, { useRef } from "react";

import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { ParametrosClienteSipco } from "src/models/ParametrosClienteSipco";

const MantenerParametrosClienteSipco = () => {
  const SCREEN_CODE = "abm0201_mantener_parametros_cliente_sipco";
  const URL_BASE = "/parametros-clientes-sipco";
  const SCREEN_TITLE = (
    <LanguageProvider id="17772" alt="Mantener Parametros Cliente SIPCO" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: ParametrosClienteSipco, filterValues: any) {
    try {
      if (
        filterValues?.securityValues?.country &&
        filterValues?.securityValues?.client &&
        filterValues?.securityValues?.account
      ) {
        const parametrosClienteSipco = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          cuenta: filterValues?.securityValues?.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          parametrosClienteSipco,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(
    model: ParametrosClienteSipco,
    filterValues: any
  ) {
    try {
      const parametrosClienteSipco = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        cuenta: filterValues?.securityValues?.account,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        parametrosClienteSipco,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(
    model: ParametrosClienteSipco,
    filterValues: any
  ) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        parametrosClienteSipco: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ParametrosClienteSipco[]> {
    if (
      filter?.securityValues?.country &&
      filter?.securityValues?.client &&
      filter?.securityValues?.account
    ) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/findBy",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
            cuenta: filter?.securityValues?.account,
          }
        );
        if (status === 200) return data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }
  async function handleGet(model: any): Promise<ParametrosClienteSipco> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          parametrosClienteSipco: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadCupoPriorizacion(
    filter: any
  ): Promise<ParametrosClienteSipco[]> {
    if (filter?.securityValues?.country) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/codigo-playa",
          {
            pais: filter.securityValues.country,
          }
        );

        if (status === 200) {
          return data;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ParametrosClienteSipco>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
            client: true,
            account: true,
          },
        }}
        // filterSettings={[]}
        dataTableColumns={[
          {
            field: "cuenta.descripcion",
            header: <LanguageProvider id="448" alt="x" />,
          },

          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },

          {
            field: "confirmaAad",
            header: <LanguageProvider id="17771" alt="x" />,
            body: (rowData: any) => (rowData.confirmaAad ? "SI" : "NO"),
          },

          {
            field: "reparaDanioDesp",
            header: <LanguageProvider id="19369" alt="x" />,
            body: (rowData: any) => (rowData.reparaDanioDesp ? "SI" : "NO"),
          },

          {
            field: "enviarMailArmadoHR",
            header: <LanguageProvider id="19498" alt="x" />,
            body: (rowData: any) => (rowData.enviarMailArmadoHR ? "SI" : "NO"),
          },

          {
            field: "enviarMailDespacho",
            header: <LanguageProvider id="19499" alt="x" />,
            body: (rowData: any) => (rowData.enviarMailDespacho ? "SI" : "NO"),
          },

          {
            field: "liberacionAutomaticaRemito",
            header: <LanguageProvider id="19772" alt="x" />,
            body: (rowData: any) =>
              rowData.liberacionAutomaticaRemito ? "SI" : "NO",
          },

          {
            field: "pasajeTercero",
            header: <LanguageProvider id="19773" alt="x" />,
            body: (rowData: any) => (rowData.pasajeTercero ? "SI" : "NO"),
          },

          {
            field: "pendienteTaller",
            header: <LanguageProvider id="20945" alt="x" />,
            body: (rowData: any) => (rowData.pendienteTaller ? "SI" : "NO"),
          },

          {
            field: "pedidoPlaya",
            header: <LanguageProvider id="22138" alt="x" />,
            body: (rowData: any) => (rowData.pedidoPlaya ? "SI" : "NO"),
          },

          {
            field: "compraFijoPorDireccionEntrega",
            header: <LanguageProvider id="22279" alt="x" />,
            body: (rowData: any) =>
              rowData.compraFijoPorDireccionEntrega ? "SI" : "NO",
          },

          {
            field: "compraPorTipoServicio",
            header: <LanguageProvider id="22353" alt="x" />,
            body: (rowData: any) =>
              rowData.compraPorTipoServicio ? "SI" : "NO",
          },

          {
            field: "cupoPriorizacion",
            header: <LanguageProvider id="23256" alt="x" />,
            body: (rowData: any) =>
              rowData.cupoPriorizacion
                ? rowData.cupoPriorizacion.descripcion
                : null,
          },

          { field: "cupo", header: <LanguageProvider id="23257" alt="x" /> },

          {
            field: "intervaloPriorizacion",
            header: <LanguageProvider id="23258" alt="x" />,
          },

          {
            field: "enviarInterfazEscala",
            header: <LanguageProvider id="23393" alt="x" />,
            body: (rowData: any) =>
              rowData.enviarInterfazEscala ? "SI" : "NO",
          },

          {
            field: "exigeDisponibilidadEscala",
            header: <LanguageProvider id="23408" alt="x" />,
            body: (rowData: any) =>
              rowData.exigeDisponibilidadEscala ? "SI" : "NO",
          },

          {
            field: "validaNacionalizacion",
            header: <LanguageProvider id="23411" alt="x" />,
            body: (rowData: any) =>
              rowData.validaNacionalizacion ? "SI" : "NO",
          },

          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },

          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.date
                ).toLocaleString()}
              </span>
            ),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: null,
          activo: true,
          pais: null,
          cliente: null,
          cuenta: null,
          confirmaAad: true,
          reparaDanioDesp: true,
          enviarMailArmadoHR: true,
          enviarMailDespacho: true,
          liberacionAutomaticaRemito: true,
          pasajeTercero: true,
          pendienteTaller: true,
          pedidoPlaya: false,
          compraFijoPorDireccionEntrega: false,
          compraPorTipoServicio: false,
          cupoPriorizacion: null,
          cupo: 0,
          intervaloPriorizacion: 0,
          enviarInterfazEscala: false,
          exigeDisponibilidadEscala: false,
          validaNacionalizacion: false,
          feriadoFPE: false,
          compraFijoPorTopeDeKM: 0,
        }}
        formSettings={[
          {
            field: "confirmaAad",
            label: LanguageProvider({ id: "17771", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "reparaDanioDesp",
            label: LanguageProvider({ id: "19369", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "enviarMailArmadoHR",
            label: LanguageProvider({ id: "19498", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "enviarMailDespacho",
            label: LanguageProvider({ id: "19499", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "liberacionAutomaticaRemito",
            label: LanguageProvider({ id: "19772", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "pasajeTercero",
            label: LanguageProvider({ id: "19773", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "pendienteTaller",
            label: LanguageProvider({ id: "20945", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "pedidoPlaya",
            label: LanguageProvider({ id: "22138", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "compraFijoPorDireccionEntrega",
            label: LanguageProvider({ id: "22279", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "compraPorTipoServicio",
            label: LanguageProvider({ id: "22353", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "enviarInterfazEscala",
            label: LanguageProvider({ id: "23393", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "exigeDisponibilidadEscala",
            label: LanguageProvider({ id: "23408", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "validaNacionalizacion",
            label: LanguageProvider({ id: "23411", alt: "x" }),
            type: "checkbox",
          },
          {
            field: "cupoPriorizacion",
            label: LanguageProvider({ id: "23256", alt: "x" }),
            dropDownPlaceholder: LanguageProvider({ id: "23256", alt: "x" }),
            type: "dropdown",
            dataSource: (filter: any) => loadCupoPriorizacion(filter),
            dropDownTemplate: DropdownOptionTemplate,
          },
          {
            field: "cupo",
            label: LanguageProvider({ id: "23257", alt: "x" }),
            type: "inputNumber",
            max: 9999,
          },
          {
            field: "intervaloPriorizacion",
            label: LanguageProvider({ id: "23258", alt: "x" }),
            type: "inputNumber",
            max: 99,
          },
          {
            field: "compraFijoPorTopeDeKM",
            label: LanguageProvider({ id: "25006", alt: "x" }),
            type: "inputNumber",
            max: 8,
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        list={handleList}
        get={handleGet}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
      />
    </>
  );
};

export default MantenerParametrosClienteSipco;

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
