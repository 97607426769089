import "./carga-masiva-danos.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

import CargaMasivaAba from "./components/carga-masiva-danos-aba";
import DestranadoMasivoDanos from "./components/destrabado-masivo-danos-aba";
import { Dialog } from "primereact/dialog";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import ReparacionMasivaDanos from "./components/reparacion-masiva-danos-aba";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes"; 
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

// Data Table não está funcional, por não conter no vídoe de desenvolvimento
export function CargaMasivaDanos() {
  const SCREEN_CODE = "fun0124_carga_masiva_danos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();   
  const toast = useRef<Toast>(null);

  usePageViews();
  const [vinData, setVinData] = useState(null);
  const [codVin, setCodVin] = useState("");   
  const [listVinObject, setListVinObject] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState([]);
  const [listClientes, setListClientes] = useState([]);
  const [modal, setModal] = useState(false);
  const [loadingVin, setLoadingVin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTabIndex] = useState(0);

  const filterLabelId = [
    662, 54, 1491, 18113, 18136, 3701, 2693, 9573, 5854, 22700, 9955, 18090,
  ];
  const filterNames = [];

  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }

  const showError = (id: string, alt: string) => {
    toast.current.show({
      severity: "error",
      summary: "Erro",
      detail: <LanguageProvider id={id} alt={alt} />,
      life: 3000,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setLoadingVin(true);
      findDatas();
    }
  };

  function findDatas() {
    if (codVin.length >= 8 && codVin.length <= 17) {
      findFirstBy();
    } else {
      setLoadingVin(false);
      return showError(
        "653",
        "Debe ingresar un minimo de 8 y un maximo de 17 dígitos"
      );
    }
  }

  async function findClienteByVin(vin: string) {
    setSelectedCliente([]);

    if (!vin) return showError("681", "Seleccione un vin");

    try {
      const { status, data } = await sipcoAxiosService.get(
        "report/findClientesVinBy",
        {
          params: {
            codPais: securityFilters.securityValues?.country?.value,
            codCuenta: securityFilters.securityValues?.account?.value,
            codVin: vin,
          },
        }
      );

      if (status === 200) {
        setListClientes(data);
        if (data.length > 0 || data.length === 1) {
          setSelectedCliente(data[0].descripcion);
          loadVinVinReportData(data[0]);
        }
        setListClientes(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findFirstBy() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "report/findFirstBy",
        {
          params: {
            codPais: securityFilters.securityValues?.country?.value,
            codCuenta: securityFilters.securityValues?.account?.value,
            codVin: codVin,
          },
        }
      );

      if (status === 200) {
        if (data.length === 0) {
          setLoadingVin(false);
          return showError("1480", "El VIN no existe");
        }

        if (codVin.length === 8) {
          if (data.length > 1) {
            setListVinObject(data);
            setModal(true);
          } else if (data.length === 1) {
            setCodVin(data[0].id);
            findClienteByVin(data[0].id);
          }
        } else if (data.length >= 1) {
          findClienteByVin(data[0].id);
        }

        setLoadingVin(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadVinVinReportData(cliente) {
    if (!selectedCliente) {
      return;
    }
    try {
      const reportResult = await sipcoAxiosService.get("/report/vinReport", {
        params: {
          codPais: securityFilters.securityValues?.country?.value,
          codCuenta: securityFilters.securityValues?.account?.value,
          codCliente: cliente.id,
          codVin: codVin,
        },
      });

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === 200 && data) {
          setVinData(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="carga-masiva-danos">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={findDatas}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Accordion multiple activeIndex={[0, 1]}>
        <AccordionTab header={filterNames[11].label}>
          <TabView activeIndex={activeTabIndex}>
            <TabPanel header={filterNames[11].label}>
              <CargaMasivaAba vinData={vinData} />
            </TabPanel>
            <TabPanel header={filterNames[3].label}>
              <ReparacionMasivaDanos vinData={vinData} />
            </TabPanel>
            <TabPanel header={filterNames[4].label}>
              <DestranadoMasivoDanos vinData={vinData} />
            </TabPanel>
          </TabView>
        </AccordionTab>
      </Accordion>
      <div
        style={{ zIndex: 10000 }}
        className="card flex justify-content-center"
      >
        <Dialog
          visible={modal}
          modal
          header={
            <span className="font-bold white-space-nowrap">
              <LanguageProvider id={681} alt="Seleccione un vin" />
            </span>
          }
          style={{ width: "80rem" }}
          onHide={() => {
            if (!modal) return;
            setModal(false);
          }}
        >
          <div className="m-0" style={{ padding: "1rem" }}>
            <SipcoDataTable
              value={listVinObject}
              selectionMode="single"
              tableStyle={{ minWidth: "50rem" }}
              onRowDoubleClick={(e) => {
                setModal(false);
                setCodVin(e.data.id);
                setLoadingVin(false);
                findClienteByVin(e.data.id);
              }}
              dataKey="id"
            >
              <SipcoColumn field="id" header="Vin" />
              <SipcoColumn
                field="cliente.descripcion"
                header={<LanguageProvider id={"24080"} alt="Cliente" />}
              />
              <SipcoColumn
                field="marca.descripcion"
                header={<LanguageProvider id={23890} alt="Marca" />}
              />
              <SipcoColumn
                field="modelo.descripcion"
                header={<LanguageProvider id={23865} alt="Modelo" />}
              />
              <SipcoColumn
                field="stockPlaya.calle"
                header={<LanguageProvider id={990} alt="Calle" />}
              />
              <SipcoColumn
                field="stockPlaya.columna"
                header={<LanguageProvider id={991} alt="Columna" />}
              />
            </SipcoDataTable>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
