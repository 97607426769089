import "./AdministracionRemitosAConcesionarios.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import SipcoInputText from "@shared/components/sipco-input-text";
import { HttpStatusCode } from "axios";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import { format, set } from "date-fns";

//TODO - parte visual da tela iniciada, aguardando back para finalizar report  e botões de Administración de Remitos - Lucas Silvestre - 23/07/2024
export function AdministracionRemitosAConcesionarios() {
  const SCREEN_CODE = "fun0043_administracion_remitos_a_concesionarios";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [ingreseElVin, setIngreseElVin] = useState([]);
  const [concesionarioFooter, setConcesionarioFooter] = useState([]);



  const [filter, setFilter] = useState({
    fecha: null,
    capitalyHBA: false,
    tipoRemito: null,
    letra: null,
    puntoVenta: null,
    numero: null,
    estados: null,
    concesionario: null,
    concesionarioFooter: null,
    marca: null,
    mercados: null,
    marcas: null,
    modelos: null,
    transportista: null,
    colores: null,
    diasCalculados: null,
    direccionFooter: null,
    familias: null,
    vin: null,
    bloqueoCalidad: null,
    direccion: null,
    modelo: null,
    parametrosFechaNulo: '',
    registrosSelecionados: '',
  });

  const data = convertDateObjects(reporte?.filas);

  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const filterNames = [];
  const filterColumnsId = [
    16757, 16757, 3344, 2941, 932, 1491, 14370, 1492, 396, 2139, 2140, 849, 850,
    1, 4216, 1, 3069, 3591, 8299, 159, 3186, 13338, 16264, 10502, 367, 1, 16758,
    10906, 12103, 1719, 1, 2368, 22446, 4197,
  ];

  const columnsNames = [];

  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  };

  const columnsNamesTable = [
    { field: "remito.leyendaRemito.id", header: "Cod. Leyenda" },
    { field: "remito.leyendaRemito.descripcion", header: "Leyenda" },
    { field: "remito.estadoRemito.descripcion", header: "Estado" },
    { field: "remito.concesionario.id", header: "Concesionario" },
    { field: "remito.id", header: "Código Remito" },
    { field: "remito.vin.id", header: "VIN" },
    { field: "remito.fechaEmision.date", header: "Fecha Emisión" },
    { field: "remito.vin.marca.descripcion", header: "Marca" },
    { field: "remito.vin.modelo.descripcion", header: "Modelo" },
    { field: "remito.vin.color.descripcion", header: "Color" },
    { field: "remito.letra", header: "Letra" },
    { field: "remito.puntoDeVenta", header: "Pto. Venta" },
    { field: "remito.direccionEntrega.id", header: "Boca de Entrega" },
    { field: "remito.direccionEntrega.direccion", header: "Dirección" },
    { field: "remito.provinciaImpresion", header: "Provincia" },
    { field: "remito.razonSocialImpresion", header: "Razón Social" },
    { field: "remito.domicilioImpresion", header: "Domicilio" },
    { field: "remito.localidadImpresion", header: "Localidad" },
    { field: "remito.vin.modeloLargo", header: "Modelo Largo" },
    { field: "rangosSaneamientoBloquea", header: "Rangos Saneamiento" },
    { field: "remito.direccionEntrega.corredor.descripcion", header: "Corredor" },
    { field: "remito.vin.mercado.id", header: "Mercado ID" },
    { field: "remito.vin.mercado.descripcion", header: "Mercado Descripción" },
    { field: "remito.vin.descripcionIngresoTaller", header: "Ingreso Taller" },
    { field: "remito.remitoTransferencia", header: "Remito Transferencia" },
    { field: "remito.vin.fechaSalidaFabrica.date", header: "Fecha Salida Fábrica" },
    { field: "remito.vin.fechaBloqueoCalidad.date", header: "Fecha Bloqueo Calidad" },
    { field: "remito.ficticio", header: "Ficticio" },
    { field: "remito.fechaLiberacion.date", header: "Fecha Liberación" }
  ];


  const [tipoRemitos, setTiposRemitos] = useState([]);
  const [loadingTipoRemitos, setLoadingTipoRemitos] = useState(false);
  async function loadRemitos() {
    try {
      if (securityFilters.securityValues.country) {
        setLoadingTipoRemitos(true);
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/tipo-remito",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setTiposRemitos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoRemitos(false);
    }
  }


  const [mercados, setMercados] = useState([]);
  const [loadingMercados, setLoadingMercados] = useState(false);

  async function loadMercados() {
    try {
      if (
        securityFilters.securityValues.country
      ) {
        setLoadingMercados(true);
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/mercado",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setMercados(data);
          return data
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
  }

  const [estados, setEstados] = useState([]);
  const [loadingEstados, setLoadingEstados] = useState(false);
  async function loadEstado() {
    try {
      if (securityFilters?.securityValues?.country) {
        setLoadingEstados(true);
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/estado-remito",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setEstados(data);
          return data;
        }
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
  }

  const [concesionario, setConcesionario] = useState([]);
  const [loadingConcesionario, setLoadingConcesionario] = useState(false);
  async function loadConcesionario() {
    try {
      if (
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.country
      ) {
        setLoadingConcesionario(true);
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/concesionario",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: "0", descripcion: "TODOS" });
          setConcesionario(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConcesionario(false);
    }
  }

  const [marca, setMarca] = useState([]);
  const [loadingMarca, setLoadingMarca] = useState(false);
  async function loadMarca() {
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client
      ) {
        setLoadingMarca(true);
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/marca",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: "0", descripcion: "TODOS" });
          setMarca(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
  }

  const [bloqueoCalidad, setBloqueoCalidad] = useState([]);
  const [loadingBloqueoCalidad, setLoadingBloqueoCalidad] = useState(false);
  async function loadBloqueoCalidad() {
    try {
      if (
        securityFilters.securityValues.country
      ) {
        setLoadingBloqueoCalidad(true);
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/bloqueo-calidad",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: 0, descripcion: "TODOS" });
          setBloqueoCalidad(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBloqueoCalidad(false);
    }
  }

  const [direccion, setDireccion] = useState([]);
  const [loadingDireccion, setLoadingDireccion] = useState(false);
  async function loadDireccion(concesionario: any) {
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.country &&
        concesionario
      ) {
        setLoadingDireccion(true);
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/direccion-entrega",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            concesionario: concesionario,
          }
        );
        if (status === HttpStatusCode.Ok) {
          data.unshift({ id: "0", descripcion: "TODOS" });
          setDireccion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDireccion(false);
    }
  }

  async function handleDropDownConcesionario(value: any) {
    try {
      const direccion = await loadDireccion(value.target.value);

      setFilter({ ...filter, concesionario: value.target.value, direccion })
    } catch (error) {
      console.error(error);
    }
  }

  const [modelo, setModelo] = useState([]);
  const [loadingModelo, setLoadingModelo] = useState(false);
  async function loadModelo(value: any) {
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.country &&
        value) {
        setLoadingModelo(true);
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/modelo",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca: value,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setModelo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelo(false);
    }
  }

  async function handleDropDownMarca(value: any) {
    try {
      const modelo = await loadModelo(value.target.value);

      setFilter({ ...filter, marca: value.target.value, modelo });
    } catch (error) {
      console.error(error);
    }
  }

  async function searchIngreseElVin(event: any) {
    await loadIngreseElVin(event.query);
  }

  async function loadIngreseElVin(query = "") {
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.country
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: filter.vin,
          }
        );
        if (status === 200) {
          if (query) {
            setIngreseElVin(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setIngreseElVin(data);
          }
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "País, Clientes e Cuentas Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [transportista, setTransportista] = useState([]);
  const [loadingDiasCalculados, setLoadingDiasCalculados] = useState(false);

  async function loadDiasCalculados() {
    try {
      setLoadingDiasCalculados(true);
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-remitos-concesionario/dias-calculo-fabricacion",
        {
          pais: securityFilters.securityValues.country,
        }
      );
      if (status === HttpStatusCode.Ok) {
        setTransportista(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDiasCalculados(false);
    }
  }

  const [diasCalculados, setDiasCalculados] = useState([]);
  const [loadingTransportista, setLoadingTransportista] = useState(false);
  async function loadTransportista() {
    try {
      setLoadingTransportista(true);
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-remitos-concesionario/proveedor",
        {
          pais: securityFilters.securityValues.country,
        }
      );
      if (status === HttpStatusCode.Ok) {
        setDiasCalculados(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransportista(false);
    }
  }
  const [loadingConcesionarioFooter, setLoadingConcesionarioFooter] = useState(false);
  async function loadConcesionarioFooter() {
    try {
      setLoadingConcesionarioFooter(true);
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-remitos-concesionario/concesionario",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
        }
      );
      if (status === HttpStatusCode.Ok) {
        setDiasCalculados(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConcesionarioFooter(false);
    }
  }

  const [direccionFooter, setDireccionFooter] = useState([]);
  const [loadingDireccionFooter, setLoadingDireccionFooter] = useState(false);
  async function loadDireccionFooter() {
    try {
      setLoadingDireccionFooter(true);
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-remitos-concesionario/direccion-entrega",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
        }
      );

      if (status === HttpStatusCode.Ok) {
        setDireccionFooter(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDireccionFooter(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      const tipoRemito = await loadRemitos();
      const mercados = await loadMercados();
      const estados = await loadEstado();
      const concesionario = await loadConcesionario();
      const marca = await loadMarca();
      const bloqueoCalidad = await loadBloqueoCalidad();
      const transportista = await loadTransportista();
      const diasCalculados = await loadDiasCalculados();
      const concesionarioFooter = await loadConcesionarioFooter();
      const direccionFooter = await loadDireccionFooter();


      setFilter({
        ...filter,
        tipoRemito,
        mercados,
        estados,
        concesionario,
        marca,
        bloqueoCalidad,
        transportista,
        diasCalculados,
        concesionarioFooter,
        direccionFooter
      });

    };
    init();
  }, [securityFilters.securityValues]);


  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null;
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-remitos-concesionario/findBy",
        {
          pais: securityFilters?.securityValues?.country || null,
          cliente: securityFilters?.securityValues?.client || null,
          cuenta: securityFilters?.securityValues?.account || null,
          subcuenta: securityFilters?.securityValues?.subaccount || null,
          vin: filter.vin || null,
          tipoRemito: filter.tipoRemito || null,
          mercado: filter.mercados || null,
          marca: filter.marca || null,
          modelo: filter.modelo.length === 0 ? null : filter.modelo,
          concesionario: filter.concesionario || null,
          direccionEntrega: filter.direccion || null,
          estadoRemito: filter.estados || null,
          letra: filter.letra || null,
          puntoVenta: filter.puntoVenta || null,
          numero: filter.numero || null,
          bloqueoCalidad: filter.bloqueoCalidad || null,
          chkZona: filter.capitalyHBA,
        }
      );

      if (status === HttpStatusCode.Ok) {
        setReporte(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }


  return (
    <div className="administracion-remitos-a-concesionarios">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"16656"} alt="Tipo de venta" />}
        >
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"848"} alt="Tipo de Remito" />
              </label>
              <SipcoDropdown
                loading={loadingTipoRemitos}
                options={tipoRemitos}
                value={filter?.tipoRemito}
                onChange={(e) => setFilter({ ...filter, tipoRemito: e.value })}
                optionLabel="descripcion"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"10774"} alt="Mercados" />
              </label>
              <SipcoDropdown
                loading={loadingMercados}
                options={mercados}
                value={filter?.mercados}
                onChange={(e) => setFilter({ ...filter, mercados: e.value })}
                optionLabel="descripcion"
              />
            </div>
            <div className="RadioButtonFilter">
              <Checkbox
                checked={filter.capitalyHBA}
                onChange={(e) => {
                  setFilter({ ...filter, capitalyHBA: e.checked });
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={"8588"} alt="Capital y GBA" />
              </label>
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Ingrese el Vin" />
              </label>
              <SipcoAutocomplete
                value={filter?.vin}
                suggestions={ingreseElVin}
                completeMethod={searchIngreseElVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value })}
                field="id"
                dropdown
              />
            </div>
          </div>
        </AccordionTab>

        <AccordionTab
          header={<LanguageProvider id={"7795"} alt="Filtrar Por:" />}
        >
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"6781"} alt="Letra" />
              </label>
              <SipcoInputText
                value={filter?.letra}
                onChange={(e) => setFilter({ ...filter, letra: e.target.value })}
                maxLength={1}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"13358"} alt="Punto Venta" />
              </label>
              <SipcoInputText
                value={filter?.puntoVenta}
                onChange={(e) => setFilter({ ...filter, puntoVenta: e.target.value })}
                maxLength={4}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"851"} alt="Número" />
              </label>
              <SipcoInputText
                value={filter?.numero}
                onChange={(e) => setFilter({ ...filter, numero: e.target.value })}
                maxLength={8}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"1151"} alt="Estados" />
              </label>
              <SipcoDropdown
                loading={loadingEstados}
                value={filter?.estados}
                options={estados}
                onChange={(e) => setFilter({ ...filter, estados: e.value })}
                optionLabel="descripcion"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"2941"} alt="Concesionario" />
              </label>
              <SipcoDropdown
                loading={loadingConcesionario}
                options={concesionario}
                value={filter?.concesionario}
                onChange={(e: any) => handleDropDownConcesionario(e)}
                optionLabel="descripcion"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"396"} alt="Marca" />
              </label>
              <SipcoDropdown
                loading={loadingMarca}
                options={marca}
                value={filter?.marca}
                onChange={(e: any) => handleDropDownMarca(e)}
                optionLabel="descripcion"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"1595"} alt="Bloqueo Calidad" />
              </label>
              <SipcoDropdown
                loading={loadingBloqueoCalidad}
                options={bloqueoCalidad}
                value={filter?.bloqueoCalidad}
                onChange={(e) =>
                  setFilter({ ...filter, bloqueoCalidad: e.value })
                }
                optionLabel="descripcion"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"24014"} alt="Dirección" />
              </label>
              <SipcoDropdown
                loading={loadingDireccion}
                options={direccion}
                value={filter.direccion}
                onChange={(e) => setFilter({ ...filter, direccion: e.value })}
                optionLabel="descripcion"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"10746"} alt="Modelo" />
              </label>
              <SipcoDropdown
                loading={loadingModelo}
                options={modelo}
                value={filter?.modelo}
                onChange={(e) => setFilter({ ...filter, modelo: e.value })}
                optionLabel="descripcion"
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>


      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Administración de Remitos
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              <div className="tags">
                <Tag value="Vin en taller" style={{ background: "#ff0000" }} />
                <Tag
                  value="Vin Indisponible"
                  style={{ background: "#0000db" }}
                />
                <Tag
                  value="Vehivulos Pendiente Taller"
                  style={{ background: "#c0c000" }}
                />
                <Tag
                  value="VIN No Nacionalizado"
                  style={{ background: "#ff8000" }}
                />
                <Tag
                  value="Vin con 150 dias desde el Ecom"
                  style={{ background: "#808000" }}
                />
                <Tag
                  value="Operaciones bloqueantes y no bloqueantes"
                  style={{ background: "#800040" }}
                />
                <Tag
                  value="Liberacion Remito Bloqueada"
                  style={{ background: "#00aa00" }}
                />
              </div>
              <div className="form ">
                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"754"} alt="Transportista" />
                    </label>
                    <SipcoDropdown
                      loading={loadingTransportista}
                      value={filter.transportista}
                      options={diasCalculados}
                      optionLabel="descripcion"
                      disabled={true}
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"2941"} alt="Concesionario" />
                    </label>
                    <SipcoDropdown
                      loading={loadingConcesionarioFooter}
                      value={filter.concesionarioFooter}
                      options={diasCalculados}
                      optionLabel="descripcion"
                      disabled={true}
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"864"}
                        alt="Registros Seleccionados"
                      />
                    </label>
                    <SipcoInputText
                      value={filter.registrosSelecionados}
                      onChange={(e) => setFilter({ ...filter, registrosSelecionados: e.target.value })}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"3625"} alt="Observaciones" />
                    </label>
                    <SipcoDropdown
                      disabled
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"3069"} alt="Dirección" />
                    </label>
                    <SipcoDropdown
                      disabled
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"2587"}
                        alt="Parametro para fecha Nulo"
                      />
                    </label>
                    <SipcoInputText
                      value={filter?.parametrosFechaNulo}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          parametrosFechaNulo: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="RadioButtonFilter">
                    <Checkbox
                      checked={filter.capitalyHBA}
                      onChange={(e) => {
                        setFilter({ ...filter, capitalyHBA: e.checked });
                      }}
                      style={{ justifyContent: "center", position: "relative" }}
                    />
                    <label>
                      <LanguageProvider id={"25071"} alt="Liberar PSA" />
                    </label>
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"3625"} alt="Observaciones" />
                    </label>
                    <SipcoAutocomplete
                      // value={filter.letra}
                      // suggestions={playas}
                      // completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"3116"}
                        alt="Cantidad de Registros"
                      />
                    </label>
                    <SipcoAutocomplete
                      // value={filter.letra}
                      // suggestions={playas}
                      // completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                    />
                  </div>
                </div>
              </div>

              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({
                    id: "748",
                    alt: "Seleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "749",
                    alt: "Deseleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "1522",
                    alt: "Retener el/los remitos",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "1523",
                    alt: "Liberar el/los remitos",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "25066",
                    alt: "Anulacion de Liberacion",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "27", alt: "Modificar" })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "187",
                    alt: "Imprimir o generar archivo Excel",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={20}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {columnsNamesTable.map((colum, index) => {
            if (colum.header === "Fecha Emision") {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </SipcoDataTable>
      </Panel>
    </div>
  );
}
export default AdministracionRemitosAConcesionarios;
