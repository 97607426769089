import "./AdministracionRemitosFicticio.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { HttpStatusCode } from "axios";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const dataTableColumns = [
  {
    field: "remito.leyendaRemito.id",
    header: <LanguageProvider id="16757" alt="x" />,
  },
  {
    field: "remito.leyendaRemito.descripcion",
    header: <LanguageProvider id="16757" alt="x" />,
  },
  {
    field: "remito.estadoRemito.descripcion",
    header: <LanguageProvider id="3344" alt="x" />,
  },
  {
    field: "remito.concesionario.id",
    header: <LanguageProvider id="2941" alt="x" />,
  },
  {
    field: "remito.id",
    header: <LanguageProvider id="932" alt="x" />,
  },
  {
    field: "remito.vin.id",
    header: <LanguageProvider id="1491" alt="x" />,
  },
  {
    field: "activoBloqueaOpPlaya",
    header: <LanguageProvider id="14370" alt="x" />,
  },
  {
    field: "remito.fechaEmision.date",
    header: <LanguageProvider id="1492" alt="x" />,
    body: (rowData: any) =>
      rowData.remito?.fechaEmision?.date ? (
        <span>
          {new Date(rowData.remito.fechaEmision.date).toLocaleDateString()}
        </span>
      ) : (
        "-"
      ),
  },
  {
    field: "remito.vin.marca.descripcion",
    header: <LanguageProvider id="396" alt="x" />,
  },
  {
    field: "remito.vin.modelo.descripcion",
    header: <LanguageProvider id="2139" alt="x" />,
  },
  {
    field: "remito.vin.color.descripcion",
    header: <LanguageProvider id="2140" alt="x" />,
  },
  {
    field: "remito.letra",
    header: <LanguageProvider id="849" alt="x" />,
  },
  {
    field: "remito.puntoDeVenta",
    header: <LanguageProvider id="850" alt="x" />,
  },
  {
    field: "remito.direccionEntrega.id",
    header: <LanguageProvider id="4216" alt="x" />,
  },
  {
    field: "remito.direccionEntrega.direccion",
    header: <LanguageProvider id="3069" alt="x" />,
  },
  {
    field: "remito.razonSocialImpresion",
    header: <LanguageProvider id="3591" alt="x" />,
  },
  {
    field: "remito.domicilioImpresion",
    header: <LanguageProvider id="8299" alt="x" />,
  },
  {
    field: "remito.provinciaImpresion",
    header: <LanguageProvider id="159" alt="x" />,
  },
  {
    field: "remito.localidadImpresion",
    header: <LanguageProvider id="3186" alt="x" />,
  },
  {
    field: "remito.vin.modeloLargo",
    header: <LanguageProvider id="13338" alt="x" />,
  },
  {
    field: "rangosSaneamientoBloquea",
    header: <LanguageProvider id="16264" alt="x" />,
  },
  {
    field: "remito.direccionEntrega.corredor.descripcion",
    header: <LanguageProvider id="10502" alt="x" />,
  },
  {
    field: "remito.vin.mercado.id",
    header: <LanguageProvider id="367" alt="x" />,
  },
  {
    field: "remito.vin.mercado.descripcion",
    header: <LanguageProvider id="1" alt="x" />,
  },
  {
    field: "remito.vin.descripcionIngresoTaller",
    header: <LanguageProvider id="16758" alt="x" />,
  },
  {
    field: "remito.remitoTransferencia",
    header: <LanguageProvider id="10906" alt="x" />,
    body: (rowData: any) => (rowData.remito?.remitoTransferencia ? "SI" : "NO"),
  },
  {
    field: "remito.vin.fechaSalidaFabrica.date",
    header: <LanguageProvider id="1719" alt="x" />,
    body: (rowData: any) =>
      rowData.remito?.vin?.fechaSalidaFabrica?.date ? (
        <span>
          {new Date(
            rowData.remito.vin.fechaSalidaFabrica.date
          ).toLocaleDateString()}
        </span>
      ) : (
        "-"
      ),
  },
  {
    field: "remito.vin.fechaBloqueoCalidad.date",
    header: <LanguageProvider id="2368" alt="x" />,
    body: (rowData: any) =>
      rowData.remito?.vin?.fechaBloqueoCalidad?.date ? (
        <span>
          {new Date(
            rowData.remito.vin.fechaBloqueoCalidad.date
          ).toLocaleDateString()}
        </span>
      ) : (
        "-"
      ),
  },
  {
    field: "remito.ficticio",
    header: <LanguageProvider id="22446" alt="x" />,
    body: (rowData: any) => (rowData.remito?.ficticio ? "SI" : "NO"),
  },
];

const TODOS_OBJ = {
  id: "TODOS",
  descripcionFull: "TODOS",
  descripcion: "TODOS",
  label: "TODOS",
  direccion: "TODOS",
};

const colorClasses: { [key: string]: string } = {
  "0xFF0000": "color-FF0000",
  "0x0000FF": "color-0000FF",
  "0xC0C000": "color-C0C000",
  "0x00AA00": "color-00AA00",
  "0xFF8000": "color-FF8000",
  "0x808000": "color-808000",
  "0x800040": "color-800040",
};

export function AdministracionRemitosFicticio() {
  const SCREEN_CODE = "fun0162_administracion_remitos_ficticio";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);

  const [loadingDataTable, setLoadingDataTable] = useState(false);
  const toast = useRef<Toast>(null);

  const [dataTableValue, setDataTableValue] = useState([]);
  const [filter, setFilter] = useState({
    tipoRemito: null,
    mercado: null,
    capitalYGba: true,
    vin: null,
    letra: "",
    puntoDeVenta: "",
    idRemito: "",
    estados: null,
    concesionario: null,
    marca: null,
    bloqueoCalidad: null,
    direccionEntrega: null,
    modelo: null,
  });

  /**
   * filtro usado no teste
   * pais:1,
   * cliente: 00000453,
   * cuenta: 1
   * subcuenta: 1
   * demais filtros sera TODOS só alterar
   * marca para 7
   * modelo para 1CEAA5NKNM27A0C1
   *
   */
  async function loadReportData() {
    try {
      setLoadingDataTable(true);
      const payload = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        cuenta: securityFilters.securityValues.account,
        subcuenta: securityFilters.securityValues.subaccount,
        vin: filter.vin?.id ? filter.vin : null,
        tipoRemito:
          filter.tipoRemito?.id !== "TODOS" ? filter.tipoRemito : null,
        mercado: filter.mercado?.id !== "TODOS" ? filter.mercado : null,
        marca: filter.marca?.id !== "TODOS" ? filter.marca : null,
        modelo: filter.modelo?.id !== "TODOS" ? filter.modelo : null,
        concesionario:
          filter.concesionario?.id !== "TODOS" ? filter.concesionario : null,
        direccionEntrega:
          filter.direccionEntrega?.direccion !== "TODOS"
            ? filter.direccionEntrega
            : null,
        estadoRemito: filter.estados?.id !== "TODOS" ? filter.estados : null,
        letra: filter.letra,
        puntoDeVenta: filter.puntoDeVenta,
        idRemito: filter.idRemito,
        bloqueoCalidad:
          filter.bloqueoCalidad?.id !== "TODOS" ? filter.bloqueoCalidad : null,
        capitalGranBsAs: filter.capitalYGba,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-remitos-ficticio/ficticios",
        payload
      );
      if (status === 200) {
        if (data.length > 0) {
          setDataTableValue(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTable(false);
    }
  }

  const columnsNamesTable = [
    { field: "codCompra", header: "Leyenda" },
    { field: "tipoTaller", header: "Leyenda" },
    { field: "descripcion", header: "Estado" },
    { field: "fechaCompra", header: "Concesionario" },
    { field: "codEstado", header: "Código Remito" },
    { field: "codEstado", header: "Fecha generación OT" },
    { field: "codEstado", header: "Fecha Emision" },
    { field: "codEstado", header: "Marca" },
    { field: "codEstado", header: "Modelo" },
    { field: "codEstado", header: "Color" },
  ];

  const [tipoRemito, setTipoRemito] = useState([]);
  const [loadingTipoRemito, setLoadingTipoRemito] = useState(false);
  async function loadTipoRemito() {
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-ficticio/tipo-remito",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          setTipoRemito(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [mercado, setMercado] = useState([]);
  const [loadingMercado, setLoadingMercado] = useState(false);
  async function loadMercado() {
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-ficticio/mercado",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          setMercado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(query: any) {
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-ficticio/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: {
              id: query,
            },
          }
        );
        if (status === 200) {
          setVin(data);
          return data;
        }
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [estados, setEstados] = useState([]);
  const [loadingEstados, setLoadingEstados] = useState(false);
  async function loadEstados() {
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-ficticio/estado-remito",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            updatedData.unshift(TODOS_OBJ);
            setEstados(updatedData);
            return updatedData;
          }
          return [];
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [concesionario, setConcesionario] = useState([]);
  const [loadingConcesionario, setLoadingConcesionario] = useState(false);
  async function loadConcesionario() {
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-ficticio/concesionario",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            updatedData.unshift(TODOS_OBJ);
            setConcesionario(updatedData);
            return updatedData;
          }
          return [];
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [direccion, setDireccion] = useState([]);
  const [loadingDireccion, setLoadingDireccion] = useState(false);
  async function loadDireccion() {
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        filter.concesionario != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-ficticio/direccion-entrega",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            concesionario: filter.concesionario,
          }
        );
        if (status === 200) {
          const updatedData = data.map((item: any) => ({
            ...item,
            search: `${item.direccion}${item.id}`,
          }));
          updatedData.unshift(TODOS_OBJ);
          setDireccion(updatedData);
          return updatedData;
        }
        return [];
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [marcas, setMarcas] = useState([]);
  const [loadingMarcas, setLoadingMarcas] = useState(false);
  async function loadMarca() {
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-ficticio/marca",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            updatedData.unshift(TODOS_OBJ);
            setMarcas(updatedData);
            return updatedData;
          }
          return [];
        }
        return [];
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [modelo, setModelo] = useState([]);
  const [loadingModelo, setLoadingModelo] = useState(false);
  async function loadModelo() {
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        filter.marca != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-ficticio/modelo",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca: filter.marca,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = data.map((item: any) => ({
              ...item,
              search: `${item.descripcion}${item.id}`,
            }));
            data.unshift(TODOS_OBJ);
            setModelo(updatedData);
            return updatedData;
          }
          return [];
        }
        return [];
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [bloqueoCalidad, setBloqueoCalidad] = useState([]);
  const [loadingBloqueoCalidad, setLoadingBloqueoCalidad] = useState(false);
  async function loadBloqueoCalidad() {
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-ficticio/bloqueo",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          data.unshift(TODOS_OBJ);
          setBloqueoCalidad(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  function setloadings(boolean) {
    setLoadingTipoRemito(boolean);
    setLoadingMercado(boolean);
    setLoadingEstados(boolean);
    setLoadingMarcas(boolean);
    setLoadingBloqueoCalidad(boolean);
  }

  const DEBOUNCE_DELAY = 1000; // 1000ms de delay para debouncing

  // Efeito para carregar as chamadas mais rápidas (sem incluir loadConcesionario)
  useEffect(() => {
    const timer = setTimeout(() => {
      async function initialize() {
        if (
          securityFilters?.securityValues?.country &&
          securityFilters?.securityValues?.client
        ) {
          try {
            setloadings(true);

            // Usando Promise.allSettled para tratar múltiplas promessas de forma resiliente
            const results = await Promise.allSettled([
              loadTipoRemito(),
              loadMercado(),
              loadEstados(),
              loadMarca(),
              loadBloqueoCalidad(),
            ]);

            // Filtra as promessas resolvidas e trata os resultados
            const [tipoRemito, mercado, estados, marca, bloqueoCalidad] =
              results.map((result) =>
                result.status === "fulfilled" ? result.value[0] : null
              );

            setFilter({
              ...filter,
              tipoRemito,
              mercado,
              estados,
              marca,
              bloqueoCalidad,
            });
          } catch (error) {
            console.error(error);
          } finally {
            setloadings(false);
          }
        }
      }

      initialize();
    }, DEBOUNCE_DELAY);

    // Limpa o timer quando as dependências mudam
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    securityFilters?.securityValues?.country,
    securityFilters?.securityValues?.client,
  ]);

  // Efeito separado para carregar o loadConcesionario (chamada mais lenta) com debounce
  useEffect(() => {
    const timer = setTimeout(() => {
      async function loadConcesionarioData() {
        if (
          securityFilters?.securityValues?.country && // Verifica a mudança no país também
          securityFilters?.securityValues?.client
        ) {
          try {
            setLoadingConcesionario(true); 
            const concesionario = await loadConcesionario();
            setFilter((prevFilter) => ({
              ...prevFilter,
              concesionario: concesionario[0],
            }));
          } catch (error) {
            console.error(error);
          } finally {
            setLoadingConcesionario(false);
          }
        }
      }

      loadConcesionarioData();
    }, DEBOUNCE_DELAY); // Aplica o debounce na chamada da função loadConcesionarioData

    // Limpa o timer quando as dependências mudam
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    securityFilters?.securityValues?.country,
    securityFilters?.securityValues?.client,
  ]);

  useEffect(() => {
    async function handleDireccion() {
      if (
        filter.concesionario !== null &&
        filter.concesionario?.id !== "TODOS"
      ) {
        try {
          setLoadingDireccion(true);
          const direccion = await loadDireccion();
          setFilter({
            ...filter,
            direccionEntrega: direccion[0],
          });
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingDireccion(false);
        }
      }
    }
    handleDireccion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.concesionario]);

  useEffect(() => {
    async function handleModelo() {
      if (filter.marca !== null && filter.marca?.id !== "TODOS") {
        try {
          setLoadingModelo(true);
          const modelo = await loadModelo();
          setFilter({
            ...filter,
            modelo: modelo[0],
          });
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingModelo(false);
        }
      }
    }
    handleModelo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.marca]);

  return (
    <div className="administracion-remitos-ficticio">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"23671"} alt="Tipo Venta" />}
        >
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"848"} alt="Tipo de Remito " />
                </label>
                <SipcoDropdown
                  value={filter.tipoRemito}
                  options={tipoRemito}
                  onChange={(e) =>
                    setFilter({ ...filter, tipoRemito: e.value })
                  }
                  optionLabel="descripcion"
                  loading={loadingTipoRemito}
                  filter
                  filterBy="search"
                  itemTemplate={DropdownOptionTemplate}
                  valueTemplate={DropdownOptionTemplate}
                  placeholder={LanguageProvider({
                    id: "848",
                    alt: "Tipo de Remito",
                  })}
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"367"} alt="Mercado" />
                </label>
                <SipcoDropdown
                  value={filter.mercado}
                  options={mercado}
                  onChange={(e) => setFilter({ ...filter, mercado: e.value })}
                  optionLabel="descripcion"
                  loading={loadingMercado}
                  filter
                  filterBy="search"
                  itemTemplate={DropdownOptionTemplate}
                  valueTemplate={DropdownOptionTemplate}
                  placeholder={LanguageProvider({
                    id: "367",
                    alt: "Mercado",
                  })}
                />
              </div>

              <div className={"sipco-option"}>
                <div className="checkboxSipco">
                  <label>
                    <LanguageProvider id={"8588"} alt="Capital y GBA" />
                  </label>
                  <Checkbox checked />
                </div>
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter?.vin?.id}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.value || null })
                  }
                  field="id"
                  forceSelection
                  dropdown
                />
              </div>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab
          header={<LanguageProvider id={"22550"} alt="Filtrar por" />}
        >
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"849"} alt="Letra " />
                </label>
                <SipcoInputText />
                <label>
                  <LanguageProvider id={"1151"} alt="Estados " />
                </label>
                <SipcoDropdown
                  value={filter.estados}
                  options={estados}
                  onChange={(e) => setFilter({ ...filter, estados: e.value })}
                  optionLabel="descripcion"
                  loading={loadingEstados}
                  filter
                  filterBy="search"
                  itemTemplate={DropdownOptionTemplate}
                  valueTemplate={DropdownOptionTemplate}
                  placeholder={LanguageProvider({
                    id: "1151",
                    alt: "Estados",
                  })}
                />
                <label>
                  <LanguageProvider id={"1595"} alt="Bloqueo Calidad " />
                </label>
                <SipcoDropdown
                  value={filter.bloqueoCalidad}
                  options={bloqueoCalidad}
                  onChange={(e) =>
                    setFilter({ ...filter, bloqueoCalidad: e.value })
                  }
                  optionLabel="descripcion"
                  loading={loadingBloqueoCalidad}
                  filter
                  filterBy="search"
                  itemTemplate={DropdownOptionTemplate}
                  valueTemplate={DropdownOptionTemplate}
                  placeholder={LanguageProvider({
                    id: "1595",
                    alt: "Bloqueo Calidad",
                  })}
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13358"} alt="Punto Venta " />
                </label>
                <SipcoInputText />
                <label>
                  <LanguageProvider id={"2941"} alt="Concesionario " />
                </label>
                <SipcoDropdown
                  value={filter.concesionario}
                  options={concesionario}
                  onChange={(e) =>
                    setFilter({ ...filter, concesionario: e.value })
                  }
                  optionLabel="descripcion"
                  loading={loadingConcesionario}
                  filter
                  filterBy="search"
                  itemTemplate={DropdownOptionTemplate}
                  valueTemplate={DropdownOptionTemplate}
                  placeholder={LanguageProvider({
                    id: "2941",
                    alt: "Concesionario",
                  })}
                />
                <label>
                  <LanguageProvider id={"3069"} alt="Dirección " />
                </label>
                <SipcoDropdown
                  value={filter.direccionEntrega}
                  options={direccion}
                  onChange={(e) =>
                    setFilter({ ...filter, direccionEntrega: e.value })
                  }
                  optionLabel="direccion"
                  loading={loadingDireccion}
                  filter
                  filterBy="search"
                  itemTemplate={DropdownOptionTemplatedireccion}
                  valueTemplate={DropdownOptionTemplatedireccion}
                  placeholder={LanguageProvider({
                    id: "3069",
                    alt: "Dirección",
                  })}
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"851"} alt="Número" />
                </label>
                <SipcoInputText
                  value={filter.idRemito}
                  onChange={(e) =>
                    setFilter({ ...filter, idRemito: e.target.value })
                  }
                />
                <label>
                  <LanguageProvider id={"23888"} alt="Marca " />
                </label>
                <SipcoDropdown
                  value={filter.marca}
                  options={marcas}
                  onChange={(e) => setFilter({ ...filter, marca: e.value })}
                  optionLabel="descripcion"
                  loading={loadingMarcas}
                  filter
                  filterBy="search"
                  itemTemplate={DropdownOptionTemplate}
                  valueTemplate={DropdownOptionTemplate}
                  placeholder={LanguageProvider({
                    id: "23888",
                    alt: "Marca",
                  })}
                />
                <label>
                  <LanguageProvider id={"10746"} alt="Modelo " />
                </label>
                <SipcoDropdown
                  value={filter.modelo}
                  options={modelo}
                  onChange={(e) => setFilter({ ...filter, modelo: e.value })}
                  optionLabel="descripcion"
                  loading={loadingModelo}
                  filter
                  filterBy="search"
                  itemTemplate={DropdownOptionTemplate}
                  valueTemplate={DropdownOptionTemplate}
                  placeholder={LanguageProvider({
                    id: "10746",
                    alt: "modelo",
                  })}
                  virtualScrollerOptions={{ itemSize: 20 }}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Ingreso Vines y Daños Por Archivo Texto.
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={dataTableValue}
                  columns={columnsNamesTable}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({
                    id: "748",
                    alt: "Seleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "749",
                    alt: "Deseleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "24447",
                    alt: "Marcar Ficticio",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "24448",
                    alt: "Desmarcar Ficticio",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          // size={"small"}
          // showGridlines
          // stripedRows
          // ref={dt}
          value={dataTableValue}
          // paginator
          // scrollHeight="flex"
          // filterDisplay="menu"
          // rows={25}
          // rowsPerPageOptions={[5, 10, 25, 50]}
          // paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          // currentPageReportTemplate="{first} to {last} of {totalRecords}"
          // style={{ maxWidth: "100%" }}
          rowClassName={(rowData) => colorClasses[rowData.color] || ""}
          loading={loadingDataTable}
        >
          {dataTableColumns.map((dataTableColumn, key) => (
            <SipcoColumn
              key={key}
              field={dataTableColumn.field}
              header={dataTableColumn.header}
              body={dataTableColumn.body}
              filter
              sortable
            />
          ))}
        </SipcoDataTable>
        <Divider />

        <div className="form">
          <div className={"form-row"}>
            <div className="sipco-option">
              <Tag
                value={<LanguageProvider id={"4009"} alt="VIN en taller" />}
                style={{ background: "red" }}
              />
            </div>
            <div className="sipco-option">
              <Tag
                value={<LanguageProvider id={"9428"} alt="VIN Indisponible" />}
                style={{ background: "blue" }}
              />
            </div>
            <div className="sipco-option">
              <Tag
                value={
                  <LanguageProvider
                    id={"9854"}
                    alt="Vehiculos Pendiente Taller"
                  />
                }
                style={{ background: "#DEDE7A" }}
              />
            </div>
            <div className="sipco-option">
              <Tag
                value={
                  <LanguageProvider id={"11780"} alt="VIN No Nacionalizado" />
                }
                style={{ background: "orange" }}
              />
            </div>
            <div className="sipco-option">
              <Tag
                value={
                  <LanguageProvider
                    id={"12003"}
                    alt="Vin con 150 dias desde el Ecom"
                  />
                }
                style={{ background: "#91911C" }}
              />
            </div>
            <div className="sipco-option">
              <Tag
                value={
                  <LanguageProvider
                    id={"13534"}
                    alt="Operaciones bloqueantes y no bloqueantes"
                  />
                }
                style={{ background: "#811C91" }}
              />
            </div>
            <div className="sipco-option">
              <Tag
                value={
                  <LanguageProvider
                    id={"11454"}
                    alt="Liberacion Remito Bloqueada"
                  />
                }
                style={{ background: "green" }}
              />
            </div>
          </div>
        </div>

        <div className="form" style={{ marginTop: "40px" }}>
          <div className={"form-row"}>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"864"} alt="Registros Seleccionados: " />
              </label>
              <SipcoInputText />

              <label>
                <LanguageProvider id={"3116"} alt="Cantidad de Registros " />
              </label>
              <SipcoDropdown />
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
}

const DropdownOptionTemplate = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
const DropdownOptionTemplatedireccion = (option: any, props = null) => {
  const style = option?.activo === false ? { color: "red" } : {};
  if (option)
    return (
      <div className="flex align-items-center" style={style}>
        {option.direccion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
