import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ContableFamiliaRadio } from "src/models/ContableFamiliaRadio";

const MantenerContableFamiliasRadio = () => {
  
  const SCREEN_CODE = "abm0078_mantener_contable_familias_radio";
  const URL_BASE = "/mantener-contable-familias-radio";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: ContableFamiliaRadio, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const contableFamiliaRadio = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
          descripcion: model.descripcion,
          active: model.activo,
          id: model.id,
          generaContable: model.generaContable,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          contableFamiliaRadio,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: ContableFamiliaRadio, filterValues: any) {
    try {
      const contableFamiliaRadio = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
        descripcion: model.descripcion,
        active: model.activo,
        id: model.id,
        generaContable: model.generaContable,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        contableFamiliaRadio,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: ContableFamiliaRadio, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        contableFamiliaRadio: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<ContableFamiliaRadio[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
          marca: filter.marca,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<ContableFamiliaRadio> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          contableFamiliaRadio: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }


  return (
    <GenericAbm<ContableFamiliaRadio>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="6697" alt="Listado" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          client: true,
          
        },
      }}
      dataTableColumns={[
        { field: "id", header: <LanguageProvider id="6590" alt="Código" /> },
        {
          field: "descripcion",
          header: <LanguageProvider id="12419" alt="Descripcion" />,
        },
        {
          field: "generaContable",
          header: <LanguageProvider id="2852" alt="Generar Contable" />,
          body: (rowData: any) => rowData.generaContable ? "SI" : "NO",
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Ult. Modificación" />,
          body: (rowData: any) => (
            <span>
              {new Date(rowData.fechaUltimaModificacion.date).toLocaleString()}
            </span>
          ),
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => rowData.activo ? "SI" : "NO",
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: '',
        activo: true,
        pais: null,
        fechaUltimaModificacion: null,
        cliente: null,
        usuarioUltimaModificacion: null,
        descripcion: '',
        generaContable: false
      }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          length: 3,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="12419" alt="Descripcion" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "generaContable",
          label: <LanguageProvider id="2852" alt="Generar Contable" />,
          type: "checkbox",
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerContableFamiliasRadio;


const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};