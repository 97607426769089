import "./DevolucionMercadoLocal.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format } from "date-fns";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { LanguageProviderString } from "@shared/components/language-provider-string";
import useAuth from "@shared/AuthContext";
import useFilterColumnsIds from "../../../hooks/useFilterColumnsIds";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import PopupDevolucionDanios from "./components/PopupDevolucionDanios";

const columnsNamesTableIds = [
  { header: 849, field: "remito.letra" },
  { header: 850, field: "remito.puntoDeVenta" },
  { header: 932, field: "remito.id" },
  { header: 54, field: "cliente.descripcion" },
  { header: 1491, field: "vin.id" },
  { header: 396, field: "vin.marca.descripcion" },
  { header: 2139, field: "vin.modelo.descripcion" },
  { header: 2140, field: "vin.color.descripcion" },
  { header: 2941, field: "concesionarioP.descripcion" },
  { header: 4216, field: "direccionEntregaP.id" },
];

export function DevolucionMercadoLocal() {
  const SCREEN_CODE = "fun0057_devolucion_mercado_local";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const { user } = useAuth();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const columnsNamesTable = useFilterColumnsIds(columnsNamesTableIds);
  const [filter, setFilter] = useState({
    hojaRuta: null,
    vin: null,
    called: null,
    column: null,
    nivel: null,
    transportista: "",
    chofer: "",
    tractor: null,
    cantidadUnidades: "",
    compraFlete: false,
    ventaFlete: false,
    nota: "",
  });

  const [tableHojaRuta, setTableHojaRuta] = useState([]);
  const [vinSuggestions, setVinSuggestions] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);
  const [hojaRutaOptions, setHojaRutaOptions] = useState([]);
  const [loadingHojaRuta, setLoadingHojaRuta] = useState(false);
  const [columnOptions, setColumnOptions] = useState([]);
  const [loadingColumns, setLoadingColumns] = useState(false);
  const [nivelOptions, setNivelOptions] = useState([]);
  const [loadingNivel, setLoadingNivel] = useState(false);
  const [fullHojaRuta, setFullHojaRuta] = useState(null);
  const [calledOptions, setCalledOptions] = useState([]);
  const [loadingCalled, setLoadingCalled] = useState(false);
  const [visiblePopupDevolucionDanios, setVisiblePopupDevolucionDanios] =
    useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: {
              id: query,
            },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return {
              ...x,
              label: `${x.id}`,
            };
          });

          setVinSuggestions(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  async function loadHojaRuta() {
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/hoja-ruta",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === 200) {
          setHojaRutaOptions(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadFullSearch(hojaRuta: any) {
    try {
      if (securityFilters?.securityValues?.country && hojaRuta) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/full-search",
          {
            hojaRuta: hojaRuta ?? null,
          }
        );

        if (status === 200) {
          setFullHojaRuta(data);
          return data;
        }
      }
    } catch (error) {
      console.error("Error in loadFullSearch:", error);
    }
  }

  async function loadColumns(called: any) {
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/columna",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            calle: called ?? null,
          }
        );
        if (status === 200) {
          setColumnOptions(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadCalled() {
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/calle",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === 200) {
          setCalledOptions(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadNivel(called: any, column: any) {
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/nivel",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            calle: called ?? null,
            columna: column ?? null,
          }
        );
        if (status === 200) {
          setNivelOptions(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function showToast({
    severity,
    messageId,
    alt,
    messageExtra = "",
    life = 3000,
  }) {
    toast.current.show({
      severity,
      detail: `${messageExtra} ${LanguageProviderString({ id: messageId, alt: alt, user })}`,
      life,
    });
  }

  function showConfirmationDialog({
    headerId,
    messageId,
    alt,
    messageExtra = "",
    onAccept,
  }) {
    confirmDialog({
      header: LanguageProviderString({
        id: headerId,
        alt: "Atención",
        user,
      }),
      message: `${messageExtra} ${LanguageProviderString({
        id: messageId,
        alt: alt,
        user,
      })}`,
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => {
        setTimeout(() => {
          onAccept();
        }, 200);
      },
      reject: () => setLoading(false),
    });
  }

  function onValidationProcesarComplete(coef) {
    showConfirmationDialog({
      headerId: "16239",
      messageId: "13275",
      alt: "¿Desea procesar los cambios realizados?",
      messageExtra: coef,
      onAccept: () => procesar(),
    });
  }

  function validDialog(coef) {
    showConfirmationDialog({
      headerId: "16239",
      messageId: "660",
      alt: "Esta excediendo la cantidad permitida - Desea Continuar ?",
      messageExtra: "",
      onAccept: () => onValidationProcesarComplete(coef),
    });
  }

  async function onValidarVinHrDevolucion(coef) {
    try {
      const payload = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        lineas: tableHojaRuta,
        stockPlayaDestino: {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          subcuenta: securityFilters.securityValues.subaccount,
          calle: filter.called,
          columna: filter.column,
          nivel: filter.nivel,
        },
      };

      const { status, data } = await sipcoAxiosService.post(
        "/devolucion-mercado-local/validar",
        payload
      );

      if (status !== 200) {
        return setLoading(false);
      }
      if (data === "") {
        return onValidationProcesarComplete(coef);
      }

      if (data === 660) {
        validDialog(coef);
      } else if (data === 2444) {
        showToast({
          severity: "warn",
          messageId: "2444",
          alt: "El VIN se encuentra en Stock",
          messageExtra: "",
        });
        setLoading(false);
        return;
      } else {
        showToast({
          severity: "warn",
          messageId: data.toString(),
          alt: "error",
          messageExtra: "generic",
        });
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function onCalcularCoef() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/devolucion-mercado-local/calcular-coef-hr-vin",
        {
          hojaRuta: fullHojaRuta,
          enviar: false,
          actualizaLineaBD: true,
        }
      );
      if (status === 200) {
        const coef = `El coeficiente de saturación es: ${data.coeficiente}`;

        onValidarVinHrDevolucion(coef);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function onValidBonificacion() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/devolucion-mercado-local/validar-bonificacion",
        {
          pais: securityFilters.securityValues.country,
          cuenta: securityFilters.securityValues.account,
          subcuenta: securityFilters.securityValues.subaccount,
          hojaRuta: fullHojaRuta,
        }
      );

      if (status === 200) {
        data?.mensaje
          ? showConfirmationDialog({
              headerId: "16239",
              messageId: data.mensaje,
              alt: "msg",
              onAccept: () => onCalcularCoef(),
            })
          : onCalcularCoef();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function onValidaClienteExentaBN() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/devolucion-mercado-local/valida-cliente-exenta-bn",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          hojaRuta: fullHojaRuta,
        }
      );
      if (status === 200) {
        if (data !== null && data !== "") {
          showToast({
            severity: "warn",
            messageId: data.mensaje.toString(),
            alt: "error",
            messageExtra: "generic",
          });
          onValidarVinHrDevolucion("");
        } else {
          onValidBonificacion();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function validProcesar() {
    setLoading(true);
    if (!filter.hojaRuta) {
      showToast({
        severity: "warn",
        messageId: "17292",
        alt: "Debe seleccionar una hoja de ruta",
        messageExtra: "",
      });
      return setLoading(false);
    }

    onValidaClienteExentaBN();
  }

  async function procesar() {
    try {
      // srvRemito.devolucionHRMercadoLocal(filterForm.pais, filterForm.cliente, filterForm.cuenta, lineasHr, this.hr.administradorTractor, stockPlayaDestino, nota, FUNCION, cobraFlete);

      const payload = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        cuenta: securityFilters.securityValues.account,
        lineas: tableHojaRuta,
        transportista: fullHojaRuta?.administradorTractor,
        stockPlayaDestino: {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          subcuenta: securityFilters.securityValues.subaccount,
          calle: filter.called,
          columna: filter.column,
          nivel: filter.nivel,
        },
        nota: filter.nota,
        cobraFlete: fullHojaRuta?.lineas[0]?.compra,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/devolucion-mercado-local/remito",
        payload
      );

      if (status === 200) {
        if (data) {
          showToast({
            severity: "success",
            messageId: "36",
            alt: "Su transacción se realizó con éxito.",
            messageExtra: "",
          });
          refresh();
        }
      } else {
        showToast({
          severity: "error",
          messageId: "11",
          alt: "Ha ocurrido un error, su ultima operación no fue realizada. Comuniquese con Sistemas",
          messageExtra: "",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const refresh = async () => {
    const { country, client, account, subaccount } =
      securityFilters.securityValues;
    if (country && client && account && subaccount) {
      try {
        setLoadingHojaRuta(true);
        setLoadingCalled(true);
        const [hojaRuta, called] = await Promise.all([
          loadHojaRuta(),
          loadCalled(),
        ]);
        setFilter({
          ...filter,
          hojaRuta: hojaRuta?.[0] ?? null,
          called: called?.[0] ?? null,
        });
      } catch (error) {
        console.error("Error in refresh:", error);
      } finally {
        setLoadingHojaRuta(false);
        setLoadingCalled(false);
      }
    }
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues]);

  useEffect(() => {
    const handleHojaRutaChange = async () => {
      setLoading(true);
      try {
        await loadFullSearch(filter.hojaRuta);
        await loadReportData(filter.hojaRuta);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    handleHojaRutaChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.hojaRuta]);

  useEffect(() => {
    const init = async () => {
      if (
        securityFilters.securityValues.country != null &&
        securityFilters.securityValues.client != null &&
        securityFilters.securityValues.account != null &&
        securityFilters.securityValues.subaccount != null
      ) {
        try {
          setLoadingColumns(true);
          setLoadingNivel(true);
          if (filter.called != null) {
            const column = await loadColumns(filter.called ?? null);
            if (column.length > 0) {
              const nivel = await loadNivel(
                filter.called ?? null,
                column[0] ?? null
              );
              setFilter({
                ...filter,
                column: column[0] ?? null,
                nivel: nivel[0] ?? null,
              });
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingColumns(false);
          setLoadingNivel(false);
        }
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.called]);

  async function loadReportData(hojaRuta: any) {
    try {
      if (securityFilters?.securityValues?.country && hojaRuta != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/linea-hora-ruta",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            cliente: securityFilters.securityValues.client,
            subcuenta: securityFilters.securityValues.subaccount,
            hojaRuta: hojaRuta ?? null,
          }
        );

        if (status === 200) {
          console.log("data", data);
          setTableHojaRuta(data);
          return data;
        }
      }
    } catch (error) {
      console.error("Error in loadFullSearch:", error);
    }
  }

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  const [payloadDanios, setPayloadDanios] = useState<{
    pais: string;
    cliente: string;
    cuenta: string;
    vin: { id: string };
  }>({ pais: "", cliente: "", cuenta: "", vin: { id: "" } });

  function pupUpDanios() {
    if (tableHojaRuta.length > 0) {
      setPayloadDanios({
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        cuenta: securityFilters.securityValues.account,
        vin: { id: tableHojaRuta[0]?.vin.id || null },
      });
      setVisiblePopupDevolucionDanios(true);
    } else {
      showToast({
        severity: "warn",
        messageId: "12642",
        alt: "Debe seleccionar al menos un registro",
        messageExtra: "",
      });
    }
  }

  return (
    <div className="devolucion-mercado-local">
      <Toast ref={toast} position="bottom-left" />
      <ConfirmDialog />
      <PopupDevolucionDanios
        payload={payloadDanios}
        visible={visiblePopupDevolucionDanios}
        onClose={() => {
          setVisiblePopupDevolucionDanios(false);
          refresh();
        }}
      />
      <Filter
        onSearch={() => loadReportData(filter.hojaRuta)}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN " />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vinSuggestions}
                  completeMethod={searchVin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.value || null })
                  }
                  field="label"
                  dropdown
                  forceSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"924"} alt="Hoja de Ruta " />
                </label>
                <SipcoDropdown
                  value={filter.hojaRuta}
                  options={hojaRutaOptions}
                  optionLabel="id"
                  onChange={(e) => setFilter({ ...filter, hojaRuta: e.value })}
                  loading={loadingHojaRuta}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Devolucion Nacional</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={tableHojaRuta}
                  columns={columnsNamesTableIds}
                  screenName={"AdministracionRemitos"}
                  csv={false}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  onClick={() => validProcesar()}
                  label={LanguageProvider({ id: "16283", alt: "Procesar" })}
                  text
                  loading={loading}
                />
                <Button
                  label={LanguageProvider({
                    id: "20947",
                    alt: "Modificar Daños",
                  })}
                  onClick={() => pupUpDanios()}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={tableHojaRuta}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
          rowClassName={(rowData) =>
            rowData.vinConDanio !== null ? "row-with-damage" : ""
          }
        >
          {columnsNamesTable.map((colum, index) => {
            if (colum.label === "Fecha Compra") {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.label}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <SipcoColumn
                  key={index}
                  field={colum.field}
                  header={colum.label}
                  sortable
                  filter
                />
              );
            }
          })}
        </SipcoDataTable>
        <div className="filter-options">
          <Tag
            style={{ background: "orange" }}
            value={
              <LanguageProvider
                id={"3674"}
                alt="Vehículo con daños antes de reingresarlo revise y deje los que están vigentes y cargue los nuevos"
              />
            }
          />
        </div>
        <div className="filter-options">
          <div className={"sipco-options-line"}>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"1269"} alt="Ubicacón" />
              </label>
              <SipcoDropdown
                value={filter.called}
                options={calledOptions}
                onChange={(e) => setFilter({ ...filter, called: e.value })}
                loading={loadingCalled}
                filter
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"991"} alt="Columna" />
              </label>
              <SipcoDropdown
                value={filter.column}
                options={columnOptions}
                onChange={(e) => setFilter({ ...filter, column: e.value })}
                loading={loadingColumns}
                filter
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"992"} alt="Nivel" />
              </label>
              <SipcoDropdown
                value={filter.nivel}
                options={nivelOptions}
                onChange={(e) => setFilter({ ...filter, nivel: e.value })}
                loading={loadingNivel}
                filter
              />
            </div>
          </div>

          <div className={"sipco-options-line"}>
            <div className="sipco-option" style={{ width: "100%" }}>
              <label>
                <LanguageProvider id={"1410"} alt="Nota" />
              </label>
              <SipcoInputText
                value={filter?.nota}
                onChange={(e) => setFilter({ ...filter, nota: e.target.value })}
              />
            </div>
          </div>
        </div>
        <Accordion>
          <AccordionTab
            header={<LanguageProvider id={"17355"} alt="Hoja Ruta" />}
          >
            <div className="form" style={{ display: "flex", gap: "20px" }}>
              <div
                className="colunm"
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className="sipco-option"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>
                    <LanguageProvider id={"754"} alt="Transportista" />
                  </label>
                  <SipcoInputText
                    value={fullHojaRuta?.administradorTractor?.descripcion}
                    readOnly
                  />
                </div>
                <div
                  className="sipco-option"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>
                    <LanguageProvider id={"4655"} alt="Chofer" />
                  </label>
                  <SipcoInputText
                    value={`${fullHojaRuta?.chofer.apellido} ${fullHojaRuta?.chofer.nombre}`}
                    readOnly
                  />
                </div>
              </div>
              <div
                className="colunm"
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className="sipco-option"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <label>
                    <LanguageProvider id={"4285"} alt="Tractor" />
                  </label>
                  <SipcoInputText
                    value={fullHojaRuta?.tractor?.descripcion}
                    readOnly
                  />
                </div>

                <div
                  className="sipco-option"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>
                    <LanguageProvider id={"8811"} alt="Cantidad de Unidades" />
                  </label>
                  <SipcoInputText
                    value={
                      fullHojaRuta?.batea?.tipoVehiculo?.cantidadDeVehiculos
                    }
                    readOnly
                  />
                </div>
              </div>

              <div
                className="colunm"
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className="sipco-option"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>
                    <LanguageProvider id={"4284"} alt="Batea / Transportín" />
                  </label>
                  <SipcoInputText
                    value={fullHojaRuta?.batea?.descripcion}
                    readOnly
                  />
                </div>

                <div
                  className="sipco-option"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <label>
                    <LanguageProvider id={"16875"} alt="Compra flete" />
                  </label>
                  <Checkbox
                    checked={fullHojaRuta?.lineas[0]?.compra}
                    readOnly
                  />
                  <label>
                    <LanguageProvider id={"16876"} alt="Venta flete" />
                  </label>
                  <Checkbox checked={fullHojaRuta?.lineas[0]?.venta} readOnly />
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </Panel>
    </div>
  );
}
