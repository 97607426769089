import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useRef } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { EstadoRemito } from "src/models/EstadoRemito";

const MantenerEstadosRemito = () => {
  const SCREEN_CODE = "abm0066_mantener_estados_remito";
  const URL_BASE = "/mantener-estados-remito";
  const SCREEN_TITLE = (
    <LanguageProvider id="734" alt="Mantener Estados Remitos" />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);

  async function handleSave(model: EstadoRemito, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const estadoRemito = {
          ...model,
          pais: filterValues.securityValues.country          
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          estadoRemito,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: EstadoRemito, filterValues: any) {
    try {
      const estadoRemito = {
        ...model,
        pais: filterValues.securityValues.country        
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        estadoRemito,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: EstadoRemito, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        estadoRemito: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<EstadoRemito[]> {
    if (filter?.securityValues?.country) {
      try {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/find",
          {
            pais: filter.securityValues.country,
            transaccion: filter.transaccion,
          }
        );
        if (status === 200)
          if (data.length > 0) {
            return data;
          } else {
            return [];
          }
      } catch (error) {
        console.error(error);
      }
    }

    return [];
  }
  async function handleGet(model: any): Promise<EstadoRemito> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          estadoRemito: { ...model },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<EstadoRemito>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        securityFilterProps={{
          securityOptions: {
            screenCode: SCREEN_CODE,
            country: true,
          },
        }}
        // filterSettings={[]}
        dataTableColumns={[
          { field: "id", header: <LanguageProvider id="6590" alt="Código" /> },
          {
            field: "descripcion",
            header: <LanguageProvider id="3344" alt="Estado" />,
          },
          {
            field: "error",
            header: <LanguageProvider id="13" alt="Error" />,
            body: (rowData: any) => (rowData.error ? "SI" : "NO"),
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          activo: true,
          pais: null,
          error: false
          
        }}
        formSettings={[
          {
            field: "id",
            label: LanguageProvider({ id: "6590", alt: "Código" }),
            type: "inputText",
            disabled:true
          },
          {
            field: "descripcion",
            label: LanguageProvider({ id: "3344", alt: "Descripción" }),
            type: "inputText",
            length: 50,
          },
          {
            field: "error",
            label: LanguageProvider({ id: "13", alt: "Error" }),
            type: "checkbox",
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          }
        ]
        }
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default MantenerEstadosRemito;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
