import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useRef } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import { ContableSistemaOrigen } from "src/models/ContableSistemaOrigen";

const ContableSistemaOrigens = () => {
  const SCREEN_CODE = "abm0083_contable_sistema_origen";
  const URL_BASE = "/mantener-contable-sistema-origen";
  const SCREEN_TITLE = (
    <LanguageProvider
      id="1431"
      alt="Mantener Orígenes Contables Generales"
    />
  );
  const sipcoAxiosService = useSipcoAxiosService();
  const toast = useRef<Toast>(null);
  const empityFilter = `${LanguageProvider({ id: "39", alt: "Existen datos en blanco." })} - 
  ${LanguageProvider({ id: "5198", alt: "Sistemas" })}`;

  async function handleSave(model: ContableSistemaOrigen, filterValues: any) {
    if (!filterValues?.sistema) {
      toast.current?.show({
        severity: "warn",
        detail: empityFilter,
        life: 3000,
      });
      return false;
    }
    try {
      const contableSistemaOrigen = {
        ...model,
        sistema: filterValues?.sistema,
      };
      await sipcoAxiosService.post(URL_BASE + "/save", {
        contableSistemaOrigen,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: ContableSistemaOrigen, filterValues: any) {
    try {
      const contableSistemaOrigen = {
        ...model,
        sistema: filterValues?.sistema,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        contableSistemaOrigen,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: ContableSistemaOrigen, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        contableSistemaOrigen: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<ContableSistemaOrigen[]> {
    if (!filter?.sistema) {
      toast.current?.show({
        severity: "warn",
        detail: empityFilter,
        life: 3000,
      });
      return [];
    }
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/find",
        {
          sistema: filter?.sistema,
        }
      );
      if (status === 200)
        if (data.length > 0) {
          return data;
        } else {
          return [];
        }
    } catch (error) {
      console.error(error);
    }

    return [];
  }
  async function handleGet(model: any): Promise<ContableSistemaOrigen> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          contableSistemaOrigen: { ...model },
        }
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadSistema(filter: any): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/sistema",
        {}
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <>
      <Toast ref={toast} position="bottom-left" />
      <GenericAbm<ContableSistemaOrigen>
        screenCode={SCREEN_CODE}
        title={SCREEN_TITLE}
        // securityFilterProps={{
        //   securityOptions: {
        //     screenCode: SCREEN_CODE,
        //     country: true,
        //     client: true,
        //     account: true,
        //   },
        // }}
        filterSettings={[
          {
            filter: "sistema",
            label: LanguageProvider({ id: "5198", alt: "Sistemas" }),
            placeholder: LanguageProvider({ id: "5198", alt: "Sistemas" }),
            type: "dropdown",
            dataSource: loadSistema,
            dropDownTemplate: DropdownOptionTemplate,
            required: true,
          },
        ]}
        dataTableColumns={[
          {
            field: "codigoOrigen",
            header: <LanguageProvider id="6111" alt="Origen Contable" />,
          },
          {
            field: "usuarioUltimaModificacion.id",
            header: (
              <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
            ),
          },
          {
            field: "fechaUltimaModificacion.date",
            header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
            body: (rowData: any) => (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            ),
          },
          {
            field: "activo",
            header: <LanguageProvider id="72" alt="Activo" />,
            body: (rowData: any) => (rowData.activo ? "SI" : "NO"),
          },
        ]}
        dataTableRowClassName={(rowData: any) => {
          let className = "";
          if (!rowData.activo) {
            className += "data-row-inactive";
          }
          return className;
        }}
        defaultValues={{
          id: "",
          activo: true,
          sistema: null,
          codigoOrigen: "",
        }}
        formSettings={[
          {
            field: "codigoOrigen",
            label: LanguageProvider({
              id: "6111",
              alt: "Origen Contable",
            }),
            type: "inputText",
            length: 4,
            keyfilter:"pint",
            required: true,
            disableOnUpdate: true
          },
          {
            field: "activo",
            label: LanguageProvider({ id: "72", alt: "Activo" }),
            type: "checkbox",
          },
        ]}
        save={handleSave}
        update={handleUpdate}
        remove={handleRemove}
        list={handleList}
        get={handleGet}
      />
    </>
  );
};

export default ContableSistemaOrigens;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
