import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React, { useRef, useState } from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Destino } from "src/models/Destino";

const MantenerDestinos = () => {
  const SCREEN_CODE = "abm0020_mantener_destinos";
  const URL_BASE = "/mantener-destinos";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: Destino, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const destino = {
          ...model,
          pais: filterValues.securityValues.country,
          cliente: filterValues.securityValues.client,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          destino,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleUpdate(model: Destino, filterValues: any) {
    try {
      const destino = {
        ...model,
        pais: filterValues.securityValues.country,
        cliente: filterValues.securityValues.client,
      };
      await sipcoAxiosService.post(URL_BASE + "/update", {
        destino,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleRemove(model: Destino, filterValues: any) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        destino: { ...model },
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }
  async function handleList(filter: any): Promise<Destino[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter.securityValues.country,
          cliente: filter.securityValues.client,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function handleGet(model: any): Promise<Destino> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          destino: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  
  const lastCountryIdForPais = useRef<number | null>(null); // Armazena o último ID
  const [paddingPaises, setPaddingPaises] = useState(false);
  const [prevPaises, setPrevPaises] = useState([]); //Armazena previamente o valor de um retorno positivo
  async function loadPaises(filter: any): Promise<Destino[]> {
    try {
      const currentCountryId = filter?.securityValues?.country?.id;

      // Se o país não existir ou o ID for o mesmo, não faz a requisição
      if (!currentCountryId || lastCountryIdForPais.current === currentCountryId) {
        return prevPaises.length > 0 ? prevPaises : []; // retorna o valor previo para chamadas do generic-abm
      }

      if (!paddingPaises) {
        setPaddingPaises(true);
        lastCountryIdForPais.current = currentCountryId; // Atualiza o último ID

        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/pais",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
          }
        );

        if (status === 200) {
          setPrevPaises(data);          
          return data;
        }
        setPaddingPaises(false);
      }
    } catch (error) {
      setPaddingPaises(false);
      console.error(error);
    }
    return [];
  }

  const lastCountryIdForCliente = useRef<number | null>(null); // Armazena o último ID
  const [paddingClientes, setPaddingClientes] = useState(false);
  const [prevClientes, setPrevClientes] = useState([]); //Armazena previamente o valor de um retorno positivo
  async function loadClientes(filter: any): Promise<Destino[]> {
    try {
      const currentCountryId = filter?.securityValues?.country?.id;

      // Se o país não existir ou o ID for o mesmo, não faz a requisição
      if (!currentCountryId || lastCountryIdForCliente.current === currentCountryId) {
        return prevClientes.length > 0 ? prevClientes : []; // retorna o valor previo para chamadas do generic-abm
      }

      if (!paddingClientes) {
        setPaddingClientes(true);
        lastCountryIdForCliente.current = currentCountryId; // Atualiza o último ID

        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/cliente",
          {
            pais: filter.securityValues.country,
            cliente: filter.securityValues.client,
          }
        );

        if (status === 200) {
          setPrevClientes(data);          
          return data;
        }
        setPaddingClientes(false);
      }
    } catch (error) {
      setPaddingClientes(false);
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<Destino>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="360" alt="Mantener Destinos" />}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
        },
      }}
      dataTableColumns={[
        {
          field: "paisDestino.descripcion",
          header: <LanguageProvider id="154" alt="País" />,
        },
        {
          field: "id",
          header: <LanguageProvider id="6590" alt="Código" />,
        },
        {
          field: "descripcion",
          header: <LanguageProvider id="369" alt="Destinos" />,
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
        {
          field: "cobrarParafina",
          header: <LanguageProvider id="16294" alt="Cobra Parafinado" />,
          body: (rowData: any) => {
            return rowData.cobrarParafina ? "SI" : "NO";
          },
        },
        {
          field: "cobrarEmbolsado",
          header: <LanguageProvider id="16293" alt="Cobra Embolsado" />,
          body: (rowData: any) => {
            return rowData.cobrarEmbolsado ? "SI" : "NO";
          },
        },
        {
          field: "cliente.descripcion",
          header: <LanguageProvider id="54" alt="Cliente" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
      ]}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      defaultValues={{
        id: "",
        activo: true,
        pais: null,
        cliente: null,
        cobrarEmbolsado: false,
        cobrarParafina: false,
        paisDestino: null,
        destinoFrancia:"",
      }}
      loadDropdownData={{ paisDestino: loadPaises, cliente: loadClientes }}
      formSettings={[
        {
          field: "id",
          label: <LanguageProvider id="6590" alt="Código" />,
          type: "inputText",
          length: 4,
        },
        {
          field: "descripcion",
          label: <LanguageProvider id="369" alt="Destinos" />,
          type: "inputText",
          length: 50,
        },
        {
          field: "paisDestino",
          label: <LanguageProvider id="154" alt="País" />,
          type: "dropdown",
          dropDownPlaceholder: LanguageProvider({ id: "154", alt: "País" }),
          dataSource: (filter: any) => loadPaises(filter),
          dropDownTemplate: DropdownOptionTemplate,
        },
        {
          field: "cliente",
          label: <LanguageProvider id="54" alt="Cliente" />,
          type: "dropdown",
          dropDownPlaceholder: LanguageProvider({ id: "54", alt: "Cliente" }),
          dataSource: (filter: any) => loadClientes(filter),
          dropDownTemplate: DropdownOptionTemplateCliente,
        },
        {
          field: "cobrarParafina",
          label: <LanguageProvider id="16294" alt="Cobra Parafinado" />,
          type: "checkbox",
        },
        {
          field: "cobrarEmbolsado",
          label: <LanguageProvider id="16293" alt="Cobra Embolsado" />,
          type: "checkbox",
        },
        {
          field: "activo",
          label: <LanguageProvider id="72" alt="Activo" />,
          type: "checkbox",
        },
      ]}
      list={handleList}
      get={handleGet}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
    />
  );
};

export default MantenerDestinos;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};

const DropdownOptionTemplateCliente = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.label} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
}
