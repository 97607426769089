import GenericAbm from "@shared/components/genericAbm/generic-abm";
import { LanguageProvider } from "@shared/components/language-provider";
import React from "react";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { DiasEntrega } from "src/models/DiasEntrega";

const MantenerDiasEntrega = () => {
  const SCREEN_CODE = "abm0140_mantener_dias_entrega";
  const URL_BASE = "/mantener-dias-entrega";
  const sipcoAxiosService = useSipcoAxiosService();

  async function handleSave(model: DiasEntrega, filterValues: any) {
    try {
      if (filterValues?.securityValues?.country) {
        const diaEntrega = {
          ...model,
          pais: filterValues.securityValues.country,
          cuenta: filterValues.securityValues.account,
        };
        await sipcoAxiosService.post(URL_BASE + "/save", {
          diaEntrega: diaEntrega,
        });
        return true;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleUpdate(model: DiasEntrega) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/update", {
        diaEntrega: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleRemove(model: DiasEntrega) {
    try {
      await sipcoAxiosService.post(URL_BASE + "/remove", {
        diaEntrega: model,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  }

  async function handleList(filter: any): Promise<DiasEntrega[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findBy",
        {
          pais: filter?.securityValues?.country,
          cuenta: filter?.securityValues?.account,
        }
      );
      if (status === 200) return data;
      return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function handleGet(model: any): Promise<DiasEntrega> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/findByPk",
        {
          diaEntrega: model,
        }
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  async function loadPaisOrigen(): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/form/pais",
        {}
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadProvinciaOrigen(filter): Promise<any[]> {
    try {
      if (filter?.paisOrigen) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/provincia",
          {
            pais: filter?.paisOrigen,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadCiudadOrigen(filter): Promise<any[]> {
    try {
      if (filter?.estadoOrigen) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/ciudad",
          {
            provincia: filter?.estadoOrigen,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadPaisDestino(): Promise<any[]> {
    try {
      const { status, data } = await sipcoAxiosService.post(
        URL_BASE + "/form/pais",
        {}
      );
      if (status === 200) return data;
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadProvinciaDestino(filter): Promise<any[]> {
    try {
      if (filter?.paisDestino) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/provincia",
          {
            pais: filter?.paisDestino,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadCiudadDestino(filter): Promise<any[]> {
    try {
      if (
        filter?.pais &&
        filter?.cuenta &&
        filter?.ciudad &&
        filter?.estadoDestino
      ) {
        const { status, data } = await sipcoAxiosService.post(
          URL_BASE + "/form/ciudad-destino",
          {
            pais: filter?.pais,
            cuenta: filter?.cuenta,
            ciudad: filter?.ciudad,
            provincia: filter?.estadoDestino,
          }
        );
        if (status === 200) return data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  return (
    <GenericAbm<DiasEntrega>
      screenCode={SCREEN_CODE}
      title={<LanguageProvider id="11240" alt="Mantener Días de entrega" />}
      save={handleSave}
      update={handleUpdate}
      remove={handleRemove}
      list={handleList}
      get={handleGet}
      defaultValues={{
        id: "",
        pais: null,
        cuenta: null,
        paisOrigen: null,
        estadoOrigen: null,
        ciudadOrigen: null,
        paisDestino: null,
        estadoDestino: null,
        ciudadDestino: null,
        diasEntrega: "",
      }}
      dataTableRowClassName={(rowData: any) => {
        let className = "";
        if (!rowData.activo) {
          className += "data-row-inactive";
        }
        return className;
      }}
      securityFilterProps={{
        securityOptions: {
          screenCode: SCREEN_CODE,
          country: true,
          account: true,
        },
      }}
      loadDropdownData={{
        paisOrigen: loadPaisOrigen,
        estadoOrigen: loadProvinciaOrigen,
        ciudadOrigen: loadCiudadOrigen,
        paisDestino: loadPaisDestino,
        estadoDestino: loadProvinciaDestino,
        ciudadDestino: loadCiudadDestino,
      }}
      dataTableColumns={[
        {
          field: "paisOrigen.descripcion",
          header: <LanguageProvider id="2978" alt="País Origen" />,
        },
        {
          field: "estadoOrigen.descripcion",
          header: <LanguageProvider id="11235" alt="Provincia Origen" />,
        },
        {
          field: "ciudadOrigen.descripcion",
          header: <LanguageProvider id="11237" alt="Ciudad Origen" />,
        },
        {
          field: "diasEntrega",
          header: <LanguageProvider id="11238" alt="Días de Entrega" />,
        },
        {
          field: "ciudadDestino.descripcion",
          header: <LanguageProvider id="10683" alt="Ciudad Destino" />,
        },
        {
          field: "estadoDestino.descripcion",
          header: <LanguageProvider id="10682" alt="Provincia Destino" />,
        },
        {
          field: "paisDestino.descripcion",
          header: <LanguageProvider id="10681" alt="País Destino" />,
        },
        {
          field: "usuarioUltimaModificacion.id",
          header: (
            <LanguageProvider id="13706" alt="Usuario Últ. Modificación" />
          ),
          body: (rowData) => `(${rowData.usuarioUltimaModificacion.id})`,
        },
        {
          field: "fechaUltimaModificacion.date",
          header: <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />,
          body: (rowData: any) => {
            return (
              <span>
                {new Date(
                  rowData.fechaUltimaModificacion.timestamp
                ).toLocaleString()}
              </span>
            );
          },
        },
        {
          field: "activo",
          header: <LanguageProvider id="72" alt="Activo" />,
          body: (rowData: any) => {
            return rowData.activo ? "SI" : "NO";
          },
        },
      ]}
      formSettings={[
        {
          field: "paisOrigen",
          label: <LanguageProvider id="2978" alt="País Origen" />,
          dropDownPlaceholder: LanguageProvider({
            id: "2978",
            alt: "País Origen",
          }),
          type: "dropdown",
          dataSource: () => loadPaisOrigen(),
          dropDownTemplate: DropdownOptionTemplate,
          virtualizeDropdown: true,
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "estadoOrigen",
          label: <LanguageProvider id="11235" alt="Provincia Origen" />,
          dropDownPlaceholder: LanguageProvider({
            id: "11235",
            alt: "Provincia Origen",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadProvinciaOrigen(filter),
          dropDownTemplate: DropdownOptionTemplate,
          virtualizeDropdown: true,
          dependency: ["paisOrigen"],
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "ciudadOrigen",
          label: <LanguageProvider id="11237" alt="Ciudad Origen" />,
          dropDownPlaceholder: LanguageProvider({
            id: "11237",
            alt: "Ciudad Origen",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadCiudadOrigen(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["estadoOrigen"],
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "paisDestino",
          label: <LanguageProvider id="10681" alt="País Destino" />,
          dropDownPlaceholder: LanguageProvider({
            id: "10681",
            alt: "País Destino",
          }),
          type: "dropdown",
          dataSource: () => loadPaisDestino(),
          dropDownTemplate: DropdownOptionTemplate,
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "estadoDestino",
          label: <LanguageProvider id="10682" alt="Provincia Destino" />,
          dropDownPlaceholder: LanguageProvider({
            id: "10682",
            alt: "Provincia Destino",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadProvinciaDestino(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["paisDestino"],
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "ciudadDestino",
          label: <LanguageProvider id="10683" alt="Ciudad Destino" />,
          dropDownPlaceholder: LanguageProvider({
            id: "10683",
            alt: "Ciudad Destino",
          }),
          type: "dropdown",
          dataSource: (filter: any) => loadCiudadDestino(filter),
          dropDownTemplate: DropdownOptionTemplate,
          dependency: ["estadoDestino"],
          disableOnUpdate: true,
          required: true,
        },
        {
          field: "diasEntrega",
          label: <LanguageProvider id="11238" alt="Días de Entrega" />,
          type: "inputNumber",
          min: 0,
          max: 999,
          required: true,
        },
      ]}
    />
  );
};

export default MantenerDiasEntrega;

const DropdownOptionTemplate = (option: any, props = null) => {
  if (option)
    return (
      <div className="flex align-items-center">
        {option.descripcion} ({option.id})
      </div>
    );
  return <span>{props.placeholder}</span>;
};
