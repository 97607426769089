import "./contabilizar-viajes-terceros.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Toast } from "primereact/toast";
import useRowStyles from "../../../hooks/useRowStyles";
import useFormattedDate from "../../../hooks/useFormattedDate";
import { Dialog } from "primereact/dialog";
import ContabilizarViajesTercerosDialog from "./componentes/contabilizar-viajes-terceros-dialog";

export function ContabilizarViajesTerceros() {
  const SCREEN_CODE = "fun0226_contabilizar_viajes_terceros";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();

  const dt = useRef(null);
  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);
  const [selectedReportData, setSelectedReportData] = useState(null);
  const [validFieldDialog, setValidFieldDialog] = useState(false);
  const [displayFormDialog, setDisplayFormDialog] = useState(false);
  const [formModel, setFormModel] = useState(null);
  const [dialogMode, setDialogMode] = useState<DialogMode | null>(null);

  enum DialogMode {
    ComprasVentas = 0,
    Contabilizar = 1,
    Anular = 2,
  }

  const rowStyles = useRowStyles([selectedReportData]);
  const { formatDate } = useFormattedDate();

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const btnComprasVentasClick = () => {
    try {
      setDialogMode(DialogMode.ComprasVentas);
      if (!selectedReportData) {
        setValidFieldDialog(true);
        return;
      }
      setDisplayFormDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  const btnContabilizarClick = () => {
    try {
      setDialogMode(DialogMode.Contabilizar);
      if (!selectedReportData) {
        setValidFieldDialog(true);
        return;
      }
      setDisplayFormDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  const btnAnularClick = () => {
    try {
      setDialogMode(DialogMode.Anular);
      if (!selectedReportData) {
        setValidFieldDialog(true);
        return;
      }
      setDisplayFormDialog(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmationClose = () => {
    try {
      setValidFieldDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [filter, setFilter] = useState({
    nroOfertaServicio: "",
    nroHojaRuta: "",
    vin: null,
    cliente: null,
    codReferencia: "",
    fechaDesde: new Date(),
    fechaHasta: new Date(),
  });

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters.securityValues.country && vin !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/contabilizar-viajes-terceros/vin",
          {
            pais: { id: securityFilters.securityValues.country.id },
            cuenta: { id: securityFilters.securityValues.account.id },
            vin: { id: vin },
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError("1480", "El VIN no existe");
          }
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    setVin([]);
    return [];
  }

  const [loadingCliente, setLoadingCliente] = useState(false);
  const [cliente, setCliente] = useState([]);
  async function loadCliente() {
    setLoadingCliente(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/contabilizar-viajes-terceros/cliente",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
          }
        );

        if (status === 200) {
          data.unshift({ id: "TODOS", descripcionFull: "TODOS" });
          setCliente(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCliente(false);
    }
    setCliente([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const clientes = await loadCliente();

          setFilter((prevFilter) => ({
            ...prevFilter,
            cliente: clientes?.length > 0 ? clientes[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  useEffect(() => {
    async function updateSelectedReportData() {
      if (selectedReportData) {
        try {
          setFormModel(selectedReportData);
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateSelectedReportData();
  }, [selectedReportData]);

  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/contabilizar-viajes-terceros/detalle",
        {
          pais: { id: securityFilters.securityValues.country.id },
          cuenta: { id: securityFilters.securityValues.account.id },
          ordenServicio: filter.nroOfertaServicio,
          hojaRuta: filter.nroHojaRuta,
          vin: { id: filter?.vin?.id },
          fechaDesde: {
            date: filter.fechaDesde.getTime(),
          },
          fechaHasta: {
            date: filter.fechaHasta.getTime(),
          },
          cliente: { id: filter?.cliente?.id },
          codReferencia: filter?.codReferencia,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  return (
    <div className="contabilizar-viajes-terceros">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          account: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="16563" alt="Nro. Oferta Servicio" />
                </label>
                <SipcoInputText
                  value={filter.nroOfertaServicio}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      nroOfertaServicio: e.target.value,
                    })
                  }
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="2940" alt="Nro. Hoja de Ruta" />
                </label>
                <SipcoInputText
                  value={filter.nroHojaRuta}
                  onChange={(e) =>
                    setFilter({ ...filter, nroHojaRuta: e.target.value })
                  }
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="662" alt="Ingrese el VIN" />
                </label>
                <SipcoAutocomplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="id"
                  dropdown
                  forceSelection
                  maxLength={17}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="54" alt="Cliente" />
                </label>
                <SipcoDropdown
                  value={filter.cliente}
                  options={cliente}
                  onChange={(e) => setFilter({ ...filter, cliente: e.value })}
                  optionLabel="descripcionFull"
                  virtualScrollerOptions={{ itemSize: 10 }}
                  loading={loadingCliente}
                  filter
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="23097" alt="Código referencia" />
                </label>
                <SipcoInputText
                  value={filter.codReferencia}
                  onChange={(e) =>
                    setFilter({ ...filter, codReferencia: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="2621" alt="Desde" />
                </label>
                <SipcoCalendar
                  value={filter.fechaDesde}
                  onChange={(e) => {
                    const date = new Date(e.value);
                    setFilter({
                      ...filter,
                      fechaDesde: date,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="2622" alt="Hasta" />
                </label>
                <SipcoCalendar
                  value={filter.fechaHasta}
                  onChange={(e) => {
                    const date = new Date(e.value);
                    setFilter({
                      ...filter,
                      fechaHasta: date,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        header={
          <LanguageProvider id="23095" alt="Contabilizar Viajes de Terceros" />
        }
      >
        <SipcoDataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={16}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="single"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
        >
          <SipcoColumn
            field="caratula.id"
            header={<LanguageProvider id="16641" alt="Oferta de Servicios" />}
            sortable
            filter
          />
          <SipcoColumn
            field="cliente.descripcion"
            header={<LanguageProvider id="54" alt="Cliente" />}
            sortable
            filter
          />
          <SipcoColumn
            field="fechaCotizacion.date"
            header={<LanguageProvider id="6256" alt="Fecha Cotización" />}
            sortable
            body={(rowData) =>
              rowData.fechaCotizacion?.date
                ? formatDate(rowData.fechaCotizacion.date)
                : rowData.fechaCotizacion || ""
            }
          />
          <SipcoColumn
            field="cantidad"
            header={<LanguageProvider id="11720" alt="Cantidad de Vines" />}
            sortable
            filter
          />
        </SipcoDataTable>
      </Panel>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "23531",
            alt: "Compras/Ventas",
          })}
          icon="pi pi-shopping-cart"
          onClick={btnComprasVentasClick}
        />
        <Button
          label={LanguageProvider({
            id: "1309",
            alt: "Contabilizar",
          })}
          icon="pi pi-calculator"
          onClick={btnContabilizarClick}
        />
        <Button
          label={LanguageProvider({
            id: "2784",
            alt: "Anular",
          })}
          icon="pi pi-ban"
          onClick={btnAnularClick}
        />
      </div>

      <div className="dialogs">
        {/* Debe seleccionar al menos un registro de la Grilla Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validFieldDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleConfirmationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleConfirmationClose}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="753"
              alt="Debe seleccionar al menos un registro de la Grilla."
            />
          </p>
        </Dialog>
      </div>

      <div className="popups">
        <ContabilizarViajesTercerosDialog
          visible={displayFormDialog}
          model={formModel}
          mode={dialogMode}
          onClose={() => {
            setDisplayFormDialog(false);
            //setFormModel(null);
          }}
        />
      </div>
    </div>
  );
}
export default ContabilizarViajesTerceros;

const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
