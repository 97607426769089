import "./ModificacionVin.scss";
import "primeicons/primeicons.css";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScreenCodeValue } from "@shared/ScrennCode";
import SipcoCalendar from "@shared/components/sipco-calendar";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import { useInactivoColor } from "../../../hooks/useInactivoColor";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import SipcoAutocomplete from "@shared/components/sipco-autocomplete";
import { HttpStatusCode } from "axios";
import { Checkbox } from "primereact/checkbox";
import { LanguageProviderString } from "@shared/components/language-provider-string";
import useAuth from "@shared/AuthContext";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import SipcoInputTextArea from "@shared/components/sipco-input-text-area";


const initialVinState = {
  id: "",
  marca: null,
  modelo: null,
  color: null,
  denominacionComercial: null,
  destinoFrancia: null,
  modeloLargo: "",
  numeroMotor: "",
  patente: "",
  mercado: null,
  fechaSalidaFabrica: null,
  codigoPlataforma: null,
  destino: null,
  fechaPendienteIngresoTaller: null,
  descripcionIngresoTaller: "",
  fechaBloqueaLiberacionRemito: null,
  descripcionBloqueaLiberacionRemito: "",
};
export function ModificacionVin() {
  const SCREEN_CODE = "fun0046_modificacion_vin";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  usePageViews();
  ScreenCodeValue(SCREEN_CODE);
  const inactivoColor = useInactivoColor();
  const { user } = useAuth();

  const [procesarDialog, setProcesarDialog] = useState(false);
  const [confirmProcessDialog, setConfirmProcessDialog] = useState(false);
  const [fieldsEnabled, setFieldsEnabled] = useState(false);



  const [filter, setFilter] = useState({
    vin: { id: "" } as any,
    nuevoVinCheck: false,
    nuevoVin: null,
  });
  const [vin, setVin] = useState<any>(initialVinState);
  const [vinAnterior, setVinAnterior] = useState<any>({});
  const [disabledNuevoVin, setDisabledNuevoVin] = useState(true);

  const [loadingVin, setLoadingVin] = useState(false);

  //  Ingrese el Vin

  async function loadVin() {
    if (
      filter?.vin?.id?.length < 8 ||
      filter?.vin?.id?.length > 17 ||
      filter?.vin?.id?.length === undefined
    ) {
      toast.current?.show({
        severity: "warn",
        detail: LanguageProviderString({
          id: "653",
          alt: "Debe ingresar un minimo de 8 y un maximo de 17 dígitos",
          user: user,
        }),
        life: 3000,
      });
      setDisabledNuevoVin(true);
      return;
    }
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.account &&
        filter.vin.id !== ""
      ) {
        setLoadingVin(true);
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/vin-activo",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: filter.vin.id,
          }
        );
        if (status === 200) {
          if (data.length > 0) {
            setVinAnterior(data[0]);
            // Obtém os dados completos de marca, mercado e destino
            const fullData = await loadAllFilds();
            let modelos, colores, denominacionComercial, destinosFrancia;
            let attempts = 0;
            const maxAttempts = 3; // Tenta no máximo 3 vezes

            do {
              [modelos, colores, denominacionComercial, destinosFrancia] =
                await Promise.all([
                  loadModelos(data[0].marca),
                  loadCores(data[0].marca),
                  loadDenoComerciais(data[0].marca),
                  loadDestinosFrancias(data[0].marca, data[0].destino),
                ]);

              attempts++;
            } while (
              (modelos?.length === 0 ||
                colores?.length === 0 ||
                denominacionComercial?.length === 0 ||
                destinosFrancia?.length === 0) &&
              attempts < maxAttempts
            );

            // Preenche os valores utilizando os IDs de data[0] e os detalhes de fullData
            const testeVin = {
              ...data[0],
              id: data[0].id,
              marca:
                fullData.marca.find((m) => m.id === data[0].marca?.id) ||
                data[0].marca,
              mercado:
                fullData.mercado.find((m) => m.id === data[0].mercado?.id) ||
                data[0].mercado,
              destino:
                fullData.destino.find((d) => d.id === data[0].destino?.id) ||
                data[0].destino,
              modelo:
                modelos.find((m) => m.id === data[0].modelo?.id) ||
                data[0].modelo,
              color:
                colores.find((c) => c.id === data[0].color?.id) ||
                data[0].color,
              denominacionComercial:
                denominacionComercial.find(
                  (d) => d.id === data[0]?.denominacionComercial?.id
                ) || data[0],
              destinoFrancia:
                destinosFrancia.find(
                  (d) => d.destinoFrancia === data[0].destinoFrancia
                ) || data[0],
            };
            console.log("testeVin", testeVin);
            setVin(testeVin);
            setFilter({ ...filter, vin: data[0] });
            setDisabledNuevoVin(true);
            setFieldsEnabled(true);
          } else {
            setDisabledNuevoVin(false);
            setFieldsEnabled(false);
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  const [loadingVinNuevo, setLoadingVinNuevo] = useState(false);
  const [vinNuevo, setVinNuevo] = useState(null);

  // Nuevo Vin
  async function loadVinNuevo(value: any) {
    setLoadingVinNuevo(true);
    try {
      if (securityFilters?.securityValues?.country && value) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/vin",
          {
            pais: securityFilters.securityValues.country,
            vin: value,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setVinNuevo(data);
          toast.current.show({
            severity: "info",
            summary: "Atención",
            detail: "El VIN ya existe",
            life: 3000,
          });
          setFieldsEnabled(true);
          return data;
        }
      } else {
        toast.current.show({
          severity: "warn",
          summary: "No exist Vin",
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVinNuevo(false);
    }
  }

  const [loadingMarcas, setLoadingMarcas] = useState(false);
  const [marcas, setMarcas] = useState([]);
  async function loadMarcas() {
    setLoadingMarcas(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/marca",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setMarcas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
    return [];
  }

  const [loadingModelos, setLoadingModelos] = useState(false);
  const [modelos, setModelos] = useState([]);
  async function loadModelos(marca: any) {
    setLoadingModelos(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        marca
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/modelo",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca: marca,
          }
        );
        if (status === 200) {
          setModelos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
    return [];
  }

  const [loadingCores, setLoadingCores] = useState(false);
  const [cores, setCores] = useState([]);
  async function loadCores(marca: any) {
    setLoadingCores(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        marca
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/color",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca: marca,
          }
        );
        if (status === 200) {
          setCores(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCores(false);
    }
    return [];
  }

  const [loadingDenoComerciais, setLoadingDenoComerciais] = useState(false);
  const [denoComerciais, setDenoComerciais] = useState([]);
  async function loadDenoComerciais(marca: any) {
    setLoadingDenoComerciais(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        marca
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/denominacion-comercial",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca: marca,
          }
        );
        if (status === 200) {
          setDenoComerciais(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDenoComerciais(false);
    }
    return [];
  }

  const [loadingMercados, setLoadingMercados] = useState(false);
  const [mercados, setMercados] = useState([]);

  async function loadMercados() {
    setLoadingMercados(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/mercado",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setMercados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
    return [];
  }

  const [loadingDestinos, setLoadingDestinos] = useState(false);
  const [destinos, setDestinos] = useState([]);

  async function loadDestinos() {
    setLoadingDestinos(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/destino",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === HttpStatusCode.Ok) {
          setDestinos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinos(false);
    }
    return [];
  }

  const [loadingDestinosFrancias, setLoadingDestinosFrancias] = useState(false);
  const [destinosFrancias, setDestinosFrancias] = useState([]);
  async function loadDestinosFrancias(marca: any, destino: any) {
    setLoadingDestinosFrancias(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        destino &&
        marca
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/marca-destino",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca: marca,
            destino: destino,
          }
        );
        if (status === 200) {
          setDestinosFrancias(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinosFrancias(false);
    }
    return [];
  }

  const [loadingProcesar, setLoadingProcesar] = useState(false);

  async function procesar() {
    setLoadingProcesar(true);
    try {
      const payload = {
        vinAnterior: vinAnterior,
        vinNovo: { ...vin, destinoFrancia: vin.destinoFrancia.id },
        // vinNovo: {
        //   id: filter?.vin?.id,
        //   pais: filter?.vin?.pais,
        //   cliente: filter?.vin?.cliente,
        //   cuenta: filter?.vin?.cuenta,
        //   patente: filter?.vin?.patente,
        //   fechaPendienteIngresoTaller: filter?.vin?.fechaPendienteIngresoTaller,
        //   numeroMotor: filter?.vin?.numeroMotor,
        //   color: filter?.vin?.color,
        //   marca: filter?.vin?.marca,
        //   modelo: filter?.vin?.modelo,
        //   modeloLargo: filter?.vin?.modeloLargo,
        //   mercado: filter?.vin?.mercado,
        //   origen: filter?.vin?.origen,
        //   destino: filter?.vin?.destino,
        //   descripcionIngresoTaller: filter?.vin?.descripcionIngresoTaller,
        //   fechaBloqueaLiberacionRemito:
        //     filter?.vin?.fechaBloqueaLiberacionRemito,
        //   descripcionBloqueaLiberacionRemito:
        //     filter?.vin?.descripcionBloqueaLiberacionRemito,
        //   destinoFranciaFinal: filter?.vin?.destinoFranciaFinal,
        //   denominacionComercial: filter?.vin?.denominacionComercial,
        //   fechaSalidaFabrica: filter?.vin?.fechaSalidaFabrica,
        //   ncm: filter?.vin?.ncm,
        //   codigoPlataforma: filter?.vin?.codigoPlataforma,
        // },
      };

      const { status } = await sipcoAxiosService.post(
        "/modificacion-datos-vin/update-vin",
        payload
      );
      if (status === HttpStatusCode.Ok) {
        showSuccess("36");
        //aqui
        setVin(initialVinState);
        setFieldsEnabled(false);
      } else {
        toast.current.show({
          severity: "error",
          detail: status,
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcesar(false);
    }
  }

  async function handleMarcaChange(value: any) {
    try {
      const [modelos, colores, denominacionComercial, destinosFrancia] =
        await Promise.all([
          loadModelos(value),
          loadCores(value),
          loadDenoComerciais(value),
          loadDestinosFrancias(value, vin.destino),
        ]);
      setVin({
        ...vin,
        marca: value,
        modelo: modelos[0] ?? null,
        color: colores[0] ?? null,
        denominacionComercial: denominacionComercial[0] ?? null,
        destinoFrancia: destinosFrancia[0] ?? null,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function handleDestinoChange(filterDestino: any) {
    try {
      const [destinosFrancia] = await Promise.all([
        loadDestinosFrancias(vin.marca, filterDestino),
      ]);
      setVin({
        ...vin,
        destino: filterDestino,
        destinoFrancia: destinosFrancia[0] ?? null,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function loadAllFilds() {
    const [marca, mercado, destino] = await Promise.all([
      loadMarcas(),
      loadMercados(),
      loadDestinos(),
    ]);
    return { marca, mercado, destino };
  }

  function comfirmProcesar() {
    confirmDialog({
      header: <LanguageProvider id={"16239"} alt="Atención" />,
      message: (
        <LanguageProvider
          id={"13275"}
          alt="¿Desea procesar los cambios realizados?"
        />
      ),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => procesar(),
      reject: null,
    });
  }

  function valid() {
    if (
      vinAnterior.numeroMotor === vin.numeroMotor &&
      vinAnterior.color.id === vin.color.id &&
      vinAnterior.mercado.id === vin.mercado.id &&
      vinAnterior.marca.id === vin.marca.id &&
      vinAnterior.destino.id === vin.destino.id &&
      vinAnterior.marca.id === vin.marca.id &&
      vinAnterior.modelo.id === vin.modelo.id &&
      vinAnterior.modeloLargo === vin.modeloLargo &&
      vin.fechaPendienteIngresoTaller.date ===
        vinAnterior.fechaPendienteIngresoTaller.date &&
      vin.descripcionIngresoTaller === vinAnterior.descripcionIngresoTaller &&
      vin.fechaBloqueaLiberacionRemito.date ===
        vinAnterior.fechaBloqueaLiberacionRemito.date &&
      vin.fechaSalidaFabrica.date === vinAnterior.fechaSalidaFabrica.date &&
      vin.descripcionBloqueaLiberacionRemito ===
        vinAnterior.descripcionBloqueaLiberacionRemito
    ) {
      showWarn("909");
      return;
    }
    
    if (
      vin.marca.id === null ||
      vin.modelo.id === null ||
      vin.modeloLargo === "" ||
      vin.color.id === null
    ) {
      showWarn("39");
      return;
    }

    comfirmProcesar();
  }

  const showWarn = (id: string) => {
    try {
      toast.current.show({
        severity: "warn",
        summary: <LanguageProvider id={"16239"} alt={"Atención"} />,
        detail: <LanguageProvider id={id} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const showSuccess = (id: string) => {
    try {
      toast.current.show({
        severity: "success",
        summary: <LanguageProvider id={"16239"} alt={"Atención"} />,
        detail: <LanguageProvider id={id} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setDisabledNuevoVin(true);
    setFieldsEnabled(false);
    setVin(initialVinState);
  }, [filter.vin.id, securityFilters.securityValues]);

  return (
    <div className="modificacion-vin">
      <Toast ref={toast} position="bottom-left" />
      <ConfirmDialog />
      <div className="filter-container">
        <Filter
          securityOptions={{
            country: true,
            client: true,
            account: true,
            screenCode: SCREEN_CODE,
          }}
        />
      </div>
      <div className="panel-container">
        <Panel
          header={<LanguageProvider id={"903"} alt="Modificación datos VIN" />}
        >
          {/* Dados Vin  */}

          <div className="panel-header">
            <Panel>
              <div className="form">
                <div className="form-row">
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                    </label>
                    <SipcoInputText
                      value={filter?.vin?.id}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          vin: { id: e.target.value.toLocaleUpperCase() },
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          loadVin();
                        }
                      }}
                      keyfilter={"alphanum"}
                      maxLength={20}
                    />
                  </div>
                  <div style={{ alignSelf: "flex-end" }}>
                    <Button
                      icon="pi pi-search"
                      onClick={() => loadVin()}
                      loading={loadingVin}
                    />
                  </div>
                  <div className="check-option" style={{ marginTop: "1em" }}>
                    <label>
                      <LanguageProvider id={"2471"} alt="Nuevo VIN" />
                    </label>
                    <Checkbox
                      checked={filter?.nuevoVinCheck}
                      value={filter?.nuevoVinCheck}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          nuevoVinCheck: e.checked,
                          nuevoVin: "",
                        })
                      }
                      disabled={disabledNuevoVin}
                    />
                    {filter.nuevoVinCheck && (
                      <>
                        <SipcoInputText
                          value={filter?.nuevoVin}
                          onChange={(e) =>
                            setFilter({ ...filter, nuevoVin: e.target.value })
                          }
                        />
                        <Button
                          icon="pi pi-save"
                          label="Processar"
                          onClick={() => loadVinNuevo(filter.nuevoVin)}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Panel>
          </div>
          <div className="panel-content">
            {/* Dados a Modificar  */}
            <div className="card-left">
              <Panel
                header={
                  <LanguageProvider
                    id={"906"}
                    alt="Acesse os Dados a Modificar"
                  />
                }
              >
                <div className="form">
                  <div className="form-row">
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider id={"23888"} alt="Marca" />
                      </label>
                      <SipcoDropdown
                        value={vin?.marca}
                        options={marcas}
                        onChange={(e) => handleMarcaChange(e.value)}
                        optionLabel="descripcionFull"
                        itemTemplate={inactivoColor}
                        disabled={!fieldsEnabled}
                        loading={loadingMarcas}
                        filter
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"2139"} alt="Modelo" />
                      </label>
                      <SipcoDropdown
                        value={vin?.modelo}
                        options={modelos}
                        onChange={(e) => setVin({ ...vin, modelo: e.value })}
                        optionLabel="descripcionFull"
                        itemTemplate={inactivoColor}
                        disabled={!fieldsEnabled || !vin?.marca}
                        loading={loadingModelos}
                        filter
                      />
                    </div>
                    <div
                      className="sipco-option"
                      style={{ alignSelf: "flex-end" }}
                    >
                      <SipcoInputText
                        value={vin?.modelo?.id || ""}
                        readOnly={true}
                        disabled={!fieldsEnabled}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"13338"} alt="Modelo Largo" />
                      </label>
                      <SipcoInputText
                        value={vin?.modeloLargo?.charAt(0)}
                        disabled={!fieldsEnabled}
                      />
                    </div>
                    <div
                      className="sipco-option"
                      style={{ alignSelf: "flex-end" }}
                    >
                      <SipcoInputText
                        value={vin?.modeloLargo?.slice(1)}
                        disabled={!fieldsEnabled}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"2140"} alt="Color" />
                      </label>
                      <SipcoDropdown
                        value={vin?.color}
                        options={cores}
                        onChange={(e) => setVin({ ...vin, color: e.value })}
                        optionLabel="descripcionFull"
                        itemTemplate={inactivoColor}
                        disabled={!fieldsEnabled || !vin?.marca}
                        loading={loadingCores}
                        filter
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider
                          id={"18202"}
                          alt="Denominación Comercial"
                        />
                      </label>
                      <SipcoDropdown
                        value={vin?.denominacionComercial}
                        options={denoComerciais}
                        onChange={(e) =>
                          setVin({ ...vin, denominacionComercial: e.value })
                        }
                        optionLabel="descripcionFull"
                        disabled={!fieldsEnabled || !vin?.marca}
                        loading={loadingDenoComerciais}
                        filter
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"13335"} alt="Motor" />
                      </label>
                      <SipcoInputText
                        value={vin?.numeroMotor}
                        onChange={(e) =>
                          setVin({ ...vin, numeroMotor: e.target.value })
                        }
                        disabled={!fieldsEnabled}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"4724"} alt="Patente" />
                      </label>
                      <SipcoInputText
                        value={vin?.patente}
                        onChange={(e) =>
                          setVin({ ...vin, patente: e.target.value })
                        }
                        maxLength={8}
                        disabled={!fieldsEnabled}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"367"} alt="Mercado" />
                      </label>
                      <SipcoDropdown
                        value={vin?.mercado}
                        options={mercados}
                        onChange={(e) => setVin({ ...vin, mercado: e.value })}
                        optionLabel="descripcion"
                        disabled={!fieldsEnabled}
                        loading={loadingMercados}
                        filter
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"3410"} alt="Destino" />
                      </label>
                      <SipcoDropdown
                        value={vin?.destino}
                        options={destinos}
                        onChange={(e) => {
                          handleDestinoChange(e.value);
                        }}
                        optionLabel="descripcionFull"
                        disabled={!fieldsEnabled}
                        loading={loadingDestinos}
                        filter
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"16274"} alt="Destino Francia" />
                      </label>
                      <SipcoDropdown
                        value={vin?.destinoFrancia}
                        options={destinosFrancias}
                        onChange={(e) => {
                          setVin({ ...vin, destinoFrancia: e.value });
                        }}
                        optionLabel="descripcionFull"
                        disabled={!fieldsEnabled}
                        loading={loadingDestinosFrancias}
                        filter
                        itemTemplate={inactivoColor}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider
                          id={"12103"}
                          alt="Fecha Fabricación"
                        />
                      </label>
                      <SipcoCalendar
                        value={new Date(vin?.fechaSalidaFabrica?.timestamp)}
                        onChange={(e) =>
                          setVin({
                            ...vin,
                            fechaSalidaFabrica: {
                              timestamp: e.value.toISOString(),
                              date: e.value.toISOString(),
                            },
                          })
                        }
                        showButtonBar
                        showIcon
                        dateFormat="dd/mm/yy"
                        disabled={!fieldsEnabled}
                      />
                    </div>
                    <div className="sipco-option">
                      <label style={{ display: "block", marginBottom: "5px" }}>
                        <LanguageProvider id={"22209"} alt="Cód. Plataforma" />
                      </label>
                      <SipcoInputText
                        value={vin?.codigoPlataforma}
                        onChange={(e) =>
                          setVin({
                            ...vin,
                            codigoPlataforma: e.target.value,
                          })
                        }
                        maxLength={13}
                        disabled={!fieldsEnabled}
                      />
                    </div>
                  </div>
                </div>
              </Panel>
            </div>
            <div className="card-group">
              <div className="card">
                <Panel
                  header={
                    <LanguageProvider
                      id={"9853"}
                      alt="Pendiente Ingreso Taller"
                    />
                  }
                >
                  <div className="form">
                    <div className="form-row">
                      <div className="sipco-option">
                        <label>
                          <LanguageProvider id={"2131"} alt="Fecha" />
                        </label>
                        <SipcoCalendar
                          value={
                            vin?.fechaPendienteIngresoTaller?.date
                              ? new Date(vin.fechaPendienteIngresoTaller.date)
                              : null
                          }
                          onChange={(e) =>
                            setVin({
                              ...vin,
                              fechaPendienteIngresoTaller: {
                                timestamp: e.value.toISOString(),
                                date: e.value.toISOString(),
                              },
                            })
                          }
                          showButtonBar
                          showIcon
                          dateFormat="dd/mm/yy"
                          disabled={!fieldsEnabled}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"3625"} alt="Observaciones" />
                        </label>
                        <SipcoInputTextArea
                          value={vin.descripcionIngresoTaller}
                          onChange={(e) =>
                            setVin({
                              ...vin,
                              descripcionIngresoTaller: e.target.value,
                            })
                          }
                          disabled={!fieldsEnabled}
                          rows={5}
                          cols={30}
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
              </div>
              <div className="card">
                <Panel
                  header={
                    <LanguageProvider
                      id={"11439"}
                      alt="Bloquear Liberación de Remitos"
                    />
                  }
                >
                  <div className="form">
                    <div className="form-row">
                      <div className="sipco-option">
                        <label>
                          <LanguageProvider id={"2131"} alt="Fecha" />
                        </label>
                        <SipcoCalendar
                          value={
                            vin?.fechaBloqueaLiberacionRemito?.date
                              ? new Date(vin.fechaBloqueaLiberacionRemito.date)
                              : null
                          }
                          onChange={(e) =>
                            setVin({
                              ...vin,
                              fechaBloqueaLiberacionRemito: {
                                timestamp: e.value.toISOString(),
                                date: e.value.toISOString(),
                              },
                            })
                          }
                          showButtonBar
                          showIcon
                          dateFormat="dd/mm/yy"
                          disabled={!fieldsEnabled}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className={"sipco-option"}>
                        <label>
                          <LanguageProvider id={"3625"} alt="Observaciones" />
                        </label>
                        <SipcoInputTextArea
                          value={vin.descripcionBloqueaLiberacionRemito}
                          onChange={(e) =>
                            setVin({
                              ...vin,
                              descripcionBloqueaLiberacionRemito:
                                e.target.value,
                            })
                          }
                          disabled={!fieldsEnabled}
                          rows={5}
                          cols={30}
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
              </div>
            </div>
          </div>

          <Panel>
            <Button
              label={LanguageProvider({ id: "16283", alt: "Procesar" })}
              icon="pi pi-print"
              onClick={() => valid()}
              // onClick={() => setVin(initialVinState)}
              // disabled={!fieldsEnabled}
            />
          </Panel>
        </Panel>
      </div>

      {/* <Dialog
        header={<LanguageProvider id="16239" alt="Atención" />}
        visible={procesarDialog}
        style={{ width: "30vw", textAlign: "center" }}
        footer={
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <Button
              label={getLabel("10043", "Si")}
              icon="pi pi-check"
              onClick={() => handleProcesar(true)}
              autoFocus
              disabled={loadingProcesar}
            />
            <Button
              label={getLabel("10044", "No")}
              icon="pi pi-times"
              onClick={() => handleProcesar(false)}
              className="p-button-text"
              disabled={loadingProcesar}
            />
          </div>
        }
        onHide={() => setProcesarDialog(false)}
      >
        <p style={{ padding: "1rem 0", margin: "0" }}>
          <LanguageProvider
            id="13275"
            alt="¿Desea procesar los cambios realizados?"
          />
        </p>
        {loadingProcesar && (
          <ProgressSpinner style={{ width: "50px", height: "50px" }} />
        )}
      </Dialog>
      <Dialog
        header={<LanguageProvider id="16239" alt="Atención" />}
        visible={confirmProcessDialog}
        style={{ width: "30vw", textAlign: "center" }}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Button
              label={getLabel("5599", "OK")}
              icon="pi pi-check"
              autoFocus
            />
          </div>
        }
        onHide={null}
      >
        <p style={{ padding: "1rem 0", margin: "0" }}>
          <LanguageProvider
            id="36"
            alt="Su transacción se realizó con éxito."
          />
        </p>
      </Dialog> */}
    </div>
  );
}
export default ModificacionVin;
