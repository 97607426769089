import "./ModalModificationReparacionServicos.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { LanguageProvider } from "@shared/components/language-provider";
import SipcoColumn from "@shared/components/sipco-column";
import SipcoDataTable from "@shared/components/sipco-datatable";
import SipcoDropdown from "@shared/components/sipco-dropdown";
import SipcoInputText from "@shared/components/sipco-input-text";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../../../protected-routes";
import { useLocation } from "react-router-dom";
import usePageViews from "../../../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { HttpStatusCode } from "axios";
import { format } from "date-fns";

interface InterfaceModalModificationReparacionServicos {
  visible: boolean;
  onClose: () => void;
  model?: any;
  onConfirm?: (updatedList: any[]) => void;
  order?: any;
  listado?: any[];
}

const ModalModificationReparacionServicos: React.FC<
  InterfaceModalModificationReparacionServicos
> = ({ visible, onClose, model, onConfirm, order, listado }) => {
  const location = useLocation();
  const { pais, cuenta, tallerFilter, subcuenta, nroOrden } =
    location.state || {};
  const [selectedRows, setSelectedRows] = useState([]);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const toast = useRef<Toast>(null);
  const [mensageError, setMensageError] = useState(false);


  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    cliente: null,
    grupoTaller: null,
    cuentaTaller: null,
    modeloTaller: null,
    origen: null,
    prestacion: null,
    labelPrestacion: null,
    labelSubpretacion: null,
    horas: null,
    mecanico: null
  });


  console.log(listado)

  const [origen, setOrigen] = useState([]);
  const [loadingOrigen, setLoadingOrigen] = useState(false);

  async function loadOrigen() {
    setLoadingOrigen(true);
    try {
      if (order.pais !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-servicios/contable-origen-users",
          {
          }
        );
        if (status === HttpStatusCode.Ok) {
          setOrigen(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigen(false);
    }
  }

  const [modalReport, setModalReport] = useState([]);
  const [loadingModalReport, setLoadingModalReport] = useState(false);

  const [prestacions, setPrestacions] = useState([]);
  const [loadingPrestacions, setLoadingPrestacions] = useState(false);

  async function loadPrestacions(origen: any) {
    setLoadingPrestacions(true);
    try {
      if (order.pais !== null && modalReport.length >= 0) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-servicios/contable-origen-prestacions",
          {
            pais: order.pais,
            codori: origen.id
          }
        );
        if (status === HttpStatusCode.Ok) {
          setPrestacions(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPrestacions(false);
    }
  }

  const [subprestacions, setSubPrestacions] = useState([]);
  const [loadingSubPrestacions, setLoadingSubPrestacions] = useState(false);


  async function loadSubPrestacions(prestacion: any, origen: any) {
    setLoadingSubPrestacions(true);
    try {
      if (order.pais !== null && prestacion) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-servicios/contable-origen-subprestacions",
          {
            prestacion: prestacion.id,
            codori: origen.id
          }
        );
        if (status === HttpStatusCode.Ok) {
          setSubPrestacions(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSubPrestacions(false);
    }
  }
  const [mecanico, setMecanico] = useState([]);
  const [loadingMecanico, setLoadingMecanico] = useState(false);


  async function loadMecanico() {
    setLoadingMecanico(true);
    try {
      if (order.pais !== null && modalReport.length >= 0) {
        const { status, data } = await sipcoAxiosService.post(
          "/orden-reparacion-servicios/mecanico",
          {
            pais: order.pais,
            cuenta: order.cuenta,
            subcuenta: order.subcuenta,
            taller: order.taller
          }
        );
        if (status === HttpStatusCode.Ok) {
          setMecanico(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMecanico(false);
    }
  }




  function hadleLoadPrestacion(origen: any) {
    try {
      loadPrestacions(origen);



      setFilter({
        ...filter,
        origen: origen
      })
    } catch (error) {
      console.error(error);
    }
  }


  function hadleLoadSubPrestacion(prestacion: any, origen: any) {
    try {
      loadSubPrestacions(prestacion, origen);



      setFilter({
        ...filter,
        prestacion: prestacion
      })
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    const init = async () => {
      try {
        const [origen, mecanico] = await Promise.all([
          loadOrigen(),
          loadMecanico()
        ]);

        setFilter(({
          ...filter,
          origen: origen[0],
          mecanico: mecanico[0],
        }));
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
      }
    };

    init();
  }, [visible]);




  const [updateLabel, setUpdateLabel] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  async function update() {
    setLoadingUpdate(true);
    try {

      const { status, data } = await sipcoAxiosService.post(
        "/orden-reparacion-servicios/update",
        {
          detallesOrdenServicio: {
            pais: order.pais ?? null,
            cuenta: order.cuenta ?? null,
            subcuenta: order.subcuenta ?? null,
            taller: order.taller ?? null,
            ordenReparacion: order,
            id: listado.map(x => x.id)[0],
            horasEstimadas: filter.horas ?? null,
            clienteServicio: filter.cliente ?? null,
            mecanico: filter.mecanico ?? null,
            prestacion: filter.prestacion.id ?? null,
            codori: filter.origen.id ?? null,
            servicio: selectedRows[0] ?? null
          }
        }
      );
      if (status === HttpStatusCode.Ok) {
        setUpdateLabel(data);
        onClose();
        toast.current?.show({
          severity: "success",
          summary: "Success",
          detail: (
            <LanguageProvider
              id={"36"}
              alt="Su transacción se realizó con éxito."
            />
          ),
          life: 3000,
        });
        return data;
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Error",
          life: 3000,
        });
        setMensageError(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUpdate(false);
    }
  }



  return (
    <div>
      <Dialog
        className="modalReparacion"
        header="Orden Reparación Servicios - Modificar"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={LanguageProvider({ id: "15447", alt: "Confirmar" })}
                icon="pi pi-check"
                onClick={update}
                autoFocus
              />
              <Button
                label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
                icon="pi pi-times"
                onClick={onClose}
                className="p-button-text"
              />
            </div>
          );
        }}
      >

        <div className="modal-container">
          <div className="form-modal">

            <div className="form-column">
              {mensageError ? <p>Campos vacios</p> : null}
              <label><LanguageProvider id={"2496"} alt="Origen" /></label>
              <SipcoDropdown
                loading={loadingOrigen}
                options={origen}
                optionLabel="descripcion"
                value={filter.origen}
                onChange={(e) => hadleLoadPrestacion(e.value)}
                className="input-modal" />

              <label><LanguageProvider id={"4137"} alt="Prestación" /></label>
              <SipcoDropdown
                loading={loadingPrestacions}
                options={prestacions} optionLabel="descripcion" value={filter.prestacion}
                onChange={(e) => hadleLoadSubPrestacion(e.value, filter.origen)} className="input-modal" />

              <label><LanguageProvider id={"4587"} alt="Subprestación" /></label>

              <SipcoDropdown
                loading={loadingSubPrestacions}
                options={subprestacions}
                optionLabel="descripcion"
                value={filter.labelSubpretacion}
                onChange={(e) => setFilter({ ...filter, labelSubpretacion: e.value })}
                className="input-modal"
              />

            </div>
            <div className="form-column">
              <label><LanguageProvider id={"2372"} alt="Horas" /></label>
              <SipcoInputText value={filter.horas} onChange={(e) => setFilter({ ...filter, horas: e.target.value })} className="input-modal" />

              <label><LanguageProvider id={"17027"} alt="Mecánico" /></label>
              <SipcoDropdown
                loading={loadingMecanico}
                options={mecanico}
                optionLabel="descripcion"
                value={filter.mecanico} onChange={(e) => setFilter({ ...filter, mecanico: e.value })}
                className="input-modal" />
            </div>
          </div>
        </div>

      </Dialog>
    </div>
  );
};
export default ModalModificationReparacionServicos;
